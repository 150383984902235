<div *ngIf="!(colorScheme$ | ngrxPush)?.length; else showChart">
  <h3>No Data</h3>
</div>
<ng-template #showChart>
  <!-- <sso-custom-width-bar-chart
    class="generate-custom-width-bar-chart-graph"
    yAxisLabel="Marginal Abatement Cost (in €/tCO2e)"
    xAxisLabel="Total non-discounted emissions abated over the project lifetime (in CO2e)"
    titleText="MAC Curve"
    [data]="data"
    [colors]="colorScheme$ | ngrxPush"
  >
  </sso-custom-width-bar-chart> -->
</ng-template>

<mat-form-field
  class="form"
  [appearance]="readonly ? 'fill' : appearance || 'outline'"
  [ngClass]="customClass"
  floatlabel="auto"
  [style.pointerEvents]="readonly ? 'none' : ''"
  [class]="size"
>
  <mat-icon *ngIf="prefixIcon" matPrefix class="prefix-anchor">{{
    prefixIcon
  }}</mat-icon>
  <mat-icon *ngIf="suffixIcon" matSuffix class="suffix-anchor">{{
    suffixIcon
  }}</mat-icon>
  <mat-label>{{ label }}</mat-label>
  <input
    *ngIf="!isTextArea; else textArea"
    data-testid="input-field"
    matInput
    [attr.aria-label]="label"
    [formControl]="control"
    [placeholder]="placeholder"
    [type]="type"
    [required]="required"
    [maxlength]="maxLength"
    (keyup)="onKeyUp($event)"
    (blur)="onBlur($event)"
    [readonly]="readonly"
  />
  <ng-template #textArea>
    <textarea
      #input
      data-testid="textarea-field"
      matInput
      [rows]="rows"
      [attr.aria-label]="label"
      [formControl]="control"
      [placeholder]="placeholder"
      [type]="type"
      [required]="required"
      [readonly]="readonly"
      [maxlength]="maxLength"
      (keyup)="onKeyUp($event)"
      (keypress)="onKeyPress($event)"
    >
    </textarea>
  </ng-template>
  <mat-icon
    *ngIf="tooltip"
    matSuffix
    class="input-tooltip-anchor"
    [matTooltip]="tooltip"
    [matTooltipPosition]="tooltipPosition"
    >help</mat-icon
  >
  <mat-icon *ngIf="icon" matSuffix>{{ icon }}</mat-icon>
  <div class="unit-container" *ngIf="unit">{{ unit }}</div>
  <mat-hint data-testid="mat-hint">{{ hint }}</mat-hint>
  <mat-error
    data-testid="mat-error"
    *ngFor="let error of control?.errors | keyvalue"
    [innerHtml]="
      errorTranslationKey
        ? (errorTranslationKey + error?.key | translate: errorData[error?.key])
        : errorMessageMap[error?.key]
    "
  >
  </mat-error>
</mat-form-field>

import { createAction, props } from '@ngrx/store';

import { CompareData } from './compare.model';

function createActionTypeSentence(
  verb: string,
  status: 'OK' | 'NOK' | '' = '',
): string {
  return `[Compare API] ${verb} ${status}`;
}

const getComparisonResultAction = createAction(
  createActionTypeSentence('Get Comparison Result'),
  props<{
    clientId: string;
    referenceRoadmapId: string;
    roadmapIds: string[];
  }>(),
);

const getComparisonResultOKAction = createAction(
  createActionTypeSentence('Get Comparison Result', 'OK'),
  props<{ message: string }>(),
);

const getComparisonResultNOKAction = createAction(
  createActionTypeSentence('Get Comparison Result', 'NOK'),
  props<{
    params: {
      clientId: string;
      referenceRoadmapId: string;
      roadmapIds: string[];
    };
    message: string;
  }>(),
);

const downloadComparisonResultAction = createAction(
  createActionTypeSentence('Download Comparison Result'),
  props<{ signedUrl: string; filesize: number }>(),
);

const downloadComparisonResultOKAction = createAction(
  createActionTypeSentence('Download Comparison Result', 'OK'),
  props<{ data: CompareData; message: string }>(),
);

const downloadComparisonResultNOKAction = createAction(
  createActionTypeSentence('Download Comparison Result', 'NOK'),
  props<{
    params: { signedUrl: string; filesize: number };
    message: string;
  }>(),
);
const updateLoadedProgress = createAction(
  createActionTypeSentence('Update Loaded Progress'),
  props<{ id: string; progress }>(),
);

const resetStore = createAction(
  createActionTypeSentence('Reset Compare Store'),
);

export const CompareEntityActions = {
  getComparisonResultAction: getComparisonResultAction,
  getComparisonResultOKAction: getComparisonResultOKAction,
  getComparisonResultNOKAction: getComparisonResultNOKAction,
  downloadComparisonResultAction: downloadComparisonResultAction,
  downloadComparisonResultOKAction: downloadComparisonResultOKAction,
  downloadComparisonResultNOKAction: downloadComparisonResultNOKAction,
  updateLoadedProgressAction: updateLoadedProgress,
  resetStoreAction: resetStore,
};

<div
  class="drawer-contents"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="16px"
>
  <h1>{{ 'Client.labels.tools.drawer.title' | translate }}</h1>
  <p class="routing-crumbs">
    <span
      class="crumb"
      *ngFor="let crumb of toolBreadcrumbs$ | async; let last = last"
    >
      <span
        [ngClass]="{ last: !!last, upper: !!!last }"
        (click)="activateTool(crumb.id)"
        >{{
          'Client.labels.tools.breadcrumbs.' + crumb.labelKey | translate
        }}</span
      >
      <span class="separator" *ngIf="!last">
        <p>/</p>
      </span>
    </span>
  </p>
  <div
    class="tool-window"
    data-testid="tool-window"
    *ngIf="(currentToolRoute$ | async) !== null"
  >
    @switch((currentToolRoute$ | async)) { @case('') {
    <div class="tools" data-testid="tools-selection">
      <sso-tool-card
        style="background-color: pink"
        *ngFor="let tool of tools"
        [data]="tool"
        (opened)="activateTool($event)"
      ></sso-tool-card>
    </div>
    } @case('inputConverter') {
    <!-- TODO: Create own input converter component -->
    <form [formGroup]="convertForm">
      <div fxLayout="column" fxLayoutAlign="stretch stretch">
        <h2 class="converter-title">
          {{ 'Client.labels.tools.inputConverter.name' | translate }}
        </h2>
        <div
          class="content"
          fxLayout="column"
          fxLayoutAlign="stretch stretch"
          fxLayoutGap="8px"
        >
          <div
            class="template-button"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <a
              data-testid="download-input-converter-button"
              class="sso-clickable custom-template"
              (click)="downloadInputConverterTemplate()"
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="2px"
            >
              <mat-icon matPrefix>description</mat-icon>
              <b
                >{{
                  'Client.labels.tools.inputConverter.downloadTemplate'
                    | translate
                }}
              </b>
            </a>
          </div>
          <div
            class="tools-container"
            fxLayout="column"
            fxLayoutAlign="stretch stretch"
          >
            @switch (converterStatus$ | async) { @case ('processing') {
            <sso-progress-spinner
              mode="indeterminate"
              [text]="'Processing'"
              size="medium"
            ></sso-progress-spinner>
            } @case ('success') {
            <div
              class="conversion-success"
              fxLayout="column"
              fxLayoutAlign="center center"
              fxLayoutGap="16px"
            >
              <mat-icon class="success">check_circle</mat-icon>
              <p>
                {{
                  'Client.labels.tools.inputConverter.conversionSuccess'
                    | translate
                }}
              </p>
            </div>
            } @default { @if (hasConversionError$ | async) {
            <div
              class="conversion-failed"
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="16px"
            >
              <mat-icon class="failed"> warning </mat-icon>
              <p fxFlex>
                {{
                  'Client.labels.tools.inputConverter.conversionIssue'
                    | translate
                }}
              </p>
            </div>
            }
            <mat-form-field class="full-width" appearance="outline">
              <sso-file-upload
                [required]="true"
                [placeholder]="'Select file for import'"
                [formControl]="convertForm.controls.file"
                [acceptedExtensions]="allowedFileExtensions"
              >
              </sso-file-upload>
              <mat-icon matSuffix>attach_file</mat-icon>
              <mat-error
                *ngIf="convertForm?.controls?.file?.errors?.required"
                [innerHtml]="'Upload.messages.inputFile.required' | translate"
              >
              </mat-error>
            </mat-form-field>
            } } @if (hasConversionError$ | async) {
            <sso-input-converter-summary
              [data]="conversionLogs$ | async"
            ></sso-input-converter-summary>
            }
          </div>
        </div>
      </div>
    </form>
    } }
    <div
      class="drawer-actions"
      fxLayout="row"
      fxLayoutAlign="end center"
      fxLayoutGap="16px"
    >
      <div class="loading" fxLayout="column" fxLayoutAlign="center center">
        <sso-progress-spinner
          mode="indeterminate"
          *ngIf="uploading$ | async"
          text=""
          size="x2Small"
        ></sso-progress-spinner>
      </div>
      <button
        *ngIf="displayConvert$ | async"
        data-testid="convert-button"
        mat-flat-button
        (click)="onConvert()"
        color="primary"
        [disabled]="cannotConvert$ | async"
      >
        {{ 'Generic.labels.convert' | translate }}
      </button>
      <button
        data-testid="close-button"
        mat-button
        (click)="onClose()"
        color="primary"
        [disabled]="cannotCloseDrawer$ | async"
      >
        {{ 'Generic.labels.close' | translate }}
      </button>
    </div>
  </div>
</div>

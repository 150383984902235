import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NoClientComponent } from './no-client.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PushPipe } from '@ngrx/component';

@NgModule({
  declarations: [NoClientComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    PushPipe,
  ],
  exports: [NoClientComponent],
})
export class NoClientModule {}

<div class="container">
  <mat-accordion multi>
    <mat-expansion-panel *ngIf="!!(errors$ | async).length">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div
            class="summary-header"
            fxLayout="row"
            fxLayoutAlign="stretch stretch"
            fxLayoutGap="8px"
          >
            <mat-icon class="icon error">error</mat-icon>
            <span
              >{{
                'Client.labels.tools.inputConverter.errorsHeader' | translate
              }}
              ({{ (errors$ | async).length }})
            </span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="summary-content">
        <div class="list error" role="list">
          <table>
            <tr>
              <th class="column location">
                <span class="lbl-location">{{
                  'Client.labels.tools.inputConverter.location' | translate
                }}</span>
              </th>
              <th>
                <span class="lbl-message">{{
                  'Client.labels.tools.inputConverter.issue' | translate
                }}</span>
              </th>
            </tr>
            <tr class="list-item error" *ngFor="let error of errors$ | async">
              <td class="column location">
                <div
                  class="location-item"
                  *ngFor="let location of composeLocationArray(error.location)"
                  fxLayout="column"
                  fxLayoutAlign="stretch stretch"
                >
                  <span class="name">{{
                    'Client.labels.tools.inputConverter.locations.' +
                      location.key | translate
                  }}</span>
                  <span class="value">{{ location.value }}</span>
                </div>
              </td>
              <td>
                <span>{{ error.message }}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="!!(warnings$ | async).length">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div
            class="summary-header"
            fxLayout="row"
            fxLayoutAlign="stretch stretch"
            fxLayoutGap="8px"
          >
            <mat-icon class="icon warning">warning</mat-icon>
            <span
              >{{
                'Client.labels.tools.inputConverter.warningsHeader' | translate
              }}
              ({{ (warnings$ | async).length }})
            </span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="summary-content">
        <div class="list warn" role="list">
          <table>
            <tr>
              <th class="column location">
                <span class="lbl-location">{{
                  'Client.labels.tools.inputConverter.location' | translate
                }}</span>
              </th>
              <th>
                <span class="lbl-message">{{
                  'Client.labels.tools.inputConverter.issue' | translate
                }}</span>
              </th>
            </tr>
            <tr
              class="list-item error"
              *ngFor="let warning of warnings$ | async"
            >
              <td class="column location">
                <div
                  class="location-item"
                  *ngFor="
                    let location of composeLocationArray(warning.location)
                  "
                  fxLayout="column"
                  fxLayoutAlign="stretch stretch"
                >
                  <span class="name">{{
                    'Client.labels.tools.inputConverter.locations.' +
                      location.key | translate
                  }}</span>
                  <span class="value">{{ location.value }}</span>
                </div>
              </td>
              <td>
                <span>{{ warning.message }}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div
  class="container"
  fxLayout="column"
  fxLayoutAlign="center stretch"
  fxLayoutGap="16px"
>
  <div class="header">
    <h1>{{ 'Client.labels.filterTrajectories' | translate }}</h1>
  </div>
  <div class="description"></div>
  <div class="table">
    <sso-generic-table
      [configuration]="configuration$ | ngrxPush"
      (selectionChange)="onSelectionChange($event)"
    ></sso-generic-table>
  </div>
  <div
    class="actions"
    fxLayout="row"
    fxLayoutAlign="end stretch"
    fxLayoutGap="16px"
  >
    <button
      mat-flat-button
      type="button"
      color="primary"
      (click)="onSaveSelected()"
    >
      {{ showSaveButtonLabel$() | ngrxPush | translate }}
    </button>
  </div>
</div>

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { AlphaRequestActions } from './alpha-request.actions';
import { AlphaRequest } from './alpha-request.model';

export const alphaRequestFeatureKey = 'alphaRequest';

export interface AlphaRequestState extends EntityState<AlphaRequest> {
  loading: boolean;
  error: string;
  message: string;
  loaded: boolean;
  roadmapsLoading: boolean;
  roadmapsLoaded: boolean;
}

export const selectRequestId = (alphaRequest: AlphaRequest) =>
  alphaRequest.requestId;

export const alphaRequestAdapter: EntityAdapter<AlphaRequest> = createEntityAdapter<
  AlphaRequest
>({
  selectId: selectRequestId,
});

export const initialAlphaRequestState: AlphaRequestState = alphaRequestAdapter.getInitialState(
  {
    loading: false,
    error: undefined,
    message: undefined,
    loaded: false,
    roadmapsLoading: false,
    roadmapsLoaded: false,
  },
);

const alphaRequestReducer = createReducer(
  initialAlphaRequestState,
  on(AlphaRequestActions.getList, (state) => {
    return { ...state, loading: true, error: undefined };
  }),
  on(AlphaRequestActions.getListSuccess, (state, { data, message }) => {
    return alphaRequestAdapter.upsertMany(data, {
      ...state,
      loaded: true,
      message,
      loading: false,
    });
  }),
  on(AlphaRequestActions.getListFailure, (state, { error, message }) => {
    return { ...state, loading: false, loaded: true, error, message };
  }),
  on(AlphaRequestActions.updateRequest, (state) => {
    return { ...state, loading: true, error: undefined };
  }),
  on(AlphaRequestActions.updateSuccess, (state, { data, message }) => {
    return alphaRequestAdapter.upsertOne(
      { ...data },
      { ...state, loaded: true, message, loading: false },
    );
  }),
  on(AlphaRequestActions.updateFailure, (state, { error, message }) => {
    return { ...state, loading: false, error, message };
  }),
  on(AlphaRequestActions.getRoadmaps, (state) => {
    return { ...state, roadmapsLoaded: false, roadmapsLoading: true };
  }),
  on(AlphaRequestActions.getRoadmapsFailure, (state, { error, message }) => {
    return {
      ...state,
      roadmapsLoaded: false,
      roadmapsLoading: false,
      error,
      message,
    };
  }),
  on(AlphaRequestActions.getRoadmapsSuccess, (state, { data, roadmaps }) => {
    const alphaRequestToUpdate = state.entities?.[data.requestId];
    return alphaRequestAdapter.upsertOne(
      { ...alphaRequestToUpdate, roadmaps },
      { ...state, roadmapsLoaded: true, roadmapsLoading: false },
    );
  }),
);

export function AlphaRequestReducer(
  state: AlphaRequestState | undefined,
  action: Action,
) {
  return alphaRequestReducer(state, action);
}

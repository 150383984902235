import { CustomValidators } from 'ssotool-core/utils';
import { DialogData } from 'ssotool-shared/component/dialog';

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

const DEFAULT_FILE_EXTS = ['xls', 'xlsx'];
@Component({
  selector: 'sso-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignImportDialogComponent {
  importForm = this.formBuilder.group({
    file: ['', [Validators.required, CustomValidators.file(['xls', 'xlsx'])]],
    description: '',
  });
  allowedFileExtns = DEFAULT_FILE_EXTS;

  get isPristine() {
    return this.importForm.pristine || this.importForm.invalid;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CampaignImportDialogComponent>,
  ) {}

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    /* istanbul ignore else */
    if (this.importForm.valid) {
      this.dialogRef.close({
        description: this.importForm.controls.description.value,
        file: this.importForm.controls.file.value['files'][0],
      });
    }
  }
}

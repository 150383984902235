import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AreaChartModule } from 'ssotool-shared/modules/area-chart';
import { MultiSelectModule } from 'ssotool-shared/modules/multi-select';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TimeseriesInputModule } from '@oculus/components/timeseries-input';

import { ProfilesFormComponent } from './profiles-form.component';
import { LetModule, PushPipe } from '@ngrx/component';
import { BreadcrumbModule } from 'ssotool-app/shared/component/breadcrumb';

@NgModule({
  declarations: [ProfilesFormComponent],
  exports: [ProfilesFormComponent],
  imports: [
    CommonModule,
    AreaChartModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TimeseriesInputModule,
    MultiSelectModule,
    TranslateModule,
    NgxSkeletonLoaderModule,
    LetModule,
    PushPipe,
    BreadcrumbModule,
  ],
})
export class ProfilesFormModule {}

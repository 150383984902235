import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ClientShareInfo } from 'ssotool-app/+client/store';
import { FormFieldOption, PermissionChecker } from 'ssotool-shared/helpers';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';

export type InputIcon = Readonly<{
  icon: string;

  inline?: boolean;
  class?: string;
  click?: EventEmitter<any>;
  color?: ThemePalette;
}>;
@Component({
  selector: 'sso-share-client-table',
  templateUrl: './share-client-table.component.html',
  styleUrls: ['./share-client-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareClientTableComponent implements OnInit {
  sharedUsers$ = new BehaviorSubject<ClientShareInfo[]>([]);
  @Input() activeClient;
  @Input() permissionOptions: Array<FormFieldOption<any>>;
  @Input() set sharedUsers(userList: ClientShareInfo[]) {
    if (userList) {
      this.sharedUsers$.next(userList);
    }
  }
  @Input() set readonly(isReadOnly: boolean) {
    this.isReadOnly = isReadOnly;
    if (isReadOnly) {
      this.displayedColumns$.next(['name', 'permission']);
    } else {
      this.displayedColumns$.next(['name', 'permission', 'action']);
    }
  }
  @Output() pristine = new EventEmitter();
  isReadOnly: boolean;
  dataSource: MatTableDataSource<ClientShareInfo> = new MatTableDataSource([]);
  userListForm: FormGroup;
  searchInput: FormControl;
  displayedColumns$ = new BehaviorSubject<string[]>([]);
  searchIcon: InputIcon = {
    icon: 'search',
    inline: true,
  };

  constructor(
    private formBuilder: FormBuilder,
    private permissionChecker: PermissionChecker,
  ) {
    this.userListForm = this.formBuilder.group({
      permission: [null, Validators.required],
      search: [null],
    });

    this.searchInput = this.userListForm.get('search') as FormControl;
    this.searchInput.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((value) => this.onSearch(value));
  }

  ngOnInit(): void {
    this.sharedUsers$.subscribe((userList: ClientShareInfo[]) => {
      this.dataSource.data = userList;
    });
  }

  onSearch(value): void {
    this.dataSource.filter = value.trim().toLowerCase();
  }

  onUpdate(userId: string, role: string): void {
    const sharedUsers = this.sharedUsers$.value;
    const index = sharedUsers.findIndex((info) => info.userId === userId);
    const user = sharedUsers[index];
    sharedUsers[index] = {
      ...user,
      role,
      permissions: this.permissionChecker.getRole(role),
    };

    this.sharedUsers$.next(sharedUsers);
    this.pristine.emit(false);
  }

  onRemove(userId: string): void {
    const sharedUsers = this.sharedUsers$.value;
    const index = sharedUsers.findIndex((info) => info.userId === userId);
    sharedUsers.splice(index, 1);
    this.sharedUsers$.next(sharedUsers);
    this.pristine.emit(false);
  }

  getRole(role: string) {
    const userRole = this.permissionOptions.find(
      (option) => option.value === role,
    );
    return userRole?.name || '-';
  }
}

import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, combineLatest } from 'rxjs';
import { skipWhile, map } from 'rxjs/operators';
import { ErrorMessages } from 'ssotool-app/shared/services';
import { Module, ENTITIES_WITH_COLUMN } from './log-viewer.references';

@Injectable({
  providedIn: 'root',
})
export class LogViewerService {
  getLogMessages(
    loading$: Observable<boolean>,
    errorMessage$: Observable<ErrorMessages>,
  ): Observable<Module[]> {
    return combineLatest([loading$, errorMessage$]).pipe(
      skipWhile(([loading, logs]) => loading && !!!logs),
      map(([_, logs]) => {
        return this.generateModuleData(Object.entries(logs || {}));
      }),
    );
  }

  generateModuleData(issues): Module[] {
    return issues.map(([tableName, data]) => {
      const sortedData = data
        .slice()
        .sort((a, b) => a.location?.row - b.location?.row);
      const name = this.formatTableName(tableName);
      const dataSource = new MatTableDataSource(sortedData);
      let displayedColumns = ['message', 'field', 'row'];
      const tableNameLower = tableName.toLowerCase();
      if (
        ENTITIES_WITH_COLUMN.some((entity) => tableNameLower.includes(entity))
      ) {
        displayedColumns = ['message', 'field', 'column', 'row'];
      }

      return {
        name,
        displayedColumns,
        dataSource,
      };
    });
  }

  formatTableName(tableName: string) {
    return tableName.replace('_', ' ');
  }
}

import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import {
  filter,
  first,
  map,
  mergeMap,
  skipWhile,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import {
  CampaignImportFacadeService,
  CampaignImportFormModel,
  CampaignImportModel,
  CampaignImportTableModel,
} from 'ssotool-app/+campaign/store/import';
import { Client, ClientFacadeService } from 'ssotool-app/+client';
import { StatusMap } from 'ssotool-app/app.model';
import {
  IMPORT_STATUS,
  IMPORT_STATUS_ICON_MAP,
} from 'ssotool-app/app.references';
import { LogViewerComponent } from 'ssotool-app/shared/modules/log-viewer/log-viewer.component';
import {
  DialogComponent,
  ExecStatusChecker,
  LogData,
  LogType,
  MessageDialogComponent,
  PermissionChecker,
  SSOToolRoutePathService,
  TitleBarBreadcrumbs,
  UserStateManagerService,
} from 'ssotool-shared';

import { formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  LOCALE_ID,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { CampaignImportDialogComponent } from '../import-dialog';

@Component({
  selector: 'sso-campaign-import-page',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignImportComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  statusMap: StatusMap = IMPORT_STATUS_ICON_MAP;
  addButton = {
    name: ' Upload File',
    icon: 'upload',
  };
  displayedColumns = ['date', 'name', 'description', 'status', 'action'];

  COLUMN_DEFS = ['date', 'name', 'description', 'status', 'actions'];
  clientId: string;
  activeClient$ = this.clientFacade.selectActiveClientData$.pipe(
    skipWhile((client) => !!!client),
  );
  isAdmin$: Observable<boolean> = this.activeClient$.pipe(
    map((client) => this.permissionChecker.isAdmin(client.permissions)),
  );
  templateVersion$ = this.activeClient$.pipe(
    map((client: Client) => {
      this.clientId = client.clientId;
      return client.campaignTemplateVersion;
    }),
  );
  loading$ = this.campaignImportFacade.loading$;
  importing$ = this.campaignImportFacade.importing$;
  downloadingTemplate$ = this.campaignImportFacade.downloadingTemplate$;
  testData = [] as CampaignImportTableModel[];
  data$: Observable<CampaignImportTableModel[]> =
    this.clientFacade.activeClientId$.pipe(
      first(),
      filter((clientId) => !!clientId),
      mergeMap((clientId) =>
        this.campaignImportFacade
          .getCampaignImportList$(clientId)
          .pipe(
            map((campaignImportList: CampaignImportModel[]) =>
              campaignImportList?.map((data) => this.mapToTable(data)),
            ),
          ),
      ),
      map((data) =>
        data.sort(
          (a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf(),
        ),
      ),
    );

  ownerReferences$ = combineLatest([
    this.data$,
    this.userService.dataList$,
  ]).pipe(map(([data, _]) => this.reduceImportListToNamesKeyValue(data)));
  hasOngoingImport$ = this.data$.pipe(
    map((data) => data.some((d) => this.statusChecker.checkImport(d.status))),
  );

  datasource$ = this.data$.pipe(
    map((importList) => {
      const matDatasource = new MatTableDataSource(importList);
      matDatasource.sort = this.sort;
      matDatasource.paginator = this.paginator;
      return matDatasource;
    }),
  );

  noData$ = this.datasource$.pipe(map((source) => !source?.data?.length));

  latestSuccsessfulImport$ = new BehaviorSubject<CampaignImportTableModel>(
    null,
  );

  clientId$ = this.activeClient$.pipe(map((client) => client.clientId));

  loadingNotImporting$ = combineLatest([this.loading$, this.importing$]).pipe(
    map(([loading, importing]) => !!loading && !!!importing),
  );

  titleBreadcrumb$: Observable<TitleBarBreadcrumbs> = this.clientId$.pipe(
    map((clientId) => {
      return [
        {
          route: this.routePath.campaign(clientId),
          label: 'Campaign.labels.title',
        },
      ];
    }),
  );

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private dialog: MatDialog,
    private clientFacade: ClientFacadeService,
    private campaignImportFacade: CampaignImportFacadeService,
    private permissionChecker: PermissionChecker,
    private userService: UserStateManagerService,
    private statusChecker: ExecStatusChecker,
    private router: Router,
    public routePath: SSOToolRoutePathService,
  ) {}

  ngOnInit(): void {
    this.data$.pipe(filter((data) => !!data)).subscribe((data) => {
      for (let index = 0; index < data.length; index++) {
        const importModel = data[index];
        // get the first successful import from the list. the list is sorted
        // in descending order, so the latest successful is the first
        if (importModel.status === IMPORT_STATUS.SUCCESS) {
          this.latestSuccsessfulImport$.next(importModel);
          break;
        }
      }
    });
  }

  onImport() {
    this.dialog
      .open(CampaignImportDialogComponent, {
        data: {
          confirm: 'Import',
          close: 'Cancel',
          width: '30%',
        },
      })
      .afterClosed()
      .pipe(withLatestFrom(this.clientFacade.selectActiveClientData$))
      .subscribe(([data, client]: [CampaignImportFormModel, Client]) => {
        if (data) {
          const { description, file } = data;
          this.campaignImportFacade.importCampaign(client.clientId, {
            description,
            file,
          });
        }
      });
  }

  backToList() {
    this.clientFacade.activeClientId$
      .pipe(take(1))
      .subscribe((clientId) =>
        this.router.navigate(this.routePath.campaign(clientId)),
      );
  }

  isLogsDisabled(data: CampaignImportTableModel): boolean {
    return this.statusChecker.isStartingState(data?.status);
  }

  isErrorTableDisabled(data: CampaignImportTableModel): boolean {
    return data.status !== IMPORT_STATUS.ERROR;
  }

  onDownload(data: CampaignImportTableModel) {
    this.campaignImportFacade.downloadCampaignImportData(
      data?.clientId,
      data?.id,
    );
  }

  viewLogs(data: CampaignImportTableModel) {
    this.campaignImportFacade
      .logsLoaded$(data?.id)
      .pipe(first())
      .subscribe((isLogsLoaded) => {
        if (!isLogsLoaded) {
          this.campaignImportFacade.downloadErrorLogs(data?.clientId, data?.id);
        }
      });
    this.dialog.open<LogViewerComponent, LogData, any>(LogViewerComponent, {
      width: '60%',
      data: {
        clientId: data.clientId,
        logType: LogType.CAMPAIGN_IMPORT,
        body: { importId: data.id },
        details: data.logDetails,
        loading$: this.campaignImportFacade.importLogsLoading$(data.id),
        errorMessages$: this.campaignImportFacade.getLogs(data.id),
        hasError: !!!this.isErrorTableDisabled(data),
      },
    });
  }

  private reduceImportListToNamesKeyValue(data: any[]) {
    return data.reduce((acc, { owner }) => {
      const user = this.userService.get(owner);

      if (!user) {
        this.userService.getUserById(owner);
        return this.userService.getUserIdLoading$;
      }
      const fullName = `${user?.firstName} ${user?.lastName}`;
      acc[owner] = user?.loaded ? fullName : 'Loading name...';

      return acc;
    }, {});
  }

  private mapToTable(
    dataImport: CampaignImportModel,
  ): CampaignImportTableModel {
    const {
      importId,
      createdAt,
      description,
      owner,
      clientId,
      status,
      loading,
      logDetails,
    } = dataImport;
    return {
      id: importId,
      description,
      date: this.formatDate(createdAt),
      owner,
      clientId,
      status,
      loading,
      logDetails,
    };
  }

  formatDate(dateString: string) {
    return formatDate(dateString, 'yyyy/MM/dd HH:mm', this.locale);
  }

  onDownloadTemplate() {
    if (this.clientId) {
      this.campaignImportFacade.downloadTemplate(this.clientId);
    }
  }

  cannotBeDeleted(importModel: CampaignImportTableModel) {
    // deletable only if not the latest successful import or the status is not processing
    if (
      importModel.id === this.latestSuccsessfulImport$.value?.id ||
      this.cannotBeDeletedByStatus(importModel.status)
    ) {
      return true;
    }
    return false;
  }

  private cannotBeDeletedByStatus(status: string): boolean {
    let cannotDeleteStatuses = this.statusChecker.STARTING_STATUSES.concat(
      IMPORT_STATUS.PROCESSING,
    );

    return cannotDeleteStatuses.includes(status);
  }

  deleteImport(importModel: CampaignImportTableModel) {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: 'Delete Import',
          message: 'Are you sure to delete this import?',
          confirm: 'Delete',
          close: 'Cancel',
          disableClose: false,
          width: '250px',
          importModel,
        },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data && data.importModel) {
          this.campaignImportFacade.deleteImport(
            importModel?.clientId,
            importModel?.id,
          );
        }
      });
  }
}

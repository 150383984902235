import { filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';

import { CampaignImportActions } from './campaign-import.action';
import { CampaignImportFormModel } from './campaign-import.model';
import { CampaignImportState } from './campaign-import.reducer';
import {
  campaignImportEntities,
  downloadingTemplate,
  getCampaignImportList,
  getLogs,
  importDownloading,
  importing,
  importLoading,
  importLogsLoading,
  loaded,
  loading,
  logsLoaded,
} from './campaign-import.selector';

@Injectable()
export class CampaignImportFacadeService {
  constructor(
    private store: Store<CampaignImportState>,
    private actionSubject$: ActionsSubject,
  ) {}

  loading$ = this.store.pipe(select(loading));
  loaded$ = this.store.pipe(select(loaded));
  getCampaignImportEntities$ = this.store.pipe(select(campaignImportEntities));
  downloadingTemplate$ = this.store.pipe(select(downloadingTemplate));
  importing$ = this.store.pipe(select(importing));
  getCampaignImportList$ = (clientId: string) =>
    this.store.pipe(select(getCampaignImportList(clientId)));
  logsLoaded$ = (importId: string) =>
    this.store.pipe(select(logsLoaded(importId)));
  getLogs(importId: string) {
    return this.store.pipe(select(getLogs(importId)));
  }
  importLoading$ = (importId: string) =>
    this.store.pipe(select(importLoading(importId)));
  importLogsLoading$ = (importId: string) =>
    this.store.pipe(select(importLogsLoading(importId)));
  importDownloading$ = (importId: string) =>
    this.store.pipe(select(importDownloading(importId)));
  get(clientId: string, importId: string) {
    this.store.dispatch(CampaignImportActions.get({ clientId, importId }));
    return this.actionSubject$.pipe(
      filter((action) => action.type === CampaignImportActions.getSuccess.type),
    );
  }

  getList(clientId: string) {
    this.store.dispatch(CampaignImportActions.getList({ clientId }));
  }

  updateImportStatus(clientId: string, importId: string, status: string) {
    this.store.dispatch(
      CampaignImportActions.updateImportStatus({ clientId, importId, status }),
    );
  }

  importCampaign(clientId: string, data: CampaignImportFormModel) {
    this.store.dispatch(
      CampaignImportActions.importCampaign({ clientId, data }),
    );
  }

  downloadCampaignImportData(clientId: string, importId: string) {
    this.store.dispatch(
      CampaignImportActions.downloadCampaignImportData({ clientId, importId }),
    );
  }

  downloadTemplate(clientId: string) {
    this.store.dispatch(CampaignImportActions.downloadTemplate({ clientId }));
  }

  downloadErrorLogs(clientId: string, importId: string) {
    return this.store.dispatch(
      CampaignImportActions.downloadErrorLogs({ clientId, importId }),
    );
  }

  deleteImport(clientId: string, importId: string) {
    this.store.dispatch(
      CampaignImportActions.deleteImport({ clientId, importId }),
    );
  }

  updateImportLogDetails(
    importId: string,
    requestId: string,
    streamName: string,
  ) {
    this.store.dispatch(
      CampaignImportActions.updateImportLogDetails({
        importId,
        logDetails: { requestId, streamName },
      }),
    );
  }
}

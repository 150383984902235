<div class="import-list-container">
  <div class="import-list-table">
    <mat-table
      [dataSource]="datasource$ | async"
      matSort
      class="sso-stripe-table"
    >
      <ng-container *ngFor="let field of COLUMN_DEFS" [matColumnDef]="field">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ field | titlecase }}
        </mat-header-cell>
        <mat-cell *matCellDef="let data; let i = index" [ngSwitch]="field">
          <div *ngSwitchCase="'user'">
            <div fxLayout="column">
              <span class="small-font">{{
                (ownerReferences$ | async)[data.owner]
              }}</span>
              <span class="sso-text-gray small-font">
                {{ data.createdAt | date : 'yyyy/MM/dd hh:mm' }}</span
              >
            </div>
            <span class="sso-text-gray smaller-font">
              {{ data.description || '-' }}</span
            >
          </div>

          <div *ngSwitchCase="'status'">
            <div
              [class]="statusMap[data?.status]?.class"
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="5%"
            >
              <mat-icon
                [attr.aria-label]="statusMap[data?.status]?.label"
                *ngIf="statusMap[data?.status]?.icon"
              >
                {{ statusMap[data.status]?.icon }}
              </mat-icon>
              <span style="white-space: nowrap"
                ><strong>{{ statusMap[data.status]?.label }}</strong></span
              >
            </div>
          </div>

          <div *ngSwitchCase="'actions'">
            <div fxLayout="row" fxLayoutAlign="start center">
              <button
                data-testid="view-logs-button"
                mat-icon-button
                color="primary"
                type="button"
                (click)="viewLogs(data)"
                [disabled]="isLogsButtonDisabled(data)"
              >
                <mat-icon>notes</mat-icon>
              </button>
              <button
                data-testid="download-import-button"
                mat-icon-button
                color="primary"
                aria-label="logs"
                (click)="onDownload(data)"
                [disabled]="isButtonDisabled(data.status)"
              >
                <mat-icon>download</mat-icon>
              </button>
              <button
                mat-icon-button
                color="warn"
                aria-label="delete"
                (click)="onDelete(data)"
                [disabled]="
                  isButtonDisabled(data.status) ||
                  data.importId === (latestSuccessfulImport$ | async)?.importId
                "
              >
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="COLUMN_DEFS; sticky: true"
      ></mat-header-row>

      <mat-row *matRowDef="let row; columns: COLUMN_DEFS"></mat-row>
    </mat-table>

    <div
      *ngIf="loading$ | async; else loaded"
      fxLayout="column"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >
      <mat-spinner strokeWidth="3" [diameter]="30"></mat-spinner>
      <span class="spinner-caption">
        {{ 'Generic.messages.loadingData' | translate }}
      </span>
    </div>
    <ng-template #loaded>
      <div
        *ngIf="!(datasource$ | async).data.length"
        class="full-width no-data"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <mat-icon> history </mat-icon>
        {{ 'DataManagement.messages.noHistory' | translate }}
      </div>
    </ng-template>

    <mat-paginator
      [hidden]="!(datasource$ | async).data.length"
      pageSize="10"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'ssoTitlecase',
})
export class SSOTitleCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    return value.replace(/(?:^|\s|\/)\S/g, (txt) => {
      return txt.toUpperCase();
    });
  }
}

<div
  class="sso-banner"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  *ngIf="notLoading$ | async"
  @slideInOut
>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <mat-icon>thumb_up</mat-icon>
    <span>
      {{ 'Release.messages.newVersion' | translate: { version: version } }}
    </span>
    <a
      mat-button
      routerLink="release-notes"
      routerLinkActive="sso-quick-link-active"
      [routerLinkActiveOptions]="{ exact: true }"
      target="_blank"
      (click)="closeBanner()"
    >
      <u>
        <b>{{ 'Release.messages.navigateReleaseNotes' | translate }}</b>
      </u>
      <mat-icon inline="true">east</mat-icon>
    </a>
  </div>
  <div>
    <button mat-icon-button (click)="closeBanner()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

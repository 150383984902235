import { EnvConfig } from 'ssotool-core/config';
import { ENV_CONFIG } from 'ssotool-core/tokens';

import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

@Injectable({ providedIn: 'root' })
export class ErrorService implements ErrorHandler {
  sentryDsn: string;

  constructor(@Inject(ENV_CONFIG) environment: EnvConfig) {
    if (environment && environment.sentryDsn) {
      this.sentryDsn = environment.sentryDsn;
      Sentry.init({
        dsn: environment.sentryDsn,
        release: environment.sentryRelease,
      });
    }
  }

  handleError(error: any): void {
    if (this.sentryDsn) {
      Sentry.captureException(error.originalError || error);
    }

    if (error instanceof HttpErrorResponse) {
      return;
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { InputConverterService } from '../+data-management/components/tools-drawer/input-converter.service';
import { EntityResolverService } from './services';
import { BinDataApiService } from './stores/bindata/bindata-api.service';
import { BinDataFacadeService } from './stores/bindata/bindata-facade.service';
import { BinDataEffects } from './stores/bindata/bindata.effects';
import { BinDataEntityStore } from './stores/bindata/bindata.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      BinDataEntityStore.featureName,
      BinDataEntityStore.reducer,
    ),
    EffectsModule.forFeature(BinDataEffects),
  ],
  providers: [
    EntityResolverService,
    BinDataFacadeService,
    BinDataApiService,
    InputConverterService,
  ],
})
export class EntitiesRootModule {}

import { combineLatest } from 'rxjs';
import { first, mergeMap, tap } from 'rxjs/operators';
import { ClientFacadeService, ProfileFacadeService } from 'ssotool-app/+client';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

@Injectable()
export class CampaignResolverService implements Resolve<any> {
  constructor(
    private profileFacade: ProfileFacadeService,
    private clientFacade: ClientFacadeService,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    this.clientFacade.selectActiveClientData$
      .pipe(
        mergeMap((client) =>
          combineLatest([
            this.profileFacade.loaded$.pipe(first()),
            this.profileFacade.loading$.pipe(first()),
          ]).pipe(
            tap(([loaded, loading]) => {
              if (client && !loaded && !loading) {
                this.profileFacade.clearLocalStorage();
              }
            }),
          ),
        ),
      )
      .subscribe();
  }
}

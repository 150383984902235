import { style, transition, trigger, useAnimation } from '@angular/animations';
import { fadeIn, fadeOut } from 'ng-animate';

export const fadeInAnimation = trigger('fadeIn', [
  transition(':enter', useAnimation(fadeIn, { params: { timing: 0.3 } })),
]);

export const fadeOutAnimation = trigger('fadeOut', [
  transition(':leave', [
    style({
      position: 'fixed',
    }),
    useAnimation(fadeOut, { params: { timing: 0.3, a: 0 } }),
  ]),
]);

export const fadeAnimation = trigger('fade', [
  transition(':enter', useAnimation(fadeIn, { params: { timing: 0.3 } })),
  transition(':leave', [
    style({
      position: 'fixed',
    }),
    useAnimation(fadeOut, { params: { timing: 0.3, a: 0 } }),
  ]),
]);

import { Pipe, PipeTransform } from '@angular/core';

import { Coerce } from '../helpers';

/**
 * Pipe to get total value of an object.
 */
@Pipe({
  name: 'totalValue',
})
export class TotalValuePipe implements PipeTransform {
  /**
   * Get the total value of an object or array.
   * @param data - data that has values
   * @returns total value of an object or array
   */
  transform(
    data: Record<any, number | string> | string[] | number[] | number,
  ): number {
    if (!!Number(data)) {
      return Number(Number(data).toFixed(2));
    }

    return data
      ? Number(
          Number(
            Coerce.getObjValues(data).reduce(
              (total, curr) => Number(total) + (Number(curr) || 0),
              0,
            ),
          ).toFixed(2),
        )
      : 0;
  }
}

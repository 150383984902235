import { ClientDataEntity } from 'ssotool-app/+client';
import {
  BaseCurveEntity,
  CampaignDetails,
  CurveData,
  GeographyDetails,
  ProjectDetails,
} from 'ssotool-app/+roadmap/stores/result/result.model';
import { NAN_VALUE } from 'ssotool-app/app.references';
import { Coerce, YearlyValues } from 'ssotool-shared';

import { Injectable } from '@angular/core';

import {
  CAMPAIGN_VIEW,
  DetailDataParameters,
  GEOGRAPHY_VIEW,
} from './result-utility.model';

type DetailedCurvesRecord = Record<string, CampaignDetails | GeographyDetails>;
type CurveDataEntry = [string, BaseCurveEntity[]];

@Injectable()
export class ResultParserService {
  groupDetailedProjects(
    projects: ProjectDetails[],
    requiredFields: string[],
  ): Record<string, ProjectDetails> {
    return Coerce.toArray(requiredFields).reduce((groupedProjects, kpi) => {
      projects?.forEach((project) => {
        const key = `${project.campaignId}_${project.geoId}_${project.startDate}`;
        if (groupedProjects?.[key]) {
          groupedProjects[key][kpi] += project?.[kpi] || 0;
        } else {
          groupedProjects[key] = project;
        }
      });

      return groupedProjects;
    }, {});
  }

  groupDetailedCurves(
    curveData: CurveData,
    params: DetailDataParameters,
  ): DetailedCurvesRecord {
    const details: DetailedCurvesRecord = {};

    Object.entries(curveData).forEach((curveDatum) => {
      if (params.group === GEOGRAPHY_VIEW && params.geographyNameLevelMap) {
        this.groupDetailedCurveByGeography(details, curveDatum, params);
      } else if (params.group === CAMPAIGN_VIEW) {
        this.groupDetailedCurveByCampaign(details, curveDatum, params);
      }
    });

    return details;
  }

  private groupDetailedCurveByGeography(
    details: DetailedCurvesRecord,
    [kpi, kpiData]: CurveDataEntry,
    {
      viewLevels,
      geographyNameLevelMap,
      geographyLevelNameMap,
      level,
      geos,
    }: DetailDataParameters,
  ) {
    const selectedHid = geographyNameLevelMap[viewLevels[1]];

    switch (viewLevels?.length) {
      case 2:
        this.groupDataByCampaign(
          details,
          kpiData.filter((datum) => datum?.geoHid?.startsWith(selectedHid)),
          kpi,
          GEOGRAPHY_VIEW,
        );
        break;
      default:
        this.groupDataByGeography(
          details,
          kpiData,
          geographyLevelNameMap,
          kpi,
          level,
          geos,
        );
    }
  }

  private groupDetailedCurveByCampaign(
    details: DetailedCurvesRecord,
    [kpi, kpiData]: CurveDataEntry,
    { viewLevels, geographyHierarchyMap }: DetailDataParameters,
  ) {
    switch (viewLevels?.length) {
      case 2:
        this.groupDataByCampaign(
          details,
          kpiData.filter((datum) => datum.campaign === viewLevels[1]),
          kpi,
          GEOGRAPHY_VIEW,
        );
        break;
      default:
        this.groupDataByCampaign(details, kpiData, kpi);
        this.convertGeoToParent(details, geographyHierarchyMap);
    }
  }

  private groupDataByCampaign(
    details: DetailedCurvesRecord,
    kpiData: BaseCurveEntity[],
    kpi: string,
    additionalUniquenessKey = '',
  ) {
    kpiData.forEach((kpiDetails) => {
      const detail_key = `${kpiDetails?.campaign}_${kpiDetails?.lever}_${
        kpiDetails?.campaignCategory
      }_${kpiDetails?.process}${
        additionalUniquenessKey
          ? `_${kpiDetails?.[additionalUniquenessKey]}`
          : ''
      }`;

      if (!details?.[detail_key]) {
        details[detail_key] = {
          name: kpiDetails?.campaign,
          type: kpiDetails.lever,
          category: kpiDetails.campaignCategory,
          process: kpiDetails.process,
          geography: kpiDetails.geography,
          geoHids: [],
        };
      }

      details[detail_key].geoHids.push(kpiDetails.geoHid);

      details[detail_key][kpi] = this.aggregateValues(
        details[detail_key]?.[kpi],
        kpiDetails.values,
      );
    });
  }

  private groupDataByGeography(
    details: DetailedCurvesRecord,
    kpiData: BaseCurveEntity[],
    hierarchyLevels: Record<string, string>,
    kpi: string,
    level: string,
    geographies: ClientDataEntity,
  ) {
    kpiData.forEach((kpiDetails) => {
      Coerce.getObjKeys(hierarchyLevels).forEach((hId) => {
        if (kpiDetails?.geoHid?.startsWith(hId)) {
          const geoParent = Coerce.getObjValues(geographies).find(
            (geo) => geo.name === hierarchyLevels[hId],
          ).parentName;
          const detail_key = `${hId}_${geoParent}`;

          if (!details?.[detail_key]) {
            details[detail_key] = {
              name: hierarchyLevels[hId],
              type: level,
              process: kpiDetails.process,
              geoParent,
            };
          }

          details[detail_key][kpi] = this.aggregateValues(
            details[detail_key]?.[kpi],
            kpiDetails?.values,
          );
        }
      });
    }, {});
  }

  private aggregateValues(
    existingValues: YearlyValues,
    nextValues: YearlyValues,
  ): YearlyValues {
    return Object.entries(nextValues).reduce<YearlyValues>(
      (yearlyValues, [year, value]) => {
        value = value === NAN_VALUE ? '0' : value;

        if (existingValues && existingValues?.[year]) {
          yearlyValues[year] = (
            Number(existingValues?.[year]) + (Number(value) || 0)
          ).toString();
        } else {
          yearlyValues[year] = value;
        }
        return yearlyValues;
      },
      {},
    );
  }

  private convertGeoToParent(
    details: DetailedCurvesRecord,
    hierarchyMap: Record<string, string>,
  ) {
    Object.entries(details).forEach(([key, detail]) => {
      const hidParts: string[][] = detail?.geoHids?.reduce((acc, hid) => {
        hid?.split('-').forEach((value, index) => {
          if (!acc[index]) {
            acc[index] = [value];
          } else {
            acc[index].push(value);
          }
        });
        return acc;
      }, []);

      let parentHid: string;

      hidParts.every((part) => {
        const isPartEqual = new Set(part).size === 1;

        if (isPartEqual) {
          if (!parentHid) {
            parentHid = part[0];
          } else {
            parentHid += `-${part[0]}`;
          }

          return isPartEqual;
        } else {
          return false;
        }
      });

      if (parentHid) {
        details[key].geography = hierarchyMap[parentHid];
      }
    });
  }
}

import { from, of } from 'rxjs';
import {
  catchError,
  first,
  map,
  mapTo,
  mergeMap,
  toArray,
} from 'rxjs/operators';
import { doNothing } from 'ssotool-core/utils';

import { Actions, ofType } from '@ngrx/effects';

import { BasePortfolioFacadeService } from './portfolio-facade.service';
import { PortfolioEntityActions } from './portfolio.actions';
import { BasePortfolioAPIService } from './portfolio.service';

export abstract class BasePortfolioEntityEffects {
  private actions: Actions;
  private service: BasePortfolioAPIService;
  private facade: BasePortfolioFacadeService;

  constructor(
    actions$: Actions,
    service: BasePortfolioAPIService,
    facade: BasePortfolioFacadeService,
  ) {
    this.actions = actions$;
    this.service = service;
    this.facade = facade;
  }

  getSideEffect = () => {
    const actions = PortfolioEntityActions(this.getAPIName());
    return this.actions.pipe(
      ofType(actions.getAction),
      mergeMap((action) =>
        this.service.getRequest(action.clientId, action.id).pipe(
          map((data) => actions.getOKAction({ data })),
          catchError(() => of(actions.getNOKAction({ id: action.id }))),
        ),
      ),
    );
  };

  getOKSideEffect = () => {
    const actions = PortfolioEntityActions(this.getAPIName());
    return this.actions.pipe(ofType(actions.getOKAction));
  };
  getNokSideEffect = () => {
    const actions = PortfolioEntityActions(this.getAPIName());
    return this.actions.pipe(ofType(actions.getNOKAction));
  };

  getAllSideEffect = () => {
    const actions = PortfolioEntityActions(this.getAPIName());
    return this.actions.pipe(
      ofType(actions.getAllAction),
      mergeMap((action) =>
        this.service
          .getAllRequest(action.clientId)
          .pipe(
            map((ids) =>
              actions.getAllOKAction({ data: ids, clientId: action.clientId }),
            ),
          ),
      ),
      catchError((error) => of(actions.getAllNOKAction())),
    );
  };

  getAllOkSideEffect = () => {
    const actions = PortfolioEntityActions(this.getAPIName());
    return this.actions.pipe(
      ofType(actions.getAllOKAction),
      mergeMap((action) =>
        this.facade.getDataListStream().pipe(
          first(),
          mergeMap((dataList) =>
            from(
              (action.data || [])
                .filter((data: any) =>
                  dataList && dataList[data.id] && dataList[data.id].loaded
                    ? doNothing()
                    : data,
                )
                .map((data) => data),
            ).pipe(
              mergeMap((_c: any) => this.facade.get(action.clientId, _c)),
              toArray(),
            ),
          ),
          mapTo(action),
        ),
      ),
    );
  };

  getAllNokSideEffect = () => {
    const actions = PortfolioEntityActions(this.getAPIName());
    return this.actions.pipe(ofType(actions.getAllNOKAction));
  };

  abstract getAPIName(): string;
}

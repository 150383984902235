import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

interface DataModel {
  readonly name: string;
  readonly value: string;
}

@Component({
  selector: 'sso-view-dataset',
  templateUrl: './view-dataset.component.html',
  styleUrls: ['./view-dataset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewDataSetComponent {
  private _dataset: DataModel[];
  private _dataType: string;
  dateFormat = 'MMM d';

  @Input() set dataset(data: DataModel[]) {
    if (data) {
      this._dataset = data;
    }
  }
  @Input() set dataType(dataType: string) {
    if (dataType) {
      this._dataType = dataType;
    }

    this.generateDateFormat(dataType);
  }

  get dataType() {
    return this._dataType;
  }

  get dataset() {
    return this._dataset;
  }

  constructor() {}

  generateDateFormat(dataType: string): void {
    switch (dataType) {
      case 'Yearly':
        this.dateFormat = 'yyyy';
        break;
      case 'Monthly':
        this.dateFormat = 'MMM';
        break;
      default:
        this.dateFormat = 'MMM d';
        break;
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { ChipsPanelComponent } from './chips-panel.component';

@NgModule({
  declarations: [ChipsPanelComponent],
  imports: [CommonModule, MatChipsModule, MatIconModule],
  exports: [ChipsPanelComponent],
})
export class ChipsPanelModule {}

import { createAction, props } from '@ngrx/store';

import { CampaignEntity, SelectedCampaigns } from './campaign.model';

// TODO: Update model
const create = createAction(
  '[Campaign Form: Create] Create campaign ',
  props<{ clientId: string; data: any }>(),
);

// TODO: Update model
const createSuccess = createAction(
  '[Campaign create API] Create a campaign success',
  props<{ data: any; message: string }>(),
);

// TODO: Update model
const createFailure = createAction(
  '[Campaign create API] Create a campaign failure',
  props<{ clientId: string; data: any; error: any; message: string }>(),
);

// TODO: Update model
const update = createAction(
  '[Campaign Form: Update] Update campaign info',
  props<{ clientId: string; id: string; data: any }>(),
);

// TODO: Update model
const updateSuccess = createAction(
  '[Campaign update API] Update campaign info success',
  props<{ data: any; message: string }>(),
);

// TODO: update model
const updateFailure = createAction(
  '[Campaign update API] Update campaign failure',
  props<{ data: any; error: any; message: string }>(),
);

const get = createAction(
  '[Campaign Page] Get a campaign',
  props<{ clientId: string; id: string; campaignType: string }>(),
);

// TODO: Update model
const getSuccess = createAction(
  '[Campaign get API] Get campaign success',
  props<{ data: any; message: string }>(),
);

const getFailure = createAction(
  '[Campaign get API] Get campaign failure',
  props<{ data: any; error: any; message: string }>(),
);

const getList = createAction(
  '[Campaign List Page], Get campaigns',
  props<{ clientId: string }>(),
);

const getListSuccess = createAction(
  '[Campaign getlist API] Get campaigns success',
  props<{ clientId: string; data: CampaignEntity[]; message: string }>(),
);

const getListFailure = createAction(
  '[Campaign API Get list failure',
  props<{ data: any; error: any; message: string }>(),
);

const clearList = createAction('Clear Campaign List');

const deleteCampaign = createAction(
  '[Campaign List Page], Delete campaign',
  props<{ clientId: string; id: string; campaignType: string }>(),
);

const deleteCampaignSuccess = createAction(
  '[Campaign delete API] Delete campaign success',
  props<{ data: any; message: string }>(),
);

const deleteCampaignFailure = createAction(
  '[Campaign delete API Delete failure',
  props<{ data: any; error: any; message: string }>(),
);

const deleteCampaigns = createAction(
  '[Campaign List Page], Delete multiple campaign',
  props<{ clientId: string; campaigns: SelectedCampaigns }>(),
);

const deleteCampaignsSuccess = createAction(
  '[Campaign delete API] Delete multiple campaign success',
  props<{ clientId: string; data: any; message: string }>(),
);

const deleteCampaignsFailure = createAction(
  '[Campaign delete API] Delete multiple campaigns failure',
  props<{ data: any; error: any; message: string }>(),
);

const batchDuplicateCampaigns = createAction(
  '[Campaign List Page], Duplicate campaigns',
  props<{ clientId: string; campaigns: SelectedCampaigns }>(),
);

const batchDuplicateCampaignsSuccess = createAction(
  '[Campaign duplicate API] Duplicate campaigns success',
  props<{ clientId: string; data: any; message: string }>(),
);

const batchDuplicateCampaignsFailure = createAction(
  '[Campaign duplicate API] Duplicate failure',
  props<{ data: any; error: any; message: string }>(),
);

const populateWithLibrary = createAction(
  '[Campaign List Page], Populate with Library',
  props<{ clientId: string }>(),
);

const populateWithLibrarySuccess = createAction(
  '[Campaign populate API] Populate with library success',
  props<{ clientId: string; data: any; message: string }>(),
);

const populateWithLibraryFailure = createAction(
  '[Campaign populate API] Populate with library failure',
  props<{ data: any; error: any; message: string }>(),
);

const resetIndividualCampaignLoadedState = createAction(
  '[Campaign reset API] Reset campaigns loaded state',
);

export const CampaignActions = {
  create,
  createSuccess,
  createFailure,
  update,
  updateSuccess,
  updateFailure,
  get,
  getSuccess,
  getFailure,
  getList,
  getListSuccess,
  getListFailure,
  clearList,
  deleteCampaign,
  deleteCampaignSuccess,
  deleteCampaignFailure,
  deleteCampaigns,
  deleteCampaignsSuccess,
  deleteCampaignsFailure,
  populateWithLibrary,
  populateWithLibrarySuccess,
  populateWithLibraryFailure,
  batchDuplicateCampaigns,
  batchDuplicateCampaignsSuccess,
  batchDuplicateCampaignsFailure,
  resetIndividualCampaignLoadedState,
};

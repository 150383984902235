import { Flag, KPI_PERCENTAGE_DEFINITIONS } from 'ssotool-app/app.references';

/**
 * Gets the KPI percentage definition to define the color class and icon (up or down direction).
 * @param flag - the KPI flag if 'Positive' or 'Negative'
 * @param percentage - the percentage value of the KPI
 * @returns the KPI percentage definition
 */
export const getKPIClassDefinition = (flag: Flag, percentage: number) => {
  if (!flag) {
    return undefined;
  }

  return KPI_PERCENTAGE_DEFINITIONS[flag]?.[Math.sign(Number(percentage) || 0)];
};

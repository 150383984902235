import { BehaviorSubject } from 'rxjs';
import { ClickMode } from 'ssotool-app/shared/directives/selection-emitter';
import { SelectionEventData } from 'ssotool-app/shared/services/multiselection';

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { SelectionItem } from '@oculus/utils/click-selection';
import { GanttItem } from '../gantt.models';

@Component({
  selector: 'sso-gantt-name',
  templateUrl: './gantt-name.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class GanttNameComponent implements SelectionItem {
  @Input() ganttItem: GanttItem;
  @Input() active = false;
  @Output() selectClick = new EventEmitter<SelectionEventData>();

  active$ = new BehaviorSubject<boolean>(false);

  constructor(private host: ElementRef) {}

  getUniqueId() {
    return this.ganttItem.id;
  }

  getSelectionElement() {
    return this.host.nativeElement;
  }

  hasDisabledOverlay() {
    return !this.ganttItem?.isShown && this.ganttItem?.isResultsOnly;
  }

  isItemActive() {
    return this.active$.value || this.active;
  }

  setItemActive() {
    this.active$.next(true);
    this.active = true;
  }

  setItemInactive() {
    this.active$.next(false);
    this.active = false;
  }

  onSelectClick(mode: ClickMode) {
    this.selectClick.emit({
      mode,
      id: this.getUniqueId(),
    });
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BIN_DATA_FEATURE_NAME, BindataState } from './bindata.reducer';

export const bindatataFeatureState = createFeatureSelector<BindataState>(
  BIN_DATA_FEATURE_NAME,
);

export const loaded = createSelector(
  bindatataFeatureState,
  (state) => state.loaded,
);

import { combineLatest, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  first,
  map,
  mergeMap,
  skipWhile,
  tap,
} from 'rxjs/operators';
import {
  generateYearlyArray,
  Granularity,
  GRANULARITY_DATE_ARRAY_MAP,
  GRANULARITY_TICKFORMAT,
} from 'ssotool-app/app.references';
import { generateEndpoint } from 'ssotool-core/utils';
import { Coerce, download } from 'ssotool-shared';
import {
  ProfileDetailsForm,
  ProfileStatus,
} from 'ssotool-shared/modules/profiles-form';
import { processDownloadedData } from 'ssotool-shared/services';
import { ConfigService } from 'ssotool-shared/services/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ClientFacadeService } from '../client.facade.service';
import { ClientDataEntity } from '../client.model';
import { ProfileLocalStorageService } from './profile-local-storage.service';
import {
  BackendProfile,
  BackendProfileDetails,
  BackendProfileUpdatePayload,
  Profile,
  Profiles,
} from './profile.model';

/**
 * TODO: Integration of get profiles is a bad practice.
 * The key {profileId}_{geoId} should not be created on the backend.
 * */

@Injectable()
export class ProfileService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private clientFacade: ClientFacadeService,
    private localStorage: ProfileLocalStorageService,
  ) {}

  getProfiles(clientId: string) {
    return this.http
      .get<any>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.profiles.download,
          clientId,
        ),
        {
          params: {
            downloadType: 'volumes',
          },
        },
      )
      .pipe(
        mergeMap((profilesDataSignedUrl: any) => {
          if (!profilesDataSignedUrl) {
            return of({ clientId, profiles: undefined });
          }
          return this.http
            .get(profilesDataSignedUrl.signedUrl, {
              reportProgress: true,
              observe: 'events',
              responseType: 'blob',
            })
            .pipe(
              download(),
              processDownloadedData<string>(),
              mergeMap((data: string) =>
                this.clientFacade.dataLoaded$(clientId).pipe(
                  skipWhile((loaded) => !loaded),
                  mergeMap(() =>
                    combineLatest([
                      this.clientFacade.selectCompanies$(clientId),
                      this.clientFacade.selectGeos$(clientId),
                    ]).pipe(
                      first(),
                      mergeMap(([companies, geos]) => {
                        const jsonData = JSON.parse(data);
                        this.localStorage.addProfiles(
                          this.mapToFrontEnd(jsonData, geos, companies),
                        );
                        return of({});
                      }),
                    ),
                  ),
                ),
              ),
            );
        }),
      );
  }

  /**
   * Fetches and stores a single site profile from the back-end to local storage
   * @param clientId - client uuid
   * @param profileId - campaign id
   * @param geoId - site id
   */
  getSiteProfile(
    clientId: string,
    profileId: string,
    geoId: string,
    force: boolean,
  ) {
    return this.checkProfileIfExisting(profileId, geoId).pipe(
      mergeMap((res) => {
        if (res && !force) {
          return of({});
        } else {
          return this.http
            .get(
              generateEndpoint(
                this.config.api.baseUrl,
                this.config.api.endpoints.profiles.getSiteProfile,
                clientId,
                profileId,
                geoId,
              ),
            )
            .pipe(
              mergeMap((response: Record<string, BackendProfileDetails>) =>
                this.clientFacade.dataLoaded$(clientId).pipe(
                  skipWhile((loaded) => !loaded),
                  mergeMap(() =>
                    combineLatest([
                      this.clientFacade.selectCompanies$(clientId),
                      this.clientFacade.selectGeos$(clientId),
                    ]).pipe(
                      first(),
                      mergeMap(([companies, geos]) => {
                        this.localStorage.addSiteProfile(
                          `${profileId}_${geoId}`,
                          this.mapToFrontendSingle(response, geos, companies),
                        );
                        return of({});
                      }),
                    ),
                  ),
                ),
              ),
            );
        }
      }),
    );
  }

  upsertProfile(
    data: ProfileDetailsForm,
    clientId: string,
    profileId: string,
    geoId: string,
  ) {
    if (!data) {
      return of({});
    }
    return this.http
      .post(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.profiles.upsert,
          clientId,
          profileId,
          geoId,
        ),
        this.mapToBackendSingle(data),
      )
      .pipe(
        mergeMap(() =>
          this.clientFacade.dataLoaded$(clientId).pipe(
            skipWhile((loaded) => !loaded),
            mergeMap(() =>
              combineLatest([
                this.clientFacade.selectCompanies$(clientId),
                this.clientFacade.selectGeos$(clientId),
              ]).pipe(
                first(),
                mergeMap(([companies, geos]) => {
                  this.localStorage.upsertProfile(
                    `${profileId}_${geoId}`,
                    this.mapToFrontendSingle(
                      {
                        [`${profileId}_${geoId}`]:
                          this.mapToBackendSingle(data),
                      },
                      geos,
                      companies,
                    ),
                  );
                  return of({});
                }),
              ),
            ),
          ),
        ),
        map((response) => response as string),
        catchError((error) => throwError(error)),
      );
  }

  deleteProfile(clientId: string, profileId: string, geoId: string) {
    return this.http
      .delete(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.profiles.delete,
          clientId,
          profileId,
          geoId,
        ),
      )
      .pipe(
        tap(() => this.localStorage.deleteProfile(`${profileId}_${geoId}`)),
        catchError((error) => throwError(error)),
      );
  }

  mapToFrontEnd(
    beProfiles: BackendProfile,
    geoIdNameMap: Record<string, any>,
    companyIdNameMap: Record<string, any>,
  ): Profiles[] {
    if (!beProfiles) {
      return [];
    }
    let allProfiles: Profiles[] = [];
    Object.entries(beProfiles).forEach(([profileId, profileValues]) => {
      // iterate list of profile
      const profiles = [];
      allProfiles.push({
        profileId,
        profiles,
      });
      profileValues?.forEach((profile) => {
        const site = geoIdNameMap?.[profile.geoId]?.name;
        const company = companyIdNameMap?.[profile.companyId]?.name;
        const granularity = profile.granularity;
        const profileType = profile.type;
        const tickformat = GRANULARITY_TICKFORMAT[granularity];
        // get first set of profiles SSO-4241
        if (profile.value) {
          const first_profile = profile.value[0];
          const startYear = first_profile.fromYear;
          const endYear = first_profile.toYear;
          const load = first_profile.values;

          profiles.push({
            siteId: profile?.geoId,
            site,
            companyId: profile?.companyId,
            company,
            granularity,
            tickformat,
            profileType,
            startYear,
            endYear,
            values: this.generateSeries(load, granularity, startYear, endYear),
          } as Profile);
        }
      });
    });
    return allProfiles;
  }

  mapToBackEnd(data: ProfileDetailsForm[]): BackendProfileUpdatePayload {
    let updateList: BackendProfileDetails[] = [];
    let deleteList: string[] = [];

    data?.forEach((siteProfile) => {
      if (siteProfile.status === ProfileStatus.MODIFIED) {
        updateList.push({
          granularity: siteProfile.granularity,
          geoId: siteProfile.siteId,
          companyId: siteProfile.companyId,
          type: 'campaign',
          value: [
            {
              fromYear: Number(siteProfile.startYear),
              toYear: Number(siteProfile.endYear),
              values: (siteProfile.granularity === Granularity.YEARLY
                ? [Coerce.getObjValues(siteProfile.values)[0]]
                : Coerce.getObjValues(siteProfile.values)
              ).map((v) => (v || 0).toString()),
            },
          ],
        });
      } else if (
        siteProfile.status === ProfileStatus.DELETED &&
        !siteProfile.isNew
      ) {
        deleteList.push(siteProfile.siteId);
      }
    });
    return {
      update: updateList,
      delete: deleteList,
    };
  }

  mapToFrontendSingle(
    beProfile: Record<string, BackendProfileDetails>,
    geoIdNameMap: ClientDataEntity<string>,
    companyIdNameMap: Record<string, any>,
  ): Profiles {
    return this.mapToFrontEnd(
      Object.entries(beProfile).reduce((acc, [id, details]) => {
        acc[id] = [details];
        return acc;
      }, {}),
      geoIdNameMap,
      companyIdNameMap,
    )?.[0];
  }

  mapToBackendSingle(data: ProfileDetailsForm): BackendProfileDetails {
    return this.mapToBackEnd([data]).update?.[0];
  }

  generateSeries(
    values: string[],
    granularity: string,
    startYear: number,
    endYear: number,
  ): Record<string, string> {
    if (!values) {
      return {};
    }

    if (granularity === Granularity.YEARLY) {
      return generateYearlyArray(values, startYear, endYear);
    } else {
      const keyReference: string[] = this.getKeyReference(granularity);
      return values.reduce((acc, value, index) => {
        acc[keyReference[index]] = value;
        return acc;
      }, {});
    }
  }

  private getKeyReference(granularity: string): string[] {
    return Coerce.toArray(GRANULARITY_DATE_ARRAY_MAP[granularity]);
  }

  checkProfileIfExisting(
    profileId: string,
    geoId: string,
  ): Observable<boolean> {
    return this.localStorage
      .getProfilesByIdStream(`${profileId}_${geoId}`)
      .pipe(
        first(),
        map((profiles) => Coerce.toArray(profiles).length > 0),
      );
  }
}

<div class="container">
  <h1 class="mat-headline-6" mat-dialog-title>
    {{ 'Logs.labels.viewLogs' | translate }}
  </h1>
  <sso-tab-group
    [selections]="logTabDefinitions"
    color="primary"
    [formControl]="logTabControl"
  >
  </sso-tab-group>
  <div class="mat-body" mat-dialog-content #logContent>
    <ng-container *ngIf="isViewingRealTimeLogs(); else errorContainer">
      <div class="no-logs" *ngIf="!requestId; else logContainer">
        {{ 'Logs.messages.oldLogs' | translate }}
      </div>
      <ng-template #logContainer>
        <div
          *ngIf="noLogs$ | async; else logs"
          fxLayoutGap="10px"
          class="empty-logs"
        >
          <sso-spinner
            [hidden]="(timer$ | async) === 0"
            [caption]="'Logs.messages.getLogs' | translate"
          ></sso-spinner>
          <span [hidden]="(timer$ | async) > 0">{{
            'Logs.messages.noLogs' | translate
          }}</span>
        </div>
        <ng-template #logs>
          <cdk-virtual-scroll-viewport itemSize="60">
            <div
              class="log-header"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="20px"
            >
              <span fxFlex="150px" class="log-timestamp">{{
                'Logs.labels.timestampTitle' | translate
              }}</span>
              <span class="log-message">{{
                'Logs.labels.messageTitle' | translate
              }}</span>
            </div>
            <div
              class="log-content"
              fxLayout="column"
              fxLayoutAlign="start start"
            >
              <div
                class="log-container"
                *cdkVirtualFor="let log of logs$ | async; let index = index"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <div
                  fxFlex="150px"
                  class="log-timestamp"
                  fxLayout="column"
                  fxLayoutAlign="center start"
                >
                  <span>{{ log.timestamp | date: 'mediumDate' }}</span>
                  <span class="sso-text-gray sso-text-smaller">{{
                    log.timestamp | date: 'mediumTime'
                  }}</span>
                </div>
                <div
                  [attr.data-testid]="'log-message-' + index"
                  class="log-message"
                  data-testid="log-message"
                >
                  {{ log.message | strip: logRegex }}
                </div>
              </div>
            </div>
          </cdk-virtual-scroll-viewport>
        </ng-template>
      </ng-template>
    </ng-container>
    <ng-template #errorContainer>
      <div
        *ngIf="loading$ | async; else loaded"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        class="spinner-container"
        data-testid="error-loader"
      >
        <mat-spinner strokeWidth="3" [diameter]="40"></mat-spinner>
        <span class="spinner-caption">
          {{ 'Generic.messages.loadingData' | translate }}
        </span>
      </div>
      <ng-template #loaded>
        <div class="mat-dialog-content" data-testid="loaded-error-container">
          <mat-accordion multi #accordion="matAccordion">
            <mat-expansion-panel
              expanded
              *ngFor="let module of issues | async; let i = index"
              class="mb-3"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ formatTableName(module.name) }}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="panel-content">
                <mat-table
                  [dataSource]="module.dataSource"
                  class="sso-stripe-table mb-1"
                >
                  <!-- Message Column -->
                  <ng-container matColumnDef="message">
                    <mat-header-cell *matHeaderCellDef>{{
                      'Logs.headers.message' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxLayoutGap="1%"
                      >
                        <mat-icon [class]="element.type.toLowerCase()">{{
                          element.type.toLowerCase()
                        }}</mat-icon>
                        {{ element.message }}
                      </div>
                    </mat-cell>
                  </ng-container>

                  <!-- Field Column -->
                  <ng-container matColumnDef="field">
                    <mat-header-cell *matHeaderCellDef>{{
                      'Logs.headers.field' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{
                      element?.location?.field
                    }}</mat-cell>
                  </ng-container>

                  <!-- Cols Column -->
                  <ng-container matColumnDef="column">
                    <mat-header-cell *matHeaderCellDef class="text-center">{{
                      'Logs.headers.column' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="text-center">{{
                      element?.location?.column
                    }}</mat-cell>
                  </ng-container>

                  <!-- Row Column -->
                  <ng-container matColumnDef="row">
                    <mat-header-cell *matHeaderCellDef class="text-center">
                      {{ 'Logs.headers.row' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="text-center">{{
                      element?.location?.row
                    }}</mat-cell>
                  </ng-container>

                  <mat-header-row
                    *matHeaderRowDef="module.displayedColumns"
                    sticky
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: module.displayedColumns"
                  ></mat-row>
                </mat-table>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </ng-template>
    </ng-template>
  </div>
  <div mat-dialog-actions fxLayoutAlign="end" style="padding-top: 30px">
    <button
      data-testid="download-button"
      mat-flat-button
      color="primary"
      (click)="onDownload()"
      [class.spinner]="downloading$ | async"
      [disabled]="(downloading$ | async) || (noLogs$ | async)"
      [hidden]="!isViewingRealTimeLogs()"
    >
      {{ 'Logs.labels.downloadLogs' | translate }}
    </button>
    <button
      mat-flat-button
      color=""
      mat-dialog-close
      data-testid="close-log-button"
    >
      {{ 'Generic.labels.close' | translate }}
    </button>
  </div>
</div>

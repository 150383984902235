import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ChartLegendsModule } from '@oculus/components/chart-legends';
import { SankeyDiagramModule } from '@oculus/components/sankey-diagram';

import { SankeyChartComponent } from './sankey-chart.component';

@NgModule({
  declarations: [SankeyChartComponent],
  imports: [
    CommonModule,
    SankeyDiagramModule,
    ChartLegendsModule,
    TranslateModule,
  ],
  exports: [SankeyChartComponent],
})
export class SankeyChartModule {}

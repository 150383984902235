<div class="sso-page">
  <div
    class="warning-message"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="12px"
    *ngIf="isIneligibleForComputation$ | async"
  >
    <mat-icon matPrefix>warning_amber</mat-icon>
    <span>{{ 'Entities.messages.inEligibleForComputation' | translate }} </span>
  </div>
  <div fxLayout="column" fxLayoutGap="12px">
    <sso-title-bar
      [titlePage]="'Entities.labels.title' | translate"
      icon="equalizer"
      class="sso-title-bar"
    >
    </sso-title-bar>
    @if(loading$ | async) {
    <sso-spinner caption="Loading client data"></sso-spinner>
    } @else { @if(showTabs$| async) {
    <div class="full-width multi-level-tabs">
      <sso-tab-group
        class="tabs-primary"
        [selections]="mainEntitiesTabs"
        color="primary"
        [formControl]="selectedMainTabControl"
        [noActive]="isDataImportRoute$ | async"
      >
      </sso-tab-group>
      <sso-tab-group
        class="tabs-secondary"
        *ngIf="isSecondaryTabsDisplayed | async"
        [selections]="secondaryEntitiesTabs"
        color="primary"
        [formControl]="selectedSecondaryTabControl"
        [noActive]="isDataImportRoute$ | async"
      >
      </sso-tab-group>
    </div>
    }
    <div fxLayout="row" fxLayoutGap="16px">
      <div class="entities-page-content" fxFlex>
        <router-outlet></router-outlet>
      </div>
    </div>
    }
  </div>
</div>

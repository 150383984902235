import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';

import { TabGroupComponent } from './tab-group.component';

@NgModule({
  declarations: [TabGroupComponent],
  imports: [CommonModule, MatTabsModule, FlexLayoutModule, MatIconModule],
  exports: [TabGroupComponent],
})
export class TabGroupModule {}

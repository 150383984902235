import { forkJoin, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { SignedUrl } from 'ssotool-app/app.model';
import { generateEndpoint } from 'ssotool-core/utils';
import { download, processDownloadedData } from 'ssotool-shared/services';
import { ConfigService } from 'ssotool-shared/services/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FlowsLocalStorageService } from '../flows-local-storage/flows-local-storage.service';
import {
  Flows,
  FlowsData,
  FlowsType,
} from '../flows-local-storage/flows.model';

@Injectable()
export class BinDataApiService {
  constructor(
    private httpClient: HttpClient,
    private config: ConfigService,
    private flowLocalStorage: FlowsLocalStorageService,
  ) {}

  downloadAllBindata(clientId: string) {
    return forkJoin([
      this.downloadBindata(clientId, FlowsType.TIMESERIES),
      this.downloadBindata(clientId, FlowsType.FLOWVOLUME),
      this.downloadBindata(clientId, FlowsType.FLOWPRICE),
    ]);
  }

  private downloadBindata(clientId: string, location: FlowsType) {
    return this.httpClient
      .get(this.makeGetBinEndpoint(clientId, location))
      .pipe(
        mergeMap((bindataSignedUrl: SignedUrl) =>
          this.httpClient
            .get(bindataSignedUrl.signedUrl, {
              reportProgress: true,
              observe: 'events',
              responseType: 'blob',
            })
            .pipe(
              download(),
              processDownloadedData<string>(),
              mergeMap((data: string) => {
                const binData: Record<string, FlowsData> = JSON.parse(data);

                return this.flowLocalStorage.addFlows(
                  location,
                  this.mapBinToFrontend(binData),
                );
              }),
              map(() => of({ clientId, location })),
            ),
        ),
      );
  }

  mapBinToFrontend(data: Record<string, FlowsData>): Array<Flows> {
    return Object.entries<FlowsData>(data).map<Flows>(([entityId, data]) => ({
      entityId,
      data,
    }));
  }

  makeGetBinEndpoint(clientId: string, location: string): string {
    const { baseUrl, endpoints } = this.config.api;

    return generateEndpoint(
      baseUrl,
      endpoints.binstore.downloadSignedUrl,
      clientId,
      location,
    );
  }
}

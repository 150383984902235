<div fxLayoutGap="10px" fxLayout="row">
  <mat-icon aria-hidden="false">insert_chart_outlined</mat-icon>
  <h2 class="mat-headline-6" mat-dialog-title>
    {{ data?.name | titlecase }} Time Series
  </h2>
</div>
<sso-tabbed-area-chart
  [yearlyTabs]="yearlyTabs"
  [seriesDataList]="yearlyChartValues$ | async"
  (onTabChange)="onTabChange($event)"
  [loading]="loading$ | async"
></sso-tabbed-area-chart>
<div mat-dialog-actions fxLayout="row">
  <button (click)="onClose()" color="" mat-flat-button type="button">
    Close
  </button>
</div>

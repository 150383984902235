import { BehaviorSubject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeMap,
} from 'rxjs/operators';
import { DrawerMode } from 'ssotool-app/+campaign/components/drawer/base-drawer.model';
import { ClientFacadeService } from 'ssotool-app/+client/store/client.facade.service';
import { ProfileFacadeService } from 'ssotool-app/+client/store/profile-local-storage/profile.facade.service';
import { Profile } from 'ssotool-app/+client/store/profile-local-storage/profile.model';
import {
  DEFAULT_END_YEAR,
  DEFAULT_START_YEAR,
  Granularity,
  GRANULARITY_COUNT,
  GRANULARITY_TICKFORMAT,
  INITIAL_DATE,
} from 'ssotool-app/app.references';
import {
  ProfileDetails,
  SiteDetail,
} from 'ssotool-app/shared/modules/profile-input';
import { Coerce, distinctObject, YearlyValues } from 'ssotool-shared/helpers';

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ProfileDetailsForm, ProfileStatus } from './profiles-form.model';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sso-profiles-form',
  templateUrl: './profiles-form.component.html',
  styleUrls: ['./profiles-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ProfilesFormComponent,
      multi: true,
    },
  ],
})
export class ProfilesFormComponent implements ControlValueAccessor, OnInit {
  private _selectedSite = new BehaviorSubject<SiteDetail>(null);
  private _details = new BehaviorSubject<ProfileDetails>(null);
  private _sites = new BehaviorSubject<Record<string, string>>({});
  private _profiles = new BehaviorSubject<Profile[]>(null);
  private _onChange = (value: ProfileDetailsForm) => {};
  private _onTouch = () => {};
  private _mode = new BehaviorSubject<DrawerMode>(DrawerMode.EMPTY);

  @Input() set details(data: ProfileDetails) {
    if (data) {
      this._details.next(data);
    }
  }

  @Input() set profiles(profiles: Profile[]) {
    this._profiles.next(profiles);
  }

  @Input() set mode(mode: DrawerMode) {
    this._mode.next(mode);
  }

  siteOptions$ = this._sites.pipe(map((sites) => Coerce.getObjKeys(sites)));

  DEFAULT_VALUES = {
    site: '',
    siteId: '',
    granularity: 'hourly',
    tickformat: GRANULARITY_TICKFORMAT.hourly,
    status: ProfileStatus.MODIFIED,
    values: this.createNullValues(),
    isNew: true,
    editedValues: [],
    hasValues: false,
    startYear: DEFAULT_START_YEAR,
    endYear: DEFAULT_END_YEAR,
    companyId: '',
  };

  siteNameControl = new FormControl();

  profileForm = this.formBuilder.group({ ...this.DEFAULT_VALUES });
  loading$ = this.profileFacade.loading$;

  mode$ = this._mode.asObservable();

  get values(): YearlyValues {
    return this.profileForm.controls.values.value as YearlyValues;
  }

  get tickformat(): string {
    return this.profileForm.controls.tickformat.value as string;
  }

  get granularity(): string {
    return this.profileForm.controls.granularity.value as string;
  }

  get profileFormControls(): Record<string, AbstractControl> {
    return this.profileForm.controls;
  }

  get details(): ProfileDetails {
    return this._details.value;
  }

  get sites(): Record<string, string> {
    return this._sites.value;
  }

  get profiles(): Profile[] {
    return this._profiles.value;
  }

  get hasValues(): boolean {
    const unknownTypeProfileValue: unknown = this.profileForm.value;
    const readonlyProfileValue: Readonly<ProfileDetailsForm> =
      unknownTypeProfileValue as Readonly<ProfileDetailsForm>;
    return Coerce.toObject<ProfileDetailsForm>(readonlyProfileValue).hasValues;
  }

  constructor(
    private clientFacade: ClientFacadeService,
    private profileFacade: ProfileFacadeService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.initializeDefaultStartAndEndYear();
    this.initializeProfileFormSubscription();
    this.initializeSiteNameFormSubscription();
    this.initializeSiteOptions();
  }

  writeValue(obj: ProfileDetailsForm): void {
    if (obj) {
      const editedValues: string[] = Coerce.toArray<string>(
        Coerce.getObjValues(Coerce.toObject(obj.values)),
      );
      this.profileForm.patchValue({
        ...obj,
        editedValues,
      });
      this.siteNameControl.patchValue(obj.site);
    } else {
      this.profileForm.patchValue(this.DEFAULT_VALUES);
    }
  }

  registerOnChange(fn: () => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.profileForm.disable();
    }
  }

  isReadonly(mode: DrawerMode): boolean {
    return ![DrawerMode.EDIT, DrawerMode.CREATE].includes(mode);
  }

  private initializeDefaultStartAndEndYear() {
    this.clientFacade.selectActiveClientData$
      .pipe(
        untilDestroyed(this),
        filter((data) => !!data),
        first(),
      )
      .subscribe((data) => {
        this.profileForm.patchValue({
          startYear: data.runSettings?.defaultStartYear
            ? parseInt(data.runSettings.defaultStartYear)
            : DEFAULT_START_YEAR,
          endYear: data.runSettings?.defaultEndYear
            ? parseInt(data.runSettings.defaultEndYear)
            : DEFAULT_END_YEAR,
        });
      });
  }

  private createNullValues(
    granularity: string = Granularity.HOURLY,
    startYear: number = DEFAULT_START_YEAR,
    endYear: number = DEFAULT_END_YEAR,
  ): Record<string, string> {
    if (Granularity.YEARLY === granularity) {
      let yearDuration = startYear === endYear ? 1 : endYear - startYear + 1;
      return Array(yearDuration)
        .fill(null)
        .reduce((acc, _, index) => {
          const startDate = new Date(DEFAULT_START_YEAR.toString());
          startDate.setFullYear(startYear + index);
          acc[startDate.toUTCString()] = null;
          return acc;
        }, {});
    } else {
      return Array(GRANULARITY_COUNT[granularity])
        .fill(null)
        .reduce((acc, _, index) => {
          const startDate = new Date(INITIAL_DATE);
          switch (granularity) {
            case Granularity.HOURLY:
              startDate.setTime(startDate.getTime() + index * 60 * 60 * 1000);
              break;
            case Granularity.DAILY:
              startDate.setDate(startDate.getDate() + index);
              break;
            case Granularity.MONTHLY:
              startDate.setMonth(startDate.getMonth() + index);
              break;
            default:
              break;
          }
          acc[startDate.toUTCString()] = null;
          return acc;
        }, {});
    }
  }

  private initializeProfileFormSubscription() {
    this.profileForm.valueChanges
      .pipe(untilDestroyed(this), distinctObject)
      .subscribe((value: ProfileDetailsForm) => this._onChange(value));

    this.initializeEditedValuesFormSubscription();
  }

  private initializeEditedValuesFormSubscription() {
    this.profileFormControls.editedValues.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe((editedValues: string[]) => {
        this.profileFormControls.status.patchValue(ProfileStatus.MODIFIED);
        const values = Coerce.getObjKeys(
          this.profileFormControls.values.value,
        ).reduce((acc, date, idx) => {
          acc[date] = editedValues[idx];
          return acc;
        }, {});

        this.profileFormControls.values.patchValue(values, {
          emitEvent: false,
        });
      });
  }

  private initializeSiteNameFormSubscription() {
    this.siteNameControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        map((site: string) => {
          const siteId = this.sites[site];
          this.getProfiles(site, siteId);
          return siteId;
        }),
        mergeMap((siteId) => {
          return this.profileFacade.getProfilesById(
            `${this.details.campaignId}_${siteId}`,
          );
        }),
      )
      .subscribe((profile: Profile[]) => {
        this.patchValueInProfileForm(profile);
      });
  }

  private getProfiles(site: string, siteId: string) {
    this._selectedSite.next({ name: site, geoId: siteId });
    if (
      [this.details.clientId, this.details.campaignId, siteId].every(Boolean)
    ) {
      this.profileFacade.getProfile(
        this.details.clientId,
        this.details.campaignId,
        siteId,
      );
    }
  }

  private patchValueInProfileForm(profile: Profile[]) {
    let selectedProfile: ProfileDetailsForm = {
      ...this.DEFAULT_VALUES,
      site: this._selectedSite.value.name,
      siteId: this._selectedSite.value.geoId,
      hasValues: false,
    };
    if (profile?.length) {
      selectedProfile = {
        ...profile[0],
        site: this._selectedSite.value.name,
        siteId: this._selectedSite.value.geoId,
        status: ProfileStatus.PRISTINE,
        tickformat: this.DEFAULT_VALUES.tickformat,
        editedValues: Coerce.getObjValues(profile[0].values),
        hasValues: true,
      };
    }
    this.profileForm.patchValue(selectedProfile);
  }

  private initializeSiteOptions() {
    this.clientFacade
      .getSites(
        this.details?.clientId,
        this.details?.isGeoGroup,
        this.details?.geoId,
      )
      .pipe(untilDestroyed(this), first())
      .subscribe((sites) => {
        this._sites.next(
          sites.reduce((acc, site) => {
            acc[site.name] = site.geoId;
            return acc;
          }, {}),
        );
      });
  }
}

import { Observable, of } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client';
import { Coerce, YearlyInputDialogComponent } from 'ssotool-shared';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'sso-metrics-list',
  templateUrl: './metrics-list.component.html',
  styleUrls: ['./metrics-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetricsListComponent implements OnInit {
  entityType = this.route.snapshot.data['type'];
  referenceKey = this.route.snapshot.data['referenceKey'];
  ID = this.route.snapshot.params[this.referenceKey];

  entities$: Observable<any[]> = of([]);
  loading$: Observable<boolean> = of(false);
  clientId = '';
  columnDefs = [
    { name: 'name', type: '' },
    { name: 'geography', type: 'geographies' },
    { name: 'company', type: 'companies' },
    { name: 'sector', type: 'sectors' },
    { name: 'process', type: 'processes' },
    { name: 'fluid', type: 'fluids' },
    { name: 'quantity', type: 'quantity' },
    { name: 'values', type: '', mode: 'sparkline' },
  ].filter((col) => col.type !== this.entityType);
  excludedColumnFilter = ['values'];

  constructor(
    private route: ActivatedRoute,
    private clientFacade: ClientFacadeService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.clientFacade.selectActiveClientData$.pipe().subscribe((client) => {
      /* istanbul ignore else */
      if (client && client.clientId) {
        this.clientId = client.clientId;
        this.entities$ = this.clientFacade.selectMetrics$(client.clientId).pipe(
          skipWhile((data) => !data),
          map((data) =>
            Coerce.getObjValues(data)
              .filter((metric) => metric[this.referenceKey] === this.ID)
              .map((metric) => ({
                name: metric.name,
                geography: metric.geoName,
                company: metric.companyName,
                sector: metric.sectorName,
                process: metric.processName,
                fluid: metric.fluidName,
                quantity: metric.quantityName,
                values: metric.values,
              })),
          ),
        );
        this.loading$ = this.clientFacade.dataLoading$(client.clientId);
      }
    });
  }

  onClick(entity: any) {
    const yearRange = Coerce.getObjKeys(entity.values);
    this.dialog
      .open(YearlyInputDialogComponent, {
        width: '70%',
        data: {
          yearlyValues: entity.values,
          startYear: Number(yearRange.sort()[0]),
          endYear: Number(yearRange.reverse()[0]),
          disableClose: true,
          isViewMode: true,
          name: entity.name,
        },
      })
      .afterClosed()
      .subscribe();
  }
}

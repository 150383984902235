import {
  DEFAULT_END_YEAR,
  DEFAULT_START_YEAR,
} from 'ssotool-app/app.references';

import { AbstractControl, ValidatorFn } from '@angular/forms';

import { Coerce } from './coerce.utils';
import { hasMultipleValues } from './form-helpers';
import { isObjectEmpty } from 'ssotool-app/core/utils/object-util';

export type NumYearlyValues = {
  [year: string]: number;
};

export interface YearlyValues {
  [year: string]: string;
}

export interface YearlyValuesBackend {
  startYear: number;
  endYear: number;
  value: string;
  annualChange: string;
}

/**
 * Converts list of values to a dictionary of yearly values based on the start year. The length of the list determines the end year using
 * the start year as starting point. Returns null if values or start year parameters are not defined.
 * @param values    - the list of values to be assigned each year
 * @param startYear - the start year
 */
export const convertListToYearlyValues = (
  values: Array<string | null | undefined>,
  startYear: number,
): YearlyValues => {
  if (!values || !startYear) {
    return null;
  }

  return values
    .map((value, index) => ({ value, year: startYear + index }))
    .reduce(
      (prev, next) => {
        prev[next.year] = next.value;
        return prev;
      },
      {}, // Generate an object of yearly values via reduce function
    );
};

/**
 * Converts a single value parameter to a dictionary of yearly values based on the start and end year parameters. Returns null if start
 * or end year parameters are not defined; or start year is greater than end year.
 * @param value     - the value to be assigned to each year
 * @param startYear - the start year
 * @param endYear   - the end year
 */
export const convertToYearlyValues = (
  value: string | null | undefined,
  startYear: number,
  endYear: number,
): YearlyValues => {
  if (!startYear || !endYear) {
    return null;
  }

  if (startYear > endYear) {
    return null;
  }

  return convertListToYearlyValues(
    Array.from(Array(endYear - startYear + 1)).map((_) => value), // Create an array from the start and end year parameters
    startYear,
  );
};

export class YearlyValuesMapper {
  static mapToFrontend = (data: YearlyValuesBackend[]): YearlyValues =>
    !isObjectEmpty(data)
      ? YearlyValuesMapper.mapYearlyValuesToFrontend(data)
      : {};

  // TODO: update mapper to backend
  static mapToBackend = (
    data: YearlyValues,
    allowNull: boolean = false,
  ): YearlyValuesBackend[] =>
    !isObjectEmpty(data)
      ? YearlyValuesMapper.mapYearlyValuesToBackend(data, allowNull)
      : [];

  private static mapYearlyValuesToFrontend(
    data: YearlyValuesBackend[],
  ): YearlyValues {
    const mappedValue = {};
    if (data.length) {
      data.forEach((bv) => {
        let startYear = bv.startYear;
        const endYear = bv.endYear + 1;
        if (Number(bv.annualChange)) {
          const annualChange = 1 + Number(bv.annualChange);
          let value = 0;
          let prevValue = Number(bv.value);
          while (startYear < endYear) {
            value = prevValue;
            mappedValue[startYear] = String(value);
            startYear += 1;
            prevValue = value * annualChange;
          }
        } else {
          while (startYear < endYear) {
            mappedValue[startYear] = String(bv.value);
            startYear += 1;
          }
        }
      });
    }
    return mappedValue;
  }

  private static mapYearlyValuesToBackend = (
    data: YearlyValues,
    allowNull: boolean,
  ): YearlyValuesBackend[] => {
    if (hasMultipleValues(data)) {
      const years = Coerce.getObjKeys(data).map((year) => Number(year));
      const yearlyValue = [];
      let _startYear = DEFAULT_START_YEAR;
      let lastYearWithValidValue = 0;
      let firstYearWithValidValue = 0;
      years.forEach((year) => {
        if (!!data[year] || (data[year] as unknown) === 0) {
          firstYearWithValidValue = !!firstYearWithValidValue
            ? firstYearWithValidValue
            : year;
          lastYearWithValidValue = year;
        }
      });

      years.forEach((year) => {
        if (data[year] === data[year - 1]) {
          yearlyValue[yearlyValue.length - 1]['endYear'] = year;
        } else {
          yearlyValue.push({
            startYear: _startYear,
            endYear: year,
            value: YearlyValuesMapper.processValue(
              data[year],
              allowNull,
              firstYearWithValidValue <= year && year <= lastYearWithValidValue,
            ),
            annualChange: '0.0',
          });
        }

        _startYear = year + 1;
      });
      return yearlyValue;
    }

    return [
      {
        startYear: DEFAULT_START_YEAR,
        endYear: DEFAULT_END_YEAR,
        value: YearlyValuesMapper.processValue(
          data[DEFAULT_START_YEAR],
          allowNull,
        ),
        annualChange: '0.0',
      },
    ];
  };

  private static processValue(
    value: string,
    allowNull: boolean,
    isWithinValidValuedYears: boolean = false,
  ): string {
    return !!allowNull &&
      !!!isWithinValidValuedYears &&
      [null, ''].includes(value)
      ? ''
      : Coerce.toZero(value).toString();
  }
}

export type GranularYearlyValueData = {
  minSize: YearlyValues;
  maxSize: YearlyValues;
};

export const validateGranularYearlyValues = (
  data: GranularYearlyValueData,
  startYear = DEFAULT_START_YEAR,
  endYear = DEFAULT_END_YEAR,
): ValidatorFn => {
  return function (control: AbstractControl): { [key: string]: any } | null {
    let value = control.value;

    if (['string', 'number'].includes(typeof value)) {
      value = convertToYearlyValues(value, startYear, endYear);
    }

    let result = null;

    Object.entries(value || {}).some(([key, val]) => {
      const minValue = Number(data.minSize[key]);
      const maxValue = data.maxSize && Number(data.maxSize[key]);
      if (Number(val) < minValue) {
        result = { minValue: { year: key, value: minValue } };
        return true;
      } else if (maxValue && Number(val) > maxValue) {
        result = { maxValue: { year: key, value: maxValue } };
        return true;
      }
    });

    return result;
  };
};

export function validateMinMaxYearlyValues(
  minControlName: string,
  maxControlName: string,
  startYear = DEFAULT_START_YEAR,
  endYear = DEFAULT_END_YEAR,
) {
  return (control: AbstractControl) => {
    if (
      control.get(minControlName)?.disabled ||
      control.get(maxControlName)?.disabled
    ) {
      return null;
    }
    let minSize = control.get(minControlName)?.value;
    let maxSize = control.get(maxControlName)?.value;
    let values = [minSize, maxSize];

    values.forEach((value, index) => {
      if (['string', 'number'].includes(typeof value)) {
        values[index] = convertToYearlyValues(value, startYear, endYear);
      }
    });

    if (!minSize || !maxSize) {
      return null;
    }

    let isValid = true;
    let invalidValue = '';
    Coerce.getObjKeys(minSize).some((year) => {
      const minSizeVal = minSize[year];
      const maxSizeVal = maxSize[year];
      if (+minSizeVal > +maxSizeVal) {
        isValid = false;
        invalidValue = year;
        return true;
      }
    });
    return isValid ? null : { minMustBeLesser: invalidValue };
  };
}

type CompareItem = number | boolean;
export type CompareOperator = '<' | '>' | '|' | '&' | '>=' | '<=';
export type CompareDateOperator = Exclude<CompareOperator, '|' | '&'>;
const operatorMap: Record<
  CompareOperator,
  (a: CompareItem, b: CompareItem) => boolean
> = {
  '<': (a, b) => a < b,
  '>': (a, b) => a > b,
  '<=': (a, b) => a <= b,
  '>=': (a, b) => a >= b,
  '|': (a, b) => !!(a || b),
  '&': (a, b) => !!(a && b),
};

export function compareYear(
  firstDate: string,
  secondDate: string,
  operator: Exclude<CompareDateOperator, '|' | '&'>,
) {
  return operatorMap[operator](
    new Date(firstDate).getTime(),
    new Date(secondDate).getTime(),
  );
}

export function mapRangeLimits(
  range: Partial<Record<'startLimit' | 'endLimit', string>>,
  defaultStart: string,
  defaultEnd: string,
  isWithin: boolean = true,
): Record<'startLimit' | 'endLimit', string> {
  const rangeStartLimit = range?.startLimit;
  const rangeEndLimit = range?.endLimit;

  if (compareYear(rangeStartLimit, rangeEndLimit, '>')) {
    return {
      startLimit: defaultStart,
      endLimit: defaultEnd,
    };
  }

  if (isWithin) {
    return {
      startLimit: operatorMap['&'](
        compareYear(rangeStartLimit, defaultStart, '>='),
        compareYear(rangeStartLimit, defaultEnd, '<='),
      )
        ? rangeStartLimit
        : defaultStart,
      endLimit: operatorMap['&'](
        compareYear(rangeEndLimit, defaultStart, '>='),
        compareYear(rangeEndLimit, defaultEnd, '<='),
      )
        ? rangeEndLimit
        : defaultEnd,
    };
  } else {
    return {
      startLimit: compareYear(rangeStartLimit, defaultStart, '<')
        ? rangeStartLimit
        : defaultStart,
      endLimit: compareYear(rangeEndLimit, defaultEnd, '>')
        ? rangeEndLimit
        : defaultEnd,
    };
  }
}

import { Injectable } from '@angular/core';
import {
  CompareRoadmapVarQueryParamType,
  CompareRoadmapWithVariationType,
  CompareVariationQueryParamMap,
} from '../modules/compare-variations/compare-variations.model';

@Injectable()
export class CompareVariationsService {
  constructor() {}

  transformToRoadmapWithVariation(
    data: CompareRoadmapVarQueryParamType,
  ): CompareRoadmapWithVariationType {
    return data.reduce((acc, item) => {
      const roadmapName = data.length === 1 ? '' : item.roadmapName;

      item.variations.forEach((varId) =>
        acc.push([item.roadmapId, roadmapName, varId]),
      );
      return acc;
    }, []);
  }

  parseQueryParam(
    data: CompareVariationQueryParamMap,
  ): CompareRoadmapVarQueryParamType {
    let result = JSON.parse(
      data.params?.compareData || null,
    ) as CompareRoadmapVarQueryParamType;
    return this.isCompareRoadmapVarQueryParamType(result) ? result : null;
  }

  isCompareRoadmapVarQueryParamType(data: any): boolean {
    return (
      Array.isArray(data) &&
      data.length > 0 &&
      data.every(
        (item: Object) =>
          item.hasOwnProperty('roadmapId') &&
          item.hasOwnProperty('variations') &&
          Array.isArray(item?.['variations']),
      )
    );
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PushPipe } from '@ngrx/component';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';

import { WaterfallComponent } from './waterfall.component';

@NgModule({
  declarations: [WaterfallComponent],
  imports: [
    CommonModule,
    PushPipe,
    TranslateModule,
    FlexLayoutModule,
    MatCheckboxModule,
    MatCardModule,
    MatSlideToggleModule,
  ],
  exports: [WaterfallComponent],
  providers: [TranslatePipe],
})
export class WaterfallModule {}

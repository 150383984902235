<div class="sso-table-message">
  <div *ngIf="loading; else hasError" [@rowAnimation]>
    <div *ngIf="dataLength === 0">
      {{ 'Generic.messages.loadingData' | translate }}
    </div>
  </div>
  <ng-template #hasError>
    <div *ngIf="error; else hasNoData" [@rowAnimation]>
      {{ 'Generic.messages.errorLoadingData' | translate }}
    </div>
  </ng-template>
  <ng-template #hasNoData>
    <div *ngIf="dataLength === 0; else hasNoFilteredData" [@rowAnimation]>
      {{ noRecordsMessage | translate }}
    </div>
  </ng-template>
  <ng-template #hasNoFilteredData>
    <div *ngIf="filteredDataLength === 0" [@rowAnimation]>
      {{ 'Generic.messages.noResultsFound' | translate }} '{{ filter }}'
    </div>
  </ng-template>
</div>

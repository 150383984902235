import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  FilteredComponent,
  FilterOptions,
  FiltersService,
} from 'ssotool-shared';

import { SelectionModel } from '@angular/cdk/collections';
import { Directive, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Directive()
export abstract class BaseEntityTableController extends FilteredComponent {
  constructor(protected filtersService: FiltersService) {
    super(filtersService);
  }
  protected entitiesSubject = new BehaviorSubject<any[]>([]);
  protected filteredEntities = new BehaviorSubject<any[]>([]);

  selection: SelectionModel<any>;
  activeFilters = new BehaviorSubject<FilterOptions>({});
  searchInput$ = new BehaviorSubject('');
  arrayFields = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @Input() set entities(entities: any[]) {
    if (entities) {
      this.filteredEntities.next(entities);
      this.entitiesSubject.next(entities);
      this.selection = new SelectionModel(
        true,
        entities.filter(this.checkIfSelected),
      );
    }
  }
  @Input() loading: boolean;

  filtered$ = new BehaviorSubject<any[]>([]);
  isAllSelected$ = new BehaviorSubject<boolean>(false);

  datasource = new MatTableDataSource([]);
  /** Template streams */
  datasource$ = combineLatest([this.filteredEntities, this.searchInput$]).pipe(
    map(([entities, searchInput]) => {
      this.filtered$.next(entities);
      this.isAllSelected$.next(entities?.every(this.checkIfSelected));
      this.datasource.data = entities;
      this.datasource.sort = this.sort;
      this.datasource.paginator = this.paginator;
      this.datasource.filter = searchInput;

      return this.datasource;
    }),
  );
  dataSourceLength$ = this.datasource$.pipe(
    map((source) => source.data?.length),
  );

  protected abstract checkIfSelected(entity: any);

  isAllSelected() {
    const numSelected = this.selection.selected?.length;
    const numRows = this.datasource.data?.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.selection.select(...this.datasource.data);
  }
}

import { ClientFacadeService } from 'ssotool-app/+client';
import { BindataBaseComponent } from 'ssotool-app/+entities/containers/bindata/bindata-base.component';
import {
  BindataChartValues,
  BindataHourlyModel,
} from 'ssotool-app/+entities/containers/bindata/bindata-chart.model';
import { BinDataFacadeService } from 'ssotool-app/+entities/stores/bindata/bindata-facade.service';
import { BinData } from 'ssotool-app/+entities/stores/bindata/bindata.model';

import { Directive, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Directive()
export class BindataHourlyBaseComponent
  extends BindataBaseComponent
  implements OnInit
{
  constructor(
    public data: BindataHourlyModel,
    public dialogRef: MatDialogRef<BindataHourlyModel>,
    public binDataFacade: BinDataFacadeService,
    public clientFacade: ClientFacadeService,
  ) {
    super(dialogRef, binDataFacade, clientFacade);
  }

  ngOnInit() {
    if (this.data) {
      this.yearlyTabs = this.data.values.map((v) => ({
        startYear: v.startYear,
        endYear: v.endYear,
      }));
      this.activeBin = this.data.values[0];
      this.loadBinaryData(this.data);
      if (this.data.fluidId) {
        this.setQuantityByFluid(this.data.clientId, this.data.fluidId);
      } else if (this.data.quantityId) {
        this.setQuantity(this.data.clientId, this.data.quantityId);
      }
    }
  }

  setYearlyChartValue(bdata: BinData) {
    this.yearlyChartValues$.next({ name: this.data.name, series: bdata.data });
  }

  onTabChange(event) {
    this.activeBin = this.data.values[event] as BindataChartValues;
    this.loadBinaryData(this.data);
  }
}

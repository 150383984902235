import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { FluidsMenuButtonComponent } from './fluids-menu-button.component';

const modules = [CommonModule, MatIconModule, MatButtonModule, MatMenuModule];

@NgModule({
  declarations: [FluidsMenuButtonComponent],
  imports: [...modules],
  exports: [FluidsMenuButtonComponent],
})
export class FluidsMenuButtonModule {}

import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { SignedUrl } from 'ssotool-app/app.model';
import { generateEndpoint } from 'ssotool-core/utils';
import { download, processDownloadedData } from 'ssotool-shared/services';
import { ConfigService } from 'ssotool-shared/services/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  CampaignImportFormModel,
  CampaignImportLog,
  CampaignImportModel,
} from '../store/import';

@Injectable()
export class CampaignImportAPIService {
  constructor(private http: HttpClient, private config: ConfigService) {}

  get(clientId: string, importId: string): Observable<any> {
    return this.http
      .get<any>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.campaignImport.getImportCampaignInfo,
          clientId,
          importId,
        ),
      )
      .pipe(
        map((response) => this.mapImportCampaignToFrontend(response)),
        catchError((error) => throwError(error)),
      );
  }

  getList(clientId: string) {
    return this.http
      .get<CampaignImportModel[]>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.campaignImport.getImportCampaignList,
          clientId,
        ),
      )
      .pipe(catchError((error) => throwError(error)));
  }

  importCampaign(clientId: string, importCampaign: CampaignImportFormModel) {
    return this.http
      .post(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.campaignImport.importCampaign,
          clientId,
        ),
        {
          description: importCampaign.description,
        },
      )
      .pipe(
        mergeMap((uploadResponse: any) =>
          this.http
            .get(
              generateEndpoint(
                this.config.api.baseUrl,
                this.config.api.endpoints.campaignImport.getImportSignedUrl,
                clientId,
                uploadResponse.importId,
                importCampaign.file.name,
              ),
            )
            .pipe(
              mergeMap((uploadSignedUrl: any) =>
                this.http
                  .put(uploadSignedUrl.signedUrl, importCampaign.file)
                  .pipe(
                    map(() => this.mapImportCampaignToFrontend(uploadResponse)),
                  ),
              ),
            ),
        ),
        catchError((error) => throwError(error)),
      );
  }

  downloadCampaignImportData(clientId: string, importId: string) {
    return this.http
      .get(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.campaignImport.getDownloadSignedUrl,
          clientId,
          importId,
        ),
      )
      .pipe(
        mergeMap((downloadSignedUrl: any) =>
          this.http.get(downloadSignedUrl.signedUrl).pipe(
            map((response: any) => {
              const blob = new Blob([response], {
                type: 'applications/octet-stream',
              });
              const resUrl = window.URL.createObjectURL(blob);
              const anchor = document.createElement('a');
              anchor.download = downloadSignedUrl.filename;
              anchor.href = resUrl;
              anchor.click();
              return response;
            }),
          ),
        ),
        catchError((error) => throwError(error)),
      );
  }

  downloadTemplate(clientId: string) {
    return this.http
      .get(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.campaign.downloadTemplate,
          clientId,
        ),
      )
      .pipe(
        mergeMap((downloadSignedUrl: SignedUrl) =>
          this.http.get(downloadSignedUrl.signedUrl).pipe(
            map((response: any) => {
              const blob = new Blob([response], {
                type: 'applications/octet-stream',
              });
              const resUrl = window.URL.createObjectURL(blob);
              const anchor = document.createElement('a');
              anchor.download = downloadSignedUrl.filename;
              anchor.href = resUrl;
              anchor.click();
              return response;
            }),
          ),
        ),
        catchError((error) => throwError(error)),
      );
  }

  downloadErrorLogs(clientId: string, importId: string) {
    return this.http
      .get(
        generateEndpoint(
          this.config?.api?.baseUrl,
          this.config?.api?.endpoints?.campaignImport?.getImportCampaignLogs,
          clientId,
          importId,
        ),
      )
      .pipe(
        filter((url) => !!url),
        mergeMap((logDownloadSignedUrl: SignedUrl) =>
          this.http
            .get(logDownloadSignedUrl.signedUrl, {
              reportProgress: true,
              observe: 'events',
              responseType: 'blob',
            })
            .pipe(
              download(),
              processDownloadedData<string>(),
              map((logs) => this.parseMessages(logs)),
            ),
        ),
      );
  }

  mapImportCampaignToFrontend(data: any) {
    return {
      clientId: data.clientId,
      importId: data.importId,
      owner: data.owner,
      description: data.description,
      createdAt: data.createdAt,
      filename: data.filename,
      status: data.status,
    };
  }

  parseMessages(messages: any) {
    if (messages) {
      let parsedMessages: any;
      try {
        parsedMessages = JSON.parse(messages);
        return this.groupCampaignByModule(parsedMessages?.issues);
      } catch (error) {
        return messages;
      }
    } else {
      return messages;
    }
  }

  groupCampaignByModule(messages: CampaignImportLog[]) {
    const newMessages = {};
    messages.forEach((data: CampaignImportLog) => {
      let module = data?.location?.module;
      if (module === undefined) {
        module = 'Internal Error';
      }
      newMessages[module] = newMessages[module]
        ? [...newMessages[module], data]
        : [data];
    });
    return newMessages;
  }

  deleteImport(clientId: string, importId: string) {
    return this.http
      .delete(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.campaignImport.delete,
          clientId,
          importId,
        ),
      )
      .pipe(catchError((error) => throwError(error)));
  }
}

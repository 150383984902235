import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Coerce } from 'ssotool-app/shared';

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { ResultExportEntityActions } from './result-export.actions';
import { ResultExportNotification } from './result-export.model';
import { ResultExportEntityModel } from './result-export.reducer';
import {
  selectActiveExport,
  selectExport,
  selectLoaded,
  selectLoading,
} from './result-export.selector';

@Injectable()
export class ResultExportFacadeService {
  constructor(private store: Store) {}

  loading$ = this.store.pipe(select(selectLoading));
  loaded$ = this.store.pipe(select(selectLoaded));

  export$ = (id: string): Observable<ResultExportEntityModel> =>
    this.store.select(selectExport({ id }));
  activeExport$ = this.store.pipe(select(selectActiveExport));
  exporting$ = this.activeExport$.pipe(
    map((datum) => Coerce.toObject(datum).exporting),
  );
  exportStageMessage$ = this.activeExport$.pipe(
    map((datum) => Coerce.toObject(datum).stageMessage),
  );

  getList(clientId: string): void {
    this.store.dispatch(ResultExportEntityActions.getList({ clientId }));
  }

  createExportDetailedResults(
    clientId: string,
    roadmapIds: Array<string>,
  ): void {
    this.store.dispatch(
      ResultExportEntityActions.createExportDetailedResults({
        clientId,
        roadmapIds,
      }),
    );
  }

  createExportSummaryResults(
    clientId: string,
    roadmapIds: Array<string>,
  ): void {
    this.store.dispatch(
      ResultExportEntityActions.createExportSummaryResults({
        clientId,
        roadmapIds,
      }),
    );
  }

  processNotification(data: ResultExportNotification): void {
    this.store.dispatch(
      ResultExportEntityActions.processNotification({ data }),
    );
  }

  cancelExport(clientId: string, exportId: string): void {
    this.store.dispatch(
      ResultExportEntityActions.cancelExport({
        clientId,
        exportId,
      }),
    );
  }
}

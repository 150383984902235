<div fxFlex fxLayout="column" fxLayoutAlign="space-between">
  <div fxFlex class="sso-table-container" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center">
    <table mat-table [dataSource]="tableData">
      <ng-container matColumnDef="startYear">
        <th mat-header-cell *matHeaderCellDef>Start Year</th>
        <td mat-cell *matCellDef="let element">{{ element.startYear }}</td>
      </ng-container>

      <ng-container matColumnDef="endYear">
        <th mat-header-cell *matHeaderCellDef>End Year</th>
        <td mat-cell *matCellDef="let element">{{ element.endYear }}</td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef class="align-right">Value</th>
        <td mat-cell *matCellDef="let element" class="align-right">{{ element.value | number }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div *ngIf="tableData?.length === 0" class="common-table-empty">
      Nothing to display!
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  InputModule,
  SelectModule,
  TooltipAnchorModule,
  YearlyInputModule,
} from 'ssotool-shared';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';

import { StorageFormComponent } from './storage-form.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [StorageFormComponent],
  exports: [StorageFormComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    InputModule,
    TooltipAnchorModule,
    SelectModule,
    YearlyInputModule,
    MatFormFieldModule,
    MatDividerModule,
    MatIconModule,
    MatTooltipModule,
  ],
})
export class StorageFormModule {}

import { Injectable } from '@angular/core';
import { Trajectory } from '../client.model';
import { Store } from '@ngrx/store';
import { DatahubFeatureState } from './datahub.reducer';
import { DatahubActions } from './datahub.actions';
import { DatahubSelectors } from './datahub.selectors';

@Injectable({
  providedIn: 'root',
})
export class DatahubFacadeService {
  loaded$ = this.dataHubStore.select(DatahubSelectors.loaded);
  loading$ = this.dataHubStore.select(DatahubSelectors.loading);
  pristine$ = this.dataHubStore.select(DatahubSelectors.pristine);
  getTrajectories$ = this.dataHubStore.select(
    DatahubSelectors.selectTrajectories,
  );

  chooseTrajectoriesToSave(
    clientId: string,
    importId: string,
    selectedIds: Trajectory['trajectoryId'][],
  ): void {
    this.dataHubStore.dispatch(
      DatahubActions.chooseTrajectoriesToSave({
        clientId,
        importId,
        selectedIds,
      }),
    );
  }

  /**
   * Retrieves TDB commodities for a specific client and import.
   * @param {string} clientId - The ID of the client
   * @param {string} importId - The ID of the import
   */
  getTDBCommodities(clientId: string, importId: string) {
    this.dataHubStore.dispatch(
      DatahubActions.getTDBCommodities({
        clientId,
        importId,
      }),
    );
  }

  constructor(private dataHubStore: Store<DatahubFeatureState>) {}
}

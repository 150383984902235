import { FilterWithCondition } from 'ssotool-app/shared';
import { NewKPIType } from '../portfolio.model';

export type RoadmapVisualization = {
  clientId: string;
  roadmapId: string;
  visualizationId: string;
  name: string;
  createdAt?: string;
  details?: VisualizationMap;
};

export type VisualizationMap = {
  filters?: FilterWithCondition;
  kpiType: NewKPIType;
  kpi: string;
  subKpi: string[];
  curveType: string;
  xAxis: GroupLevel;
  splitBy: GroupLevel;
  indicatorField?: string;
  geography?: string;
  year?: string;
  showZeroValue?: boolean;
  enumerateBy?: GroupLevel;
  colorMap?: ColorMap | {};
};

export type ColorMap = {
  [campaignName: string]: string;
};

export type GroupLevel = {
  group: string;
  level: string;
};

export type RoadmapVisualizationSaveSuccess = {
  category: string;
  categoryNormalizedName: string;
  clientId: string;
  createdAt: string;
  dataType: string;
  details: string;
  id: string;
  name: string;
  normalizedName: string;
  owner: string;
  uniqueId: string;
  updatedAt: string;
  version: string;
  visualizationId: string;
};

export type PartialRoadmapVisualization = Partial<RoadmapVisualization>;

export const ROADMAP_VISUALIZATION_FEATURE_NAME = 'Roadmap Visualization';

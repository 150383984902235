import { Coerce } from 'ssotool-shared/helpers';

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { AnnouncementActions } from './announcement.action';
import { AnnouncementType } from './announcement.const';
import { Announcement } from './announcement.model';

export const announcementFeatureKey = 'Announcements';

export interface AnnouncementState extends EntityState<Announcement> {
  loading: boolean;
  loaded: boolean;
}

export const announcementAdapter: EntityAdapter<Announcement> =
  createEntityAdapter<Announcement>();

export const initialAnnouncementState: AnnouncementState =
  announcementAdapter.getInitialState({
    loading: false,
    loaded: false,
  });

function checkUnreadRelease(items: Array<Announcement>): boolean {
  return items.some((item) => item.type === AnnouncementType.RELEASE);
}

function getLatestReleaseVersion(items: Array<Announcement>): string {
  return Coerce.toObject(items[0].data).version;
}
const announcementReducer = createReducer(
  initialAnnouncementState,
  on(AnnouncementActions.getList, (state) => ({
    ...state,
    loading: true,
  })),
  on(AnnouncementActions.getListSuccess, (state, { items }) =>
    announcementAdapter.upsertMany(items, {
      ...state,
      loading: false,
      loaded: true,
    }),
  ),
  on(AnnouncementActions.getListFailure, (state) => ({
    ...state,
    loading: false,
    loaded: false,
  })),
  on(AnnouncementActions.readAnnouncement, (state) => ({
    ...state,
    loading: true,
  })),
  on(AnnouncementActions.readAnnouncementSuccess, (state) =>
    announcementAdapter.removeAll({
      ...state,
      loading: false,
    }),
  ),
  on(AnnouncementActions.readAnnouncementFailure, (state) => ({
    ...state,
    loading: false,
  })),
);

export function AnnouncementReducer(state: AnnouncementState, action: Action) {
  return announcementReducer(state, action);
}

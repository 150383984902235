

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { InputComponent } from './input.component';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MatTooltipModule,
  TranslateModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
];

@NgModule({
  declarations: [InputComponent],
  imports: [...modules],
  exports: [InputComponent],
})
export class InputModule {}

import { Observable } from 'rxjs';
import { UserStateManagerService } from 'ssotool-shared';
import { ConfigService } from 'ssotool-shared/services/config';

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable()
export class CampaignPageGuard implements CanActivate {
  constructor(
    private userService: UserStateManagerService,
    private config: ConfigService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.userService.isSuperUser();
  }
}

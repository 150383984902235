<ng-container *ngIf="sankeyData$ | async; else emptySankey">
  <oculus-sankey-diagram
    [data]="sankeyData$ | async"
    height="800"
    valueFormat="0.3s"
  ></oculus-sankey-diagram>
  <oculus-chart-legends
    orientation="horizontal"
    [data]="legendData$ | async"
  ></oculus-chart-legends>
</ng-container>
<ng-template #emptySankey>
  <div class="no-value">
    {{ 'Generic.messages.noResultsFound' | translate }}
  </div>
</ng-template>

import { SSOSpinnerModule, TabGroupModule } from 'ssotool-app/shared';
import { PipesModule } from 'ssotool-shared';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { LogViewerComponent } from './log-viewer.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [LogViewerComponent],
  exports: [LogViewerComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDialogModule,
    TranslateModule,
    SSOSpinnerModule,
    ScrollingModule,
    PipesModule,
    ReactiveFormsModule,
    TabGroupModule,
    MatExpansionModule,
    MatTableModule,
    MatIconModule,
    MatProgressSpinnerModule,
  ],
})
export class LogViewerModule {}

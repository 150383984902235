import { filter, first, map, mergeMap, tap } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { RoadmapImportFacadeService } from '../stores/import/roadmap-import.facade.service';

@Injectable()
export class RoadmapImportResolverService implements Resolve<any> {
  constructor(
    private clientFacade: ClientFacadeService,
    private importFacade: RoadmapImportFacadeService,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const roadmapId = route.parent.params.roadmapId;

    this.clientFacade.selectActiveClientData$
      .pipe(
        filter((client) => !!client?.clientId),
        first(),
        map((client) => client.clientId),
        mergeMap((id) =>
          this.importFacade.loaded$.pipe(
            tap((loaded) => {
              if (!loaded) {
                this.importFacade.getList(id, roadmapId);
              }
            }),
          ),
        ),
      )
      .subscribe();
  }
}

import { Component, Inject, Optional } from '@angular/core';
import { HotToastRef } from '@ngneat/hot-toast';
import { Observable } from 'rxjs';

@Component({
  selector: 'sso-single-button-toast-component',
  template: `<div>
    {{ toastRef.data.message }}
    <a
      data-testid="custom-click"
      class="sso-clickable sso-text-blue"
      (click)="handleActionClick()"
      >{{ toastRef.data.label }}</a
    >
  </div>`,
})
export class SingleButtonToastComponent {
  constructor(
    @Optional()
    @Inject(HotToastRef)
    public toastRef: HotToastRef<{
      message: string;
      label: string;
      action: Observable<{}>;
    }>,
  ) {}

  handleActionClick() {
    this.toastRef.data.action.subscribe();
    setTimeout(() => {
      this.toastRef.close();
    }, 100);
  }
}

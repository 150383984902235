import { SsoTooltipModule } from 'ssotool-shared/directives';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { GanttBarComponent } from './gantt-bar.component';
import { LetModule, PushPipe } from '@ngrx/component';

@NgModule({
  declarations: [GanttBarComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    SsoTooltipModule,
    PushPipe,
    LetModule,
  ],
  exports: [GanttBarComponent],
})
export class GanttBarModule {}

import { CAMPAIGN_FINAL_STATUSES } from 'ssotool-app/+campaign/campaign-page.references';
import {
  checkIfInCancel,
  determineExportingStatus,
} from 'ssotool-app/+campaign/services/campaign-export.service';
import { Coerce } from 'ssotool-app/shared';

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { CampaignExportActions } from './campaign-export.action';
import {
  CAMPAIGN_EXPORT_STATUS,
  CampaignExportModel,
} from './campaign-export.model';

export const campaignExportFeatureKey = 'CampaignsExport';

export interface CampaignExportState
  extends EntityState<Partial<CampaignExportModel>> {
  exporting?: boolean;
  loaded: boolean;
  error: string;
  message: string;
  activeExportId: string;
  cancelExportTriggered?: boolean;
}

export const selectCampaignExportId = (campaignExport: CampaignExportModel) =>
  Coerce.toString(campaignExport.exportId, '');

export const sortDates = (
  exportData1: CampaignExportModel,
  exportData2: CampaignExportModel,
) => {
  const date1 = new Date(exportData1.createdAt);
  const date2 = new Date(exportData2.createdAt);
  return date1.getTime() - date2.getTime();
};

export const campaignExportAdapter: EntityAdapter<
  Partial<CampaignExportModel>
> = createEntityAdapter<CampaignExportModel>({
  selectId: selectCampaignExportId,
  sortComparer: sortDates,
});

export const initialCampaignExportState: CampaignExportState =
  campaignExportAdapter.getInitialState({
    error: undefined,
    message: undefined,
    exporting: false,
    activeExportId: undefined,
    loaded: false,
    cancelExportTriggered: false,
  });

const campaignExportReducer = createReducer(
  initialCampaignExportState,

  // Export campaigns
  on(CampaignExportActions.exportSelectedCampaigns, (state) => {
    return { ...state };
  }),
  on(
    CampaignExportActions.exportSelectedCampaignsSuccess,
    (state, { data }) => {
      return {
        ...state,
        exporting: true,
        activeExportId: data?.exportId,
      };
    },
  ),
  on(
    CampaignExportActions.exportSelectedCampaignsFailure,
    (state, { error, message }) => {
      return { ...state, error, message, exporting: false };
    },
  ),

  // Cancel export campaigns
  on(CampaignExportActions.cancelExportCampaigns, (state, { exportId }) => {
    const status = CAMPAIGN_EXPORT_STATUS.ONGOING_CANCELLING;

    return campaignExportAdapter.upsertOne(
      { exportId, status },
      {
        ...state,
        cancelExportTriggered: true,
      },
    );
  }),
  on(
    CampaignExportActions.cancelExportCampaignsOK,
    (state, { exportId, message }) => {
      const status = CAMPAIGN_EXPORT_STATUS.CANCELLED;

      return campaignExportAdapter.upsertOne(
        { exportId, status },
        {
          ...state,
          message,
          exporting: false,
          cancelExportTriggered: false,
        },
      );
    },
  ),
  on(
    CampaignExportActions.cancelExportCampaignsPartial,
    (state, { exportId, message }) => {
      const status = CAMPAIGN_EXPORT_STATUS.FAILED_CANCELLING;

      return campaignExportAdapter.upsertOne(
        { exportId, status },
        {
          ...state,
          message,
          exporting: false,
          cancelExportTriggered: false,
        },
      );
    },
  ),
  on(
    CampaignExportActions.cancelExportCampaignsNOK,
    (state, { exportId, error, message }) => {
      const status = CAMPAIGN_EXPORT_STATUS.ERROR_CANCELLING;

      return campaignExportAdapter.upsertOne(
        { exportId, status },
        {
          ...state,
          exporting: false,
          error,
          message,
          cancelExportTriggered: false,
        },
      );
    },
  ),

  // Get list export campaigns
  on(CampaignExportActions.getListCampaignsExport, (state) => {
    return { ...state, error: undefined };
  }),
  on(
    CampaignExportActions.getListCampaignsExportSuccess,
    (state, { data, message }) => {
      const updateData = data.map((d) => ({ ...d } as CampaignExportModel));
      const exporting = updateData.some(
        (item) => item.status === CAMPAIGN_EXPORT_STATUS.ONGOING,
      );
      const sortedByDateExports = updateData.sort((a, b) => {
        const aTimestamp = new Date(a.updatedAt).valueOf();
        const bTimestamp = new Date(b.updatedAt).valueOf();
        return bTimestamp - aTimestamp;
      });
      const activeExportId =
        sortedByDateExports.length && exporting
          ? sortedByDateExports[0].exportId
          : '';
      return campaignExportAdapter.upsertMany(updateData, {
        ...state,
        message,
        exporting,
        activeExportId,
        loaded: true,
      });
    },
  ),
  on(CampaignExportActions.getListCampaignsExportFailure, (state) => {
    return { ...state, loaded: false };
  }),

  // Update Export Status
  on(
    CampaignExportActions.updateExportStatusSuccess,
    (state, { exportId, status, stages }) => {
      const currentState = Coerce.toEmptyObject(state.entities)[exportId];

      let currentStateStatus = Coerce.toEmptyObject(currentState).status;
      let exporting: boolean = determineExportingStatus(status);
      let tempStatus = checkIfInCancel(currentStateStatus)
        ? currentStateStatus
        : status;

      if (CAMPAIGN_FINAL_STATUSES.includes(currentStateStatus)) {
        return state;
      } else {
        return campaignExportAdapter.upsertOne(
          { exportId, status: tempStatus, stages },
          { ...state, exporting, activeExportId: exportId },
        );
      }
    },
  ),
  on(
    CampaignExportActions.updateExportStatusFailure,
    (state, { exportId, status, stages, message }) => {
      return campaignExportAdapter.upsertOne(
        { exportId, status, stages },
        {
          ...state,
          exporting: false,
          loaded: false,
          message,
          error: message,
          activeExportId: exportId,
        },
      );
    },
  ),

  // Download Excel Export stage
  on(
    CampaignExportActions.downloadExportExcelSuccess,
    (state, { data, message }) => {
      return campaignExportAdapter.removeOne(data?.exportId, {
        ...state,
        exporting: false,
        message,
      });
    },
  ),
  on(
    CampaignExportActions.downloadExportExcelFailure,
    (state, { message, error, data }) => {
      return campaignExportAdapter.removeOne(data?.exportId, {
        ...state,
        message,
        error,
        exporting: false,
      });
    },
  ),
);

export function CampaignExportReducer(
  state: CampaignExportState | undefined,
  action: Action,
) {
  return campaignExportReducer(state, action);
}

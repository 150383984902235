import { iif, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client';
import { Coerce } from 'ssotool-app/shared';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { CampaignExportService } from '../../services/campaign-export.service';
import { CampaignExportActions } from './campaign-export.action';
import { CampaignExportFacadeService } from './campaign-export.facade.service';
import {
  CAMPAIGN_EXPORT_STATUS,
  CampaignExportModel,
} from './campaign-export.model';
import { ToastService } from 'ssotool-app/shared/services/toast/toast.service';

@Injectable()
export class CampaignExportEffects {
  getListCampaignsExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignExportActions.getListCampaignsExport),
      mergeMap((action) =>
        this.campaignExportService.getListCampaignsExport(action.clientId).pipe(
          map((response: CampaignExportModel[]) => {
            return CampaignExportActions.getListCampaignsExportSuccess({
              clientId: action.clientId,
              data: response,
              message: 'Get list of campaigns export success!',
            });
          }),
          catchError((error) =>
            of(
              CampaignExportActions.getListCampaignsExportFailure({
                data: action,
                error:
                  (error.error || {}).error ||
                  'Get list of campaigns export error!',
                message:
                  (error.error || {}).error ||
                  'Get list of campaigns export error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getListCampaignsExportSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignExportActions.getListCampaignsExportSuccess),
        map((action) => {
          return this.getOngoingExport(action.data);
        }),
        tap((latest) => {
          if (
            this.campaignExportService.isExcelGenerateDone(
              Coerce.toObject<CampaignExportModel>(latest).stages,
            )
          ) {
            this.campaignExportFacade.downloadExportExcel(
              Coerce.toObject<CampaignExportModel>(latest).clientId,
              Coerce.toObject<CampaignExportModel>(latest).exportId,
            );
          }
        }),
      ),
    { dispatch: false },
  );

  getListCampaignsExportFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignExportActions.getListCampaignsExportFailure),
      ),
    { dispatch: false },
  );

  updateExportStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignExportActions.updateExportStatus),
      map((action) => {
        return this.campaignExportService.isExportValid(
          Coerce.toEmptyObject(action),
        )
          ? CampaignExportActions.updateExportStatusSuccess(action)
          : CampaignExportActions.updateExportStatusFailure({
              ...action,
              message: 'Export campaign error!',
            });
      }),
    ),
  );

  updateExportStatusSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignExportActions.updateExportStatusSuccess),
        tap((action) => {
          if (this.campaignExportService.isExcelGenerateDone(action.stages)) {
            this.campaignExportFacade.downloadExportExcel(
              action.clientId,
              action.exportId,
            );
          }
        }),
      ),
    { dispatch: false },
  );

  updateExportStatusFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignExportActions.updateExportStatusFailure),
        tap((action) => {
          this.toastService.showToast(action.message, 'error');
        }),
      ),
    { dispatch: false },
  );

  downloadExportExcel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignExportActions.downloadExportExcel),
        mergeMap((action) => {
          return this.campaignExportService
            .downloadExport(action.clientId, action.exportId)
            .pipe(
              map((resp) => {
                if (resp.status) {
                  return CampaignExportActions.downloadExportExcelSuccess({
                    data: {
                      exportId: action.exportId,
                    },
                    message: 'success',
                  });
                } else {
                  return CampaignExportActions.downloadExportExcelFailure({
                    data: undefined,
                    error: 'error',
                    message: 'error',
                  });
                }
              }),
            );
        }),
      ),
    { dispatch: false },
  );

  cancelExportCampaigns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignExportActions.cancelExportCampaigns),
      mergeMap((action) =>
        this.campaignExportService
          .cancelExportCampaigns(action.clientId, action.exportId)
          .pipe(
            map(() =>
              CampaignExportActions.cancelExportCampaignsOK({
                clientId: action.clientId,
                exportId: action.exportId,
                message: 'Cancel campaign success!',
              }),
            ),
            catchError((err) =>
              iif(
                () => err.status === 409,
                of(
                  CampaignExportActions.cancelExportCampaignsPartial({
                    clientId: action.clientId,
                    exportId: action.exportId,
                    message:
                      'Campaign export is almost finished. Cancellation is not processed.',
                  }),
                ),
                of(
                  CampaignExportActions.cancelExportCampaignsNOK({
                    clientId: action.clientId,
                    exportId: action.exportId,
                    message: 'Error occured. Cancellation is not processed.',
                    error: (err.error || {}).error || 'Error',
                  }),
                ),
              ),
            ),
          ),
      ),
    ),
  );

  cancelExportCampaignsOK$ = createEffect(
    () =>
      this.actions$.pipe(ofType(CampaignExportActions.cancelExportCampaignsOK)),
    {
      dispatch: false,
    },
  );

  cancelExportCampaignsPartial$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignExportActions.cancelExportCampaignsPartial),
        tap((action) => this.toastService.showToast(action.message, 'info')),
      ),
    {
      dispatch: false,
    },
  );

  cancelExportCampaignsNOK$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignExportActions.cancelExportCampaignsNOK),
        tap((action) =>
          this.toastService.showToast(
            action.message,
            'action',
            undefined,
            of({}).pipe(
              tap(() =>
                this.campaignExportFacade.cancelExportCampaigns(
                  action.clientId,
                  action.exportId,
                ),
              ),
            ),
          ),
        ),
      ),
    {
      dispatch: false,
    },
  );

  exportCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignExportActions.exportSelectedCampaigns),
      mergeMap((action) =>
        this.campaignExportService
          .exportCampaign(action.clientId, action.campaigns)
          .pipe(
            map((response) => {
              return CampaignExportActions.exportSelectedCampaignsSuccess({
                clientId: action.clientId,
                data: {
                  exportId: response.exportId,
                  clientId: response.clientId,
                },
              });
            }),
            catchError((error) =>
              of(
                CampaignExportActions.exportSelectedCampaignsFailure({
                  data: action,
                  error: (error.error || {}).error || 'Export campaign error!',
                  message: (error.error || {}).error || 'Export client error!',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  exportSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignExportActions.exportSelectedCampaignsSuccess),
        tap((action) => {
          this.clientFacade.updateOngoingExport(action.clientId, true);
        }),
      ),
    { dispatch: false },
  );

  exportFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignExportActions.exportSelectedCampaignsFailure),
        tap((action) =>
          this.toastService.showToast(
            action.message,
            'action',
            undefined,
            of({}).pipe(
              tap(() =>
                this.campaignExportFacade.exportCampaign(
                  action.data.clientId,
                  action.data.campaigns,
                ),
              ),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private campaignExportService: CampaignExportService,
    private toastService: ToastService,
    private campaignExportFacade: CampaignExportFacadeService,
    private clientFacade: ClientFacadeService,
  ) {}

  private getOngoingExport(
    data: CampaignExportModel[],
  ): CampaignExportModel | undefined {
    const ongoingExport = data.filter(
      (data: CampaignExportModel) =>
        data.status === CAMPAIGN_EXPORT_STATUS.ONGOING,
    );
    return ongoingExport.length ? ongoingExport[0] : undefined;
  }
}

import { Component, EventEmitter, Output } from '@angular/core';
import { Input } from '@angular/core';
import { ToolCardData } from './tool-card.model';

@Component({
  selector: 'sso-tool-card',
  templateUrl: './tool-card.component.html',
  styleUrl: './tool-card.component.scss',
})
export class ToolCardComponent {
  @Output() opened = new EventEmitter<ToolCardData['id']>();
  @Input() data: ToolCardData = {
    icon: '',
    id: '',
    routeId: '',
  };

  onActivateTool() {
    this.opened.emit(this.data.id);
  }
}

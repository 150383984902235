export const BASE_FILENAME = 'roadmap_chart_';

export const BASE_TEXT_OPTS_L = {
  color: '9F9F9F',
  margin: 3,
  border: [
    null,
    null,
    {
      pt: 1,
      color: 'CFCFCF',
    },
    null,
  ],
};

export const BASE_TEXT_OPTS_R = {
  text: 'SSo Tool',
  options: {
    color: '9F9F9F',
    margin: 3,
    border: [
      0,
      0,
      {
        pt: 1,
        color: 'CFCFCF',
      },
      0,
    ],
    align: 'right',
  },
};

export const BASE_TABLE_OPTS = {
  x: 0.5,
  y: 0.13,
  colW: [9, 33],
};

export const ICHART_OPTIONS = {
  x: 1,
  y: 1,
  w: 8,
  h: 4,
};

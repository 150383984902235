import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { mergeMap, startWith, tap } from 'rxjs/operators';
import { distinctObject } from 'ssotool-shared/helpers/reactive-operator';

import { Directive, Injectable, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';

import {
  FilterConditionValue,
  FiltersDialogConfiguration,
} from '../filters-dialog/filters-dialog.model';
import {
  FilterSettings,
  FilterWithCondition,
  FilterWithConditionData,
} from './filters.model';

export type OtherConditionFunction<T> = (datum: T) => boolean;

@Injectable()
export class FiltersService {
  dialogConfig: FiltersDialogConfiguration = {};
  constructor() {}

  createFilterOptions<T = any>(
    filters: string[],
    data: T[],
    filterLabelMapper?: Record<string, string>,
    convertToString?: boolean,
    settings?: FilterSettings,
    excludeConditionFn?: (datum: any) => boolean,
  ): Record<string, string[]> {
    return filters.reduce((acc, filter) => {
      const renamedFilter = filterLabelMapper[filter] || filter;

      acc[renamedFilter] = [];

      (data || []).forEach((datum) => {
        const filterAttributeValue = convertToString
          ? datum?.[filter]?.toString()
          : this.getEntity(filter, datum, settings)?.filter(
              (value) => !excludeConditionFn?.(value),
            );

        if (
          filterAttributeValue?.length &&
          !excludeConditionFn?.(filterAttributeValue)
        ) {
          acc[renamedFilter] = acc[renamedFilter].concat(filterAttributeValue);
        }
      });

      acc[renamedFilter] = [...new Set(acc[renamedFilter])].sort();

      return acc;
    }, {});
  }

  initializeFilterControls(
    filterControl: FormControl,
    changeTriggers$: Observable<any>[],
    patchControls: FormControl[],
    filterControlFunction?: (
      filters: FilterWithCondition,
      level?: string,
    ) => Observable<any>,
    level$: Observable<string> = of(''),
  ): Subscription {
    const subscriptions = combineLatest([
      filterControl.valueChanges.pipe(startWith({}), distinctObject),
      level$?.pipe(startWith('')) || of(''),
      ...changeTriggers$,
    ])
      .pipe(
        tap(([filters, ..._]) =>
          patchControls.forEach((control) =>
            control.patchValue(filters, { emitEvent: false }),
          ),
        ),
        mergeMap(
          ([filters, level, ..._]) =>
            filterControlFunction?.(filters, level) || of({}),
        ),
      )
      .subscribe();

    patchControls.forEach((control) =>
      subscriptions.add(
        control.valueChanges.subscribe((filters) =>
          filterControl.patchValue(filters),
        ),
      ),
    );

    return subscriptions;
  }

  filterObjectsWithCondition<T>(
    data: T[],
    filterObject: FilterWithCondition,
    others: OtherConditionFunction<T>[] = [],
    settings: FilterSettings = {},
  ) {
    return data?.filter(
      (datum) =>
        this.filterObjectWithCondition<T>(datum, filterObject, settings) &&
        others.every((conditionFn) => conditionFn(datum)),
    );
  }

  private filterObjectWithCondition<T>(
    datum: T,
    filterObject: FilterWithCondition,
    settings: FilterSettings,
  ): boolean {
    return Object.entries(filterObject || {}).every(([key, filter]) => {
      const newKey = settings?.keyMapper?.[key] || key;
      const entity = this.getEntity(newKey, datum, settings);

      if (!entity) {
        return false;
      }

      return (
        !filter ||
        this.checkFilterCondition(newKey, [].concat(entity), filter, settings)
      );
    });
  }

  private getEntity<T>(
    key: string,
    datum: T,
    settings: FilterSettings,
  ): string[] {
    const objectArrayMapperKey = settings?.objectArray?.[key];
    const entity = datum?.[key];

    if (objectArrayMapperKey) {
      return entity?.map((item) => item?.[objectArrayMapperKey]);
    }

    return [].concat(entity).map((e) => settings?.valueMapper?.[e] || e);
  }

  protected checkFilterCondition(
    key: string,
    dataToCompare: string[],
    filterDatum: FilterWithConditionData,
    settings?: FilterSettings,
  ): boolean {
    const removedIndicatorFilterValues = filterDatum?.values?.map((value) =>
      this.removeValueIndicators(value, settings?.valueIndicators),
    );

    switch (filterDatum.condition) {
      case FilterConditionValue.IS:
        return !!this.getValuesIntersection(
          dataToCompare,
          removedIndicatorFilterValues,
        );
      case FilterConditionValue.IS_NOT:
        return !this.getValuesIntersection(
          dataToCompare,
          removedIndicatorFilterValues,
        );
      case FilterConditionValue.IS_PART_OF:
        return !!this.getValuesIntersection(
          dataToCompare,
          this.getReferenceValues(
            removedIndicatorFilterValues,
            settings?.isPartOfReferences?.[key],
          ),
        );
      default:
        return true;
    }
  }

  private removeValueIndicators(value: string, indicators: string[]) {
    return (indicators || []).reduce(
      (acc, indicator) => acc.replace(indicator, ''),
      value,
    );
  }

  private getValuesIntersection(data: string[], filterValues: string[]) {
    return filterValues?.some((value) => data?.includes(value));
  }

  private getReferenceValues(
    filterValues: string[],
    references: Record<string, string[]>,
  ): string[] {
    return filterValues.reduce(
      (acc, value) => acc.concat(references?.[value] || []),
      [],
    );
  }
}

@Directive()
export abstract class FilteredComponent implements OnDestroy {
  protected filterSubscriptions = new Subscription();
  abstract filtersDialogConfig: FiltersDialogConfiguration;

  constructor(filtersService: FiltersService) {}

  ngOnDestroy(): void {
    this.filterSubscriptions.unsubscribe();
  }

  protected abstract initializeFiltersAndLevel(): void;
  protected filterControlFunction?(
    filters: FilterWithCondition,
    level?: string,
  );

  private optionalRunner() {
    this.filterControlFunction?.(null, null);
  }
}

import { Coerce } from 'ssotool-app/shared/helpers';

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { CampaignActions } from './campaign.action';

export const campaignFeatureKey = 'Campaigns';

// TODO: Update model
export interface CampaignState extends EntityState<any> {
  loading: boolean;
  exporting?: boolean;
  error: string;
  message: string;
  fetched: boolean;
}

export const selectCampaignId = (campaign: any) => campaign?.id;
export const sortName = (campaign1: any, campaign2: any) =>
  campaign1?.name?.localeCompare(campaign2?.name);

export const campaignAdapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: selectCampaignId,
  sortComparer: sortName,
});

export const initialCampaignState: CampaignState =
  campaignAdapter.getInitialState({
    loading: false,
    error: undefined,
    message: undefined,
    fetched: false,
    exporting: false,
  });

const campaignReducer = createReducer(
  initialCampaignState,

  // Create campaign
  on(CampaignActions.create, (state) => {
    return { ...state, loading: true, error: undefined };
  }),
  on(CampaignActions.createSuccess, (state, { data, message }) => {
    return campaignAdapter.upsertOne(
      { ...data, loaded: true },
      { ...state, loading: false, message },
    );
  }),
  on(CampaignActions.createFailure, (state, { message, error }) => {
    return { ...state, error, message, loading: false };
  }),

  // Update campaign
  on(CampaignActions.update, (state, { id }) => {
    return campaignAdapter.upsertOne({ id, loading: true }, state);
  }),
  on(CampaignActions.updateSuccess, (state, { data, message }) => {
    return campaignAdapter.upsertOne(
      { ...data, loading: false },
      { ...state, message },
    );
  }),
  on(CampaignActions.updateFailure, (state, { message, error, data }) => {
    return campaignAdapter.upsertOne(
      { id: data?.id, loading: false },
      { ...state, message, error },
    );
  }),

  // Get campaign
  on(CampaignActions.get, (state, { id }) => {
    return campaignAdapter.upsertOne({ id, loading: true }, { ...state });
  }),
  on(CampaignActions.getSuccess, (state, { data, message }) => {
    return campaignAdapter.upsertOne(
      {
        ...data,
        loaded: true,
        loading: false,
      },
      { ...state, error: undefined, message },
    );
  }),
  on(CampaignActions.getFailure, (state, { data, error, message }) => {
    return campaignAdapter.upsertOne(
      { ...data, loading: false, loaded: false },
      { ...state, error, message },
    );
  }),

  // Get campaigns
  on(CampaignActions.getList, (state) => {
    return { ...state, loading: true, error: undefined };
  }),
  on(CampaignActions.getListSuccess, (state, { data, message }) => {
    return campaignAdapter.upsertMany(data, {
      ...state,
      loading: false,
      loaded: true,
      message,
      fetched: true,
    });
  }),
  on(CampaignActions.getListFailure, (state, { error, message }) => {
    return { ...state, loading: false, error, message, fetched: false };
  }),

  on(CampaignActions.clearList, () => {
    return campaignAdapter.getInitialState() as CampaignState;
  }),

  // Delete campaign
  on(CampaignActions.deleteCampaign, (state, { id }) => {
    return campaignAdapter.upsertOne({ id, loading: true }, state);
  }),
  on(CampaignActions.deleteCampaignSuccess, (state, { data, message }) => {
    return campaignAdapter.removeOne(data?.id, {
      ...state,
      message,
    });
  }),
  on(
    CampaignActions.deleteCampaignFailure,
    (state, { message, error, data }) => {
      return campaignAdapter.upsertOne(
        { id: data?.id, loading: false },
        { ...state, message, error },
      );
    },
  ),

  // Delete multiple campaign
  on(CampaignActions.deleteCampaigns, (state, { campaigns }) => {
    const data = Coerce.getObjKeys(campaigns).map((id) => ({
      id,
      loading: true,
    }));

    return campaignAdapter.upsertMany(data, {
      ...state,
    });
  }),
  on(CampaignActions.deleteCampaignsSuccess, (state, { data, message }) => {
    return campaignAdapter.removeMany(data.ids, {
      ...state,
      message,
    });
  }),
  on(
    CampaignActions.deleteCampaignsFailure,
    (state, { message, error, data }) => {
      const campaigns = Coerce.getObjKeys(data.campaigns).map((id) => ({
        id,
        loading: false,
      }));
      return campaignAdapter.upsertMany(campaigns, {
        ...state,
        message,
        error,
      });
    },
  ),

  // Populate campaign
  on(CampaignActions.populateWithLibrary, (state) => {
    return { ...state, loading: true };
  }),
  on(CampaignActions.populateWithLibrarySuccess, (state, { message }) => {
    return { ...state, loading: false, message };
  }),
  on(
    CampaignActions.populateWithLibraryFailure,
    (state, { error, message }) => {
      return {
        ...state,
        loading: false,
        error,
        message,
      };
    },
  ),

  // Duplicate multiple campaign
  on(CampaignActions.batchDuplicateCampaigns, (state) => {
    return { ...state, loading: true };
  }),
  on(CampaignActions.batchDuplicateCampaignsSuccess, (state, { message }) => {
    return { ...state, loading: false, message };
  }),
  on(
    CampaignActions.batchDuplicateCampaignsFailure,
    (state, { error, message }) => {
      return {
        ...state,
        loading: false,
        error,
        message,
      };
    },
  ),

  on(CampaignActions.resetIndividualCampaignLoadedState, (state) =>
    campaignAdapter.upsertMany(
      Coerce.getObjValues(state.entities).map((entity) => ({
        ...entity,
        loaded: false,
      })),
      state,
    ),
  ),
);

export function CampaignReducer(
  state: CampaignState | undefined,
  action: Action,
) {
  return campaignReducer(state, action);
}

import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  roadmapImportAdapter,
  roadmapImportFeatureKey,
  RoadmapImportState,
} from './roadmap-import.reducer';

const { selectEntities, selectAll } = roadmapImportAdapter.getSelectors();

export const roadmapImportFeatureState =
  createFeatureSelector<RoadmapImportState>(roadmapImportFeatureKey);

export const roadmapImportEntities = createSelector(
  roadmapImportFeatureState,
  selectEntities,
);

export const roadmapImportList = createSelector(
  roadmapImportFeatureState,
  selectAll,
);

export const loading = createSelector(
  roadmapImportFeatureState,
  (state) => state.loading,
);

export const loaded = createSelector(
  roadmapImportFeatureState,
  (state) => state.loaded,
);

export const downloadingTemplate = createSelector(
  roadmapImportFeatureState,
  (state) => state.downloadingTemplate,
);

export const importLoading = ({ importId }: { importId: string }) =>
  createSelector(
    roadmapImportEntities,
    (entities) => entities?.[importId]?.loading,
  );

export const importLogsLoading = ({ importId }: { importId: string }) =>
  createSelector(
    roadmapImportEntities,
    (entities) => entities?.[importId]?.logsLoading,
  );

export const logsLoaded = (importId: string) =>
  createSelector(
    roadmapImportEntities,
    (entities) => entities?.[importId]?.logsLoaded,
  );

export const importDownloading = ({ importId }: { importId: string }) =>
  createSelector(
    roadmapImportEntities,
    (entities) => entities?.[importId]?.downloading,
  );

export const getLogs = (importId: string) => {
  return createSelector(
    roadmapImportEntities,
    (dataList) => dataList?.[importId]?.messages,
  );
};

export const getRoadmapImportList = (props: any) =>
  createSelector(roadmapImportList, (roadmapList) =>
    roadmapList.filter(
      (roadmap) =>
        roadmap.clientId === props.clientId &&
        roadmap.roadmapId === props.roadmapId,
    ),
  );

export const importing = createSelector(
  roadmapImportFeatureState,
  (state) => state.importing,
);

<div
  class="container"
  fxLayout="column"
  fxLayoutAlign="center start"
  fxLayoutGap="8px"
  (click)="onActivateTool()"
>
  <h2>{{ 'Client.labels.tools.' + data.id + '.name' | translate }}</h2>
  <p>
    {{ 'Client.labels.tools.' + data.id + '.description' | translate }}
  </p>
  <a class="sso-clickable" (click)="onActivateTool()">{{
    'Client.labels.tools.buttons.openTool' | translate | uppercase
  }}</a>
</div>

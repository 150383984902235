import { Coerce } from 'ssotool-app/shared/helpers/coerce.utils';
import {
  ClientReferenceData,
  ResultReferenceEntities,
  ResultReferenceCampaign,
} from '../stores/result';
import { BaseGraphMapper } from './base-graph.mapper';

export class EconomicsCurvesMapper extends BaseGraphMapper {
  protected static BASE_COLOR = '#faca08';

  static mapEconomicsCurves = (
    data,
    entities: ResultReferenceEntities,
    clientData: ClientReferenceData,
    campaignData: ResultReferenceCampaign,
  ) => {
    const unit = data?.CAPEX?.[0]?.unit || '';
    const capex = EconomicsCurvesMapper.mapEntitiesNameAndIndex(
      data.CAPEX,
      entities,
      'CAPEX',
      data.index,
      clientData,
      campaignData,
    );
    const opex = EconomicsCurvesMapper.mapEntitiesNameAndIndex(
      data.OPEX,
      entities,
      'OPEX',
      data.index,
      clientData,
      campaignData,
    );
    const capex_investment_year = EconomicsCurvesMapper.mapEntitiesNameAndIndex(
      Coerce.toArray(data['CAPEX Investment Year']),
      entities,
      'CAPEX Investment Year',
      data.index,
      clientData,
      campaignData,
    );

    const tdc = capex.concat(opex);

    const tdc_stacked_bar = EconomicsCurvesMapper.applySKtoPK(
      tdc,
      'lever',
      'kpiType',
    );

    const economicsCurves = {
      capex: { data: capex, unit },
      opex: { data: opex, unit },
      tdc_stacked_bar: { data: tdc_stacked_bar, unit },
      tdc_pie: { data: tdc, unit },
      tdc_waterfall: { data: tdc, unit },
      capex_investment_year: { data: capex_investment_year, unit },
    };

    return economicsCurves;
  };

  static mapEconomicsCurvesv2 = (data) => {
    const tdc = data.capex.concat(data.opex);
    const tdc_stacked_bar = EconomicsCurvesMapper.applySKtoPK(
      tdc,
      'lever',
      'kpiType',
    );

    const economicsCurves = {
      ...data,
      tdc,
      tdc_sk: tdc_stacked_bar,
    };

    return economicsCurves;
  };
}

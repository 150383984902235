import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

/**
 * This data service is a component helper that
 * serves as data management. For instance, the component
 * serves a CRUD service thus making the managing of data easierimply calling
 * the built-in functions from this service.
 * @param listData - the initial list of data from the component
 * @param data - the single value for the CRUD service
 */
@Injectable()
export class ManagedDataService<T> {

  private _listData$ = new BehaviorSubject<Array<T>>([]);

  listData$ = this._listData$.asObservable();

  constructor() { }

  add(data: T): void {
    const listData = this.getListData();
    listData.push(data);
    this.emit(listData);
  }

  edit(data: T, newData: T): void {
    const listData = this.exclude(data);
    listData.push(newData);
    this.emit(listData);
  }

  delete(data: T): void {
    this.emit(this.exclude(data));
  }

  search(data: T): T {
    return this.getListData().find(findData => findData === data);
  }

  exclude(data: T): Array<T> {
    return this.getListData().filter(filterData => filterData !== data);
  }

  setListData(listData: Array<T>): void {
    this._listData$.next(listData);
  }

  getListData(): Array<T> {
    return [...this._listData$.getValue()];
  }

  private emit(listData: Array<T>): void {
    this.setListData([...listData]);
  }

}

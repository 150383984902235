<span mat-dialog-title>
  <h2 data-testid="title">
    {{
      data?.title || 'Generic.labels.colorPicker.title'
        | translate: { name: data?.name }
    }}
  </h2>
</span>

<mat-dialog-content fxLayout="column" fxLayoutAlign="center center">
  <div
    [(colorPicker)]="color"
    [cpPresetColors]="data?.presets"
    cpDialogDisplay="inline"
    [cpToggle]="true"
  ></div>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="end">
  <button mat-flat-button color="" mat-dialog-close data-testid="close-button">
    {{ data?.close || 'Generic.labels.cancel' | translate }}
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="onConfirm()"
    data-testid="confirm-button"
  >
    {{ data?.confirm || 'Generic.labels.ok' | translate }}
  </button>
</mat-dialog-actions>

import { map, tap } from 'rxjs/operators';
import { BackendCurveCompare, CurveCompare } from 'ssotool-app/+roadmap';
import { generateEndpoint } from 'ssotool-core/utils';
import {
  EconomicsCurvesMapper,
  EnvironmentalCurvesMapper,
  SustainabilityCurvesMapper,
} from 'ssotool-roadmap/services';
import { ConfigService } from 'ssotool-shared/services/config';
import {
  download,
  Download,
  processDownloadedData,
} from 'ssotool-shared/services/download';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BasePortfolioAPIService } from '../portfolio.service';
import { CompareFacadeService } from './compare-facade.service';
import { CompareData } from './compare.model';
import { TEMPORARY_ID } from './compare.reducer';

@Injectable()
export class CompareApiService extends BasePortfolioAPIService {
  /**
   * Request to trigger the start of the comparison result processing
   * @param clientId id of the client
   * @param referenceRoadmapId id of the reference roadmap
   * @param roadmapIds ids of the roadmaps to be compared
   * @returns response of the trigger
   */
  getComparisonResult(
    clientId: string,
    referenceRoadmapId: string,
    roadmapIds: string[],
  ) {
    return this.httpClient.post(this.makeGetEndpoint(clientId), {
      referenceRoadmapId,
      roadmapIds,
    });
  }

  /**
   * Request to download the comparison data and map it for display.
   * @param signedUrl generated download signed url of the comparison data.
   * @param filesize total size of the comparison data.
   * @returns mapped comparison data.
   */
  downloadComparisonResult(signedUrl: string, filesize: number) {
    // return this.httpClient.get('assets/mocks/compare.mock.json').pipe(map((res) => this.mapToFrontend(res as CompareData))); // MOCK
    return this.httpClient
      .get(signedUrl, {
        reportProgress: true,
        observe: 'events',
        responseType: 'blob',
      })
      .pipe(
        download(filesize),
        tap((data: Download) =>
          this.compareFacade.updateLoadedProgress(data.progress),
        ),
        processDownloadedData<string>(),
        map((result: string) => this.mapToFrontend(JSON.parse(result))),
      );
  }

  mapToBackend(entity: any): any {
    return { ...entity };
  }

  mapToFrontend(backendModel): CompareData {
    return {
      ...backendModel,
      curves: this.mapCurves(backendModel.curves),
      id: TEMPORARY_ID,
    };
  }

  makeGetEndpoint(clientId: string): string {
    const { baseUrl, endpoints } = this.config.api;
    return generateEndpoint(
      baseUrl,
      endpoints.portfolio.getComparisonResults,
      clientId,
    );
  }

  makeGetAllEndpoint(clientId: string): string {
    return 'assets/mocks/compare.mock.json';
  }

  mapCurves(curves: BackendCurveCompare): CurveCompare {
    Object.entries(curves).map(([key, curve]) => {
      curves[key] = {
        ...curve,
        economics: EconomicsCurvesMapper.mapEconomicsCurvesv2(curve.economics),
        environmental: EnvironmentalCurvesMapper.mapEnvironmentalCurvesv2(
          curve.environmental,
        ),
        sustainability: SustainabilityCurvesMapper.mapSustainabilityCurvesv2(
          curve.sustainability,
        ),
      };
    });
    return curves;
  }

  constructor(
    private httpClient: HttpClient,
    private config: ConfigService,
    private compareFacade: CompareFacadeService,
  ) {
    super(httpClient);
  }
}

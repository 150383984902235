import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PartialRoadmapVariation } from './roadmap-variation.model';
import {
  createRoadmapVariationId,
  ROADMAP_VARIATION_FEATURE_NAME,
  roadmapVariationAdapter,
  RoadmapVariationState,
} from './roadmap-variation.reducer';

const { selectIds, selectEntities, selectAll } =
  roadmapVariationAdapter.getSelectors();

const variationFeatureState = createFeatureSelector<RoadmapVariationState>(
  ROADMAP_VARIATION_FEATURE_NAME,
);

export const selectVariationEntities = createSelector(
  variationFeatureState,
  selectEntities,
);

export const selectVariationList = createSelector(
  variationFeatureState,
  selectAll,
);

export const selectLoading = createSelector(
  variationFeatureState,
  (state) => state.loading,
);

export const selectLoaded = createSelector(
  variationFeatureState,
  (state) => state.loaded,
);

export const selectVariations = ({ roadmapId }: { roadmapId: string }) =>
  createSelector(selectVariationList, (data: PartialRoadmapVariation[]) =>
    data.filter((datum) => datum.roadmapId === roadmapId),
  );

export const selectVariation = (props: PartialRoadmapVariation) =>
  createSelector(
    selectVariationEntities,
    (entities) =>
      entities[createRoadmapVariationId(props)] as PartialRoadmapVariation,
  );

export const selectVariationLoading = (props: PartialRoadmapVariation) =>
  createSelector(
    selectVariationEntities,
    (entities) => !!entities[createRoadmapVariationId(props)]?.loading,
  );

export const selectVariationLoaded = (props: PartialRoadmapVariation) =>
  createSelector(
    selectVariationEntities,
    (entities) => !!entities[createRoadmapVariationId(props)]?.loaded,
  );

export const selectVariationAccessed = (props: PartialRoadmapVariation) =>
  createSelector(
    selectVariationEntities,
    (entities) => !!entities[createRoadmapVariationId(props)]?.accessed,
  );

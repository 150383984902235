import { filter, first, mergeMap, tap } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client/store/client.facade.service';

import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { CampaignExportFacadeService } from '../store/export';

@Injectable()
export class CampaignExportResolverService implements Resolve<any> {
  constructor(
    private campaignExportFacade: CampaignExportFacadeService,
    private clientFacade: ClientFacadeService,
  ) {}

  resolve(): void {
    this.clientFacade.selectActiveClientData$
      .pipe(
        filter((client) => !!client),
        first(),
        mergeMap((client) =>
          this.campaignExportFacade.loaded$.pipe(
            filter((loaded) => !loaded),
            first(),
            tap((_) =>
              this.campaignExportFacade.getListCampaignsExport(client.clientId),
            ),
          ),
        ),
      )
      .subscribe();
  }
}

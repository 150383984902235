import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { KPIWidgetComponent } from './kpi-widget.component';

@NgModule({
  declarations: [KPIWidgetComponent],
  imports: [CommonModule],
  exports: [KPIWidgetComponent],
})
export class KPIWidgetModule {}

<mat-list-item
  fxLayout="row"
  fxLayoutAlign="start center"
  [ngClass]="{
    active: (active$ | async) || active,
    resultsonly: hasDisabledOverlay()
  }"
  class="left-item overlay"
  ssoSelectionEmitter
  (selectClick)="onSelectClick($event)"
  [selectable]="!hasDisabledOverlay()"
>
  <ng-content></ng-content>
  <ng-content></ng-content>
</mat-list-item>

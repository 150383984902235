import { of } from 'rxjs';
import { catchError, first, map, mergeMap, tap } from 'rxjs/operators';
import { createErrorObject } from 'ssotool-app/shared/helpers';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ProfileActions } from './profile.actions';
import { ProfileFacadeService } from './profile.facade.service';
import { ProfileService } from './profile.service';
import { ToastService } from 'ssotool-app/shared/services/toast/toast.service';

@Injectable()
export class ProfileEffects {
  getProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.getProfiles),
      mergeMap((action) =>
        this.profileService.getProfiles(action.clientId).pipe(
          map(() =>
            ProfileActions.getProfilesSuccess({
              clientId: action.clientId,
              message: 'Get profiles success!',
            }),
          ),
          catchError((error) => {
            return of(
              ProfileActions.getProfilesFailure(
                createErrorObject(error, 'Get profiles list error!'),
              ),
            );
          }),
        ),
      ),
    ),
  );

  getProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.getProfile),
      mergeMap((action) =>
        this.profileService
          .getSiteProfile(
            action.clientId,
            action.profileId,
            action.geoId,
            action.force,
          )
          .pipe(
            map(() =>
              ProfileActions.getProfilesSuccess({
                clientId: action.clientId,
                message: 'Get Profile success',
              }),
            ),
            catchError((error) => {
              return of(
                ProfileActions.getProfilesFailure(
                  createErrorObject(error, 'Get profiles list error!'),
                ),
              );
            }),
          ),
      ),
    ),
  );

  upsertProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.upsertProfile),
      mergeMap((action) =>
        this.profileService
          .upsertProfile(
            action.data,
            action.clientId,
            action.profileId,
            action.geoId,
          )
          .pipe(
            map(() => ProfileActions.upsertProfileSuccess()),
            catchError(() =>
              of(
                ProfileActions.upsertProfileFailure({
                  data: action.data,
                  clientId: action.clientId,
                  profileId: action.profileId,
                  geoId: action.geoId,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  upsertProfileSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.upsertProfileSuccess),
        tap(() => this.toast.showToast('Upsert profile started.', 'info')),
      ),
    { dispatch: false },
  );

  upsertProfileFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.upsertProfileFailure),
        tap((action) => {
          this.toast.showToast(
            'Update profile failed!',
            'action',
            undefined,
            of({}).pipe(
              first(),
              tap(() => {
                this.profileFacade.upsertProfile(
                  action.clientId,
                  action.profileId,
                  action.geoId,
                  action.data,
                );
              }),
            ),
          );
        }),
      ),
    { dispatch: false },
  );

  deleteProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.deleteProfile),
      mergeMap((action) =>
        this.profileService
          .deleteProfile(action.clientId, action.profileId, action.geoId)
          .pipe(
            map(() => ProfileActions.deleteProfileSuccess()),
            catchError(() =>
              of(
                ProfileActions.deleteProfileFailure({
                  clientId: action.clientId,
                  profileId: action.profileId,
                  geoId: action.geoId,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  deleteProfileSuccess$ = createEffect(
    () => this.actions$.pipe(ofType(ProfileActions.deleteProfileSuccess)),
    { dispatch: false },
  );

  deleteProfileFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.deleteProfileFailure),
        tap((action) => {
          this.toast.showToast(
            'Delete profile failed!',
            'action',
            undefined,
            of({}).pipe(
              first(),
              tap(() => {
                this.profileFacade.deleteProfile(
                  action.clientId,
                  action.profileId,
                  action.geoId,
                );
              }),
            ),
          );
        }),
      ),
    { dispatch: false },
  );

  updateModifyStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.updateModifyStatus),
        tap((action) => {
          if (action.code === 200) {
            this.toast.showToast('Update profile success!', 'success');
          } else {
            this.toast.showToast('Update profile failed!', 'error');
          }

          this.profileFacade.getProfile(
            action.clientId,
            action.profileId,
            action.geoId,
            true,
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private profileService: ProfileService,
    private profileFacade: ProfileFacadeService,
    private toast: ToastService,
  ) {}
}

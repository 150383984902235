/**
 * A custom implementation of the TranslateLoader interface from @ngx-translate/core.
 * This loader is responsible for loading translations from multiple resources using HTTP requests.
 * RATIONAL:
 * - The multi-translate-http-loader does not work with interceptors.
 * - Therefore, new headers are added to the request to skip the interceptors.
 *   https://stackoverflow.com/questions/69051268/ngx-translate-multi-http-loader-something-went-wrong-for-the-following-transla
 */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import merge from 'deepmerge';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export class MultiTranslateHttpLoader implements TranslateLoader {
  /**
   * Constructs a new instance of the MultiTranslateHttpLoader class.
   * @param http The HttpClient instance used to make HTTP requests.
   * @param resources An array of resource objects that define the prefix and suffix for each translation file.
   */
  constructor(
    private http: HttpClient,
    private resources: {
      prefix: string;
      suffix: string;
    }[],
  ) {}

  /**
   * The header name used to skip interceptors for HTTP requests made by this loader.
   */
  static InterceptorSkipHeader = 'X-Skip-Interceptor';

  /**
   * Retrieves the translation data for the specified language.
   * @param lang The language code for the desired translation.
   * @returns An Observable that emits the translation data.
   */
  public getTranslation(lang: string): Observable<any> {
    const headers = new HttpHeaders().set(
      MultiTranslateHttpLoader.InterceptorSkipHeader,
      '',
    );

    const requests = this.resources.map((resource) => {
      const path = resource.prefix + lang + resource.suffix;

      return this.http.get(path, { headers });
    });

    return forkJoin(requests).pipe(map((response) => merge.all(response)));
  }
}

import { Chart } from 'chart.js';
import { isNullOrUndef, toLineHeight, valueOrDefault } from 'chart.js/helpers';

export const toFontString = (font) => {
  if (!font || isNullOrUndef(font.size) || isNullOrUndef(font.family)) {
    return null;
  }

  return (
    (font.style ? font.style + ' ' : '') +
    (font.weight ? font.weight + ' ' : '') +
    font.size +
    'px ' +
    font.family
  );
};

// @todo move this in Chart.canvas.textSize
// @todo cache calls of measureText if font doesn't change?!
export const textSize = (ctx, lines, font) => {
  var items = [].concat(lines);
  var ilen = items.length;
  var prev = ctx.font;
  var width = 0;
  var i;

  ctx.font = font.string;

  for (i = 0; i < ilen; ++i) {
    width = Math.max(ctx.measureText(items[i]).width, width);
  }

  ctx.font = prev;

  return {
    height: ilen * font.lineHeight,
    width: width,
  };
};

export const parseFont = (value, height) => {
  var global = Chart.defaults;
  var size = valueOrDefault(value.size, global.font.size);

  if (value.resizable) {
    size = adaptTextSizeToHeight(height, value.minSize, value.maxSize);
  }

  var font = {
    family: valueOrDefault(value.family, global.font.family),
    lineHeight: toLineHeight(value.lineHeight, size),
    size: size,
    style: valueOrDefault(value.style, global.font.style),
    weight: valueOrDefault(value.weight, null),
    string: '',
  };

  font.string = toFontString(font);
  return font;
};

export const adaptTextSizeToHeight = (height, min, max) => {
  var size = (height / 100) * 2.5;
  if (min && size < min) {
    return min;
  }
  if (max && size > max) {
    return max;
  }
  return size;
};

<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
  <div fxLayout="column" [ngClass]="{ 'year-shown-container': showYears }">
    <div class="year-container" *ngIf="showYears">
      {{ minYear$ | async }}
    </div>
    <div class="value-container">
      {{ (minYearValue$ | async | metricScale)?.value }}
    </div>
  </div>
  <div [id]="canvasContainerId">
    <canvas [id]="generatedId" height="20px" width="50px"></canvas>
  </div>
  <div fxLayout="column" [ngClass]="{ 'year-shown-container': showYears }">
    <div class="year-container" *ngIf="showYears">
      {{ maxYear$ | async }}
    </div>
    <div class="value-container">
      {{ (maxYearValue$ | async | metricScale)?.value }}
    </div>
  </div>
</div>

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DATAHUB_FEATURE_NAME, DatahubFeatureState } from './datahub.reducer';

export const datahubFeatureState =
  createFeatureSelector<DatahubFeatureState>(DATAHUB_FEATURE_NAME);

const selectTrajectories = createSelector(
  datahubFeatureState,
  (state: DatahubFeatureState) => Object.values(state.trajectories),
);

const loading = createSelector(datahubFeatureState, (state) => state.loading);

const error = createSelector(datahubFeatureState, (state) => state.error);

const loaded = createSelector(datahubFeatureState, (state) => state.loaded);

const pristine = createSelector(
  datahubFeatureState,
  (state) => !state.loaded && !state.loading,
);

export const DatahubSelectors = {
  selectTrajectories,
  loading,
  error,
  loaded,
  pristine,
};

import { Dimensions, Feature } from 'ssotool-core/config';

import { Action } from '@ngrx/store';

export const pageFeatureName = 'page';

const typeCache: { [label: string]: boolean } = {};
export const createActionType = <T>(label: T | ''): T => {
  if (typeCache[label as string]) {
    throw new Error(`Action type '${label}' is already used.`);
  }
  typeCache[label as string] = true;
  return label as T;
};

export const PageActionTypes = {
  SET_BACKGROUND: createActionType(`[${pageFeatureName}] Set Background`),
  SET_NAME: createActionType(`[${pageFeatureName}] Set Name`),
  SET_SIDENAV_LINKS: createActionType(`[${pageFeatureName}] Set Sidenav Links`),
  SET_TITLE: createActionType(`[${pageFeatureName}] Set Title`),
  SET_TOOLBAR_LINKS: createActionType(`[${pageFeatureName}] Set Toolbar Links`),
  SET_DIMENSIONS: createActionType(
    `[${pageFeatureName}] Set Content Dimensions`,
  ),
};

export class PageSetBackgroundAction implements Action {
  readonly type = PageActionTypes.SET_BACKGROUND;
  constructor(public payload: string) {}
}

export class PageSetNameAction implements Action {
  readonly type = PageActionTypes.SET_NAME;
  constructor(public payload: string) {}
}
export class PageSetSidenavLinksAction implements Action {
  readonly type = PageActionTypes.SET_SIDENAV_LINKS;
  constructor(public payload: Array<Feature>) {}
}

export class PageSetTitleAction implements Action {
  readonly type = PageActionTypes.SET_TITLE;
  constructor(public payload: string) {}
}

export class PageSetToolbarLinksAction implements Action {
  readonly type = PageActionTypes.SET_TOOLBAR_LINKS;
  constructor(public payload: Array<Feature>) {}
}

export class PageSetDimensionsAction implements Action {
  readonly type = PageActionTypes.SET_DIMENSIONS;
  constructor(public payload: Dimensions) {}
}

export type PageActions =
  | any
  | PageSetBackgroundAction
  | PageSetNameAction
  | PageSetSidenavLinksAction
  | PageSetTitleAction
  | PageSetToolbarLinksAction
  | PageSetDimensionsAction;

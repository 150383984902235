import { Injectable } from '@angular/core';
import {
  TabFiltersState,
  TabFilterState,
  MAIN_TABS,
  SUB_TABS,
} from './roadmap-filter-storage.model';

@Injectable({
  providedIn: 'root',
})
/**TODO:
 * create a generic initialization process here.
 * So we do not need to create for each component every time.
 */
export class RoadmapFiltersStorage {
  private state: TabFiltersState = {};

  set(
    id: string,
    mainTab: MAIN_TABS,
    subTab: SUB_TABS | string,
    filters: TabFilterState,
  ): void {
    this.state = Object.assign(this.state, {
      [id]: Object.assign(this.state[id] || {}, {
        [mainTab]: subTab
          ? Object.assign(this.state[id]?.[mainTab] || {}, {
              [subTab]: filters,
            })
          : filters,
      }),
    });
  }

  get(id: string, mainTab: MAIN_TABS): TabFilterState {
    return this.state[id]?.[mainTab];
  }

  clear(): void {
    this.state = {};
  }

  constructor() {}
}

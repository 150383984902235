import { createAction, props } from '@ngrx/store';
import {
  FLUID_DEMAND_FEATURE_NAME,
  FluidDemandActionParams,
  FluidDemandActionParamsWithData,
  FluidDemand,
} from './fluid-demand.model';

function createActionType(
  verb: string,
  status: 'Success' | 'Failed' | '' = '',
): string {
  return `[${FLUID_DEMAND_FEATURE_NAME} API] ${verb} ${status}`;
}

const get = createAction(
  createActionType('Get'),
  props<FluidDemandActionParams>(),
);

const getSuccess = createAction(
  createActionType('Get', 'Success'),
  props<{ data: FluidDemand }>(),
);

const getFailed = createAction(
  createActionType('Get', 'Failed'),
  props<FluidDemandActionParamsWithData>(),
);

export const FluidDemandActions = {
  get,
  getSuccess,
  getFailed,
};

import { SsoCommifyModule } from 'ssotool-app/shared/directives/commify';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { YearlyForecastModule } from '@oculus/components/yearly-forecast';

import { LineChartModule } from '../line-chart/line-chart.module';
import { RangeInputModule } from '../range-input';
import { YearlyInputDialogComponent } from './yearly-input-dialog.component';

const components = [YearlyInputDialogComponent];
const modules = [
  FormsModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  LineChartModule,
  RangeInputModule,
  YearlyForecastModule,
  SsoCommifyModule,
  MatTooltipModule,
  MatIconModule,
  MatButtonModule,
  MatDialogModule,
];

@NgModule({
  declarations: components,
  imports: [CommonModule, ...modules],
  exports: components,
  providers: [],
})
export class YearlyInputDialogModule {}

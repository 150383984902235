export enum DrawerDisplayMode {
  CAMPAIGN = 'campaign',
  PROFILES = 'profiles',
}
export enum DrawerMode {
  EDIT = 'edit',
  CREATE = 'create',
  VIEW = 'view',
  EMPTY = 'empty',
}

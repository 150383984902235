import { HttpClient } from '@angular/common/http';

import { MultiTranslateHttpLoader } from './multi-translate-http-loader.util';

// istanbul ignore next
export function HttpLoaderFactory(http: HttpClient) {
  // istanbul ignore next
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/generic_', suffix: '.json' },
    { prefix: './assets/i18n/auth_', suffix: '.json' },
    { prefix: './assets/i18n/user_', suffix: '.json' },
    { prefix: './assets/i18n/client_', suffix: '.json' },
    { prefix: './assets/i18n/support_', suffix: '.json' },
    { prefix: './assets/i18n/gantt_', suffix: '.json' },
    { prefix: './assets/i18n/upload_', suffix: '.json' },
    { prefix: './assets/i18n/portfolio_', suffix: '.json' },
    { prefix: './assets/i18n/campaign_', suffix: '.json' },
    { prefix: './assets/i18n/dashboard_', suffix: '.json' },
    { prefix: './assets/i18n/ambition_', suffix: '.json' },
    { prefix: './assets/i18n/data_management_', suffix: '.json' },
    { prefix: './assets/i18n/entities_', suffix: '.json' },
    { prefix: './assets/i18n/roadmap_', suffix: '.json' },
    { prefix: './assets/i18n/request_', suffix: '.json' },
    { prefix: './assets/i18n/release_', suffix: '.json' },
    { prefix: './assets/i18n/profile_', suffix: '.json' },
    { prefix: './assets/i18n/logs_', suffix: '.json' },
    { prefix: './assets/help/contextHelp_', suffix: '.json' },
  ]);
}

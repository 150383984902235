import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { first } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client';


@Injectable()
export class EntityResolverService implements Resolve<any> {
  constructor(private clientFacade: ClientFacadeService) { }

  resolve(route: ActivatedRouteSnapshot) {
    this.clientFacade.selectActiveClientData$.subscribe(
        (client) => {
          if (client && client.clientId) {
            this.clientFacade
            .dataLoaded$(client.clientId)
            .pipe(first())
            .subscribe((loaded) => {
              if (!loaded) {
                this.clientFacade.getClientDataInfo(client.clientId);
              }
            });
          }
        });
    }
}

import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';

import { PageActionTypes, PageActions, pageFeatureName } from './page.actions';
import { PageState } from './page.model';


export const pageInitialState: PageState = {
  title: 'EYES',
};

export function pageReducer(state: PageState = pageInitialState, action: PageActions): PageState {
  switch (action.type) {
    case PageActionTypes.SET_BACKGROUND:
      return {
        ...state,
        background: action.payload,
      };
    case PageActionTypes.SET_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case PageActionTypes.SET_SIDENAV_LINKS:
      return {
        ...state,
        sideNavLinks: action.payload,
      };
    case PageActionTypes.SET_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    case PageActionTypes.SET_TOOLBAR_LINKS:
      return {
        ...state,
        toolbarLinks: action.payload,
      };
    case PageActionTypes.SET_DIMENSIONS:
      return {
        ...state,
        dimensions: action.payload,
      };
    default:
      return state;
  }
}

export const pageFeature: MemoizedSelector<object, PageState> = createFeatureSelector<PageState>(pageFeatureName);

export const pageSelectors = {
  state: pageFeature,
  background: createSelector(pageFeature, (state: PageState) => state.background),
  name: createSelector(pageFeature, (state: PageState) => state.name),
  sideNavLinks: createSelector(pageFeature, (state: PageState) => state.sideNavLinks),
  title: createSelector(pageFeature, (state: PageState) => state.title),
  toolbarLinks: createSelector(pageFeature, (state: PageState) => state.toolbarLinks),
  dimensions: createSelector(pageFeature, (state: PageState) => state.dimensions),
};

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ClientListPageComponent } from './client-list-page.component';
import { ClientResolverService } from './services';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: ClientListPageComponent,
    resolve: [ClientResolverService],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientRoutingModule {}

import { filter, map } from 'rxjs/operators';

import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { BaseTableComponent } from './base-table.component';
import { ManagedDataService } from './managed-data.service';

@Directive()
export abstract class ManagedTableComponent<T>
  extends BaseTableComponent<T>
  implements OnInit
{
  private __disabled: boolean;
  public get disabled(): boolean {
    return this.__disabled;
  }
  @Input()
  public set disabled(value: boolean) {
    this.__disabled = value;
  }
  @Input() set data(value: Array<T>) {
    this.managedData.setListData(value || []);
  }

  @Output() add = new EventEmitter<T>();
  @Output() edit = new EventEmitter<T>();
  @Output() delete = new EventEmitter<T>();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public managedData: ManagedDataService<T>,
  ) {
    super(ngControl, changeDetector);
  }

  dataSourceLength$ = this.managedData.listData$.pipe(
    filter((data) => !!data),
    map((data) => data.length),
    this.takeUntilShare(),
  );

  ngOnInit() {
    this.managedData.listData$.pipe(this.takeUntil()).subscribe((data) => {
      this.onChange(data);
      this.refreshData(data);
    });
  }

  writeValue(value: Array<T>) {
    this.managedData.setListData(value || []);
    super.writeValue(value);
  }

  onAdd(data?: T) {
    if (!!!data) {
      return;
    }
    this.managedData.add(data);
    this.add.emit(data);
  }

  onEdit(data?: T, newData?: T) {
    if (!!!data || !!!newData) {
      return;
    }
    this.managedData.edit(data, newData);
    this.edit.emit(newData);
  }

  onDelete(data?: T) {
    if (!!!data) {
      return;
    }
    this.managedData.delete(data);
    this.delete.emit(data);
  }
}

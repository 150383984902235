import { map, skipWhile } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client';
import { FlowBaseListComponent } from 'ssotool-app/+entities/containers/flow-base-list';
import { Coerce } from 'ssotool-app/shared/helpers';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { FlowVolumesBarChartDialogComponent } from './flow-volumes-bar-chart-dialog';
import { FlowVolumesHourlyDialogComponent } from './flow-volumes-hourly-dialog';

@UntilDestroy()
@Component({
  selector: 'sso-flow-volumes-list',
  templateUrl: './flow-volumes-list.component.html',
  styleUrls: ['./flow-volumes-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowVolumesListComponent
  extends FlowBaseListComponent
  implements OnInit
{
  constructor(
    public route: ActivatedRoute,
    private clientFacade: ClientFacadeService,
    private dialog: MatDialog,
  ) {
    super(route);
  }

  ngOnInit(): void {
    this.clientFacade.selectActiveClientData$
      .pipe(untilDestroyed(this))
      .subscribe((client) => {
        /* istanbul ignore else */
        if (client && client.clientId) {
          this.clientId = client.clientId;
          this.entities$ = this.clientFacade
            .selectFlowVolumes$(client.clientId)
            .pipe(
              skipWhile((data) => !data),
              map((data) =>
                Coerce.getObjValues(data)
                  .filter(
                    (flowvolumes) => flowvolumes[this.referenceKey] === this.ID,
                  )
                  .map((flowvolumes) => ({
                    ...flowvolumes,
                    geography: flowvolumes.geoName,
                    process: flowvolumes.processName,
                    fluid: flowvolumes.fluidName,
                    granularity:
                      flowvolumes.granularity?.charAt(0).toUpperCase() +
                      flowvolumes.granularity?.slice(1),
                  })),
              ),
            );
          this.loading$ = this.clientFacade.dataLoading$(client.clientId);
        }
      });
  }

  onClick(entity: any) {
    if (entity.granularity === 'Hourly') {
      this.dialog
        .open(FlowVolumesHourlyDialogComponent, {
          width: '80%',
          data: {
            location: entity.location,
            entityId: entity.flowVolumeId,
            clientId: this.clientId,
            values: entity.values,
            name: entity.granularity,
            fluidId: entity.fluidId,
          },
        })
        .afterClosed()
        .subscribe();
    } else {
      this.dialog
        .open(FlowVolumesBarChartDialogComponent, {
          width: '80%',
          data: {
            location: entity.location,
            entityId: entity.flowVolumeId,
            clientId: this.clientId,
            values: entity.values,
            name: entity.granularity,
            granularity: entity.granularity,
            fluidId: entity.fluidId,
            chartProps: this.generateChartProperties(entity.granularity),
          },
        })
        .afterClosed()
        .subscribe();
    }
  }
}

import { SiteDetail, YearlyValues, YearlyValuesBackend } from 'ssotool-shared';

export type BackendCampaignEntity = Readonly<{
  readonly clientId: string;
  readonly campaignId: string;
  readonly campaignType: string;
  readonly subtype: string;
  readonly campaignCategory: string;
  readonly name: string;
  readonly roadmap: string;
  readonly renewalStrategy: string;
  readonly geoId?: string;
  readonly siteId?: string;
  readonly companyEntityId?: string;
  readonly sectorId?: string;
  readonly fluidId?: string;
  readonly processId: string;
  // limit parameters related
  readonly savings?: YearlyValuesBackend[];
  readonly maxCapacity?: YearlyValuesBackend[];
  readonly maxVolume?: YearlyValuesBackend[];
  readonly isGeoGroup?: boolean;
  readonly associatedFluidIds?: string[];
  readonly profileSites?: string[];
}>;

// Model for campaign table
export type CampaignEntity = Readonly<{
  readonly clientId: string;
  readonly id: string;
  readonly campaignType: string;
  readonly subtype: string;
  readonly category: string;
  readonly name: string;
  readonly pathway: string;
  readonly geoId?: string;
  readonly siteId?: string;
  readonly displayedGeoId?: string;
  readonly companyId?: string;
  readonly sectorId?: string;
  readonly processId: string;
  readonly geography?: string;
  readonly site?: string;
  readonly displayedGeo?: string;
  readonly company?: string;
  readonly sector?: string;
  readonly fluids?: string[]; // for campaign types with multiple fluid associated
  readonly fluidIds?: string[];
  readonly isGeoGroup?: boolean;
  readonly process?: string;
  // limit parameters related
  readonly savings?: YearlyValues;
  readonly maxCapacity?: YearlyValues;
  readonly maxVolume?: YearlyValues;
  readonly profileSites?: string[];
}>;

export interface BackendCampaignBaseModel extends BackendCampaignEntity {
  readonly owner?: string;
}
export interface CampaignBaseModel extends CampaignEntity {
  readonly owner?: string;
  readonly metric?: string;
  readonly metricId?: string;
  readonly fluidId?: string;
  readonly fluid?: string; // for campaign types with single fluid associated
  readonly renewalStrategy: string;
  readonly associatedFluidIds?: string[];
  readonly selectedSiteControl?: SiteDetail;
}

export interface CampaignMarket extends CampaignBaseModel {
  readonly minVolume: YearlyValues;
  readonly maxVolume: YearlyValues;
  readonly duration: number;
  readonly co2RateScope1: YearlyValues;
  readonly co2RateScope2: YearlyValues;
  readonly co2RateScope3: YearlyValues;
  readonly fixedPrice: YearlyValues;
  readonly indivisibleCost: YearlyValues;
  readonly tax: YearlyValues;
  readonly processId: string;
  readonly pricingMethod: string;
  readonly sizingMethod: string;
}

export interface CampaignEnergyEfficiency extends CampaignBaseModel {
  readonly origEELabel: string;
  readonly finalEELabel: string;
  readonly processId: string;
  readonly fluidId: string;
  readonly metricId: string;
  readonly savings: YearlyValues;
  readonly incrementalSavings: YearlyValues;
  readonly technicalLife: YearlyValues;
  readonly depreciationTime: YearlyValues;
  readonly implementationTime: YearlyValues;
  readonly buildCo2Scope3Emissions: YearlyValues;
  readonly indivisibleCo2Scope3Emissions: YearlyValues;
  readonly buildCost: YearlyValues;
  readonly indivisibleCost: YearlyValues;
  readonly incrementalBuildCost: YearlyValues;
  readonly incrementalIndivisibleCost: YearlyValues;
  readonly foM: YearlyValues;
  readonly foMPerInstall: YearlyValues;
}

export type Fluid = Readonly<{
  readonly fluidId: string;
  readonly efficiencies: YearlyValues;
}>;

export interface CampaignConverter extends CampaignBaseModel {
  readonly processId: string;
  readonly minCapacity: YearlyValues;
  readonly maxCapacity: YearlyValues;
  readonly minimumOperatingHours: YearlyValues;
  readonly technicalLife: YearlyValues;
  readonly depreciationTime: YearlyValues;
  readonly implementationTime: YearlyValues;
  readonly buildCo2Scope3Emissions: YearlyValues;
  readonly indivisibleCo2Scope3Emissions: YearlyValues;
  readonly buildCost: YearlyValues;
  readonly indivisibleCost: YearlyValues;
  readonly foM: YearlyValues;
  readonly foMPerInstall: YearlyValues;
  readonly consumptionFluids: Fluid[];
  readonly productionFluids: Fluid[];
  readonly mainConsumptionFluidId: string;
  readonly mainProductionFluidId: string;
  readonly scale: string;
}

export interface CampaignRenewableProduction extends CampaignBaseModel {
  readonly fluidId: string;
  readonly minCapacity: YearlyValues;
  readonly maxCapacity: YearlyValues;
  readonly technicalLife: YearlyValues;
  readonly depreciationTime: YearlyValues;
  readonly implementationTime: YearlyValues;
  readonly buildCo2Scope3Emissions: YearlyValues;
  readonly indivisibleCo2Scope3Emissions: YearlyValues;
  readonly buildCost: YearlyValues;
  readonly indivisibleCost: YearlyValues;
  readonly foM: YearlyValues;
  readonly foMPerInstall: YearlyValues;
  readonly scale: string;
}

export interface CampaignStorage extends CampaignBaseModel {
  readonly fluidId: string;
  readonly minCapacity: YearlyValues;
  readonly maxCapacity: YearlyValues;
  readonly epRatio: YearlyValues;
  readonly maxDod: YearlyValues;
  readonly roundtripEfficiency: YearlyValues;
  readonly technicalLife: YearlyValues;
  readonly depreciationTime: YearlyValues;
  readonly implementationTime: YearlyValues;
  readonly buildCo2Scope3Emissions1: YearlyValues;
  readonly buildCo2Scope3Emissions2: YearlyValues;
  readonly indivisibleCo2Scope3Emissions: YearlyValues;
  readonly buildCost1: YearlyValues;
  readonly buildCost2: YearlyValues;
  readonly indivisibleCost: YearlyValues;
  readonly foM1: YearlyValues;
  readonly foM2: YearlyValues;
  readonly foMPerInstall: YearlyValues;
  readonly scale: string;
}

export type Campaign = Readonly<{
  readonly energyEfficiency: CampaignEnergyEfficiency[];
  readonly converter: CampaignConverter[];
  readonly renewableProduction: CampaignRenewableProduction[];
  readonly storage: CampaignStorage[];
}>;

export type CampaignTypes =
  | CampaignEnergyEfficiency
  | CampaignConverter
  | CampaignRenewableProduction
  | CampaignStorage
  | CampaignMarket;

export type SelectedCampaigns = Readonly<{ [campaignId: string]: string }>;

export enum RenewalStrategy {
  auto_same_size = 'Automatic with same size',
  auto_free_size = 'Automatic with free sizing',
  no_renewal = 'No renewal',
}

export enum PricingMethod {
  lock_in = 'Lock-in',
  variable = 'Variable',
}

export enum SizingMethod {
  fixed_volume = 'Fixed Volume',
  variable_volume = 'Variable Volume',
}

export type CampaignEntityReferences = Readonly<{
  companies?: Record<string, any>;
  geos?: Record<string, any>;
  sites?: Record<string, any>;
  sectors?: Record<string, any>;
  processes?: Record<string, any>;
  fluids?: Record<string, any>;
  metrics?: Record<string, any>;
  geoGroups?: Record<string, any>;
}>;

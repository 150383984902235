import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client/store';
import {
  Coerce,
  DialogComponent,
  DialogData,
  ExecStatusChecker,
  getEnumKeyByValue,
  mapEntitiesToSelectOptions,
  PermissionChecker,
  SSOToolRoutePathService,
} from 'ssotool-shared';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DetailsDrawerService } from '@oculus/components/details-drawer';

import { BaseDrawerComponent, DrawerMode } from './components/drawer';
import { CampaignDrawerData } from './components/drawer/campaign-drawer';
import {
  CampaignEntity,
  CampaignFacadeService,
  CampaignTypes,
  PricingMethod,
  RenewalStrategy,
  SelectedCampaigns,
  SizingMethod,
} from './store';
import { CampaignExportFacadeService } from './store/export';
import {
  CampaignImportFacadeService,
  CampaignImportModel,
} from './store/import';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'sso-campaign-page',
  templateUrl: './campaign-page.component.html',
  styleUrls: ['./campaign-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignPageComponent implements OnInit {
  clientId: string;
  owner: string;
  data$ = this.campaignFacade.getCampaignList$;
  loading$: Observable<boolean> = of(false);
  exporting$ = this.campaignExportFacade.exporting$;
  isAdmin$ = new BehaviorSubject<boolean>(false);
  readonly$ = this.isAdmin$.asObservable().pipe(map((isAdmin) => !isAdmin));
  hasOngoingExport$ = new BehaviorSubject<boolean>(false);
  hasOngoingImport$: Observable<boolean>;
  isIneligibleForComputation$: Observable<string[]>;
  rowData = new BehaviorSubject<any>(null);
  fluidOptions$ = new BehaviorSubject<any>([]);
  activeCampaignId$ = new BehaviorSubject<string>('');
  private clearSelection = new BehaviorSubject<boolean>(false);
  clearSelection$ = this.clearSelection.asObservable();
  exportStatus$: Observable<string> =
    this.campaignExportFacade.determineExportStatusMessage();
  exportId: string = '';
  cancelExportTriggered$: Observable<boolean> =
    this.campaignExportFacade.cancelExportTriggered$;
  displayCancelExport$: Observable<boolean> = combineLatest([
    this.exporting$,
    this.cancelExportTriggered$,
  ]).pipe(map(([isExporting, isCancelling]) => isExporting && !isCancelling));

  constructor(
    private clientFacade: ClientFacadeService,
    private campaignFacade: CampaignFacadeService,
    private campaignImportFacade: CampaignImportFacadeService,
    private campaignExportFacade: CampaignExportFacadeService,
    private statusChecker: ExecStatusChecker,
    private dialog: MatDialog,
    private permissionChecker: PermissionChecker,
    private router: Router,
    private routePath: SSOToolRoutePathService,
    private drawerService: DetailsDrawerService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.clientFacade.selectActiveClientData$
      .pipe(untilDestroyed(this))
      .subscribe((_c) => {
        /* istanbul ignore else */
        if (_c && _c.permissions) {
          this.isAdmin$.next(this.permissionChecker.isAdmin(_c.permissions));

          this.clientId = _c.clientId;

          this.isIneligibleForComputation$ =
            this.clientFacade.selectIneligibleCampaignsForComputation$(
              this.clientId,
            );
          this.owner = _c.owner;
          this.loading$ = combineLatest([
            this.campaignFacade.loading$,
            this.campaignImportFacade.loading$,
            this.clientFacade.dataLoading$(_c.clientId),
          ]).pipe(map((loading) => loading.includes(true)));
          this.hasOngoingImport$ = this.campaignImportFacade
            .getCampaignImportList$(this.clientId)
            .pipe(
              map((importList: CampaignImportModel[]) =>
                importList.some((d) =>
                  this.statusChecker.checkImport(d.status),
                ),
              ),
            );
          this.clientFacade
            .selectFluids$(_c.clientId)
            .pipe(
              untilDestroyed(this),
              skipWhile((data) => !data),
              map((data) => mapEntitiesToSelectOptions(data)),
            )
            .subscribe((data) => this.fluidOptions$.next(data));
        }
      });
    this.campaignExportFacade.activeExportId$
      .pipe(untilDestroyed(this))
      .subscribe((activeExportId) => {
        this.exportId = Coerce.toString(activeExportId, '');
      });
  }

  onAdd(): void {
    this.drawerService.open(BaseDrawerComponent, {
      data: {
        mode: DrawerMode.CREATE,
        campaign: {},
        clientId: this.clientId,
      },
      width: '60vw',
      closeIcon: false,
      overrideClose: true,
    });
  }

  onDelete(data: CampaignTypes = this.rowData.value?.data): void {
    this.openDialogComponent({
      title: `Delete Campaign ${Coerce.toEmptyObject(data).name}`,
      message: 'Are you sure?',
      confirm: 'Delete',
      client: data,
    })
      .afterClosed()
      .subscribe((resp) => {
        /* istanbul ignore else */
        if (resp) {
          this.deleteCampaign(data, resp.client);
        }
      });
  }

  deleteMultiple(data: CampaignTypes[]): void {
    this.openDialogComponent({
      title: 'Delete Campaigns',
      message: 'Are you sure you want to delete all the selected campaigns?',
      confirm: 'Delete',
      client: data,
    })
      .afterClosed()
      .subscribe((confirmed) => {
        /* istanbul ignore else */
        const clientId: string = data[0].clientId;
        const campaignIdTypeMap = this.campaignReducer(data);
        if (confirmed) {
          this.campaignFacade.deleteMultiple(clientId, campaignIdTypeMap);
          this.resetDrawer();
        }
      });
  }

  onDuplicate(data: CampaignTypes = this.rowData.value?.data): void {
    this.openDialogComponent({
      title: `Duplicate Campaign ${data?.name}`,
      message: 'Are you sure?',
      confirm: 'Duplicate',
      client: data,
    })
      .afterClosed()
      .subscribe((resp) => {
        /* istanbul ignore else */
        if (resp) {
          const campaigns = this.campaignReducer([data]);
          this.campaignFacade.batchDuplicate(data.clientId, campaigns);
        }
      });
  }

  onBatchDuplicate(data: CampaignTypes[]): void {
    this.openDialogComponent({
      title: 'Duplicate Campaigns',
      message: 'Are you sure you want to duplicate all the selected campaigns?',
      confirm: 'Duplicate',
      client: data,
    })
      .afterClosed()
      .subscribe((confirmed) => {
        const clientId: string = data[0]?.clientId;
        /* istanbul ignore else */
        if (confirmed) {
          const campaigns = this.campaignReducer(data);
          this.campaignFacade.batchDuplicate(clientId, campaigns);
        }
      });
  }

  onExport(data: CampaignTypes[]): void {
    this.openDialogComponent({
      title: 'Export Campaigns',
      message: 'Are you sure you want to export all the selected campaigns?',
      confirm: 'Export',
      client: data,
    })
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          this.campaignExportFacade
            .exportCampaign(data[0]?.clientId, this.campaignReducer(data))
            .pipe(take(1))
            .subscribe(() => {
              // clear all selection
              this.clearSelection.next(true);
            });
        }
      });
  }

  campaignReducer(data: CampaignTypes[]): SelectedCampaigns {
    return data.reduce((acc, curr) => {
      acc[curr.id] = curr.campaignType;
      return acc;
    }, {});
  }

  onCancelExport() {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translateService.instant(
            'Campaign.labels.export.cancelExportTitle',
          ),
          message: this.translateService.instant(
            'Campaign.labels.export.cancelExportMessage',
          ),
          confirm: 'Confirm',
          close: 'Cancel',
          disableClose: false,
          width: '250px',
        },
      })
      .afterClosed()
      .subscribe((confirmed) => {
        /* istanbul ignore else */
        if (confirmed) {
          this.campaignExportFacade.cancelExportCampaigns(
            this.clientId,
            this.exportId,
          );
        }
      });
  }

  onPopulateWithLibrary(): void {
    this.campaignFacade.populateWithLibrary(this.clientId);
  }

  onOpenImport(): void {
    this.router.navigate(this.routePath.campaignImport(this.clientId));
  }

  openDrawer(campaign: CampaignEntity): void {
    this.drawerService.open<BaseDrawerComponent, CampaignDrawerData, any>(
      BaseDrawerComponent,
      {
        data: {
          mode: DrawerMode.VIEW,
          campaign,
          clientId: this.clientId,
        },
        width: '60vw',
        closeIcon: false,
        overrideClose: true,
      },
    );
  }

  private openDialogComponent(
    data: DialogData,
  ): MatDialogRef<DialogComponent, DialogData> {
    return this.dialog.open(DialogComponent, {
      data: { ...data, disableClose: false, close: 'Cancel', width: '250px' },
    });
  }

  private deleteCampaign(data: CampaignTypes, client: CampaignTypes): void {
    if (this.rowData.value?.data?.id === data.id) {
      this.drawerService.close();
    }
    this.campaignFacade.delete(client.clientId, client.id, client.campaignType);
    this.resetDrawer();
  }

  private resetDrawer(): void {
    this.drawerService.open(BaseDrawerComponent, {
      data: {
        mode: DrawerMode.CREATE,
        campaign: {},
        clientId: this.clientId,
      },
      width: '60vw',
      closeIcon: false,
      overrideClose: true,
    });
    this.drawerService.close();
  }
}

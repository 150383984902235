import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client';
import {
  CompareVariationsFacadeService,
  ResultFilterOptions,
} from 'ssotool-app/+roadmap/stores';
import { Coerce, convertArrayToFormFieldOptions } from 'ssotool-shared/helpers';

import { Injectable } from '@angular/core';

import { CompareRoadmapWithVariationType } from '../compare-variations.model';
import { DetailsFormModel } from './detailed-compare.model';
import { REQUIRED_ENTITY_FIELDS_MAPPER } from './detailed-compare.references';

@Injectable()
export class CompareDetailsInterpreter {
  constructor(
    private compareFacade: CompareVariationsFacadeService,
    private clientFacade: ClientFacadeService,
  ) {}

  getDetails(
    referenceIds: CompareRoadmapWithVariationType,
    { entity, level, filters, viewLevels }: DetailsFormModel,
  ) {
    const requiredFields: string[] =
      REQUIRED_ENTITY_FIELDS_MAPPER[`${entity}${viewLevels?.length || 1}`];

    return this.compareFacade
      .selectDetails(
        referenceIds,
        entity,
        level,
        filters,
        viewLevels,
        requiredFields,
      )
      .pipe(
        map((details) => {
          return details.reduce((acc, variationDetails, index) => {
            variationDetails.forEach((detail) => {
              const compositeKeys = Coerce.getObjKeys(detail)
                .sort()
                .filter((key) => !requiredFields.includes(key));
              const uniqueKey = compositeKeys
                .map((key) => detail[key])
                .join('-');

              const kpiValues = requiredFields.reduce((acc, key) => {
                acc[key] = detail[key];

                return acc;
              }, {});

              acc[uniqueKey] = {
                kpiLabel: requiredFields,
                ...acc[uniqueKey],
                ...compositeKeys.reduce((acc, key) => {
                  acc[key] = detail[key];
                  return acc;
                }, {}),
                [this.getRoadmapVariationString(referenceIds[index])]:
                  kpiValues,
                ...(index === 0 && {
                  referenceValue: kpiValues,
                }),
              };
            });

            return acc;
          }, {});
        }),
        map((details) => Coerce.getObjValues(details || {})),
      );
  }

  getLevels() {
    return this.clientFacade.selectActiveClientData$.pipe(
      filter((client) => !!client),
      mergeMap((client) =>
        this.clientFacade.selectGeoHierarchy$(client.clientId),
      ),
      map((levels) =>
        convertArrayToFormFieldOptions(Coerce.getObjKeys(levels)),
      ),
    );
  }

  getUnits(
    referenceIds: CompareRoadmapWithVariationType,
    { entity, viewLevels }: DetailsFormModel,
  ) {
    const requiredFields: string[] =
      REQUIRED_ENTITY_FIELDS_MAPPER[`${entity}${viewLevels?.length || 1}`];

    return this.compareFacade.selectUnits(referenceIds, requiredFields).pipe(
      map((units) =>
        referenceIds
          .map(this.getRoadmapVariationString)
          .reduce((acc, id, index) => {
            acc[id] = units[index];
            if (index === 0) {
              acc['referenceValue'] = units[index];
            }
            return acc;
          }, {}),
      ),
    );
  }

  getFilterOptions(
    activeFilters: string[],
    referenceIds: CompareRoadmapWithVariationType,
  ): Observable<ResultFilterOptions> {
    return this.compareFacade.selectFilters(referenceIds, activeFilters);
  }

  private getRoadmapVariationString(
    item: [roadmapId: string, roadmapName: string, variationId: string],
  ): string {
    return `${item[0]}-${item[2]}`;
  }
}

<div class="share-client-table" fxLayout="column" fxLayoutGap="20px">
  <div
    class="tableControls"
    fxLayout="row"
    fxLayoutGap="120px"
    fxLayoutAlign="space-between center"
  >
    <div class="mat-caption" fxLayout="column" *ngIf="activeClient?.ownerName">
      <h1 *ngIf="activeClient">{{ activeClient?.name }}</h1>
      <span
        >Created by {{ activeClient?.ownerName }} on
        {{ activeClient?.createdAt | date: 'dd MMM yyyy' }}</span
      >
    </div>
    <sso-search-input
      class="searchInput"
      [placeholder]="'Search name'"
      [label]="'Search name'"
      [formControl]="searchInput"
    ></sso-search-input>
  </div>
  <mat-table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef
        ><span class="indented">{{
          'Client.labels.sharedTo' | translate
        }}</span></mat-header-cell
      >
      <mat-cell *matCellDef="let element"
        ><span class="indented">{{ element.name }}</span></mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="permission">
      <mat-header-cell *matHeaderCellDef>{{
        'Client.labels.permission' | translate
      }}</mat-header-cell>
      <mat-cell *matCellDef="let element; index as i">
        <span *ngIf="isReadOnly">{{ getRole(element.role) }}</span>
        <sso-select
          class="permission-select"
          *ngIf="!isReadOnly"
          fxFlex="90%"
          [options]="permissionOptions"
          [placeholder]="'Client.labels.permission' | translate"
          [label]="'Client.labels.permission' | translate"
          [value]="element.role"
          [disabled]="isReadOnly"
          [required]="true"
          (selectionChange)="onUpdate(element?.userId, $event)"
        ></sso-select>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef [ngStyle]="{ 'padding-left': '.6em' }">
        Action
      </mat-header-cell>
      <mat-cell *matCellDef="let element; index as i">
        <button
          data-testid="delete-shared-client-button"
          [disabled]="isReadOnly"
          mat-icon-button
          tooltipPosition="right"
          (click)="onRemove(element?.userId)"
        >
          <mat-icon color="primary" matTooltip="Delete user"
            >delete_forever</mat-icon
          >
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns$ | async"
    ></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns$.value"></mat-row>
  </mat-table>
</div>

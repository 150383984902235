<ngx-charts-line-chart
  [view]="[chartWidth$ | async, chartHeight$ | async]"
  [xAxis]="true"
  [yAxis]="true"
  [legend]="showLegend"
  [results]="chartData"
  [showYAxisLabel]="true"
  [showXAxisLabel]="true"
  [xAxisLabel]="xAxisLabel"
  [xAxisTickFormatting]="chartXAxisFormat"
  [roundDomains]="true"
  [curve]="lineCurve"
  [scheme]="'fire'"
>
</ngx-charts-line-chart>

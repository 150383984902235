import { createAction, props } from '@ngrx/store';

import {
  RoadmapVariation,
  RoadmapVariationGetListParams,
  RoadmapVariationGetParams,
} from './roadmap-variation.model';

function createActionType(
  verb: string,
  status: 'Success' | 'Failed' | '' = '',
): string {
  return `[Roadmap Variation API] ${verb} ${status}`;
}

const accessRoadmapVariationAction = createAction(
  createActionType('Access Roadmap Variation'),
  props<{ roadmapId: string; variationId: string }>(),
);

const get = createAction(
  createActionType('Get'),
  props<RoadmapVariationGetParams>(),
);

const getSuccess = createAction(
  createActionType('Get', 'Success'),
  props<{ data: RoadmapVariation }>(),
);

const getFailed = createAction(
  createActionType('Get', 'Failed'),
  props<RoadmapVariationGetParams>(),
);

const getList = createAction(
  createActionType('Get List'),
  props<RoadmapVariationGetListParams>(),
);

const getListSuccess = createAction(
  createActionType('Get List', 'Success'),
  props<{ data: RoadmapVariation[] }>(),
);

const getListFailed = createAction(
  createActionType('Get List', 'Failed'),
  props<RoadmapVariationGetListParams>(),
);

const reloadSingleRoadmapVariationAction = createAction(
  createActionType('Reload'),
  props<{
    clientId: string;
    roadmapId: string;
    variationId: string;
    status: string;
    notifType: string;
  }>(),
);

const reloadSingleRoadmapVariationOKAction = createAction(
  createActionType('Reload', 'Success'),
  props<{ data: any; isSuccessful: boolean }>(),
);

const reloadSingleRoadmapVariationNOKAction = createAction(
  createActionType('Reload', 'Failed'),
  props<{ message: any }>(),
);

const reloadRoadmapVariationSilentlyAction = createAction(
  createActionType('Reload RoadmapVariation Silently'),
  props<{
    roadmapId: string;
    variationId: string;
    status: string;
    feStatus: string;
  }>(),
);

const reloadAffectedRoadmapVariationAction = createAction(
  createActionType('Reload RoadmapVariation'),
  props<{ clientId: string; roadmapIds: string[] }>(),
);

const reloadAffectedRoadmapVariationOKAction = createAction(
  createActionType('Reload RoadmapVariation', 'Success'),
  props<{ data: any; message: string }>(),
);

const reloadAffectedRoadmapVariationNOKAction = createAction(
  createActionType('Reload RoadmapVariation', 'Failed'),
  props<{ message: any }>(),
);

const reloadMultipleRoadmapSilentlyAction = createAction(
  createActionType('Reload Multiple Roadmap Silently'),
  props<{ roadmapIds: string[]; beStatus: string; feStatus: string }>(),
);

export const RoadmapVariationActions = {
  accessRoadmapVariationAction,
  get,
  getSuccess,
  getFailed,
  getList,
  getListSuccess,
  getListFailed,
  reloadSingleRoadmapVariationAction,
  reloadSingleRoadmapVariationOKAction,
  reloadSingleRoadmapVariationNOKAction,
  reloadRoadmapVariationSilentlyAction,
  reloadAffectedRoadmapVariationAction,
  reloadAffectedRoadmapVariationOKAction,
  reloadAffectedRoadmapVariationNOKAction,
  reloadMultipleRoadmapSilentlyAction,
};

import { FormService } from 'ssotool-app/shared/services/form';
import {
  endDateMustBeGreaterThanStartDate,
  mustBeWithinRange,
} from 'ssotool-app/shared/validators';

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FormFieldErrorMessageMap } from '../../../helpers/form-helpers';
import { GanttDialogData } from '../gantt.models';

@Component({
  selector: 'sso-gantt-dialog',
  templateUrl: './gantt-dialog.component.html',
  styleUrls: ['./gantt-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class GanttDialogComponent implements OnInit {
  form = new FormGroup(
    {
      earliestDate: new FormControl('', [
        Validators.required,
        mustBeWithinRange(this.data),
      ]),
      latestDate: new FormControl('', [
        Validators.required,
        mustBeWithinRange(this.data),
      ]),
    },
    {
      validators: [
        endDateMustBeGreaterThanStartDate('earliestDate', 'latestDate'),
      ],
    },
  );
  errorMessages: FormFieldErrorMessageMap = JSON.parse(
    JSON.stringify(
      this.formService.getErrorMessageMap('Generic.messages.errors.gantt'),
    )
      .replace(/\blimitPlaceholder\b/g, this.limitRangeText)
      .replace(/\blabelPlaceholder\b/g, this.data.label),
  );

  get isPristine() {
    return this.form.pristine;
  }

  get limitRangeText() {
    return `${this.data.validStartLimit} to ${this.data.validEndLimit}`;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GanttDialogData,
    private formService: FormService,
    public dialogRef: MatDialogRef<GanttDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.form.patchValue({
      earliestDate: this.data.currentStart,
      latestDate: this.data.currentEnd,
    });
  }

  onCancel() {
    this.dialogRef.close({});
  }

  onSubmit() {
    /* istanbul ignore else */
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }

  onSetDefaultValues() {
    this.form.patchValue({
      earliestDate: this.data.defaultStart,
      latestDate: this.data.defaultEnd,
    });
    this.form.markAsDirty();
    this.form.markAsTouched();
  }
}

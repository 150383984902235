export interface PortfolioEntity<T = any> {
  readonly id: string;
  readonly name?: string;
  readonly loading?: boolean;
  readonly loaded?: boolean;
  readonly accessed?: boolean;
  readonly progress?: number;
  readonly baselineIndicators?: T;
  readonly baselineLoading?: boolean;
  readonly baselineFetched?: boolean;
  readonly updating?: boolean;
  readonly queueCount?: number;
  readonly queueFailed?: boolean;
}

export enum KPIType {
  ECONOMICS = 'economics',
  ENVIRONMENTAL = 'environmental',
  SUSTAINABILITY = 'sustainability',
}

export enum NewKPIType {
  FINANCIALS = 'financials',
  SUSTAINABILITY = 'sustainability',
  OPERATIONS = 'operations',
}

import { ExistingStorage } from 'ssotool-app/+client/store';

export enum ExistingStorageDrawerMode {
  EDIT = 'edit',
  CREATE = 'create',
  VIEW = 'view',
}

export type ExistingStorageDrawerData = Readonly<{
  mode: ExistingStorageDrawerMode;
  clientId: string;
  existingStorage?: Partial<ExistingStorage>;
  readonly?: boolean;
}>;

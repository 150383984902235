import { Injectable } from '@angular/core';
import { FilterGroups, SelectedFilters } from '../combination-filter.model';

@Injectable({
  providedIn: 'root',
})
export class SimpleFiltersService {
  constructor() {}

  createFilterOptions(
    data: any[],
    filtersToExclude: string[] = [],
  ): FilterGroups {
    const filterMaps = data.reduce((filters, datum) => {
      Object.keys(datum).forEach((datumKey) => {
        if (!this.isIncluded(datumKey, filtersToExclude)) {
          if (!filters[datumKey]) {
            filters[datumKey] = [];
          }
          filters[datumKey].push(datum[datumKey]);
        }
      });
      return filters;
    }, {});

    return Object.keys(filterMaps).map((filterKey) => ({
      id: filterKey,
      placeholder: filterKey,
      options: [...new Set(filterMaps[filterKey])].map((option: string) => ({
        id: option,
        name: option,
      })),
      multiple: true,
    }));
  }

  filter<T>(
    filterThese: T[],
    filters: SelectedFilters,
    keyPredicate: (item: T) => string = null,
  ): T[] {
    return filterThese.filter((item) => {
      return Object.keys(filters).every((filterKey) => {
        const itemValue = keyPredicate
          ? keyPredicate(item)[filterKey]
          : item[filterKey];
        return itemValue && this.isIncluded(itemValue, filters[filterKey]);
      });
    });
  }

  private isIncluded(value: string, values: string[]): boolean {
    if (!!values.length) {
      return values.includes(value);
    }

    return true;
  }
}

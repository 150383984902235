import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FluidsToggleGroupComponent } from './fluids-toggle-group.component';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MatButtonToggleModule,
  MatIconModule,
  FlexLayoutModule,
  MatTooltipModule,
];

@NgModule({
  declarations: [FluidsToggleGroupComponent],
  imports: [...modules],
  exports: [FluidsToggleGroupComponent],
})
export class FluidsToggleGroupModule {}

<div class="container">
  <mat-tab-group
    [selectedIndex]="selected?.value"
    (selectedIndexChange)="onChangeSelectedTab($event)"
  >
    <mat-tab *ngFor="let tab of yearlyTabs">
      <ng-template matTabLabel>
        <div fxLayout="row" fxLayoutAlign="center center">
          <b>{{ tab.startYear || 'Start Year' }}</b>
          <mat-icon>chevron_right</mat-icon>
          <b>{{ tab.endYear || 'End Year' }}</b>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  <div
    class="sso-spinner-container"
    *ngIf="
      loading || (seriesDataList$ | async) === (false || null || undefined);
      else showChart
    "
  >
    <sso-spinner></sso-spinner>
  </div>
  <ng-template #showChart>
    <div
      class="sso-spacer-top-half sso-spacer"
      fxLayout="row"
      fxLayoutGap="5px"
      fxLayoutAlign="center center"
    >
      <div
        class="chart-container"
        fxLayout="column"
        fxLayoutAlign="end center"
        fxLayoutGap="5px"
      >
        <ngx-charts-area-chart
          class="custom-chart"
          [view]="view"
          [scheme]="scheme"
          [legend]="legend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisTickFormatting]="xAxisFormat"
          [xAxis]="xAxis"
          [yAxis]="yAxis"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [timeline]="timeline"
          [results]="seriesDataList$ | async"
          (select)="onSelect($event)"
        >
        </ngx-charts-area-chart>
        <div
          *ngIf="hasRangeSlider && (seriesDataList$ | async) && !loading"
          class="slider-container"
          [style.width.px]="width"
        >
          <ngx-slider
            [options]="dateFilter.sliderOptions$ | async"
            [value]="from"
            [highValue]="to"
            (valueChange)="onFromChange($event)"
            (highValueChange)="onToChange($event)"
          >
          </ngx-slider>
        </div>
      </div>
      <sso-view-dataset
        [dataset]="dataset"
        dataType="Hourly"
      ></sso-view-dataset>
    </div>
  </ng-template>
</div>

<button
  data-testid="fluid-menu-button"
  mat-button
  color="primary"
  [matMenuTriggerFor]="menu"
  [disabled]="disabled"
>
  <mat-icon>add_circle_outline</mat-icon>
  <span>{{ label }}</span>
</button>

<mat-menu #menu="matMenu" class="sso-menu-button-panel" yPosition="below">
  <button
    data-testid="fluid-option-button"
    mat-menu-item
    *ngFor="let option of options"
    (click)="onClick(option)"
  >
    <span>{{ option.name }}</span>
  </button>
</mat-menu>

import { TabbedBarChartModule } from 'ssotool-shared/component/tabbed-bar-chart';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {FlowVolumesBarChartDialogComponent} from './flow-volumes-bar-chart-dialog.component';


@NgModule({
  declarations: [FlowVolumesBarChartDialogComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    TabbedBarChartModule,
  ],
  exports: [FlowVolumesBarChartDialogComponent],
})
export class FlowVolumesBarChartDialogModule {}

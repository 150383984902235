<div
  class="container"
  fxLayoutGap="10px"
  fxLayout="column"
  fxLayoutAlign="stretch stretch"
>
  <div mat-dialog-title fxLayoutGap="10px" fxLayout="row">
    <mat-icon aria-hidden="false">insert_chart_outlined</mat-icon>
    <h2 class="mat-headline-6">{{ data?.name | titlecase }} Flow Prices</h2>
  </div>
  <sso-tabbed-bar-chart
    [loading]="loading$ | async"
    [yearlyTabs]="yearlyTabs"
    [seriesDataList]="yearlyChartValues$ | async"
    (onTabChange)="onTabChange($event)"
    [chartType]="data.granularity"
    [from]="data.chartProps.floor"
    [to]="data.chartProps.ceil"
    [floor]="data.chartProps.floor"
    [ceiling]="data.chartProps.ceil"
    [minRange]="data.chartProps.minRange"
    [maxRange]="data.chartProps.maxRange"
    [yAxisLabel]="quantity$ | async"
  ></sso-tabbed-bar-chart>
  <div mat-dialog-actions fxLayout="row">
    <button (click)="onClose()" color="" mat-flat-button type="button">
      Close
    </button>
  </div>
</div>

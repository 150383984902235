import * as _moment from 'moment';
import { Options } from '@angular-slider/ngx-slider';
import { BehaviorSubject, combineLatest, map, Observable, of, tap } from 'rxjs';
import { ENGIE_DEFAULT_SCHEME } from 'ssotool-app/app.references';
import { DateFilterService } from 'ssotool-shared/services';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import {
  YearlyBarChartData,
  YearlyBarChartResult,
} from './tabbed-bar-chart.model';
import { Coerce } from 'ssotool-app/shared/helpers/coerce.utils';

const moment = _moment;

@Component({
  selector: 'sso-tabbed-bar-chart',
  templateUrl: './tabbed-bar-chart.component.html',
  styleUrls: ['./tabbed-bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabbedBarChartComponent implements OnInit {
  /* Data */
  @Input() loading: boolean;
  dataset;
  chartName: string;
  @Input() set seriesDataList(seriesDataList: YearlyBarChartData) {
    if (seriesDataList) {
      const year = seriesDataList.startYear
        ? seriesDataList.startYear
        : this.dateFilterService.year;
      this.dateFilterService.year = year;
      this._seriesDataList$.next(seriesDataList.series);
      this.dataset = seriesDataList.series.map((s, index) =>
        this.dateFilterService.mapDataToSeries(
          s,
          index,
          year,
          seriesDataList.granularity,
        ),
      );
      // The seriesDataList$ to be used in displaying the chart based on the array of strings from binstore and the date filter applied
      this.seriesDataList$ = combineLatest([
        this._seriesDataList$,
        this.dateFilterService.dateChange$,
      ]).pipe(
        this.dateFilterService.filter$(
          seriesDataList.name,
          year,
          seriesDataList.granularity,
        ),
        map((seriesDataList) =>
          seriesDataList ? seriesDataList[0].series : [],
        ),
        tap((result) => this.patchPaddingForYearlyData(result)),
        map((result) => this.fillData(result)),
      );
      this.chartName = seriesDataList.name;
    }
  }

  /* tabs */
  @Input() yearlyTabs = [];

  @Input() chartType = 'Daily'; // enum('daily', 'monthly', 'yearly')

  @Output() fromChange = new EventEmitter<any>();
  @Output() toChange = new EventEmitter<any>();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onTabChange = new EventEmitter<any>();
  selected = new FormControl(0); // The control representing the selected or active tab

  _seriesDataList$ = new BehaviorSubject<any[]>([]);
  seriesDataList$: Observable<any[]> = of([]);
  _seriesDataList: YearlyBarChartData;
  _sliderOptions: Options = {
    step: 1,
    draggableRange: true,
    showTicks: true,
    floor: 1,
    ceil: 365,
    minRange: 0,
    maxRange: 365,
  };

  legend = false;
  showLabels = false;
  animations = true;

  @Input() width = 720;
  @Input() height = 350;
  @Input() xAxis = true;
  @Input() yAxis = true;
  @Input() showYAxisLabel = true;
  @Input() showXAxisLabel = true;
  @Input() xAxisLabel = '';
  @Input() yAxisLabel = '';
  timeline = true;
  scheme = {
    ...ENGIE_DEFAULT_SCHEME,
    domain: ['#1FA9EC'],
  };
  view: [number, number] = [this.width, this.height];

  private _barPadding = new BehaviorSubject<number>(8);
  barPadding$ = this._barPadding as Observable<number>;

  /* slider options */
  @Input() set floor(floor: number) {
    this.sliderOptions = { ...this.sliderOptions, floor };
  }

  @Input() set ceiling(ceil: number) {
    this.sliderOptions = { ...this.sliderOptions, ceil };
  }

  @Input() set minRange(minRange: number) {
    this.sliderOptions = { ...this.sliderOptions, minRange };
  }

  @Input() set maxRange(maxRange: number) {
    this.sliderOptions = { ...this.sliderOptions, maxRange };
  }
  @Input() set sliderOptions(sliderOptions: Options) {
    this._sliderOptions = sliderOptions;
  }
  get sliderOptions(): Options {
    return this._sliderOptions;
  }

  /* Range Slider */
  @Input() hasRangeSlider = true;
  @Input() from: any = 0 as any;
  @Input() to: any = 365 as any;

  constructor(public dateFilterService: DateFilterService) {}

  ngOnInit() {
    this.initSlider();
  }

  initSlider() {
    const { defaultSliderOptions } = this.dateFilterService;
    const newDefaultSliderOptions = {
      ...defaultSliderOptions,
      ...this._sliderOptions,
    };
    this.dateFilterService.defaultSliderOptions = newDefaultSliderOptions;
    this.dateFilterService.defaultSliderOptions$.next(newDefaultSliderOptions);
    this.dateFilterService.maxDate$.next(this.maxRange);
  }

  // Format of the chart's x-axis
  xAxisFormat = (value: any) => {
    if (this.chartType === 'Daily') {
      return moment(value).format('MMM DD');
    } else if (this.chartType === 'Monthly') {
      return value;
    } else {
      return value ? moment(value).format('YYYY') : value;
    }
  };

  patchPaddingForYearlyData(result: YearlyBarChartResult[]) {
    if (this.chartType === 'Yearly' && result.length < 10) {
      this._barPadding.next(20);
    }
  }

  fillData(result: YearlyBarChartResult[]): YearlyBarChartResult[] {
    // TODO: fix dirty hack: resolve big bar chart width, add dummy values
    if (this.chartType === 'Yearly' && result.length < 10) {
      // total bar width = 560 = barWidth * barCount + barPadding * (barCount - 1)
      // this._barPadding.next((560 - 40 * (dataLength + 1)) / dataLength);
      // 20 might change based on the chart width
      let x = 10 - result.length;
      let lastYear = result[result.length - 1].name;
      let fillArray = Array.from({ length: x }, (_, index) => ({
        name: this.incrementYear(lastYear, index + 1),
        value: 0,
      }));
      result.push(...fillArray);
    }
    return Coerce.toArray(result);
  }

  private incrementYear(startYear: string, offset: number): string {
    return (Coerce.toZero(startYear) + offset).toString();
  }

  onSelect(event) {}

  onFromChange(value: any) {
    this.dateFilterService.minDate$.next(value);
  }

  onToChange(value: any) {
    this.dateFilterService.maxDate$.next(value);
  }

  onChangeSelectedTab(index: number) {
    this.selected.patchValue(index);
    this.onTabChange.emit(index);
  }
}

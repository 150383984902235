import { KPIType } from 'ssotool-app/+roadmap/stores';
import { FormFieldOption } from 'ssotool-shared';

import { CompareVariationAnalysisKpiTabs } from '../compare-variations.references';
import { ChartType } from './curves-compare-variations.models';

// KPIs
// financials
export const CAPEX = { name: 'CAPEX', value: 'capex' };
const OPEX = { name: 'OPEX', value: 'opex' };
export const TDC = { name: 'Total Discounted Cost', value: 'tdc' };

export const FINANCIALS_KPI_OPTIONS: FormFieldOption<string>[] = [
  CAPEX,
  OPEX,
  TDC,
];
// sustainaibility
export const TOTAL_CO2_EMISSIONS = {
  name: 'Total CO2 Emissions',
  value: 'totalCo2Emissions',
};

export const SCOPE = { name: 'Scope', value: 'scope' };
const SCOPE_ONE = { name: 'Scope 1', value: 'scope1' };
const SCOPE_TWO = { name: 'Scope 2', value: 'scope2' };
const SCOPE_THREE = { name: 'Scope 3', value: 'scope3' };

export const SUSTAINABILITY_SUB_KPI_OPTIONS: FormFieldOption<string>[] = [
  SCOPE_ONE,
  SCOPE_TWO,
  SCOPE_THREE,
];

export const SUSTAINABILITY_KPI_OPTIONS: FormFieldOption<string>[] = [
  TOTAL_CO2_EMISSIONS,
];

// operations
export const ENERGY_CONSUMPTION = {
  name: 'Energy Consumption',
  value: 'totalEnergyConsumption',
};

export const ENERGY_PRODUCTION = {
  name: 'Energy Production',
  value: 'totalEnergyProduction',
};

export const FLUID_FLOWS = {
  name: 'Flows',
  value: 'flows',
};

export const OPERATIONS_KPI_OPTIONS: FormFieldOption<string>[] = [
  ENERGY_CONSUMPTION,
  ENERGY_PRODUCTION,
];

// Group and Filter
export const GEOGRAPHY = { name: 'Geography', value: 'geography' };
export const YEARS = { name: 'Years', value: 'years' };
export const ENTITY = { name: 'Company', value: 'entity' };
export const CAMPAIGN = { name: 'Campaign', value: 'campaign' };
const LEVER = { name: 'Lever', value: 'lever' };
const PROCESS = { name: 'Process', value: 'process' };
const FLUID = { name: 'Fluid', value: 'fluid' };
export const COMMON_FILTER = [
  YEARS.value,
  LEVER.value,
  ENTITY.value,
  PROCESS.value,
  GEOGRAPHY.value,
  CAMPAIGN.value,
  FLUID.value,
];

// Curves
export const STACKED_BAR = {
  name: 'Stacked Bar',
  value: ChartType.STACKED_BAR,
  icon: 'stacked_bar_chart',
};
export const PIE = {
  name: 'Pie',
  value: ChartType.PIE,
  icon: 'pie_chart_outline',
};
export const WATERFALL = {
  name: 'Waterfall',
  value: ChartType.WATERFALL,
  icon: 'waterfall_chart',
};

// Others
export const DISPLAY_GROUP_FIELD = [WATERFALL.value, PIE.value];
export const DISPLAY_LEVEL_FIELD = [GEOGRAPHY.value, ENTITY.value];
export const INCLUDE_SECONDARY_GROUPING = [TDC.value];

export const Y_AXIS_OPTIONS_REFERENCE = {
  [CompareVariationAnalysisKpiTabs.FINANCIALS]: FINANCIALS_KPI_OPTIONS,
  [CompareVariationAnalysisKpiTabs.SUSTAINABILITY]: SUSTAINABILITY_KPI_OPTIONS,
  [CompareVariationAnalysisKpiTabs.OPERATIONS]: OPERATIONS_KPI_OPTIONS,
};

export const KPI_TYPE_MAPPER = {
  [CompareVariationAnalysisKpiTabs.FINANCIALS]: KPIType.ECONOMICS,
  [CompareVariationAnalysisKpiTabs.SUSTAINABILITY]: KPIType.ENVIRONMENTAL,
  [CompareVariationAnalysisKpiTabs.OPERATIONS]: KPIType.SUSTAINABILITY,
};

export const NEW_KPI_TYPE_MAPPER: Record<KPIType, string> = {
  [KPIType.ECONOMICS]: CompareVariationAnalysisKpiTabs.FINANCIALS,
  [KPIType.ENVIRONMENTAL]: CompareVariationAnalysisKpiTabs.SUSTAINABILITY,
  [KPIType.SUSTAINABILITY]: CompareVariationAnalysisKpiTabs.OPERATIONS,
};

const ENTITY_OPTIONS = [
  YEARS,
  LEVER,
  PROCESS,
  FLUID,
  CAMPAIGN,
  GEOGRAPHY,
  ENTITY,
];

export const FINANCIALS_X_AXIS_OPTIONS = ENTITY_OPTIONS;
export const SUSTAINABILITY_X_AXIS_OPTIONS = ENTITY_OPTIONS;
export const OPERATIONS_X_AXIS_OPTIONS = ENTITY_OPTIONS;

export const X_AXIS_OPTIONS_REFERENCE = {
  [CompareVariationAnalysisKpiTabs.FINANCIALS]: FINANCIALS_X_AXIS_OPTIONS,
  [CompareVariationAnalysisKpiTabs.SUSTAINABILITY]:
    SUSTAINABILITY_X_AXIS_OPTIONS,
  [CompareVariationAnalysisKpiTabs.OPERATIONS]: OPERATIONS_X_AXIS_OPTIONS,
};

export const FINANCIALS_SPLIT_OPTIONS = ENTITY_OPTIONS;
export const SUSTAINABILITY_SPLIT_OPTIONS = ENTITY_OPTIONS;
export const OPERATIONS_SPLIT_OPTIONS = ENTITY_OPTIONS;

export const SPLIT_OPTIONS_REFERENCE = {
  [CompareVariationAnalysisKpiTabs.FINANCIALS]: FINANCIALS_SPLIT_OPTIONS,
  [CompareVariationAnalysisKpiTabs.SUSTAINABILITY]:
    SUSTAINABILITY_SPLIT_OPTIONS,
  [CompareVariationAnalysisKpiTabs.OPERATIONS]: OPERATIONS_SPLIT_OPTIONS,
};

export const CURVE_OPTIONS: FormFieldOption<string>[] = [
  STACKED_BAR,
  PIE,
  WATERFALL,
];

import { ProfileFacadeService } from 'ssotool-app/+client/store';

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import {
  CampaignAPIService,
  CampaignExportResolverService,
  CampaignExportService,
  CampaignImportAPIService,
  CampaignImportResolverService,
  CampaignResolverService,
} from './services';
import {
  CampaignEffects,
  CampaignFacadeService,
  campaignFeatureKey,
  CampaignReducer,
} from './store';
import {
  CampaignExportEffects,
  CampaignExportFacadeService,
  campaignExportFeatureKey,
  CampaignExportReducer,
} from './store/export';
import {
  CampaignImportEffects,
  CampaignImportFacadeService,
  campaignImportFeatureKey,
  CampaignImportReducer,
} from './store/import';
import { ToastModule } from 'ssotool-app/shared/services/toast/toast.module';

@NgModule({
  imports: [
    EffectsModule.forFeature([
      CampaignEffects,
      CampaignImportEffects,
      CampaignExportEffects,
    ]),
    StoreModule.forFeature(campaignFeatureKey, CampaignReducer),
    StoreModule.forFeature(campaignImportFeatureKey, CampaignImportReducer),
    StoreModule.forFeature(campaignExportFeatureKey, CampaignExportReducer),
    ToastModule,
  ],
  providers: [
    CampaignAPIService,
    CampaignFacadeService,
    CampaignImportResolverService,
    CampaignImportFacadeService,
    CampaignExportFacadeService,
    CampaignImportAPIService,
    CampaignExportService,
    ProfileFacadeService,
    CampaignResolverService,
    CampaignExportResolverService,
  ],
})
export class CampaignRootModule {}

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FluidDemandAPIService } from './fluid-demand-api.service';
import { HotToastService } from '@ngneat/hot-toast';
import { Injectable } from '@angular/core';
import { FluidDemandActions } from './fluid-demand.actions';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class FluidDemandEffects {
  constructor(
    private actions$: Actions,
    private service: FluidDemandAPIService,
    private toastService: HotToastService,
  ) {}

  get$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FluidDemandActions.get),
      mergeMap((params) =>
        this.service.get(params.clientId).pipe(
          map((data) => FluidDemandActions.getSuccess({ data })),
          catchError(() =>
            of(
              FluidDemandActions.getFailed({
                ...params,
                error: '500',
                message: 'Get fluid demands failed!',
              }),
            ),
          ),
        ),
      ),
    );
  });

  getListFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FluidDemandActions.getFailed),
        tap((action) => {
          this.toastService.error(action.message);
        }),
      ),
    { dispatch: false },
  );
}

import { createAction, props } from '@ngrx/store';

import {
  ResultExportDownloadingStageDetails,
  ResultExportModel,
  ResultExportNotification,
  ResultExportNotificationUpdateDetails,
} from './result-export.model';

function createActionTypeSentence(
  verb: string,
  status: 'OK' | 'NOK' | '' = '',
): string {
  return `[Result Export API] ${verb} ${status}`;
}

const getList = createAction(
  createActionTypeSentence('Get Roadmap Result Export List'),
  props<{ clientId: string }>(),
);

const getListSuccess = createAction(
  createActionTypeSentence('Get Roadmap Result Export List', 'OK'),
  props<{ data: Array<ResultExportModel> }>(),
);

const getListFailure = createAction(
  createActionTypeSentence('Get Roadmap Result Export List', 'NOK'),
  props<{ clientId: string }>(),
);

const createExportDetailedResults = createAction(
  createActionTypeSentence('Create Detailed Result Export'),
  props<{ clientId: string; roadmapIds: Array<string> }>(),
);

const createExportDetailedResultsSuccess = createAction(
  createActionTypeSentence('Create Detailed Result Export', 'OK'),
  props<{ data: ResultExportModel }>(),
);

const createExportDetailedResultsFailure = createAction(
  createActionTypeSentence('Create Detailed Result Export', 'NOK'),
  props<{ clientId: string; roadmapIds: Array<string> }>(),
);

const createExportSummaryResults = createAction(
  createActionTypeSentence('Create Summary Result Export'),
  props<{ clientId: string; roadmapIds: Array<string> }>(),
);

const createExportSummaryResultsSuccess = createAction(
  createActionTypeSentence('Create Summary Result Export', 'OK'),
  props<{ data: ResultExportModel }>(),
);

const createExportSummaryResultsFailure = createAction(
  createActionTypeSentence('Create Summary Result Export', 'NOK'),
  props<{ clientId: string; roadmapIds: Array<string> }>(),
);

const processNotification = createAction(
  createActionTypeSentence('Process Export Notification'),
  props<{ data: ResultExportNotification }>(),
);

const updateExportDetails = createAction(
  createActionTypeSentence('Update Export Details'),
  props<{ data: ResultExportNotificationUpdateDetails }>(),
);

const downloadResult = createAction(
  createActionTypeSentence('Download Result'),
  props<{ data: ResultExportDownloadingStageDetails }>(),
);

const downloadResultSuccess = createAction(
  createActionTypeSentence('Download Result', 'OK'),
);

const downloadResultFailure = createAction(
  createActionTypeSentence('Download Result', 'NOK'),
);

const cancelExport = createAction(
  createActionTypeSentence('Cancel Result Export'),
  props<{ clientId: string; exportId: string }>(),
);

const cancelExportSuccess = createAction(
  createActionTypeSentence('Cancel Result Export', 'OK'),
  props<{ clientId: string; exportId: string }>(),
);

const cancelExportFailure = createAction(
  createActionTypeSentence('Cancel Result Export', 'NOK'),
  props<{
    clientId: string;
    exportId: string;
    message: string;
    error: string;
  }>(),
);

export type ProcessNotificationDispatchAction =
  | ReturnType<typeof updateExportDetails>
  | ReturnType<typeof downloadResult>;

export const ResultExportEntityActions = {
  getList,
  getListSuccess,
  getListFailure,
  createExportDetailedResults,
  createExportDetailedResultsSuccess,
  createExportDetailedResultsFailure,
  createExportSummaryResults,
  createExportSummaryResultsSuccess,
  createExportSummaryResultsFailure,
  processNotification,
  updateExportDetails,
  downloadResult,
  downloadResultSuccess,
  downloadResultFailure,
  cancelExport,
  cancelExportSuccess,
  cancelExportFailure,
};

import {
  BehaviorSubject,
  combineLatest,
  MonoTypeOperatorFunction,
  Subscription,
} from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import {
  ClientFacadeService,
  ClientFilterFunctionDatum,
  ClientFiltersService,
} from 'ssotool-app/+client';
import { collectItemsWithKey } from 'ssotool-core/utils';
import {
  Coerce,
  FilterSettings,
  FilterWithCondition,
  getEndYear,
  getStartYear,
  GROUP_INDICATOR,
  hasMultipleValues,
  SimpleChipsComponent,
  YearlyValues,
} from 'ssotool-shared';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { BaseEntityTableController } from '../base';
import {
  ColumnDefModel,
  EntityAction,
  SelectedType,
} from './entity-common-table.model';
import { ARRAY_FIELDS } from './entity-common-table.references';

@Component({
  selector: 'sso-entity-common-table',
  templateUrl: './entity-common-table.component.html',
  styleUrls: ['./entity-common-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityCommonTableComponent
  extends BaseEntityTableController
  implements OnInit, OnDestroy
{
  @ViewChildren(SimpleChipsComponent)
  filterChips: QueryList<SimpleChipsComponent>;
  private readonly FIELD_TYPE = { name: 'type' };
  private readonly FIELD_NAME = { name: 'name', mode: 'event' };
  private readonly FIELD_PARENT = { name: 'parent' };
  private readonly FIELD_GROUP = { name: 'group' };

  stripLabel = GROUP_INDICATOR;
  arrayFields = ARRAY_FIELDS;
  private subscriptions = new Subscription();

  filterControl = new FormControl([]);
  searchInputControl = new FormControl('');
  mockYearlyControl = new FormControl({});
  chipForm = new FormControl({});

  COLUMN_DEFS: Array<ColumnDefModel> = [
    this.FIELD_NAME,
    this.FIELD_TYPE,
    this.FIELD_PARENT,
    this.FIELD_GROUP,
  ];
  COLUMN_DEFS_STR = [
    this.FIELD_NAME.name,
    this.FIELD_TYPE.name,
    this.FIELD_PARENT.name,
    this.FIELD_GROUP.name,
  ];
  _excludedCols: string[] = [];
  @Input() set excludedColumns(excluded: string[]) {
    /* istanbul ignore else */
    if (excluded?.length > 0) {
      this._excludedCols = excluded;
      this.FILTERS = this.getFilters();
    }
  }
  @Input() set columnDefs(value: ColumnDefModel[]) {
    this.COLUMN_DEFS = value || this.COLUMN_DEFS;
    if (value) {
      this.COLUMN_DEFS_STR = [];
      value.forEach((v) => this.COLUMN_DEFS_STR.push(v.name));
      this.FILTERS = this.getFilters();
    }
  }

  @Input() filterDialogTitle = '';
  FILTERS = this.getFilters();
  filterChanged$ = new BehaviorSubject<any>(null);
  filtersDialogConfig = this.filtersService.dialogConfig;

  private clientId$ = this.clientFacade.selectActiveClientData$.pipe(
    map((client) => client?.clientId),
  );

  private _filterSettings = new BehaviorSubject<FilterSettings>({
    objectArray: this.arrayFields.reduce((acc, curr) => {
      acc[curr] = 'name';
      return acc;
    }, {}),
    noHierarchy: true,
  });
  @Input() set additionalFilterSettings(settings: FilterSettings) {
    this._filterSettings.next({
      ...this.filterSettings,
      ...settings,
    });
  }
  get filterSettings() {
    return this._filterSettings.value;
  }
  filterOptions$ = combineLatest([
    this.clientId$,
    this._filterSettings,
    this.filterChanged$,
  ]).pipe(
    mergeMap(([id, settings, _]) =>
      this.filtersService.getFilterOptions(
        id,
        [
          {
            obs: this.entitiesSubject,
            attributes: this.FILTERS,
            labelMapper: {},
          },
        ],
        settings,
      ),
    ),
  );
  filterOptionsLength$ = this.filterOptions$.pipe(
    map(
      (options) =>
        Coerce.getObjKeys(Coerce.toObject<Record<string, string[]>>(options))
          .length,
    ),
  );
  noData$ = this.filterOptionsLength$.pipe(map((length) => !length));

  @Input() set isView(isView: boolean) {
    if (!isView) {
      this.COLUMN_DEFS_STR.unshift('selected');
      this.FILTERS.unshift('selected');
      this.filterChanged$.next(null);
    } else if (this.COLUMN_DEFS_STR[0] === 'selected') {
      this.COLUMN_DEFS_STR.shift();
      this.FILTERS.shift();
      this.filterChanged$.next(null);
    }
  }

  // Toggles the specified key in the model passed in the entity table.
  @Input() toggleSelectedKey = 'selected';
  @Input() references: Record<string, Record<string, string>> = {};
  @Input() actions: EntityAction[] = [];
  @Input() disableRowClick: boolean = false;

  @Output() baseClick: EventEmitter<any> = new EventEmitter();
  @Output() toggleUpdates: EventEmitter<any[]> = new EventEmitter();
  @Output() rowClicked: EventEmitter<any> = new EventEmitter();

  constructor(
    protected filtersService: ClientFiltersService,
    private clientFacade: ClientFacadeService,
  ) {
    super(filtersService);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeFiltersAndLevel();
    this.initializeSearch();
  }

  protected initializeFiltersAndLevel(): void {
    this.subscriptions.add(
      this.filtersService.initializeFilterControls(
        this.filterControl,
        [],
        [this.chipForm],
        this.filterControlFunction.bind(this),
      ),
    );
  }

  protected filterControlFunction?(
    filters: FilterWithCondition,
    level?: string,
  ) {
    return this.clientId$.pipe(
      mergeMap((id) =>
        this.filtersService
          .getFilterControlFunction(
            id,
            this.entitiesSubject.value,
            filters,
            null,
            this.filterSettings,
          )
          .pipe(this.updateFilteredEntities()),
      ),
    );
  }

  private updateFilteredEntities(): MonoTypeOperatorFunction<
    ClientFilterFunctionDatum<any>
  > {
    return tap(({ data }) => {
      this.filteredEntities.next(data);
    });
  }

  private initializeSearch() {
    this.searchInputControl.valueChanges.subscribe((searchInput) =>
      this.searchInput$.next(searchInput?.toLocaleLowerCase()),
    );
  }

  onClick(event: any) {
    this.baseClick.emit(event);
  }

  hasMultipleValues(value: YearlyValues) {
    return hasMultipleValues(value);
  }

  toggleAll() {
    this.masterToggle();
    const updated = this.entitiesSubject.value.map((entity) => {
      if (this.filtered$.value.includes(entity)) {
        return {
          ...entity,
          [this.toggleSelectedKey]: !this.isAllSelected$.value,
          selected: this.toggleSelectedValue(this.isAllSelected$.value),
        };
      } else {
        return entity;
      }
    });

    this.updateChanges(updated);
  }

  toggleOne(row) {
    this.selection.toggle(row);
    const updated = this.entitiesSubject.value.map((target) => {
      if (target.constraintId === row.constraintId) {
        const toggleValue = row[this.toggleSelectedKey];

        return {
          ...target,
          [this.toggleSelectedKey]: !toggleValue,
          selected: this.toggleSelectedValue(toggleValue),
        };
      } else {
        return target;
      }
    });

    this.updateChanges(updated);
  }

  private updateChanges(updates: any[]) {
    this.entitiesSubject.next(updates);
    this.toggleUpdates.emit(updates);
  }

  clearFilters() {
    this.filterChips.forEach((chip) => chip.clear());
    this.activeFilters.next({});
  }

  rowClick(event) {
    if (this.disableRowClick) {
      return;
    }
    this.rowClicked.emit(event);
  }

  nameColumnClick(event) {
    this.rowClicked.emit(event);
  }

  getEntityArrayFieldDisplay(entityField: { name: string }[]) {
    return entityField
      .map((field) => field?.name)
      .sort()
      .join(', ');
  }

  getReferenceValue(field: string, value: string) {
    return this.references?.[field]?.[value];
  }

  private toggleSelectedValue(isSelected): SelectedType {
    return isSelected ? SelectedType.NOT_SELECTED : SelectedType.SELECTED;
  }

  private getFilters() {
    return collectItemsWithKey(this.COLUMN_DEFS, 'name').filter(
      (colName) => !this._excludedCols.includes(colName),
    );
  }

  checkIfSelected(entity: any) {
    return entity?.selected === SelectedType.SELECTED;
  }

  getStartYear(value: Record<string, string>): string {
    return getStartYear(value);
  }

  getEndYear(value: Record<string, string>): string {
    return getEndYear(value);
  }
}

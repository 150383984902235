import { InputModule, SelectModule, YearlyInputModule } from 'ssotool-shared';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { MarketFormComponent } from './market-form.component';

@NgModule({
  declarations: [MarketFormComponent],
  exports: [MarketFormComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    InputModule,
    SelectModule,
    YearlyInputModule,
    MatFormFieldModule,
    MatDividerModule,
    MatIconModule,
    MatTooltipModule,
  ],
})
export class MarketFormModule {}

import { map, Observable, of, skipWhile } from 'rxjs';
import { ClientFacadeService } from 'ssotool-app/+client/store/client.facade.service';
import { Coerce } from 'ssotool-app/shared/helpers/coerce.utils';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'sso-sites-list',
  templateUrl: './sites-list.component.html',
  styleUrls: ['./sites-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SitesListComponent implements OnInit {
  entities$: Observable<any> = of([]);
  loading$: Observable<boolean> = of(false);
  clientId = '';

  columnDefs = [
    { name: 'name', mode: 'event' },
    { name: 'location', label: 'Location', mode: 'location' },
    { name: 'geoName', label: 'Geography' },
    { name: 'archetypeName', label: 'Archetype' },
    { name: 'companyName', label: 'Company Entity' },
  ];

  constructor(private clientFacade: ClientFacadeService) {}

  ngOnInit(): void {
    this.clientFacade.selectActiveClientData$
      .pipe(untilDestroyed(this))
      .subscribe((client) => {
        /* istanbul ignore else */
        if (client && client.clientId) {
          this.clientId = client.clientId;
          this.entities$ = this.clientFacade.selectSites$(client.clientId).pipe(
            skipWhile((data) => !data),
            map((data) =>
              Coerce.getObjValues(data).map(
                ({
                  archetypeId,
                  archetypeName,
                  companyName,
                  geoId,
                  geoName,
                  location,
                  representativeSite,
                  companyEntityId,
                  siteId,
                  name,
                }) => ({
                  id: siteId,
                  archetypeId,
                  name,
                  archetypeName,
                  companyName,
                  location,
                  geoId,
                  geoName,
                  boldRow: representativeSite,
                  companyEntityId,
                }),
              ),
            ),
          );
          this.loading$ = this.clientFacade.dataLoading$(client.clientId);
        }
      });
  }
}

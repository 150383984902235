import { Observable, of } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client';
import { Coerce } from 'ssotool-app/shared/helpers';
import { SSOToolRoutePathService } from 'ssotool-shared/services';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isFeatureEnabled } from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';

@UntilDestroy()
@Component({
  selector: 'sso-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyListComponent implements OnInit {
  entities$: Observable<any> = of([]);
  loading$: Observable<boolean> = of(false);
  clientId = '';

  columnDefs = [
    { name: 'name', mode: 'event' },
    { name: 'parent' },
    ...(isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE)
      ? []
      : [{ name: 'type' }, { name: 'groups', mode: 'array' }]),
  ];

  constructor(
    private clientFacade: ClientFacadeService,
    private router: Router,
    private routePath: SSOToolRoutePathService,
  ) {}

  ngOnInit(): void {
    this.clientFacade.selectActiveClientData$
      .pipe(untilDestroyed(this))
      .subscribe((client) => {
        /* istanbul ignore else */
        if (client && client.clientId) {
          this.clientId = client.clientId;
          this.entities$ = this.clientFacade
            .selectCompanies$(client.clientId)
            .pipe(
              skipWhile((data) => !data),
              map((data) =>
                Coerce.getObjValues(data).map(
                  ({
                    companyEntityId,
                    name,
                    companyType,
                    parentName,
                    group,
                  }) => ({
                    id: companyEntityId,
                    name,
                    parent: parentName,
                    type: companyType,
                    group,
                  }),
                ),
              ),
            );
          this.loading$ = this.clientFacade.dataLoading$(client.clientId);
        }
      });
  }

  onClick(event: any) {
    this.router.navigate(this.routePath.viewCompany(this.clientId, event.id));
  }
}

import { DataImportLog } from 'ssotool-app/+data-management/stores';

import { MatTableDataSource } from '@angular/material/table';

import { TabDetail } from '../tab-group';

export type Module = {
  name: string;
  displayedColumns: string[];
  dataSource: MatTableDataSource<DataImportLog>;
};

export const LOG_REGEX =
  /.*(TRACE|DEBUG|INFO|NOTICE|WARN|WARNING|ERROR|SEVERE|FATAL)\s+(.+):[a-zA-Z_/]+:[0-9]+\s+/i;

export const ENTITIES_WITH_COLUMN = [
  'timeseries',
  'flows',
  'volumes',
  'prices',
];

export const LOGS_TAB_NAME = 'Real-time logs';
export const ERROR_TAB_NAME = 'Error Messages Table';

export const LOG_TAB_DEFINITIONS: TabDetail[] = [
  {
    label: LOGS_TAB_NAME,
    alignment: 'row',
  },
  {
    label: ERROR_TAB_NAME,
    alignment: 'row',
  },
];

import { ProgressIndicatorModule } from 'ssotool-shared/component/progress-indicator/progress-indicator.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { CampaignPageComponent } from './campaign-page.component';
import { CampaignRoutingModule } from './campaign-routing.module';
import { BaseDrawerModule } from './components/drawer';
import { CampaignImportModule } from './components/import';
import { CampaignTableModule } from './components/table/campaign-table.module';

@NgModule({
  declarations: [CampaignPageComponent],
  exports: [CampaignPageComponent],
  imports: [
    CommonModule,
    CampaignRoutingModule,
    TranslateModule,
    CampaignTableModule,
    MatDialogModule,
    CampaignImportModule,
    MatIconModule,
    ProgressIndicatorModule,
    BaseDrawerModule,
  ],
})
export class CampaignModule {}

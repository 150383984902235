import { SsoCommifyModule } from 'ssotool-app/shared/directives/commify';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PushPipe } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { YearlyForecastModule } from '@oculus/components/yearly-forecast';

import { YearlyViewModule } from '../../component/yearly-view';
import { PipesModule } from '../../pipes';
import { SparklineModule } from '../sparkline';
import { YearlyInputComponent } from './yearly-input.component';

const components = [YearlyInputComponent];
const modules = [
  FormsModule,
  TranslateModule,
  FlexLayoutModule,
  PushPipe,
  ReactiveFormsModule,
  SparklineModule,
  YearlyViewModule,
  SsoCommifyModule,
  PipesModule,
  MatTooltipModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatButtonModule,
  MatMenuModule,
  YearlyForecastModule,
];

@NgModule({
  declarations: components,
  imports: [CommonModule, ...modules],
  exports: components,
  providers: [],
})
export class YearlyInputModule {}

<div
  *ngIf="showOutOfRange(); else ganttChart"
  data-testid="incompatible-view"
  class="incompatible-date"
>
  Dates are out of range!
</div>
<ng-template #ganttChart>
  <div class="sso-gantt" fxLayout="column" fxLayoutGap="1rem">
    <div class="gantt-header" fxLayout="row" fxLayoutAlign="end none">
      <div fxFlex="36">
        <mat-list
          fxFlex="15"
          fxLayout="row"
          fxLayoutAlign="center center"
          class="selection-column"
          *ngIf="isSelectionView"
        >
          <mat-checkbox
            mat-header
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            color="primary"
            data-testid="master-toggle-id"
          >
          </mat-checkbox>
        </mat-list>
        <mat-list
          fxFlex
          class="task-name"
          [ngClass]="{
            'gantt-border-right': !enableActionColumn
          }"
        >
          <div mat-header fxLayout="column" fxLayoutAlign="center start">
            <div fxLayout="row" class="empty">{{ subDates[0] }}</div>
            <div fxLayout="row" fxLayoutAlign="start center">
              {{ config?.taskName | uppercase }}S
            </div>
          </div>
        </mat-list>
        <mat-list
          class="action-column"
          *ngIf="enableActionColumn"
          fxFlex="110px"
          fxLayoutAlign="start center"
        >
          <ng-content select="[actionHeader]"></ng-content>
        </mat-list>
      </div>
      <div #yearColumns fxFlex="64">
        <mat-list
          class="gantt-chart"
          [style.max-width.px]="ganttHeaderWidth"
          [scrollLeft]="scrollLeft"
        >
          <div mat-header fxLayout="column" [style.width.px]="ganttHeaderWidth">
            <div fxLayout="row">
              <div
                [style.width.px]="subColumnWidth + 1"
                *ngFor="let date of subDates"
              >
                {{ date }}
              </div>
            </div>
            <div
              class="date-header"
              fxLayout="row"
              fxLayoutAlign="start center"
              *ngrxLet="dates$ as dates"
            >
              <div
                [style.width.px]="columnWidth"
                *ngFor="let date of dates"
                fxLayoutAlign="center center"
              >
                {{ date }}
              </div>
            </div>
          </div>
        </mat-list>
      </div>
    </div>
    <ng-content select="[ganttSubheader]"></ng-content>
    <ng-container *ngIf="chartShownItems$ | async as shownedItems">
      <div
        class="gantt-content"
        fxLayout="row"
        fxLayoutAlign="end none"
        *ngIf="shownedItems?.length > 0; else showEmptyChart"
      >
        <div fxFlex="36">
          <mat-list>
            <cdk-virtual-scroll-viewport
              ssoWindowVirtualScrollStrategy
              #scrollViewport
              id="ganttContentInfo"
              class="viewport-scroll-none"
              (scroll)="onScroll($event)"
            >
              <sso-gantt-name
                *cdkVirtualFor="
                  let item of chartShownItems$ | async;
                  templateCacheSize: 0;
                  trackBy: itemById
                "
                [ganttItem]="item"
                [active]="isSelectedItem(item.id) | ngrxPush"
                (selectClick)="onNameClick($event, item)"
                data-testid="gantt-name"
              >
                <div
                  fxFlex="15"
                  fxLayout="row"
                  fxLayoutAlign="center none"
                  class="selection-column"
                  *ngIf="isSelectionView"
                >
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(item.id) : null"
                    [checked]="selection.isSelected(item.id)"
                    color="primary"
                    [attr.data-testid]="item.name"
                  ></mat-checkbox>
                </div>
                <div
                  fxFlex
                  class="task-name"
                  [ngClass]="{
                    'gantt-border-right': !enableActionColumnForAll
                  }"
                  data-testid="row-name"
                >
                  <div
                    mat-line
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="5px"
                  >
                    <span ssoTooltip [content]="itemName">{{ item.name }}</span>
                    <mat-icon
                      *ngIf="!isResultsOnlyItemEditable(item)"
                      fxLayoutAlign="center center"
                      class="help-icon"
                      [matTooltip]="
                        'Roadmap.tooltips.baselineCampaign' | translate
                      "
                      [matTooltipPosition]="'right'"
                      >star</mat-icon
                    >
                  </div>
                  <div #itemName style="font-size: large">
                    {{ item.name }}
                  </div>
                </div>
                <div
                  fxFlex="85px"
                  class="action-column"
                  *ngIf="enableActionColumnForAll"
                  fxLayout="row"
                  fxLayoutAlign="end center"
                  (click)="$event.stopPropagation()"
                >
                  <div>
                    <button
                      mat-icon-button
                      class="gray-icon filled"
                      data-testid="lock-button"
                      (click)="onLockClick(item)"
                      [disabled]="disableActionColumn(item)"
                      *ngIf="item.isLock === 'lock'"
                      [matTooltip]="'Roadmap.tooltips.lock' | translate"
                    >
                      <mat-icon>lock</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      class="gray-icon"
                      data-testid="open-lock-button"
                      (click)="onOpenLockClick(item)"
                      [disabled]="disableActionColumn(item)"
                      *ngIf="item.isLock === 'openLock'"
                      [matTooltip]="'Roadmap.tooltips.openLock' | translate"
                    >
                      <mat-icon class="material-icons-outlined"
                        >lock_open</mat-icon
                      >
                    </button>
                    <button
                      mat-icon-button
                      class="partial-icon"
                      data-testid="partial-lock-button"
                      (click)="onOpenLockClick(item)"
                      [disabled]="disableActionColumn(item)"
                      *ngIf="item.isLock === 'partial'"
                      [matTooltip]="'Roadmap.tooltips.openLock' | translate"
                    >
                      <mat-icon svgIcon="partial_lock_icon"></mat-icon>
                    </button>
                  </div>
                  <button
                    mat-icon-button
                    class="gray-icon"
                    data-testid="more-actions"
                    [matMenuTriggerFor]="actionMenu"
                    [disabled]="disableActionColumn(item)"
                    type="button"
                  >
                    <mat-icon>more_horiz</mat-icon>
                  </button>
                  <mat-menu #actionMenu>
                    <ng-container *ngFor="let action of ganttItemActions">
                      <ng-container [ngSwitch]="action.id">
                        <button
                          *ngSwitchCase="'editRanges'"
                          mat-menu-item
                          (click)="onUpdateLimits(item)"
                        >
                          <mat-icon>date_range</mat-icon>
                          <span>{{ action.label | translate }}</span>
                        </button>
                        <button
                          *ngSwitchCase="'deleteItem'"
                          mat-menu-item
                          class="warn-item"
                          (click)="deleteItem(item)"
                        >
                          <mat-icon>delete_outline</mat-icon>
                          <span>{{ action.label | translate }}</span>
                        </button>
                        <button
                          mat-menu-item
                          *ngSwitchDefault
                          (click)="action.actionFn(item)"
                          [disabled]="isActionDisabled(item, action)"
                        >
                          <mat-icon>{{ action.icon }}</mat-icon>
                          <span>{{ action.label | translate }}</span>
                        </button>
                      </ng-container>
                    </ng-container>
                  </mat-menu>
                </div>
              </sso-gantt-name>
            </cdk-virtual-scroll-viewport>
          </mat-list>
        </div>
        <div fxFlex="64">
          <mat-list
            class="gantt-chart"
            [style.max-width.px]="ganttHeaderWidth"
            (mousedown)="onMouseDown($event)"
          >
            <cdk-virtual-scroll-viewport
              ssoWindowVirtualScrollStrategy
              #scrollViewport
              id="ganttContentBars"
              class="viewport-scroll-none"
              [scrollLeft]="scrollLeft"
              (scroll)="onScroll($event)"
            >
              <mat-list-item
                *cdkVirtualFor="
                  let data of chartShownItems$ | async;
                  templateCacheSize: 0;
                  trackBy: itemById
                "
                [style.width.px]="ganttHeaderWidth"
                [ngClass]="{
                  active: isSelectedItem(data.id) | ngrxPush,
                  resultsonly: !isResultsOnlyItemEditable(data)
                }"
                class="right-item"
              >
                <sso-gantt-bar
                  [data]="data"
                  [ganttHeaderWidth]="ganttHeaderWidth"
                  [readonly]="disableEditActions(data)"
                  [disabled]="isDisabled"
                  [columnWidth]="columnWidth"
                  [ganttStartDate]="startDate$ | ngrxPush"
                  [viewMode]="config?.viewMode"
                  [disableOutputMovement]="disableOutputMovement"
                  (dateChange)="updateChartItemDate($event)"
                  [multiSelectChanges]="multiSelectChanges$ | ngrxPush"
                >
                </sso-gantt-bar>
              </mat-list-item>
              <div
                *ngIf="hasLimits"
                class="gantt-limit"
                [style.height.px]="ganttHeightLimit"
                [style.width.px]="ganttWidthLimit"
                [style.left.px]="ganttLeftLimit"
                [style.background]="config?.limitColor"
              ></div>
            </cdk-virtual-scroll-viewport>
          </mat-list>
        </div>
      </div>
      <sso-sticky-horizontal-scroll
        class="gantt-x-scroll-bar"
        leftContentSpace="36"
        rightContentSpace="64"
        contentScrollId="ganttChartScroll"
        [contentWidth]="ganttHeaderWidth"
        [contentScrollLeft]="scrollLeft"
        [contentColumnWidth]="columnWidth"
        (scrollHorizontal)="onScroll($event)"
        *ngIf="shownedItems?.length > 0"
      ></sso-sticky-horizontal-scroll>
    </ng-container>
    <ng-template #showEmptyChart>
      <div class="empty-gantt">
        <h2>{{ config.emptyChartMessage | translate }}</h2>
      </div>
    </ng-template>
  </div>
</ng-template>

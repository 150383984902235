<ng-container *ngIf="(chips$ | ngrxPush)?.length">
  <div class="list-container">
    <mat-chip-listbox data-testid="chip-list">
      <mat-chip-option
        *ngFor="let chip of chips$ | ngrxPush"
        (removed)="removeChip(chip)"
      >
        {{ chip.key | uppercase }} {{ chip.condition }} '{{
          chip.value | strip: stripLabel
        }}'
        <mat-icon class="white-color" data-testid="remove-button" matChipRemove
          >cancel</mat-icon
        >
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
</ng-container>

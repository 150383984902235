import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
  ProgressIndicatorModule,
  SearchInputModule,
  SSOSpinnerModule,
  TabGroupModule,
} from 'ssotool-shared';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PushPipe } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';

import { ClientListPageComponent } from './client-list-page.component';
import { ClientRoutingModule } from './client-routing.module';
import { ShareClientModule } from './components';
import { NoClientModule } from './components/no-client/no-client.module';

@NgModule({
  declarations: [ClientListPageComponent],
  imports: [
    FlexLayoutModule,
    CommonModule,
    FormsModule,
    ShareClientModule,
    ReactiveFormsModule,
    ClientRoutingModule,
    MatFormFieldModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatButtonModule,
    MatBadgeModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    TranslateModule,
    SearchInputModule,
    TabGroupModule,
    NoClientModule,
    SSOSpinnerModule,
    NgxSkeletonLoaderModule,
    PushPipe,
    MatTooltipModule,
    ProgressIndicatorModule,
  ],
  exports: [ClientListPageComponent],
})
export class ClientModule {}

<form
  [formGroup]="form"
  fxLayout="column"
  fxLayoutGap="16px"
  (ngSubmit)="onSubmit()"
  class="dialog-container"
>
  <div fxLayout="column" fxLayoutGap="2px" fxLayoutAlign="start start">
    <h1>
      {{ 'Generic.messages.defineRange' | translate : { name: data.name } }}
    </h1>
    <a (click)="onSetDefaultValues()" class="reset-button">{{
      'Generic.labels.setToDefault' | translate
    }}</a>
  </div>
  <mat-divider></mat-divider>

  <div class="input-form" fxLayout="column" fxLayoutGap="10px">
    <sso-input
      [label]="'Generic.labels.earliestDate' | translate : { date: data.label }"
      formControlName="earliestDate"
      [required]="true"
      [errorMessageMap]="errorMessages?.earliestDate"
    ></sso-input>
    <sso-input
      [label]="'Generic.labels.latestDate' | translate : { date: data.label }"
      formControlName="latestDate"
      [required]="true"
      [errorMessageMap]="errorMessages?.latestDate"
    ></sso-input>
  </div>
  <mat-divider></mat-divider>
  <mat-error class="form-errors" *ngIf="form?.errors?.mustBeEarlier">
    {{ errorMessages?.mustBeEarlier }}
  </mat-error>
  <div
    mat-dialog-actions
    class="dialogActions"
    fxLayout="row"
    fxLayoutGap="10px"
  >
    <button
      mat-flat-button
      type="submit"
      color="primary"
      [disabled]="isPristine"
      data-testid="update"
    >
      {{ 'Generic.labels.update' | translate }}
    </button>
    <button mat-flat-button type="button" [color]="" (click)="onCancel()">
      {{ 'Generic.labels.cancel' | translate }}
    </button>
  </div>
</form>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FirstValuePipe } from './firstvalue.pipe';
import { KeysPipe } from './keys.pipe';
import { LoadingStatusPipe } from './loadingstatus.pipe';
import { MetricScalePipe } from './metric-scale.pipe';
import { ObjectFilterPipe } from './object-filter.pipe';
import { SafePipe } from './safe.pipe';
import { StripPipe } from './strip.pipe';
import { TotalValuePipe } from './totalvalue.pipe';

const pipes = [
  MetricScalePipe,
  StripPipe,
  FirstValuePipe,
  TotalValuePipe,
  LoadingStatusPipe,
  SafePipe,
  ObjectFilterPipe,
  KeysPipe,
];
@NgModule({
  imports: [CommonModule],
  exports: [...pipes],
  declarations: [...pipes],
})
export class PipesModule {}

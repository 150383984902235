import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client/store/client.facade.service';
import { Coerce, createErrorObject } from 'ssotool-app/shared/helpers';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { CampaignAPIService } from '../services/campaign.service';
import { CampaignTypes } from './campaign.model';
import { CampaignActions } from './campaign.action';
import { CampaignFacadeService } from './campaign.facade.service';
import { ToastService } from 'ssotool-app/shared/services/toast/toast.service';

@Injectable()
export class CampaignEffects {
  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignActions.create),
      mergeMap((action) =>
        this.campaignService.create(action.clientId, action.data).pipe(
          map((response) =>
            CampaignActions.createSuccess({
              data: response,
              message: 'Create campaign success!',
            }),
          ),
          catchError((error) =>
            of(
              CampaignActions.createFailure({
                ...createErrorObject(error, 'Create campaign error!'),
                clientId: action.clientId,
                data: action,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  createSuccess$ = createEffect(
    () => this.actions$.pipe(ofType(CampaignActions.createSuccess)),
    { dispatch: false },
  );

  createFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignActions.createFailure),
        tap((action) =>
          this.toast.showToast(
            action.message,
            'action',
            undefined,
            of({}).pipe(
              tap(() => {
                this.campaignFacade.create(action.clientId, action.data);
              }),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignActions.update),
      mergeMap((action) =>
        this.campaignService
          .update(action.clientId, action.id, action.data)
          .pipe(
            map((response) =>
              CampaignActions.updateSuccess({
                data: response,
                message: 'Update campaign success!',
              }),
            ),
            catchError((error) =>
              of(
                CampaignActions.updateFailure({
                  ...createErrorObject(error, 'Update campaign error!'),
                  data: action,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  updateSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignActions.updateSuccess),
        tap((action) => {
          this.toast.showToast(action.message, 'success');
          this.clientFacade.refreshClientForIneligibleCampaign(
            [Coerce.toEmptyObject<CampaignTypes>(action.data).id],
            Coerce.toEmptyObject<CampaignTypes>(action.data).clientId,
          );
        }),
      ),
    { dispatch: false },
  );

  updateFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignActions.updateFailure),
        tap((action) =>
          this.toast.showToast(
            action.message,
            'action',
            undefined,
            of({}).pipe(
              tap(() =>
                this.campaignFacade.update(
                  action.data.clientId,
                  action.data.id,
                  action.data.data,
                ),
              ),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignActions.get),
      mergeMap((action) =>
        this.campaignService
          .get(action.clientId, action.id, action.campaignType)
          .pipe(
            map((response) =>
              CampaignActions.getSuccess({
                data: response,
                message: 'Get campaign success!',
              }),
            ),
            catchError((error) =>
              of(
                CampaignActions.getFailure({
                  ...createErrorObject(error, 'Get campaign error!'),
                  data: action,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getSuccess$ = createEffect(
    () => this.actions$.pipe(ofType(CampaignActions.getSuccess)),
    { dispatch: false },
  );

  getFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignActions.getFailure),
        tap((action) => this.toast.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  getList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignActions.getList),
      mergeMap((action) =>
        this.campaignService.getList(action.clientId).pipe(
          map((response: any) => {
            return CampaignActions.getListSuccess({
              clientId: action.clientId,
              data: response,
              message: 'Get campaign list success!',
            });
          }),
          catchError((error) =>
            of(
              CampaignActions.getListFailure({
                ...createErrorObject(error, 'Get campaign list error!'),
                data: action,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getListSuccess$ = createEffect(
    () => this.actions$.pipe(ofType(CampaignActions.getListSuccess)),
    { dispatch: false },
  );

  getListFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignActions.getListFailure),
        tap((action) => this.toast.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  deleteMultiple$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignActions.deleteCampaigns),
      mergeMap((action) =>
        this.campaignService
          .deleteMultiple(action.clientId, action.campaigns)
          .pipe(
            map((response) =>
              CampaignActions.deleteCampaignsSuccess({
                clientId: action.clientId,
                data: response,
                message: 'Delete campaigns success!',
              }),
            ),
            catchError((error) =>
              of(
                CampaignActions.deleteCampaignsFailure({
                  ...createErrorObject(error, 'Delete campaigns error!'),
                  data: action,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  deleteMultipleSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignActions.deleteCampaignsSuccess),
        tap((action) => {
          this.campaignFacade.getList(action.clientId);
          this.clientFacade.refreshClientForIneligibleCampaign(
            Coerce.toEmptyObject<Record<string, string[]>>(action.data).ids,
            action.clientId,
          );
        }),
      ),
    { dispatch: false },
  );

  deleteMultipleFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignActions.deleteCampaignsFailure),
        tap((action) =>
          this.toast.showToast(
            action.message,
            'action',
            undefined,
            of({}).pipe(
              tap(() =>
                this.campaignFacade.deleteMultiple(
                  action.data.clientId,
                  action.data.campaigns,
                ),
              ),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignActions.deleteCampaign),
      mergeMap((action) =>
        this.campaignService
          .delete(action.clientId, action.id, action.campaignType)
          .pipe(
            map((response) =>
              CampaignActions.deleteCampaignSuccess({
                data: response,
                message: 'Delete campaign success!',
              }),
            ),
            catchError((error) =>
              of(
                CampaignActions.deleteCampaignFailure({
                  ...createErrorObject(error, 'Delete campaign error!'),
                  data: action,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  deleteSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignActions.deleteCampaignSuccess),
        tap((action) => {
          this.clientFacade.refreshClientForIneligibleCampaign(
            [Coerce.toEmptyObject<CampaignTypes>(action.data).id],
            Coerce.toEmptyObject<CampaignTypes>(action.data).clientId,
          );
        }),
      ),
    { dispatch: false },
  );

  deleteFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignActions.deleteCampaignFailure),
        tap((action) =>
          this.toast.showToast(
            action.message,
            'action',
            undefined,
            of({}).pipe(
              tap(() => {
                const data = Coerce.toEmptyObject<CampaignTypes>(action.data);
                this.campaignFacade.delete(
                  data.clientId,
                  data.id,
                  data.campaignType,
                );
              }),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  batchDuplicate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignActions.batchDuplicateCampaigns),
      mergeMap((action) =>
        this.campaignService
          .batchDuplicate(action.clientId, action.campaigns)
          .pipe(
            map((response) =>
              CampaignActions.batchDuplicateCampaignsSuccess({
                clientId: action.clientId,
                data: response,
                message: 'Duplicate campaign success!',
              }),
            ),
            catchError((error) =>
              of(
                CampaignActions.batchDuplicateCampaignsFailure({
                  ...createErrorObject(error, 'Duplicate campaign error!'),
                  data: action,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  batchDuplicateSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignActions.batchDuplicateCampaignsSuccess),
        tap((action) => {
          this.toast.showToast(action.message, 'success');
          this.campaignFacade.getList(action.clientId);
        }),
      ),
    { dispatch: false },
  );

  batchDuplicateFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignActions.batchDuplicateCampaignsFailure),
        tap((action) =>
          this.toast.showToast(
            action.message,
            'action',
            undefined,
            of({}).pipe(
              tap(() =>
                this.campaignFacade.batchDuplicate(
                  action.data.clientId,
                  action.data.campaigns,
                ),
              ),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  populateWithLibrary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignActions.populateWithLibrary),
      mergeMap((action) =>
        this.campaignService.populateWithLibrary(action.clientId).pipe(
          map((response: any) => {
            return CampaignActions.populateWithLibrarySuccess({
              clientId: action.clientId,
              data: response,
              message: 'Populate with library success!',
            });
          }),
          catchError((error) =>
            of(
              CampaignActions.populateWithLibraryFailure({
                ...createErrorObject(error, 'Populate with library error!'),
                data: action,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  populateWithLibrarySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignActions.populateWithLibrarySuccess),
        tap((action) => {
          this.toast.showToast(action.message, 'success');
          this.campaignFacade.getList(action.clientId);
        }),
      ),
    { dispatch: false },
  );

  populateWithLibraryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignActions.populateWithLibraryFailure),
        tap((action) => this.toast.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private campaignService: CampaignAPIService,
    private toast: ToastService,
    private campaignFacade: CampaignFacadeService,
    private clientFacade: ClientFacadeService,
  ) {}
}

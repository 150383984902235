<form
  [formGroup]="yearlyChartDialogForm"
  (ngSubmit)="onConfirm()"
  fxLayout="column"
>
  <h2 *ngIf="!data?.isViewMode; else viewTitle">Modify Yearly Values</h2>
  <ng-template #viewTitle>
    <div fxLayoutGap="10px" fxLayout="row" class="mat-headline-6">
      <mat-icon aria-hidden="false">timeline</mat-icon>
      <h2 class="mat-headline-6">{{ data?.name }} Yearly Values</h2>
    </div>
  </ng-template>

  <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="25px">
    <sso-line-chart
      fxFlex
      xAxisLabel="Years"
      yAxisLabel="Value"
      [yearlyValuesMap]="yearlyChartDialogForm?.controls?.rangeValue?.value"
    >
    </sso-line-chart>
    <div class="range-input" fxFlex="40%">
      <p *ngIf="!data?.isViewMode">Input Range</p>
      <sso-range-input
        formControlName="rangeValue"
        [start]="startYear"
        [end]="endYear"
        [isViewMode]="data?.isViewMode"
        [errorMessage]="data?.errorMessage"
        [isNonNegativeInteger]="data?.isNonNegativeInteger"
        [granularValueValidatorData]="data?.granularValueValidatorData"
      >
      </sso-range-input>
    </div>
  </div>

  <div mat-dialog-actions fxLayout="row" class="dialogActions">
    <button
      mat-flat-button
      type="submit"
      color="primary"
      [disabled]="isDisabled()"
      [hidden]="data?.isViewMode"
    >
      {{ data?.confirm || 'OK' }}
    </button>
    <button
      mat-flat-button
      type="button"
      [color]="data?.isViewMode && 'primary'"
      (click)="onClose()"
    >
      {{ data?.close || (data?.isViewMode ? 'Close' : 'Cancel') }}
    </button>
  </div>
</form>

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseEntitiesFacadeService } from 'ssotool-app/+entities/stores/entities-facade.service';
import { ClientEntityState } from 'ssotool-app/+entities/stores/entities.reducer';
import { distinctObject } from 'ssotool-shared/helpers';

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { FlowsLocalStorageService } from '../flows-local-storage/flows-local-storage.service';
import { FlowsType } from '../flows-local-storage/flows.model';
import { BinDataActions } from './bindata.actions';
import { BinDataEntityStore } from './bindata.reducer';
import { loaded } from './bindata.selector';

@Injectable()
export class BinDataFacadeService extends BaseEntitiesFacadeService {
  private binDataActions;
  private rxBinStore: Store<ClientEntityState>;

  constructor(
    private store: Store<ClientEntityState>,
    private flowsService: FlowsLocalStorageService,
  ) {
    super(store);
    this.rxBinStore = store;
    this.binDataActions = BinDataActions(this.getAPIName());
  }

  loaded$ = this.store.pipe(select(loaded));

  getBinData(
    location: FlowsType,
    entityId: string,
    index: number,
  ): Observable<string[]> {
    return this.flowsService.getFlowDataByEntityId(location, entityId).pipe(
      distinctObject,
      map((value) => (value?.length > 0 ? value[index] : [])),
    );
  }

  getAPIName(): string {
    return BinDataEntityStore.featureName;
  }

  downloadAllBinData(clientId: string) {
    this.rxBinStore.dispatch(
      this.binDataActions.downloadAllBindataAction({ clientId }),
    );
  }

  clearLocalStorage() {
    this.flowsService.clear(FlowsType.TIMESERIES);
    this.flowsService.clear(FlowsType.FLOWVOLUME);
    this.flowsService.clear(FlowsType.FLOWPRICE);
  }
}

import { generateEndpoint } from 'ssotool-app/core';
import { BackendFluidDemand, FluidDemand } from './fluid-demand.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, Observer, throwError } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  skipWhile,
  switchMap,
} from 'rxjs/operators';
import { ConfigService } from 'ssotool-app/shared/services/config';
import { download } from 'ssotool-app/shared';

@Injectable()
export class FluidDemandAPIService {
  constructor(private http: HttpClient, private config: ConfigService) {}

  get(clientId: string): Observable<FluidDemand> {
    return this.http.get<any>(this.makeGetEndpoint(clientId)).pipe(
      mergeMap((fluidDemandDataSignedUrl: any) => {
        if (!fluidDemandDataSignedUrl) {
          return EMPTY;
        }

        return this.http
          .get(fluidDemandDataSignedUrl.signedUrl, {
            reportProgress: true,
            observe: 'events',
            responseType: 'blob',
          })
          .pipe(
            download(),
            skipWhile((downloadedData) => !downloadedData['content']),
            switchMap(
              (response) =>
                new Observable((observer: Observer<any>) => {
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    observer.next(reader.result);
                    observer.complete();
                  };
                  reader.readAsText(response.content);
                }),
            ),
            map((data: string) => this.mapToFrontend(data)),
          );
      }),
      catchError((error) => throwError(error)),
    );
  }

  private makeGetEndpoint(clientId: string): string {
    const { baseUrl, endpoints } = this.config.api;
    return generateEndpoint(baseUrl, endpoints.fluidDemand.get, clientId);
  }

  // temp mapper
  mapToFrontend(data: string): FluidDemand {
    const backendData = JSON.parse(data) as BackendFluidDemand;
    return backendData as FluidDemand;
  }
}

import { Component, Input } from '@angular/core';

import { TitleBarBreadcrumbs, TitleStatus } from './title-bar.model';

@Component({
  selector: 'sso-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.scss'],
})
export class TitleBarComponent {
  @Input() titlePage: string = '';
  @Input() subtitle: string = '';
  @Input() icon: TitleStatus = {};
  @Input() iconRotate: boolean = false;
  @Input() breadcrumbs: TitleBarBreadcrumbs = [];

  withBorder = false;

  constructor() {}
}

import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  Input,
  Renderer2,
} from '@angular/core';

import { ClickMode } from './selection-emitter.model';

@Directive({
  selector: '[ssoSelectionEmitter]',
})
export class SelectionEmitterDirective implements OnDestroy, AfterViewInit {
  @Output() selectClick = new EventEmitter<ClickMode>();
  @Input() selectable: boolean = true;

  private clickListener;

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.clickListener = this.renderer.listen(
      this.element.nativeElement,
      'click',
      (event: MouseEvent): void => {
        this.selectClick.emit(this.getClickMode(event));
      },
    );
  }

  ngOnDestroy(): void {
    this.clickListener();
  }

  private getClickMode(event: MouseEvent): ClickMode {
    return this.selectable
      ? (event.shiftKey && ClickMode.SHIFT) ||
          (event.ctrlKey && ClickMode.CTRL) ||
          ClickMode.DEFAULT
      : ClickMode.DEFAULT;
  }
}

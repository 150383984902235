<mat-progress-bar
  *ngIf="loading$ | async"
  mode="indeterminate"
  color="accent"
></mat-progress-bar>
<div class="dialog-container">
  <h1 class="mat-headline-6" mat-dialog-title>
    <div [innerHTML]="data?.title || 'Confirmation'"></div>
  </h1>
  <form [formGroup]="clientForm" (ngSubmit)="onSubmit()">
    <div fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutGap="10px">
        <sso-input
          data-testid="input-name-field"
          class="client-input"
          [tooltipPosition]="'left'"
          [tooltip]="'Name of the client'"
          [placeholder]="'Client Name'"
          [label]="'Client Name'"
          [formControl]="clientForm?.controls?.name"
          [required]="true"
          [errorMessageMap]="errorMessages?.name"
        >
        </sso-input>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <sso-input
          data-testid="input-description-field"
          class="client-input"
          [isTextArea]="true"
          [tooltipPosition]="'left'"
          [tooltip]="'Description of the client'"
          [placeholder]="'Client description'"
          [label]="'Client description'"
          [formControl]="clientForm?.controls?.description"
          [errorMessageMap]="errorMessages?.description"
        >
        </sso-input>
      </div>
    </div>
    <div class="sso-spacer-top-half" fxLayout="row" fxLayoutGap="10px">
      <button
        data-testid="cancel-button"
        color=""
        mat-flat-button
        type="button"
        (click)="onCancel()"
      >
        Cancel
      </button>
      <button
        data-testid="confirm-button"
        color="primary"
        mat-flat-button
        [disabled]="
          clientForm?.pristine || (loading$ | async) || clientForm?.invalid
        "
      >
        {{ mode === 'update' ? 'Update' : 'Create' }}
      </button>
    </div>
  </form>
</div>

import { of } from 'rxjs';
import { catchError, first, map, mapTo, mergeMap, tap } from 'rxjs/operators';
import { RoadmapApiService } from 'ssotool-app/+roadmap/stores/roadmap';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { AlphaRequestService } from '../services/alpha-request.service';
import { AlphaRequestActions } from './alpha-request.actions';
import { AlphaRequestFacadeService } from './alpha-request.facade.service';
import { ToastService } from 'ssotool-app/shared/services/toast/toast.service';

@Injectable()
export class AlphaRequestEffects {
  constructor(
    private actions$: Actions,
    private alphaRequestService: AlphaRequestService,
    private alphaRequestFacade: AlphaRequestFacadeService,
    private roadmapService: RoadmapApiService,
    private snackbar: ToastService,
  ) {}
  getList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlphaRequestActions.getList),
      mergeMap((action) =>
        this.alphaRequestService.getList().pipe(
          map((response) =>
            AlphaRequestActions.getListSuccess({
              data: response,
              message: 'Get Alpha Request List  success!',
            }),
          ),
          catchError((error) =>
            of(
              AlphaRequestActions.getListFailure({
                data: action,
                error:
                  (error.error || {}).error || 'Get Alpha Request list error!',
                message:
                  (error.error || {}).error || 'Get Alpha Request list error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getListSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AlphaRequestActions.getListSuccess),
        tap((action) => this.snackbar.showToast(action.message, 'success')),
        mergeMap((action) =>
          this.alphaRequestFacade.getAlphaList$.pipe(first(), mapTo(action)),
        ),
      ),
    { dispatch: false },
  );

  getListFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AlphaRequestActions.getListFailure),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlphaRequestActions.updateRequest),
      mergeMap((action) =>
        this.alphaRequestService.updateRequest(action.data).pipe(
          map((response) =>
            AlphaRequestActions.updateSuccess({
              data: response,
              message: 'Update request success!',
            }),
          ),
          catchError((error) =>
            of(
              AlphaRequestActions.updateFailure({
                error: (error.error || {}).error || 'Update request error!',
                message: (error.error || {}).error || 'Update request error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  updateSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AlphaRequestActions.updateSuccess),
        tap((action) => this.snackbar.showToast(action.message, 'success')),
      ),
    { dispatch: false },
  );

  updateFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AlphaRequestActions.updateFailure),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  getRoadmaps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlphaRequestActions.getRoadmaps),
      mergeMap((action) =>
        this.roadmapService.getAllRoadmaps(action.clientId).pipe(
          map((roadmaps) =>
            AlphaRequestActions.getRoadmapsSuccess({
              data: action,
              roadmaps,
              message: 'Get Roadmaps Success!',
            }),
          ),
          catchError((error) =>
            of(
              AlphaRequestActions.getRoadmapsFailure({
                message: 'Get Roadmaps Failure!',
                error: error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getRoadmapsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AlphaRequestActions.getRoadmapsSuccess),
        tap((action) => this.snackbar.showToast(action.message, 'success')),
      ),
    { dispatch: false },
  );

  getRoadmapsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AlphaRequestActions.getRoadmapsFailure),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );
}

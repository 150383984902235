import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StickyHorizontalScrollComponent } from './sticky-horizontal-scroll.component';

@NgModule({
  declarations: [StickyHorizontalScrollComponent],
  imports: [CommonModule, FlexLayoutModule],
  exports: [StickyHorizontalScrollComponent],
})
export class StickyHorizontalScrollModule {}

import { Observable } from 'rxjs';
import { LogDetails } from 'ssotool-app/app.model';

import { CloudWatchLoggerConfig } from '@oculus/utils';

export interface RoadmapLogCredentials {
  readonly core: CloudWatchLoggerConfig;
  readonly roadmap: CloudWatchLoggerConfig;
}

export enum LogType {
  CLIENT_IMPORT = 'client_import',
  CAMPAIGN_IMPORT = 'campaign_import',
  ROADMAP_IMPORT = 'roadmap_import',
}

export type LogData = Readonly<{
  clientId: string;
  logType: LogType;
  body: Record<string, string>;
  details?: LogDetails;
  errorMessages$?: Observable<ErrorMessages>;
  loading$?: Observable<boolean>;
  hasError?: boolean;
}>;

export type ErrorMessages = Record<string, ErrorLogMessage[]>;

export type ErrorLogMessage = {
  code: number;
  location: {
    module: string;
    field: string;
    column?: string;
    row?: number;
  };
  message: string;
  type: string;
};

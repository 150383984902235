import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client';
import { Coerce } from 'ssotool-app/shared/helpers';
import { SSOToolRoutePathService } from 'ssotool-shared/services';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sso-fluid-list',
  templateUrl: './fluid-list.component.html',
  styleUrls: ['./fluid-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FluidListComponent implements OnInit {
  /** Template variables */
  entities$: Observable<any[]> = of([]);
  loading$: Observable<boolean> = of(false);
  clientId = '';
  columnDefs = [
    { name: 'name', mode: 'event' },
    { name: 'parent' },
    { name: 'quantity' },
  ];

  constructor(
    private clientFacade: ClientFacadeService,
    private router: Router,
    private routePath: SSOToolRoutePathService,
  ) {}

  ngOnInit(): void {
    this.clientFacade.selectActiveClientData$.pipe().subscribe((client) => {
      /* istanbul ignore else */
      if (client && client.clientId) {
        this.clientId = client.clientId;
        this.entities$ = this.clientFacade.selectFluids$(client.clientId).pipe(
          map((data) =>
            Coerce.getObjValues(data).map(
              ({ fluidId, name, quantityName, parentName }) => ({
                id: fluidId,
                name,
                quantity: quantityName,
                parent: parentName,
              }),
            ),
          ),
        );
        this.loading$ = this.clientFacade.dataLoading$(client.clientId);
      }
    });
  }

  onClick(event: any) {
    this.router.navigate(this.routePath.viewFluid(this.clientId, event.id));
  }
}

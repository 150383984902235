import { Component, EventEmitter, Input, Output } from '@angular/core';

type ProgressBarMode = 'indeterminate' | 'query' | 'determinate' | 'buffer';
@Component({
  selector: 'sso-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss'],
})
export class ProgressIndicatorComponent {
  @Input() mode: ProgressBarMode = 'indeterminate';
  @Input() icon: string | null = null;
  @Input() message: string = '';
  @Input() value: number = 0;
  @Input() hasProgressBar = true;
  @Input() displayCancelButton = false;
  @Input() cancelButtonMessage: string = 'Cancel';
  @Output() cancelClick = new EventEmitter<string>();

  constructor() {}

  onCancel() {
    this.cancelClick.emit();
  }
}

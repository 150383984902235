<mat-form-field
  [appearance]="appearance"
  floatLabel="auto"
  data-testid="multi-select-container"
>
  <mat-label>{{ label }}</mat-label>
  <mat-select
    #multiSelect
    data-testid="multi-select"
    [formControl]="control"
    [multiple]="multiple"
    (click)="clearSearch()"
    panelClass="custom-panel"
    [required]="required"
  >
    <mat-select-trigger>
      {{ control.value?.length ? (selected | strip : stripLabel) : '' }}
      <span
        *ngIf="multiple && control.value?.length > 1"
        class="additional-selection"
      >
        (+{{ control.value.length - 1 }}
        {{ control.value?.length === 2 ? 'other' : 'others' }})
      </span>
    </mat-select-trigger>
    <div class="panel-header" fxLayout="column">
      <h3 color="primary">{{ label }}</h3>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center none">
        <sso-input
          *ngIf="enableSearch"
          [formControl]="searchControl"
          [prefixIcon]="searchIconPrefix && 'search'"
          [suffixIcon]="!searchIconPrefix && 'search'"
          [label]="searchLabel"
        ></sso-input>
        <button
          type="button"
          mat-icon-button
          color="primary"
          (click)="clearFilter()"
        >
          <mat-icon matTooltip="Clear filter">close</mat-icon>
        </button>
      </div>
    </div>
    <cdk-virtual-scroll-viewport class="panel-content" itemSize="35">
      <ng-container *cdkVirtualFor="let option of filteredOptions">
        <mat-optgroup *ngIf="grouped; else string" [label]="option.name">
          <mat-option *ngFor="let val of option.values" [value]="val">{{
            val | strip : stripLabel
          }}</mat-option>
        </mat-optgroup>
        <ng-template #string>
          <mat-option [value]="option">{{
            option | strip : stripLabel
          }}</mat-option>
        </ng-template>
      </ng-container>
    </cdk-virtual-scroll-viewport>
    <!-- The code below is needed so that mat option select will work for cdkVirtual -->
    <ng-container *ngFor="let option of filteredOptions" class="panel-content">
      <mat-optgroup *ngIf="grouped; else string" [label]="option.name" hidden>
        <mat-option
          *ngFor="let val of option.values"
          [value]="val"
          hidden
          [attr.data-testid]="val"
          >{{ val | strip : stripLabel }}</mat-option
        >
      </mat-optgroup>
      <ng-template #string>
        <mat-option [value]="option" hidden>{{
          option | strip : stripLabel
        }}</mat-option>
      </ng-template>
    </ng-container>
  </mat-select>
</mat-form-field>

import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { BinDataApiService } from './bindata-api.service';
import { BinDataActions } from './bindata.actions';
import { BinDataEntityStore } from './bindata.reducer';

@Injectable()
export class BinDataEffects {
  downloadAllBindata$ = createEffect(this.downloadBindataSideEffect.bind(this));
  downloadAllBindataOk$ = createEffect(
    this.downloadBindataOKSideEffect.bind(this),
    { dispatch: false },
  );
  downloadAllBindataNOK$ = createEffect(
    this.downloadBindataNokSideEffect.bind(this),
    { dispatch: false },
  );

  constructor(private act: Actions, private api: BinDataApiService) {}

  getAPIName(): string {
    return BinDataEntityStore.featureName;
  }

  downloadBindataSideEffect() {
    const actions = BinDataActions(this.getAPIName());
    return this.act.pipe(
      ofType(actions.downloadAllBindataAction),
      mergeMap((action) => this.api.downloadAllBindata(action.clientId)),
      map(() => actions.downloadAllBindataOKAction()),
      catchError((err) => of(actions.downloadAllBindataNOKAction())),
    );
  }

  downloadBindataOKSideEffect() {
    const actions = BinDataActions(this.getAPIName());
    return this.act.pipe(ofType(actions.downloadAllBindataOKAction));
  }

  downloadBindataNokSideEffect() {
    const actions = BinDataActions(this.getAPIName());
    return this.act.pipe(ofType(actions.downloadAllBindataNOKAction));
  }
}

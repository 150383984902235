import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ProfileDetailsForm } from 'ssotool-app/shared/modules/profiles-form/profiles-form.model';

import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';

import { ProfileLocalStorageService } from './profile-local-storage.service';
import { ProfileActions } from './profile.actions';
import { Profile } from './profile.model';
import { ProfileState } from './profile.reducer';
import { loaded, loading } from './profile.selector';

@Injectable()
export class ProfileFacadeService {
  constructor(
    private localStorageService: ProfileLocalStorageService,
    private store: Store<ProfileState>,
    private actionSubject$: ActionsSubject,
  ) {}

  loading$ = this.store.pipe(select(loading));
  loaded$ = this.store.pipe(select(loaded));

  // DISPATCH

  /**
   * Loads a single profile to local storage, use getProfilesById to get the data
   * @param clientId - client uuid
   * @param profileId - campaign id
   * @param geoId - site id
   */
  getProfile(
    clientId: string,
    profileId: string,
    geoId: string,
    force: boolean = false,
  ) {
    this.store.dispatch(
      ProfileActions.getProfile({ clientId, profileId, geoId, force }),
    );
  }

  getProfiles(clientId: string) {
    this.store.dispatch(ProfileActions.getProfiles({ clientId }));
  }

  upsertProfile(
    clientId: string,
    profileId: string,
    geoId: string,
    data: ProfileDetailsForm,
  ) {
    this.store.dispatch(
      ProfileActions.upsertProfile({
        data,
        clientId,
        profileId,
        geoId,
      }),
    );

    return this.actionSubject$.pipe(
      filter(
        (action) => action.type === ProfileActions.upsertProfileSuccess.type,
      ),
    );
  }

  deleteProfile(clientId: string, profileId: string, geoId: string) {
    this.store.dispatch(
      ProfileActions.deleteProfile({
        clientId,
        profileId,
        geoId,
      }),
    );

    return this.actionSubject$.pipe(
      filter(
        (action) => action.type === ProfileActions.deleteProfileSuccess.type,
      ),
    );
  }

  updateModifyStatus(
    code: number,
    clientId: string,
    profileId: string,
    geoId?: string,
  ) {
    this.store.dispatch(
      ProfileActions.updateModifyStatus({
        code,
        clientId,
        profileId,
        geoId,
      }),
    );
  }

  // LOCAL STORAGE

  /**
   * Retrieves the profiles associated with the id, use **profileId_siteId** for single site
   * @param profileId - campaign id or \<profileId_siteId\>
   * @returns all site profiles related to the profileId
   */
  getProfilesById(profileId: string): Observable<Profile[]> {
    return this.localStorageService.getProfilesByIdStream(profileId);
  }

  /**
   * Deletes a profile document in local storage
   * @param profileId - campaign id or \<profileId_siteId\>
   */
  deleteProfileFromStorage(profileId: string) {
    this.localStorageService.deleteProfile(profileId);
  }

  clearLocalStorage() {
    this.localStorageService.clear();
  }
}

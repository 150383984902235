import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';

import {
  SSOSpinnerModule,
  ViewDataSetModule,
  YearlyViewModule,
} from './component';
import { DialogModule } from './component/dialog/dialog.module';
import { TabbedAreaChartModule } from './component/tabbed-area-chart';
import { TabbedBarChartModule } from './component/tabbed-bar-chart';
import {
  AutoFocusModule,
  SsoCommifyModule,
  SsoTooltipModule,
} from './directives';
import { ExecStatusChecker } from './helpers';
import {
  FileUploadModule,
  FiltersDialogModule,
  FiltersModule,
  FluidsArrayModule,
  FluidsMenuButtonModule,
  FluidsToggleGroupModule,
  GanttModule,
  InputModule,
  KPIWidgetModule,
  LineChartModule,
  MultiSelectModule,
  PieChartModule,
  ProgressSpinnerModule,
  RangeInputModule,
  SankeyChartModule,
  SearchInputModule,
  SelectModule,
  SparklineModule,
  StackedBarModule,
  TooltipAnchorModule,
  WaterfallModule,
  YearlyInputDialogModule,
  YearlyInputModule,
} from './modules';
import { PipesModule } from './pipes';
import { DateFilterService } from './services/date-filter.service';
import { SSOToolRoutePathService } from './services/route-path.service';

const sharedModules = [
  GanttModule,
  FileUploadModule,
  SSOSpinnerModule,
  KPIWidgetModule,
  PieChartModule,
  WaterfallModule,
  SparklineModule,
  YearlyInputModule,
  YearlyInputDialogModule,
  RangeInputModule,
  LineChartModule,
  SelectModule,
  InputModule,
  FluidsToggleGroupModule,
  FluidsMenuButtonModule,
  FluidsArrayModule,
  SsoTooltipModule,
  SsoCommifyModule,
  AutoFocusModule,
  ProgressSpinnerModule,
  YearlyViewModule,
  // CustomWidthBarChartModule,
  PipesModule,
  FiltersModule,
  FiltersDialogModule,
  MultiSelectModule,
  StackedBarModule,
  SearchInputModule,
  TooltipAnchorModule,
  SankeyChartModule,
  TabbedBarChartModule,
  ViewDataSetModule,
  TabbedAreaChartModule,
  DialogModule,
];
@NgModule({
  imports: [...sharedModules, MatTabsModule],
  exports: [...sharedModules],
  declarations: [],
  providers: [DateFilterService, SSOToolRoutePathService, ExecStatusChecker],
})
export class SharedModule {}

import { BehaviorSubject, Subscription, timer } from 'rxjs';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { loaderSizes } from './progress-spinner.reference';

@Component({
  selector: 'sso-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressSpinnerComponent implements OnInit, OnDestroy {
  @Input() mode: 'determinate' | 'indeterminate' = 'determinate';
  @Input() color = 'primary';
  @Input() set value(value: number) {
    this.value$.next(value ? value : 0);
    this.checkIfDone(value);
  }
  @Input() text = '';
  @Input() set size(size: 'x2Small' | 'xSmall' | 'small' | 'medium' | 'large') {
    this.sizeClass = size;
    this.loaderSize = loaderSizes[size];
    this.initializeContentSize();
  }
  @Input() set duration(duration: number) {
    this._duration = duration * 10;
  }

  @Output() done = new EventEmitter<any>();

  value$ = new BehaviorSubject<number>(0);
  _duration: number;
  contentSize: number;
  contentTop: number;
  loaderSize = loaderSizes.medium;
  subscription: Subscription;
  sizeClass = 'medium';

  ngOnInit() {
    this.initializeContentSize();
    this.initializeTimer();
  }

  initializeContentSize() {
    const { diameter, strokeWidth } = this.loaderSize;

    this.contentSize = diameter - 4 * strokeWidth;
    this.contentTop = 2 * strokeWidth - diameter;
  }

  initializeTimer() {
    /* istanbul ignore else */
    if (this._duration) {
      this.subscription = timer(500, 100).subscribe((val) => {
        this.value = (val / this._duration) * 100;
      });
    }
  }

  ngOnDestroy() {
    /* istanbul ignore else */
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  checkIfDone(value) {
    /* istanbul ignore else */
    if (value >= 150) {
      this.value$.next(100);
      this.done.emit();
      /* istanbul ignore else */
      if (this._duration && this.subscription) {
        this.subscription.unsubscribe();
      }
    }
  }
}

<div
  fxLayout="column"
  fxLayoutGap="10px"
  class="profiles-form-container"
  data-testid="profiles-form-container"
>
  <div
    [formGroup]="profileForm"
    fxLayout="column"
    fxLayoutGap="10px"
    class="full-width"
    *ngrxLet="mode$ as mode"
  >
    <div class="multi-select-container">
      <sso-multi-select
        data-testid="select-sites"
        [label]="'Profiles.labels.viewBySite' | translate"
        [options]="siteOptions$ | ngrxPush"
        [formControl]="siteNameControl"
        color="primary"
        [multiple]="false"
      ></sso-multi-select>
    </div>
    <div class="contents-loader" *ngIf="loading$ | async; else content">
      <ngx-skeleton-loader
        count="1"
        animation="progress"
        [theme]="{ height: '400px', width: '100%' }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="6"
        animation="progress"
        [theme]="{ height: '40px', width: '30%', 'margin-right': '5px' }"
      ></ngx-skeleton-loader>
    </div>
    <ng-template #content>
      <!-- WITH PROFILES -->
      <div *ngIf="mode !== 'empty'; else withoutProfiles">
        <div
          class="area-chart-container full-width"
          fxLayout="column"
          fxLayoutAlign="start stretch"
        >
          <div fxLayout="column" fxLayoutAlign="start center">
            <sso-area-chart
              [data]="values || {}"
              unit="kWh/kWi"
              [tickformat]="tickformat"
              data-testid="profile-chart-id"
            ></sso-area-chart>
          </div>
          <div class="timeseries-input-container">
            <oculus-timeseries-input
              formControlName="editedValues"
              [granularity]="granularity"
              [isReadOnly]="isReadonly(mode)"
              data-testid="timeseries-input"
            ></oculus-timeseries-input>
          </div>
        </div>
      </div>

      <!-- WITHOUT PROFILES -->
      <ng-template #withoutProfiles>
        <div
          class="full-width"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <div
            class="full-width no-data"
            data-testid="noProfiles"
            fxLayout="column"
            fxLayoutAlign="center stretch"
          >
            {{ 'Profiles.messages.noProfiles' | translate }}
          </div>
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>

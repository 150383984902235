import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { SsoTooltipModule } from '../../directives/tooltip';
import { InputModule } from '../input/input.module';
import { MoreActionsModule } from '../more-actions/more-actions.module';
import { GanttBarModule } from './gantt-bar/gantt-bar.module';
import { GanttDialogComponent } from './gantt-dialog';
import { GanttComponent } from './gantt.component';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { GanttNameModule } from 'ssotool-shared/modules/gantt/gantt-name';
import { LetModule, PushPipe } from '@ngrx/component';

import { WindowVirtualScrollDirective } from './window-virtual-scroll/window-virtual-scroll.directive';
import { BatchingService } from 'ssotool-app/shared/services/batching';
import { StickyHorizontalScrollModule } from 'ssotool-app/shared/component';

const components = [
  GanttComponent,
  GanttDialogComponent,
  WindowVirtualScrollDirective,
];
@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TranslateModule,
    MatListModule,
    MatCardModule,
    MatTooltipModule,
    SsoTooltipModule,
    MatBadgeModule,
    InputModule,
    MatDividerModule,
    GanttBarModule,
    GanttNameModule,
    MatMenuModule,
    MoreActionsModule,
    MatCheckboxModule,
    ScrollingModule,
    LetModule,
    PushPipe,
    StickyHorizontalScrollModule,
  ],
  providers: [BatchingService],
  exports: [...components],
})
export class GanttModule {}

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
  ChipListModule,
  FiltersModule,
  SearchInputModule,
  TitleBarModule,
} from 'ssotool-shared/modules';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CampaignTableComponent } from './campaign-table.component';

@NgModule({
  declarations: [CampaignTableComponent],
  exports: [CampaignTableComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    FiltersModule,
    MatTableModule,
    MatCheckboxModule,
    MatSortModule,
    MatPaginatorModule,
    MatChipsModule,
    MatMenuModule,
    TitleBarModule,
    ChipListModule,
    SearchInputModule,
    MatProgressBarModule,
    NgxSkeletonLoaderModule,
  ],
})
export class CampaignTableModule {}

import {
  EXECUTION_STATUS,
  FE_EXECUTION_STATUS,
  IMPORT_STATUS,
} from 'ssotool-app/app.references';

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ExecStatusChecker {
  DISABLE_ACTIVITY = [
    FE_EXECUTION_STATUS.NEW,
    FE_EXECUTION_STATUS.PREPARING,
    FE_EXECUTION_STATUS.PROCESSING,
    FE_EXECUTION_STATUS.RETRYING,
    EXECUTION_STATUS.NOT_READY,
    EXECUTION_STATUS.PREPARING,
    EXECUTION_STATUS.QUEUED,
    EXECUTION_STATUS.READY,
    EXECUTION_STATUS.RUNNING,
    EXECUTION_STATUS.RETRYING,
  ];

  ENABLE_STOP_COMPUTATION = [
    FE_EXECUTION_STATUS.PREPARING,
    FE_EXECUTION_STATUS.PROCESSING,
    FE_EXECUTION_STATUS.RETRYING,
    EXECUTION_STATUS.PREPARING,
    EXECUTION_STATUS.QUEUED,
    EXECUTION_STATUS.RUNNING,
    EXECUTION_STATUS.RETRYING,
  ];

  DISABLE_INPUT_DOWNLOAD = [FE_EXECUTION_STATUS.PREPARING];

  DISABLE_OUTPUT_DOWNLOAD = [
    FE_EXECUTION_STATUS.PREPARING,
    FE_EXECUTION_STATUS.DRAFT,
    FE_EXECUTION_STATUS.PROCESSING,
    FE_EXECUTION_STATUS.RETRYING,
    FE_EXECUTION_STATUS.POST_PROCESSING,
  ];

  DISABLE_COMPUTE_ROADMAP = [
    FE_EXECUTION_STATUS.NEW,
    FE_EXECUTION_STATUS.PREPARING,
    FE_EXECUTION_STATUS.PROCESSING,
    FE_EXECUTION_STATUS.RETRYING,
    FE_EXECUTION_STATUS.POST_PROCESSING,
  ];

  DISABLE_VIEW_LOG = [
    FE_EXECUTION_STATUS.NEW,
    FE_EXECUTION_STATUS.DRAFT,
    FE_EXECUTION_STATUS.PREPARING,
    EXECUTION_STATUS.NOT_READY,
    EXECUTION_STATUS.PREPARING,
  ];

  DISABLE_IMPORT = [
    IMPORT_STATUS.NEW,
    IMPORT_STATUS.PROCESSING,
    IMPORT_STATUS.REQUESTED,
    IMPORT_STATUS.READY,
    IMPORT_STATUS.TDB_INVOKING,
  ];

  SUCCESSFUL_STATUSES = [
    IMPORT_STATUS.SUCCESS,
    EXECUTION_STATUS.SUCCESS,
    FE_EXECUTION_STATUS.SUCCESS,
  ];

  FAILED_STATUSES = [
    IMPORT_STATUS.ERROR,
    EXECUTION_STATUS.FAILED,
    EXECUTION_STATUS.CANCELLED,
    FE_EXECUTION_STATUS.FAILED,
    FE_EXECUTION_STATUS.CANCELLED,
  ];

  IMPORT_FAILED = [IMPORT_STATUS.ERROR];

  OUTDATED_STATUSES = [
    FE_EXECUTION_STATUS.OUTDATED,
    FE_EXECUTION_STATUS.SUCCESS,
    FE_EXECUTION_STATUS.DRAFT,
    FE_EXECUTION_STATUS.FAILED,
    FE_EXECUTION_STATUS.CANCELLED,
  ];

  DUPLICABLE_STATUSES = [
    FE_EXECUTION_STATUS.DRAFT,
    FE_EXECUTION_STATUS.POST_PROCESSING,
    FE_EXECUTION_STATUS.SUCCESS,
    FE_EXECUTION_STATUS.FAILED,
    FE_EXECUTION_STATUS.CANCELLED,
    FE_EXECUTION_STATUS.OUTDATED,
  ];

  VARIATION_COMPARISON_STATUSES = [
    EXECUTION_STATUS.SUCCESS,
    FE_EXECUTION_STATUS.SUCCESS,
    FE_EXECUTION_STATUS.OUTDATED,
  ];

  STARTING_STATUSES = [
    IMPORT_STATUS.NEW,
    IMPORT_STATUS.REQUESTED,
    IMPORT_STATUS.READY,
  ];

  /**
   * Check the status for disabled activities.
   * @param status status to check.
   * @returns flag if execution is ongoing.
   */
  check(status: string): boolean {
    return this.DISABLE_ACTIVITY.includes(status);
  }

  /**
   * Transform backend status to frontend displayed statuses.
   * @param status status to map.
   * @param outdated flag if the data is outdated.
   * @returns mapped status.
   */
  mapToFrontend(status, outdated = false) {
    if (!status && outdated) {
      return FE_EXECUTION_STATUS.OUTDATED;
    }

    switch (status) {
      case EXECUTION_STATUS.NOT_READY:
        return FE_EXECUTION_STATUS.NEW;
      case EXECUTION_STATUS.SUCCESS:
        return outdated
          ? FE_EXECUTION_STATUS.OUTDATED
          : FE_EXECUTION_STATUS.SUCCESS;
      case EXECUTION_STATUS.FAILED:
        return outdated
          ? FE_EXECUTION_STATUS.OUTDATED
          : FE_EXECUTION_STATUS.FAILED;
      case EXECUTION_STATUS.PREPARING:
      case EXECUTION_STATUS.QUEUED:
        return FE_EXECUTION_STATUS.PREPARING;
      case EXECUTION_STATUS.CANCELLED:
        return FE_EXECUTION_STATUS.CANCELLED;
      case EXECUTION_STATUS.POST_PROCESSING:
        return FE_EXECUTION_STATUS.POST_PROCESSING;
      case EXECUTION_STATUS.RETRYING:
        return FE_EXECUTION_STATUS.RETRYING;
      default:
        return FE_EXECUTION_STATUS.PROCESSING;
    }
  }

  /**
   * Check if the input files can be downloaded.
   * @param status status to check.
   * @returns flag if logs can be viewed.
   */
  checkViewLog(status: string): boolean {
    return this.DISABLE_VIEW_LOG.includes(status);
  }

  /**
   * Check if the input files can be downloaded.
   * @param status status to check.
   * @returns flag if input files can be downloaded.
   */
  checkInputDownload(status: string): boolean {
    return this.DISABLE_INPUT_DOWNLOAD.includes(status);
  }

  /**
   * Check if the output files can be downloaded.
   */
  checkOutputDownload(status: string): boolean {
    return this.DISABLE_OUTPUT_DOWNLOAD.includes(status);
  }

  /**
   * Checks if the roadmap can be computed based on the given status.
   * @param status FE equivalent status of the roadmap run.
   */
  canRoadmapBeComputed(status: string): boolean {
    return !this.DISABLE_COMPUTE_ROADMAP.includes(status);
  }

  /**
   * Checks if the roadmap can be duplicated based on the given status.
   * @param status FE equivalent status of the roadmap run.
   */
  canRoadmapBeDuplicated(status: string): boolean {
    return this.DUPLICABLE_STATUSES.includes(status);
  }

  /**
   * Checks if the roadmap variations can be compared based on the given status.
   * @param status FE equivalent status of the roadmap run.
   */
  canVariationsBeCompared(status: string): boolean {
    return this.VARIATION_COMPARISON_STATUSES.includes(status);
  }
  /**
   * Check if the current process has an ongoing import.
   * @param status status to check.
   * @returns flag if import is ongoing.
   */
  checkImport(status: string): boolean {
    return this.DISABLE_IMPORT.includes(status);
  }

  /**
   * Check if the current process is computing
   * @param status status to check.
   * @returns flag if roadmap is computing.
   */
  checkStopComputation(status: string): boolean {
    return !this.ENABLE_STOP_COMPUTATION.includes(status);
  }

  /**
   * Check if the current process is successful.
   * @param status status to check.
   * @returns flag if process is successful.
   */
  isSuccessful(status: string): boolean {
    return this.SUCCESSFUL_STATUSES.includes(status);
  }

  /**
   * Check if the current process is failed.
   * @param status status to check.
   * @returns flag if process is failed.
   */
  isError(status: string): boolean {
    return this.FAILED_STATUSES.includes(status);
  }

  /**
   * Check if the current process is outdated.
   * @param status status to check.
   * @returns flag if process is outdated.
   */
  isComputationPossible(status: string): boolean {
    return this.OUTDATED_STATUSES.includes(status);
  }

  isStartingState(status: string): boolean {
    return this.STARTING_STATUSES.includes(status);
  }
}

import { DialogData } from '../../component/dialog';

export enum GanttLockStatus {
  LOCK = 'lock',
  OPEN_LOCK = 'openLock',
  PARTIAL = 'partial',
}

export type GanttViewMode = 'day' | 'month' | 'year';
export interface GanttConfig {
  taskName?: string;
  startDate?: string;
  endDate?: string;
  headerHeight?: number;
  viewMode?: GanttViewMode;
  dateFormat?: string;
  emptyChartMessage?: string;
  startLimit?: string;
  endLimit?: string;
  defaultStartLimit?: string;
  defaultEndLimit?: string;
  limitColor?: string;
  updateVariable?: string;
  validStartLimit?: string;
  validEndLimit?: string;
  updateFn?: (...args) => Record<string, any>;
  canPushLimit?: boolean;
}

export interface GanttItem {
  startDate?: string;
  endDate?: string;
  startDates?: string[];
  endDates?: string[];
  name?: string;
  id?: string;
  isShown?: boolean;
  isSelected?: boolean;
  isResultsOnly?: boolean;
  isFiltered?: boolean;
  color?: string;
  startLimit?: string;
  endLimit?: string;
  isLock?: GanttLockStatus;
  leftChange?: number;
  widthChange?: number;
  isUpdated?: boolean;
  isToBeDeleted?: boolean;
  type?: string;
  isDraggable?: boolean;
}

export type GanttNameClickEvent = Readonly<{
  item: GanttItem;
  isDefault: boolean;
}>;

export interface GanttTooltip {
  id: string;
  name: string;
  startDates: string[];
  endDates: string[];
  startLimit?: string;
  endLimit?: string;
}

export interface GanttDialogData extends DialogData {
  inputStartLimit: string;
  inputEndLimit: string;
  name: string;
  label: string;
  defaultStart?: string;
  defaultEnd?: string;
  currentStart: string;
  currentEnd: string;
  validStartLimit?: string;
  validEndLimit?: string;
}

export type GanttBuiltInActions = 'editRanges' | 'deleteItem';
export interface GanttItemAction {
  readonly id?: GanttBuiltInActions;
  readonly label?: string;
  readonly actionFn?: (...args) => Record<string, any>;
  readonly icon?: string;
  readonly disabled?: boolean;
}

export type PreSelectedType = string[];

export interface GanttMultiSelectionChanges {
  ids?: string[];
  limitLeftChange?: number;
  limitWidthChange?: number;
}

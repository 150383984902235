import { BehaviorSubject } from 'rxjs';

import { Component, Input } from '@angular/core';
import { AreaData } from '@oculus/components/area-diagram';

type AreaType = AreaData<Record<string, string>, Date>;
@Component({
  selector: 'sso-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss'],
})
export class AreaChartComponent {
  private chartData = new BehaviorSubject<AreaType>(null);
  chartData$ = this.chartData.asObservable();
  @Input() set data(data: Record<string, string>) {
    this.chartData.next(this.mapToAreaModel(data));
  }
  @Input() unit: string = '';
  @Input() tickformat: string = '';
  @Input() width: null | number = null;

  hoverformat =
    '<br>%{x}<br><br>Value: <b>%{y:,.4f} %{yaxis.title.text}<b><extra></extra>';
  constructor() {}

  mapToAreaModel(data: Record<string, string>): AreaType {
    return {
      traces: [
        {
          points: Object.entries(data).map(([key, value]) => ({
            name: new Date(key),
            value: Number(value),
          })),
        },
      ],
    };
  }
}

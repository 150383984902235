import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SSOToolRoutePathService } from 'ssotool-app/shared';

const ERROR_MAP = {
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Page Not Found',
  500: 'Internal Server Error',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
};

const DEFAULT_STATUS_CODE = 404;
const DEFAULT_ERROR_MESSAGE = ERROR_MAP[DEFAULT_STATUS_CODE];

@Component({
  selector: 'sso-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  constructor(
    private router: Router,
    private routePath: SSOToolRoutePathService,
    private activeRoute: ActivatedRoute,
  ) {}

  private _statusCode = new BehaviorSubject<number>(DEFAULT_STATUS_CODE);
  private _errorMessage = new BehaviorSubject<string>(DEFAULT_ERROR_MESSAGE);

  statusCode$ = this._statusCode.asObservable();
  errorMessage$ = this._errorMessage.asObservable();

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((params) => {
      const { code } = params;

      this.updateCode(code);
    });
  }

  goBack(): void {
    this.router.navigate(this.routePath.clients());
  }

  private updateCode(code: number) {
    if (!!code) {
      this._statusCode.next(code);

      let errorMessage = ERROR_MAP[code];
      this.updateMessage(errorMessage);
    }
  }

  private updateMessage(message: string) {
    if (!!message) {
      this._errorMessage.next(message);
    }
  }
}

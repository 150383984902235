import { createReducer, on } from '@ngrx/store';

import { ChartActions } from './charts.actions';

export interface ChartState<T = any> {
  colorMap: { [label: string]: string };
}

export const INITIAL_CHART_STATE: ChartState = {
  colorMap: {},
};

function reduceSetColorForLabelGroupedAction(
  currentState: ChartState,
  payload: { data: { hexCode: string; label: string }[] },
): ChartState {
  const newColorMap = payload.data.reduce((acc, item) => {
    acc[item.label] = item.hexCode;
    return acc;
  }, {});

  return { ...currentState, colorMap: newColorMap };
}

function reduceSetColorForLabelAction(
  currentState: ChartState,
  payload: { label: string; hexValue: string },
): ChartState {
  const newColorMap = {
    ...currentState.colorMap,
    [payload.label]: payload.hexValue,
  };
  return { ...currentState, colorMap: newColorMap };
}

function reduceResetColorMapAction(currentState: ChartState): ChartState {
  return { ...currentState, colorMap: {} };
}

export const ChartStore = {
  featureName: 'Chart',
  reducer: createReducer(
    INITIAL_CHART_STATE,
    on(
      ChartActions().setColorForLabelGrouped,
      reduceSetColorForLabelGroupedAction,
    ),
    on(ChartActions().setColorForLabel, reduceSetColorForLabelAction),
    on(ChartActions().resetColorMap, reduceResetColorMapAction),
  ),
};

export const ChartStateReducerFunctions = {
  reduceSetColorForLabelGroupedAction,
  reduceSetColorForLabelAction,
  reduceResetColorMapAction,
};

import { BehaviorSubject } from 'rxjs';

import { Component, Input } from '@angular/core';
import { ChartLegends } from '@oculus/components/chart-legends';

import { SankeyMetadata } from './sankey-chart.model';

@Component({
  selector: 'sso-sankey-chart',
  templateUrl: './sankey-chart.component.html',
  styleUrls: ['./sankey-chart.component.scss'],
})
export class SankeyChartComponent {
  private sankeyData = new BehaviorSubject<SankeyMetadata>(null);
  sankeyData$ = this.sankeyData.asObservable();

  private legendData = new BehaviorSubject<ChartLegends>([]);
  legendData$ = this.legendData.asObservable();

  @Input() set metadata(data: SankeyMetadata) {
    if (data && data.flows.length) {
      this.sankeyData.next(data);
      this.legendData.next(
        data.flows
          .map((flow) => ({
            color: flow.color,
            label: flow.name,
          }))
          .filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.label === value.label),
          ),
      );
    } else {
      this.sankeyData.next(null);
    }
  }
}

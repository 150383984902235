import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BreadcrumbLevel } from './breadcrumb.model';

@Component({
  selector: 'sso-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent<T> {
  @Input() levels: BreadcrumbLevel<T>[] = [];
  @Output() crumbClick = new EventEmitter<BreadcrumbLevel<T>>();
  constructor() {}

  onBreadcrumbClick(crumb: BreadcrumbLevel<T>): void {
    this.crumbClick.emit(crumb);
  }

  getCrumbTestID(crumb: BreadcrumbLevel<T>, index: number): string {
    return `crumb-${crumb.name}-${index}`;
  }
}

import { Injectable } from '@angular/core';

@Injectable()
export class BatchingService<T> {
  private items: T[] = [];

  private pageIndex: number = -1;
  private start: number = -1;
  private end: number = 0;
  private batchSize: number = 1;

  private getItems(): T[] {
    this.start = this.coerceNegativeNumber(this.pageIndex * this.batchSize);
    this.end = this.coerceNegativeNumber(this.start + this.batchSize);
    return this.items.slice(this.start, this.end);
  }

  private coerceNegativeNumber(from: number): number {
    return Math.max(from, 0);
  }

  reset(): void {
    this.start = -1;
    this.end = this.batchSize;
    this.pageIndex = -1;
  }

  set(items: T[], batchSize: number = 1): void {
    this.items = items;
    this.batchSize = batchSize;
    this.reset();
  }

  previous(): T[] {
    this.pageIndex--;
    return this.getItems();
  }

  current(): T[] {
    return this.getItems();
  }

  next(): T[] {
    this.pageIndex++;
    return this.getItems();
  }
}

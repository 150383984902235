import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of, skipWhile, map } from 'rxjs';
import { ClientFacadeService } from 'ssotool-app/+client/store/client.facade.service';
import { Coerce } from 'ssotool-app/shared/helpers/coerce.utils';

@UntilDestroy()
@Component({
  selector: 'sso-archetype-list',
  templateUrl: './archetype-list.component.html',
  styleUrls: ['./archetype-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArchetypeListComponent implements OnInit {
  entities$: Observable<any> = of([]);
  loading$: Observable<boolean> = of(false);
  clientId = '';

  columnDefs = [{ name: 'name', mode: 'event' }, { name: 'parent' }];

  constructor(private clientFacade: ClientFacadeService) {}

  ngOnInit(): void {
    this.clientFacade.selectActiveClientData$
      .pipe(untilDestroyed(this))
      .subscribe((client) => {
        /* istanbul ignore else */
        if (client && client.clientId) {
          this.clientId = client.clientId;
          this.entities$ = this.clientFacade
            .selectArchetypes$(client.clientId)
            .pipe(
              skipWhile((data) => !data),
              map((data) =>
                Coerce.getObjValues(data).map(
                  ({
                    companyEntityId,
                    name,
                    companyType,
                    parentName,
                    group,
                  }) => ({
                    id: companyEntityId,
                    name,
                    parent: parentName,
                    type: companyType,
                    group,
                  }),
                ),
              ),
            );
          this.loading$ = this.clientFacade.dataLoading$(client.clientId);
        }
      });
  }
}

import { groupBy } from 'lodash';
import { Coerce } from 'ssotool-app/shared/helpers';
import { HID_KEYS } from 'ssotool-client';

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PortfolioEntityState } from '../portfolio.reducer';
import { CompareData } from './compare.model';
import { COMPARE_FEATURE_NAME, TEMPORARY_ID } from './compare.reducer';

export const compareFeatureState =
  createFeatureSelector<PortfolioEntityState>(COMPARE_FEATURE_NAME);

/**
 * Select the comparison result of the current comparison data.
 * @returns Selector of the dataMap of the specific compare.
 */
export const selectComparisonResult = () =>
  createSelector(
    compareFeatureState,
    (state) => state.dataMap[TEMPORARY_ID] as CompareData,
  );

/**
 * Select the comparison result download progress.
 * @returns Selector of the download progress.
 */
export const selectProgress = () =>
  createSelector(selectComparisonResult(), (data) => data?.progress);

/**
 * Select the comparison result reference roadmap id.
 * @returns Selector of the reference roadmap id.
 */
export const selectReferenceRoadmapId = () =>
  createSelector(selectComparisonResult(), (data) => data?.refRoadmapId);

/**
 * Select the comparison result baseline id.
 * @returns Selector of the baseline id.
 */
export const selectBaselineId = () =>
  createSelector(selectComparisonResult(), (data) => data?.baselineId);

/**
 * Select the comparison result roadmap name mapper.
 * @returns Selector of the roadmap name mapper.
 */
export const selectNameMapping = () =>
  createSelector(selectComparisonResult(), (data) => data?.roadmapNameMapping);

/**
 * Select the comparison result kpi table data.
 * @returns Selector of the kpi table data.
 */
export const selectCompareKpis = () =>
  createSelector(selectComparisonResult(), (data) => data?.kpis);

/**
 * Filter the data if the datum is on the same hierarchy
 * @param data - data to be filtered
 * @param key - reference key on the datum
 * @param hids - list of hids as filter values
 * @returns filtered data by hid
 */
export const filterValueByHid = (data: any[], key: string, hids: string[]) => {
  return hids.length
    ? data.filter((datum) => hids.some((hid) => datum[key].startsWith(hid)))
    : data;
};

/**
 * Collects the hid from the selected filters
 * @param filters - the selected filters
 * @param hierarchy - object that includes the list of hids per hierarchy
 * @param groups - object that includes groups name and hids
 * @returns the collected hids based on the selected filters
 */
export const collectHIds = (
  filters: string[],
  hierarchy: any,
  groups: any,
): string[] => {
  const hierMap = {};
  let groupHids = [];
  Coerce.getObjKeys(hierarchy).map((hid) => (hierMap[hierarchy[hid]] = hid));
  const fromHierData: string[] = filters
    .map((filter) => hierMap[filter])
    .filter((filter) => !!filter);
  const fromGrpData: string[][] = filters
    .map((filter) => groups[filter])
    .filter((filter) => !!filter);

  fromGrpData.map((hids) => (groupHids = groupHids.concat(hids)));
  return fromHierData.concat(groupHids);
};

export const filterByValues = (data: any[], key: string, values: string[]) => {
  if (values.length) {
    return data.filter((o) => values.indexOf(o[key]) > -1);
  } else {
    return data;
  }
};

/**
 * Filter each datum if the key should be retained
 * @param data - data to be filtered
 * @param datumField - reference key on the datum
 * @param retainKeys - list of keys as filter values
 * @param yearIndex - list of years.
 * @returns filtered data by keys
 */
export const filterField = (
  data: any[],
  datumField: string,
  retainKeys: string[],
  yearIndex: string[],
) => {
  if (retainKeys.length) {
    const yrIdx: number[] = retainKeys.map((year) =>
      yearIndex.findIndex((value) => value === year),
    );
    return data.reduce((filteredData, datum) => {
      const replacementField = [];

      yrIdx.forEach((idx) => replacementField.push(datum[datumField][idx]));

      const datumCopy = Object.assign({}, datum);
      datumCopy[datumField] = replacementField;

      return filteredData.concat(datumCopy);
    }, []);
  } else {
    return data;
  }
};

/**
 * Filters the raw daw.
 * @param rawData - raw data to be filtered.
 * @param filters - selected filters.
 * @param hierarchicalData - hierarchical object with its hid.
 * @param groupData - group with the list of hids.
 * @param yrIndex - years data of the curve.
 */
const applyFilters = (
  rawData: any[],
  filters: { [key: string]: string[] },
  hierarchicalData: { [entityType: string]: { [hid: string]: string } },
  groupData: { [entityType: string]: { [groupName: string]: string[] } },
  yrIndex: string[],
) => {
  let filteredData = rawData;
  Coerce.getObjKeys(filters).forEach((filterKey) => {
    switch (filterKey) {
      case 'years':
        filteredData = filterField(
          filteredData,
          'values',
          filters[filterKey],
          yrIndex,
        );
        break;
      case 'geography':
      case 'entity':
        const hIds = collectHIds(
          filters[filterKey],
          hierarchicalData[filterKey],
          groupData[filterKey],
        );
        filteredData = filterValueByHid(
          filteredData,
          HID_KEYS[filterKey],
          hIds,
        );
        break;
      case undefined:
        break;
      default:
        // process and fluid
        filteredData = filterByValues(
          filteredData,
          filterKey,
          filters[filterKey],
        );
        break;
    }
  });
  return filteredData;
};

/**
 * Select the comparison result curve data.
 * @returns Selector of the curve data.
 */
export const selectCompareCurves = (
  kpiType: string,
  kpi: string,
  group: string,
  level: string,
  filters: { [key: string]: string[] },
) =>
  createSelector(selectComparisonResult(), (data) => {
    return Object.entries(data?.curves).map(([key, curve]) => {
      // apply filters
      const rawData = curve?.[kpiType]?.[kpi];
      const filteredData = applyFilters(
        rawData,
        filters,
        data?.hierarchy,
        data?.groups,
        curve?.index,
      );
      let result = groupBy(filteredData, group);
      const displayLevels = data?.options?.[group]?.[level];
      const yearsData =
        filters['years'].length === 0 ? curve?.index : filters['years'];

      if (displayLevels) {
        const hIdKey = HID_KEYS[group];
        const hierarchyLevels = Object.entries(data?.hierarchy?.[group]).reduce(
          (acc, [key, value]) => {
            if (displayLevels.includes(value)) {
              acc[key] = value;
            }
            return acc;
          },
          {},
        );

        result = Coerce.getObjKeys(hierarchyLevels).reduce((acc, hId) => {
          acc[hierarchyLevels[hId]] = filteredData.filter((result) =>
            result[hIdKey].startsWith(hId),
          );

          return acc;
        }, {});
      }
      return {
        data: result,
        unit: curve?.[kpiType]?.[kpi][0]?.unit,
        index: yearsData,
        id: key,
      };
    });
  });

/**
 * Select the comparison result filter/grouping options.
 * @returns Selector of the filter/grouping options.
 */
export const selectOptions = () =>
  createSelector(selectComparisonResult(), (data) => {
    const geography = Object.entries(data?.options?.['geography'] || {}).map(
      ([level, values]) => {
        return { name: level, values };
      },
    );
    const entity = Object.entries(data?.options?.['entity'] || {}).map(
      ([level, values]) => {
        return { name: level, values };
      },
    );

    const result = { ...data?.options, geography, entity };
    return result;
  });

/**
 * Select the comparison result hierarchical data.
 * @returns Selector of the hierarchical data.
 */
export const selectHierarchy = () =>
  createSelector(selectComparisonResult(), (data) => data?.hierarchy);

/**
 * Select the levels of different group with levels.
 * @param groups list of groups with levels.
 * @returns Selector of the levels data.
 */
export const selectLevel = (groups: string[]) =>
  createSelector(selectComparisonResult(), (data) =>
    groups.reduce(
      (acc, group) => ({
        ...acc,
        [group]: data?.options[group],
      }),
      {},
    ),
  );

/**
 * Select the comparison result client data groups.
 * @returns Selector of the client data groups.
 */
export const selectCompareGroups = () =>
  createSelector(selectComparisonResult(), (data) => data?.groups);

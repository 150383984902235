<div class="dialog-container">
  <span>
    <h2 data-testid="title">
      {{ data?.title || 'Generic.labels.confirmation' | translate }}
    </h2>
  </span>

  <div fxLayout="column">
    <div class="mat-body" data-testid="message">
      {{ data?.message || 'Generic.messages.confirmation' | translate }}
    </div>
    <mat-list *ngIf="data?.listContent" data-testid="list-content">
      <mat-list-item *ngFor="let item of data?.listContent">
        <mat-icon
          mat-list-icon
          color="primary"
          *ngIf="data?.listIcon"
          data-testid="list-icon"
          >{{ data?.listIcon }}</mat-icon
        >
        <div mat-line>{{ item }}</div>
      </mat-list-item>
    </mat-list>
  </div>

  <div class="dialog-buttons" fxLayoutAlign="end" fxLayoutGap="10px">
    <button
      *ngIf="!data?.nonCancellable"
      mat-flat-button
      color=""
      [ngClass]="{ hideButton: data?.close === undefined }"
      mat-dialog-close
      data-testid="close-button"
    >
      {{ data?.close || 'Generic.labels.cancel' | translate }}
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="onConfirm()"
      data-testid="confirm-button"
    >
      {{ data?.confirm || 'Generic.labels.ok' | translate }}
    </button>
  </div>
</div>

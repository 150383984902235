import { ExistingConverter } from 'ssotool-app/+client/store';

export enum ExistingConverterDrawerMode {
  EDIT = 'edit',
  CREATE = 'create',
  VIEW = 'view',
}

export type ExistingConverterDrawerData = Readonly<{
  mode: ExistingConverterDrawerMode;
  clientId: string;
  existingConverter?: Partial<ExistingConverter>;
  readonly?: boolean;
}>;

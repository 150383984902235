import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { AlphaRequestFacadeService } from '../store';

@Injectable()
export class AlphaRequestResolver implements Resolve<any> {
  constructor(private alphaRequestFacade: AlphaRequestFacadeService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.alphaRequestFacade.loaded$.pipe(
      first(),
      tap((loaded) => !loaded && this.alphaRequestFacade.getList()),
    );
  }
}

<div [class]="currentStyleClass | ngrxPush">
  <input
    #tableCellElement
    data-testid="cell-input"
    matInput
    type="number"
    role="input"
    [readonly]="mode !== 'edit'"
    (scroll)="(false)"
    (wheel)="(false)"
    (mousewheel)="(false)"
    (keydown)="onKeyDown($event)"
    (keypress)="editCell()"
    [formControl]="cellInput"
  />
</div>

import { createAction, props } from '@ngrx/store';

import { PortfolioRoadmap } from '../../+roadmap/stores/roadmap/roadmap.model';
import { AlphaRequest, AlphaRequestPayload } from './alpha-request.model';

const doNothingAction = createAction('[Alpha Request List Page] Do nothing');
const getList = createAction('[Alpha Request List Page], Get Requests');

const getListSuccess = createAction(
  '[Alpha Request API], Get Requests List success',
  props<{
    data: AlphaRequest[];
    message: string;
  }>(),
);

const getListFailure = createAction(
  '[Alpha Request API], Get Requests List Failure',
  props<{ data: any; message: string; error: any }>(),
);

const updateRequest = createAction(
  '[Alpha Request List Page], Update Request',
  props<{ data: AlphaRequestPayload }>(),
);

const updateSuccess = createAction(
  '[Alpha Request API], Update Request success',
  props<{ data: AlphaRequest; message: string }>(),
);

const updateFailure = createAction(
  '[Alpha Request API], Update Request Failure',
  props<{ message: string; error: any }>(),
);

const getRoadmaps = createAction(
  '[Alpha Request API], Get Roadmaps',
  props<{ clientId: string; requestId: string }>(),
);

const getRoadmapsSuccess = createAction(
  '[Alpha Request API], Get Roadmaps success',
  props<{
    data: { clientId: string; requestId: string };
    roadmaps: PortfolioRoadmap[];
    message: string;
  }>(),
);

const getRoadmapsFailure = createAction(
  '[Alpha Request API], Get Roadmaps Failure',
  props<{ message: string; error: any }>(),
);

export const AlphaRequestActions = {
  doNothingAction,
  getList,
  getListSuccess,
  getListFailure,
  updateRequest,
  updateSuccess,
  updateFailure,
  getRoadmaps,
  getRoadmapsSuccess,
  getRoadmapsFailure,
};

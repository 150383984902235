import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  startWith,
  tap,
} from 'rxjs';
import { ClientFacadeService } from 'ssotool-app/+client/store/client.facade.service';
import { DataImportAPIService } from 'ssotool-app/+data-management/services/data-import/data-import.service';
import { CustomValidators } from 'ssotool-app/core/utils/validators.util';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DetailsDrawerService } from '@oculus/components/details-drawer';

import { InputConverterService } from './input-converter.service';
import { ToolRoute } from './tools-router.model';
import { ToolsRouterService } from './tools-router.service';
import {
  DEFAULT_CONVERTER_FILE_EXTS,
  TOOLS,
  TOOLS_ROUTES,
} from './tools.references';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sso-tools-drawer',
  templateUrl: './tools-drawer.component.html',
  styleUrl: './tools-drawer.component.scss',
})
export class ToolsDrawerComponent implements OnInit {
  constructor(
    private clientFacadeService: ClientFacadeService,
    private drawerService: DetailsDrawerService,
    private inputConverterService: InputConverterService,
    private formBuilder: FormBuilder,
    private toolsRouterService: ToolsRouterService,
    private dataImportService: DataImportAPIService,
  ) {}

  tools = TOOLS;

  private _currentToolRoute = this.toolsRouterService.activeRoute;
  currentToolRoute$ = this._currentToolRoute as Observable<string>;

  private _toolBreadcrumbs = this.toolsRouterService.breadcrumbs;
  toolBreadcrumbs$ = this._toolBreadcrumbs as Observable<ToolRoute[]>;

  clientId: string;

  convertForm = this.formBuilder.group({
    file: ['', [Validators.required, CustomValidators.file(['xls', 'xlsx'])]],
  });

  allowedFileExtensions = DEFAULT_CONVERTER_FILE_EXTS;

  converterStatus$ = this.inputConverterService.status$.pipe(
    tap((status) => {
      if (!!status) {
        this._uploading.next(false);
      }
    }),
  );

  hasConversionError$ = this.inputConverterService.status$.pipe(
    map((status) => status === 'failed'),
  );

  conversionLogs$ = this.inputConverterService.errorLogs$;

  private _uploading = new BehaviorSubject<boolean>(false);
  uploading$ = this._uploading as Observable<boolean>;

  displayConvert$ = combineLatest([
    this.currentToolRoute$,
    this.inputConverterService.status$,
  ]).pipe(
    map(
      ([toolRoute, status]) =>
        ['failed', 'ready'].includes(status) && toolRoute === 'inputConverter',
    ),
  );

  cannotConvert$ = combineLatest([
    this.inputConverterService.status$,
    this.uploading$,
    this.convertForm.controls.file.valueChanges,
  ]).pipe(
    map(([status, uploading, _]) => {
      return (
        status === 'processing' ||
        uploading ||
        this.convertForm.controls.file.invalid
      );
    }),
    startWith(true),
  );

  cannotCloseDrawer$ = this.inputConverterService.processing$;

  loadingTemplateDownload$ = this.dataImportService.loadingDownload$;

  ngOnInit(): void {
    this.setClientId();
    this.inputConverterService.reset();
    this.toolsRouterService.routes = TOOLS_ROUTES;
  }

  private setClientId() {
    this.clientFacadeService.activeClientId$
      .pipe(untilDestroyed(this))
      .subscribe((clientId) => {
        this.clientId = clientId;
      });
  }

  activateTool(id: ToolRoute['id']): void {
    this.toolsRouterService.activateRoute(id);
  }

  onClose(): void {
    this.drawerService.close();
  }

  onConvert(): void {
    if (this.convertForm.valid) {
      this._uploading.next(true);
      this.inputConverterService.importData(
        this.clientId,
        this.convertForm.controls.file.value?.['files']?.[0],
      );
    }
    this.convertForm.reset();
  }

  downloadInputConverterTemplate() {
    this.dataImportService.downloadTemplateByType('input_converter');
  }
}

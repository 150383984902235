import Chart from 'chart.js/auto';
import { BehaviorSubject } from 'rxjs';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';

import { YearlyValues } from '../../helpers/time-dependent';
import { generateUuid } from 'ssotool-app/shared/helpers';

@Component({
  selector: 'sso-sparkline-component',
  templateUrl: './sparkline.component.html',
  styleUrls: ['./sparkline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SparklineComponent implements AfterViewInit {
  private sparkline: Chart;
  private _yearlyValues: number[];
  private minYear = new BehaviorSubject<string>('');
  minYear$ = this.minYear.asObservable();
  private maxYear = new BehaviorSubject<string>('');
  maxYear$ = this.maxYear.asObservable();
  private minYearValue = new BehaviorSubject<string>('');
  minYearValue$ = this.minYearValue.asObservable();
  private maxYearValue = new BehaviorSubject<string>('');
  maxYearValue$ = this.maxYearValue.asObservable();

  generatedId: string = generateUuid();
  canvasContainerId = 'canvas-container-' + this.generatedId;

  @Input() set yearlyValuesMap(yearlyValues: YearlyValues) {
    const entries = Object.entries(yearlyValues || {});
    const [minYear, minYearValue] = entries[0] || [];
    const [maxYear, maxYearValue] = entries[entries.length - 1] || [];

    this.minYear.next(minYear);
    this.minYearValue.next(minYearValue);
    this.maxYear.next(maxYear);
    this.maxYearValue.next(maxYearValue);
    this._yearlyValues = entries.map(([_, pt]) => Number(pt));
    this.initializeMetadata();
  }

  @Input() showYears = false;

  constructor() {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initializeMetadata();
    }, 500);
  }

  private initializeMetadata() {
    this.cleanup();
    this.sparkline = this.initializeChartData();
  }

  cleanup() {
    /* istanbul ignore else */
    if (this.sparkline) {
      this.sparkline.destroy();
    }
  }

  private initializeChartData() {
    const context = (
      document.getElementById(this.generatedId) as any
    )?.getContext('2d');

    if (!context) {
      return;
    }

    return new Chart(context, {
      type: 'line',
      data: {
        labels: new Array(this._yearlyValues.length).fill(''),
        datasets: [
          {
            data: this._yearlyValues,
            pointBackgroundColor: '#2699FB',
            pointBorderColor: '#2699FB',
            pointRadius: (options) =>
              options.dataIndex === 0 ||
              options.dataIndex === this._yearlyValues.length - 1
                ? 2
                : 0,
          },
        ],
      },
      options: this.getChartOptions(),
    });
  }

  private getChartOptions() {
    return {
      responsive: false,
      hover: {
        mode: null,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      elements: {
        line: {
          borderColor: '#ffa940',
          borderWidth: 2,
        },
        point: {
          radius: 2,
        },
      },
      scales: {
        y: {
          display: false,
        },
        x: {
          display: false,
        },
      },
    };
  }
}

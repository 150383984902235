import { Observable } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';
import { Breadcrumb, Feature } from 'ssotool-core/config';

import { Injectable } from '@angular/core';
import {
  NavigationEnd,
  NavigationExtras,
  Params,
  Router,
  RoutesRecognized,
} from '@angular/router';
import {
  RouterNavigationPayload,
  RouterStateSerializer,
} from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';

import {
  RouterActiveAction,
  RouterBackAction,
  RouterForwardAction,
  RouterGoAction,
} from './router.actions';
import { RouterStateExtended, RouterStateUrl } from './router.model';
import { routerSelectors } from './router.reducer';

@Injectable({ providedIn: 'root' })
export class RouterStore {
  state$: Observable<RouterStateExtended> = this._store$.pipe(
    select(routerSelectors.state),
  );

  activeRoute$: Observable<RouterStateUrl> = this._store$.pipe(
    select(routerSelectors.activeRoute),
  );
  desiredRoute$: Observable<RouterStateUrl> = this._store$.pipe(
    select(routerSelectors.desiredRoute),
  );

  data$: Observable<Feature> = this._store$.pipe(select(routerSelectors.data));
  params$: Observable<Params> = this._store$.pipe(
    select(routerSelectors.params),
  );
  queryParams$: Observable<Params> = this._store$.pipe(
    select(routerSelectors.queryParams),
  );
  url$: Observable<string> = this._store$.pipe(select(routerSelectors.url));
  breadcrumbs$: Observable<Array<Breadcrumb>> = this._store$.pipe(
    select(routerSelectors.breadcrumbs),
  );

  private _activeUrl: string;
  private _previousUrl: string;

  protected lastRoutesRecognized: RoutesRecognized = null;

  constructor(
    private _store$: Store<RouterStateUrl | RouterStateExtended>,
    router: Router,
    stateSerializer: RouterStateSerializer<any>,
  ) {
    router.events
      .pipe(
        filter<RoutesRecognized>((event) => event instanceof RoutesRecognized),
      )
      .subscribe((e) => {
        this.lastRoutesRecognized = e;
      });

    router.events
      .pipe(
        filter<NavigationEnd>((event) => event instanceof NavigationEnd),
        withLatestFrom(_store$.pipe(select(routerSelectors.state))),
        filter(
          ([event, routerReducer]) => routerReducer?.navigationId === event.id,
        ),
      )
      .subscribe(([event, routerReducer]) => {
        const routerStateSerialized = stateSerializer.serialize(
          router.routerState.snapshot,
        );
        this._activeUrl = this.lastRoutesRecognized.url;
        this.routerActivate({
          routerState: routerStateSerialized,
          event: new RoutesRecognized(
            this.lastRoutesRecognized.id,
            this.lastRoutesRecognized.url,
            this.lastRoutesRecognized.urlAfterRedirects,
            routerStateSerialized as any,
          ),
        });
      });

    router.events
      .pipe(filter<NavigationEnd>((event) => event instanceof NavigationEnd))
      .subscribe((e: any) => {
        this._previousUrl = e.url;
      });
  }

  go(path: any[], query?: any, extras?: NavigationExtras): void {
    this._store$.dispatch(new RouterGoAction({ path, query, extras }));
  }

  back(): void {
    this._store$.dispatch(new RouterBackAction());
  }

  forward(): void {
    this._store$.dispatch(new RouterForwardAction());
  }

  getActiveUrl(): string {
    return this._activeUrl;
  }

  getPreviousUrl() {
    return this._previousUrl;
  }

  reload(): void {
    document.location.reload();
  }

  private routerActivate(payload: RouterNavigationPayload<any>) {
    this._store$.dispatch(new RouterActiveAction(payload));
  }
}

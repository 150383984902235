import { createFeatureSelector, createSelector } from '@ngrx/store';
import { alphaRequestAdapter, AlphaRequestState, alphaRequestFeatureKey } from './alpha-request.reducer';

const { selectAll } = alphaRequestAdapter.getSelectors();

export const alphaRequestFeatureState = createFeatureSelector<AlphaRequestState>(alphaRequestFeatureKey);

export const getAlphaRequestList = createSelector(alphaRequestFeatureState, selectAll);

export const loading = createSelector(alphaRequestFeatureState, (state) => state.loading);

export const loaded = createSelector(alphaRequestFeatureState, (state) => state.loaded);

export const roadmapsLoading = createSelector(alphaRequestFeatureState, (state) => state.roadmapsLoading);

export const roadmapsLoaded = createSelector(alphaRequestFeatureState, (state) => state.roadmapsLoaded);

export const alphaRequest = (requestId: string) => {
    return createSelector(alphaRequestFeatureState, state => state.entities[requestId]);
};

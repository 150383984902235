import { map } from 'rxjs/operators';
import { AnnouncementFacadeService } from 'ssotool-app/+announcement/stores';
import { SlideInOutAnimation } from 'ssotool-app/shared/helpers/animations';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sso-release-notes-banner',
  templateUrl: './release-notes-banner.component.html',
  styleUrls: ['./release-notes-banner.component.scss'],
  animations: [SlideInOutAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReleaseNotesBannerComponent {
  @Input() id: string;
  @Input() version: string = '';

  notLoading$ = this.announcementFacade.loading$.pipe(
    map((loading) => !loading),
  );

  constructor(private announcementFacade: AnnouncementFacadeService) {}

  closeBanner() {
    return this.announcementFacade.readAnnouncement(this.id);
  }
}

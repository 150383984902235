import { forkJoin, from, iif, Observable, of, pipe, throwError } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { ScoopaBase } from 'scoopabase';
import { PromiseResponse } from 'scoopabase/dist/scoopabase/scoopabase.interface';

import { Injectable } from '@angular/core';

import { Flows, FlowsData, FlowsType } from './flows.model';
import { ADD_BATCH_SIZE } from './flows.references';

@Injectable({
  providedIn: 'root',
})
export class FlowsLocalStorageService {
  private scoopaDB = new ScoopaBase('flows');

  /**
   * Getter function for the collection of the given flow type
   * Flowtypes: timeseries, flowvolumes, flowprices
   */
  getCollection(flowType: FlowsType) {
    return this.scoopaDB.collection<Flows>(flowType);
  }

  /**
   * Process the flow data to be saved in the local storage
   * @param flowType the type of flow to add data to its collection
   * @param flows array of flow to be saved in the local storage
   */
  addFlows(
    flowType: FlowsType,
    flows: Array<Flows>,
  ): Observable<PromiseResponse<Flows>[]> {
    return this.addFlowsBatched(flowType, flows).pipe(take(1));
  }

  private addFlowsBatched(
    flowType: FlowsType,
    flows: Array<Flows>,
    startIndex = 0,
  ): Observable<Array<PromiseResponse<Flows>>> {
    const flowLength = flows?.length;
    const endIndex = Math.min(startIndex + ADD_BATCH_SIZE, flowLength);
    const flowsBatch = flows.slice(startIndex, endIndex);

    if (flowLength === 0) {
      return of(null);
    }

    return forkJoin(
      flowsBatch.map((flow) => this.addSingleFlow(flowType, flow)),
    ).pipe(
      mergeMap((result) =>
        iif(
          () => endIndex < flowLength,
          this.addFlowsBatched(flowType, flows, endIndex),
          of(result),
        ),
      ),
      catchError((error) => throwError(error)),
    );
  }

  //this function might not be used at all.
  /**
   * Process the flow data to be saved in the local storage
   * @param flowType the type of flow to add data to its collection
   * @param flow single flow to be saved in the local storage
   */
  private addSingleFlow(
    flowType: FlowsType,
    flow: Flows,
  ): Observable<PromiseResponse<Flows>> {
    return from(this.getCollection(flowType).add(flow, flow.entityId));
  }

  /**
   * Returns single flow series data for the given entity id.
   * @param flowType the type of flow to get data from its collection
   * @param entityId the id of the data to be read from the database
   * @returns observable of the data array for the corresponding entity id, without the entity id
   */
  getFlowDataByEntityId(
    flowType: FlowsType,
    entityId: string,
  ): Observable<FlowsData> {
    return this.getCollection(flowType)
      .document$(entityId)
      .pipe(map((flows) => flows?.data || []));
  }

  /**
   * Clear all of the contents of the local storage
   * Purpose is to remove the persistent of local storage
   */
  clear(flowType: FlowsType) {
    this.getCollection(flowType).clearAll();
  }
}

import { first, mergeMap, tap } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client/store/client.facade.service';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { CampaignImportFacadeService } from '../store/import/campaign-import.facade.service';

@Injectable()
export class CampaignImportResolverService implements Resolve<any> {
  constructor(private campaignImportFacade: CampaignImportFacadeService, private clientFacade: ClientFacadeService) {}

  resolve(route: ActivatedRouteSnapshot) {
    this.clientFacade.selectActiveClientData$.pipe(
      mergeMap((client) => this.campaignImportFacade.loaded$.pipe(
        first(),
        tap((loaded) => client && !loaded && this.campaignImportFacade.getList(client?.clientId)),
      )),
    ).subscribe();
  }
}

import { Component, Input } from '@angular/core';
import {
  InputConverterLog,
  InputConverterSummary,
  Location,
} from './input-converter-summary.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'sso-input-converter-summary',
  templateUrl: './input-converter-summary.component.html',
  styleUrls: ['./input-converter-summary.component.scss'],
})
export class InputConverterSummaryComponent {
  private _data = new BehaviorSubject<InputConverterSummary>([]);
  @Input() set data(value: InputConverterSummary) {
    this._errors.next(value.filter((log) => log.type === 'ERROR'));
    this._warnings.next(value.filter((log) => log.type === 'WARN'));
  }

  get data() {
    return this._data.getValue();
  }

  private _errors = new BehaviorSubject<InputConverterLog[]>([]);
  errors$ = this._errors.asObservable();

  private _warnings = new BehaviorSubject<InputConverterLog[]>([]);
  warnings$ = this._warnings.asObservable();

  constructor() {}

  composeLocationArray(location: InputConverterLog['location']): Location[] {
    return Object.entries(location).reduce((acc, [key, value]) => {
      if (value) {
        acc.push({ key, value });
      }
      return acc;
    }, []);
  }
}

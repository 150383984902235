import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PushPipe } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';

import { ConverterModule } from '../converter-form/converter.module';
import { EnergyEfficiencyFormModule } from '../energy-efficiency-form';
import { MarketFormModule } from '../market-form';
import { RenewableProductionFormModule } from '../renewable-production-form';
import { StorageFormModule } from '../storage-form';
import { CampaignDrawerComponent } from './campaign-drawer.component';
import { SelectModule } from 'ssotool-app/shared/modules/select/select.module';
import { ProfilesFormModule } from 'ssotool-app/shared/modules/profiles-form/profiles-form.module';

@NgModule({
  declarations: [CampaignDrawerComponent],
  exports: [CampaignDrawerComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
    SelectModule,
    MatDialogModule,
    ConverterModule,
    MarketFormModule,
    StorageFormModule,
    EnergyEfficiencyFormModule,
    RenewableProductionFormModule,
    ProfilesFormModule,
    PushPipe,
    NgxSkeletonLoaderModule,
  ],
})
export class CampaignDrawerModule {}

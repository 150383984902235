import 'tslib'; // Import the 'tslib' module

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { PushPipe } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import {
  DetailsDrawerModule,
  DetailsDrawerService,
} from '@oculus/components/details-drawer';
import { DrawerModule } from '@oculus/components/drawer';
import { FooterModule } from '@oculus/components/footer';
import { HeaderModule } from '@oculus/components/header';

import { AlphaRequestRootModule } from './+alpha-request/alpha-request-root.module';
import { AmbitionRootModule } from './+ambition/ambition-root.module';
import { AnnouncementRootModule } from './+announcement';
import { CampaignRootModule } from './+campaign/campaign-root.module';
import { CampaignModule } from './+campaign/campaign.module';
import { ClientRootModule } from './+client/client-root.module';
import { ClientHeaderModule } from './+client/components/client-header/client-header.module';
import { DataManagementRootModule } from './+data-management/data-management-root.module';
import { EntitiesRootModule } from './+entities/entities-root.module';
import { ErrorPageModule } from './+error';
import { GlobalImportRootModule } from './+global-import/global-import-root.module';
import { ReleaseNotesBannerModule } from './+release-notes/components/banner/release-notes-banner.module';
import { ReleaseNotesRootModule } from './+release-notes/release-notes-root.module';
import { RoadmapRootModule } from './+roadmap/roadmap-root.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core';
import { LogoModule, SsoTooltipModule } from './shared';

const nativeModules = [
  CommonModule,
  RouterModule,
  TranslateModule,
  FlexLayoutModule,
  PushPipe,
];

const ssoModules = [
  CoreModule,
  ClientRootModule,
  ClientHeaderModule,
  DataManagementRootModule,
  EntitiesRootModule,
  RoadmapRootModule,
  CampaignModule,
  CampaignRootModule,
  GlobalImportRootModule,
  AmbitionRootModule,
  AlphaRequestRootModule,
  AnnouncementRootModule,
  ReleaseNotesRootModule,
  ErrorPageModule,
  LogoModule,
  ReleaseNotesBannerModule,
];

const libraryModules = [
  DrawerModule,
  FooterModule,
  HeaderModule,
  DetailsDrawerModule,
  NgSelectModule,
  SsoTooltipModule,
];

const materialModules = [
  MatMenuModule,
  MatIconModule,
  MatSidenavModule,
  MatTooltipModule,
  MatListModule,
  MatButtonModule,
  MatNativeDateModule,
];
@NgModule({
  declarations: [AppComponent],
  imports: [
    ...nativeModules,
    ...ssoModules,
    ...libraryModules,
    ...materialModules,
  ],
  bootstrap: [AppComponent],
  providers: [
    MatIconRegistry,
    DetailsDrawerService,
    provideHotToastConfig({
      position: 'top-right',
      stacking: 'vertical',
      visibleToasts: 5,
    }),
  ],
})
export class AppModule {}

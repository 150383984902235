import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { doNothing } from 'ssotool-core/utils';
import {
  createErrorObject,
  ExecStatusChecker,
  SSOToolRoutePathService,
  UserStateManagerService,
} from 'ssotool-shared';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { RoadmapFacadeService } from '../roadmap/roadmap-facade.service';
import { RoadmapImportActions } from './roadmap-import.action';
import { RoadmapImportAPIService } from './roadmap-import.service';
import { ToastService } from 'ssotool-app/shared/services/toast/toast.service';

@Injectable()
export class RoadmapImportEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoadmapImportActions.get),
      mergeMap((action) =>
        this.roadmapImportService
          .get(action.clientId, action.roadmapId, action.importId)
          .pipe(
            map((response) =>
              RoadmapImportActions.getSuccess({
                data: response,
                message: 'Get roadmap import info success!',
              }),
            ),
            catchError((error) =>
              of(
                RoadmapImportActions.getFailure({
                  ...createErrorObject(error, 'Get roadmap import info error'),
                  data: action,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoadmapImportActions.getSuccess),
        tap((action) =>
          this.userManagerService.get(action.data.owner)
            ? doNothing()
            : this.userManagerService.getUserById(action.data.owner),
        ),
      ),
    { dispatch: false },
  );

  getFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoadmapImportActions.getFailure),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  getList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoadmapImportActions.getList),
      mergeMap((action) =>
        this.roadmapImportService
          .getList(action.clientId, action.roadmapId)
          .pipe(
            map((response) =>
              RoadmapImportActions.getListSuccess({
                data: {
                  data: response,
                  clientId: action.clientId,
                },
                message: 'Get roadmap import list success!',
              }),
            ),
            catchError((error) =>
              of(
                RoadmapImportActions.getListFailure({
                  ...createErrorObject(error, 'Get roadmap import list error'),
                  data: action,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getListSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoadmapImportActions.getListSuccess),
        tap((action) => this.snackbar.showToast(action.message, 'success')),
      ),
    { dispatch: false },
  );

  getListFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoadmapImportActions.getListFailure),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  downloadRoadmapImportData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoadmapImportActions.downloadRoadmapImportData),
      mergeMap((action) =>
        this.roadmapImportService
          .downloadRoadmapImportData(
            action.clientId,
            action.roadmapId,
            action.importId,
          )
          .pipe(
            map((response) =>
              RoadmapImportActions.downloadRoadmapImportDataSuccess({
                data: { ...response, ...action },
                message: 'Download Roadmap Import Success',
              }),
            ),
            catchError((error) =>
              of(
                RoadmapImportActions.downloadRoadmapImportDataFailure({
                  ...createErrorObject(error, 'Download roadmap import error'),
                  data: action,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  downloadRoadmapImportDataSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoadmapImportActions.downloadRoadmapImportDataSuccess),
        tap((action) => this.snackbar.showToast(action.message, 'success')),
      ),
    { dispatch: false },
  );

  downloadRoadmapImportDataFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoadmapImportActions.downloadRoadmapImportDataFailure),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  updateImportStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoadmapImportActions.updateImportStatus),
        filter(
          (action) =>
            this.importStatusChecker.isSuccessful(action.status) ||
            this.importStatusChecker.isError(action.status),
        ),
        tap((action) => {
          if (this.importStatusChecker.isSuccessful(action.status)) {
            this.snackbar.showToast(
              this.translateService.instant('Roadmap.messages.import.success'),
              'success',
            );
            this.roadmapFacade.get(action.clientId, action.roadmapId);
            setTimeout(
              () =>
                this.router.navigate(
                  this.routePath.viewRoadmap(action.clientId, action.roadmapId),
                ),
              3000,
            );
          } else {
            this.snackbar.showToast(
              this.translateService.instant('Roadmap.messages.import.error'),
              'error',
            );
          }
        }),
      ),
    { dispatch: false },
  );

  importRoadmap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoadmapImportActions.importRoadmap),
      mergeMap((action) =>
        this.roadmapImportService
          .importRoadmap(action.clientId, action.roadmapId, action.data)
          .pipe(
            map((response) =>
              RoadmapImportActions.importRoadmapSuccess({
                data: response,
                message: this.translateService.instant(
                  'Roadmap.messages.import.processing',
                ),
              }),
            ),
            catchError((error) =>
              of(
                RoadmapImportActions.importRoadmapFailure({
                  ...createErrorObject(error, 'Import roadmap error'),
                  data: action.data,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  importRoadmapSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoadmapImportActions.importRoadmapSuccess),
        tap((action) => this.snackbar.showToast(action.message, 'info')),
      ),
    { dispatch: false },
  );

  importRoadmapFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoadmapImportActions.importRoadmapFailure),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  downloadErrorLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoadmapImportActions.downloadErrorLogs),
      mergeMap((action) =>
        this.roadmapImportService
          .downloadErrorLogs(action.clientId, action.roadmapId, action.importId)
          .pipe(
            map((response) =>
              RoadmapImportActions.downloadErrorLogsSuccess({
                importId: action.importId,
                data: response,
                message: 'Download logs success',
              }),
            ),
            catchError((error) =>
              of(
                RoadmapImportActions.downloadErrorLogsFailure({
                  ...createErrorObject(error, 'Download logs error'),
                  data: action,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  deleteImport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoadmapImportActions.deleteImport),
      mergeMap((action) =>
        this.roadmapImportService
          .deleteImport(action.clientId, action.roadmapId, action.importId)
          .pipe(
            map(() =>
              RoadmapImportActions.deleteImportSuccess({
                data: action,
                importId: action.importId,
                message: 'Roadmap import deleted successfully',
              }),
            ),
            catchError((error) =>
              of(
                RoadmapImportActions.deleteImportFailure({
                  ...createErrorObject(error, 'Delete import error'),
                  data: action,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  deleteImportSuccessful$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoadmapImportActions.deleteImportSuccess),
        tap((action) => this.snackbar.showToast(action.message, 'success')),
      ),
    { dispatch: false },
  );

  deleteImportFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoadmapImportActions.deleteImportFailure),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  downloadTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoadmapImportActions.downloadTemplate),
      mergeMap((action) =>
        this.roadmapImportService.downloadTemplate(action.clientId).pipe(
          map(() =>
            RoadmapImportActions.downloadTemplateSuccess({
              data: action,
              message: 'Download template success',
            }),
          ),
          catchError((error) =>
            of(
              RoadmapImportActions.downloadTemplateFailure({
                ...createErrorObject(error, 'Download template error'),
                data: action,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  downloadTemplateSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoadmapImportActions.downloadTemplateSuccess),
        tap((action) => this.toastService.success(action.message)),
      ),
    { dispatch: false },
  );

  downloadTemplateFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoadmapImportActions.downloadTemplateFailure),
        tap((action) => this.toastService.error(action.message)),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private roadmapImportService: RoadmapImportAPIService,
    private snackbar: ToastService,
    private userManagerService: UserStateManagerService,
    private importStatusChecker: ExecStatusChecker,
    private translateService: TranslateService,
    private router: Router,
    private routePath: SSOToolRoutePathService,
    private roadmapFacade: RoadmapFacadeService,
    private toastService: HotToastService,
  ) {}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PieChartComponent } from './pie-chart.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [PieChartComponent],
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    MatCheckboxModule,
    MatCardModule,
    TranslateModule,
  ],
  exports: [PieChartComponent],
})
export class PieChartModule {}

import { Observable } from 'rxjs';
import { DialogData, YearlyValues } from 'ssotool-shared';

export interface TargetFormModel {
  readonly isTarget?: boolean;
  readonly isCustom?: boolean;
  readonly clientId: string;
  readonly owner?: string;
  readonly name: string;
  readonly scenarioName: string;
  readonly geoId: string;
  readonly siteId: string;
  readonly companyEntityId: string;
  readonly sectorId: string;
  readonly fluidId: string;
  readonly processId: string;
  readonly quantityId: string;
  readonly indicator: string;
  readonly values?: YearlyValues;
  readonly constraintId?: string;
}

export interface TargetDialogModel extends TargetFormModel, DialogData {
  readonly mode: 'add' | 'edit' | 'view';
  readonly owner?: string;
  readonly clientId: string;
  readonly ambitions$?: Observable<string[]>;
}

export interface BackendTargetModel {
  readonly constraintId: string;
  readonly isTarget?: boolean;
  readonly isCustom?: boolean;
  readonly clientId: string;
  readonly owner: string;
  readonly name: string;
  readonly scenarioName: string;
  readonly geoId: string;
  readonly companyEntityId: string;
  readonly sectorId?: string;
  readonly processId?: string;
  readonly fluidId?: string;
  readonly quantityId: string;
  readonly values: YearlyValues;
  readonly indicator?: string;
}

export interface TargetModel {
  readonly id: string;
  readonly name: string;
  readonly company: string;
  readonly geography: string;
  readonly sector: string;
  readonly process: string;
  readonly fluid: string;
  readonly quantity: string;
  readonly selected: SelectedType;
  readonly clientId: string;
  readonly constraintId: string;
  readonly companyEntityId: string;
  readonly geoId?: string;
  readonly siteId?: string;
  readonly sectorId: string;
  readonly processId: string;
  readonly fluidId: string;
  readonly quantityId: string;
  readonly scenario: string;
  readonly values: YearlyValues;
  readonly isTarget?: boolean;
  readonly sectorName?: string;
  readonly geoName?: string;
  readonly companyName?: string;
  readonly processName?: string;
  readonly fluidName?: string;
  readonly quantityName?: string;
  readonly unit?: string;
  readonly scenarioName?: string;
  readonly indicator?: string;
  readonly isCustom?: boolean;
  readonly compositeKey?: string;
}

export enum SelectedType {
  SELECTED = 'selected',
  NOT_SELECTED = 'not selected',
}

import { ToolRoute } from './tools-router.model';
import { ToolCardData } from './tools/tool-card/tool-card.model';

export const TOOLS_ROUTES: ToolRoute = {
  id: '',
  labelKey: 'allTools',
  children: [
    {
      id: 'inputConverter',
      labelKey: 'inputConverter',
      children: [],
    },
  ],
};

export const TOOLS: ToolCardData[] = [
  {
    icon: 'double_arrow',
    id: 'inputConverter',
    routeId: 'input-converter',
  },
];

export const DEFAULT_CONVERTER_FILE_EXTS = ['xls', 'xlsx'];

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CampaignStageMap } from './campaign-export.model';

import {
  campaignExportAdapter,
  campaignExportFeatureKey,
  CampaignExportState,
} from './campaign-export.reducer';

const { selectEntities, selectAll } = campaignExportAdapter.getSelectors();

export const campaignExportFeatureState =
  createFeatureSelector<CampaignExportState>(campaignExportFeatureKey);

export const campaignExportListEntities = createSelector(
  campaignExportFeatureState,
  selectEntities,
);

export const campaignExportList = createSelector(
  campaignExportFeatureState,
  selectAll,
);

export const exporting = createSelector(
  campaignExportFeatureState,
  (state) => state.exporting,
);

export const loaded = createSelector(
  campaignExportFeatureState,
  (state) => state.loaded,
);

export const cancelExportTriggered = createSelector(
  campaignExportFeatureState,
  (state) => state.cancelExportTriggered,
);

export const selectCampaignExport = (props) =>
  createSelector(campaignExportListEntities, (entities) => entities[props?.id]);

export const activeExportId = createSelector(
  campaignExportFeatureState,
  (state) => state.activeExportId,
);

export const selectActiveCampaignExport = createSelector(
  campaignExportFeatureState,
  campaignExportListEntities,
  (state, entities) =>
    state.activeExportId ? entities[state.activeExportId] : {},
);

export const selectStages = (exportId: string) =>
  createSelector(
    campaignExportFeatureState,
    (state) => state.entities[exportId]?.stages as CampaignStageMap[],
  );

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LibraryImportAPIService } from './services';
import {
  LibraryImportEffects,
  LibraryImportFacadeService,
  libraryImportFeatureKey,
  LibraryImportReducer,
} from './store';

@NgModule({
  declarations: [],
  imports: [
    EffectsModule.forFeature([LibraryImportEffects]),
    StoreModule.forFeature(libraryImportFeatureKey, LibraryImportReducer),
  ],
  providers: [LibraryImportAPIService, LibraryImportFacadeService],
})
export class GlobalImportRootModule {}

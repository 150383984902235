import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FORM_OPTION_DIVIDER_VALUE } from 'ssotool-app/app.references';
import { BaseComponent } from 'ssotool-shared/component/base';
import { FormFieldOption } from 'ssotool-shared/helpers/form-helpers';

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatSelectChange } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { FormFieldErrorMessage } from '../../helpers/form-helpers';

@UntilDestroy()
@Component({
  selector: 'sso-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent extends BaseComponent implements OnInit {
  @Input() hideRequiredMarker: boolean;
  @Input() required: boolean;
  @Input() hint: string;
  @Input() label: string;
  @Input() tooltip: string;
  @Input() appearance: MatFormFieldAppearance;
  @Input() placeholder: string;
  @Input() readonly: boolean;
  @Input() options: Array<FormFieldOption<any>> = [];
  @Input() control: FormControl = new FormControl();
  @Input() errorMessageMap: FormFieldErrorMessage = {};
  private _customClassMap = new BehaviorSubject<Record<string, string>>({});
  @Input() set customClassMap(customClassMap: Record<string, string>) {
    this._customClassMap.next(customClassMap);
  }

  private selected = new BehaviorSubject<any>({});
  selected$ = this.selected.asObservable();

  customClassMapValue$ = combineLatest([
    this._customClassMap.pipe(startWith({})),
    this.selected$.pipe(startWith({})),
  ]).pipe(map(([classMap, selected]) => classMap?.[selected?.value]));

  @Input() set disabled(disabled: boolean) {
    if (!this.control) {
      return;
    }
    disabled
      ? this.control.disable({ emitEvent: false })
      : this.control.enable({ emitEvent: false });
  }

  @Input() set value(value: string) {
    /* istanbul ignore else*/
    if (this.control) {
      this.control.patchValue(value, { emitEvent: false });
    }
  }

  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Optional() private _changeDetector?: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    if (this.control) {
      this.addSubscription(
        this.control.statusChanges.subscribe(() =>
          this._changeDetector ? this._changeDetector.markForCheck() : true,
        ),
      );
      this.changeSelected(this.control.value);
      this.addSubscription(
        this.control.valueChanges.subscribe((value) =>
          this.changeSelected(value),
        ),
      );
    }
  }

  onSelectionChange(change: MatSelectChange) {
    this.selectionChange.emit(change.value);
    this.changeSelected(change.value);
  }

  isDividerOption(value: string) {
    return value === FORM_OPTION_DIVIDER_VALUE;
  }

  getInputValue(value?: string) {
    return this.options?.find((option) => option.value === value)?.name || '';
  }

  private changeSelected(value: string) {
    this.selected.next(this.options?.find((x) => value === x.value));
  }
}

import { Coerce } from 'ssotool-shared/helpers';

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { version } from '../../../environments/environment-commons';
import { AnnouncementType } from './announcement.const';
import {
  announcementAdapter,
  announcementFeatureKey,
  AnnouncementState,
} from './announcement.reducer';

const { selectEntities, selectAll } = announcementAdapter.getSelectors();

export const announcementFeatureState =
  createFeatureSelector<AnnouncementState>(announcementFeatureKey);

export const announcementEntities = createSelector(
  announcementFeatureState,
  selectEntities,
);

export const announcementList = createSelector(
  announcementFeatureState,
  selectAll,
);

export const loading = createSelector(
  announcementFeatureState,
  (state) => state.loading,
);

export const loaded = createSelector(
  announcementFeatureState,
  (state) => state.loaded,
);

export const showReleaseBanner = createSelector(
  announcementList,
  (announcements) =>
    announcements.some((item) => item.type === AnnouncementType.RELEASE),
);

export const latestRelease = createSelector(announcementList, (announcements) =>
  Coerce.toObject(
    announcements.find((item) => item.type === AnnouncementType.RELEASE),
  ),
);

export const latestReleaseVersion = createSelector(
  latestRelease,
  (release) => Coerce.toObject(release.data).version,
);

export const latestReleaseId = createSelector(
  latestRelease,
  (release) => release.id,
);

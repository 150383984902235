import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { BaseTableComponent } from '../permission-table/base-table.component';
import { SelectionModel } from '@angular/cdk/collections';
import {
  ColumnDefinition,
  Definition,
} from '../permission-table/base-table.model';
import { FormControl } from '@angular/forms';
import {
  SelectionGroups,
  TableConfiguration,
  TableItem,
  TableItems,
  SelectionGroupWithStatus,
} from './generic-table.model';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import {
  FilterGroups,
  SelectedFilters,
} from '../combination-filter/combination-filter.model';
import { SimpleFiltersService } from '../combination-filter/simple-filters/simple-filters.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Coerce } from 'ssotool-app/shared/helpers/coerce.utils';

@UntilDestroy()
@Component({
  selector: 'sso-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss'],
})
export class GenericTableComponent
  extends BaseTableComponent<any>
  implements OnInit, AfterViewInit, OnChanges
{
  private _configuration: TableConfiguration<any>;
  @Input() set configuration(value: TableConfiguration<any>) {
    this._configuration = value;
    this.columnsDef = value.columnDefinitions;
    const visibleItems = Coerce.toArray(value.items).filter(
      (item) => item.isShown,
    );
    this.refreshData(visibleItems);
    this.refreshPagination(this.paginator);
    this.refreshSelections();
    this.refreshFilters(value);
    this.refreshGroups(value.selectionGroups, visibleItems);
    this.createSelectionGroups();
  }

  selectionGroups: SelectionGroupWithStatus[] = [];

  get data(): TableItems<unknown> {
    return this.dataSource.data;
  }

  @Output() selectionChange = new EventEmitter<TableItem<any>['id'][]>();

  mockYearlyControl = new FormControl({});
  columnDefinitions$ = new BehaviorSubject<Definition[]>([]);
  columnsToDisplay$ = new BehaviorSubject<Array<keyof ColumnDefinition>>([]);
  groups$ = new BehaviorSubject<SelectionGroups>([]);
  areAllSelected$ = new BehaviorSubject<boolean>(false);
  filters$ = new BehaviorSubject<FilterGroups>([]);

  defineColumns(): ColumnDefinition {
    return {};
  }

  onSelectedFiltersChange(selectedFilters: SelectedFilters): void {
    if (selectedFilters && this._configuration) {
      const filteredData = this.simpleFiltersService.filter(
        this._configuration.items,
        selectedFilters,
        (item: TableItem<any>) => item.value,
      );
      this.refreshData(filteredData);
      this.refreshPagination(this.paginator);
      this.refreshGroups(this._configuration.selectionGroups, filteredData);
    }
    this.updateMainCheckBoxState();
  }

  onSelectionEvent(event: any, item: TableItem<any>): void {
    // UNUSED NOW
    // main checkbox
    // if (!item) {
    //   this.onGroupSelectionEvent(event);
    //   return;
    // }

    if (event.checked) {
      this.itemSelections.select(item.id);
    } else {
      this.itemSelections.deselect(item.id);
    }

    this.refreshSelectedGroupStates();

    this.selectionChange.emit(this.itemSelections.selected);
  }

  // UNUSED NOW
  // onGroupSelectionEvent(event: any): void {
  //   if (event.checked) {
  //     this.data.forEach((item) => {
  //       this.itemSelections.select(item.id);
  //     });
  //   } else {
  //     this.itemSelections.clear();
  //   }

  //   this.selectionChange.emit(this.itemSelections.selected);
  // }

  //
  /**
   * reflect the single item toggles into the selection group's collective selected status
   * @param isFromAllToggle if called from the all toggle
   * @param checked change in selected status
   */
  private refreshSelectedGroupStates(
    isFromAllToggle: boolean = false,
    checked: boolean = false,
  ): void {
    this.selectionGroups.forEach((group) => {
      // mimic toggle state if toggling from the ALL checkbox
      if (isFromAllToggle) {
        group.selected = checked;
      } else {
        // this conditional is to prevent asserting
        // no-children groups ([].every(fn) is always truthy)
        if (!!group.children.length) {
          group.selected = group.children.every((item) =>
            this.itemSelections.isSelected(item.id),
          );
        } else {
          group.selected = group.selected;
        }
      }
      // group.selected = isFromAllToggle
      //   ? checked
      //   : !!group.children.length
      //   ? group.children.every((item) =>
      //       this.itemSelections.isSelected(item.id),
      //     )
      //   : group.selected;
    });
  }

  groupSelect(event: MatCheckboxChange, groupId: string): void {
    let affectedGroups: SelectionGroupWithStatus[];
    if (groupId === 'header') {
      affectedGroups = this.selectionGroups;
    } else {
      affectedGroups = this.selectionGroups.filter(
        (group) => group.group.id === groupId,
      );
    }

    affectedGroups.forEach((affectedGroup) => {
      affectedGroup.children.forEach((item) => {
        if (event.checked) {
          this.itemSelections.select(item.id);
        } else {
          this.itemSelections.deselect(item.id);
        }
      });
    });

    this.refreshSelectedGroupStates(groupId === 'header', event.checked);

    this.selectionChange.emit(this.itemSelections.selected);
  }

  /**
   * Util to locate the selected status of a group
   * @param groupId
   * @returns {bool} boolean that contains selected status
   */
  getSelectedStatus(groupId: string) {
    return !!Coerce.toEmptyObject(
      this.selectionGroups.find((group) => group.group.id === groupId),
    ).selected;
  }

  private createSelectionGroups(): void {
    let groups = this._configuration.selectionGroups.reduce((acc, group) => {
      acc.push({
        group,
        selected: false,
        children: this._configuration.items.filter(
          (item) => item.groupId === group.id,
        ),
      });
      return acc;
    }, []);

    this.selectionGroups = groups;
  }

  itemSelections = new SelectionModel<TableItem<any>['id']>(true, []);

  private getColumnDefinitions(): Definition[] {
    return Object.values(this.columnsDef);
  }

  private refreshGroups(
    selectionGroups: SelectionGroups,
    items: TableItems<any>,
  ): void {
    this.groups$.next(this.buildGroups(selectionGroups, items));
  }

  private refreshPagination(paginator: MatPaginator): void {
    this.dataSource.paginator = paginator;
  }

  private getColumnsToDisplay(): Array<keyof ColumnDefinition> {
    return Object.keys(this.columnsDef);
  }

  private subscribeSelectionChange(): void {
    this.itemSelections.changed.pipe(untilDestroyed(this)).subscribe((s) => {
      this.updateMainCheckBoxState();
    });
  }

  private updateMainCheckBoxState() {
    const areAllSelected =
      this.itemSelections.selected.length ===
      Coerce.toArray(this._configuration.items).filter((item) => item.isShown)
        .length;
    this.areAllSelected$.next(areAllSelected);
  }

  private refreshSelections(): void {
    this.data
      .filter((item) => item.isSelected)
      .map((item) => this.itemSelections.select(item.id));
    this.updateMainCheckBoxState();
  }

  private refreshFilters(data: TableConfiguration<any>): void {
    this.filters$.next(
      this.simpleFiltersService.createFilterOptions(
        Coerce.toArray(data.items).map((item) => item.value),
        data.filtersToExclude,
      ),
    );
  }

  private buildGroups(
    groups: SelectionGroups,
    items: TableItems<any>,
  ): SelectionGroups {
    return [
      {
        labelKey: 'header',
        id: 'header',
      },
      ...groups.filter((group) => {
        return items.some((item) => item.groupId === group.id);
      }),
    ];
  }

  constructor(private simpleFiltersService: SimpleFiltersService) {
    super(null, null);
  }

  ngOnChanges(): void {
    this.columnDefinitions$.next(this.getColumnDefinitions());
    this.columnsToDisplay$.next(this.getColumnsToDisplay());
  }

  ngAfterViewInit(): void {
    this.subscribeSelectionChange();
    this.refreshPagination(this.paginator);
    // this.subscribeToGroupSelectionChanges();
  }
}

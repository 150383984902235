import { query, style, transition, trigger, useAnimation } from '@angular/animations';
import { fadeIn } from 'ng-animate';

export const parentRouteAnimation = trigger('parentRouteAnimation', [
  transition('* => *', [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          width: '100%',
          opacity: 0,
          transform: 'scale(0) translateY(100%)'
        })
      ],
      { optional: true }
    ),
    query(':enter',
      [
        useAnimation(
          fadeIn,
          { params: { timing: 0.300 } }
        )
      ],
      { optional: true })
  ])
]);

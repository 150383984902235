export type CampaignExportModel = Readonly<{
  clientId: string;
  exportId: string;
  description?: string;
  owner: string;
  createdAt: string;
  updatedAt?: string;
  updatedBy?: string;
  status: CAMPAIGN_EXPORT_STATUS;
  stages: CampaignStageMap[];
}>;

export type CampaignStageMap = {
  name: CAMPAIGN_EXPORT_STATUS;
  details: Record<string, any>;
};

export type CampaignStageDetails = Readonly<{
  total: string;
  done: string;
}>;

export enum CAMPAIGN_EXPORT_STATUS {
  SUCCESS = 'succeeded',
  FAILED = 'failed',
  ONGOING = 'ongoing',
  ERROR_CANCELLING = 'error_on_cancelling',
  FAILED_CANCELLING = 'failed_cancelling',
  ONGOING_CANCELLING = 'ongoing_cancelling',
  CANCELLED = 'cancelled',
  INITIALIZATION = 'initialize',
  PROCESSING_CAMPAIGNS = 'process_campaigns',
  CONSOLIDATION = 'consolidate',
  EXCEL_GENERATION = 'excel_generate',
  EXCEL_DOWNLOAD = 'excel_download',
}

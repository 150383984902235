// import { makeClientEntityStateReducer } from '../entities.reducer';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { BinDataActions } from './bindata.actions';

export const BIN_DATA_FEATURE_NAME = 'BinData';

export interface BindataState extends EntityState<any> {
  loaded: boolean;
}

export const bindataAdapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialBindataState: BindataState = bindataAdapter.getInitialState(
  {
    loaded: false,
  },
);

export const binDataStateReducer = createReducer(
  initialBindataState,
  on(
    BinDataActions(BIN_DATA_FEATURE_NAME).downloadAllBindataAction,
    (state) => {
      return { ...state, loaded: false };
    },
  ),
  on(
    BinDataActions(BIN_DATA_FEATURE_NAME).downloadAllBindataNOKAction,
    (state) => {
      return { ...state, loaded: false };
    },
  ),
  on(
    BinDataActions(BIN_DATA_FEATURE_NAME).downloadAllBindataOKAction,
    (state) => {
      return { ...state, loaded: true };
    },
  ),
);

export const BinDataEntityStore = {
  featureName: BIN_DATA_FEATURE_NAME,
  reducer: binDataStateReducer,
};

import { combineLatest, Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { ReleaseNotesFacadeService } from '../store';

@Injectable()
export class ReleaseNotesResolver implements Resolve<any> {
  constructor(private releaseNotesFacade: ReleaseNotesFacadeService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return combineLatest([
      this.releaseNotesFacade.loaded$.pipe(first()),
      this.releaseNotesFacade.loading$.pipe(first()),
    ]).pipe(
      map((loaded) => loaded.includes(true)),
      tap((loaded) => !loaded && this.releaseNotesFacade.get()),
    );
  }
}

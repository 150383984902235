import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Coerce, createErrorObject } from 'ssotool-app/shared';

import { Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ResultExportService } from './result-export-api.service';
import {
  ProcessNotificationDispatchAction,
  ResultExportEntityActions,
} from './result-export.actions';
import { ResultExportNotification } from './result-export.model';

@Injectable()
export class ResultExportEffects {
  constructor(
    private actions$: Actions,
    private service: ResultExportService,
    private toast: HotToastService,
  ) {}

  getList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResultExportEntityActions.getList),
      mergeMap(({ clientId }) =>
        this.service.getList(clientId).pipe(
          map((data) => ResultExportEntityActions.getListSuccess({ data })),
          catchError(() =>
            of(ResultExportEntityActions.getListFailure({ clientId })),
          ),
        ),
      ),
    ),
  );

  createExportDetailedResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResultExportEntityActions.createExportDetailedResults),
      mergeMap(({ clientId, roadmapIds }) =>
        this.service.createDetailedExport(clientId, roadmapIds).pipe(
          map((data) =>
            ResultExportEntityActions.createExportDetailedResultsSuccess({
              data,
            }),
          ),
          catchError(() =>
            of(
              ResultExportEntityActions.createExportDetailedResultsFailure({
                clientId,
                roadmapIds,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  createExportDetailedResultsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResultExportEntityActions.createExportDetailedResultsSuccess),
      ),
    { dispatch: false },
  );

  createExportDetailedResultsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResultExportEntityActions.createExportDetailedResultsFailure),
        tap(() =>
          this.toast.error('Exporting detailed roadmap results error!'),
        ),
      ),
    { dispatch: false },
  );

  createExportSummaryResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResultExportEntityActions.createExportSummaryResults),
      mergeMap(({ clientId, roadmapIds }) =>
        this.service.createSummaryExport(clientId, roadmapIds).pipe(
          map((data) =>
            ResultExportEntityActions.createExportSummaryResultsSuccess({
              data,
            }),
          ),
          catchError(() =>
            of(
              ResultExportEntityActions.createExportSummaryResultsFailure({
                clientId,
                roadmapIds,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  createExportSummaryResultsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResultExportEntityActions.createExportSummaryResultsSuccess),
        tap(() =>
          this.toast.info('Exporting summary roadmap results started.'),
        ),
      ),
    { dispatch: false },
  );

  createExportSummaryResultsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResultExportEntityActions.createExportSummaryResultsFailure),
        tap(() => this.toast.error('Exporting summary roadmap results error!')),
      ),
    { dispatch: false },
  );

  processNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResultExportEntityActions.processNotification),
      switchMap((action) => {
        const dispatchActions: Array<ProcessNotificationDispatchAction> = [
          ResultExportEntityActions.updateExportDetails({
            data: this.service.mapNotificationToFrontend(action.data),
          }),
        ];
        this.downloadResults(action.data, dispatchActions);
        return dispatchActions;
      }),
    ),
  );

  downloadResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResultExportEntityActions.downloadResult),
      mergeMap((action) =>
        this.service.downloadResult(action.data).pipe(
          map(() => ResultExportEntityActions.downloadResultSuccess()),
          catchError(() =>
            of(ResultExportEntityActions.downloadResultFailure()),
          ),
        ),
      ),
    ),
  );

  cancelExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResultExportEntityActions.cancelExport),
      mergeMap(({ clientId, exportId }) =>
        this.service.cancelExport(clientId, exportId).pipe(
          map((response) =>
            ResultExportEntityActions.cancelExportSuccess({
              clientId,
              exportId,
            }),
          ),
          catchError((error) =>
            of(
              ResultExportEntityActions.cancelExportFailure({
                clientId,
                exportId,
                ...createErrorObject(
                  error.error,
                  'Cancel export roadmap results error!',
                ),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  cancelExportSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResultExportEntityActions.cancelExportSuccess),
      tap(() => this.toast.info('Export roadmap results cancelled.')),
      map((action) =>
        ResultExportEntityActions.getList({ clientId: action.clientId }),
      ),
    ),
  );

  cancelExportFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResultExportEntityActions.cancelExportFailure),
        tap(({ message }) => this.toast.error(message)),
      ),
    { dispatch: false },
  );

  private downloadResults(
    data: ResultExportNotification,
    dispatchActions: ProcessNotificationDispatchAction[],
  ): void {
    if (this.service.canDownloadResult(data.stage, data.status)) {
      dispatchActions.push(
        ResultExportEntityActions.downloadResult({
          data: Coerce.toObject(data.stage).details,
        }),
      );
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { PushPipe } from '@ngrx/component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { BaseDrawerComponent } from './base-drawer.component';
import { BreadcrumbModule } from 'ssotool-app/shared';
import { CampaignDrawerModule } from './campaign-drawer';
import { ProfilesDrawerModule } from './profiles-drawer/profiles-drawer.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [BaseDrawerComponent],
  exports: [BaseDrawerComponent],
  imports: [
    CommonModule,
    CampaignDrawerModule,
    ProfilesDrawerModule,
    PushPipe,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    MatDividerModule,
    MatButtonModule,
    FlexLayoutModule,
    BreadcrumbModule,
    TranslateModule,
  ],
})
export class BaseDrawerModule {}

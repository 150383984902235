import { Coerce } from 'ssotool-app/shared/helpers';

import {
  FilterConditionValue as condition,
  FiltersDialogConditionMode as mode,
} from './filters-dialog.model';

export const FiltersDialogConditionOptions: Record<mode, condition[]> = {
  [mode.DIRECT]: [condition.IS, condition.IS_NOT],
  [mode.WITH_GROUP]: [condition.IS, condition.IS_NOT, condition.IS_PART_OF],
  [mode.GROUP_ONLY]: [condition.IS_PART_OF],
  [mode.ALL]: Coerce.getObjValues(condition),
};

import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, skipWhile, take } from 'rxjs/operators';
import { ClientDataEntity } from 'ssotool-app/+client/store';
import { ClientFacadeService } from 'ssotool-client';
import { generateEndpoint } from 'ssotool-core/utils';
import { Coerce, YearlyValuesMapper } from 'ssotool-shared';
import { ConfigService } from 'ssotool-shared/services/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { buildCompositeKey } from '../ambition-page.references';
import { BackendTargetModel, TargetModel } from '../store/ambition.model';

@Injectable()
export class AmbitionService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private clientFacade: ClientFacadeService,
  ) {}

  // TODO: Refactor mergeMap line 32 to line 59 for getList and other similar implementation

  getList(clientId: string): Observable<TargetModel[]> {
    const { baseUrl, endpoints } = this.config.api;
    return this.http
      .get<BackendTargetModel[]>(
        generateEndpoint(baseUrl, endpoints.ambition.listConstraints, clientId),
      )
      .pipe(
        take(1),
        mergeMap((data) => {
          if (data.length === 0) {
            return of(data);
          }
          return this.clientFacade.dataLoaded$(clientId).pipe(
            skipWhile((loaded) => !loaded),
            mergeMap(() =>
              combineLatest([
                this.clientFacade.selectGeos$(clientId),
                this.clientFacade.selectSites$(clientId),
                this.clientFacade.selectCompanies$(clientId),
                this.clientFacade.selectSectors$(clientId),
                this.clientFacade.selectProcesses$(clientId),
                this.clientFacade.selectFluids$(clientId),
                this.clientFacade.selectQuantities$(clientId),
              ]).pipe(
                map((clientEntities) => {
                  if (data.length === 0) {
                    return data;
                  }
                  return data.map((_d) =>
                    this.mapTargetToFrontEnd(_d, clientEntities),
                  );
                }),
              ),
            ),
          );
        }),
        catchError((error) => throwError(error)),
      );
  }

  updateTargets(
    clientId: string,
    constraints: { constraintId: string; isTarget: boolean }[],
  ): Observable<any> {
    const { baseUrl, endpoints } = this.config.api;
    return this.http
      .post(
        generateEndpoint(baseUrl, endpoints.ambition.updateTargets, clientId),
        {
          constraints,
        },
      )
      .pipe(
        map((response) => response as string),
        catchError((error) => throwError(error)),
      );
  }

  createCustomTarget(clientId: string, data: any): Observable<TargetModel> {
    const { baseUrl, endpoints } = this.config.api;
    return this.http
      .post(
        generateEndpoint(
          baseUrl,
          endpoints.ambition.createCustomTarget,
          clientId,
        ),
        this.mapTargetToBackend(data),
      )
      .pipe(
        take(1),
        mergeMap((data) =>
          this.clientFacade.dataLoaded$(clientId).pipe(
            skipWhile((loaded) => !loaded),
            mergeMap(() =>
              combineLatest([
                this.clientFacade.selectGeos$(clientId),
                this.clientFacade.selectSites$(clientId),
                this.clientFacade.selectCompanies$(clientId),
                this.clientFacade.selectSectors$(clientId),
                this.clientFacade.selectProcesses$(clientId),
                this.clientFacade.selectFluids$(clientId),
                this.clientFacade.selectQuantities$(clientId),
              ]).pipe(
                map((clientEntities) =>
                  this.mapTargetToFrontEnd(data, clientEntities),
                ),
              ),
            ),
            catchError((error) => throwError(error)),
          ),
        ),
      );
  }

  editCustomTarget(clientId: string, data: any): Observable<TargetModel> {
    const { baseUrl, endpoints } = this.config.api;
    return this.http
      .post(
        generateEndpoint(
          baseUrl,
          endpoints.ambition.editCustomTarget,
          clientId,
          data?.constraintId,
        ),
        this.mapTargetToBackend(data),
      )
      .pipe(
        take(1),
        mergeMap((data) =>
          this.clientFacade.dataLoaded$(clientId).pipe(
            skipWhile((loaded) => !loaded),
            mergeMap(() =>
              combineLatest([
                this.clientFacade.selectGeos$(clientId),
                this.clientFacade.selectSites$(clientId),
                this.clientFacade.selectCompanies$(clientId),
                this.clientFacade.selectSectors$(clientId),
                this.clientFacade.selectProcesses$(clientId),
                this.clientFacade.selectFluids$(clientId),
                this.clientFacade.selectQuantities$(clientId),
              ]).pipe(
                map((clientEntities) =>
                  this.mapTargetToFrontEnd(data, clientEntities),
                ),
              ),
            ),
            catchError((error) => throwError(error)),
          ),
        ),
      );
  }

  deleteCustomTarget(clientId: string, constraintId: string) {
    const { baseUrl, endpoints } = this.config.api;
    return this.http
      .delete(
        generateEndpoint(
          baseUrl,
          endpoints.ambition.editCustomTarget,
          clientId,
          constraintId,
        ),
      )
      .pipe(
        take(1),
        mergeMap((data) =>
          this.clientFacade.dataLoaded$(clientId).pipe(
            skipWhile((loaded) => !loaded),
            mergeMap(() =>
              combineLatest([
                this.clientFacade.selectGeos$(clientId),
                this.clientFacade.selectSites$(clientId),
                this.clientFacade.selectCompanies$(clientId),
                this.clientFacade.selectSectors$(clientId),
                this.clientFacade.selectProcesses$(clientId),
                this.clientFacade.selectFluids$(clientId),
                this.clientFacade.selectQuantities$(clientId),
              ]).pipe(
                map((clientEntities) =>
                  this.mapTargetToFrontEnd(data, clientEntities),
                ),
              ),
            ),
            catchError((error) => throwError(error)),
          ),
        ),
      );
  }

  mapTargetToBackend(data: any): any {
    return !!data
      ? {
          constraintId: data.constraintId,
          isTarget: data.isTarget,
          isCustom: data.isCustom,
          clientId: data.clientId,
          owner: data.owner,
          name: data.name,
          scenarioName: data.scenarioName,
          geoId: data.geoId,
          siteId: data.siteId,
          companyEntityId: data.companyEntityId,
          sectorId: data.sectorId,
          fluidId: data.fluidId,
          processId: data.processId,
          quantityId: data.quantityId,
          indicator: data.indicator,
          values: YearlyValuesMapper.mapToBackend(data.values),
        }
      : {};
  }

  mapTargetToFrontEnd(
    data: any,
    [
      geos,
      sites,
      companies,
      sectors,
      processes,
      fluids,
      quantities,
    ]: ClientDataEntity[],
  ): any {
    if (data) {
      return {
        constraintId: data.constraintId,
        isTarget: data.isTarget,
        isCustom: data.isCustom,
        clientId: data.clientId,
        owner: data.owner,
        name: data.name,
        scenarioName: data.scenarioName,
        geoId: data.geoId,
        siteId: data.siteId,
        companyEntityId: data.companyEntityId,
        sectorId: data.sectorId,
        fluidId: data.fluidId,
        processId: data.processId,
        quantityId: data.quantityId,
        indicator: data.indicator,
        values: YearlyValuesMapper.mapToFrontend(data.values),
        geoName:
          this.getGeoName(geos, data.geoId) ||
          this.getGeoName(sites, data.siteId),
        companyName: companies?.[data?.companyEntityId]?.name,
        sectorName: sectors?.[data?.sectorId]?.name,
        processName: processes?.[data?.processId]?.name,
        fluidName: fluids?.[data?.fluidId]?.name,
        quantityName: quantities?.[data?.quantityId]?.name,
        unit: quantities?.[data?.quantityId]?.unit,
        compositeKey: buildCompositeKey(data),
      };
    }
    return {};
  }

  private getGeoName(
    geo: ClientDataEntity['geos'] | ClientDataEntity['sites'],
    id: ClientDataEntity['geoId'] | ClientDataEntity['siteId'],
  ) {
    if (!geo) {
      return null;
    }
    return Coerce.toEmptyObject(geo[id]).name;
  }
}

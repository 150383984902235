import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { ReleaseNotesActions } from './release-notes.action';
import { ReleaseModel } from './release-notes.model';

export const releaseNoteFeatureKey = 'ReleaseNotes';
export const TEMPORARY_ID = '1';

export interface ReleaseNotesState extends EntityState<ReleaseModel> {
  loading: boolean;
  error: string;
  message: string;
  loaded: boolean;
  progress: number;
}

export const releaseNotesAdapter: EntityAdapter<ReleaseModel> =
  createEntityAdapter<ReleaseModel>({});

export const initialReleaseNotesState: ReleaseNotesState =
  releaseNotesAdapter.getInitialState({
    loading: false,
    error: undefined,
    message: undefined,
    loaded: false,
    progress: 0,
  });

const releaseNotesReducer = createReducer(
  initialReleaseNotesState,
  on(ReleaseNotesActions.get, (state) => ({
    ...state,
    loading: true,
    progress: 0,
    error: undefined,
  })),
  on(ReleaseNotesActions.getSuccess, (state, { data, message }) =>
    releaseNotesAdapter.upsertOne(data, {
      ...state,
      loading: false,
      progress: 100,
      loaded: true,
      message,
    }),
  ),
  on(ReleaseNotesActions.getFailure, (state, { error, message }) => ({
    ...state,
    loading: false,
    progress: 0,
    error,
    message,
    loaded: false,
  })),
  on(ReleaseNotesActions.updateLoadedProgress, (state, { progress }) => ({
    ...state,
    progress,
  })),
  on(ReleaseNotesActions.update, (state) => ({
    ...state,
    loading: true,
    progress: 0,
    error: undefined,
  })),

  on(ReleaseNotesActions.updateFailure, (state, { error, message }) => ({
    ...state,
    loading: false,
    progress: 0,
    error,
    message,
    loaded: false,
  })),
);

export function ReleaseNotesReducer(state: ReleaseNotesState, action: Action) {
  return releaseNotesReducer(state, action);
}

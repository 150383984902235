<form [formGroup]="form" fxLayout="column" fxLayoutGap="10px">
  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start">
    <div
      fxLayout="column"
      fxLayoutGap="10px"
      fxLayoutAlign="center center"
      *ngIf="!viewMode"
    >
      <div *ngIf="(fluids$ | async)?.length > 0">
        <sso-fluids-toggle-group
          [viewMode]="viewMode"
          formControlName="fluidToggle"
          [fluids]="fluids$ | async"
          [layout]="'column'"
          (remove)="removeFluid($event)"
          (select)="selectMainFluid($event)"
        >
        </sso-fluids-toggle-group>
      </div>

      <sso-fluids-menu-button
        icon="add"
        color="primary"
        [label]="label"
        [disabled]="(fluidOptions$ | async)?.length === 0"
        [options]="fluidOptions$ | async"
        (selectOption)="selectFluidOption($event)"
      >
      </sso-fluids-menu-button>
    </div>

    <div [fxLayout]="viewMode ? 'row wrap' : 'column'" fxLayoutGap="10px">
      <div [formGroup]="fluid" *ngFor="let fluid of fluidsForm.controls">
        <sso-yearly-input
          formControlName="efficiencies"
          [startYear]="startYear"
          [endYear]="endYear"
          [errorMessage]="''"
          [readonly]="viewMode"
          [label]="
            viewMode ? (fluidReferences$ | async)[fluid?.value?.fluidId] : ''
          "
          [mainInput]="
            (fluidReferences$ | async)[fluid?.value?.fluidId] === mainInput ||
            fluid?.value?.fluidId === mainInput
          "
          [unit]="unit"
          [hideRequiredMarker]="true"
        ></sso-yearly-input>
        <div fxLayout="column" fxLayoutAlign="end center">
          <span *ngIf="">MAIN</span>
        </div>
      </div>
    </div>
  </div>
</form>

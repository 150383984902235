import { isFeatureEnabled } from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';

export const READONLY_COLUMNS = [
  { name: 'name', label: 'Name' },
  { name: 'type', label: 'Type' },
  { name: 'pathway', label: 'Pathway' },
  {
    name: isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE)
      ? 'displayedGeo'
      : 'geography',
    label: 'Site / Geography',
  },
];
export const FILTER_COLUMNS = ['name', 'type', 'pathway'];

export const COLUMNS = [{ name: 'select', label: '' }].concat(READONLY_COLUMNS);

import { ProfilesFormModule } from 'ssotool-app/shared';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

import { ProfilesDrawerComponent } from './profiles-drawer.component';
import { TranslateModule } from '@ngx-translate/core';
import { PushPipe } from '@ngrx/component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [ProfilesDrawerComponent],
  exports: [ProfilesDrawerComponent],
  imports: [
    CommonModule,
    ProfilesFormModule,
    ReactiveFormsModule,
    PushPipe,
    MatButtonModule,
    TranslateModule,
    FlexLayoutModule,
  ],
})
export class ProfilesDrawerModule {}

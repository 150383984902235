import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  Coerce,
  download,
  DownloadContent,
  downloadFile,
} from 'ssotool-app/shared';
import { generateEndpoint } from 'ssotool-core/utils';
import { ConfigService } from 'ssotool-shared/services/config';

import { formatDate } from '@angular/common';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID, RendererFactory2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
  ResultExportDownloadingStageDetails,
  ResultExportModel,
  ResultExportNotification,
  ResultExportNotificationUpdateDetails,
  ResultExportStage,
  ResultExportStageMap,
  ResultExportStatus,
} from './result-export.model';

@Injectable()
export class ResultExportService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private renderer: RendererFactory2,
    private translateService: TranslateService,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  getList(clientId: string): Observable<Array<ResultExportModel>> {
    return this.http
      .get<Array<ResultExportModel>>(this.makeGetListEndpoint(clientId))
      .pipe(
        map((data) => data.map((datum) => this.mapToFrontend(datum))),
        catchError((error) => throwError(error)),
      );
  }

  createDetailedExport(
    clientId: string,
    roadmapIds: Array<string>,
  ): Observable<ResultExportModel> {
    const { endpoints } = this.config.api;
    return this.http
      .post<ResultExportModel>(
        this.makeCreateExportEndpoint(
          clientId,
          endpoints.result.createDetailedExport,
        ),
        {
          roadmapIds,
        },
      )
      .pipe(
        map((data) => this.mapToFrontend(data)),
        catchError((error) => throwError(error)),
      );
  }

  createSummaryExport(
    clientId: string,
    roadmapIds: Array<string>,
  ): Observable<ResultExportModel> {
    const { endpoints } = this.config.api;
    return this.http
      .post<ResultExportModel>(
        this.makeCreateExportEndpoint(
          clientId,
          endpoints.result.createSummaryExport,
        ),
        {
          roadmapIds,
        },
      )
      .pipe(
        map((data) => this.mapToFrontend(data)),
        catchError((error) => throwError(error)),
      );
  }

  canDownloadResult(
    stage: ResultExportStageMap,
    status: ResultExportStatus,
  ): boolean {
    return (
      ResultExportStage.canDownload(Coerce.toObject(stage)) &&
      ResultExportStatus.canDownload(status)
    );
  }

  downloadResult(
    data: ResultExportDownloadingStageDetails,
  ): Observable<DownloadContent> {
    return this.http
      .get(data.signedUrl, {
        reportProgress: true,
        observe: 'events',
        responseType: 'blob',
      })
      .pipe(
        download(data.filesize),
        downloadFile(this.renderer, data.filename),
        catchError((error) => throwError(error)),
      );
  }

  cancelExport(
    clientId: string,
    exportId: string,
  ): Observable<HttpResponse<void>> {
    return this.http
      .get<HttpResponse<void>>(
        this.makeCancelExportEndpoint(clientId, exportId),
      )
      .pipe(
        map((response) => response),
        catchError((error) => throwError(error)),
      );
  }

  mapNotificationToFrontend(
    data: ResultExportNotification,
  ): ResultExportNotificationUpdateDetails {
    return {
      exportId: data.exportId,
      status: data.status,
      stage: data.stage,
      exporting: ResultExportStatus.isOngoing(data.status),
      stageMessage: this.retrieveExportStatusMessage(data.stage),
    };
  }

  private mapToFrontend(data: ResultExportModel): ResultExportModel {
    return {
      ...data,
      createdAt: this.formatDate(data.createdAt),
      updatedAt: this.formatDate(data.updatedAt),
      exporting: ResultExportStatus.isOngoing(data.status),
      stageMessage: this.retrieveExportStatusMessage(data.stage),
    };
  }

  private retrieveExportStatusMessage(stage: ResultExportStageMap): string {
    const translation = Coerce.toString(
      ResultExportStageMap.getMessage(Coerce.toObject(stage).name),
      'Roadmap.messages.exportingResults.indicator',
    );
    return this.translateService.instant(
      translation,
      ResultExportStageMap.getDetails(stage),
    ) as string;
  }

  private makeGetListEndpoint(clientId: string): string {
    const { baseUrl, endpoints } = this.config.api;
    return generateEndpoint(baseUrl, endpoints.result.getList, clientId);
  }

  private makeCreateExportEndpoint(clientId: string, endpoint: string) {
    const { baseUrl } = this.config.api;
    return generateEndpoint(baseUrl, endpoint, clientId);
  }

  private makeCancelExportEndpoint(clientId: string, exportId: string) {
    const { baseUrl, endpoints } = this.config.api;
    return generateEndpoint(
      baseUrl,
      endpoints.result.cancelExport,
      clientId,
      exportId,
    );
  }

  private formatDate(dateString: string): string {
    return formatDate(dateString, 'dd MMM YYYY HH:mm', this.locale);
  }
}

<div mat-dialog-title fxLayoutGap="10px" fxLayout="row">
  <mat-icon aria-hidden="false">insert_chart_outlined</mat-icon>
  <h2 class="mat-headline-6">{{ data?.name | titlecase }} Time Series</h2>
</div>
<sso-tabbed-bar-chart
  [loading]="loading$ | async"
  [yearlyTabs]="yearlyTabs"
  [seriesDataList]="yearlyChartValues$ | async"
  (onTabChange)="onTabChange($event)"
  [chartType]="data.granularity"
  [from]="data.chartProps.floor"
  [to]="data.chartProps.ceil"
  [floor]="data.chartProps.floor"
  [ceiling]="data.chartProps.ceil"
  [minRange]="data.chartProps.minRange"
  [maxRange]="data.chartProps.maxRange"
></sso-tabbed-bar-chart>
<div mat-dialog-actions fxLayout="row">
  <button (click)="onClose()" color="" mat-flat-button type="button">
    Close
  </button>
</div>

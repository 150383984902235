import { ChartData, Plugin } from 'chart.js';
import Chart from 'chart.js/auto';
import { cloneDeep } from 'lodash';
import { ELLIPSE_CURVE_COLORS } from 'ssotool-app/app.references';
import {
  clearLegend,
  generateHTMLLegendPlugin,
  LegendData,
} from 'ssotool-app/shared/charts.plugins';
import { Coerce } from 'ssotool-app/shared/helpers';
import { ColorSchemeService } from 'ssotool-shared/services';

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { PieMetadata, PieSlice } from './pie-chart.model';
import { pieLabelPlugin } from './pie-label/plugin';

@Component({
  selector: 'sso-pie-chart-component',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ColorSchemeService],
})
export class PieChartComponent {
  @ViewChild('pieChartCanvas', { static: true }) pieChartCanvas: ElementRef;
  @ViewChild('toolTip', { static: true }) toolTip: ElementRef;
  @Input() set metadata(meta: PieMetadata[]) {
    this._meta = cloneDeep(meta);
    this.metadataCount = this._meta.length;
    this.initializeMetadata();
  }

  @Output() legendClick = new EventEmitter<{
    name: string;
    color: string;
  }>();
  // testing observables
  @Output() metadataChanges$ = new EventEmitter<PieMetadata>();
  @Output() colorSchemeChanges$ = new EventEmitter();
  @Output() chartJSDataChanges$ = new EventEmitter<{
    labels: string[];
    datasets: unknown;
  }>();

  @Input() diffByOpacity: boolean = true;
  private opacityProgression: number = 0.4;

  pieChart: Chart[];
  metadataCount: number;

  private _meta: PieMetadata[];
  private colorsFromBase: string[];
  private total: string;
  private unit: string;
  private colorMap: Record<string, string> = {};
  private legendId = 'pie-legend';

  constructor(private schemer: ColorSchemeService) {}

  private initializeMetadata() {
    this.cleanup();
    if (this._meta.length === 1) {
      this.total = Number(this._meta[0]?.total).toLocaleString(undefined, {
        maximumFractionDigits: 2,
      });
      this.unit = this._meta[0]?.unit;
    }

    if (this._meta) {
      this.pieChart = this.toChartJS(this._meta);
    }
  }

  cleanup(): void {
    /* istanbul ignore else */
    const activeCharts = Chart.instances;
    if (activeCharts) {
      Coerce.getObjValues(activeCharts).map((chart) => {
        chart.destroy();
      });
    }
    const main = document.getElementById('main');
    if (main?.firstChild) {
      main.innerHTML = '';
    }
    const otherPie = document.getElementById('children');
    if (otherPie?.firstChild) {
      otherPie.innerHTML = '';
    }
    this.colorMap = {};
    this.colorsFromBase = [];

    clearLegend(this.legendId);
  }

  private toChartJSData(
    metaData: PieMetadata,
    index: number = 0,
  ): {
    labels: string[];
    datasets: unknown[];
  } {
    const labels = metaData.slices.map((slice) => slice.name);
    const colors = this.mapColors(metaData.slices).map((color) =>
      this.appendOpacityToColor(index, color),
    );
    const datasets = [
      {
        label: 'Data',
        data: metaData.slices.map((slice) => slice.value),
        backgroundColor: colors,
        hoverOffset: 4,
      },
    ];

    const chartData = {
      labels,
      datasets,
    };

    return chartData;
  }

  private appendOpacityToColor(level: number, color: string) {
    return this.diffByOpacity ? color + this.getOpacity(level) : color;
  }

  private getOpacity(level: number): string {
    const FULL_OPACITY = 0xff;
    return Math.floor(
      FULL_OPACITY * (1 - level * this.opacityProgression),
    ).toString(16);
  }

  private mapColors(slices: PieSlice[]): string[] {
    const finalColor = [];
    slices.forEach((slice) => {
      const value = slice.name;
      this.colorMap[value] =
        slice.color ||
        (this.colorMap && this.colorMap[value]
          ? this.colorMap[value]
          : this.colorsFromBase.shift());
      finalColor.push(this.colorMap[value]);
    });
    return finalColor;
  }

  private toChartJS(metadata: PieMetadata[]): Chart[] {
    this.colorsFromBase = this.schemer.getBaseColors();
    return metadata.map((value, index) => {
      let containerId = 'main';
      let canvasId = 'pie';
      let displayLegend = false;
      if (index !== 0) {
        canvasId = `pie_${index}`;
        containerId = 'children';
      }
      this.createCanvas(containerId, canvasId);

      const context = (document.getElementById(canvasId) as any)?.getContext(
        '2d',
      );
      if (!context) {
        return;
      }

      const options = {
        responsive: false,
        layout: {
          padding: 100,
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: !displayLegend && value.name ? true : false,
            text: value.name,
            position: 'bottom' as any,
            padding: {
              top: 40,
            },
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: function (context) {
                return `${context.label}: ${context.formattedValue} ${value.unit}`;
              },
            },
          },
          $arrowlabels: {
            display: (n) => !!this.getPercent(n.percent * 100),
            backgroundColor: 'white',
            percentPrecision: 2,
            color: 'black',
            stretch: 20,
            font: {
              resizable: true,
              minSize: 12,
              maxSize: 14,
            },
            lineWidth: 1,
            padding: 1,
          },
        },
      };

      if (value?.baseColor) {
        this.colorsFromBase = this.schemer.getBaseColors();
      } else {
        this.generateColors(value?.slices.length);
      }

      const chartData = this.toChartJSData(value, index);

      this.chartJSDataChanges$.emit({ ...chartData, ...{ name: 'pie' } });

      const pieChart = new Chart(context, {
        type: 'pie',
        data: chartData as ChartData,
        options,
        plugins: [
          pieLabelPlugin as unknown as Plugin,
          generateHTMLLegendPlugin(this.legendId, this.openDialog.bind(this)),
        ],
      });
      return pieChart;
    });
  }

  openDialog(selected: LegendData) {
    this.legendClick.emit(selected);
  }

  getPercent(num: Number): Number {
    return Number(Math.round(Number(`${num}e+3`)) + 'e-3');
  }

  private generateColors(slicesLength: number) {
    if (slicesLength > this.colorsFromBase.length) {
      let colorCount = 0;
      let idx = 1;
      let increment = 2;
      while (slicesLength !== this.colorsFromBase.length) {
        if (colorCount > this.schemer.getBaseColors().length - 1) {
          colorCount = 0;
          idx = 0;
          increment++;
        }
        this.colorsFromBase.splice(
          idx,
          0,
          this.schemer.generateAdjacentScheme(
            ELLIPSE_CURVE_COLORS[colorCount],
            2,
          )[1],
        );
        idx = idx + increment;
        ++colorCount;
      }
    }
  }

  private createCanvas(containerId: string, canvasId: string): void {
    if (!!!document.getElementById(canvasId)) {
      const legends = document.getElementById(containerId);
      const canvas = document.createElement('canvas');
      canvas.id = canvasId;
      const size = this.metadataCount <= 2 ? '700px' : '500px';
      canvas.style.width = containerId === 'main' ? '1000px' : size;
      canvas.style.height = containerId === 'main' ? '700px' : size;

      legends?.appendChild(canvas);
    }
  }
}

import { CampaignEnergyEfficiency } from 'ssotool-app/+campaign/store';
import { YearlyValuesMapper } from 'ssotool-shared';

import { CampaignBaseMapper } from './campaign-base.mapper';

export class EnergyEfficiencyMapper {
  static mapToFrontend = (data: any): CampaignEnergyEfficiency =>
    !!data
      ? ({
          ...CampaignBaseMapper.mapToFrontend(data),
          origEELabel: data.origEELabel,
          finalEELabel: data.finalEELabel,
          processId: data.processId,
          fluidId: data.fluidId,
          metricId: data.metricId,
          savings: YearlyValuesMapper.mapToFrontend(data.savings),
          incrementalSavings: YearlyValuesMapper.mapToFrontend(
            data.incrementalSavings,
          ),
          technicalLife: YearlyValuesMapper.mapToFrontend(data.technicalLife),
          depreciationTime: YearlyValuesMapper.mapToFrontend(
            data.depreciationTime,
          ),
          implementationTime: YearlyValuesMapper.mapToFrontend(
            data.implementationTime,
          ),
          buildCo2Scope3Emissions: YearlyValuesMapper.mapToFrontend(
            data.buildCo2Scope3Emissions,
          ),
          indivisibleCo2Scope3Emissions: YearlyValuesMapper.mapToFrontend(
            data.indivisibleCo2Scope3Emissions,
          ),
          buildCost: YearlyValuesMapper.mapToFrontend(data.buildCost),
          indivisibleCost: YearlyValuesMapper.mapToFrontend(
            data.indivisibleCost,
          ),
          incrementalBuildCost: YearlyValuesMapper.mapToFrontend(
            data.incrementalBuildCost,
          ),
          incrementalIndivisibleCost: YearlyValuesMapper.mapToFrontend(
            data.incrementalIndivisibleCost,
          ),
          foM: YearlyValuesMapper.mapToFrontend(data.foM),
          foMPerInstall: YearlyValuesMapper.mapToFrontend(data.foMPerInstall),
        } as any)
      : {};

  static mapToBackend = (data: CampaignEnergyEfficiency) =>
    !!data
      ? {
          ...CampaignBaseMapper.mapToBackend(data),
          origEELabel: data.origEELabel,
          finalEELabel: data.finalEELabel,
          processId: data.processId,
          fluidId: data.fluidId,
          metricId: data.metricId,
          savings: YearlyValuesMapper.mapToBackend(data.savings),
          incrementalSavings: YearlyValuesMapper.mapToBackend(
            data.incrementalSavings,
          ),
          technicalLife: YearlyValuesMapper.mapToBackend(data.technicalLife),
          depreciationTime: YearlyValuesMapper.mapToBackend(
            data.depreciationTime,
          ),
          implementationTime: YearlyValuesMapper.mapToBackend(
            data.implementationTime,
          ),
          buildCo2Scope3Emissions: YearlyValuesMapper.mapToBackend(
            data.buildCo2Scope3Emissions,
          ),
          indivisibleCo2Scope3Emissions: YearlyValuesMapper.mapToBackend(
            data.indivisibleCo2Scope3Emissions,
          ),
          buildCost: YearlyValuesMapper.mapToBackend(data.buildCost),
          indivisibleCost: YearlyValuesMapper.mapToBackend(
            data.indivisibleCost,
          ),
          incrementalBuildCost: YearlyValuesMapper.mapToBackend(
            data.incrementalBuildCost,
          ),
          incrementalIndivisibleCost: YearlyValuesMapper.mapToBackend(
            data.incrementalIndivisibleCost,
          ),
          foM: YearlyValuesMapper.mapToBackend(data.foM),
          foMPerInstall: YearlyValuesMapper.mapToBackend(data.foMPerInstall),
        }
      : {};
}

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client';
import { Coerce } from 'ssotool-app/shared/helpers';
import { SSOToolRoutePathService } from 'ssotool-shared/services';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sso-sector-list',
  templateUrl: './sector-list.component.html',
  styleUrls: ['./sector-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectorListComponent implements OnInit {
  /** Template variables */
  entities$: Observable<any[]> = of([]);
  loading$: Observable<boolean> = of(false);
  clientId = '';

  columnDefsOverride = [
    { name: 'name', mode: 'event' },
    { name: 'type' },
    { name: 'parent' },
  ];

  constructor(
    private clientFacade: ClientFacadeService,
    private router: Router,
    private routePath: SSOToolRoutePathService,
  ) {}

  ngOnInit(): void {
    this.clientFacade.selectActiveClientData$.pipe().subscribe((client) => {
      /* istanbul ignore else */
      if (client && client.clientId) {
        this.clientId = client.clientId;
        this.entities$ = this.clientFacade.selectSectors$(client.clientId).pipe(
          map((data) =>
            Coerce.getObjValues(data).map(
              ({ sectorId, name, sectorType, parentName }) => ({
                id: sectorId,
                name,
                type: sectorType,
                parent: parentName,
              }),
            ),
          ),
        );
        this.loading$ = this.clientFacade.dataLoading$(client.clientId);
      }
    });
  }

  onClick(event: any) {
    this.router.navigate(this.routePath.viewSector(this.clientId, event.id));
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EntityCommonTableModule } from 'ssotool-app/+entities/components/entity-common-table';
import { ArchetypeListComponent } from './archetype-list.component';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [ArchetypeListComponent],
  imports: [BrowserModule, CommonModule, EntityCommonTableModule, RouterModule],
  exports: [ArchetypeListComponent],
})
export class ArchetypeListModule {}

import { filter, first, mergeMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { RoadmapVisualizationFacadeService } from '../stores/visualization';
import { combineLatest, of } from 'rxjs';

@Injectable()
export class RoadmapVisualizationResolverService implements Resolve<any> {
  constructor(private visualizationFacade: RoadmapVisualizationFacadeService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const roadmapId = of(route.params.roadmapId);
    const clientId = of(route.params.clientId);

    combineLatest([clientId, roadmapId])
      .pipe(
        filter(([clientId, roadmapId]) => !!clientId && !!roadmapId),
        first(),
        mergeMap(([clientId, roadmapId]) =>
          this.visualizationFacade.loaded$.pipe(
            tap((loaded) => {
              if (!loaded) {
                this.visualizationFacade.getList(clientId, roadmapId);
              }
            }),
          ),
        ),
      )
      .subscribe();
  }
}

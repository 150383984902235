import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@oculus/auth/amplify';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private router: Router,
  ) {}
  canActivate(): Observable<boolean> {
    return this.authService.checkAuth$.pipe(
      mergeMap((loggedIn) => {
        if (loggedIn) {
          return this.redirectToClients();
        }
        return of(true);
      }),
      catchError(() => of(true)),
    );
  }

  protected redirectToClients(): Observable<boolean> {
    this.router.navigate(['/clients']);
    return of(false);
  }
}

<mat-form-field
  [appearance]="readonly && noBorderBottom ? 'fill' : appearance"
  class="sso-maximize-width sso-yearly-input-custom"
  [floatLabel]="'always'"
  [hideRequiredMarker]="true"
>
  <input matInput hidden [formControl]="inputValueControl" />
  <mat-label fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2px">
    <span>{{ label | translate }}</span>
    <span *ngIf="mainInput" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon color="primary">circle</mat-icon>
      <span class="main-input">MAIN</span>
    </span>
    <span *ngIf="!hideRequiredMarker && !readonly">*</span>
  </mat-label>
  <ng-container *ngIf="hasMultipleValues$ | async; else singleValue">
    <sso-sparkline-component
      [yearlyValuesMap]="multiValueControl?.value"
      class="sparkline-class"
      [matMenuTriggerFor]="menu"
      (menuOpened)="toggleMenu()"
      (menuClosed)="toggleMenu(false)"
    ></sso-sparkline-component>
  </ng-container>
  <ng-template #singleValue>
    <ng-container *ngIf="!readonly; else singleReadonly">
      <input
        data-testid="input-field"
        matInput
        ssoCommify
        [type]="'text'"
        [placeholder]="placeholder || label | translate"
        [formControl]="inputValueControl"
        [required]="!readonly"
        [attr.aria-label]="label"
      />
    </ng-container>
    <ng-template #singleReadonly>
      <div
        [matMenuTriggerFor]="menu"
        (menuOpened)="toggleMenu()"
        (menuClosed)="toggleMenu(false)"
        [ngClass]="{ 'sso-link': isSingleValueClickable }"
      >
        {{ inputValueControl?.value | number : '1.0-5' }}
      </div>
    </ng-template>
  </ng-template>

  <div matSuffix class="unit-container" *ngIf="unit">{{ unit }}</div>

  <!-- MatSuffixes doesnt work in nested (even ng-container) and angular material does not want to fix/create the feature for it LOL https://github.com/angular/angular/issues/24858 -->
  <mat-icon
    matSuffix
    *ngIf="(hasMultipleValues$ | async) && !isDisabled && !readonly"
    color="warn"
    class="cancel-button"
    (click)="clearValues()"
  >
    cancel
  </mat-icon>
  <mat-icon
    class="placeholder-icon"
    matSuffix
    *ngIf="(hasMultipleValues$ | async) && readonly && !isSingleValueClickable"
    >minimize</mat-icon
  >
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    class="icon-container"
    [matMenuTriggerFor]="menu"
    (menuOpened)="toggleMenu()"
    (menuClosed)="toggleMenu(false)"
  >
    <mat-icon
      *ngIf="(notMultipleValues$ | async) && !readonly"
      matSuffix
      color="primary"
      class="icon-control"
    >
      {{ iconSuffix }}
    </mat-icon>
  </div>

  <mat-icon
    *ngIf="contextHelpMsg"
    class="input-tooltip-anchor"
    matSuffix
    tooltipPosition="left"
    [matTooltip]="contextHelpMsg"
    >help
  </mat-icon>
  <mat-hint
    class="warning-info"
    *ngIf="warning"
    [innerHtml]="warningMessage"
  ></mat-hint>
  <mat-error
    *ngFor="let error of inputValueControl?.errors | keyvalue"
    [innerHtml]="
      errorMessage + '.' + error?.key | translate : { param: error?.value }
    "
  >
  </mat-error>
  <mat-hint *ngIf="!warning">{{ hint }}</mat-hint>
</mat-form-field>

<mat-menu
  class="mat-menu-half"
  #menu="matMenu"
  overlapTrigger
  xPosition="after"
>
  <oculus-yearly-forecast
    *ngIf="menuOpened$ | ngrxPush as opened"
    [years]="years$ | ngrxPush"
    [dataType]="forecastType"
    [formControl]="multiValueControl"
    [readOnly]="readonly"
    [opened]="menuOpened$ | ngrxPush"
  ></oculus-yearly-forecast>
</mat-menu>

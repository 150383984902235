import { BehaviorSubject, combineLatest, iif, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

/* eslint-disable @angular-eslint/no-input-rename */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  GanttItem,
  GanttMultiSelectionChanges,
  GanttTooltip,
  GanttViewMode,
} from '../gantt.models';
import { changeDate, getDate, getDateDiff } from '../gantt.references';
import {
  LIMIT_PADDER,
  CURRENT_ITEMS,
  LIMIT_ITEMS,
} from './gantt-bar.references';

@UntilDestroy()
@Component({
  selector: 'sso-gantt-bar',
  templateUrl: './gantt-bar.component.html',
  styleUrls: ['./gantt-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class GanttBarComponent implements OnInit {
  private _data = new BehaviorSubject<GanttItem>({
    id: '',
    name: '',
    isFiltered: false,
    isShown: false,
  });

  private mouseMoveListener;
  private mouseUpListener;

  private _ganttStartDate = new BehaviorSubject<string>('');

  private _multiSelectChanges = new BehaviorSubject<GanttMultiSelectionChanges>(
    {
      ids: [],
      limitLeftChange: 0,
      limitWidthChange: 0,
    },
  );

  private _columnWidth = new BehaviorSubject<number>(30);

  xStart = 0;
  currWidths: number[] = [];
  currLefts: number[] = [];
  origWidths: number[] = [];
  origLefts: number[] = [];
  movingIndex: number;
  limitWidth: number;
  limitLeft: number;
  origLimitWidth: number;
  origLimitLeft: number;
  height = 24;
  limitHeight = 25;
  extraNum = 4;

  canResize: string = '';
  canDrag = '';
  hasLimits = false;
  hasResult = false;

  // INPUTS
  @Input('ganttHeaderWidth') ganttHeaderWidth: number;
  @Input('viewMode') viewMode: GanttViewMode;
  @Input('readonly') readonly = false;
  @Input('disabled') disabled = false;
  // @Input('columnWidth') columnWidth = 30;
  // for investments/results (pills)
  @Input() disableOutputMovement = true;
  // allow movement of LIMIT BAR to undisplayed columns
  @Input('allowOutOfBounds') allowOutOfBounds = true;

  @Input('data') set data(data: GanttItem) {
    this._data.next(data);
  }

  @Input() set ganttStartDate(ganttStartDate: string) {
    this._ganttStartDate.next(ganttStartDate);
  }

  @Input('multiSelectChanges') set multiSelectChanges(
    changes: GanttMultiSelectionChanges,
  ) {
    this._multiSelectChanges.next(changes);
  }

  @Input() set columnWidth(columnWidth: number) {
    this._columnWidth.next(columnWidth);
  }

  // GETTERS

  get data() {
    return this._data.value;
  }

  get ganttWidth() {
    return this.columnWidth * 100;
  }

  get ganttStartDate() {
    return this._ganttStartDate.value;
  }

  ganttStartDate$ = this._ganttStartDate.asObservable();

  get isMoving() {
    return (
      !this.disabled && !this.readonly && (!!this.canResize || this.canDrag)
    );
  }

  get columnWidth() {
    return this._columnWidth.value;
  }

  columnWidth$ = this._columnWidth.asObservable();

  tooltipData$: Observable<GanttTooltip> = this.updateToolTipData();
  toolTipHeader$: Observable<string> = this.createTooltipHeader();

  @Output() dateChange = new EventEmitter<any>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick = new EventEmitter<any>();
  @Output() tooltipEvent = new EventEmitter<GanttTooltip>();

  constructor(private renderer: Renderer2) {}

  /**
   * Initializes the gantt bar item configurations based on the dates passed.
   */
  ngOnInit() {
    this._data.pipe(untilDestroyed(this)).subscribe(() => {
      this.initializeCurrentPosition();
    });

    this._multiSelectChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.multiSelectChangesAction();
    });

    this.initializePositions();
  }

  private initializePositions(): void {
    combineLatest([this.ganttStartDate$, this.columnWidth$]).subscribe(() => {
      this.initializeCurrentPosition();
    });
  }

  /**
   * Initializes the initial width and left offset of the gantt bar item.
   */
  initializeCurrentPosition() {
    const { startDates = [], endDates = [], endLimit, startLimit } = this.data;
    this.hasLimits = !!endLimit && !!startLimit;
    this.hasResult = startDates.length !== 0 && endDates.length !== 0;

    startDates.forEach((startDate, index) => {
      this.origWidths[index] = this.currWidths[index] =
        getDateDiff(endDates[index], startDate, this.viewMode) *
          this.columnWidth +
        this.columnWidth;
      this.origLefts[index] = this.currLefts[index] =
        getDateDiff(startDate, this.ganttStartDate, this.viewMode) *
          this.columnWidth -
        4;
    });

    if (this.hasLimits) {
      this.origLimitWidth = this.limitWidth =
        getDateDiff(endLimit, startLimit, this.viewMode) * this.columnWidth +
        this.columnWidth -
        2 * LIMIT_PADDER;
      this.origLimitLeft = this.limitLeft =
        getDateDiff(startLimit, this.ganttStartDate, this.viewMode) *
          this.columnWidth +
        LIMIT_PADDER;
    }
  }

  /**
   * Computes for the change and determines which values changed on the gantt bar.
   * @param event mouse event that changes the position and size of the gantt bar.
   */
  onMouseMove(event: MouseEvent) {
    event.preventDefault();

    if (this.isMoving) {
      const changePos = this.getChangePositionValue(event.clientX);
      const movableTargets = [this.canResize, this.canDrag];

      if (movableTargets.some((val) => CURRENT_ITEMS.includes(val))) {
        this.updateCurrentValues(event.clientX, changePos);
      } else if (movableTargets.some((val) => LIMIT_ITEMS.includes(val))) {
        this.updateLimitValues(event.clientX, changePos);
      }
    }
  }

  private getChangePositionValue(clientX: number) {
    const dx = clientX - this.xStart;
    const rem = dx % this.columnWidth;
    return dx - rem + (rem < this.columnWidth ? 0 : this.columnWidth);
  }

  private updateCurrentValues(clientX: number, changePos: number) {
    if (
      this.currWidths[this.movingIndex] + this.currLefts[this.movingIndex] <=
      this.ganttWidth
    ) {
      const nextLeft = this.currLefts[this.movingIndex] + changePos;
      let nextWidth = this.currWidths[this.movingIndex] - changePos;

      switch (this.canResize) {
        case 'leftHandler':
          if (this.canMoveLeftHandler(changePos, nextLeft)) {
            this.currLefts[this.movingIndex] = nextLeft;

            if (nextWidth >= this.columnWidth) {
              this.currWidths[this.movingIndex] = nextWidth;
            }
            this.emitDateChange(clientX);
          }
          break;
        case 'rightHandler':
          nextWidth = this.currWidths[this.movingIndex] + changePos;
          /* istanbul ignore else */
          if (this.canMoveRightHandler(changePos, nextWidth)) {
            this.currWidths[this.movingIndex] = nextWidth;
            this.emitDateChange(clientX);
          }
          break;
        default:
          if (this.canDrag && this.canDragBar(changePos, nextLeft)) {
            this.currLefts[this.movingIndex] = nextLeft;
            this.emitDateChange(clientX);
          }
      }
    }
  }

  /**
   * Check if the left handler can move.
   * @param pos value for the computed change position.
   * @param left value for the change offset left.
   */
  canMoveLeftHandler(pos: number, left: number) {
    return (
      left >= 0 &&
      pos !== 0 &&
      left <
        this.currWidths[this.movingIndex] + this.currLefts[this.movingIndex]
    );
  }

  /**
   * Check if the right handler can move.
   * @param pos value for the computed change position.
   * @param width value for the change width.
   */
  canMoveRightHandler(pos: number, width: number) {
    return (
      pos !== 0 &&
      width >= this.columnWidth &&
      this.currLefts[this.movingIndex] + width <= this.ganttWidth
    );
  }

  /**
   * Check if the whole bar can be dragged out of its current position.
   * @param pos value for the computed change position.
   * @param left value for the change offset left.
   */
  canDragBar(pos: number, left: number) {
    return (
      left >= 0 &&
      pos !== 0 &&
      this.currWidths[this.movingIndex] + left <= this.ganttWidth
    );
  }

  private updateLimitValues(clientX: number, changePos: number) {
    if (this.limitWidth + this.limitLeft <= this.ganttWidth + this.extraNum) {
      const nextLeft = this.limitLeft + changePos;
      let nextWidth = this.limitWidth - changePos;

      switch (this.canResize) {
        case 'leftLimitHandler':
          if (this.canMoveLeftHandlerLimit(changePos, nextLeft)) {
            this.updateLeftLimitHandlerValue(nextLeft, nextWidth);
            this.emitLimitDateChange(clientX);
          }
          break;
        case 'rightLimitHandler':
          nextWidth = this.limitWidth + changePos;
          /* istanbul ignore else */
          if (this.canMoveRightHandlerLimit(changePos, nextWidth)) {
            this.updateRightLimitHandlerValue(nextWidth);
            this.limitWidth = nextWidth;
            this.emitLimitDateChange(clientX);
          }
          break;
        default:
          if (this.canDrag && this.canDragBarLimit(changePos, nextLeft)) {
            this.updateBarLimitValue(nextLeft);
            this.emitLimitDateChange(clientX);
          }
      }
    }
  }

  private isDraggable(): boolean {
    return this.data.isDraggable;
  }

  /**
   * Check if the left handler can move.
   * @param pos value for the computed change position.
   * @param left value for the change offset left.
   */
  canMoveLeftHandlerLimit(pos: number, left: number) {
    const allowPush = this.allowOutOfBounds ? true : left >= 0;
    const canMoveLeft =
      allowPush && pos !== 0 && left < this.limitWidth + this.limitLeft;
    return canMoveLeft;
  }

  /**
   * Check if the right handler can move.
   * @param pos value for the computed change position.
   * @param width value for the change width.
   */
  canMoveRightHandlerLimit(pos: number, width: number) {
    const canMoveRight =
      pos !== 0 &&
      width >= this.columnWidth &&
      this.limitLeft + width <= this.ganttWidth + this.extraNum;
    return canMoveRight;
  }

  /**
   * Check if the whole bar can be dragged out of its current position.
   * @param pos value for the computed change position.
   * @param left value for the change offset left.
   */
  canDragBarLimit(pos: number, left: number) {
    return (
      this.isDraggable() &&
      left >= 0 &&
      pos !== 0 &&
      this.limitWidth + left <= this.ganttHeaderWidth
    );
  }

  /**
   * Updates the date values of the data based on the changes on the gantt bar's width and left offset.
   * @param currX current X position of the mouse pointer to be referenced
   */
  emitDateChange(currX: number) {
    this.xStart = currX;

    const widthChange = Math.round(
      (this.origWidths[this.movingIndex] - this.currWidths[this.movingIndex]) /
        this.columnWidth,
    );
    const leftChange = Math.round(
      (this.origLefts[this.movingIndex] - this.currLefts[this.movingIndex]) /
        this.columnWidth,
    );

    this.origWidths[this.movingIndex] = this.currWidths[this.movingIndex];
    this.origLefts[this.movingIndex] = this.currLefts[this.movingIndex];

    const newStart = leftChange;
    const newEnd = leftChange + widthChange;

    this.data.startDate = changeDate(
      this.data.startDate,
      newStart,
      this.viewMode,
    );
    this.data.endDate = changeDate(this.data.endDate, newEnd, this.viewMode);
    this.dateChange.emit(this.data);
    this.tooltipData$ = this.updateToolTipData();
  }

  /**
   * Updates the date values of the data based on the changes on the gantt bar's width and left offset.
   * @param currX current X position of the mouse pointer to be referenced
   */
  emitLimitDateChange(currX: number) {
    this.xStart = currX;

    const widthChange =
      (this.origLimitWidth - this.limitWidth) / this.columnWidth;
    const leftChange = (this.origLimitLeft - this.limitLeft) / this.columnWidth;

    this.origLimitWidth = this.limitWidth;
    this.origLimitLeft = this.limitLeft;

    const newStart = leftChange;
    const newEnd = leftChange + widthChange;

    this.data.startLimit = changeDate(
      this.data.startLimit,
      newStart,
      this.viewMode,
    );
    this.data.endLimit = changeDate(this.data.endLimit, newEnd, this.viewMode);
    this.data.widthChange = widthChange;
    this.data.leftChange = leftChange;
    this.dateChange.emit(this.data);
    this.tooltipData$ = this.updateToolTipData();
  }

  onMouseDown(event) {
    this.xStart = event.clientX;
    this.canDrag = this.canResize = event.target.id;
    this.movingIndex = event.target.getAttribute('data-index');
    this.mouseMoveListener = this.renderer.listen(
      window,
      'mousemove',
      (event) => {
        this.onMouseMove(event);
      },
    );
    this.mouseUpListener = this.renderer.listen(window, 'mouseup', (event) => {
      this.onMouseUpHandler();
    });
  }

  onMouseUpHandler() {
    this.canResize = this.canDrag = '';
    this.movingIndex = 0;
    this.mouseMoveListener();
    this.mouseUpListener();
  }

  updateToolTipData() {
    return this._data.pipe(
      map((data) => ({
        id: data.id,
        name: data.name,
        startDates: (data.startDates || []).map((startDate) =>
          getDate(startDate)?.format('[Jan 1,] YYYY'),
        ),
        endDates: (data.endDates || []).map((endDate) =>
          getDate(endDate)?.format('[Dec 31,] YYYY'),
        ),
        startLimit:
          !!data.startLimit &&
          getDate(data.startLimit)?.format('[Jan 1,] YYYY'),
        endLimit:
          !!data.endLimit && getDate(data.endLimit)?.format('[Dec 31,] YYYY'),
      })),
    );
  }

  private createTooltipHeader(): Observable<string> {
    return this._data.pipe(
      mergeMap((data) =>
        iif(
          () =>
            this.findEarliestDate(data.startDates || []) <= data.startLimit ||
            this.findLatestDate(data.endDates || []) >= data.endLimit,
          of(`Reached Limit: ${data.name}`),
          of(data.name),
        ),
      ),
    );
  }

  private findEarliestDate(arr) {
    return [...arr].sort((a, b) => Date.parse(a) - Date.parse(b))[0];
  }

  private findLatestDate(arr) {
    return [...arr].sort((a, b) => Date.parse(b) - Date.parse(a))[0];
  }

  private multiSelectChangesAction() {
    const changeValue = this._multiSelectChanges.getValue();

    /* istanbul ignore else */
    if (changeValue.ids.includes(this._data.getValue().id)) {
      const newLeft =
        this.limitLeft - changeValue.limitLeftChange * this.columnWidth;
      const newWidth =
        this.limitWidth - changeValue.limitWidthChange * this.columnWidth;

      // left handler
      if (
        !!changeValue.limitLeftChange &&
        !!changeValue.limitWidthChange &&
        this.canMoveLeftHandlerLimit(changeValue.limitLeftChange, newLeft)
      ) {
        this.updateLeftLimitHandlerValue(newLeft, newWidth);
        this.data.startLimit = changeDate(
          this.data.startLimit,
          changeValue.limitLeftChange,
          this.viewMode,
        );
        // right handler
      } else if (
        !!!changeValue.limitLeftChange &&
        !!changeValue.limitWidthChange &&
        this.canMoveRightHandlerLimit(changeValue.limitWidthChange, newWidth)
      ) {
        this.updateRightLimitHandlerValue(newWidth);
        this.data.endLimit = changeDate(
          this.data.endLimit,
          changeValue.limitWidthChange,
          this.viewMode,
        );
      }
      // drag bar
      else if (
        !!changeValue.limitLeftChange &&
        !!!changeValue.limitWidthChange &&
        this.canDragBarLimit(changeValue.limitLeftChange, newLeft)
      ) {
        this.updateBarLimitValue(newLeft);
        this.data.startLimit = changeDate(
          this.data.startLimit,
          changeValue.limitLeftChange,
          this.viewMode,
        );
        this.data.endLimit = changeDate(
          this.data.endLimit,
          changeValue.limitWidthChange,
          this.viewMode,
        );
      }

      this.data.leftChange = 0;
      this.data.widthChange = 0;
      this.dateChange.emit(this.data);
      this.tooltipData$ = this.updateToolTipData();
    }
  }

  private updateLeftLimitHandlerValue(left: number, width: number) {
    if (width >= this.columnWidth) {
      this.limitWidth = width;
    }
    this.updateBarLimitValue(left);
  }

  private updateRightLimitHandlerValue(width: number) {
    this.limitWidth = width;
  }

  private updateBarLimitValue(left: number) {
    this.limitLeft = left;
  }
}

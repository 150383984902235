import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ReleaseNotesService } from '../services/release-notes.service';
import { ReleaseNotesActions } from './release-notes.action';
import { ReleaseNotesFacadeService } from './release-notes.facade.service';
import { ToastService } from 'ssotool-app/shared/services/toast/toast.service';

@Injectable()
export class ReleaseNotesEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReleaseNotesActions.get),
      mergeMap(() =>
        this.releaseNotesService.get().pipe(
          map((data) =>
            ReleaseNotesActions.getSuccess({
              data,
              message: 'Get release notes success!',
            }),
          ),
          catchError((error) =>
            of(
              ReleaseNotesActions.getFailure({
                error,
                message: 'Get release notes error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReleaseNotesActions.getSuccess),
        tap((action) => this.toast.showToast(action.message, 'success')),
      ),
    { dispatch: false },
  );

  getFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReleaseNotesActions.getFailure),
        tap((action) =>
          this.toast.showToast(
            action.message,
            'action',
            undefined,
            of({}).pipe(tap(() => this.releaseNotesFacade.get())),
          ),
        ),
      ),
    { dispatch: false },
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReleaseNotesActions.update),
      mergeMap((action) =>
        this.releaseNotesService.update(action.data).pipe(
          map(() =>
            ReleaseNotesActions.updateSuccess({
              data: action.data,
              message: 'Update release notes success!',
            }),
          ),
          catchError((error) =>
            of(
              ReleaseNotesActions.updateFailure({
                data: action.data,
                error,
                message: 'Update release notes error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  updateSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReleaseNotesActions.updateSuccess),
        tap((action) => {
          this.toast.showToast(action.message, 'success');
          this.releaseNotesFacade.get();
        }),
      ),
    { dispatch: false },
  );

  updateFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReleaseNotesActions.updateFailure),
        tap((action) =>
          this.toast.showToast(
            action.message,
            'action',
            undefined,
            of({}).pipe(tap(() => this.releaseNotesFacade.update(action.data))),
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private releaseNotesService: ReleaseNotesService,
    private toast: ToastService,
    private releaseNotesFacade: ReleaseNotesFacadeService,
  ) {}
}

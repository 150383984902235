<form
  [formGroup]="baseForm"
  fxLayout="column"
  fxLayoutGap="10px"
  data-testid="form-container"
>
  <button mat-button style="height: 0; position: absolute">
    <!-- 'absorbs' the auto-focus behavior -->
  </button>

  <div class="drawer-contents">
    <div class="contents-loader" *ngIf="loading$ | async; else contents">
      <ngx-skeleton-loader
        count="1"
        animation="progress"
        [theme]="{ height: '60px', width: '100%' }"
      ></ngx-skeleton-loader>
      <mat-divider></mat-divider>
      <ngx-skeleton-loader
        count="4"
        animation="progress"
        [theme]="{ height: '40px', width: '40%', 'margin-right': '16px' }"
      ></ngx-skeleton-loader>
    </div>
    <ng-template #contents>
      <div class="general-container" fxLayout="column" fxLayoutGap="5px">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <sso-input
            class="full-width"
            data-testid="input-name-field"
            appearance="fill"
            size="large"
            [placeholder]="'Entities.placeholders.parameters.name' | translate"
            [label]="'Entities.labels.parameters.name' | translate"
            formControlName="name"
            [required]="true"
            [errorMessageMap]="errorMessages?.name"
            [readonly]="readonly$ | async"
          ></sso-input>
        </div>
        <mat-divider></mat-divider>
      </div>

      <!--Associated entities-->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header
          collapsedHeight="35px"
          expandedHeight="35px"
        >
          <mat-panel-title>
            <h3 class="mat-headline-6">
              {{ 'Entities.labels.parameters.entities' | translate }}
            </h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row wrap" fxLayoutGap="10px">
          <!-- Geography -->
          <sso-select
            data-testid="select-geo-field"
            [label]="'Entities.labels.parameters.geoId' | translate"
            [options]="geoOptions$ | async"
            [control]="baseForm?.controls?.geoId"
            [required]="true"
            [errorMessageMap]="errorMessages?.geoId"
            [readonly]="readonly$ | async"
          >
          </sso-select>
          <!-- Company Entity -->
          <sso-select
            data-testid="select-company-field"
            [label]="'Entities.labels.parameters.companyId' | translate"
            [options]="companyOptions$ | async"
            [control]="baseForm?.controls?.companyEntityId"
            [required]="true"
            [errorMessageMap]="errorMessages?.companyId"
            [readonly]="readonly$ | async"
          >
          </sso-select>
          <!-- Sector -->
          <sso-select
            data-testid="select-sector-field"
            [label]="'Entities.labels.parameters.sectorId' | translate"
            [options]="sectorOptions$ | async"
            [control]="baseForm?.controls?.sectorId"
            [readonly]="readonly$ | async"
          ></sso-select>
          <!-- Process -->
          <sso-select
            data-testid="select-process-field"
            [label]="'Entities.labels.parameters.processId' | translate"
            [options]="processOptions$ | async"
            [control]="baseForm?.controls?.processId"
            [readonly]="readonly$ | async"
          >
          </sso-select>
          <!-- Fluid -->
          <sso-select
            data-testid="select-fluid-field"
            [label]="'Entities.labels.parameters.fluidId' | translate"
            [options]="fluidOptions$ | async"
            [control]="baseForm?.controls?.fluidId"
            [readonly]="readonly$ | async"
          >
          </sso-select>
        </div>
      </mat-expansion-panel>
      <!--Main parameters-->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header
          collapsedHeight="35px"
          expandedHeight="35px"
        >
          <mat-panel-title>
            <h3 class="mat-headline-6">
              {{ 'Entities.labels.parameters.main' | translate }}
            </h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row wrap" fxLayoutGap="20px">
          <!-- Capacity -->
          <sso-yearly-input
            data-testid="yearly-capacity-field"
            formControlName="capacity"
            [startYear]="startYear"
            [endYear]="endYear"
            [errorMessage]="'Entities.messages.errors.capacity'"
            [label]="'Entities.labels.existingStorage.capacity' | translate"
            [readonly]="readonly$ | async"
          ></sso-yearly-input>
          <!-- EP Ratio -->
          <sso-yearly-input
            data-testid="yearly-ePRatio-field"
            formControlName="ePRatio"
            [startYear]="startYear"
            [endYear]="endYear"
            [errorMessage]="'Entities.messages.errors.epRatio'"
            [label]="'Entities.labels.existingStorage.epRatio' | translate"
            [readonly]="readonly$ | async"
          ></sso-yearly-input>
          <!-- Max Dod -->
          <sso-yearly-input
            data-testid="yearly-maxDod-field"
            formControlName="maxDod"
            [startYear]="startYear"
            [endYear]="endYear"
            [errorMessage]="'Entities.messages.errors.maxDod'"
            [label]="'Entities.labels.existingStorage.maxDod' | translate"
            [readonly]="readonly$ | async"
          ></sso-yearly-input>
          <!-- Roundtrip Efficiency -->
          <sso-yearly-input
            data-testid="yearly-roundtripEfficiency-field"
            formControlName="roundtripEfficiency"
            [startYear]="startYear"
            [endYear]="endYear"
            [errorMessage]="'Entities.messages.errors.roundtripEff'"
            [label]="'Entities.labels.existingStorage.roundtripEff' | translate"
            [readonly]="readonly$ | async"
          ></sso-yearly-input>
          <!-- Technical Life -->
          <sso-yearly-input
            data-testid="yearly-technicalLife-field"
            formControlName="technicalLife"
            [startYear]="startYear"
            [endYear]="endYear"
            [errorMessage]="'Entities.messages.errors.technicalLife'"
            [label]="
              'Entities.labels.existingStorage.technicalLife' | translate
            "
            [readonly]="readonly$ | async"
          ></sso-yearly-input>
          <!-- Depreciation Time -->
          <sso-yearly-input
            data-testid="yearly-depreciationTime-field"
            formControlName="depreciationTime"
            [startYear]="startYear"
            [endYear]="endYear"
            [errorMessage]="'Entities.messages.errors.depreciationTime'"
            [label]="
              'Entities.labels.existingStorage.depreciationTime' | translate
            "
            [readonly]="readonly$ | async"
          ></sso-yearly-input>
        </div>
      </mat-expansion-panel>

      <!--Cost Parameters -->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header
          collapsedHeight="35px"
          expandedHeight="35px"
        >
          <mat-panel-title>
            <h3 class="mat-headline-6">
              {{ 'Entities.labels.parameters.cost' | translate }}
            </h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row wrap" fxLayoutGap="10px">
          <!-- FoM 1 -->
          <sso-yearly-input
            data-testid="yearly-foM1-field"
            formControlName="foM1"
            [startYear]="startYear"
            [endYear]="endYear"
            [errorMessage]="'Entities.messages.errors.foM1'"
            [label]="'Entities.labels.existingStorage.foM1' | translate"
            [readonly]="readonly$ | async"
          ></sso-yearly-input>
          <!-- FoM 2 -->
          <sso-yearly-input
            data-testid="yearly-foM2-field"
            formControlName="foM2"
            [startYear]="startYear"
            [endYear]="endYear"
            [errorMessage]="'Entities.messages.errors.foM2'"
            [label]="'Entities.labels.existingStorage.foM2' | translate"
            [readonly]="readonly$ | async"
          ></sso-yearly-input>
          <!-- FoM per Install -->
          <sso-yearly-input
            data-testid="yearly-fomPerInstall-field"
            formControlName="fomPerInstall"
            [startYear]="startYear"
            [endYear]="endYear"
            [errorMessage]="'Entities.messages.errors.foMPerInstall'"
            [label]="
              'Entities.labels.existingStorage.foMPerInstall' | translate
            "
            [readonly]="readonly$ | async"
          ></sso-yearly-input>
        </div>
      </mat-expansion-panel>
    </ng-template>
  </div>
  <div
    class="drawer-actions"
    fxLayout="row"
    fxLayoutGap="5px"
    fxLayoutAlign="end center"
  >
    <ngx-skeleton-loader
      *ngIf="loading$ | async; else actions"
      count="1"
      animation="progress"
      [theme]="{ height: '40px', width: '100%' }"
    ></ngx-skeleton-loader>
    <ng-template #actions>
      <button
        (click)="onClose()"
        data-testid="close-button"
        mat-stroked-button
        *ngIf="data?.readonly; else drawerActions"
        type="button"
      >
        {{ 'Generic.labels.close' | translate | titlecase }}
      </button>
      <ng-template #drawerActions [ngSwitch]="mode$ | async">
        <ng-container *ngSwitchCase="'view'">
          <div
            *ngIf="!data?.readonly"
            class="full-width"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
              <button
                (click)="onClose()"
                data-testid="close-button"
                mat-stroked-button
                color=""
                type="button"
              >
                {{ 'Generic.labels.close' | translate | titlecase }}
              </button>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </ng-template>
  </div>
</form>

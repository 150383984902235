import { Subject } from 'rxjs';

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Dictionary } from '@ngrx/entity';

import { BaseComponent } from '../base';
import { ColumnDefinition } from './base-table.model';

@Directive()
export abstract class BaseTableComponent<T>
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() name: string;
  @Input() loading: boolean;
  @Input() searchInput: boolean;
  @Input() searchSelect: boolean;
  @Input() error: any;

  @Input() pageSize = 50;
  @Input() references: Dictionary<any> = {};
  @Input() columnsDef = this.defineColumns();
  @Input() dataSource = new MatTableDataSource<T>([]);

  @Input() searchPredicate: (data: T, filter: string) => boolean;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
  ) {
    super();
    if (ngControl != null) {
      ngControl.valueAccessor = this;
    }
  }

  refreshData(data: Array<T>) {
    this.dataSource.data = data;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (!!this.searchPredicate) {
      this.dataSource.filterPredicate = this.searchPredicate;
    }
  }

  getReference(key: string) {
    return this.references[key];
  }

  addReference(key: string, value: any) {
    this.references[key] = value;
  }

  abstract defineColumns(): ColumnDefinition;

  onKeyIn(searchValue: string) {
    this.dataSource.filter = searchValue;
  }

  static nextUniqueId = 0;

  // tslint:disable-next-line:variable-name
  private _value: any;
  get value(): any {
    return this._value;
  }
  @Input() set value(newValue: any) {
    if (newValue !== this._value) {
      this._value = newValue;
      this.stateChanges.next();
    }
  }

  // tslint:disable-next-line:variable-name
  private _id: string;
  get id(): string {
    return this._id;
  }
  @Input() set id(value: string) {
    this._id = value || this.uid;
    this.stateChanges.next();
  }

  // tslint:disable-next-line:variable-name
  private _required = false;
  get required(): boolean {
    return this._required;
  }
  @Input() set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  // tslint:disable-next-line:variable-name
  private _focused = false;
  get focused(): boolean {
    return this._focused;
  }
  set focused(value: boolean) {
    this._focused = value;
  }

  // tslint:disable-next-line:variable-name
  private _disabled = false;
  get disabled(): boolean {
    return this._disabled;
  }
  @Input() set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  // tslint:disable-next-line:variable-name
  private _readonly = false;
  get readonly(): boolean {
    return this._readonly;
  }
  @Input() set readonly(value: boolean) {
    this._readonly = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  uid = `eyes-base-control-${BaseTableComponent.nextUniqueId++}`;
  stateChanges = new Subject<void>();

  onChange: (value: any) => void = () => {};
  onTouched = () => {};

  ngOnInit() {
    this.setDisabledState(this.disabled);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.stateChanges.complete();
  }

  writeValue(value: any): void {
    this.value = value;
    this.markForCheck();
    this.stateChanges.next();
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.markForCheck();
    this.stateChanges.next();
  }

  markForCheck() {
    if (this.changeDetector) {
      this.changeDetector.markForCheck();
    }
  }
}

import { FilterWithCondition } from 'ssotool-app/shared/modules/filters';

export enum MAIN_TABS {
  TIMELINE = 'timeline',
  TARGETS = 'targets',
  PORTFOLIO_ANALYSIS = 'portfolioAnalysis',
  DETAILED_VIEW = 'detailedView',
}

export enum SUB_TABS {
  FINANCIALS = 'financials',
  SUSTAINABILITY = 'sustainability',
  OPERATIONS = 'operations',
}

export type TabFilter = Readonly<Record<keyof SUB_TABS, FilterWithCondition>>;
export type TabFilterState = Readonly<
  FilterWithCondition | Record<keyof MAIN_TABS, TabFilter>
>;
export type TabFiltersState = Readonly<Record<string, TabFilterState>>;

import { Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { Observable } from 'rxjs';
import { SingleButtonToastComponent } from 'ssotool-app/shared/component/custom-toast/single-button-toast/single-button-toast.component';

@Injectable()
export class ToastService {
  DEFAULT_DURATION = 5000;

  constructor(private toastService: HotToastService) {}

  showToast(
    message: string,
    type: 'success' | 'error' | 'info' | 'action',
    duration?: number,
    action?: Observable<{}>,
  ) {
    switch (type) {
      case 'success':
        this.toastService.success(message, {
          duration: duration || this.DEFAULT_DURATION,
        });
        break;
      case 'error':
        this.toastService.error(message, {
          autoClose: false,
          dismissible: true,
        });
        break;
      case 'info':
        this.toastService.info(message, {
          duration: duration || this.DEFAULT_DURATION,
        });
        break;
      case 'action':
        this.toastService.error(SingleButtonToastComponent, {
          autoClose: false,
          dismissible: true,
          data: { message, action, label: 'Retry?' },
        });
        break;
    }
  }
}

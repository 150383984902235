import { filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';

import { AlphaRequestActions } from './alpha-request.actions';
import { AlphaRequestPayload } from './alpha-request.model';
import { AlphaRequestState } from './alpha-request.reducer';
import {
  alphaRequest,
  getAlphaRequestList,
  loaded,
  loading,
  roadmapsLoaded,
  roadmapsLoading,
} from './alpha-request.selectors';

@Injectable()
export class AlphaRequestFacadeService {
  constructor(
    private store: Store<AlphaRequestState>,
    private actionSubject$: ActionsSubject,
  ) {}

  loading$ = this.store.pipe(select(loading));
  loaded$ = this.store.pipe(select(loaded));
  getAlphaList$ = this.store.pipe(select(getAlphaRequestList));

  /** Put roadmap in alpha request context. Roadmaps are based on client context. */
  roadmapsLoading$ = this.store.pipe(select(roadmapsLoading));
  roadmapsLoaded$ = this.store.pipe(select(roadmapsLoaded));

  getRequest(requestId: string) {
    return this.store.pipe(select(alphaRequest(requestId)));
  }

  getList() {
    this.store.dispatch(AlphaRequestActions.getList());
  }

  updateRequest({ requestId, consultantId }: AlphaRequestPayload) {
    this.store.dispatch(
      AlphaRequestActions.updateRequest({ data: { requestId, consultantId } }),
    );
    return this.actionSubject$.pipe(
      filter(
        (action) => action.type === AlphaRequestActions.updateSuccess.type,
      ),
    );
  }

  getAllRoadmaps(clientId: string, requestId: string) {
    this.store.dispatch(
      AlphaRequestActions.getRoadmaps({ clientId, requestId }),
    );
  }

  completeRequest({ requestId, roadmapIds }: AlphaRequestPayload) {
    this.store.dispatch(
      AlphaRequestActions.updateRequest({ data: { requestId, roadmapIds } }),
    );
    return this.actionSubject$.pipe(
      filter(
        (action) => action.type === AlphaRequestActions.updateSuccess.type,
      ),
    );
  }

  dismissRequest(requestId: string, dismissReason: string) {
    this.store.dispatch(
      AlphaRequestActions.updateRequest({ data: { requestId, dismissReason } }),
    );
    return this.actionSubject$.pipe(
      filter(
        (action) => action.type === AlphaRequestActions.updateSuccess.type,
      ),
    );
  }
}

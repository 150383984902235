import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  dataImportAdapter,
  dataImportFeatureKey,
  DataImportState,
} from './data-import.reducer';
import {
  HAS_COMMODITY_PRICES,
  TDB_INVOKED,
  TDB_INVOKING,
} from 'ssotool-app/app.references';
import { Coerce } from 'ssotool-app/shared';

const { selectIds, selectEntities, selectAll, selectTotal } =
  dataImportAdapter.getSelectors();

export const dataImportFeatureState =
  createFeatureSelector<DataImportState>(dataImportFeatureKey);

export const dataImportEntities = createSelector(
  dataImportFeatureState,
  selectEntities,
);

export const dataImportList = createSelector(dataImportFeatureState, selectAll);

export const loading = createSelector(
  dataImportFeatureState,
  (state) => state.loading,
);

export const loaded = createSelector(
  dataImportFeatureState,
  (state) => state.loaded,
);

export const uploading = createSelector(
  dataImportFeatureState,
  (state) => state.uploading,
);

// custom selectors
export const getLogs = (importId: string) => {
  return createSelector(
    dataImportEntities,
    (dataList) => dataList?.[importId]?.messages,
  );
};
export const logsLoaded = (importId: string) => {
  return createSelector(
    dataImportEntities,
    (dataList) => dataList?.[importId]?.logsLoaded,
  );
};
export const downloading = createSelector(
  dataImportFeatureState,
  (state) => state.downloading,
);
export const getDataImportList = (clientId) =>
  createSelector(dataImportList, (dataList) =>
    dataList.filter((data) => data.clientId === clientId),
  );

export const latestSuccessfulImport = createSelector(
  dataImportList,
  (imports) => imports.find((dataImport) => dataImport.status === 'success'),
);

export const latestImportNeedingTrajectories = createSelector(
  dataImportList,
  (imports) =>
    imports.find((dataImport) => {
      return (
        [TDB_INVOKING, TDB_INVOKED].includes(dataImport.status) &&
        Coerce.toEmptyObject(dataImport.tdbStatus) === HAS_COMMODITY_PRICES
      );
    }),
);

import { ProfileDetailsForm } from 'ssotool-app/shared/modules/profiles-form/profiles-form.model';

import { createAction, props } from '@ngrx/store';

const getProfiles = createAction(
  '[Production Profiles] Get Profiles',
  props<{ clientId: string }>(),
);

const getProfilesSuccess = createAction(
  '[Production Profiles] Get profiles success',
  props<{ clientId: string; message: string }>(),
);
const getProfilesFailure = createAction(
  '[Production Profiles] Get profiles failure',
  props<{ error: any; message: string }>(),
);

const getProfile = createAction(
  '[Production Profiles] Get Site Profile',
  props<{
    clientId: string;
    profileId: string;
    geoId: string;
    force: boolean;
  }>(),
);

const upsertProfile = createAction(
  '[Production Profiles] Upsert Profile',
  props<{
    data: ProfileDetailsForm;
    clientId: string;
    profileId: string;
    geoId: string;
  }>(),
);

const upsertProfileSuccess = createAction(
  '[Production Profiles] Upsert Profile success',
);

const upsertProfileFailure = createAction(
  '[Production Profiles] Upsert Profile failure',
  props<{
    data: ProfileDetailsForm;
    clientId: string;
    profileId: string;
    geoId: string;
  }>(),
);

const deleteProfile = createAction(
  '[Production Profiles] Delete Profile',
  props<{
    clientId: string;
    profileId: string;
    geoId: string;
  }>(),
);

const deleteProfileSuccess = createAction(
  '[Production Profiles] Delete Profile success',
);

const deleteProfileFailure = createAction(
  '[Production Profiles] Delete Profile failure',
  props<{
    clientId: string;
    profileId: string;
    geoId: string;
  }>(),
);

const updateModifyStatus = createAction(
  '[Production Profiles] Update Modify Status',
  props<{ code: number; clientId: string; profileId: string; geoId: string }>(),
);

export const ProfileActions = {
  getProfiles,
  getProfilesSuccess,
  getProfilesFailure,
  getProfile,
  upsertProfile,
  upsertProfileSuccess,
  upsertProfileFailure,
  deleteProfile,
  deleteProfileSuccess,
  deleteProfileFailure,
  updateModifyStatus,
};

import { filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';

import { RoadmapImportActions } from './roadmap-import.action';
import { RoadmapImportFormModel } from './roadmap-import.model';
import { RoadmapImportState } from './roadmap-import.reducer';
import {
  downloadingTemplate,
  getLogs,
  getRoadmapImportList,
  importDownloading,
  importing,
  importLoading,
  importLogsLoading,
  loaded,
  loading,
  logsLoaded,
  roadmapImportEntities,
} from './roadmap-import.selector';

@Injectable()
export class RoadmapImportFacadeService {
  constructor(
    private store: Store<RoadmapImportState>,
    private actionSubject$: ActionsSubject,
  ) {}

  loading$ = this.store.pipe(select(loading));
  loaded$ = this.store.pipe(select(loaded));
  getRoadmapImportEntities$ = this.store.pipe(select(roadmapImportEntities));
  downloadingTemplate$ = this.store.pipe(select(downloadingTemplate));
  importing$ = this.store.pipe(select(importing));
  getRoadmapImportList$ = (clientId: string, roadmapId: string) =>
    this.store.select(getRoadmapImportList({ clientId, roadmapId }));
  logsLoaded$ = (importId: string) =>
    this.store.pipe(select(logsLoaded(importId)));
  getLogs(importId: string) {
    return this.store.pipe(select(getLogs(importId)));
  }
  importLoading$ = (importId: string) =>
    this.store.select(importLoading({ importId }));
  importLogsLoading$ = (importId: string) =>
    this.store.select(importLogsLoading({ importId }));
  importDownloading$ = (importId: string) =>
    this.store.select(importDownloading({ importId }));

  get(clientId: string, roadmapId: string, importId: string) {
    this.store.dispatch(
      RoadmapImportActions.get({ clientId, roadmapId, importId }),
    );
    return this.actionSubject$.pipe(
      filter((action) => action.type === RoadmapImportActions.getSuccess.type),
    );
  }

  getList(clientId: string, roadmapId: string) {
    this.store.dispatch(RoadmapImportActions.getList({ clientId, roadmapId }));
  }

  updateImportStatus(
    clientId: string,
    roadmapId: string,
    importId: string,
    status: string,
  ) {
    this.store.dispatch(
      RoadmapImportActions.updateImportStatus({
        clientId,
        roadmapId,
        importId,
        status,
      }),
    );
  }

  importRoadmap(
    clientId: string,
    roadmapId: string,
    data: RoadmapImportFormModel,
  ) {
    this.store.dispatch(
      RoadmapImportActions.importRoadmap({ clientId, roadmapId, data }),
    );
  }

  downloadRoadmapImportData(
    clientId: string,
    roadmapId: string,
    importId: string,
  ) {
    this.store.dispatch(
      RoadmapImportActions.downloadRoadmapImportData({
        clientId,
        roadmapId,
        importId,
      }),
    );
  }

  downloadErrorLogs(clientId: string, roadmapId: string, importId: string) {
    return this.store.dispatch(
      RoadmapImportActions.downloadErrorLogs({ clientId, roadmapId, importId }),
    );
  }

  deleteImport(clientId: string, roadmapId: string, importId: string) {
    this.store.dispatch(
      RoadmapImportActions.deleteImport({ clientId, roadmapId, importId }),
    );
  }

  updateImportLogDetails(
    importId: string,
    roadmapId: string,
    requestId: string,
    streamName: string,
  ) {
    this.store.dispatch(
      RoadmapImportActions.updateImportLogDetails({
        importId,
        roadmapId,
        logDetails: { requestId, streamName },
      }),
    );
  }

  downloadTemplate(clientId: string) {
    this.store.dispatch(RoadmapImportActions.downloadTemplate({ clientId }));
  }
}

import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CAMPAIGN_EXPORT_MESSAGES } from 'ssotool-app/+campaign/campaign-page.references';
import { Coerce } from 'ssotool-app/shared';

import { SelectedCampaigns } from '../campaign.model';
import { CampaignExportActions } from './campaign-export.action';
import {
  CAMPAIGN_EXPORT_STATUS,
  CampaignStageMap,
  CampaignExportModel,
  CampaignStageDetails,
} from './campaign-export.model';
import { CampaignExportState } from './campaign-export.reducer';
import {
  activeExportId,
  cancelExportTriggered,
  exporting,
  selectActiveCampaignExport,
  loaded,
  selectStages,
} from './campaign-export.selector';

@Injectable()
export class CampaignExportFacadeService {
  constructor(
    private store: Store<CampaignExportState>,
    private actionSubject$: ActionsSubject,
    private translateService: TranslateService,
  ) {}

  loaded$ = this.store.pipe(select(loaded));
  exporting$ = this.store.pipe(select(exporting));
  activeExportId$ = this.store.pipe(select(activeExportId));
  selectActiveCampaignExport$ = this.store.pipe(
    select(selectActiveCampaignExport),
  );
  cancelExportTriggered$ = this.store.pipe(select(cancelExportTriggered));
  selectActiveExportId$ = this.store.pipe(select(activeExportId));
  selectStages = (exportId: string) =>
    this.store.pipe(select(selectStages(exportId)));

  exportCampaign(clientId: string, campaigns: SelectedCampaigns) {
    this.store.dispatch(
      CampaignExportActions.exportSelectedCampaigns({ clientId, campaigns }),
    );
    return this.actionSubject$.pipe(
      filter(
        (action) =>
          action.type ===
          CampaignExportActions.exportSelectedCampaignsSuccess.type,
      ),
    );
  }

  cancelExportCampaigns(clientId: string, exportId: string) {
    this.store.dispatch(
      CampaignExportActions.cancelExportCampaigns({
        clientId,
        exportId,
      }),
    );
  }

  getListCampaignsExport(clientId: string) {
    this.store.dispatch(
      CampaignExportActions.getListCampaignsExport({ clientId }),
    );
  }

  updateExportStatus(
    clientId: string,
    exportId: string,
    status: CAMPAIGN_EXPORT_STATUS,
    stages: CampaignStageMap[],
  ) {
    this.store.dispatch(
      CampaignExportActions.updateExportStatus({
        clientId,
        exportId,
        status,
        stages,
      }),
    );
  }

  downloadExportExcel(clientId: string, exportId: string) {
    this.store.dispatch(
      CampaignExportActions.downloadExportExcel({
        clientId,
        exportId,
      }),
    );
  }

  determineExportStatusMessage(): Observable<string> {
    return this.selectActiveCampaignExport$.pipe(
      filter((activeExport) => !!activeExport),
      map((activeExport) => this.findExportMessage(activeExport)),
    );
  }

  findExportMessage({ status, stages }: Partial<CampaignExportModel>): string {
    const endStatuses = [
      CAMPAIGN_EXPORT_STATUS.SUCCESS,
      CAMPAIGN_EXPORT_STATUS.FAILED,
      CAMPAIGN_EXPORT_STATUS.ONGOING_CANCELLING,
      CAMPAIGN_EXPORT_STATUS.CANCELLED,
      CAMPAIGN_EXPORT_STATUS.FAILED_CANCELLING,
    ];
    return endStatuses.includes(status)
      ? this.getTranslatedMesage(status)
      : this.getTranslatedMessageForStage(Coerce.toArray(stages).slice(-1)[0]);
  }

  private getTranslatedMesage(
    status: CAMPAIGN_EXPORT_STATUS,
    details: Record<string, string> = {},
  ): string {
    return this.translateService.instant(
      CAMPAIGN_EXPORT_MESSAGES[status] ||
        'Campaign.messages.exportingCampaigns.indicator',
      details,
    ) as string;
  }

  private getTranslatedMessageForStage({
    name,
    details,
  }: CampaignStageMap): string {
    const campaign: CampaignStageDetails =
      Coerce.toEmptyObject(details).campaign;
    return this.getTranslatedMesage(name, {
      done: Coerce.toString(Coerce.toEmptyObject(campaign).done, '?'),
      total: Coerce.toString(Coerce.toEmptyObject(campaign).total, '?'),
    });
  }
}

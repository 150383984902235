import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import {
  PartialRoadmapVisualization,
  ROADMAP_VISUALIZATION_FEATURE_NAME,
  RoadmapVisualization,
} from './visualization.model';
import { Action, createReducer, on } from '@ngrx/store';
import { RoadmapVisualizationActions } from './visualization.actions';
import { mapToRoadmapVisualization } from './visualization-api.service';

export interface RoadmapVisualizationState
  extends EntityState<PartialRoadmapVisualization> {
  active: PartialRoadmapVisualization['visualizationId'];
  loading: boolean;
  loaded: boolean;
}

export const selectRoadmapVisualizationName = (
  roadmapVisualization: RoadmapVisualization,
) => roadmapVisualization.name;

const sortDate = (
  visualizationData1: RoadmapVisualization,
  visualizationData2: RoadmapVisualization,
) => {
  const date1 = new Date(visualizationData1.createdAt);
  const date2 = new Date(visualizationData2.createdAt);
  return date1.getTime() - date2.getTime();
};

export const roadmapVisualizationAdapter: EntityAdapter<PartialRoadmapVisualization> =
  createEntityAdapter<RoadmapVisualization>({
    selectId: selectRoadmapVisualizationName,
    sortComparer: sortDate,
  });

export const initialRoadmapVisualizationState: RoadmapVisualizationState =
  roadmapVisualizationAdapter.getInitialState({
    active: null,
    ids: [],
    entities: {},
    loading: false,
    loaded: false,
  });

const roadmapVisualizationReducer = createReducer(
  initialRoadmapVisualizationState,
  // get visualization
  on(RoadmapVisualizationActions.get, (state) => {
    return { ...state, loading: true };
  }),
  on(RoadmapVisualizationActions.getSuccess, (state, props) => {
    const updateData = mapToRoadmapVisualization([props.data])[0];
    return roadmapVisualizationAdapter.upsertOne(
      { ...updateData },
      { ...state, loading: false, loaded: true },
    );
  }),
  on(RoadmapVisualizationActions.getFailed, (state) => {
    return { ...state, loading: false, loaded: false };
  }),
  // Get List of visualizations
  on(RoadmapVisualizationActions.getList, (state) => {
    return { ...state, loading: true };
  }),
  on(RoadmapVisualizationActions.getListSuccess, (state, { data, message }) => {
    const updateData = mapToRoadmapVisualization(data.data);

    return roadmapVisualizationAdapter.upsertMany(updateData, {
      ...state,
      loading: false,
      loaded: true,
      message,
    });
  }),
  on(
    RoadmapVisualizationActions.getListFailure,
    (state, { error, message }) => {
      return { ...state, loading: false, loaded: false, error, message };
    },
  ),
  // Save visualization
  on(RoadmapVisualizationActions.save, (state, props) =>
    roadmapVisualizationAdapter.upsertOne(
      { ...props },
      { ...state, loading: true },
    ),
  ),
  on(RoadmapVisualizationActions.saveSuccess, (state, { data }) => {
    const roadVis = mapToRoadmapVisualization([data])[0];
    return roadmapVisualizationAdapter.upsertOne(
      { ...roadVis },
      { ...state, loading: false },
    );
  }),
  on(RoadmapVisualizationActions.saveFailed, (state) => ({
    ...state,
    loading: false,
  })),
  // Delete visualization
  on(RoadmapVisualizationActions.deleteAction, (state, props) =>
    roadmapVisualizationAdapter.removeOne(props?.name, {
      ...state,
    }),
  ),
  on(RoadmapVisualizationActions.deleteFailed, (state, { data, message }) =>
    roadmapVisualizationAdapter.upsertOne(
      { ...data },
      { ...state, loading: false },
    ),
  ),
  on(RoadmapVisualizationActions.deleteFromNotif, (state, props) =>
    roadmapVisualizationAdapter.removeOne(props?.visualizationName, {
      ...state,
    }),
  ),
  on(RoadmapVisualizationActions.setActiveVisualization, (state, props) => {
    return { ...state, active: props.name };
  }),
  on(RoadmapVisualizationActions.resetActiveVisualization, (state) => {
    return { ...state, active: null };
  }),
);

export const RoadmapVisualizationStore = {
  featureName: ROADMAP_VISUALIZATION_FEATURE_NAME,
  reducer: roadmapVisualizationReducer,
};

export function RoadmapVisualizationReducer(
  state: RoadmapVisualizationState | undefined,
  action: Action,
) {
  return roadmapVisualizationReducer(state, action);
}

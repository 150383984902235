<form [formGroup]="form">
  <sso-yearly-view
    *ngIf="isViewMode; else rangeInput"
    class="full-width"
    [yearlyValuesMap]="form?.controls?.result?.value"
  ></sso-yearly-view>
  <ng-template #rangeInput>
    <div class="range-container" fxLayout="column" fxLayoutGap="8px">
      <div
        fxLayout="column"
        formArrayName="intervalList"
        *ngFor="let interval of intervalListControls; let i = index"
      >
        <div [formGroupName]="i" fxLayout="row" fxLayoutGap="10px">
          <sso-select
            label="Start Year"
            [options]="getStartYearOptions(interval)"
            [control]="interval?.controls?.startYear"
            [disabled]="!!!i"
            (selectionChange)="updateRange(i, $event)"
            fxFlex="calc(30%-10px)"
          ></sso-select>
          <sso-select
            label="End Year"
            [options]="getEndYearOptions(interval)"
            [control]="interval?.controls?.endYear"
            [disabled]="true"
            fxFlex="calc(30%-10px)"
          ></sso-select>

          <mat-form-field
            appearance="outline"
            [@fadeIn]
            fxFlex="calc(30%-10px)"
          >
            <mat-label>Value</mat-label>
            <input
              matInput
              ssoCommify
              formControlName="value"
              placeholder="0"
              [type]="'text'"
              required
            />
          </mat-form-field>

          <button
            type="button"
            mat-icon-button
            color="primary"
            (click)="deleteRange(i)"
            *ngIf="i"
            [disabled]="isViewMode"
          >
            <mat-icon color="warn" *ngIf="!isViewMode">cancel</mat-icon>
          </button>
          <div *ngIf="!i" fxFlex="40px"></div>
        </div>

        <mat-error
          *ngFor="let error of interval?.controls?.value?.errors | keyvalue"
          [innerHtml]="errorMessage + '.' + error?.key | translate"
        ></mat-error>
        <mat-error
          *ngFor="let error of interval?.errors | keyvalue"
          [innerHtml]="
            errorMessage + '.' + error?.key | translate: { param: error?.value }
          "
        ></mat-error>
      </div>
    </div>
  </ng-template>
  <button
    type="button"
    mat-button
    color="primary"
    (click)="addRange()"
    [disabled]="isDisabled()"
    *ngIf="!isEnd()"
    [hidden]="isViewMode"
  >
    <mat-icon>add</mat-icon>
    <span>Interval</span>
  </button>
</form>

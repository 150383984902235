import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { SSOSpinnerModule } from '../spinner';
import { ViewDataSetModule } from '../view-dataset';
import { TabbedAreaChartComponent } from './tabbed-area-chart.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

@NgModule({
  declarations: [TabbedAreaChartComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    NgxChartsModule,
    MatTabsModule,
    MatIconModule,
    SSOSpinnerModule,
    NgxSliderModule,
    ViewDataSetModule,
  ],
  exports: [TabbedAreaChartComponent],
})
export class TabbedAreaChartModule {}

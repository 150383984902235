export enum SelectionDirection {
  DOWN_RIGHT_ISH, // covers right, down right, down
  DOWN_LEFT,
  UP_LEFT_ISH, // covers left, up_left, up
  UP_RIGHT,
}

/*
  A = starting point
  B = ending point
                  (x,y)       (x,y)
  direction       topLeft     bottomRight
  ----------------------------------------
  right           A,A         B,B ------|
  down_right      A,A         B,B       |======= same
  down            A,A         B,B ------|
  down_left       B,A         A,B ==============
  left            B,B         A,A ------|
  up_left         B,B         A,A       |======= same
  up              B,B         A,A ------|
  up_right        A,B         B,A ==============
*/

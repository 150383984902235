import { Pipe, PipeTransform } from '@angular/core';

/**
 * @input - number to convert
 * @decimalCount - number of decimals
 * @unit - unit to be appended
 * Implementation:
 * {{ 1234 | metricScale  }} <!-- 1.23 k -->
 * {{ 1234 | metricScale : 0  }}  <!-- 1 k -->
 */
@Pipe({
  name: 'metricScale',
})
export class MetricScalePipe implements PipeTransform {
  transform(
    input: any,
    decimalCount: number = 2,
    unit: string = '',
  ): { value: string; unit?: string } {
    let parsedInput = Number.parseFloat(input);

    if (!parsedInput && parsedInput !== 0) {
      return null;
    }

    let scaleList = ['', 'k', 'M', 'G', 'T', 'P'];
    let index = 0;

    if (unit.slice(0, 2) === 'kg') {
      scaleList = ['kg', 't', 'kt', 'Mt', 'Gt', 'Tt'];
    } else if (scaleList.includes(unit[0])) {
      index = scaleList.indexOf(unit[0]);
    }

    const baseUnit = unit.slice(scaleList[index].length);

    while (parsedInput >= 1000 && index < scaleList.length - 1) {
      parsedInput = parsedInput / 1000;
      index += 1;
    }

    const parsedValue = parsedInput.toFixed(decimalCount);
    const scale = scaleList[index];
    return {
      value: unit ? parsedValue : `${parsedValue} ${scale}`,
      unit: scale + baseUnit,
    };
  }
}

import { ExistingRenewable } from 'ssotool-app/+client/store';

export enum ExistingRenewableDrawerMode {
  EDIT = 'edit',
  CREATE = 'create',
  VIEW = 'view',
}

export type ExistingRenewableDrawerData = Readonly<{
  mode: ExistingRenewableDrawerMode;
  clientId: string;
  existingRenewable?: Partial<ExistingRenewable>;
  readonly?: boolean;
}>;

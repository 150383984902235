import { CO2_EMISSIONS_STR } from 'ssotool-app/app.references';

export const ICON_REFERENCES = {
  icon: {
    totalInvestmentCost: 'sso-icon-yellow nj-icon-electric-car',
    totalOperationalCost: 'sso-icon-yellow nj-icon-hand-tool',
    npv: 'sso-icon-yellow nj-icon-card-money',
    totalCo2SavingsEmission: 'sso-icon-green nj-icon-document-search',
    totalCo2Emissions: 'sso-icon-green nj-icon-gauge',
    totalEnergyConsumption: 'sso-icon-blue nj-icon-twenty-four',
  },
  label: {
    totalInvestmentCost: 'capex',
    totalOperationalCost: 'opex',
    npv: 'Discounted Cost',
    totalCo2SavingsEmission: 'Emission Savings',
    totalCo2Emissions: CO2_EMISSIONS_STR,
    totalEnergyConsumption: 'energy inflow',
  },
};

export const AUTO_SAVE_DEBOUNCE = 1000;

export const BASELINE_CAMPAIGN_PATHWAY = 'Baseline';

export const CUSTOM_RUN_OPTIONS = 'settings';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LogViewerModule } from 'ssotool-app/shared/modules/log-viewer/log-viewer.module';
import { ProgressIndicatorModule, TitleBarModule } from 'ssotool-shared';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';

import { CampaignImportDialogModule } from '../import-dialog';
import { CampaignImportComponent } from './import.component';

@NgModule({
  declarations: [CampaignImportComponent],
  exports: [CampaignImportComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    CampaignImportDialogModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    TitleBarModule,
    NgxSkeletonLoaderModule,
    ProgressIndicatorModule,
    LogViewerModule,
    MatMenuModule,
  ],
})
export class CampaignImportModule {}

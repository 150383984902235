import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';

import { AmbitionActions } from './ambition.action';
import { TargetFormModel, TargetModel } from './ambition.model';
import { AmbitionState } from './ambition.reducer';
import {
  ambitionList,
  ambitionListEntities,
  loaded,
  loading,
} from './ambition.selector';

@Injectable()
export class AmbitionFacadeService {
  constructor(
    private store: Store<AmbitionState>,
    private actionSubject$: ActionsSubject,
  ) {}

  getTargetEntities$: Observable<Record<string, TargetModel>> = this.store.pipe(
    select(ambitionListEntities),
  );
  getTargetIdList$ = this.store.pipe(select(ambitionList));
  loading$ = this.store.pipe(select(loading));
  loaded$ = this.store.pipe(select(loaded));
  getUniqueCompositeKeys$ = this.getTargetEntities$.pipe(
    map(Object.values),
    map((constraints) => {
      return Array.from(
        new Set(constraints.map((constraint) => constraint.compositeKey)),
      ).map((compositeKey) => ({ compositeKey }));
    }),
  );

  getList(clientId: string) {
    this.store.dispatch(AmbitionActions.getList({ clientId }));
  }

  updateTargets(
    clientId: string,
    constraints: { constraintId: string; isTarget: boolean }[],
  ) {
    this.store.dispatch(
      AmbitionActions.updateTargets({ clientId, constraints: constraints }),
    );
    return this.actionSubject$.pipe(
      filter(
        (action) => action.type === AmbitionActions.updateTargetsSuccess.type,
      ),
    );
  }

  createCustomTarget(clientId: string, data: TargetFormModel) {
    this.store.dispatch(AmbitionActions.createCustomTarget({ clientId, data }));
    return this.actionSubject$.pipe(
      filter(
        (action) =>
          action.type === AmbitionActions.createCustomTargetSuccess.type,
      ),
      map((action) => action?.['data']),
    );
  }

  editCustomTarget(clientId: string, data: TargetFormModel) {
    this.store.dispatch(AmbitionActions.editCustomTarget({ clientId, data }));
    return this.actionSubject$.pipe(
      filter(
        (action) =>
          action.type === AmbitionActions.editCustomTargetSuccess.type,
      ),
      map((action) => action?.['data']),
    );
  }

  deleteCustomTarget(clientId: string, constraintId: string) {
    this.store.dispatch(
      AmbitionActions.deleteCustomTarget({ clientId, constraintId }),
    );
  }

  clearList() {
    this.store.dispatch(AmbitionActions.clearList());
  }
}

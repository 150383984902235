import { ShareClientTableModule } from 'ssotool-client/containers/share-client-table';
import { SelectModule } from 'ssotool-shared/modules';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ShareClientComponent } from './share-client.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [ShareClientComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    ShareClientTableModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    SelectModule,
    TranslateModule,
    MatTooltipModule,
    MatAutocompleteModule,
  ],
  exports: [ShareClientComponent],
})
export class ShareClientModule {}

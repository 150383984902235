import { Observable, Observer } from 'rxjs';
import { map, scan, skipWhile, switchMap, tap } from 'rxjs/operators';

import { HttpEvent } from '@angular/common/http';
import { RendererFactory2 } from '@angular/core';

import { isHttpProgressEvent, isHttpResponse } from './download.guard';
import { Download, DownloadContent } from './download.model';

export function download(
  totalSize?: number,
  callbackFn?: (b: Blob) => void,
): (source: Observable<HttpEvent<Blob>>) => Observable<Download> {
  return (source: Observable<HttpEvent<Blob>>) =>
    source.pipe(
      scan(
        (previous: Download, event: HttpEvent<Blob>): Download => {
          if (isHttpProgressEvent<Blob>(event)) {
            const total = event.total || totalSize;
            return {
              progress: total
                ? Math.round((100 * event.loaded) / total)
                : previous.progress,
              state: 'IN_PROGRESS',
              content: null,
            };
          } else if (isHttpResponse(event)) {
            /* istanbul ignore else */
            if (callbackFn && event.body) {
              callbackFn(event.body);
            }

            return {
              progress: 100,
              state: 'DONE',
              content: event.body,
            };
          } else {
            return previous;
          }
        },
        { state: 'PENDING', progress: 0, content: null },
      ),
    );
}

export function processDownloadedData<T = any>(): (
  source: Observable<Download>,
) => Observable<T> {
  return (source: Observable<Download>) =>
    source.pipe(
      skipWhile((downloadedData) => !downloadedData?.content),
      switchMap(
        (response) =>
          new Observable<T>((observer: Observer<any>) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              observer.next(reader.result);
              observer.complete();
            };
            reader.readAsText(response.content);
          }),
      ),
    );
}

export function downloadFile(
  factory: RendererFactory2,
  filename: string,
): (source: Observable<Download>) => Observable<DownloadContent> {
  return (source: Observable<Download>) =>
    source.pipe(
      skipWhile((downloadedData) => !downloadedData?.content),
      map((data) => data?.content),
      tap((content) => {
        const blob = new Blob([content], {
          type: 'applications/octet-stream',
        });
        const resUrl = window.URL.createObjectURL(blob);
        const renderer = factory.createRenderer(null, null);
        const anchor = renderer.createElement('a');
        renderer.setAttribute(anchor, 'download', filename);
        renderer.setAttribute(anchor, 'href', resUrl);
        anchor.click();
      }),
    );
}

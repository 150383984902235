<div fxLayout="column" fxLayoutAlign="start space-between">
  <div class="info-message" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="start center" fxLayoutGap="12px">
      <mat-icon matPrefix *ngIf="icon">{{ icon }}</mat-icon>
      <span [innerHtml]="message"></span>
    </div>
    <div fxLayoutAlign="end center" fxFlex *ngIf="displayCancelButton">
      <button
        data-testid="cancel-button"
        mat-flat-button
        color="warn"
        (click)="onCancel()"
      >
        <b>{{ cancelButtonMessage }}</b>
      </button>
    </div>
  </div>
  <mat-progress-bar
    *ngIf="hasProgressBar"
    class="progress-bar"
    [mode]="mode"
    [value]="value"
  ></mat-progress-bar>
</div>

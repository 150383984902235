<mat-form-field
  [hideRequiredMarker]="hideRequiredMarker"
  [appearance]="readonly ? 'fill' : appearance || 'outline'"
  floatLabel="always"
  [class]="customClassMapValue$ | async"
  [style.pointerEvents]="readonly ? 'none' : ''"
>
  <mat-label>{{ label }}</mat-label>
  <mat-select
    *ngIf="!readonly; else inputReadonly"
    [formControl]="control"
    [required]="required"
    (selectionChange)="onSelectionChange($event)"
    [aria-label]="label"
    [placeholder]="placeholder"
    [panelWidth]="'auto'"
    panelClass="fit-selection-panel"
  >
    <mat-select-trigger
      fxLayout="row"
      fxLayoutGap="5px"
      fxLayoutAlign="space-evenly center"
      *ngIf="selected$ | async as selected"
    >
      <mat-icon *ngIf="selected?.icon as icon">{{ icon }}</mat-icon>
      <span>{{ selected?.name }}</span>
    </mat-select-trigger>
    <mat-option *ngIf="!required"></mat-option>
    <mat-option
      *ngFor="let option of options"
      value="{{ option.value }}"
      [disabled]="isDividerOption(option.value)"
      [style.text-align]="isDividerOption(option.value) ? 'center' : ''"
    >
      <span>
        <mat-icon *ngIf="option?.icon">{{ option?.icon }}</mat-icon>
        {{ option?.name }}
      </span>
    </mat-option>
  </mat-select>
  <ng-template #inputReadonly>
    <input
      data-testid="input-field"
      matInput
      [attr.aria-label]="label"
      [value]="getInputValue(control.value)"
      [readonly]="true"
    />
  </ng-template>
  <mat-icon
    *ngIf="tooltip"
    matSuffix
    class="input-tooltip-anchor"
    [matTooltip]="tooltip | translate"
    >help</mat-icon
  >
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <mat-error
    *ngFor="let error of control?.errors | keyvalue"
    [innerHtml]="errorMessageMap[error?.key]"
  ></mat-error>
</mat-form-field>

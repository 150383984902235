import { ClickMode } from 'ssotool-shared/directives/selection-emitter/selection-emitter.model';

export interface SelectionEventData {
  mode: ClickMode;
  id: string;
}

export type ClickSelectHandlerType = (id: string) => string[];

export const MULTISELECTION_CLASS = 'multiselection-class';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FileUploadComponent } from './file-upload.component';

const components = [FileUploadComponent];

@NgModule({
  declarations: [...components],
  exports: [...components],
})
export class FileUploadModule {}

// TODO: PROMOTE TO EYES_SHARED once needed.

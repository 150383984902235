/**
 * Set item to LocalStorage.
 * @param key string to use as key.
 * @param itemData object or value of item to be saved.
 */
export const setItem = (key: string, itemData: any) => {
  window.localStorage.setItem(key, JSON.stringify(itemData));
};
/**
 * Get Item from LocalStorage.
 * @param key string key of object to be retrieve.
 */
export const getItem = (key: string): any => {
  return JSON.parse(window.localStorage.getItem(key));
};
/**
 * Remove Item from LocalStorage.
 * @param key string key of object to be remove.
 */
export const removeItem = (key: string) => {
  window.localStorage.removeItem(key);
};

export const localStorage = {
  set: setItem,
  get: getItem,
  remove: removeItem,
};

import { Coerce } from 'ssotool-app/shared/helpers';
import {
  FormFieldErrorMessageMap,
  FormFieldOption,
} from 'ssotool-shared/helpers/form-helpers';

import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class FormService {
  constructor(
    private _formBuilder: FormBuilder,
    private _i18n: TranslateService,
  ) {}

  initForm(config: any, extra?: any): FormGroup {
    return this._formBuilder.group(config, extra);
  }

  convertToFormFieldOptions(obj: {
    [key: string]: string;
  }): Array<FormFieldOption<any>> {
    return Coerce.getObjKeys(obj).map((key) => ({
      name: obj[key],
      value: key,
    }));
  }

  getErrorMessageMap(path: string): FormFieldErrorMessageMap {
    const translation = this._i18n.instant(path);
    return translation || {};
  }
}

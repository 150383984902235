import { v4 as uuid } from 'uuid';

/**
 * uuid generator with 36 characters
 */
export const generateUuid = (): string => {
    return uuid();
};

/**
 * uuid generator that cuts the generated uuid to 5 characters.
 * only returns the last 5 digits of the uuid generated.
 */
export const generateShortUID = (): string =>
    generateUuid().substring(5, -5);


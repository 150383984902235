<oculus-details-drawer #drawer *ngIf="isLoggedIn$ | async; else login">
  <oculus-drawer
    [links]="[]"
    headerLogo="assets/logos/logo-ellipse.svg"
    headerLogoMini="assets/logos/logo-ellipse-small.svg"
    footerLogo="assets/logos/logo-engie-impact-blue.jpg"
    footerLogoMini="assets/logos/logo-engie-small-blue.svg"
    *ngIf="isLoggedIn$ | async; else login"
    [sidenavVisible]="isSideNavVisible$ | async"
  >
    <mat-nav-list oculus-drawer-nav class="side-nav" data-testid="side-nav">
      <a
        *ngFor="let link of drawerLinks$ | async"
        mat-list-item
        routerLinkActive="oculus-active-link"
        [routerLinkActiveOptions]="{ exact: !!link.pathExactMatch }"
        [disabled]="link.disabled"
        [matTooltip]="link.label"
        [routerLink]="link.path"
        [attr.data-testid]="link.label.toLowerCase().replace(' ', '-') + '-nav'"
      >
        <div class="drawer-link">
          <mat-icon
            fontSet="material-icons-outlined"
            [ngClass]="{ 'icon-rotate': link.iconRotate }"
            >{{ link.icon }}</mat-icon
          >

          <span>{{ link.label }}</span>
        </div>
      </a>
    </mat-nav-list>

    <div fxLayout="column" fxLayoutAlign="center stretch">
      <sso-release-notes-banner
        *ngIf="showReleaseNotesBanner$ | async"
        [id]="releaseId$ | async"
        [version]="releaseVersion$ | async"
      ></sso-release-notes-banner>
    </div>
    <div class="header-toolbar">
      <div fxLayout="row" fxLayoutAlign="space-between stretch">
        <sso-client-header [isSideNavVisible]="isSideNavVisible$ | ngrxPush">
          <div class="global-actions" header-global-actions>
            <span class="sso-quick-links sso-text-gray" fxLayout="row">
              <a
                *ngIf="userManager.hasAlphaRole() | async"
                mat-icon-button
                matTooltip="Request Inbox"
                routerLink="requests"
                routerLinkActive="sso-quick-link-active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <mat-icon>email</mat-icon>
              </a>
              <a mat-icon-button matTooltip="Notifications">
                <mat-icon>notifications</mat-icon>
              </a>
              <!-- <a
                mat-icon-button
                matTooltip="Switch Client"
                routerLink="clients"
                routerLinkActive="sso-quick-link-active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <mat-icon>business_center</mat-icon>
              </a> -->

              <a
                *ngIf="userManager.isSuperUser() | async"
                mat-icon-button
                matTooltip="Global Import"
                routerLink="globalImport"
                routerLinkActive="sso-quick-link-active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <mat-icon>cloud_upload</mat-icon>
              </a>
              <a
                mat-icon-button
                matTooltip="Account"
                ssoTooltip
                [config]="accountConfigTooltip"
                [content]="accountMenu"
              >
                <mat-icon>account_circle</mat-icon>
              </a>

              <div #accountMenu class="account-menu">
                <div
                  fxLayout="column"
                  fxLayoutAlign="center center"
                  fxLayoutGap="10px"
                >
                  <b>{{ username$ | async | titlecase }}</b>
                  <button
                    mat-flat-button
                    type="submit"
                    color="primary"
                    (click)="onClickLogout()"
                  >
                    {{ 'Generic.labels.logout' | translate }}
                  </button>
                </div>
              </div>
            </span>
          </div>
        </sso-client-header>
      </div>
    </div>

    <div
      class="sso-content"
      [ngClass]="{ 'sso-disable-title-bar': isDataImport$ | async }"
    >
      <router-outlet></router-outlet>
    </div>

    <oculus-footer class="sso-footer">
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <a
          class="sso-support"
          routerLink="release-notes"
          routerLinkActive="sso-quick-link-active"
          [routerLinkActiveOptions]="{ exact: true }"
          target="_blank"
        >
          Release Notes
        </a>
        <!-- <div class="sso-version" *ngIf="version$ | async as ver">
          (v{{ ver }})
        </div> -->
      </div>
      <div>
        <span>Need help?&nbsp;</span>
        <a
          class="sso-support"
          routerLink="support"
          routerLinkActive="sso-quick-link-active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          Ask for Support
        </a>
      </div>
    </oculus-footer>
  </oculus-drawer>
</oculus-details-drawer>

<ng-template #login>
  <router-outlet></router-outlet>
</ng-template>

<div class="container">
  <div class="header">
    <sso-combination-filter
      [options]="filters$ | ngrxPush"
      (selectedFiltersChange)="onSelectedFiltersChange($event)"
    ></sso-combination-filter>
  </div>
  <div
    class="main"
    fxLayout="column"
    fxLayoutAlign="center stretch"
    *ngIf="!!data.length; else noData"
  >
    <div
      class="table"
      *ngFor="let group of groups$ | ngrxPush; let groupIndex = index"
      fxLayout="column"
      fxLayoutAlign="center stretch"
      fxLayoutGap="16px"
    >
      <h2 *ngIf="groupIndex !== 0">
        {{ 'Entities.table.sections.' + group.labelKey | translate }}
      </h2>
      <mat-table [dataSource]="dataSource">
        <ng-container
          *ngFor="let definition of columnDefinitions$ | ngrxPush"
          [matColumnDef]="definition.id"
        >
          <mat-header-cell *matHeaderCellDef>
            <ng-container [ngSwitch]="definition.type">
              <ng-container *ngSwitchCase="'selection'">
                <!-- <mat-checkbox
                (change)="onSelectionEvent($event, null)"
                [checked]="areAllSelected$ | ngrxPush"
                color="primary"
              ></mat-checkbox> -->
                <mat-checkbox
                  [matMenuTriggerFor]="selectionMenu"
                  [checked]="areAllSelected$ | ngrxPush"
                  color="primary"
                  disabled
                ></mat-checkbox>
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ 'Entities.columns.' + definition.labelKey | translate }}
              </ng-container>
            </ng-container>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <ng-container [ngSwitch]="definition.type">
              <ng-container *ngSwitchCase="'selection'">
                <mat-checkbox
                  (change)="onSelectionEvent($event, row)"
                  color="primary"
                  [checked]="itemSelections.isSelected(row.id)"
                ></mat-checkbox>
              </ng-container>
              <ng-container *ngSwitchCase="'text'">
                {{ row.value[definition.id] }}
              </ng-container>
              <ng-container *ngSwitchCase="'textOverflow'">
                <div class="text-overflow">
                  {{ row.value[definition.id] }}
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'number'">
                {{ row.value[definition.id] | number }}
              </ng-container>
              <ng-container *ngSwitchCase="'date'">
                {{ row.value[definition.id] | date: 'dd/MM/yyyy' }}
              </ng-container>
              <ng-container *ngSwitchCase="'currency'">
                {{ row.value[definition.id] | currency: 'EUR' }}
              </ng-container>
              <ng-container *ngSwitchCase="'sparkline'">
                <sso-yearly-input
                  [startYear]="2010"
                  [endYear]="2050"
                  [readonly]="true"
                  [noControlValue]="row.value[definition.id]"
                ></sso-yearly-input>
              </ng-container>
            </ng-container>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="columnsToDisplay$ | ngrxPush"
          [class.hide]="groupIndex !== 0"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: columnsToDisplay$ | ngrxPush"
          [class.hide]="row.groupId !== group.id"
        >
        </mat-row>
      </mat-table>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
      <!-- <mat-paginator pageSize="5"></mat-paginator> -->
    </div>
  </div>
  <ng-template #noData>
    <div class="no-data" fxLayout="column" fxLayoutAlign="center stretch">
      <span fxLayout="column" fxLayoutAlign="center stretch">{{
        'Entities.labels.noData' | translate
      }}</span>
    </div>
  </ng-template>
</div>

<mat-menu class="mat-menu-no-max" #selectionMenu="matMenu">
  <div mat-menu-item *ngFor="let group of groups$ | ngrxPush">
    <mat-checkbox
      color="primary"
      [checked]="getSelectedStatus(group.id)"
      (change)="groupSelect($event, group.id)"
      (click)="$event.stopPropagation()"
    >
      {{ 'Entities.table.sections.' + group.labelKey | translate }}
    </mat-checkbox>
  </div>
</mat-menu>

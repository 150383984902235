import * as _moment from 'moment';
import { Options } from '@angular-slider/ngx-slider';
import { BehaviorSubject, combineLatest, of, pipe } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';

import { Injectable } from '@angular/core';

type ChartData<X, V> = Readonly<{
  name: X;
  value: V;
}>;

const DEFAULT_DEBOUNCE_TIME = 400;
const MONTH_FORMAT = 'MMM';
const DAY_FORMAT = 'D';

const moment = _moment;

export interface DateChartData extends ChartData<string, number> {
  moment: _moment.Moment;
}

@Injectable({ providedIn: 'root' })
export class DateFilterService {
  minDate$ = new BehaviorSubject<number>(1);
  maxDate$ = new BehaviorSubject<number>(7);
  year = 2019;
  defaultSliderOptions = {
    floor: 1,
    ceil: 365,
    step: 1,
    minRange: 0,
    maxRange: 31,
    draggableRange: true,
    showTicks: true,
  };
  defaultSliderOptions$ = new BehaviorSubject(this.defaultSliderOptions);

  constructor() {}

  dateChange$ = combineLatest([this.minDate$, this.maxDate$]).pipe(
    startWith([this.minDate$.value, this.maxDate$.value]),
    debounceTime(DEFAULT_DEBOUNCE_TIME),
    distinctUntilChanged(),
  );

  sliderOptions$ = of(this.defaultSliderOptions$).pipe(
    map((sliderOptions) =>
      this.getSliderOptions(this.year, sliderOptions.value),
    ),
  );

  filter$ = (
    name: string,
    year: number = this.year,
    dateFormat: string = 'Hourly',
  ) =>
    pipe(
      switchMap(([data, [minDay, maxDay]]) => {
        const minDate = minDay
          ? moment({ y: year, M: 0, d: 0, h: 0, m: 0, s: 0, ms: 0 }).dayOfYear(
              minDay,
            )
          : undefined;
        const maxDate = maxDay
          ? moment({
              y: year,
              M: 0,
              d: 0,
              h: 23,
              m: 59,
              s: 59,
              ms: 999,
            }).dayOfYear(maxDay)
          : undefined;
        return !data
          ? of([])
          : of([
              {
                name,
                series: data
                  .map((item, index) =>
                    this.mapDataToSeries(item, index, year, dateFormat),
                  )
                  .filter((seriesItem) =>
                    this.filter(seriesItem, minDate, maxDate),
                  ),
              },
            ]);
      }),
    );

  mapDataToSeries(
    item: string,
    index: number,
    year: number = this.year,
    dateFormat = 'Hourly',
  ): DateChartData {
    let dateTime: any;
    let name: string;
    if (dateFormat === 'Hourly') {
      dateTime = moment({ y: year, M: 0, d: 0, h: 0, m: 0, s: 0, ms: 0 }).hour(
        index,
      );
      name = dateTime.format('MMM-DD HH:mm');
    } else if (dateFormat === 'Daily') {
      dateTime = moment({
        y: year,
        M: 0,
        d: 0,
        h: 0,
        m: 0,
        s: 0,
        ms: 0,
      }).dayOfYear(index + 1);
      name = dateTime.format('MMM-DD');
    } else if (dateFormat === 'Monthly') {
      dateTime = moment({ y: year, M: 0, d: 0, h: 0, m: 0, s: 0, ms: 0 }).month(
        index,
      );
      name = dateTime.format('MMM');
    } else if (dateFormat === 'Yearly') {
      dateTime = moment({ y: year, M: 0, d: 0, h: 0, m: 0, s: 0, ms: 0 }).year(
        year + index,
      );
      name = dateTime.format('YYYY');
    }

    return {
      name,
      moment: dateTime,
      value: !Number.isFinite(Number.parseFloat(item))
        ? 0
        : Number.parseFloat(item),
    };
  }

  filter(
    seriesData: DateChartData,
    minDate: _moment.Moment,
    maxDate: _moment.Moment,
  ): boolean {
    return minDate && maxDate
      ? minDate.isSameOrBefore(seriesData.moment) &&
          maxDate.isSameOrAfter(seriesData.moment)
      : true;
  }

  getSliderOptions(
    year: number = this.year,
    sliderOptions = this.defaultSliderOptions,
  ): Options {
    const sliderTicks = [];
    const monthIndexes = Array.from(Array(12).keys());

    let accumulatedDays = 0;
    monthIndexes.forEach((value) => {
      const daysInMonth = moment().year(year).month(value).daysInMonth();
      const monthName = moment().month(value).format(MONTH_FORMAT);
      const midDayInMonth = daysInMonth / 2;
      const position = accumulatedDays + midDayInMonth;
      accumulatedDays += daysInMonth;
      sliderTicks.push({
        value: accumulatedDays,
        legend: monthName,
        position: position.toFixed(0),
      });
    });

    return {
      ...sliderOptions,
      ceil: accumulatedDays,
      translate: (value: number): string => {
        const date = moment()
          .year(year)
          .dayOfYear(value)
          .format(`${MONTH_FORMAT} ${DAY_FORMAT}`);
        return date;
      },
      getLegend: (value: number): string => {
        const positions = sliderTicks.map((tick) => tick.position);
        if (positions.includes('' + value)) {
          return sliderTicks.find((tick) => tick.position === '' + value)
            .legend;
        }
        return '';
      },
    };
  }
}

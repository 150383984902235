<form
  [formGroup]="baseForm"
  fxLayout="column"
  fxLayoutGap="10px"
  data-testid="form-container"
>
  <button mat-button style="height: 0; position: absolute">
    <!-- 'absorbs' the auto-focus behavior -->
  </button>

  <div class="drawer-contents">
    <div class="contents-loader" *ngIf="loading$ | async; else contents">
      <ngx-skeleton-loader
        [count]="1"
        animation="progress"
        [theme]="{ height: '60px', width: '100%' }"
      ></ngx-skeleton-loader>
      <mat-divider></mat-divider>
      <ngx-skeleton-loader
        [count]="4"
        animation="progress"
        [theme]="{ height: '40px', width: '40%', 'margin-right': '16px' }"
      ></ngx-skeleton-loader>
    </div>
    <ng-template #contents>
      <div class="general-container" fxLayout="column" fxLayoutGap="5px">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <sso-input
            class="full-width"
            data-testid="input-name-field"
            appearance="fill"
            size="large"
            [placeholder]="'Entities.placeholders.parameters.name' | translate"
            [label]="'Entities.labels.parameters.name' | translate"
            formControlName="name"
            [required]="true"
            [errorMessageMap]="errorMessages?.name"
            [readonly]="readonly$ | async"
          ></sso-input>
        </div>
        <mat-divider></mat-divider>
      </div>

      <!--Associated entities-->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header
          collapsedHeight="35px"
          expandedHeight="35px"
        >
          <mat-panel-title>
            <h3 class="mat-headline-6">
              {{ 'Entities.labels.parameters.entities' | translate }}
            </h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row wrap" fxLayoutGap="10px">
          <!-- Geography -->
          <sso-select
            *ngIf="!isSiteLevel"
            data-testid="select-geo-field"
            [label]="'Entities.labels.parameters.geoId' | translate"
            [options]="geoOptions$ | async"
            [control]="baseForm?.controls?.geoId"
            [required]="true"
            [errorMessageMap]="errorMessages?.geoId"
            [readonly]="readonly$ | async"
          ></sso-select>
          <sso-select
            *ngIf="isSiteLevel"
            data-testid="select-site-field"
            [label]="'Entities.labels.parameters.siteId' | translate"
            [options]="geoOptions$ | async"
            [control]="baseForm?.controls?.siteId"
            [required]="true"
            [errorMessageMap]="errorMessages?.siteId"
            [readonly]="readonly$ | async"
          ></sso-select>
          <!-- Company Entity -->
          <sso-select
            data-testid="select-company-field"
            *ngIf="!isSimplifiedFeatureEnabled"
            [label]="'Entities.labels.parameters.companyId' | translate"
            [options]="companyOptions$ | async"
            [control]="baseForm?.controls?.companyEntityId"
            [required]="true"
            [errorMessageMap]="errorMessages?.companyId"
            [readonly]="readonly$ | async"
          >
          </sso-select>
          <!-- Sector -->
          <sso-select
            data-testid="select-sector-field"
            *ngIf="!isSimplifiedFeatureEnabled"
            [label]="'Entities.labels.parameters.sectorId' | translate"
            [options]="sectorOptions$ | async"
            [control]="baseForm?.controls?.sectorId"
            [readonly]="readonly$ | async"
          ></sso-select>
          <!-- Process -->
          <sso-select
            data-testid="select-process-field"
            [label]="'Entities.labels.parameters.processId' | translate"
            [options]="processOptions$ | async"
            [control]="baseForm?.controls?.processId"
            [readonly]="readonly$ | async"
          >
          </sso-select>
          <!-- Fluid -->
          <sso-select
            data-testid="select-fluid-field"
            [label]="'Entities.labels.parameters.fluidId' | translate"
            [options]="fluidOptions$ | async"
            [control]="baseForm?.controls?.fluidId"
            [readonly]="readonly$ | async"
          >
          </sso-select>
          <!-- Scaling Factor -->
          <sso-input
            *ngIf="isSimplifiedFeatureEnabled && hasScalingFactor"
            data-testid="input-scaling-factor-name-field"
            [placeholder]="
              'Entities.placeholders.parameters.scalingFactorName' | translate
            "
            [label]="'Entities.labels.parameters.scalingFactorName' | translate"
            formControlName="scalingFactorName"
            [readonly]="readonly$ | async"
          ></sso-input>
        </div>
      </mat-expansion-panel>
      <!--Main parameters-->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header
          collapsedHeight="35px"
          expandedHeight="35px"
        >
          <mat-panel-title>
            <h3 class="mat-headline-6">
              {{ 'Entities.labels.parameters.main' | translate }}
            </h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row wrap" fxLayoutGap="20px">
          <!-- Volume -->
          <sso-yearly-input
            data-testid="yearly-volume-field"
            formControlName="volume"
            [startYear]="startYear"
            [endYear]="endYear"
            [errorMessage]="'Entities.messages.errors.volume'"
            [label]="'Entities.labels.existingContract.volume' | translate"
            [readonly]="readonly$ | async"
          ></sso-yearly-input>
          <!-- Duration -->
          <sso-input
            data-testid="input-duration-field"
            [type]="'number'"
            [placeholder]="
              'Entities.placeholders.parameters.duration' | translate
            "
            [label]="'Entities.labels.existingContract.duration' | translate"
            [formControl]="baseForm?.controls?.duration"
            [required]="true"
            [errorMessageMap]="errorMessages?.duration"
            [readonly]="readonly$ | async"
          ></sso-input>
        </div>
      </mat-expansion-panel>
      <!--Emission Parameters -->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header
          collapsedHeight="35px"
          expandedHeight="35px"
        >
          <mat-panel-title>
            <h3 class="mat-headline-6">
              {{ 'Entities.labels.parameters.emission' | translate }}
            </h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row wrap" fxLayoutGap="10px">
          <!-- Co2 Rate Scope 1 -->
          <sso-yearly-input
            data-testid="yearly-co2RateScope1-field"
            formControlName="co2RateScope1"
            [startYear]="startYear"
            [endYear]="endYear"
            [errorMessage]="'Entities.messages.errors.co2RateScope1'"
            [label]="
              'Entities.labels.existingContract.co2RateScope1' | translate
            "
            [readonly]="readonly$ | async"
          ></sso-yearly-input>
          <!-- Co2 Rate Scope 2 -->
          <sso-yearly-input
            data-testid="yearly-co2RateScope2-field"
            formControlName="co2RateScope2"
            [startYear]="startYear"
            [endYear]="endYear"
            [errorMessage]="'Entities.messages.errors.co2RateScope2'"
            [label]="
              'Entities.labels.existingContract.co2RateScope2' | translate
            "
            [readonly]="readonly$ | async"
          ></sso-yearly-input>
          <!-- Co2 Rate Scope 3 -->
          <sso-yearly-input
            data-testid="yearly-co2RateScope3-field"
            formControlName="co2RateScope3"
            [startYear]="startYear"
            [endYear]="endYear"
            [errorMessage]="'Entities.messages.errors.co2RateScope3'"
            [label]="
              'Entities.labels.existingContract.co2RateScope3' | translate
            "
            [readonly]="readonly$ | async"
          ></sso-yearly-input>
        </div>
      </mat-expansion-panel>

      <!--Cost Parameters -->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header
          collapsedHeight="35px"
          expandedHeight="35px"
        >
          <mat-panel-title>
            <h3 class="mat-headline-6">
              {{ 'Entities.labels.parameters.cost' | translate }}
            </h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row wrap" fxLayoutGap="10px">
          <!-- Fixed Price -->
          <sso-yearly-input
            data-testid="yearly-fixedPrice-field"
            formControlName="fixedPrice"
            [startYear]="startYear"
            [endYear]="endYear"
            [errorMessage]="'Entities.messages.errors.fixedPrice'"
            [label]="'Entities.labels.existingContract.fixedPrice' | translate"
            [readonly]="readonly$ | async"
          ></sso-yearly-input>
          <!-- Indivisible Cost -->
          <sso-yearly-input
            data-testid="yearly-indivisibleCost-field"
            formControlName="indivisibleCost"
            [startYear]="startYear"
            [endYear]="endYear"
            [errorMessage]="'Entities.messages.errors.indivisibleCost'"
            [label]="
              'Entities.labels.existingContract.indivisibleCost' | translate
            "
            [readonly]="readonly$ | async"
          ></sso-yearly-input>
          <!--tax-->
          <sso-yearly-input
            data-testid="yearly-tax-field"
            formControlName="tax"
            [startYear]="startYear"
            [endYear]="endYear"
            [errorMessage]="'Entities.messages.errors.tax'"
            [label]="'Entities.labels.existingContract.tax' | translate"
            [readonly]="readonly$ | async"
          ></sso-yearly-input>
        </div>
      </mat-expansion-panel>
    </ng-template>
  </div>
  <div
    class="drawer-actions"
    fxLayout="row"
    fxLayoutGap="5px"
    fxLayoutAlign="end center"
  >
    <ngx-skeleton-loader
      *ngIf="loading$ | async; else actions"
      [count]="1"
      animation="progress"
      [theme]="{ height: '40px', width: '100%' }"
    ></ngx-skeleton-loader>
    <ng-template #actions>
      <button
        (click)="onClose()"
        data-testid="close-button"
        mat-stroked-button
        *ngIf="data?.readonly; else drawerActions"
        type="button"
      >
        {{ 'Generic.labels.close' | translate | titlecase }}
      </button>
      <ng-template #drawerActions [ngSwitch]="mode$ | async">
        <ng-container *ngSwitchCase="'view'">
          <div
            *ngIf="!data?.readonly"
            class="full-width"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
              <button
                (click)="onClose()"
                data-testid="close-button"
                mat-stroked-button
                color=""
                type="button"
              >
                {{ 'Generic.labels.close' | translate | titlecase }}
              </button>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </ng-template>
  </div>
</form>

import { ProfileInputModule } from 'ssotool-app/shared/modules/profile-input';
import {
  InputModule,
  SelectModule,
  TooltipAnchorModule,
  YearlyInputModule,
} from 'ssotool-shared';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { RenewableProductionFormComponent } from './renewable-production-form.component';

@NgModule({
  declarations: [RenewableProductionFormComponent],
  exports: [RenewableProductionFormComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    InputModule,
    TooltipAnchorModule,
    SelectModule,
    YearlyInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
    MatDividerModule,
    ProfileInputModule,
  ],
})
export class RenewableProductionFormModule {}

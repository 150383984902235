import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';

@Component({
  selector: 'sso-simple-chips',
  templateUrl: './simple-chips.component.html',
  styleUrls: ['./simple-chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleChipsComponent {
  private activeChips = new BehaviorSubject<string[]>([]);
  private availableChips = new BehaviorSubject<string[]>([]);

  @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  @Input() label = '';
  @Input() set options(opts: string[]) {
    if (opts) {
      this.availableChips.next(opts);
      this.done(opts);
    }
  }
  @Output() chips = new EventEmitter<string[]>();

  chipInputCtrl = new FormControl();

  /** Template Streams */
  filteredOptions$ = combineLatest([this.activeChips, this.getFilterInputValueChanges(), this.availableChips]).pipe(
    map(([chips, input, available]) =>
      available.filter((option) => {
        return this.isNotYetActive(option, chips) && this.isASubstringOfAny(option, input);
      })
    )
  );
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  activeChipsSize$ = this.activeChips.pipe(map((active) => active.length));
  noActiveChips$ = this.activeChipsSize$.pipe(map((size) => size === 0));
  hasValue$ = combineLatest([this.getFilterInputValueChanges(), this.activeChipsSize$]).pipe(
    map(([input, chipsSize]) => !!chipsSize || !!input)
  );
  activeChips$ = this.activeChips.asObservable();

  onKeydownEnter() {
    const currentValue = this.chipInputCtrl.value;
    this.resetControlValue();
    this.addToActiveChips(currentValue);
  }

  onChipRemove(chipToBeRemoved: string) {
    const currentActive = this.activeChips.value;
    this.setActiveChipsAndEmit(currentActive.filter((chip) => chip !== chipToBeRemoved));
  }

  private resetControlValue() {
    this.chipInputCtrl.setValue('');
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const newChip = event.option.value;
    this.addToActiveChips(newChip);
    this.chipInput.nativeElement.value = '';
    this.resetControlValue();
  }

  private addToActiveChips(newChip: string) {
    if (newChip) {
      const currentActive = this.activeChips.value;
      this.setActiveChipsAndEmit([...currentActive, newChip]);
    }
  }

  private setActiveChipsAndEmit(newValue: string[]) {
    this.activeChips.next(newValue);
    this.chips.emit(newValue);
  }

  private isASubstringOfAny(option: string, value: string) {
    if (option && value) {
      const lowerOption = option.toLowerCase();
      const lowerValue = value.toLowerCase();
      return lowerOption.includes(lowerValue);
    } else {
      return true;
    }
  }

  private isNotYetActive(option: string, activeChips: string[]) {
    return !activeChips.includes(option);
  }

  private getFilterInputValueChanges(): Observable<string> {
    return this.chipInputCtrl.valueChanges.pipe(debounceTime(100), startWith(''));
  }

  done(something: any) {}

  clear() {
    this.setActiveChipsAndEmit([]);
  }

  onClick(trigger: MatAutocompleteTrigger) {
    /* istanbul ignore else */
    if (this.matAutocomplete.options.length) {
      trigger.openPanel();
    }
  }
}

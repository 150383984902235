import { createAction, props } from '@ngrx/store';

import { ReleaseModel, ReleaseNote } from './release-notes.model';

const get = createAction('[Release Notes API] Get release notes');

const getSuccess = createAction(
  '[Release Notes API] Get release notes success',
  props<{ data: ReleaseModel; message: string }>(),
);

const getFailure = createAction(
  '[Release Notes API] Get release notes failure',
  props<{ error: any; message: string }>(),
);

const updateLoadedProgress = createAction(
  '[Release Notes API] Update loaded progress',
  props<{ progress }>(),
);

const update = createAction(
  '[Release Notes API] Update release notes',
  props<{ data: ReleaseNote }>(),
);

const updateSuccess = createAction(
  '[Release Notes API] Update release notes success',
  props<{ data: ReleaseNote; message: string }>(),
);

const updateFailure = createAction(
  '[Release Notes API] Update release notes failure',
  props<{ data: ReleaseNote; error: any; message: string }>(),
);

export const ReleaseNotesActions = {
  get,
  getSuccess,
  getFailure,
  updateLoadedProgress,
  update,
  updateFailure,
  updateSuccess,
};

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AnnouncementAPIService } from './services/announcement.service';
import { AnnouncementEffects } from './stores/announcement.effect';
import { AnnouncementFacadeService } from './stores/announcement.facade.service';
import {
  announcementFeatureKey,
  AnnouncementReducer,
} from './stores/announcement.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([AnnouncementEffects]),
    StoreModule.forFeature(announcementFeatureKey, AnnouncementReducer),
  ],
  providers: [AnnouncementAPIService, AnnouncementFacadeService],
})
export class AnnouncementRootModule {}

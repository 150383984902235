import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ALPHA_REQUEST } from 'ssotool-app/app.references';
import { generateEndpoint } from 'ssotool-core/utils';
import { ConfigService } from 'ssotool-shared/services/config';

import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import {
  AlphaRequest,
  AlphaRequestPayload,
} from '../store/alpha-request.model';

@Injectable()
export class AlphaRequestService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  getList(): Observable<AlphaRequest[]> {
    return this.http.get<any>(this.makeGetListEndpoint()).pipe(
      map((response) => this.mapRequestListToFrontend(response)),
      catchError((error) => throwError('error')),
    );
  }

  updateRequest({
    requestId,
    ...body
  }: AlphaRequestPayload): Observable<AlphaRequest> {
    return this.http.patch<any>(this.makeUpdateEndpoint(requestId), body).pipe(
      map((response) => this.mapAlphaRequestToFrontend(response)),
      catchError((error) => throwError('error')),
    );
  }

  mapRequestListToFrontend(data: AlphaRequest[]): AlphaRequest[] {
    if (data && data.length > 0) {
      return data.map<AlphaRequest>((alphaRequest) =>
        this.mapAlphaRequestToFrontend(alphaRequest),
      );
    }
    return [];
  }

  mapAlphaRequestToFrontend(data: AlphaRequest) {
    const status =
      data.status === ALPHA_REQUEST.PROCESSING &&
      (data.internalStatus === ALPHA_REQUEST.COMPLETING ||
        data.internalStatus === ALPHA_REQUEST.COMPLETION_ERROR)
        ? data.internalStatus
        : data.status;

    return {
      ...data,
      updatedAt: this.formatDate(data.updatedAt),
      createdAt: this.formatDate(data.createdAt),
      status,
    };
  }

  makeGetListEndpoint() {
    const { baseUrl, endpoints } = this.config.api;
    return generateEndpoint(baseUrl, endpoints.alphaRequests.getList);
  }

  makeUpdateEndpoint(requestId: string) {
    const { baseUrl, endpoints } = this.config.api;
    return generateEndpoint(
      baseUrl,
      endpoints.alphaRequests.updateRequest,
      requestId,
    );
  }

  formatDate(dateString: string) {
    return formatDate(dateString, 'yyyy/MM/dd HH:mm', this.locale);
  }
}

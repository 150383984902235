import { createAction, props } from '@ngrx/store';

import {
  Client,
  ClientDataInfo,
  ClientShareInfo,
  ResponseType,
  Trajectory,
} from './client.model';

const selectActiveClient = createAction(
  '[Client List Page] Select active client',
  props<{ clientId: string }>(),
);
const removeActiveClient = createAction(
  '[Client List Page] Remove active client',
  props<{ clientId: string }>(),
);
const doNothingAction = createAction('[Client List Page] Do nothing');

const create = createAction(
  '[Client Form Page: Create] Create a client',
  props<{ name: string; description?: string }>(),
);

const createSuccess = createAction(
  '[Client API] Create a client success',
  props<{ data: Client; message: string }>(),
);

const createFailure = createAction(
  '[Client API] Create a client failure',
  props<{
    data: { name: string; description?: string };
    error: any;
    message: string;
  }>(),
);

const update = createAction(
  '[Client Form Page: Update] Update a client info',
  props<{ clientId: string; name: string; description?: string }>(),
);

const updateSuccess = createAction(
  '[Client API] Update a client info success',
  props<{ data: Client; message: string }>(),
);
const updateFailure = createAction(
  '[Client API] Update a client failure',
  props<{ data: Client; error: any; message: string }>(),
);

const get = createAction(
  '[Client Page] Get a client',
  props<{ clientId: string }>(),
);

const getSuccess = createAction(
  '[Client API] Get a client success',
  props<{ data: Client; message: string }>(),
);

const getFailure = createAction(
  '[Client API] Get a client failure',
  props<{ data: any; error: any; message: string; code?: number }>(),
);

const getList = createAction('[Client List Page] Get clients');

const getListSuccess = createAction(
  '[Client API] Get clients success',
  props<{ data: Client[]; message: string }>(),
);

const getListFailure = createAction(
  '[Client API Get clients failure',
  props<{ data: any; error: any; message: string }>(),
);

const getArchivedList = createAction('[Client List Page] Get archived clients');

const getArchivedListSuccess = createAction(
  '[Client API] Get archived clients success',
  props<{ data: Client[]; message: string }>(),
);

const getArchivedListFailure = createAction(
  '[Client API Get archived clients failure',
  props<{ data: any; error: any; message: string }>(),
);

const share = createAction(
  '[Share Client Page] Share client',
  props<{ clientId: string; shareInfo: ClientShareInfo[] }>(),
);

const shareSuccess = createAction(
  '[Share Client API] Share client success',
  props<{ data: any; message: string }>(),
);

const shareFailure = createAction(
  '[Share Client API] Share client failure',
  props<{ data: any; message: string; error: any }>(),
);

const getClientShareInfo = createAction(
  '[Share Client Page] Get client share info',
  props<{ clientId: string }>(),
);

const getClientShareInfoSuccess = createAction(
  '[Share Client API] Get client share info success',
  props<{ data: any; message: string }>(),
);

const getClientShareInfoFailure = createAction(
  '[Share Client API] Get client share info failure',
  props<{ data: any; error: any; message: string }>(),
);

const deleteClient = createAction(
  '[Client List Page], Delete client',
  props<{ clientId: string }>(),
);

const deleteClientSuccess = createAction(
  '[Client API] Delete client success',
  props<{ data: { clientId: string }; message: string }>(),
);

const deleteClientFailure = createAction(
  '[Client API Delete client failure',
  props<{ data: any; error: any; message: string }>(),
);

const getClientDataInfo = createAction(
  '[Client Data Page] Get client data',
  props<{ clientId: string }>(),
);

const getClientDataInfoSuccess = createAction(
  '[Client Data API] Get client data success',
  props<{
    data: { clientId: string; clientData: ClientDataInfo };
    message: string;
  }>(),
);

const getClientDataInfoFailure = createAction(
  '[Client Data API] Get client data failure',
  props<{ clientId: string; error: any; message: string }>(),
);

const updateOngoingExport = createAction(
  '[Client Data API] Update ongoing export flag',
  props<{ clientId: string; flag: boolean }>(),
);

const archiveClient = createAction(
  '[Client Data API] Archive client',
  props<{ clientId: string }>(),
);

const archiveClientSuccess = createAction(
  '[Client Data API] Archive client success',
  props<{ data: any; message: string }>(),
);

const archiveClientFailure = createAction(
  '[Client Data API] Archive client failure',
  props<{ data: any; error: any; message: string }>(),
);

const unarchiveClient = createAction(
  '[Client Data API] Unarchive client',
  props<{ clientId: string }>(),
);
const unarchiveClientSuccess = createAction(
  '[Client Data API] Unarchive client success',
  props<{ data: any; message: string }>(),
);
const unarchiveClientFailure = createAction(
  '[Client Data API] unarchive client failure',
  props<{ data: any; error: any; message: string }>(),
);
const cascadeUpdate = createAction(
  '[Client Data] Cascade update',
  props<{ clientId: string; updatedAt: string; updatedBy: string }>(),
);

const duplicateClient = createAction(
  '[Client API] Duplicate a client',
  props<{ clientId: string }>(),
);

const duplicateClientSuccess = createAction(
  '[Client API] Duplicate a client success',
  props<{ data: ResponseType; message: string }>(),
);

const duplicateClientFailure = createAction(
  '[Client API] Duplicate a client failure',
  props<{
    data: ResponseType;
    error: any;
    message: string;
  }>(),
);

const updateDuplicateStatus = createAction(
  '[Client API], Update duplicate status',
  props<{
    clientId: string;
    dupClientId: string;
    status: string;
    isSandbox?: boolean;
  }>(),
);

const quickSwitch = createAction(
  '[Client API], Quick switch of client',
  props<{
    clientId: string;
    hasSuccessfulImport: boolean;
    clientName: string;
  }>(),
);

export const ClientActions = {
  cascadeUpdate,
  doNothingAction,
  selectActiveClient,
  removeActiveClient,
  create,
  createSuccess,
  createFailure,
  update,
  updateSuccess,
  updateFailure,
  get,
  getSuccess,
  getFailure,
  getList,
  getListSuccess,
  getListFailure,
  getArchivedList,
  getArchivedListSuccess,
  getArchivedListFailure,
  share,
  shareSuccess,
  shareFailure,
  getClientShareInfo,
  getClientShareInfoSuccess,
  getClientShareInfoFailure,
  deleteClient,
  deleteClientSuccess,
  deleteClientFailure,
  getClientDataInfo,
  getClientDataInfoSuccess,
  getClientDataInfoFailure,
  updateOngoingExport,
  archiveClient,
  archiveClientSuccess,
  archiveClientFailure,
  unarchiveClient,
  unarchiveClientSuccess,
  unarchiveClientFailure,
  duplicateClient,
  duplicateClientSuccess,
  duplicateClientFailure,
  updateDuplicateStatus,
  quickSwitch,
};

import { createAction, props } from '@ngrx/store';

function createActionTypeSentence(
  apiName: string,
  verb: string,
  status: 'OK' | 'NOK' | '' = '',
): string {
  return `[${apiName} API] ${verb} ${status}`;
}

const downloadAllBindata = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Download All Bindata'),
    props<{
      clientId: string;
    }>(),
  );

const downloadAllBindataNOKAction = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Download All Bindata', 'NOK'),
  );

const downloadAllBindataOKAction = (apiName: string) =>
  createAction(createActionTypeSentence(apiName, 'Download All Bindata', 'OK'));

export const BinDataActions = (apiName: string) => ({
  downloadAllBindataAction: downloadAllBindata(apiName),
  downloadAllBindataOKAction: downloadAllBindataOKAction(apiName),
  downloadAllBindataNOKAction: downloadAllBindataNOKAction(apiName),
});

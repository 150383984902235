import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';

export abstract class BasePortfolioAPIService {
  private client: HttpClient;

  constructor(httpClient: HttpClient) {
    this.client = httpClient;
  }

  getRequest(clientId: string, id: string): Observable<{}> {
    return this.client.get(this.makeGetEndpoint(clientId, id)).pipe(
      map((response) => this.mapToFrontend(response)),
      catchError((error) => throwError(error)),
    );
  }

  getAllRequest<T = any>(clientId: string): Observable<T[]> {
    return this.client.get(this.makeGetAllEndpoint(clientId)).pipe(
      map((response) => response as T[]),
      catchError((error) => throwError(error)),
    );
  }

  abstract mapToBackend(entity: any, ...args): any;
  abstract mapToFrontend(backendModel: any, ...args): any;

  abstract makeGetEndpoint(clientId: string, id: string): string;
  abstract makeGetAllEndpoint(clientId: string): string;
}

import { createAction, props } from '@ngrx/store';

import { Announcement } from './announcement.model';

const getList = createAction('[Announcement List API] Get announcement list');

const getListSuccess = createAction(
  '[Announcement List API] Get announcement list success',
  props<{ items: Announcement[] }>(),
);

const getListFailure = createAction(
  '[Announcement List API] Get announcement list failure',
);

const readAnnouncement = createAction(
  '[Announcement Read API] Read announcement',
  props<{ id: string }>(),
);

const readAnnouncementSuccess = createAction(
  '[Announcement Read API] Read announcement success',
);

const readAnnouncementFailure = createAction(
  '[Announcement Read API] Read announcement failure',
);

export const AnnouncementActions = {
  getList,
  getListSuccess,
  getListFailure,
  readAnnouncement,
  readAnnouncementSuccess,
  readAnnouncementFailure,
};

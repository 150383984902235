import { Coerce } from './helpers';

export interface LegendData {
  readonly name: string;
  readonly color: string;
}

export function generateHTMLLegendPluginStackedBar(
  legendStackgroups,
  legendId,
  openDialogFn,
) {
  return {
    id: 'stackedbar-legend',
    afterUpdate(chart) {
      const items = chart.options.plugins.legend.labels
        .generateLabels(chart)
        .filter(
          (value, index, self) =>
            index === self.findIndex((item) => item.text === value.text),
        )
        .map((item) => {
          return {
            name: item.text,
            baseColor: item.fillStyle,
          };
        });
      const legendsElement = createCondensedLegendsElement(
        legendStackgroups || items,
        openDialogFn,
      );

      // Attempt to get the container by ID, if not found, attempt to get an existing one  on the DOM
      const container =
        document.getElementById(legendId) ||
        document.getElementsByClassName('chart-legend')[0];

      while (container && container.firstChild) {
        container.removeChild(container.firstChild);
      }

      container?.appendChild(legendsElement);
    },
  };
}

export function generateHTMLLegendPlugin(legendId, openDialogFn) {
  return {
    id: legendId || 'legend',
    afterUpdate(chart) {
      const legendStackGroups = getLabelsData(chart.data);

      const container = document.getElementById(legendId);
      while (container && container.firstChild) {
        container.removeChild(container.firstChild);
      }

      const legendsElement = createCondensedLegendsElement(
        legendStackGroups,
        openDialogFn,
      );

      container?.appendChild(legendsElement);
    },
  };
}

export function clearLegend(containerId: string) {
  const legends = document.getElementById(containerId);
  while (legends && legends.firstChild) {
    legends.removeChild(legends.firstChild);
  }
}

function getLabelsData(data) {
  if (data.datasets.length === 1) {
    const backgroundColors = data.datasets[0].backgroundColor;
    return data.labels.map((label, index) => {
      return {
        name: label,
        baseColor: backgroundColors[index],
      };
    });
  } else {
    const colors = data.datasets[0].backgroundColor;
    return data.datasets[0].data.map((datum, index) => {
      return {
        name: datum.x,
        baseColor: colors[index],
      };
    });
  }
}

function createCondensedLegendsElement(stackGroups, openDialogFn) {
  const div = createElement('div', {
    flexFlow: 'row wrap',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  });
  stackGroups.forEach((stack) => {
    const containingSpan = createElement('span', {
      margin: '5px',
    });

    const boxSpan = createElement('span', {
      background: stack.baseColor,
      marginRight: '5px',
      display: 'inline-block',
      minWidth: '40px',
      minHeight: '12.5px',
      cursor: 'pointer',
    });

    const textContainer = createElement('div', {
      padding: '0px',
      margin: '0px',
      marginRight: '10px',
    });

    const text = document.createTextNode(stack.name);
    textContainer.appendChild(text);

    containingSpan.append(boxSpan);
    containingSpan.append(text);
    boxSpan.onclick = function () {
      openDialogFn({ name: stack.name, color: stack.baseColor });
    };
    div.append(containingSpan);
  });

  return div;
}

function createLegendsElement(stackGroups) {
  const ul = createElement('ul', {
    display: 'flex',
    flexFlow: 'row wrap',
    margin: '0px',
    padding: '0px',
    width: 'fit-content',
    height: 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
  });

  stackGroups.forEach((stack) => {
    const li = createElement('li', {
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      margin: '0px 3px',
      alignItems: 'center',
      width: '300px',
    });

    const boxSpan = createElement('span', {
      background: stack.baseColor,
      marginRight: '5px',
      display: 'inline-block',
      minWidth: '10px',
      minHeight: '10px',
    });

    const textContainer = createElement('div', {
      padding: '0px',
      margin: '0px',
      marginRight: '10px',
    });

    const text = document.createTextNode(stack.name);
    textContainer.appendChild(text);

    li.appendChild(boxSpan);
    li.appendChild(textContainer);
    ul.appendChild(li);
  });

  return ul;
}

function createElement(type: string, styles) {
  const element = document.createElement(type);
  updateStyle(element, styles);
  return element;
}

function updateStyle(element, styles) {
  for (let style in styles) {
    if (styles.hasOwnProperty(style) && element.style.hasOwnProperty(style)) {
      element.style[style] = styles[style];
    }
  }
}

function categorizeByRoadmap(datasets, items) {
  const categorizedItems = {};

  items.forEach((item) => {
    const roadmap = datasets[item.datasetIndex].stack;
    if (categorizedItems.hasOwnProperty(roadmap)) {
      categorizedItems[roadmap].push(item);
    } else {
      categorizedItems[roadmap] = [item];
    }
  });

  return categorizedItems;
}

function createLegendTable(categorizedItems) {
  const legendTable = createTable({
    width: '50',
  });
  const columns = Coerce.getObjKeys(categorizedItems);
  const legendHeaderItems = [''].concat(columns);
  const legendTableHeaderRow = createTableHeaderRow(legendHeaderItems, {}, {});
  const legendTableRowsStyles = {
    row: {},
    header: {
      fontWeight: 'normal',
      textAlign: 'center',
    },
    rowData: {
      textAlign: 'center',
    },
  };

  const legendTableRowItems = parseTableRows(columns, categorizedItems);
  legendTable.appendChild(legendTableHeaderRow);

  legendTableRowItems.forEach((row) => {
    const rowElement = createTableRowWithHeader(
      row[0],
      row.splice(1),
      legendTableRowsStyles,
    );
    legendTable.appendChild(rowElement);
  });

  return legendTable;
}

function parseTableRows(columns, items) {
  const uniqueLabels = [];
  Object.entries(items).map(([key, children]) => {
    if (Array.isArray(children)) {
      children.forEach((child) => {
        if (!uniqueLabels.find((label) => label === child.text)) {
          uniqueLabels.push(child.text);
        }
      });
    }
  });

  const rows = uniqueLabels.map((entity) => {
    const values = columns.map((column) => {
      const entityGroup = items[column];
      const entityData = entityGroup.find((g) => g.text === entity);
      return !!entityData ? entityData.fillStyle : null;
    });

    const valuesSpans = values.map((value) => {
      return createElement('span', {
        background: value,
        marginRight: '10px',
        display: 'inline-block',
        width: '15px',
        height: '15px',
      });
    });

    return [entity, ...valuesSpans];
  });

  return rows;
}

function createTable(styles) {
  return createElement('table', styles);
}

function createTableRow(items, rowStyles, rowDataStyles) {
  const row = createElement('tr', rowStyles);
  items.splice().forEach((item) => {
    const rowData = createElement('td', rowDataStyles);
    rowData.appendChild(document.createTextNode(item.toString()));
    row.appendChild(rowData);
  });
  return row;
}

function createTableRowWithHeader(rowHeader, items, styles) {
  const row = createElement('tr', styles.row);
  const rowHeaderElement = createElement('th', styles.header);
  rowHeaderElement.appendChild(document.createTextNode(rowHeader));
  row.appendChild(rowHeaderElement);
  items.forEach((item) => {
    const rowData = createElement('td', styles.rowData);
    rowData.appendChild(item);
    row.appendChild(rowData);
  });

  return row;
}

function createTableHeaderRow(items, rowHeaderStyles, rowHeaderDataStyles) {
  const row = createElement('tr', rowHeaderStyles);
  items.forEach((item) => {
    const rowData = createElement('th', rowHeaderDataStyles);
    rowData.appendChild(document.createTextNode(item.toString()));
    row.appendChild(rowData);
  });
  return row;
}

export function generateId(length) {
  const possibleCharacters = 'ABCDEFG12345_';
  let randomID = '';
  for (let i = 0; i < length; i++) {
    randomID += possibleCharacters.charAt(
      Math.floor(Math.random() * possibleCharacters.length),
    );
  }

  return randomID;
}

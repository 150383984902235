import { NotificationsModule } from 'ssotool-app/shared/services/notifications/notifications.module';

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CanDeactivateGuard } from '../core/guards/can-deactivate-guard';
import { CompareCurveInterpreter } from './modules/compare-variations/curves';
import { CompareDetailsInterpreter } from './modules/compare-variations/detailed-compare/detailed-compare.service';
import {
  CompareVariationsService,
  RoadmapCompareResolverService,
  RoadmapResolverService,
  RoadmapResultResolverService,
  RoadmapVariationCompareResolver,
} from './services';
import { RoadmapImportResolverService } from './services/roadmap-import-resolver.service';
import { RoadmapTimelineResolverService } from './services/roadmap-timeline.resolver.service';
import { RoadmapVisualizationResolverService } from './services/roadmap-visualizations-resolver.service';
import { ChartFacadeService } from './stores/charts/charts.facade.service';
import { ChartStore } from './stores/charts/charts.reducer';
import {
  CompareApiService,
  CompareEffects,
  CompareEntityStore,
  CompareFacadeService,
} from './stores/compare';
import { CompareVariationsFacadeService } from './stores/compare-variations';
import {
  RoadmapImportEffects,
  RoadmapImportFacadeService,
  RoadmapImportStore,
} from './stores/import';
import { RoadmapImportAPIService } from './stores/import/roadmap-import.service';
import {
  ResultApiService,
  ResultEffects,
  ResultEntityStore,
  ResultFacadeService,
  ResultLocalStorageService,
} from './stores/result';
import {
  ResultExportEffects,
  ResultExportEntityStore,
  ResultExportFacadeService,
  ResultExportService,
} from './stores/result-export';
import {
  RoadmapApiService,
  RoadmapEffects,
  RoadmapEntityStore,
  RoadmapFacadeService,
} from './stores/roadmap';
import {
  RoadmapVariationEffects,
  RoadmapVariationFacadeService,
  RoadmapVariationService,
  RoadmapVariationStore,
} from './stores/roadmap-variation';
import { RoadmapVisualizationAPIService } from './stores/visualization/visualization-api.service';
import { RoadmapVisualizationEffects } from './stores/visualization/visualization.effect';
import { RoadmapVisualizationFacadeService } from './stores/visualization/visualization.facade.service';
import { RoadmapVisualizationStore } from './stores/visualization/visualization.reducer';
import { ResultFiltersService } from './utilities';
import { ResultParserService } from './utilities/result/result-parser.service';
import { KPICurveExporterService } from './services/curve-export/kpi-curve-export.service';
import { ColorSchemeService } from 'ssotool-app/shared';
import { RoadmapTimelineService } from './modules/timeline/roadmap-timeline.service';
import { TableInputService } from 'ssotool-app/shared/modules/table-input/table-input.service';
import { FluidDemandEffects } from 'ssotool-app/+client/store/fluid-demand/fluid-demand.effects';
import { FluidDemandFacadeService } from 'ssotool-app/+client/store/fluid-demand/fluid-demand.facade.service';
import { FluidDemandStore } from 'ssotool-app/+client/store/fluid-demand/fluid-demand.reducer';
import { FluidDemandAPIService } from 'ssotool-app/+client/store/fluid-demand/fluid-demand-api.service';
import { DatahubEffects } from 'ssotool-app/+client/store/datahub/datahub.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([
      RoadmapEffects,
      ResultEffects,
      CompareEffects,
      RoadmapVariationEffects,
      ResultExportEffects,
      RoadmapImportEffects,
      RoadmapVisualizationEffects,
      FluidDemandEffects,
      DatahubEffects,
    ]),
    StoreModule.forFeature(
      RoadmapEntityStore.featureName,
      RoadmapEntityStore.reducer,
    ),
    StoreModule.forFeature(
      ResultEntityStore.featureName,
      ResultEntityStore.reducer,
    ),
    StoreModule.forFeature(
      CompareEntityStore.featureName,
      CompareEntityStore.reducer,
    ),
    StoreModule.forFeature(ChartStore.featureName, ChartStore.reducer),
    StoreModule.forFeature(
      RoadmapVariationStore.featureName,
      RoadmapVariationStore.reducer,
    ),
    StoreModule.forFeature(
      ResultExportEntityStore.featureName,
      ResultExportEntityStore.reducer,
    ),
    StoreModule.forFeature(
      RoadmapImportStore.featureName,
      RoadmapImportStore.reducer,
    ),
    StoreModule.forFeature(
      RoadmapVisualizationStore.featureName,
      RoadmapVisualizationStore.reducer,
    ),
    StoreModule.forFeature(
      FluidDemandStore.featureName,
      FluidDemandStore.reducer,
    ),
    NotificationsModule,
  ],
  providers: [
    RoadmapResolverService,
    RoadmapResultResolverService,
    ResultFacadeService,
    ResultApiService,
    RoadmapFacadeService,
    RoadmapApiService,
    CanDeactivateGuard,
    CompareApiService,
    CompareFacadeService,
    RoadmapCompareResolverService,
    RoadmapVariationCompareResolver,
    ResultLocalStorageService,
    ChartFacadeService,
    RoadmapVariationFacadeService,
    RoadmapVariationService,
    ResultFiltersService,
    ResultParserService,
    CompareVariationsFacadeService,
    CompareCurveInterpreter,
    CompareDetailsInterpreter,
    CompareVariationsService,
    ResultExportService,
    ResultExportFacadeService,
    RoadmapTimelineResolverService,
    RoadmapImportFacadeService,
    RoadmapImportAPIService,
    RoadmapImportResolverService,
    RoadmapVisualizationFacadeService,
    RoadmapVisualizationAPIService,
    RoadmapVisualizationResolverService,
    KPICurveExporterService,
    ColorSchemeService,
    RoadmapTimelineService,
    TableInputService,
    FluidDemandFacadeService,
    FluidDemandAPIService,
  ],
})
export class RoadmapRootModule {}

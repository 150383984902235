import { Injectable } from '@angular/core';

@Injectable()
export class PermissionChecker {
  READ = 'r';
  WRITE = 'w';
  DELETE = 'd';
  SHARE = 's';
  UNSHARE = 'u';
  DUPLICATE = '2';
  CREATE = 'c';
  UPDATE = 'a';
  UPLOAD = 'U';
  DOWNLOAD = 'D';

  // All permissions
  PERMISSIONS = [
    this.CREATE,
    this.READ,
    this.WRITE,
    this.DELETE,
    this.SHARE,
    this.UNSHARE,
    this.DUPLICATE,
    this.UPDATE,
    this.UPLOAD,
    this.DOWNLOAD,
  ];

  // Roles
  ADMIN_ROLE = this.PERMISSIONS.join('');
  READ_ONLY = [this.READ].join('');

  private sortString(str: string) {
    return str.split('').sort().join('');
  }

  isAdmin(permissions: string) {
    return (
      permissions &&
      this.sortString(this.ADMIN_ROLE) === this.sortString(permissions)
    );
  }

  isReadonly(permissions: string) {
    return (
      permissions &&
      this.sortString(this.READ_ONLY) === this.sortString(permissions)
    );
  }

  check(userPermissions: string, rolePermissions: string): boolean {
    return userPermissions?.indexOf(rolePermissions) !== -1;
  }

  getRole(permissions: string): string {
    if (this.isReadonly(permissions)) {
      return 'readonly';
    } else if (this.isAdmin(permissions)) {
      return 'admin';
    } else {
      return null; // No permission
    }
  }

  getPermissions(role: string): string {
    if (role.toLowerCase() === 'admin') {
      return this.ADMIN_ROLE;
    } else if (role.toLowerCase() === 'readonly') {
      return this.READ_ONLY;
    } else {
      return null;
    }
  }
}

import { Injectable } from '@angular/core';
import {
  ARROW_DOWN,
  ARROW_LEFT,
  ARROW_RIGHT,
  ARROW_UP,
  ENTER,
  SHIFT_TAB,
  TAB,
} from '../table-cell';
import { Coordinate, Range, TableItem, TableItems } from './table-input.model';
import { SelectionDirection } from './table-input.references';
import { Coerce } from 'ssotool-app/shared/helpers/coerce.utils';
import { DEFAULT_START_YEAR } from 'ssotool-app/app.references';

@Injectable()
export class TableInputService {
  private translateSelectionMap = {
    [ENTER]: this.goDown.bind(this),
    [ARROW_DOWN]: this.goDown.bind(this),
    [ARROW_UP]: this.goUp.bind(this),
    [ARROW_LEFT]: this.goLeft.bind(this),
    [ARROW_RIGHT]: this.goRight.bind(this),
    [TAB]: this.goRight.bind(this),
    [SHIFT_TAB]: this.goLeft.bind(this),
  };

  private default = (x) => x;

  /**
   * Returns an adjusted range based on keyInput
   * @param keyInput
   * @param currentRange
   * @param lastX [required] last possible column index
   * @param lastY [required] last possible row index
   * @returns new selection range
   */
  translateSelection(
    keyInput: string,
    currentRange: Range,
    lastX: number,
    lastY: number,
  ): Range {
    return (this.translateSelectionMap[keyInput] || this.default)(
      currentRange,
      lastX,
      lastY,
    );
  }

  private goUp(currentRange: Range): Range {
    let x = currentRange.topLeft.x;
    let y = currentRange.topLeft.y;
    if (y > 0) {
      y--;
    }

    return { topLeft: { x, y }, bottomRight: { x, y } };
  }

  private goDown(currentRange: Range, _, lastY: number): Range {
    let x = currentRange.topLeft.x;
    let y = currentRange.topLeft.y;
    if (y < lastY) {
      y++;
    }

    return { topLeft: { x, y }, bottomRight: { x, y } };
  }

  private goLeft(currentRange: Range): Range {
    let x = currentRange.topLeft.x;
    let y = currentRange.topLeft.y;
    if (x > 0) {
      x--;
    }

    return { topLeft: { x, y }, bottomRight: { x, y } };
  }

  private goRight(currentRange: Range, lastX: number): Range {
    let x = currentRange.topLeft.x;
    let y = currentRange.topLeft.y;
    if (x < lastX) {
      x++;
    }

    return { topLeft: { x, y }, bottomRight: { x, y } };
  }

  /**
   * Checks if range has 2 values with 2 inner values each, and inner values non-negative
   *
   * See parameter type
   * @param range
   * @returns
   */
  isRangeValid(range: Range): boolean {
    return !!range && this.hasValidRangeContents(range);
  }

  private hasValidRangeContents(range: Range): boolean {
    let corners = Coerce.getObjKeys(range);
    return (
      corners.length === 2 &&
      corners.every((corner) => this.isCoordinateValid(range[corner]))
    );
  }

  /**
   * checks if coord has 2 values inside, andif both are non-negative
   *
   * **no checking if actual x and y**
   * @param coord - {x, y}
   * @returns
   */
  isCoordinateValid(coord: Coordinate): boolean {
    let dimensions = Coerce.getObjKeys(coord);
    return (
      dimensions.length === 2 &&
      dimensions.every((dimension) => coord[dimension] >= 0)
    );
  }

  /**
   * Maps an initial and final location to a topLeft-bottomRight orientation
   * @param initial coord of initial location
   * @param final coord of final location, is equal to initial if selecting 1x1
   * @returns
   * @example
   * // selecting a top-right-relative cell after another cell
   * createNewRange( {x:0,y:1} , {x:1,y:0} )
   * // returns {
   * // topLeft: {x:0,y:0},
   * // bottomRight: {x:1, y:1}
   * // }
   */
  createNewRange(initial: Coordinate, final: Coordinate): Range {
    return this.createRangeFromDirection(
      initial,
      final,
      this.findDirectionOfPoints(initial, final),
    );
  }

  // x+ goes right, y+ goes down
  private findDirectionOfPoints(
    initial: Coordinate,
    final: Coordinate,
  ): SelectionDirection {
    if (initial.x <= final.x && initial.y <= final.y) {
      return SelectionDirection.DOWN_RIGHT_ISH;
    } else if (initial.x >= final.x && initial.y >= final.y) {
      return SelectionDirection.UP_LEFT_ISH;
    } else if (initial.x > final.x && initial.y < final.y) {
      return SelectionDirection.DOWN_LEFT;
    } else {
      return SelectionDirection.UP_RIGHT;
    }
  }

  private createRangeFromDirection(
    initial: Coordinate,
    final: Coordinate,
    direction: SelectionDirection,
  ): Range {
    return this.directionRegionMap[direction](initial, final);
  }

  directionRegionMap = {
    [SelectionDirection.DOWN_RIGHT_ISH]:
      this.createRangeForDownRight.bind(this),
    [SelectionDirection.UP_LEFT_ISH]: this.createRangeForUpLeft.bind(this),
    [SelectionDirection.DOWN_LEFT]: this.createRangeForDownLeft.bind(this),
    [SelectionDirection.UP_RIGHT]: this.createRangeForUpRight.bind(this),
  };

  private createRangeForDownRight(
    initial: Coordinate,
    final: Coordinate,
  ): Range {
    return {
      topLeft: { ...initial },
      bottomRight: { ...final },
    };
  }

  private createRangeForUpLeft(initial: Coordinate, final: Coordinate): Range {
    return {
      topLeft: { ...final },
      bottomRight: { ...initial },
    };
  }

  private createRangeForDownLeft(
    initial: Coordinate,
    final: Coordinate,
  ): Range {
    return {
      topLeft: {
        x: final.x,
        y: initial.y,
      },
      bottomRight: {
        x: initial.x,
        y: final.y,
      },
    };
  }

  private createRangeForUpRight(initial: Coordinate, final: Coordinate): Range {
    return {
      topLeft: {
        x: initial.x,
        y: final.y,
      },
      bottomRight: {
        x: final.x,
        y: initial.y,
      },
    };
  }

  getDataSliceAsString(
    data: TableItems,
    selection: Range,
    startingYear: string,
  ): string {
    let offset = this.findOffset(data, startingYear);
    return (
      this.getDataSlice(data, selection, offset)
        // same thing
        // .reduce(
        //   (acc, curr, index) => {
        //     return acc + (!!index ? '\n' : '') + curr.toString();
        //   },
        //   '',
        // );
        .map((item) => item.toString())
        .join('\n')
    );
  }

  // finds the offset amount from the sorted keys of a sample item's values of data
  private findOffset(data: TableItems, startingYear: string) {
    return Object.keys(Coerce.toEmptyObject(data[0]).values)
      .sort((a, b) => a.localeCompare(b))
      .findIndex((item) => item === startingYear);
  }

  /**
   * Returns a slice of a 2D data object
   *
   * Assumes **(0,0)** to be top left corner, and positive y-axis is downwards
   *
   * Offset also assumes the values keys to be alphabetically arranged.
   *
   * e.g if YearlyValues start with 2010 and the displayed content starts with 2011,
   * offset = 1
   * @param data
   * @param selection area/range/region selected
   * @param offset number indicating the values to be discarded from the left
   * @returns
   */
  getDataSlice(
    data: TableItems,
    selection: Range,
    offset: number = 0,
  ): string[][] {
    // move isShown and offset upstream??
    let topOffset = selection.topLeft.y;
    let leftOffset = selection.topLeft.x;
    let widthOfSelection = selection.bottomRight.x - selection.topLeft.x + 1;
    let heightOfSelection = selection.bottomRight.y - selection.topLeft.y + 1;
    return data
      .filter((item) => !!item.isShown)
      .slice(topOffset, topOffset + heightOfSelection)
      .map((item) => this.convertTableItemValuesToArray(item.values))
      .map((item) =>
        item.slice(leftOffset + offset, leftOffset + widthOfSelection + offset),
      );
  }

  /**
   * converts ideally Record<string, string> to string[], sorts the keys alphabetically (YearlyValues)
   * @param values e.g YearlyValues
   * @returns
   */
  convertTableItemValuesToArray(values: TableItem<string>['values']): string[] {
    return Object.entries(values)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map((item) => item[1]);
  }
}

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AmbitionService } from './services';
import { AmbitionResolverService } from './services/ambition-resolver.service';
import {
  AmbitionEffects,
  AmbitionFacadeService,
  ambitionFeatureKey,
  AmbitionReducer,
} from './store';
import { ToastModule } from 'ssotool-app/shared/services/toast/toast.module';

@NgModule({
  imports: [
    EffectsModule.forFeature([AmbitionEffects]),
    StoreModule.forFeature(ambitionFeatureKey, AmbitionReducer),
    ToastModule,
  ],
  providers: [AmbitionResolverService, AmbitionService, AmbitionFacadeService],
})
export class AmbitionRootModule {}

<form
  [formGroup]="form"
  class="portfolio-curve"
  fxLayout="column"
  fxLayoutAlign="start start"
  fxLayoutGap="24px"
>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <sso-select
      [label]="'Portfolio.labels.selectKpi' | translate"
      [options]="kpiOptions"
      [control]="form?.controls?.kpi"
      [tooltip]="'TBD'"
      [required]="true"
    ></sso-select>
    <span
      *ngIf="!isSankey; else sankeyForm"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="10px"
    >
      <mat-form-field
        appearance="outline"
        [hidden]="hideXAxis$ | async"
        data-test="selectXaxis"
      >
        <mat-label>
          {{ 'Portfolio.labels.selectXAxis' | translate }}
        </mat-label>
        <div [ngSwitch]="isInputSimplified">
          <ng-container *ngSwitchCase="true">
            <mat-select
              formControlName="enumerateBy"
              [compareWith]="compareFn"
              required
              [aria-label]="'Portfolio.labels.selectXAxis' | translate"
            >
              <mat-option
                *ngFor="let entity of xAxisOptions$ | async"
                [value]="
                  entity?.group
                    ? { group: entity.group, level: entity.value }
                    : { group: entity.value, level: '' }
                "
              >
                {{ entity.name }}
              </mat-option>
            </mat-select>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <mat-select
              formControlName="enumerateBy"
              [compareWith]="compareFn"
              required
              [aria-label]="'Portfolio.labels.selectXAxis' | translate"
            >
              <mat-option
                *ngFor="let group of xAxisOptions$ | async"
                [value]="{ group: group.value, level: '' }"
              >
                {{ group.name }}
              </mat-option>
              <mat-optgroup
                *ngFor="let enumerateByOption of xAxisWithLevelOptions$ | async"
                [label]="enumerateByOption.name"
              >
                <mat-option
                  *ngFor="
                    let level of (levelOptions | async)[enumerateByOption.value]
                  "
                  [value]="{
                    group: enumerateByOption.value,
                    level: level.value
                  }"
                >
                  {{ level.name }}</mat-option
                >
              </mat-optgroup>
            </mat-select>
          </ng-container>
        </div>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'Portfolio.labels.selectSplit' | translate }}
        </mat-label>
        <div [ngSwitch]="isInputSimplified">
          <ng-container *ngSwitchCase="true">
            <mat-select
              formControlName="splitBy"
              [compareWith]="compareFn"
              required
              [aria-label]="'Portfolio.labels.selectSplit' | translate"
            >
              <mat-option
                *ngFor="let splitEntity of splitByOptions$ | async"
                [value]="
                  splitEntity?.group
                    ? { group: splitEntity.group, level: splitEntity.value }
                    : { group: splitEntity.value, level: null }
                "
              >
                {{ splitEntity?.name }}
              </mat-option>
            </mat-select>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <mat-select
              formControlName="splitBy"
              [compareWith]="compareFn"
              required
              [aria-label]="'Portfolio.labels.selectSplit' | translate"
            >
              <mat-option
                *ngFor="let group of splitByOptions$ | async"
                [value]="{ group: group.value, level: null }"
              >
                {{ group.name }}
              </mat-option>
              <mat-optgroup
                *ngFor="let groupWithLevel of splitByWithLevelOptions$ | async"
                [label]="groupWithLevel.name"
              >
                <mat-option
                  *ngFor="
                    let level of (levelOptions | async)[groupWithLevel.value]
                  "
                  [value]="{ group: groupWithLevel.value, level: level.value }"
                >
                  {{ level.name }}</mat-option
                >
              </mat-optgroup>
            </mat-select>
          </ng-container>
        </div>
      </mat-form-field>
    </span>
    <ng-template #sankeyForm>
      <sso-select
        [label]="'Portfolio.labels.selectYear' | translate"
        [options]="sankeyYearOptions$ | async"
        [control]="form?.controls?.year"
        [tooltip]="'TBD'"
        [required]="true"
      ></sso-select>
      <sso-select
        [label]="'Portfolio.labels.selectGeography' | translate"
        [options]="sankeyGeographyOptions$ | async"
        [control]="form?.controls?.geography"
        [tooltip]="'TBD'"
        [required]="true"
      ></sso-select>
    </ng-template>
  </div>
  <div
    class="curve-content"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="16px"
  >
    <div fxLayout="row" fxLayoutAlign="space-between center" fxFlexFill>
      <h2>{{ 'Portfolio.titles.' + labelId | translate }}</h2>

      <div
        fxFlex="70%"
        fxLayoutAlign="end center"
        fxLayoutGap="20px"
        *ngIf="!isSankey; else sankeyFilters"
      >
        <mat-selection-list
          fxLayout="row"
          *ngIf="subKpiOptions.length"
          required
          formControlName="subKpi"
        >
          <mat-list-option
            checkboxPosition="before"
            *ngFor="let kpi of subKpiOptions"
            [value]="kpi.value"
            [selected]="form.controls.subKpi.value.includes(kpi.value)"
            color="primary"
          >
            {{ kpi.name }}
          </mat-list-option>
        </mat-selection-list>
        <!-- <button color="primary" mat-button (click)="toggleFilterVisibility()">
          <mat-icon matPrefix>filter_alt</mat-icon>
          <b>{{ 'Generic.labels.filter' | translate }}</b>
        </button> -->
        <button
          mat-icon-button
          color="primary"
          data-testid="ppt-download-button"
          (click)="export()"
          [matTooltip]="'Roadmap.tooltips.exportPptx' | translate"
        >
          <mat-icon>system_update_alt</mat-icon>
        </button>
        <button
          mat-icon-button
          color="primary"
          data-testid="save-viz-button"
          (click)="toggleVisualization()"
          [matTooltip]="'Roadmap.tooltips.saveViz' | translate"
        >
          <mat-icon>{{
            (isFavorite$ | async) !== undefined
              ? 'favorite'
              : 'favorite_outline'
          }}</mat-icon>
        </button>
        <sso-filters
          [stripLabel]="stripLabel"
          [options]="filterOptions$ | async"
          formControlName="filters"
          mode="dialog"
          disable="false"
          [dialogConfig]="filtersDialogConfig"
        ></sso-filters>
        <mat-checkbox
          *ngIf="isZeroValueToggleDisplayed$ | async"
          color="primary"
          formControlName="showZeroValue"
          [checked]="showZeroValues$ | async"
          (change)="displayZeroValues()"
          >{{ 'Portfolio.labels.showZeroValues' | translate }}
        </mat-checkbox>
        <sso-select
          class="medium-select"
          data-testid="graph-type-select"
          [options]="curveOptions"
          [control]="form?.controls?.curveType"
          [required]="true"
          [hideRequiredMarker]="true"
        >
        </sso-select>
      </div>
      <ng-template #sankeyFilters>
        <button
          mat-icon-button
          color="primary"
          data-testid="save-viz-button"
          (click)="toggleVisualization()"
        >
          <mat-icon>{{
            (isFavorite$ | async) !== undefined
              ? 'favorite'
              : 'favorite_outline'
          }}</mat-icon>
        </button>
      </ng-template>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlexFill>
      <sso-chip-list
        [formControl]="chipForm"
        [stripLabel]="stripLabel"
      ></sso-chip-list>
    </div>
    <div
      *ngIf="selectedKPI$ | async as metadata"
      [ngSwitch]="form?.controls?.curveType?.value"
      class="chart-container"
    >
      <sso-pie-chart-component
        *ngSwitchCase="'pie'"
        [metadata]="metadata"
        (legendClick)="legendClick($event)"
        (chartJSDataChanges$)="chartJSDataChanges($event)"
      >
      </sso-pie-chart-component>
      <sso-waterfall-component
        *ngSwitchCase="'waterfall'"
        [metadata]="metadata"
        [hideZeroValues]="showZeroValues$ | async"
        [hideToggle]="true"
        (legendClick)="legendClick($event)"
        (chartJSDataChanges$)="chartJSDataChanges($event)"
      >
      </sso-waterfall-component>
      <sso-macc-curve-component
        *ngSwitchCase="'custom_width_bar'"
        [data]="metadata"
      >
      </sso-macc-curve-component>
      <sso-stacked-bar
        *ngSwitchCase="'stacked_bar'"
        [metadata]="metadata"
        [hideZeroValues]="showZeroValues$ | async"
        [hideToggle]="true"
        (legendClick)="legendClick($event)"
        (chartJSDataChanges$)="chartJSDataChanges($event)"
      ></sso-stacked-bar>
      <sso-stacked-bar
        *ngSwitchCase="'stacked_bar_relative'"
        [metadata]="metadata"
        [hideZeroValues]="showZeroValues$ | async"
        [hideToggle]="true"
        (legendClick)="legendClick($event)"
        (chartJSDataChanges$)="chartJSDataChanges($event)"
      ></sso-stacked-bar>
      <sso-sankey-chart
        *ngSwitchCase="'sankey'"
        [metadata]="metadata"
      ></sso-sankey-chart>

      <p *ngSwitchDefault></p>
    </div>
  </div>
</form>

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { ReleaseNotesActions } from './release-notes.action';
import { ReleaseNotesState } from './release-notes.reducer';
import { ReleaseNote } from './release-notes.model';
import {
  latestVersion,
  loaded,
  loading,
  notes,
  progress,
} from './release-notes.selector';

@Injectable()
export class ReleaseNotesFacadeService {
  constructor(private store: Store<ReleaseNotesState>) {}

  notes$ = this.store.pipe(select(notes));
  latestVersion$ = this.store.pipe(select(latestVersion));
  progress$ = this.store.pipe(select(progress));
  loading$ = this.store.pipe(select(loading));
  loaded$ = this.store.pipe(select(loaded));

  get() {
    this.store.dispatch(ReleaseNotesActions.get());
  }

  updateLoadedProgress(progress) {
    this.store.dispatch(ReleaseNotesActions.updateLoadedProgress({ progress }));
  }

  update(data: ReleaseNote) {
    this.store.dispatch(ReleaseNotesActions.update({ data: data }));
  }
}

import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import tippy, { followCursor, Instance } from 'tippy.js';

@Directive({
  selector: '[ssoTooltip]',
})
export class TooltipDirective implements OnInit {
  private contentSubject = new BehaviorSubject<any>(undefined);
  private tippyInstance: unknown;

  get instance(): Instance {
    return this.tippyInstance ? (this.tippyInstance as Instance) : null;
  }
  @Input() set disabled(disabled: boolean) {
    /* istanbul ignore else */
    if (this.tippyInstance) {
      disabled ? this.instance.disable() : this.instance.enable();
    }
  }
  @Input() config = {};
  @Input() set content(content: any) {
    /* istanbul ignore else */
    if (content) {
      this.contentSubject.next(content);
    }
  }

  constructor(private el: ElementRef) {
    this.el = el;
  }

  public ngOnInit() {
    this.subscribeForContentManagement();
  }

  private subscribeForContentManagement(): void {
    this.contentSubject.pipe(filter((data) => !!data)).subscribe((content) => {
      if (this.tippyInstance) {
        this.instance.destroy();
      }

      this.tippyInstance = tippy(this.el.nativeElement, {
        content,
        delay: [0, 0],
        followCursor: true,
        theme: 'light-border',
        plugins: [followCursor],
        maxWidth: 'none',
        ...this.config,
      });
    });
  }
}

<mat-button-toggle-group
  [fxLayout]="layout"
  [fxLayoutGap]="layout === 'row' ? '10px' : '5px'"
  fxLayoutAlign="start center"
  #group="matButtonToggleGroup"
  [value]="value"
  (change)="onValChange(group.value)"
  *ngIf="!!fluids.length"
>
  <mat-button-toggle
    [value]="fluid?.value"
    class="button-toggle"
    *ngFor="let fluid of fluids"
    [style.min-width.px]="width - 4"
    [style.max-width.px]="width - 4"
    [tabindex]="readonly ? -1 : 0"
    (mouseover)="onShowDelete(fluid)"
    (mouseout)="onHideDelete(fluid)"
    (focus)="onShowDelete(fluid)"
    [disabled]="viewMode"
  >
    <div
      class="toggle-content-wrapper"
      fxLayoutAlign="start center"
      fxLayoutGap="5px"
      [style.min-height.px]="height - 4"
      [style.max-height.px]="height - 4"
    >
      <h4 [matTooltip]="fluid?.name" class="fluid-name">{{ fluid?.name }}</h4>
      <div class="button-container" [hidden]="readonly">
        <button
          *ngIf="!viewMode"
          mat-icon-button
          class="delete-button"
          type="button"
          [ngClass]="{ visible: deleteVisibility[fluid?.name + fluid?.value] }"
          [disabled]="disabled"
          (click)="onDelete(fluid)"
          (focusout)="onHideDelete(fluid)"
        >
          <mat-icon aria-label="delete">close</mat-icon>
        </button>
      </div>
    </div>
  </mat-button-toggle>
</mat-button-toggle-group>

import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { ProfileFacadeService } from 'ssotool-app/+client';
import { Profile } from 'ssotool-app/+client/store/profile-local-storage/profile.model';
import {
  DialogComponent,
  ProfileDetails,
  ProfileDetailsForm,
  SiteDetail,
} from 'ssotool-app/shared';
import { distinctObject } from 'ssotool-shared/helpers';

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { DetailsDrawerService } from '@oculus/components/details-drawer';

import { DrawerMode } from '../base-drawer.model';
import { CampaignDrawerData } from '../campaign-drawer';

@UntilDestroy()
@Component({
  selector: 'sso-profiles-drawer',
  templateUrl: './profiles-drawer.component.html',
  styleUrls: ['./profiles-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ProfilesDrawerComponent,
      multi: true,
    },
  ],
})
export class ProfilesDrawerComponent implements OnInit, ControlValueAccessor {
  private _mode = new BehaviorSubject<DrawerMode>(DrawerMode.CREATE);
  private _data = new BehaviorSubject<CampaignDrawerData>(null);
  private _initialData = new BehaviorSubject<Profile>(null);
  private _onChange = (value: ProfileDetails) => {};
  private _onTouch = () => {};

  profilesDetails: FormGroup = new FormGroup({
    geoId: new FormControl<string>(''),
    companyId: new FormControl<string>(''),
    clientId: new FormControl<string>(''),
    selectedSite: new FormControl<SiteDetail>(null),
    campaignId: new FormControl<string>(''),
    campaignName: new FormControl<string>(''),
    isGeoGroup: new FormControl<boolean>(false),
  });
  profilesForm = new FormControl();
  mode$ = this._mode.asObservable();
  readonly$ = this.mode$.pipe(
    startWith(DrawerMode.VIEW),
    map((mode) => this.data?.readonly || mode === DrawerMode.VIEW),
  );
  loading$ = this.profileFacadeService.loading$;

  @Input() set data(data: CampaignDrawerData) {
    this._data.next(data);
  }

  @Input() set mode(mode: DrawerMode) {
    this._mode.next(mode);
  }

  get data(): CampaignDrawerData {
    return this._data.value;
  }

  get details(): ProfileDetails {
    return this.profilesDetails?.value;
  }

  get selectedSite(): string {
    return this.profilesDetails?.value.selectedSite;
  }

  get clientId(): string {
    return this.profilesDetails?.value.clientId;
  }

  get campaignId(): string {
    return this.profilesDetails?.value.campaignId;
  }

  constructor(
    private profileFacadeService: ProfileFacadeService,
    private drawerService: DetailsDrawerService,
    private translateService: TranslateService,
    private dialog: MatDialog,
  ) {}

  writeValue(obj: ProfileDetails): void {
    if (obj) {
      this.profilesDetails.patchValue(obj);
      this.profilesForm.patchValue({
        site: this.selectedSite,
      });
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  ngOnInit(): void {
    this.initializeDisplayMode();
    this.initializeFormSubscription();
  }

  onClose(): void {
    this.profilesDetails.reset();
    this.profilesForm.reset();
    this.drawerService.close();
  }

  onDelete() {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translateService.instant(
            'Profiles.messages.deleteProfiles',
            { site: this.profilesForm.value.site },
          ),
          message: 'Generic.messages.areYouSure',
          confirm: 'Generic.labels.delete',
          close: 'Generic.labels.cancel',
          disableClose: false,
          width: '250px',
        },
      })
      .afterClosed()
      .subscribe((resp) => {
        /* istanbul ignore else */
        if (resp) {
          this.onProfileDelete();
          this._mode.next(DrawerMode.CREATE);
        }
      });
  }

  onEdit() {
    this._mode.next(DrawerMode.EDIT);
    this._initialData.next(this.profilesForm.value);
    this.profilesForm.markAsPristine();
  }

  onBack() {
    if (this.profilesForm.pristine) {
      this._mode.next(DrawerMode.VIEW);
      this.profilesForm.markAsPristine();
    } else {
      this.dialog
        .open(DialogComponent, {
          data: {
            title: 'Generic.labels.discardChanges',
            message: 'Generic.messages.discardChanges',
            confirm: 'Generic.labels.yes',
            close: 'Generic.labels.cancel',
            disableClose: false,
            width: '250px',
            client: {},
          },
        })
        .afterClosed()
        .subscribe((confirmed) => {
          if (confirmed) {
            this._mode.next(DrawerMode.VIEW);
            this.profilesForm.markAsPristine();
            this.profilesForm.patchValue(this._initialData.value);
          }
        });
    }
  }

  onConfirm(): void {
    if (this.profilesForm.valid) {
      this.updateProfile({
        ...this.profilesDetails.value,
        ...this.profilesForm.value,
      });
      this._mode.next(DrawerMode.VIEW);
    }
  }

  private updateProfile(profileDetailForm: ProfileDetailsForm) {
    this.profileFacadeService.upsertProfile(
      this.clientId,
      this.campaignId,
      profileDetailForm.siteId,
      profileDetailForm,
    );
    this.profilesDetails.patchValue({
      hasValues: true,
    });
    this.profilesForm.patchValue({
      site: profileDetailForm.site,
    });
    this.profilesDetails.controls.selectedSite.patchValue(
      profileDetailForm.site,
    );
  }

  private onProfileDelete() {
    const profileDetailForm = this.profilesForm.value;
    this.profileFacadeService.deleteProfile(
      this.clientId,
      this.campaignId,
      profileDetailForm.siteId,
    );

    this.profilesForm.patchValue({
      site: profileDetailForm.site,
    });
  }

  private initializeDisplayMode(): void {
    this.profilesForm.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(({ hasValues }) => {
        if (this._mode.value !== DrawerMode.EDIT) {
          this._mode.next(hasValues ? DrawerMode.VIEW : DrawerMode.CREATE);
        }
      });
  }

  private initializeFormSubscription() {
    this.profilesDetails.valueChanges
      .pipe(distinctObject, untilDestroyed(this))
      .subscribe((data) => {
        this._onChange(data);
      });
  }
}

<form
  class="form"
  [formGroup]="filterForm"
  name="filterForm"
  data-testid="filter-form"
  (ngSubmit)="onSubmit()"
>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    mat-dialog-title
    fxLayoutGap="5px"
  >
    <mat-icon matPrefix fontIcon="filter_list">filter_list</mat-icon>
    <span class="title-page" data-testid="filter-title">
      {{
        'Generic.labels.filterBy' | translate: { title: data.title | translate }
      }}
    </span>
  </div>

  <div formArrayName="filters">
    <ng-container *ngFor="let filter of filters?.controls; let i = index">
      <div
        [formGroupName]="i"
        [attr.data-testid]="'filter-row' + i"
        fxLayout="row"
        fxLayoutAlign="start"
      >
        <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="10px">
          <mat-form-field
            class="no-error-container"
            appearance="outline"
            [attr.data-testid]="'filter-form-field-name' + i"
          >
            <mat-label>{{ 'Generic.labels.filter' | translate }}</mat-label>

            <mat-select
              formControlName="filterName"
              [attr.name]="'filterName' + i"
              [attr.data-testid]="'filter-name' + i"
              [class]="'filter-name' + i"
              required
            >
              <mat-option
                *ngFor="let option of flatFilterOptions; let j = index"
                [value]="option"
                [disabled]="setDisabledOption(option, i)"
                [attr.data-testid]="'filter-name-option' + j"
              >
                {{ option | titlecase }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            class="no-error-container"
            appearance="outline"
            [attr.data-testid]="'filter-form-field-condition' + i"
          >
            <mat-label>{{ 'Generic.labels.condition' | translate }}</mat-label>
            <mat-select
              formControlName="filterCondition"
              [attr.name]="'filterCondition' + i"
              [attr.data-testid]="'filter-condition' + i"
              required
            >
              <mat-option
                *ngFor="
                  let condition of getFilterConditionOptions(
                    filter?.controls?.filterName.value
                  ) || [];
                  let j = index
                "
                [value]="condition"
                [attr.data-testid]="'filter-condition-option' + j"
              >
                {{ condition }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <sso-multi-select
            class="no-error-container"
            label="Value"
            formControlName="filterValue"
            [options]="filterOptions[filter?.controls?.filterName.value] || []"
            [attr.data-testid]="'filter-value' + i"
            [stripLabel]="stripLabel"
            color="primary"
            required
          ></sso-multi-select>

          <button
            mat-icon-button
            class="remove-button"
            type="button"
            color="warn"
            (click)="deleteFilter(i)"
            [attr.name]="'removeIcon' + i"
            [attr.data-testid]="'remove-button' + i"
          >
            <mat-icon matPrefix fontIcon="close">close</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>

    <div fxLayout="row" fxLayoutAlign="start">
      <button
        data-testid="add-button"
        mat-button
        type="button"
        color="primary"
        (click)="addFilter()"
        [disabled]="isAddButtonDisabled()"
      >
        <mat-icon matPrefix>add_circle_outline</mat-icon>
        <b>{{ 'Generic.labels.addMoreFilters' | translate }}</b>
      </button>
    </div>
  </div>

  <div fxLayoutAlign="end" fxLayoutGap="10px">
    <button
      type="button"
      mat-button
      mat-dialog-close
      data-testid="modal-close-button"
      color="primary"
    >
      {{ 'Generic.labels.cancel' | translate }}
    </button>
    <button
      type="submit"
      mat-flat-button
      color="primary"
      data-testid="modal-confirm-button"
    >
      {{ 'Generic.labels.applyFilters' | translate }}
    </button>
  </div>
</form>

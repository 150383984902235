import { createAction, props } from '@ngrx/store';

import { LibraryImportFormModel } from './library-import.model';

const get = createAction('[Library Import Page] Get library import information', props<{ importId: string }>());

const getSuccess = createAction(
  '[Global API] Get library import information success',
  props<{ data: any; message: string }>(),
);

const getFailure = createAction(
  '[Global API] Get data import information failure',
  props<{ data: any; error: any; message: string }>(),
);

const getList = createAction('[Library Import Page], Get list of library import');

const getListSuccess = createAction(
  '[Global API] Get list of library import success',
  props<{ data: { libraryImportListIds: string[] }; message: string }>(),
);

const getListFailure = createAction(
  '[Global API] Get list of library import failure',
  props<{ data: any; error: any; message: string }>(),
);

const downloadLibrary = createAction(
  '[Global Import Library Page], Download Global Library',
  props<{ importId: string }>(),
);

const downloadLibrarySuccess = createAction(
  '[Global Import Library Page], Download Global Library Success',
  props<{ data: any; message: string }>(),
);

const downloadLibraryFailure = createAction(
  '[Global Import Library Page], Download Global Library Failure',
  props<{ data: any; error: any; message: string }>(),
);

const importLibrary = createAction(
  '[Global Import Library Page], Import global profile',
  props<{ inputFileLibrary: LibraryImportFormModel }>(),
);

const importLibrarySuccess = createAction(
  '[Global Import Library Page], Import global profile success',
  props<{ data: any; message: string }>(),
);

const importLibraryFailure = createAction(
  '[Global Import Library Page], Import global profile failure',
  props<{ importLibrary: LibraryImportFormModel; error: any; message: string }>(),
);

const downloadLibraryLogs = createAction(
  '[Global Import Library Page], Download Global Library Logs',
  props<{ importId: string }>(),
);

const downloadLibraryLogsSuccess = createAction(
  '[Global Import Library Page], Download Global Library Logs Success',
  props<{ data: any; message: string }>(),
);

const downloadLibraryLogsFailure = createAction(
  '[Global Import Library Page], Download Global Library Logs Failure',
  props<{ data: any; error: any; message: string }>(),
);

export const LibraryImportActions = {
  get,
  getSuccess,
  getFailure,
  getList,
  getListSuccess,
  getListFailure,
  downloadLibrary,
  downloadLibrarySuccess,
  downloadLibraryFailure,
  importLibrary,
  importLibrarySuccess,
  importLibraryFailure,
  downloadLibraryLogs,
  downloadLibraryLogsSuccess,
  downloadLibraryLogsFailure,
};

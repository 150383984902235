import { ExistingContract } from 'ssotool-app/+client/store';

export enum ExistingContractDrawerMode {
  EDIT = 'edit',
  CREATE = 'create',
  VIEW = 'view',
}

export type ExistingContractDrawerData = Readonly<{
  mode: ExistingContractDrawerMode;
  clientId: string;
  existingContract?: Partial<ExistingContract>;
  readonly?: boolean;
}>;

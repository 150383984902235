import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CampaignFacadeService } from 'ssotool-app/+campaign/store/campaign.facade.service';
import { RenewalStrategy } from 'ssotool-app/+campaign/store/campaign.model';
import { ClientFacadeService } from 'ssotool-app/+client/store/client.facade.service';
import {
  CAMPAIGN_COLORS,
  DEFAULT_END_YEAR,
  DEFAULT_START_YEAR,
} from 'ssotool-app/app.references';
import { ProfileDetails } from 'ssotool-app/shared/modules/profile-input';
import { FormService } from 'ssotool-app/shared/services';
import { CustomValidators } from 'ssotool-core/utils';
import { BaseFormComponent } from 'ssotool-shared/component/base-form/base-form.component';

import {
  Directive,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
} from '@angular/core';
import { NgControl, Validators } from '@angular/forms';

import { DrawerMode } from '../base-drawer.model';
import { CampaignDrawerData } from '../campaign-drawer';
import {
  createDividerFormOption,
  FormFieldErrorMessageMap,
  FormFieldOption,
  getEnumKeyByValue,
  hasMultipleValues,
  mapEnumToOptions,
} from 'ssotool-app/shared/helpers/form-helpers';
import { YearlyValues } from 'ssotool-app/shared/helpers/time-dependent';

@Directive()
export class CampaignBaseFormComponent
  extends BaseFormComponent
  implements OnInit, OnChanges
{
  private _showProfiles = new BehaviorSubject<boolean>(false);
  private _profileDetails = new BehaviorSubject<ProfileDetails>(undefined);

  showProfiles$ = this._showProfiles.asObservable();

  private _data = new BehaviorSubject<CampaignDrawerData>(null);
  @Input() set data(data: CampaignDrawerData) {
    if (data) {
      this._data.next({
        ...data,
        campaign: {
          ...data.campaign,
          displayedGeo: data?.campaign?.geography || data?.campaign?.site,
          displayedGeoId: data?.campaign?.geoId || data?.campaign?.siteId,
        },
      });
      this._showProfiles.next(data?.mode !== DrawerMode.CREATE);
      this._profileDetails.next({
        clientId: data?.clientId,
        isGeoGroup: data?.campaign?.isGeoGroup,
        geoId: data?.campaign?.geoId,
        siteId: data?.campaign?.siteId,
      });
    }
  }
  get data() {
    return this._data.value;
  }
  get details(): ProfileDetails {
    return this._profileDetails.value;
  }

  @Input() clientId: string;
  @Input() readonly: boolean;

  commonControls = {
    id: '',
    name: ['', Validators.required],
    owner: '',
    clientId: '',
    subtype: ['', Validators.required],
    category: ['', Validators.required],
    pathway: ['', Validators.required],
    renewalStrategy: [
      getEnumKeyByValue(RenewalStrategy, RenewalStrategy.auto_same_size),
      Validators.required,
    ],
    geoId: '',
    siteId: '',
    displayedGeoId: '',
    companyId: '',
    sectorId: '',
    startYear: DEFAULT_START_YEAR,
    endYear: DEFAULT_END_YEAR,
    isGeoGroup: false,
  };
  startYear = DEFAULT_START_YEAR;
  endYear = DEFAULT_END_YEAR;
  COLOR = CAMPAIGN_COLORS;

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private clientFacade: ClientFacadeService,
    private campaignFacade: CampaignFacadeService,
    public formService: FormService,
  ) {
    super(ngControl);
  }

  fluidOptions$ = new Observable<FormFieldOption<string>[]>();
  geoOptions$ = new Observable<FormFieldOption<string>[]>();
  companyOptions$ = new Observable<FormFieldOption<string>[]>();
  sectorOptions$ = new Observable<FormFieldOption<string>[]>();
  processOptions$ = new Observable<FormFieldOption<string>[]>();
  metricOptions$ = new Observable<FormFieldOption<string>[]>();

  errorMessages: FormFieldErrorMessageMap = this.formService.getErrorMessageMap(
    'Campaign.messages.errors',
  );
  renewalStrategyOptions = mapEnumToOptions(RenewalStrategy);

  ngOnInit(): void {
    this.initializeValidators();
    this.initializeSelectOptions();
    combineLatest([
      this.geoOptions$.pipe(startWith([])),
      this.baseForm.controls.displayedGeoId.valueChanges,
    ]).subscribe(([geos, geoId]) => {
      if (geos?.length) {
        const selectedGeo = geos?.find((geo) => geo?.value === geoId);
        this.baseForm.controls.isGeoGroup.patchValue(
          !!selectedGeo?.otherDetails?.groupId,
        );
        this._profileDetails.next({
          clientId: this.data?.clientId,
          isGeoGroup: !!selectedGeo?.otherDetails?.groupId,
          geoId: geoId,
        });
      }
    });

    super.ngOnInit();
  }

  ngOnChanges(_: SimpleChanges) {
    if (this.data?.campaign) {
      this.baseForm.patchValue(this.data.campaign, { emitEvent: false });
    }
  }

  private initializeValidators() {
    this.baseForm.controls.name.setAsyncValidators(
      CustomValidators.dataExist(
        this.campaignFacade.getCampaignList$,
        'name',
        this.data?.campaign,
      ),
    );
  }

  private initializeSelectOptions() {
    const selectorKey = this.clientId;
    this.fluidOptions$ = this.createFormOptions(
      this.clientFacade.selectFluids$,
      selectorKey,
    );

    this.geoOptions$ = combineLatest([
      this.createFormOptions(
        this.clientFacade.selectGeosAndSites$,
        selectorKey,
        'geoId',
        true,
      ),
      this.createFormOptions(
        this.clientFacade.selectGeographyGroups$,
        selectorKey,
        null,
        true,
      ),
    ]).pipe(
      map(([geographies, groups]) =>
        [createDividerFormOption('Site/Geographies')]
          .concat(geographies)
          .concat(createDividerFormOption('Groups'))
          .concat(groups),
      ),
    );
    this.companyOptions$ = this.createFormOptions(
      this.clientFacade.selectCompanies$,
      selectorKey,
      'companyId',
    );
    this.sectorOptions$ = this.createFormOptions(
      this.clientFacade.selectSectors$,
      selectorKey,
    );
    this.processOptions$ = this.createFormOptions(
      this.clientFacade.selectProcesses$,
      selectorKey,
    );
    this.metricOptions$ = this.createFormOptions(
      this.clientFacade.selectMetrics$,
      selectorKey,
    );
  }

  /**
   * Check yearly values if it should flag a warning.
   * @param value yearly values
   * @param startYear base year to check
   * @returns flag if warning should be displayed
   */
  flagWarnings(value: YearlyValues, startYear = this.startYear): boolean {
    if (hasMultipleValues(value)) {
      return false;
    } else {
      return Number(value?.[startYear]) === 0;
    }
  }
}

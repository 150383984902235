import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  ambitionAdapter,
  ambitionFeatureKey,
  AmbitionState,
} from './ambition.reducer';

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = ambitionAdapter.getSelectors();

export const ambitionFeatureState = createFeatureSelector<AmbitionState>(
  ambitionFeatureKey,
);

export const ambitionListEntities = createSelector(
  ambitionFeatureState,
  selectEntities,
);

export const ambitionList = createSelector(
  ambitionFeatureState,
  (state) => state.targets,
);

export const loading = createSelector(
  ambitionFeatureState,
  (state) => state.loading,
);

export const loaded = createSelector(
  ambitionFeatureState,
  (state) => state.loaded,
);

<form
  [formGroup]="campaignForm"
  (ngSubmit)="onConfirm()"
  fxLayout="column"
  fxLayoutGap="10px"
>
  <div class="drawer-contents" fxLayout="column" fxLayoutGap="10px">
    <sso-select
      *ngIf="(mode$ | async) === 'create'"
      data-testid="select-campaign-type-field"
      [label]="'Campaign.labels.technical.campaignType' | translate"
      [options]="campaignTypeOptions"
      [control]="campaignTypeControl"
      [required]="true"
    >
    </sso-select>
    <ng-container [ngSwitch]="campaignTypeControl?.value">
      <sso-drawer-converter
        *ngSwitchCase="'converter'"
        [data]="initialData$ | async"
        [clientId]="data?.clientId"
        [readonly]="readonly$ | async"
        formControlName="campaignTypeForm"
        [submit$]="submit$"
      ></sso-drawer-converter>
      <sso-drawer-market
        *ngSwitchCase="'market'"
        [data]="initialData$ | async"
        [clientId]="data?.clientId"
        [readonly]="readonly$ | async"
        formControlName="campaignTypeForm"
        [submit$]="submit$"
      ></sso-drawer-market>
      <sso-drawer-storage
        *ngSwitchCase="'storage'"
        [data]="initialData$ | async"
        [clientId]="data?.clientId"
        [readonly]="readonly$ | async"
        formControlName="campaignTypeForm"
        [submit$]="submit$"
      ></sso-drawer-storage>
      <sso-drawer-energy-efficiency
        *ngSwitchCase="'energyEfficiency'"
        [data]="initialData$ | async"
        [clientId]="data?.clientId"
        [readonly]="readonly$ | async"
        formControlName="campaignTypeForm"
        [submit$]="submit$"
      ></sso-drawer-energy-efficiency>
      <sso-drawer-renewable-production
        *ngSwitchCase="'renewableProduction'"
        [data]="initialData$ | async"
        [clientId]="data?.clientId"
        [readonly]="readonly$ | async"
        formControlName="campaignTypeForm"
        [submit$]="submit$"
      ></sso-drawer-renewable-production>
      <h2 *ngSwitchDefault class="no-implementation">
        {{ 'Campaign.messages.noImplementation' | translate }}
      </h2>
    </ng-container>
  </div>
  <div
    class="drawer-actions"
    fxLayout="row"
    fxLayoutGap="5px"
    fxLayoutAlign="end center"
  >
    <ngx-skeleton-loader
      *ngIf="loading$ | async; else actions"
      [count]="1"
      animation="progress"
      [theme]="{ height: '40px', width: '100%' }"
    ></ngx-skeleton-loader>
    <ng-template #actions>
      <button
        (click)="onClose()"
        data-testid="close-button"
        mat-stroked-button
        *ngIf="data?.readonly; else drawerActions"
        type="button"
      >
        {{ 'Generic.labels.close' | translate | titlecase }}
      </button>
      <ng-template #drawerActions [ngSwitch]="mode$ | async">
        <ng-container *ngSwitchCase="'view'">
          <div
            *ngIf="!data?.readonly"
            class="full-width"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <button
                (click)="onEdit()"
                data-testid="edit-button"
                mat-stroked-button
                color="primary"
                type="button"
              >
                {{ 'Generic.labels.edit' | translate | titlecase }}
              </button>
              <button
                (click)="onDuplicate()"
                data-testid="duplicate-button"
                mat-stroked-button
                color="primary"
                type="button"
              >
                {{ 'Generic.labels.duplicate' | translate | titlecase }}
              </button>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
              <button
                (click)="onDelete()"
                data-testid="delete-button"
                mat-stroked-button
                color="warn"
                type="button"
              >
                {{ 'Generic.labels.delete' | translate | titlecase }}
              </button>
              <button
                (click)="onClose()"
                data-testid="close-button"
                mat-stroked-button
                color=""
                type="button"
              >
                {{ 'Generic.labels.close' | translate | titlecase }}
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'edit'">
          <button
            data-testid="back-button"
            (click)="onBack()"
            mat-stroked-button
            color=""
            type="button"
          >
            {{ 'Generic.labels.back' | translate | titlecase }}
          </button>
          <button
            data-testid="update-button"
            mat-stroked-button
            color="primary"
            [disabled]="isSubmitDisabled"
            type="submit"
          >
            {{ 'Generic.labels.update' | translate | titlecase }}
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="'create'">
          <button
            (click)="onCancel()"
            data-testid="cancel-button"
            mat-stroked-button
            color=""
            type="button"
          >
            {{ 'Generic.labels.cancel' | translate | titlecase }}
          </button>
          <button
            data-testid="create-button"
            mat-stroked-button
            color="primary"
            [disabled]="isSubmitDisabled"
            type="submit"
          >
            {{ 'Generic.labels.create' | translate | titlecase }}
          </button>
        </ng-container>
      </ng-template>
    </ng-template>
  </div>
</form>

import { createReducer, on } from '@ngrx/store';
import { DatahubActions } from './datahub.actions';
import { Trajectory } from '../client.model';
import { TrajectoryRecord } from './datahub.model';

export const DATAHUB_FEATURE_NAME = 'Datahub';

export type DatahubFeatureState = Readonly<{
  trajectories: TrajectoryRecord;
  loading: boolean;
  loaded: boolean;
  error: boolean;
  errorMessage?: string;
}>;

export const initialState: DatahubFeatureState = {
  trajectories: {},
  loading: false,
  loaded: false,
  error: false,
  errorMessage: '',
};

export const datahubReducer = createReducer(
  initialState,
  on(DatahubActions.chooseTrajectoriesToSave, (state) => ({
    ...state,
    loading: true,
  })),
  on(DatahubActions.chooseTrajectoriesToSaveSuccess, (state) => ({
    ...state,
    loading: false,
    loaded: true,
    error: false,
  })),
  on(DatahubActions.chooseTrajectoriesToSaveFailed, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: true,
    errorMessage: error.message,
  })),

  on(DatahubActions.getTDBCommodities, (state) => {
    return { ...state, loading: true, loaded: false };
  }),
  on(DatahubActions.getTDBCommoditiesSuccess, (state, { data }) => ({
    ...state,
    trajectories: data,
    loading: false,
    loaded: true,
  })),
  on(DatahubActions.getTDBCommoditiesFailure, (state, { error, message }) => {
    return { ...state, loading: false, error, message, loaded: false };
  }),
);

export const DatahubStore = {
  featureName: DATAHUB_FEATURE_NAME,
  reducer: datahubReducer,
};

import { createAction, props } from '@ngrx/store';

import { ResultGetParameters } from './result-parameters.model';
import {
  BackendBaselineIndicators,
  BaselineIndicators,
  BaselineIndicatorTrigger,
  Result,
} from './result.model';

function createActionTypeSentence(
  apiName: string,
  verb: string,
  status: 'OK' | 'NOK' | '' = '',
): string {
  return `[${apiName} API] ${verb} ${status}`;
}

const get = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Get'),
    props<ResultGetParameters>(),
  );

const getSuccess = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Get', 'OK'),
    props<{ data: Result; params: ResultGetParameters }>(),
  );

const getFailure = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Get', 'NOK'),
    props<ResultGetParameters>(),
  );

const downloadComputationLogs = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Download Logs'),
    props<{ clientId: string; roadmapId: string; variationId: string }>(),
  );

const downloadComputationLogsSuccess = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Download Logs', 'OK'),
    props<{ message: string }>(),
  );

const downloadComputationLogsFailure = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Download Logs', 'NOK'),
    props<{
      clientId: string;
      roadmapId: string;
      variationId: string;
      message: string;
    }>(),
  );

const downloadComputationInputs = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Download Inputs'),
    props<{ clientId: string; roadmapId: string; variationId: string }>(),
  );

const downloadComputationInputsSuccess = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Download Inputs', 'OK'),
    props<{ message: string }>(),
  );

const downloadComputationInputsFailure = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Download Inputs', 'NOK'),
    props<{
      clientId: string;
      roadmapId: string;
      variationId: string;
      message: string;
    }>(),
  );

const downloadComputationResult = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Download Result'),
    props<{ clientId: string; roadmapId: string; variationId: string }>(),
  );

const downloadComputationResultSuccess = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Download Result', 'OK'),
    props<{ message: string }>(),
  );

const downloadComputationResultFailure = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Download Result', 'NOK'),
    props<{
      clientId: string;
      roadmapId: string;
      variationId: string;
      message: string;
    }>(),
  );

const downloadResultExcel = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Download Excel'),
    props<{ clientId: string; roadmapId: string; variationId: string }>(),
  );

const downloadResultExcelSuccess = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Download Excel', 'OK'),
    props<{ message: string }>(),
  );

const downloadResultExcelFailure = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Download Excel', 'NOK'),
    props<{
      clientId: string;
      roadmapId: string;
      variationId: string;
      message: string;
    }>(),
  );

const updateLoadedProgress = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Update Loaded Progress'),
    props<{ id: string; progress }>(),
  );

const updateLoadedVariationProgress = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Update Loaded Variation Progress'),
    props<{ roadmapId: string; variationId: string; progress }>(),
  );

const getBaselineIndicators = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Get Baseline Indicators'),
    props<{ clientId: string; roadmapId: string }>(),
  );

const getBaselineIndicatorsSuccess = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Get Baseline Indicators', 'OK'),
    props<{ data: BaselineIndicatorTrigger; message: string }>(),
  );

const getBaselineIndicatorsFailure = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Get Baseline Indicators', 'NOK'),
    props<{ clientId: string; roadmapId: string; message: string }>(),
  );

const getMultipleBaselineIndicators = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Get Multiple Baseline Indicators'),
    props<{ clientId: string }>(),
  );

const getMultipleBaselineIndicatorsSuccess = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Get Multiple Baseline Indicators', 'OK'),
    props<{ roadmapIds: string[]; message: string }>(),
  );

const getMultipleBaselineIndicatorsFailure = (apiName: string) =>
  createAction(
    createActionTypeSentence(
      apiName,
      'Get Multiple Baseline Indicators',
      'NOK',
    ),
    props<{ clientId: string; message: string }>(),
  );

const updateBaselineIndicators = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Update Baseline Indicators', ''),
    props<{ data: BackendBaselineIndicators }>(),
  );

const updateBaselineIndicatorsSuccess = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Update Baseline Indicators', 'OK'),
    props<{ data: BaselineIndicators; message: string }>(),
  );

const updateBaselineIndicatorsFailure = (apiName: string) =>
  createAction(
    createActionTypeSentence(apiName, 'Update Baseline Indicators', 'NOK'),
    props<{ data: BackendBaselineIndicators; message: string }>(),
  );

export const ResultEntityActions = (apiName: string) => ({
  getAction: get(apiName),
  getOKAction: getSuccess(apiName),
  getNOKAction: getFailure(apiName),
  downloadComputationLogsAction: downloadComputationLogs(apiName),
  downloadComputationLogsOKAction: downloadComputationLogsSuccess(apiName),
  downloadComputationLogsNOKAction: downloadComputationLogsFailure(apiName),
  downloadComputationInputsAction: downloadComputationInputs(apiName),
  downloadComputationInputsOKAction: downloadComputationInputsSuccess(apiName),
  downloadComputationInputsNOKAction: downloadComputationInputsFailure(apiName),
  downloadComputationResultAction: downloadComputationResult(apiName),
  downloadComputationResultOKAction: downloadComputationResultSuccess(apiName),
  downloadComputationResultNOKAction: downloadComputationResultFailure(apiName),
  downloadResultExcelAction: downloadResultExcel(apiName),
  downloadResultExcelOKAction: downloadResultExcelSuccess(apiName),
  downloadResultExcelNOKAction: downloadResultExcelFailure(apiName),
  updateLoadedProgressAction: updateLoadedProgress(apiName),
  updateLoadedVariationProgressAction: updateLoadedVariationProgress(apiName),
  getBaselineIndicatorsAction: getBaselineIndicators(apiName),
  getBaselineIndicatorsOKAction: getBaselineIndicatorsSuccess(apiName),
  getBaselineIndicatorsNOKAction: getBaselineIndicatorsFailure(apiName),
  updateBaselineIndicatorsAction: updateBaselineIndicators(apiName),
  updateBaselineIndicatorsOKAction: updateBaselineIndicatorsSuccess(apiName),
  updateBaselineIndicatorsNOKAction: updateBaselineIndicatorsFailure(apiName),
  getMultipleBaselineIndicatorsAction: getMultipleBaselineIndicators(apiName),
  getMultipleBaselineIndicatorsOKAction:
    getMultipleBaselineIndicatorsSuccess(apiName),
  getMultipleBaselineIndicatorsNOKAction:
    getMultipleBaselineIndicatorsFailure(apiName),
});

import { Observable } from 'rxjs';
import { Dimensions, Feature } from 'ssotool-core/config';

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import {
  PageSetBackgroundAction,
  PageSetDimensionsAction,
  PageSetNameAction,
  PageSetSidenavLinksAction,
  PageSetTitleAction,
  PageSetToolbarLinksAction,
} from './page.actions';
import { PageState } from './page.model';
import { pageSelectors } from './page.reducer';

@Injectable({ providedIn: 'root' })
export class PageStore {
  state$: Observable<PageState> = this._store$.pipe(
    select(pageSelectors.state),
  );

  background$: Observable<string> = this._store$.pipe(
    select(pageSelectors.background),
  );
  name$: Observable<string> = this._store$.pipe(select(pageSelectors.name));
  sidenavLinks$: Observable<Array<Feature>> = this._store$.pipe(
    select(pageSelectors.sideNavLinks),
  );
  title$: Observable<string> = this._store$.pipe(select(pageSelectors.title));
  toolbarLinks$: Observable<Array<Feature>> = this._store$.pipe(
    select(pageSelectors.toolbarLinks),
  );
  dimensions$: Observable<Dimensions> = this._store$.pipe(
    select(pageSelectors.dimensions),
  );

  constructor(private _store$: Store<PageState>) {}

  setBackground(background: string): Observable<string> {
    this._store$.dispatch(new PageSetBackgroundAction(background));
    return this.background$;
  }

  setName(name: string): Observable<string> {
    this._store$.dispatch(new PageSetNameAction(name));
    return this.name$;
  }

  setSidenavLinks(links: Array<Feature>): Observable<Array<Feature>> {
    this._store$.dispatch(new PageSetSidenavLinksAction(links));
    return this.sidenavLinks$;
  }

  setTitle(title: string): Observable<string> {
    this._store$.dispatch(new PageSetTitleAction(title));
    return this.title$;
  }

  setToolbarLinks(links: Array<Feature>): Observable<Array<Feature>> {
    this._store$.dispatch(new PageSetToolbarLinksAction(links));
    return this.toolbarLinks$;
  }

  setDimensions(dimensions: Dimensions): Observable<Dimensions> {
    this._store$.dispatch(new PageSetDimensionsAction(dimensions));
    return this.dimensions$;
  }
}

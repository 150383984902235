<div id="main" fxLayout="column" style="position: relative">
  <div
    *ngIf="!(hasData$ | ngrxPush)"
    class="graph-no-data-container"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    {{ 'Generic.messages.noResultsFound' | translate }}
  </div>
  <div class="canvas-container">
    <canvas
      id="waterfall"
      data-testid="waterfall"
      width="1000"
      height="400"
    ></canvas>
  </div>
  <mat-slide-toggle
    *ngIf="isHideZeroValuesVisible"
    class="zero-toggle"
    color="primary"
    (click)="$event.stopPropagation()"
    [checked]="!showZeroValues"
    (change)="$event ? toggleHideZeroValues() : null"
    [hidden]="hideToggle"
  >
    {{ 'Generic.labels.waterfallZeroToggle' | translate }}
  </mat-slide-toggle>
  <div class="graph-controls">
    <div id="legend" fxLayoutAlign="center center" fxFill></div>
  </div>
</div>

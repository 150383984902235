import { createReducer, on } from '@ngrx/store';

import { PortfolioEntityActions } from './portfolio.actions';
import { PortfolioEntity } from './portfolio.model';

export interface PortfolioEntityState<T = any> {
  dataMap: { [id: string]: PortfolioEntity<T> };
  loading: boolean;
  loaded?: boolean;
  progress?: number;
  error?: boolean;
  mergeResultsOngoing?: boolean;
  downloadingResults?: boolean;
}

export const INITIAL_PORTFOLIO_ENTITY_STATE: PortfolioEntityState = {
  dataMap: {},
  loading: false,
};

function reduceGetAction(
  previous: PortfolioEntityState,
  { id }: { id: string; clientId: string },
): PortfolioEntityState {
  const currentMap = { ...previous.dataMap };
  currentMap[id] = {
    ...currentMap[id],
    loaded: false,
    loading: true,
  };
  return { ...previous, dataMap: currentMap, loading: true };
}

function reduceGetOKAction(
  previous: PortfolioEntityState,
  payload: { data: any },
): PortfolioEntityState {
  const currentMap = { ...previous.dataMap };
  const portfolioModel = payload.data;
  currentMap[portfolioModel.id] = {
    ...currentMap[portfolioModel.id],
    ...portfolioModel,
    loaded: true,
    loading: false,
    progress: currentMap[portfolioModel.id]?.progress,
  };
  return { ...previous, dataMap: currentMap, loading: false, loaded: true };
}

function reduceGetNOKAction(
  previous: PortfolioEntityState,
  { id }: { id: string },
): PortfolioEntityState {
  const currentMap = { ...previous.dataMap };
  currentMap[id] = {
    ...currentMap[id],
    loaded: false,
    loading: false,
  };
  return { ...previous, loading: false };
}

function reduceGetAllAction(
  previous: PortfolioEntityState,
  _: { clientId: string },
): PortfolioEntityState {
  return { ...previous, loading: true };
}

function reduceGetAllOKAction(
  previous: PortfolioEntityState,
  payload: { data: string[] },
): PortfolioEntityState {
  const dataMap = payload.data.reduce((acc, curr) => {
    acc[curr] = { loaded: false, loading: true };
    return acc;
  }, {});
  return { ...previous, loading: false, dataMap, loaded: true };
}

function reduceGetAllNOKAction(
  previous: PortfolioEntityState,
  _: {},
): PortfolioEntityState {
  return { ...previous, loading: false };
}

export function makePortfolioEntityStateReducer(
  apiName: string,
  ...onActionFns: Array<ReturnType<typeof on>>
) {
  const apiActions = PortfolioEntityActions(apiName);
  const BUILT_IN_FUNCTIONS: ReturnType<typeof on>[] = [
    on(apiActions.getAction, reduceGetAction) as ReturnType<typeof on>,
    on(apiActions.getOKAction, reduceGetOKAction) as ReturnType<typeof on>,
    on(apiActions.getNOKAction, reduceGetNOKAction) as ReturnType<typeof on>,
    on(apiActions.getAllAction, reduceGetAllAction) as ReturnType<typeof on>,
    on(apiActions.getAllOKAction, reduceGetAllOKAction) as ReturnType<
      typeof on
    >,
    on(apiActions.getAllNOKAction, reduceGetAllNOKAction) as ReturnType<
      typeof on
    >,
  ];
  const builtInAndSpecific: ReturnType<typeof on>[] = [
    ...BUILT_IN_FUNCTIONS,
    ...onActionFns,
  ];
  return createReducer(INITIAL_PORTFOLIO_ENTITY_STATE, ...builtInAndSpecific);
}

export const PortfolioEntityReducerFns = {
  reduceGetAction,
  reduceGetOKAction,
  reduceGetNOKAction,
  reduceGetAllAction,
  reduceGetAllOKAction,
  reduceGetAllNOKAction,
};

import { Coerce } from 'ssotool-shared/helpers';

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { RoadmapEntityActions } from './roadmap.actions';
import { PartialRoadmap, PortfolioRoadmap } from './roadmap.model';

export const ROADMAP_FEATURE_NAME = 'Roadmap';

export interface RoadmapState extends EntityState<PortfolioRoadmap> {
  loading: boolean;
  loaded: boolean;
}

export const roadmapAdapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (roadmap: PartialRoadmap) => roadmap?.roadmapId || roadmap?.id,
});

export const initialRoadmapState: RoadmapState = roadmapAdapter.getInitialState(
  {
    ids: [],
    entities: {},
    loading: false,
    loaded: false,
  },
);

const roadmapReducer = createReducer(
  initialRoadmapState,
  //ACCESS
  on(RoadmapEntityActions.accessRoadmapAction, (state, { roadmapId }) =>
    roadmapAdapter.upsertOne({ id: roadmapId, accessed: true }, state),
  ),
  //GET
  on(RoadmapEntityActions.getRoadmapAction, (state, { roadmapId }) =>
    roadmapAdapter.upsertOne({ id: roadmapId, loading: true }, state),
  ),
  on(RoadmapEntityActions.getRoadmapOKAction, (state, { data }) =>
    roadmapAdapter.upsertOne(
      { ...data, loading: false, loaded: true, campaignsLoaded: false },
      state,
    ),
  ),
  on(RoadmapEntityActions.getRoadmapNOKAction, (state, { roadmapId }) =>
    roadmapAdapter.upsertOne({ id: roadmapId, loading: false }, state),
  ),
  //GET ALL
  on(RoadmapEntityActions.getAllRoadmapAction, (state) => ({
    ...state,
    loading: true,
  })),
  on(RoadmapEntityActions.getAllRoadmapOKAction, (state, { data }) =>
    roadmapAdapter.upsertMany(
      data.map((roadmap) => ({
        ...roadmap,
        loading: false,
        loaded: true,
        campaignsLoaded: false,
      })),
      { ...state, loaded: true, loading: false },
    ),
  ),
  on(RoadmapEntityActions.getAllRoadmapNOKAction, (state) => ({
    ...state,
    loading: false,
    loaded: false,
  })),
  // CREATE
  on(RoadmapEntityActions.createRoadmapAction, (state) => ({
    ...state,
    loading: true,
  })),
  on(RoadmapEntityActions.createRoadmapOKAction, (state, { data }) =>
    roadmapAdapter.upsertOne(
      { ...data, loaded: true, loading: false },
      { ...state, loading: false },
    ),
  ),
  on(RoadmapEntityActions.createRoadmapNOKAction, (state) => ({
    ...state,
    loading: false,
  })),
  // UPDATE
  on(
    RoadmapEntityActions.updateRoadmapAction,
    (state, { roadmapId, data, targets }) =>
      roadmapAdapter.upsertOne(
        { id: roadmapId, updating: true, campaigns: data, targets },
        state,
      ),
  ),
  on(
    RoadmapEntityActions.updateRoadmapOKAction,
    (state, { data: { id, name, updatedAt, updatedBy } }) =>
      roadmapAdapter.upsertOne(
        {
          id,
          // put the prev name back, BE doesn't always respond with name
          name: name || state.entities[id].name,
          updatedAt,
          updatedBy,
          updating: false,
        },
        state,
      ),
  ),
  on(RoadmapEntityActions.updateRoadmapNOKAction, (state, { params }) =>
    roadmapAdapter.upsertOne({ id: params.roadmapId, updating: false }, state),
  ),
  // DELETE
  on(RoadmapEntityActions.deleteRoadmapAction, (state, { roadmapId }) =>
    roadmapAdapter.upsertOne({ id: roadmapId, loading: true }, state),
  ),
  on(RoadmapEntityActions.deleteRoadmapOKAction, (state, { params }) =>
    roadmapAdapter.removeOne(params.roadmapId, state),
  ),
  on(RoadmapEntityActions.deleteRoadmapNOKAction, (state, { params }) =>
    roadmapAdapter.upsertOne({ id: params.roadmapId, loading: false }, state),
  ),
  // SET TO BASELINE
  on(RoadmapEntityActions.setToBaselineAction, (state, { roadmapId }) =>
    roadmapAdapter.upsertOne({ id: roadmapId, loading: true }, state),
  ),
  on(RoadmapEntityActions.setToBaselineOKAction, (state, { data }) => {
    let newState = state;

    const currentBaselineRoadmap = Coerce.getObjValues(state.entities).find(
      (roadmap) => roadmap.isBaseline,
    );
    if (currentBaselineRoadmap) {
      newState = roadmapAdapter.upsertOne(
        { id: currentBaselineRoadmap.id, isBaseline: false },
        state,
      );
    }

    return roadmapAdapter.upsertOne(
      { ...data, isBaseline: true, loaded: true, loading: false },
      newState,
    );
  }),
  on(RoadmapEntityActions.setToBaselineNOKAction, (state, { params }) =>
    roadmapAdapter.upsertOne({ id: params.roadmapId, loading: false }, state),
  ),
  on(RoadmapEntityActions.duplicateRoadmapsAction, (state, { roadmapIds }) =>
    roadmapAdapter.upsertMany(
      roadmapIds.map((id) => ({ id, loading: true })),
      state,
    ),
  ),
  on(
    RoadmapEntityActions.duplicateRoadmapsOKAction,
    (state, { data, roadmapIds }) =>
      roadmapAdapter.upsertMany(
        roadmapIds
          .map((id) => ({ id, loading: false }))
          .concat(
            data.map((roadmap) => ({
              ...roadmap,
              loading: false,
              loaded: true,
            })),
          ),
        state,
      ),
  ),
  on(RoadmapEntityActions.duplicateRoadmapsNOKAction, (state, { roadmapIds }) =>
    roadmapAdapter.upsertMany(
      roadmapIds.map((id) => ({ id, loading: false })),
      state,
    ),
  ),
  // SINGLE COMPUTE
  on(RoadmapEntityActions.computeRoadmapAction, (state, { roadmapId }) =>
    roadmapAdapter.upsertOne(
      { id: roadmapId, loading: true, canLaunchRoadmap: false },
      state,
    ),
  ),
  on(RoadmapEntityActions.computeRoadmapOKAction, (state, { data }) =>
    roadmapAdapter.upsertOne(
      { ...data, loading: false, loaded: true, canLaunchRoadmap: false },
      state,
    ),
  ),
  on(RoadmapEntityActions.computeRoadmapNOKAction, (state, { params }) =>
    roadmapAdapter.upsertOne({ id: params.roadmapId, loading: false }, state),
  ),

  on(
    RoadmapEntityActions.computeCanLaunchRoadmapAction,
    (state, { roadmapId }) =>
      roadmapAdapter.upsertOne(
        { id: roadmapId, loading: true, canLaunchRoadmap: false },
        state,
      ),
  ),

  on(RoadmapEntityActions.computeCanLaunchRoadmapOKAction, (state, { data }) =>
    roadmapAdapter.upsertOne(
      { ...data, loading: false, loaded: true, canLaunchRoadmap: true },
      state,
    ),
  ),

  on(RoadmapEntityActions.computeCanLaunchRoadmapNOKAction, (state) => ({
    ...state,
    loading: false,
    loaded: true,
    canLaunchRoadmap: true,
  })),

  // STOP COMPUTE
  on(RoadmapEntityActions.stopComputeRoadmapAction, (state, { roadmapId }) =>
    roadmapAdapter.upsertOne({ id: roadmapId, loading: true }, state),
  ),
  on(RoadmapEntityActions.stopComputeRoadmapOKAction, (state, { params }) =>
    roadmapAdapter.upsertOne(
      { id: params.roadmapId, loading: false, loaded: true },
      state,
    ),
  ),
  on(
    RoadmapEntityActions.stopComputeRoadmapPartialAction,
    (state, { roadmapId }) =>
      roadmapAdapter.upsertOne({ id: roadmapId, loading: false }, state),
  ),
  on(RoadmapEntityActions.stopComputeRoadmapNOKAction, (state, { params }) =>
    roadmapAdapter.upsertOne({ id: params.roadmapId, loading: false }, state),
  ),
  // MULTIPLE COMPUTE
  on(
    RoadmapEntityActions.computeMultipleRoadmapAction,
    (state, { roadmapIds }) =>
      roadmapAdapter.upsertMany(
        roadmapIds.map((id) => ({ id, loading: true })),
        state,
      ),
  ),
  on(
    RoadmapEntityActions.computeMultipleRoadmapOKAction,
    (state, { data: { roadmapIds, ...updatedFields } }) =>
      roadmapAdapter.upsertMany(
        roadmapIds.map((id) => ({
          id,
          ...updatedFields,
          canLaunchRoadmap: false,
          loading: false,
        })),
        state,
      ),
  ),
  on(
    RoadmapEntityActions.computeMultipleRoadmapNOKAction,
    (state, { params }) =>
      roadmapAdapter.upsertMany(
        params.roadmapIds.map((id) => ({ id, loading: false })),
        state,
      ),
  ),
  // SINGLE RELOAD
  on(
    RoadmapEntityActions.reloadRoadmapSilentlyAction,
    (state, { roadmapId, status, feStatus }) => {
      const previousRun = Coerce.toObject(state.entities[roadmapId]).run;
      return roadmapAdapter.upsertOne(
        {
          id: roadmapId,
          status: feStatus,
          run: {
            ...Coerce.toObject(previousRun),
            status,
          },
        },
        state,
      );
    },
  ),
  on(
    RoadmapEntityActions.updateResultsXlsRegenerationAction,
    (state, { roadmapId, status }) => {
      return roadmapAdapter.upsertOne(
        { id: roadmapId, resultsRegenerating: status === 'start' },
        state,
      );
    },
  ),
  // QUEUE UPDATE
  on(
    RoadmapEntityActions.updateQueueDetailsAction,
    (state, { id, queueCount, queueFailed }) =>
      roadmapAdapter.upsertOne({ id, queueCount, queueFailed }, state),
  ),
  // UPDATE RUN SETTINGS
  on(RoadmapEntityActions.updateRunSettingsAction, (state, { params }) =>
    roadmapAdapter.upsertOne({ id: params.roadmapId, loading: true }, state),
  ),
  on(
    RoadmapEntityActions.updateRunSettingsOKAction,
    (state, { type, ...data }) => {
      const roadmap = data.response;
      const storedRoadmap = state.entities[roadmap.roadmapId];
      return roadmapAdapter.upsertOne(
        {
          ...{
            ...storedRoadmap,
            runSettings: roadmap.runSettings,
            updatedAt: roadmap.updatedAt,
            updatedBy: roadmap.updatedBy,
          },
          loading: false,
          loaded: true,
        },
        state,
      );
    },
  ),
  on(RoadmapEntityActions.updateRunSettingsNOKAction, (state, { roadmapId }) =>
    roadmapAdapter.upsertOne({ id: roadmapId, loading: false }, state),
  ),
  // FETCH ROADMAP CAMPAIGNS
  on(RoadmapEntityActions.fetchRoadmapCampaigns, (state, { roadmapId }) =>
    roadmapAdapter.upsertOne(
      { id: roadmapId, campaignsLoading: true, campaignsLoaded: false },
      state,
    ),
  ),
  on(
    RoadmapEntityActions.fetchRoadmapCampaignsOK,
    (state, { data, roadmapId }) =>
      roadmapAdapter.upsertOne(
        {
          id: roadmapId,
          campaigns: data,
          campaignsLoading: false,
          campaignsLoaded: true,
        },
        state,
      ),
  ),
  on(RoadmapEntityActions.fetchRoadmapCampaignsNOK, (state, { roadmapId }) =>
    roadmapAdapter.upsertOne(
      {
        id: roadmapId,
        campaignsLoading: false,
      },
      state,
    ),
  ),
  // UPDATE ROADMAP CAMPAIGNS
  on(RoadmapEntityActions.updateRoadmapCampaigns, (state, { roadmapId }) =>
    roadmapAdapter.upsertOne({ id: roadmapId, updating: true }, state),
  ),
  on(
    RoadmapEntityActions.updateRoadmapCampaignsOK,
    (state, { campaigns, roadmap }) => {
      const campaignIds = campaigns.map((campaign) => campaign.id);
      const noChangeCampaigns = Coerce.toArray(
        Coerce.toObject(state.entities[roadmap.roadmapId]).campaigns,
      ).filter((campaign) => !campaignIds.includes(campaign.id));

      return roadmapAdapter.upsertOne(
        {
          id: roadmap.roadmapId,
          campaigns: noChangeCampaigns.concat(campaigns),
          updating: false,
          updatedAt: roadmap.updatedAt,
          updatedBy: roadmap.updatedBy,
        },
        state,
      );
    },
  ),
  on(RoadmapEntityActions.updateRoadmapCampaignsNOK, (state, { roadmapId }) =>
    roadmapAdapter.upsertOne(
      {
        id: roadmapId,
        updating: false,
      },
      state,
    ),
  ),
  // FETCH ROADMAP CAMPAIGNS
  on(RoadmapEntityActions.deleteRoadmapCampaigns, (state, { roadmapId }) =>
    roadmapAdapter.upsertOne({ id: roadmapId, updating: true }, state),
  ),
  on(
    RoadmapEntityActions.deleteRoadmapCampaignsOK,
    (state, { campaigns, roadmap }) => {
      const campaignIds = campaigns.map((c) => c.id);
      return roadmapAdapter.upsertOne(
        {
          id: roadmap.roadmapId,
          campaigns: Coerce.toArray(
            Coerce.toObject(state.entities[roadmap.roadmapId]).campaigns,
          ).filter((campaign) => !campaignIds.includes(campaign.id)),
          updating: false,
          updatedAt: roadmap.updatedAt,
          updatedBy: roadmap.updatedBy,
        },
        state,
      );
    },
  ),
  on(RoadmapEntityActions.deleteRoadmapCampaignsNOK, (state, { roadmapId }) =>
    roadmapAdapter.upsertOne(
      {
        id: roadmapId,
        updating: false,
      },
      state,
    ),
  ),
  on(
    RoadmapEntityActions.cascadeUpdate,
    (state, { clientId, roadmapId, updatedAt, updatedBy }) => {
      return roadmapAdapter.upsertOne(
        { clientId, roadmapId, updatedAt, updatedBy },
        { ...state, loading: false },
      );
    },
  ),
);

export const RoadmapEntityStore = {
  featureName: ROADMAP_FEATURE_NAME,
  reducer: roadmapReducer,
};

export function RoadmapReducer(
  state: RoadmapState | undefined,
  action: Action,
) {
  return roadmapReducer(state, action);
}

import { ChartOptions } from 'chart.js';
import Chart from 'chart.js/auto';
import { cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import {
  clearLegend,
  generateHTMLLegendPlugin,
  LegendData,
} from 'ssotool-app/shared/charts.plugins';
import { ColorSchemeService } from 'ssotool-shared/services';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { WaterfallMetadata } from './waterfall.model';

@Component({
  selector: 'sso-waterfall-component',
  templateUrl: './waterfall.component.html',
  styleUrls: ['./waterfall.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ColorSchemeService],
})
export class WaterfallComponent {
  private _meta;
  private _showZeroValues = false;
  private hasData = new BehaviorSubject<boolean>(false);
  private baseColors = this.schemer.getBaseColors();
  hasData$ = this.hasData.asObservable();
  waterfallChart;
  isHideZeroValuesVisible = false;

  @Input() diffByOpacity: boolean = true;
  private opacityProgression: number = 0.4;

  @Input() hideToggle: boolean = false;

  @Input() set showZeroValues(show: boolean) {
    this._showZeroValues = show;
    this.initializeMetadata();
  }

  get showZeroValues() {
    return this._showZeroValues;
  }

  @Input() set hideZeroValues(value: boolean) {
    this.toggleHideZeroValues(value);
  }
  @Input() set metadata(meta: WaterfallMetadata[]) {
    this._meta = cloneDeep(meta);
    this.initializeMetadata();
  }

  @Output() legendClick = new EventEmitter();
  @Output() chartJSDataChanges$ = new EventEmitter();

  constructor(private schemer: ColorSchemeService) {}

  toggleHideZeroValues(hideValue?: boolean) {
    let value = !this.showZeroValues;
    if (hideValue !== undefined) {
      value = hideValue;
    }
    this._showZeroValues = value;
    this.initializeMetadata();
  }

  private initializeMetadata() {
    this.cleanup();
    this.isHideZeroValuesVisible = this._meta?.length === 1;
    this.waterfallChart = this.toChartJS(this._meta);
  }

  cleanup(): void {
    /* istanbul ignore else */
    this.hasData.next(false);

    if (this.waterfallChart) {
      this.waterfallChart.destroy();
    }

    clearLegend('legend');
  }

  private toChartJS(metadata: WaterfallMetadata[]) {
    const context = (document.getElementById('waterfall') as any)?.getContext(
      '2d',
    );

    if (!context) {
      return;
    }

    const resolvedUnit = metadata[0].unit || '';

    const data = this.toChartJSData(metadata);

    this.chartJSDataChanges$.emit({ ...data, ...{ name: 'waterfall' } });

    const waterfallChart = new Chart(context, {
      type: 'bar',
      data,
      options: {
        ...this.toChartJSOptions(metadata),
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              title: function (context: any) {
                const isRdMpNameUndefined =
                  typeof context[0].dataset.parentName == 'undefined';
                if (isRdMpNameUndefined) {
                  return context[0].label;
                }

                return !context[0].dataset.parentName.length
                  ? context[0].label
                  : `${context[0].dataset.parentName}: ${context[0].label}`;
              },
              label: function (context: any) {
                const barCoordinates = context.raw.y || context.raw;
                const absoluteValue = barCoordinates[0] - barCoordinates[1];
                const value = absoluteValue.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                });
                return `${
                  context.dataset.label || 'Value'
                }: ${value} ${resolvedUnit}`;
              },
            },
          },
          legend: {
            display: false,
          },
        },
      },
      plugins: [generateHTMLLegendPlugin('legend', this.openDialog.bind(this))],
    });

    if (data?.datasets?.length && data.datasets[0].data?.length) {
      this.hasData.next(true);
    }
    return waterfallChart;
  }

  openDialog(selected: LegendData) {
    this.legendClick.emit(selected);
  }

  private toChartJSData(metadata: WaterfallMetadata[]) {
    if (metadata.length === 1) {
      return this.toChartJSDataSingleResults(metadata[0]);
    }

    const allLabels = this.extractLabelsFromMetadata(metadata);

    const nonRangedDataPoints = metadata.map((stackGroup) => {
      return this.extractNonRangedDataset(stackGroup);
    });

    const rangedDatapoints = nonRangedDataPoints.map((stackGroup, index) => {
      return {
        data: this.extractRangedDataset(stackGroup.data, allLabels),
        label: stackGroup.name,
        backgroundColor: this.baseColors.map((color) =>
          this.appendOpacityToColor(index, color),
        ),
        maxBarThickness: 30,
        parentName: stackGroup.parentName,
      };
    });

    return {
      datasets: rangedDatapoints,
    };
  }

  private appendOpacityToColor(level: number, color: string) {
    return this.diffByOpacity ? color + this.getOpacity(level) : color;
  }

  private getOpacity(level: number): string {
    const FULL_OPACITY = 0xff;
    return Math.floor(
      FULL_OPACITY * (1 - level * this.opacityProgression),
    ).toString(16);
  }

  private toChartJSDataSingleResults(metadata: WaterfallMetadata) {
    const stackGroup = metadata;
    const filteredStacks = this._showZeroValues
      ? stackGroup.stacks
      : stackGroup.stacks.filter((stack) => stack.blocksTotalValue > 0);
    const labels = filteredStacks.map((stack) => stack.name);

    const defaultColor = this.baseColors;

    const backgroundColor = filteredStacks.map((stack, idx) => {
      if (stack.color) {
        return stack.color;
      }
      return defaultColor[idx];
    });

    const nonRangedDataPoints = filteredStacks.map((s) => s.blocksTotalValue);

    const grandTotal = Math.max(...nonRangedDataPoints);

    let rangedDataPoints = [];
    nonRangedDataPoints.reduce((acc, curr) => {
      const range = acc - curr;
      rangedDataPoints.push(
        this.floorToZero(range) <= 0
          ? [this.floorToZero(acc), 0]
          : [acc, this.floorToZero(range)],
      );
      return range === 0 ? curr : range;
    }, grandTotal);

    return {
      labels,
      datasets: [
        {
          label: stackGroup.name,
          data: rangedDataPoints,
          backgroundColor,
          maxBarThickness: 30,
        },
      ],
    };
  }

  /**
   * Extract all labels in the metadata
   * @param metadata Waterfall chart metadata array
   * @returns array of unique labels
   */
  private extractLabelsFromMetadata(metadata: WaterfallMetadata[]) {
    const labelsPerMetadata = metadata.map((stackGroup) => {
      const labels = [];
      stackGroup.stacks.forEach((stack) => {
        if (!this._showZeroValues) {
          if (stack.blocksTotalValue > 0) {
            labels.push(stack.name);
          }
        } else {
          labels.push(stack.name);
        }
      });
      return labels;
    });

    const uniqueLabels = [];
    labelsPerMetadata.forEach((labelGroup) => {
      labelGroup.forEach((label) => {
        if (!uniqueLabels.find((uniqueLabel) => uniqueLabel === label)) {
          uniqueLabels.push(label);
        }
      });
    });

    return uniqueLabels;
  }

  /**
   * Extract non-ranged dataset (raw values for each entity)
   * of a waterfall metadata
   * @param metadata Waterfall chart metadata
   * @returns array of non-ranged datapoints
   */
  private extractNonRangedDataset(stackGroup: WaterfallMetadata) {
    const stacks = stackGroup.stacks;
    const nonRangedDataPoints = stacks.map((s) => {
      return {
        x: s.name,
        y: s.blocksTotalValue,
      };
    });

    return {
      data: nonRangedDataPoints,
      baseColor: stackGroup.baseColor,
      unit: stackGroup.unit,
      name: stackGroup.name,
      parentName: stackGroup.parentName,
    };
  }

  /**
   * Transform a non-ranged dataset into a ranged dataset
   * that is compatible with ChartJS data object structure.
   * All transformation is based on a reference label array.
   * Note that in a waterfall chart, some dataset may have 0 value
   * on a certain entity (e.g. None, Existing assets).
   * This transformation makes sure that it has [0,0] datapoint
   * for that.
   * @param stackGroup the stack group of the metadata
   * @param labels the array of reference labels
   * @returns array of non-ranged datapoints
   */
  private extractRangedDataset(stackGroup, labels: string[]) {
    const sortedByBlockValue = stackGroup.sort((a, b) => b.y - a.y);
    const grandTotal = sortedByBlockValue[0];
    let barEdge = grandTotal.y;

    const rangedDataset = labels.map((label) => {
      const datapoint = stackGroup.find(
        (nonRangedPoint, index) => nonRangedPoint.x === label && index !== 0,
      );
      if (!!datapoint) {
        if (datapoint.y <= 0) {
          return {
            x: datapoint.x,
            y: null,
          };
        }

        const barEnd = barEdge - datapoint.y;
        const y = [this.floorToZero(barEdge), this.floorToZero(barEnd)];
        barEdge = this.floorToZero(barEdge - datapoint.y);
        return {
          x: datapoint.x,
          y,
        };
      } else {
        return {
          x: label,
          y: null,
        };
      }
    });

    // insert grand total
    rangedDataset.every((point) => {
      if (point.x === grandTotal.x) {
        point.y = [grandTotal.y, 0];
        return false;
      }
      return true;
    });

    return rangedDataset;
  }

  /**
   * Round extremely small values to 0.
   * @param number the number to floor
   * @returns the floored number
   */
  private floorToZero(number: number) {
    return number < 0.01 ? 0 : number;
  }

  private toChartJSOptions(data: WaterfallMetadata[]): ChartOptions {
    return {
      responsive: !!data[0].responsive,
      scales: {
        x: {
          grid: {
            offset: true,
          },
          title: {
            display: true,
            text: data[0].xAxisName,
          },
        },
        y: {
          grid: {
            offset: true,
          },
          title: {
            display: true,
            text: data[0].yAxisName,
          },
        },
      },
    };
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objFilter',
})
export class ObjectFilterPipe implements PipeTransform {
  public transform(arr: Array<any>, filters: Record<string, any>): Array<any> {
    return arr.filter((item) =>
      Object.entries(filters).every(
        ([key, filterValue]) => item[key] === filterValue,
      ),
    );
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CampaignPageComponent } from './campaign-page.component';
import { CampaignImportComponent } from './components/import/import.component';
import {
  CampaignImportResolverService,
  CampaignResolverService,
} from './services';
import { CampaignExportResolverService } from './services/campaign-export-resolver.service';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: CampaignPageComponent,
    resolve: [CampaignResolverService, CampaignExportResolverService],
  },
  {
    path: 'import',
    component: CampaignImportComponent,
    resolve: [CampaignImportResolverService],
    data: {
      breadcrumb: 'Campaigns Import',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CampaignRoutingModule {}

import {
  Client,
  ClientDialog,
  ClientFacadeService,
} from 'ssotool-client/store';
import { CustomValidators } from 'ssotool-core/utils';
import { cleanForm, FormFieldErrorMessageMap } from 'ssotool-shared';
import { BaseComponent } from 'ssotool-shared/component/base';

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sso-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientFormComponent extends BaseComponent implements OnInit {
  mode = 'add';
  loading$ = this.clientFacade.loading$;
  clientId: string;

  clientForm: FormGroup = this.formBuilder.group({
    name: ['', [Validators.required, CustomValidators.whiteSpaceValidator]],
    description: '',
  });

  errorMessages: FormFieldErrorMessageMap = {
    name: {
      required: 'The client name is required.',
    },
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ClientDialog,
    public dialogRef: MatDialogRef<ClientFormComponent>,
    private formBuilder: FormBuilder,
    private clientFacade: ClientFacadeService,
  ) {
    super();
  }

  ngOnInit() {
    this.mode = this.data.mode;
    // istanbul ignore else
    if (this.mode === 'update') {
      this.clientId = this.data.client.clientId;
      this.getClient();
    }
  }

  getClient() {
    this.clientFacade
      .selectClient$(this.clientId)
      .pipe()
      .subscribe((client) => {
        if (client) {
          this.setFormValue(client);
        } else {
          this.fetchClient();
        }
      });
  }

  setFormValue(client: Client) {
    this.clientForm.controls.name.patchValue(client.name);
    this.clientForm.controls.description.patchValue(client.description);
  }

  /**
   * When no client loaded, get from backend,
   */
  fetchClient() {
    this.clientFacade.get(this.clientId);
  }

  onSubmit() {
    // istanbul ignore else
    if (this.clientForm.valid) {
      cleanForm(this.clientForm);
      if (this.mode === 'update') {
        this.clientFacade.update(
          this.clientId,
          this.clientForm.controls.name.value,
          this.clientForm.controls.description.value,
        );
      } else {
        this.clientFacade.create(
          this.clientForm.controls.name.value,
          this.clientForm.controls.description.value,
        );
      }
    }
    this.dialogRef.close();
  }
  onCancel() {
    this.dialogRef.close(this.data);
  }
}

import { LogDetails } from 'ssotool-app/app.model';

import { createAction, props } from '@ngrx/store';

import {
  RoadmapImportFormModel,
  RoadmapImportModel,
} from './roadmap-import.model';

const get = createAction(
  '[Roadmap Import Page] Get roadmap import information',
  props<{ clientId: string; roadmapId: string; importId: string }>(),
);

const getSuccess = createAction(
  '[Roadmap Import API] Get roadmap import information success',
  props<{ data: any; message: string }>(),
);

const getFailure = createAction(
  '[Roadmap Import API] Get roadmap import information failure',
  props<{ data: any; error: any; message: string }>(),
);

const getList = createAction(
  '[Roadmap Import Page], Get list of roadmap import',
  props<{ clientId: string; roadmapId: string }>(),
);

const getListSuccess = createAction(
  '[Roadmap Import API] Get list of roadmap import success',
  props<{
    data: { data: RoadmapImportModel[]; clientId: string };
    message: string;
  }>(),
);

const getListFailure = createAction(
  '[Roadmap Import API] Get list of roadmap import failure',
  props<{ data: any; error: any; message: string }>(),
);

const updateImportStatus = createAction(
  '[Roadmap Import List] Update import status',
  props<{
    clientId: string;
    roadmapId: string;
    importId: string;
    status: string;
  }>(),
);

const importRoadmap = createAction(
  '[Roadmap Import Data Page], Import roadmap data',
  props<{
    clientId: string;
    roadmapId: string;
    data: RoadmapImportFormModel;
  }>(),
);

const importRoadmapSuccess = createAction(
  '[Roadmap Import API], Import roadmap data success',
  props<{ data: any; message: string }>(),
);

const importRoadmapFailure = createAction(
  '[Roadmap Import API], Import roadmap data failure',
  props<{ data: RoadmapImportFormModel; error: any; message: string }>(),
);

const downloadRoadmapImportData = createAction(
  '[Roadmap Import Data Page], Download Roadmap Data',
  props<{ clientId: string; roadmapId: string; importId: string }>(),
);

const downloadRoadmapImportDataSuccess = createAction(
  '[Roadmap Import API], Download Roadmap Import Data Success',
  props<{ data: any; message: string }>(),
);

const downloadRoadmapImportDataFailure = createAction(
  '[Roadmap Import API], Download Roadmap Import Data Failure',
  props<{ data: any; error: any; message: string }>(),
);

const downloadErrorLogs = createAction(
  '[Roadmap export API], Download error logs',
  props<{ clientId: string; roadmapId: string; importId: string }>(),
);

const downloadErrorLogsSuccess = createAction(
  '[Roadmap export API], Download error logs success',
  props<{ importId: string; data: any; message: string }>(),
);

const downloadErrorLogsFailure = createAction(
  '[Roadmap export API], Download error logs failure',
  props<{ data: any; error: any; message: string }>(),
);

const deleteImport = createAction(
  '[Roadmap export API], Delete Import',
  props<{ clientId: string; roadmapId: string; importId: string }>(),
);

const deleteImportSuccess = createAction(
  '[Roadmap export API], Delete import success',
  props<{ importId: string; data: any; message: string }>(),
);

const deleteImportFailure = createAction(
  '[Roadmap export API], Delete import failure',
  props<{ data: any; error: any; message: string }>(),
);

const updateImportLogDetails = createAction(
  '[Roadmap Import Data Page], Import log details update',
  props<{ importId: string; roadmapId: string; logDetails: LogDetails }>(),
);

const downloadTemplate = createAction(
  '[Roadmap Import Data Page] Download Template',
  props<{ clientId: string }>(),
);

const downloadTemplateSuccess = createAction(
  '[Roadmap Import API] Download template success',
  props<{ data: any; message: string }>(),
);

const downloadTemplateFailure = createAction(
  '[Roadmap Import API] Download template failure',
  props<{ data: any; error: any; message: string }>(),
);

export const RoadmapImportActions = {
  get,
  getSuccess,
  getFailure,
  getList,
  getListSuccess,
  getListFailure,
  updateImportStatus,
  importRoadmap,
  importRoadmapSuccess,
  importRoadmapFailure,
  downloadRoadmapImportData,
  downloadRoadmapImportDataSuccess,
  downloadRoadmapImportDataFailure,
  downloadErrorLogs,
  downloadErrorLogsSuccess,
  downloadErrorLogsFailure,
  deleteImport,
  deleteImportSuccess,
  deleteImportFailure,
  updateImportLogDetails,
  downloadTemplate,
  downloadTemplateSuccess,
  downloadTemplateFailure,
};

import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, mergeMap, skipWhile } from 'rxjs/operators';
import { CampaignFacadeService } from 'ssotool-app/+campaign/store';
import { ClientFacadeService } from 'ssotool-app/+client';
import { distinctObject } from 'ssotool-app/shared';

import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

import { UpdateRunSettingsParams } from './roadmap-parameters.model';
import { RoadmapEntityActions } from './roadmap.actions';
import {
  PortfolioRoadmap,
  RoadmapCampaign,
  RoadmapCascadeUpdateData,
  RoadmapParameters,
  RoadmapTarget,
} from './roadmap.model';
import { RoadmapState } from './roadmap.reducer';
import {
  RoadmapCampaignDependencies,
  selectAffectedRoadmaps,
  selectCampaignLoaded,
  selectCampaignLoading,
  selectLoaded,
  selectLoading,
  selectResultsXlsRegenerating,
  selectRoadmap,
  selectRoadmapAccessed,
  selectRoadmapCampaigns,
  selectRoadmapList,
  selectRoadmapLoading,
  selectRoadmapQueueCount,
  selectRoadmapTargets,
  selectRoadmapUpdating,
  selectSelectedVariationIdOfRoadmap,
} from './roadmap.selector';
import { formatDate } from '@angular/common';

@Injectable()
export class RoadmapFacadeService {
  constructor(
    private store: Store<RoadmapState>,
    private actionSubject$: ActionsSubject,
    private campaignFacade: CampaignFacadeService,
    private clientFacade: ClientFacadeService,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  // STATE
  loading$ = this.store.pipe(select(selectLoading));
  loaded$ = this.store.pipe(select(selectLoaded));
  roadmaps$: Observable<PortfolioRoadmap[]> = this.store.pipe(
    select(selectRoadmapList),
  );

  selectedVariationId$ = (roadmapId: string) =>
    this.store.pipe(select(selectSelectedVariationIdOfRoadmap({ roadmapId })));

  campaignLoaded$ = (roadmapId: string) =>
    this.store.pipe(select(selectCampaignLoaded({ roadmapId })));

  campaignLoading$ = (roadmapId: string) =>
    this.store.select(selectCampaignLoading({ roadmapId }));

  // GETTERS
  roadmap$(roadmapId: string): Observable<PortfolioRoadmap> {
    return this.store.select(selectRoadmap({ roadmapId }));
  }

  getRoadmapLoading(roadmapId: string) {
    return this.store.select(selectRoadmapLoading({ roadmapId }));
  }

  getRoadmapUpdating(roadmapId: string) {
    return this.store.select(selectRoadmapUpdating({ roadmapId }));
  }

  getRoadmapAccessed(roadmapId: string) {
    return this.store.select(selectRoadmapAccessed({ roadmapId }));
  }

  getRoadmapQueueCount(roadmapId: string): Observable<number> {
    return this.store.select(selectRoadmapQueueCount({ roadmapId }));
  }

  getRoadmapQueueFailed(roadmapId: string): Observable<boolean> {
    return this.actionSubject$.pipe(
      filter((action) =>
        [
          RoadmapEntityActions.updateRoadmapOKAction.type,
          RoadmapEntityActions.updateRoadmapNOKAction.type,
        ].includes(action.type),
      ),
      filter(
        (action: { params: RoadmapParameters } & TypedAction<string>) =>
          action?.params?.roadmapId === roadmapId,
      ),
      map(
        (action) =>
          action.type === RoadmapEntityActions.updateRoadmapNOKAction.type,
      ),
    );
  }

  isResultsXlsRegenerating(roadmapId: string) {
    return this.store.select(selectResultsXlsRegenerating({ roadmapId }));
  }

  private checkRoadmapDependenciesLoaded(
    clientId: string,
  ): Observable<boolean> {
    return combineLatest([
      this.campaignFacade.fetched$,
      this.clientFacade.dataLoaded$(clientId),
    ]).pipe(
      map((loadedStates) => loadedStates.every(Boolean)),
      skipWhile((loadedAll) => !loadedAll),
      first(),
    );
  }

  private getRoadmapDependencies(
    clientId: string,
    roadmapId: string,
  ): Observable<RoadmapCampaignDependencies> {
    return this.checkRoadmapDependenciesLoaded(clientId).pipe(
      mergeMap(() =>
        combineLatest([
          this.campaignFacade.getClientCampaigns$,
          this.clientFacade.selectGeos$(clientId),
          this.clientFacade.selectGeographyGroups$(clientId),
          this.clientFacade.selectRunSettings$(clientId),
          this.clientFacade.selectSites$(clientId),
        ]).pipe(
          distinctObject,
          map(([campaigns, geos, geoGroups, runSettings, sites]) => ({
            campaigns,
            geos,
            geoGroups,
            runSettings,
            roadmapId,
            sites,
          })),
        ),
      ),
    );
  }

  getRoadmapCampaigns(
    roadmapId: string,
    clientId: string,
  ): Observable<RoadmapCampaign[]> {
    return this.getRoadmapDependencies(clientId, roadmapId).pipe(
      mergeMap((dependencies) =>
        this.store.select(
          selectRoadmapCampaigns({
            roadmapId,
            ...dependencies,
          }),
        ),
      ),
    );
  }

  getRoadmapTargets(roadmapId: string): Observable<RoadmapTarget[]> {
    return this.store.select(selectRoadmapTargets({ roadmapId }));
  }

  getAffectedRoadmaps(roadmapIds: string[]): Observable<PortfolioRoadmap[]> {
    return this.store.select(selectAffectedRoadmaps({ roadmapIds }));
  }

  // CRUD FACADE
  accessRoadmap(roadmapId: string) {
    this.store.dispatch(
      RoadmapEntityActions.accessRoadmapAction({ roadmapId }),
    );
  }

  get(clientId: string, roadmapId: string, isSuccessful?: boolean) {
    this.store.dispatch(
      RoadmapEntityActions.getRoadmapAction({
        clientId,
        roadmapId,
        isSuccessful,
      }),
    );
  }

  getAllRoadmap(clientId: string) {
    this.store.dispatch(RoadmapEntityActions.getAllRoadmapAction({ clientId }));
  }

  createRoadmap(params: RoadmapParameters) {
    this.store.dispatch(RoadmapEntityActions.createRoadmapAction(params));
    return this.actionSubject$.pipe(
      filter(
        (action) =>
          action.type === RoadmapEntityActions.createRoadmapOKAction.type,
      ),
    );
  }

  updateRoadmap(params: RoadmapParameters): Observable<boolean> {
    this.store.dispatch(RoadmapEntityActions.updateRoadmapAction(params));
    return this.actionSubject$.pipe(
      filter((action) =>
        [
          RoadmapEntityActions.updateRoadmapOKAction.type,
          RoadmapEntityActions.updateRoadmapNOKAction.type,
        ].includes(action.type),
      ),
      first(),
      map(
        (action) =>
          action.type === RoadmapEntityActions.updateRoadmapOKAction.type,
      ),
    );
  }

  deleteRoadmap(params: RoadmapParameters) {
    this.store.dispatch(RoadmapEntityActions.deleteRoadmapAction(params));
  }

  setToBaseline(params: RoadmapParameters) {
    this.store.dispatch(RoadmapEntityActions.setToBaselineAction(params));
  }

  computeRoadmap(params: RoadmapParameters) {
    this.store.dispatch(RoadmapEntityActions.computeRoadmapAction(params));
  }

  stopComputeRoadmap(clientId: string, roadmapId: string) {
    this.store.dispatch(
      RoadmapEntityActions.stopComputeRoadmapAction({ clientId, roadmapId }),
    );
  }

  computeMultipleRoadmap(
    clientId: string,
    roadmapIds: string[],
    proceed?: boolean,
  ) {
    this.store.dispatch(
      RoadmapEntityActions.computeMultipleRoadmapAction({
        clientId,
        roadmapIds,
        proceed,
      }),
    );
  }

  duplicateRoadmaps(clientId: string, roadmapIds: string[]) {
    this.store.dispatch(
      RoadmapEntityActions.duplicateRoadmapsAction({ clientId, roadmapIds }),
    );
  }

  updateRunSettings(
    params: UpdateRunSettingsParams,
    reloadAfter: boolean = true,
  ) {
    this.store.dispatch(
      RoadmapEntityActions.updateRunSettingsAction({ params, reloadAfter }),
    );
    return this.actionSubject$.pipe(
      filter(
        (action) =>
          action.type === RoadmapEntityActions.updateRunSettingsOKAction.type,
      ),
      map(
        (action) =>
          action.type === RoadmapEntityActions.updateRunSettingsOKAction.type,
      ),
      first(),
    );
  }

  fetchRoadmapCampaigns(clientId: string, roadmapId: string) {
    this.store.dispatch(
      RoadmapEntityActions.fetchRoadmapCampaigns({ clientId, roadmapId }),
    );
  }

  updateRoadmapCampaigns(
    clientId: string,
    roadmapId: string,
    campaigns: Array<RoadmapCampaign>,
  ) {
    this.store.dispatch(
      RoadmapEntityActions.updateRoadmapCampaigns({
        clientId,
        roadmapId,
        campaigns,
      }),
    );

    return this.actionSubject$.pipe(
      filter((action) =>
        [
          RoadmapEntityActions.updateRoadmapCampaignsOK.type,
          RoadmapEntityActions.updateRoadmapCampaignsNOK.type,
        ].includes(action.type),
      ),
      first(),
      map(
        (action) =>
          action.type === RoadmapEntityActions.updateRoadmapCampaignsOK.type,
      ),
    );
  }

  deleteRoadmapCampaigns(
    clientId: string,
    roadmapId: string,
    campaigns: Array<RoadmapCampaign>,
  ) {
    this.store.dispatch(
      RoadmapEntityActions.deleteRoadmapCampaigns({
        clientId,
        roadmapId,
        campaigns,
      }),
    );

    return this.actionSubject$.pipe(
      filter((action) =>
        [
          RoadmapEntityActions.deleteRoadmapCampaignsOK.type,
          RoadmapEntityActions.deleteRoadmapCampaignsNOK.type,
        ].includes(action.type),
      ),
      first(),
      map(
        (action) =>
          action.type === RoadmapEntityActions.deleteRoadmapCampaignsOK.type,
      ),
    );
  }

  // NOTIFICATION FACADE
  reloadSingleRoadmap(clientId: string, roadmapId: string, status: string) {
    this.store.dispatch(
      RoadmapEntityActions.reloadSingleRoadmapAction({
        clientId,
        roadmapId,
        status,
      }),
    );
  }

  computeCanLaunchRoadmap(clientId: string, roadmapId: string, status: string) {
    this.store.dispatch(
      RoadmapEntityActions.computeCanLaunchRoadmapAction({
        clientId,
        roadmapId,
        status,
      }),
    );
  }

  reloadMultipleRoadmap(
    clientId: string,
    roadmapIds: string[],
    status: string,
  ) {
    this.store.dispatch(
      RoadmapEntityActions.reloadMultipleRoadmapAction({
        clientId,
        roadmapIds,
        status,
      }),
    );
  }

  /**
   * Reload all affected roadmaps when a new client data is imported.
   * @param clientId
   * @param roadmapIds
   */
  reloadAffectedRoadmap(clientId: string, roadmapIds: string[]) {
    this.store.dispatch(
      RoadmapEntityActions.reloadAffectedRoadmapAction({
        clientId,
        roadmapIds,
      }),
    );
  }

  /**
   * Dispatch an action to update results xls regeneragtion progress.
   * @param roadmapId id of the roadmap.
   * @param status status of the results xls regeneration.
   */
  updateResultsXlsRegeneration(roadmapId: string, status: string) {
    this.store.dispatch(
      RoadmapEntityActions.updateResultsXlsRegenerationAction({
        roadmapId,
        status,
      }),
    );
  }

  queueUpdateRoadmap(params: RoadmapParameters) {
    this.store.dispatch(RoadmapEntityActions.queueUpdateRoadmapAction(params));
  }

  updateQueueDetails(
    id: string,
    queueCount: number,
    queueFailed: boolean = false,
  ) {
    this.store.dispatch(
      RoadmapEntityActions.updateQueueDetailsAction({
        id,
        queueCount,
        queueFailed,
      }),
    );
  }

  cascadeUpdate(data: RoadmapCascadeUpdateData) {
    this.store.dispatch(
      RoadmapEntityActions.cascadeUpdate({
        ...data,
        updatedAt: this.formatDate(data.updatedAt),
      }),
    );
  }

  formatDate(dateString: string) {
    return formatDate(dateString, 'dd MMM YYYY HH:mm', this.locale);
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  getWindow(): any {
    return window;
  }

  reloadWindow(duration: number = 1000): any {
    return setTimeout(() => {
      this.getWindow().location.reload();
    }, duration);
  }
}

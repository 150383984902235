import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'sso-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SearchInputComponent,
      multi: true,
    },
  ],
})
export class SearchInputComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() placeholder: string;
  private _onTouched = () => {};

  searchInput = new FormControl('');
  constructor() {
    this.searchInput.valueChanges.pipe(
      // startWith(this.searchInput.value),
      debounceTime(400),
      distinctUntilChanged(),
    );
  }
  writeValue(obj: any): void {}
  registerOnChange(fn) {
    this.searchInput.valueChanges.subscribe((val) => fn(val));
  }

  registerOnTouched(fn) {}
  setDisabledState?(isDisabled: boolean): void {}
}

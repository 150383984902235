import {
  FormFieldErrorMessage,
  isKeyPressedNumber,
} from 'ssotool-shared/helpers/form-helpers';

import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NgControl,
  Validator,
} from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { TooltipPosition } from '@angular/material/tooltip';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'sso-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent
  implements ControlValueAccessor, Validator, AfterContentInit
{
  control: FormControl;
  _onChange: Function;

  @Input() size: 'medium' | 'large' = 'medium';
  @Input() required: boolean;
  @Input() hint: string;
  @Input() label: string;
  @Input() tooltip: string;
  @Input() tooltipPosition: TooltipPosition = 'right';
  @Input() appearance: MatFormFieldAppearance;
  @Input() placeholder: string;
  @Input() type: string;
  @Input() maxLength: number;
  private _errorMessageMap = {};
  @Input() set errorMessageMap(errorMessageMap: FormFieldErrorMessage) {
    this._errorMessageMap = errorMessageMap || {};
  }
  get errorMessageMap() {
    return this._errorMessageMap;
  }
  @Input() errorTranslationKey;
  @Input() errorData = {};
  @Input() textAreaLines = 8;
  @Input() isTextArea: boolean;
  @Input() readonly: boolean;
  @Input() icon: string;
  @Input() unit: string;
  @Input() prefixIcon: string;
  @Input() suffixIcon: string;
  @Input() customClass: string = '';

  @Output() keyUp = new EventEmitter();
  @Output() blurred = new EventEmitter();

  get rows() {
    return this.unit ? 1 : this.textAreaLines;
  }

  constructor(@Self() @Optional() private ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  ngAfterContentInit(): void {
    this.control = this.ngControl.control as FormControl;
  }

  writeValue(value: string): void {}

  registerOnChange(fn: () => void): void {}

  registerOnTouched(fn: any): void {}

  validate(b: FormControl) {
    return !this.control || this.control.valid
      ? null
      : { error: 'Some fields are not fullfilled' };
  }

  onKeyUp(event: UIEvent) {
    this.keyUp.emit(event);
  }

  onBlur(event) {
    this.blurred.emit(event);
  }

  onKeyPress(event: KeyboardEvent) {
    if (this.type === 'number' && !isKeyPressedNumber(event)) {
      event.preventDefault();
    }
  }
}

<div fxLayout="column" fxLayoutGap="5px">
  <div fxLayout="row">
    <h3 *ngIf="name" class="sso-remove-margin">{{ name | translate }}</h3>

    <eyes-search-input
      *ngIf="searchInput"
      fxFlex="50%"
      (keyIn)="onKeyIn($event)"
    ></eyes-search-input>

    <span fxFlex></span>

    <button
      mat-button
      *ngIf="!!addButton"
      type="button"
      color="primary"
      [disabled]="loading || addButtonDisabled"
      (click)="onAdd()"
    >
      <mat-icon *ngIf="addButton?.icon" matPrefix>{{
        addButton?.icon
      }}</mat-icon>
      <b>{{ addButton?.name | translate }}</b>
    </button>
  </div>

  <mat-table
    matSort
    class="eyes-table"
    [dataSource]="dataSource"
    (matSortChange)="setCustomSort()"
    [matSortDirection]="options?.sortDirection"
  >
    <ng-container
      *ngFor="let column of columnsDef | keyvalue"
      [matColumnDef]="column.key"
    >
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="!column.value.sortable"
        [ngStyle]="{ 'justify-content': column.value.alignment }"
      >
        <div [innerHTML]="column.value.name"></div>
        <sso-tooltip-anchor
          *ngIf="column.value.toolTip"
          [message]="column.value.toolTip | translate"
        >
        </sso-tooltip-anchor>
      </mat-header-cell>

      <mat-cell
        *matCellDef="let row"
        [ngSwitch]="checkType(column.value.type, row)"
        [ngStyle]="{ 'justify-content': column.value.alignment }"
      >
        <div *ngSwitchCase="'action'">
          <button
            *ngFor="let button of column.value?.actionButtons"
            mat-icon-button
            type="button"
            color="primary"
            [disabled]="(button?.disabled && button?.disabled(row)) || loading"
            (click)="button?.eyesClick(row)"
          >
            <mat-icon
              aria-label="button?.label"
              matBadgeSize="small"
              matBadgeColor="accent"
              [matBadge]="getBadgeValue(row.clientId)"
              [matBadgeHidden]="isBadgeHidden(row, button?.action)"
              tooltipPosition="center"
              [matTooltip]="button?.tooltip"
              >{{ button?.iconPrefix }}</mat-icon
            >
          </button>
        </div>

        <div *ngSwitchCase="'reference'">
          {{
            (references[column.value.referenceKey] || {})[row[column.key]] ||
              row[column.key] ||
              '-'
          }}
        </div>

        <div
          *ngSwitchCase="'referenceList'"
          class="list-reference"
          [matTooltip]="
            getValueFromKeyList(row[column.key], column.value.referenceKey)
          "
        >
          {{
            getValueFromKeyList(row[column.key], column.value.referenceKey) ||
              '-'
          }}
        </div>

        <div *ngSwitchCase="'referenceLink'">
          <!--
            This will return reference key as routerLink;
            If referenceKey does not exist it will return the row value;
            If row value does not exist it will return nothing.
          -->
          <a
            [routerLink]="
              (references[column.value.referenceKey] || {})[row[column.key]] ||
              row[column.key] ||
              ''
            "
            color="primary"
          >
            {{ row[column.key] || '-' }}
          </a>
        </div>

        <div *ngSwitchCase="'referencePath'">
          <a
            [routerLink]="
              getRoutePath(column.value.referenceKey, row?.clientId, row?.id)
            "
            color="primary"
          >
            {{ row[column.key] || '-' }}
          </a>
        </div>

        <div *ngSwitchCase="'referenceLinkWithAction'">
          <a
            [routerLink]="
              (references[column.value.referenceKey] || {})[row[column.key]] ||
              row[column.key] ||
              ''
            "
            color="primary"
            (click)="row[column.value.eyesClick(row)]"
            >{{ row[column.key] || '-' }}</a
          >
        </div>

        <div
          *ngSwitchCase="'textOverflow'"
          class="text-overflow"
          [matTooltip]="row[column.key] || '-'"
        >
          {{ row[column.key] || '-' }}
        </div>

        <div *ngSwitchCase="'status'" [matTooltip]="row[column.key] || '-'">
          <div
            [class]="statusMap[row[column.key]]?.class"
            fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutGap="10%"
          >
            <mat-icon
              [attr.aria-label]="statusMap[row?.run?.status]?.label"
              *ngIf="statusMap[row[column.key]]?.icon"
            >
              {{ statusMap[row[column.key]]?.icon }}
            </mat-icon>
            <span style="white-space: nowrap"
              ><strong>{{ statusMap[row[column.key]]?.label }}</strong></span
            >
          </div>
        </div>

        <div *ngSwitchDefault>
          {{ row[column.key] || '-' }}
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsDef | keys"></mat-header-row>

    <mat-row
      *matRowDef="let row; columns: columnsDef | keys"
      [@rowAnimation]
      [ngClass]="{ 'emphasized-row baseline': row.isBaseline }"
    ></mat-row>
  </mat-table>
</div>

<div
  class="spinner-container"
  *ngIf="loading; else loadedFooter"
  fxLayout="column"
  fxLayoutAlign="start center"
  fxLayoutGap="10px"
>
  <mat-spinner strokeWidth="3" [diameter]="30"></mat-spinner>
  <span class="spinner-caption"> Loading... </span>
</div>

<ng-template #loadedFooter>
  <sso-table-message
    class="message"
    [style.margin-left.px]="20"
    [style.margin-top.px]="5"
    [loading]="loading"
    [error]="error"
    [dataLength]="dataSource?.data?.length"
    [filter]="dataSource?.filter"
    [filteredDataLength]="dataSource?.filteredData?.length"
    [noRecordsMessage]="message"
  >
  </sso-table-message>
</ng-template>

<mat-paginator hidePageSize showFirstLastButtons pageSize="20"></mat-paginator>

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BinDataFacadeService } from 'ssotool-app/+entities/stores/bindata/bindata-facade.service';

import { FlowVolumesBarChartDialogModel } from './flow-volumes-bar-chart-dialog.model';
import { BindataBarChartBaseComponent } from '../../bindata';
import { ClientFacadeService } from 'ssotool-app/+client';

@Component({
  selector: 'sso-flow-volumes-bar-chart-dialog',
  templateUrl: './flow-volumes-bar-chart-dialog.component.html',
  styleUrls: ['./flow-volumes-bar-chart-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowVolumesBarChartDialogComponent extends BindataBarChartBaseComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FlowVolumesBarChartDialogModel,
    public dialogRef: MatDialogRef<FlowVolumesBarChartDialogModel>,
    public binDataFacade: BinDataFacadeService,
    public clientFacade: ClientFacadeService,
  ) {
    super(data, dialogRef, binDataFacade, clientFacade);
  }
}

import { CORE_KPI_NAMES } from 'ssotool-app/app.references';
import { Coerce } from 'ssotool-app/shared/helpers';

import { KPIType } from '../stores/portfolio.model';
import { PortfolioAnalysis, ResultEntity } from '../stores/result/result.model';

export class AnalysisMapper {
  static mapToFrontend = (data: any): PortfolioAnalysis =>
    !!data
      ? {
          economics: [
            ...AnalysisMapper.mapKPIToResultEntity(
              data?.economic,
              KPIType.ECONOMICS,
            ),
          ],
          environmental: [
            ...AnalysisMapper.mapKPIToResultEntity(
              data?.environmental,
              KPIType.ENVIRONMENTAL,
            ),
          ],
          sustainability: [
            ...AnalysisMapper.mapKPIToResultEntity(
              data?.sustainability,
              KPIType.SUSTAINABILITY,
            ),
          ],
        }
      : null;

  private static mapKPIToResultEntity = (
    data = {},
    type: string,
  ): ResultEntity[] =>
    Object.entries<{ value: number; unit: string }>(
      AnalysisMapper.addPercentRateToKPIValue(data),
    )
      .map(
        ([name, value]) =>
          ({
            type,
            value: AnalysisMapper.getValue(value.value),
            name: CORE_KPI_NAMES[name],
            unit: value?.unit,
          } as ResultEntity),
      )
      .filter((_d) => !!_d?.name && (_d?.value === 0 || !!_d?.value));

  private static addPercentRateToKPIValue = (data): any => {
    const percentRateCombination = {
      total_energy_consumption: 'renewable_energy_rate',
    };
    const rateListKeys = Coerce.getObjValues(percentRateCombination);
    const _data = Coerce.getObjKeys(data)
      .filter((key) => !rateListKeys.includes(key) && !!data[key])
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});

    Object.entries(percentRateCombination).map(([kpiKey, rateKey]) => {
      if (_data[kpiKey] && data[rateKey]) {
        _data[kpiKey].value = [
          AnalysisMapper.getValue(data[kpiKey]?.value),
          AnalysisMapper.getValue(data[rateKey]?.value),
        ];
      }
    });

    return _data;
  };

  private static getValue = (value) => {
    if (value && typeof value === 'object') {
      return [
        AnalysisMapper.checkConvertValue(value[0]),
        AnalysisMapper.checkConvertValue(value[1]),
      ];
    } else {
      return AnalysisMapper.checkConvertValue(value);
    }
  };

  private static checkConvertValue = (value: number | string | null) => {
    const _value = Number(value);
    return isFinite(_value) ? Number(_value.toFixed(2)) : undefined;
  };
}

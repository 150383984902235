import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SSOToolRoutePathService {
  constructor() {}

  error = () => ['/error'];
  clients = () => ['/clients'];
  alphaRequests = () => ['/requests'];
  globalImport = () => ['/globalImport'];
  releaseNotes = () => ['/release-notes'];
  cognito = () => ['/cognito'];
  support = () => ['/support'];
  login = () => ['/login'];
  entities = (clientId: string) => ['/clients', clientId, 'entities'];
  clientImport = (clientId: string) => ['/clients', clientId, 'data'];
  ambition = (clientId: string) => ['/clients', clientId, 'ambition'];
  campaign = (clientId: string) => ['/clients', clientId, 'campaigns'];
  campaignImport = (clientId: string) => [
    '/clients',
    clientId,
    'campaigns',
    'import',
  ];
  roadmap = (clientId: string) => ['/clients', clientId, 'roadmaps'];
  createRoadmap = (clientId: string) => [
    '/clients',
    clientId,
    'roadmaps',
    'create',
  ];
  viewRoadmap = (clientId: string, roadmapId: string, variationId?: string) =>
    ['/clients', clientId, 'roadmaps', roadmapId].concat(
      !!variationId ? ['variations', variationId] : [],
    );
  // viewRoadmap = (clientId: string, roadmapId: string, variationId?: string) => [
  //   '/clients',
  //   clientId,
  //   'roadmaps',
  //   roadmapId,
  //   'variations',
  //   variationId,
  // ];
  updateRoadmap = (clientId: string, roadmapId: string) => [
    '/clients',
    clientId,
    'roadmaps',
    roadmapId,
    'update',
  ];
  compareVariationsFromList = (clientId: string) => [
    '/clients',
    clientId,
    'roadmaps',
    'compare',
  ];
  compareVariations = (clientId: string, roadmapId: string) => [
    '/clients',
    clientId,
    'roadmaps',
    roadmapId,
    'compare',
  ];
  roadmapImport = (clientId: string, roadmapId: string) => [
    '/clients',
    clientId,
    'roadmaps',
    roadmapId,
    'import',
  ];
  savedVisualizations = (clientId: string, roadmapId: string) => [
    '/clients',
    clientId,
    'roadmaps',
    roadmapId,
    'savedVisualizations',
  ];
  viewRoadmapTimeline = (
    clientId: string,
    roadmapId: string,
    variationId: string,
  ) => [
    '/clients',
    clientId,
    'roadmaps',
    roadmapId,
    'variations',
    variationId,
    'timeline',
  ];
  viewRoadmapTargets = (
    clientId: string,
    roadmapId: string,
    variationId: string,
  ) => [
    '/clients',
    clientId,
    'roadmaps',
    roadmapId,
    'variations',
    variationId,
    'targets',
  ];
  viewRoadmapResults = (
    clientId: string,
    roadmapId: string,
    variationId: string,
    secondaryTab?: string,
  ) => [
    '/clients',
    clientId,
    'roadmaps',
    roadmapId,
    'variations',
    variationId,
    'analysis',
    secondaryTab,
  ];
  viewWorldmapResults = (
    clientId: string,
    roadmapId: string,
    variationId: string,
    secondaryTab?: string,
  ) => [
    '/clients',
    clientId,
    'roadmaps',
    roadmapId,
    'variations',
    variationId,
    'worldmap',
    secondaryTab,
  ];
  viewDetailedResults = (
    clientId: string,
    roadmapId: string,
    variationId: string,
  ) => [
    '/clients',
    clientId,
    'roadmaps',
    roadmapId,
    'variations',
    variationId,
    'details',
  ];
  companies = (clientId: string) => [
    '/clients',
    clientId,
    'entities',
    'companies',
  ];
  viewCompany = (clientId: string, companyId: string) => [
    '/clients',
    clientId,
    'entities',
    'companies',
    companyId,
  ];
  geographies = (clientId: string) => [
    '/clients',
    clientId,
    'entities',
    'geographies',
  ];
  viewGeography = (clientId: string, geoId: string) => [
    '/clients',
    clientId,
    'entities',
    'geographies',
    geoId,
  ];
  archetypes = (clientId: string) => [
    '/clients',
    clientId,
    'entities',
    'archetypes',
  ];
  sites = (clientId: string) => ['/clients', clientId, 'entities', 'sites'];
  sectors = (clientId: string) => ['/clients', clientId, 'entities', 'sectors'];
  viewSector = (clientId: string, sectorId: string) => [
    '/clients',
    clientId,
    'entities',
    'sectors',
    sectorId,
  ];
  fluids = (clientId: string) => ['/clients', clientId, 'entities', 'fluids'];
  viewFluid = (clientId: string, fluidId: string) => [
    '/clients',
    clientId,
    'entities',
    'fluids',
    fluidId,
  ];
  processes = (clientId: string) => [
    '/clients',
    clientId,
    'entities',
    'processes',
  ];
  scalingFactors = (clientId: string) => [
    '/clients',
    clientId,
    'entities',
    'scalingFactors',
  ];
  viewProcess = (clientId: string, processId: string) => [
    '/clients',
    clientId,
    'entities',
    'processes',
    processId,
  ];
  existingConverters = (clientId: string) => [
    '/clients',
    clientId,
    'entities',
    'existingConverters',
  ];
  existingRenewables = (clientId: string) => [
    '/clients',
    clientId,
    'entities',
    'existingRenewables',
  ];
  existingStorages = (clientId: string) => [
    '/clients',
    clientId,
    'entities',
    'existingStorages',
  ];
  existingContracts = (clientId: string) => [
    '/clients',
    clientId,
    'entities',
    'existingContracts',
  ];
  dataImport = (clientId: string) => [
    '/clients',
    clientId,
    'entities',
    'import',
  ];
}

import { CAMPAIGN_EXPORT_STATUS } from './store/export/campaign-export.model';

export const CAMPAIGN_EXPORT_MESSAGES = {
  [CAMPAIGN_EXPORT_STATUS.INITIALIZATION]:
    'Campaign.messages.exportingCampaigns.initialization',
  [CAMPAIGN_EXPORT_STATUS.PROCESSING_CAMPAIGNS]:
    'Campaign.messages.exportingCampaigns.processing',
  [CAMPAIGN_EXPORT_STATUS.EXCEL_GENERATION]:
    'Campaign.messages.exportingCampaigns.excelGenerate',
  [CAMPAIGN_EXPORT_STATUS.EXCEL_DOWNLOAD]:
    'Campaign.messages.exportingCampaigns.excelDownload',
  [CAMPAIGN_EXPORT_STATUS.FAILED]:
    'Campaign.messages.exportingCampaigns.exportFailed',
  [CAMPAIGN_EXPORT_STATUS.SUCCESS]:
    'Campaign.messages.exportingCampaigns.exportSuccess',
  [CAMPAIGN_EXPORT_STATUS.ERROR_CANCELLING]:
    'Campaign.messages.exportingCampaigns.exportCancelError',
  [CAMPAIGN_EXPORT_STATUS.FAILED_CANCELLING]:
    'Campaign.messages.exportingCampaigns.exportCancelFailed',
  [CAMPAIGN_EXPORT_STATUS.ONGOING_CANCELLING]:
    'Campaign.messages.exportingCampaigns.exportCancelOngoing',
  [CAMPAIGN_EXPORT_STATUS.CANCELLED]:
    'Campaign.messages.exportingCampaigns.exportCancelled',
};

export const CAMPAIGN_EXPORT_GOOD_STATUS = [
  CAMPAIGN_EXPORT_STATUS.ONGOING,
  CAMPAIGN_EXPORT_STATUS.SUCCESS,
];

export const CAMPAIGN_FINAL_STATUSES = [
  CAMPAIGN_EXPORT_STATUS.SUCCESS,
  CAMPAIGN_EXPORT_STATUS.FAILED,
  CAMPAIGN_EXPORT_STATUS.CANCELLED,
];

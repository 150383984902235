import { createAction, props } from '@ngrx/store';

import { TargetFormModel, TargetModel } from './ambition.model';

const getList = createAction(
  '[Ambition List Page] Get constraints',
  props<{ clientId: string }>(),
);

const getListSuccess = createAction(
  '[Ambition List Page] Get constraints success',
  props<{ clientId: string; data: TargetModel[]; message: string }>(),
);

const getListFailure = createAction(
  '[Ambition List Page] Get constraints failure',
  props<{ clientId: string; error: any; message: string }>(),
);

const updateTargets = createAction(
  '[Ambition List Page] Update Ambitions',
  props<{
    clientId: string;
    constraints: { constraintId: string; isTarget: boolean }[];
  }>(),
);

const updateTargetsSuccess = createAction(
  '[Ambition List Page] Update Ambitions success',
  props<{
    constraints: { constraintId: string; isTarget: boolean }[];
    message: string;
  }>(),
);

const updateTargetsFailure = createAction(
  '[Ambition List Page] Update Ambitions failure',
  props<{
    clientId: string;
    constraints: { constraintId: string; isTarget: boolean }[];
    message: string;
    error: any;
  }>(),
);

const createCustomTarget = createAction(
  '[Target Create API] Create Target',
  props<{ clientId: string; data: TargetFormModel }>(),
);

const createCustomTargetSuccess = createAction(
  '[Target Create API] Create Target Success',
  props<{ data: any; message: string }>(),
);

const createCustomTargetFailure = createAction(
  '[Target Create API] Create Target Failure',
  props<{
    clientId: string;
    data: TargetFormModel;
    error: any;
    message: string;
  }>(),
);

const editCustomTarget = createAction(
  '[Target Edit API] Edit Target',
  props<{ clientId: string; data: TargetFormModel }>(),
);

const editCustomTargetSuccess = createAction(
  '[Target Edit API] Edit Target Success',
  props<{ data: any; message: string }>(),
);

const editCustomTargetFailure = createAction(
  '[Target Edit API] Edit Target Failure',
  props<{
    clientId: string;
    data: TargetFormModel;
    error: any;
    message: string;
  }>(),
);

const deleteCustomTarget = createAction(
  '[Target Delete API] Delete Target',
  props<{ clientId: string; constraintId: string }>(),
);

const deleteCustomTargetSuccess = createAction(
  '[Target Delete API] Delete Target Success',
  props<{ constraintId: string; message: string }>(),
);

const deleteCustomTargetFailure = createAction(
  '[Target Delete API] Delete Target Failure',
  props<{
    clientId: string;
    constraintId: string;
    error: any;
    message: string;
  }>(),
);

const clearList = createAction('Clear Target List');

export const AmbitionActions = {
  clearList,
  getList,
  getListSuccess,
  getListFailure,
  updateTargets,
  updateTargetsSuccess,
  updateTargetsFailure,
  createCustomTarget,
  createCustomTargetSuccess,
  createCustomTargetFailure,
  editCustomTarget,
  editCustomTargetSuccess,
  editCustomTargetFailure,
  deleteCustomTarget,
  deleteCustomTargetSuccess,
  deleteCustomTargetFailure,
};

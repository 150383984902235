<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="3%">
  <form
    fxLayout="column"
    [formGroup]="importForm"
    (ngSubmit)="onUpload()"
    fxLayoutAlign="center center"
    class="full-width"
    fxLayoutGap="1%"
  >
    <div
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="2%"
      class="full-width"
    >
      <div class="template-button" fxLayout="row" fxLayoutAlign="start center">
        <a
          data-test="downloadTemplate"
          data-testid="download-template-button"
          class="sso-clickable custom-template"
          (click)="downloadTemplate()"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="2px"
        >
          <mat-icon matPrefix>description</mat-icon>
          <b>Download Template v{{ templateVersion$ | async }}</b>
        </a>
      </div>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>{{ 'Campaign.labels.inputFile' | translate }}</mat-label>
        <sso-file-upload
          [required]="true"
          [placeholder]="'Select file for import'"
          [formControl]="importForm?.controls?.file"
          [acceptedExtensions]="allowedFileExtns"
        >
        </sso-file-upload>
        <mat-icon matSuffix>attach_file</mat-icon>
        <mat-error
          *ngIf="importForm?.controls?.file?.errors?.required"
          [innerHtml]="'Upload.messages.inputFile.required' | translate"
        >
        </mat-error>
      </mat-form-field>
    </div>
    <div
      *ngIf="showSelectTimelineType"
      class="timeline-type"
      fxLayout="row"
      fxLayoutAlign="space-between stretch"
    >
      <p class="timeline-type-label">
        {{ 'Client.labels.dataImport.selectBauTimelineType' | translate }}
      </p>
      <mat-radio-group
        formControlName="clientTimelineType"
        fxLayout="row"
        fxLayoutAlign="stretch stretch"
        fxLayoutGap="24px"
      >
        <mat-radio-button
          *ngFor="let type of timelineTypes"
          [value]="type"
          color="primary"
        >
          {{ 'Roadmap.labels.timelineType.' + type | translate }}
        </mat-radio-button>
      </mat-radio-group>
      <div
        *ngIf="importForm?.controls?.file?.bauTimelineType?.required"
        class="timelinetype-error"
      >
        <mat-error
          [innerHtml]="
            'Roadmap.messages.errors.timelineType.required' | translate
          "
        ></mat-error>
      </div>
    </div>
    <sso-input
      class="full-width"
      [placeholder]="'Generic.labels.comment' | translate"
      [label]="'Generic.labels.comment' | translate"
      [formControl]="importForm?.controls?.description"
      [isTextArea]="true"
      [tooltip]="
        'campaigns_import.campaigns_import_create_description' | translate
      "
    ></sso-input>

    <div
      class="full-width"
      fxLayout="row"
      fxLayoutGap="3%"
      fxFlexAlign="center center"
    >
      <button
        data-testid="client-upload-button"
        mat-flat-button
        type="submit"
        color="primary"
        [disabled]="
          this.importForm.pristine ||
          importForm?.invalid ||
          (uploading$ | async) ||
          (hasOngoingImport$ | async)
        "
      >
        {{ 'Generic.labels.upload' | translate }}
      </button>
    </div>
  </form>
</div>

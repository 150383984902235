<div fxLayout="row" fxLayoutAlign="start center" class="header">
  <sso-logo
    (click)="goBack()"
    expandedLogo="assets/logos/logo-ellipse.svg"
    class="logo-pointer"
  ></sso-logo>
</div>
<div
  class="container"
  fxLayout="column"
  fxLayoutAlign="start center"
  fxLayoutGap="20px"
>
  <mat-icon class="sad-icon" color="primary">sentiment_dissatisfied</mat-icon>
  <h1 class="status-code" color="primary" data-testid="status-code">
    {{ (statusCode$ | async) + ' Error' }}
  </h1>
  <span class="error-message" data-testid="error-message">{{
    errorMessage$ | async
  }}</span>
  <button
    mat-flat-button
    color="primary"
    (click)="goBack()"
    data-testid="go-back"
  >
    {{ 'Generic.labels.goHome' | translate }}
  </button>
</div>

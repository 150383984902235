<div class="main-container" fxLayout="column">
  <div
    *ngIf="!(hasData$ | ngrxPush)"
    class="graph-no-data-container"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    {{ 'Generic.messages.noResultsFound' | translate }}
  </div>
  <div id="canvas-container">
    <canvas
      id="stackedBar"
      data-testid="stackedBar"
      width="1000"
      height="400"
    ></canvas>
  </div>
  <mat-slide-toggle
    class="zero-toggle"
    color="primary"
    (click)="$event.stopPropagation()"
    (change)="toggleHideZeroValues()"
    [checked]="hideZeroValues$ | async"
    [hidden]="hideToggle"
  >
    {{ 'Generic.labels.toggleHideZeroValues' | translate }}
  </mat-slide-toggle>
  <div
    fxLayout="row"
    fxLayoutAlign="center"
    fxLayoutGap="5px"
    id="stackedbar-controls"
  >
    <div id="stackedbar-legend" class="chart-legend" fxFlex></div>
  </div>
</div>

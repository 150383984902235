import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ViewDataSetComponent } from './view-dataset.component';

@NgModule({
  declarations: [ViewDataSetComponent],
  imports: [CommonModule, FlexLayoutModule],
  exports: [ViewDataSetComponent],
})
export class ViewDataSetModule {}

import { BehaviorSubject } from 'rxjs';

import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';

import { TabDetail, TabSelectionType } from './tab-group.model';

@Component({
  selector: 'sso-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: TabGroupComponent, multi: true },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class TabGroupComponent implements OnInit, ControlValueAccessor {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  selected = new FormControl(0);

  private isObjectTabs = new BehaviorSubject<boolean>(false);
  isObjectTabs$ = this.isObjectTabs.asObservable();

  private isAlignmentColumn = new BehaviorSubject<boolean>(false);
  isAlignmentColumn$ = this.isAlignmentColumn.asObservable();

  // TODO: Create an input for tab group config
  private _selections = new BehaviorSubject<TabSelectionType>([]);
  @Input() set selections(selections: TabSelectionType) {
    if (selections?.length) {
      this.isObjectTabs.next(typeof selections[0] === 'object');
      this.isAlignmentColumn.next(
        typeof selections[0] === 'object' &&
          selections[0].alignment === 'column',
      );
    }
    this._selections.next(selections);
  }
  get selections() {
    return this._selections.getValue();
  }

  @Input() color: string = '';
  _noActive = new BehaviorSubject<boolean>(false);
  @Input() set noActive(noActive: boolean) {
    this._noActive.next(noActive);
    if (noActive) {
      this.selected.patchValue(0, { emitEvent: false });
    }
  }
  get noActive() {
    return this._noActive.getValue();
  }

  _disable = new BehaviorSubject<boolean>(false);
  @Input() set disable(disabled: boolean) {
    this._disable.next(disabled);
    if (disabled) {
      this.selected.patchValue(0, { emitEvent: false });
    }
  }
  get disable() {
    return this._disable.getValue();
  }

  @Input() stretchTabs = true;

  private onChange = (value: any) => {};

  constructor() {}

  ngOnInit(): void {
    this.selected.valueChanges.subscribe((value) => this.onChange(value));
  }

  /**
   * Function to call if there is a change in the tab selection.
   * @param index tab position.
   */
  onChangeSelectedTab(event: MatTabChangeEvent) {
    let option = this.selections.at(event.index);
    if (this.isTabDetail(option)) {
      if ((option as TabDetail).disabled) {
        return;
      }
    }

    this.selected.setValue(event.index);
  }

  private isTabDetail(tab: string | TabDetail): boolean {
    return typeof tab !== 'string';
  }

  writeValue(index: number): void {
    this.selected.patchValue(index, { emitEvent: false });
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {}
}

import { YearlyValues } from 'ssotool-app/shared/helpers/time-dependent';

export type ProfileDetailsForm = Readonly<{
  siteId: string;
  companyId: string;
  site: string;
  values?: YearlyValues;
  hasValues?: boolean;
  editedValues?: string[];
  unit?: string;
  granularity: string;
  tickformat: string;
  status: ProfileStatus;
  isNew?: boolean;
  campaignName?: string;
  startYear?: number;
  endYear?: number;
}>;

export enum ProfileStatus {
  PRISTINE = '',
  MODIFIED = 'modified',
  DELETED = 'deleted',
}

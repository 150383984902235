import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  campaignImportAdapter,
  campaignImportFeatureKey,
  CampaignImportState,
} from './campaign-import.reducer';

const { selectIds, selectEntities, selectAll, selectTotal } =
  campaignImportAdapter.getSelectors();

export const campaignImportFeatureState =
  createFeatureSelector<CampaignImportState>(campaignImportFeatureKey);

export const campaignImportEntities = createSelector(
  campaignImportFeatureState,
  selectEntities,
);

export const campaignImportList = createSelector(
  campaignImportFeatureState,
  selectAll,
);

export const loading = createSelector(
  campaignImportFeatureState,
  (state) => state.loading,
);

export const loaded = createSelector(
  campaignImportFeatureState,
  (state) => state.loaded,
);
// custom selectors
export const downloadingTemplate = createSelector(
  campaignImportFeatureState,
  (state) => state.downloadingTemplate,
);

export const importLoading = (importId: string) =>
  createSelector(
    campaignImportEntities,
    (entities) => entities?.[importId]?.loading,
  );

export const importLogsLoading = (importId: string) =>
  createSelector(
    campaignImportEntities,
    (entities) => entities?.[importId]?.logsLoading,
  );

export const logsLoaded = (importId: string) =>
  createSelector(
    campaignImportEntities,
    (entities) => entities?.[importId]?.logsLoaded,
  );

export const importDownloading = (importId: string) =>
  createSelector(
    campaignImportEntities,
    (entities) => entities?.[importId]?.downloading,
  );

export const getLogs = (importId: string) => {
  return createSelector(
    campaignImportEntities,
    (dataList) => dataList?.[importId]?.messages,
  );
};

export const getCampaignImportList = (clientId: string) =>
  createSelector(campaignImportList, (campaignList) =>
    campaignList.filter((campaign) => campaign.clientId === clientId),
  );

export const importing = createSelector(
  campaignImportFeatureState,
  (state) => state.importing,
);

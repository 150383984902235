import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to strip or remove a certain string from the source value.
 */
@Pipe({
  name: 'strip',
})
export class StripPipe implements PipeTransform {
  /**
   * Removes a certain string from the value.
   * @param value - the source string to strip from
   * @param strip - the string to remove
   * @returns the stripped string
   */
  transform(value: string, strip: string | RegExp): string {
    return value?.replace(strip, '');
  }
}

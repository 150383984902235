import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientFacadeService } from 'ssotool-app/+client';

import { BinDataFacadeService } from 'ssotool-app/+entities/stores/bindata/bindata-facade.service';
import { BindataBarChartBaseComponent } from '../../bindata';
import { FlowPricesBarChartDialogModel } from './flow-prices-bar-chart-dialog.model';

@Component({
  selector: 'sso-flow-prices-bar-chart-dialog',
  templateUrl: './flow-prices-bar-chart-dialog.component.html',
  styleUrls: ['./flow-prices-bar-chart-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowPricesBarChartDialogComponent extends BindataBarChartBaseComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FlowPricesBarChartDialogModel,
    public dialogRef: MatDialogRef<FlowPricesBarChartDialogModel>,
    public binDataFacade: BinDataFacadeService,
    public clientFacade: ClientFacadeService,
  ) {
    super(data, dialogRef, binDataFacade, clientFacade);
  }
}

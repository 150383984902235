import { filter, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';

import { CampaignActions } from './campaign.action';
import { SelectedCampaigns } from './campaign.model';
import { CampaignState } from './campaign.reducer';
import {
  campaignList,
  campaignListEntities,
  campaignLoaded,
  campaignLoading,
  clientCampaigns,
  fetched,
  list,
  loading,
  selectCampaign,
} from './campaign.selector';

@Injectable()
export class CampaignFacadeService {
  constructor(
    private store: Store<CampaignState>,
    private actionSubject$: ActionsSubject,
  ) {}

  getCampaignEntities$ = this.store.pipe(select(campaignListEntities));
  getCampaignList$ = this.store.pipe(select(campaignList));
  getCampaignList = select(list);
  loading$ = this.store.pipe(select(loading));
  fetched$ = this.store.pipe(select(fetched));
  getClientCampaigns$ = this.store.pipe(select(clientCampaigns));
  selectCampaign$ = (id) => this.store.pipe(select(selectCampaign, { id }));
  campaignLoading$ = (id) => this.store.pipe(select(campaignLoading, { id }));
  campaignLoaded$ = (id) => this.store.pipe(select(campaignLoaded, { id }));

  // TODO: Update model
  create(clientId: string, data: any) {
    this.store.dispatch(CampaignActions.create({ clientId, data }));
    return this.actionSubject$.pipe(
      filter((action) => action.type === CampaignActions.createSuccess.type),
      map((action) => action?.['data']),
    );
  }

  // TOD: Update model
  update(clientId: string, id: string, data: any) {
    this.store.dispatch(CampaignActions.update({ clientId, id, data }));
    return this.actionSubject$.pipe(
      filter((action) => action.type === CampaignActions.updateSuccess.type),
    );
  }

  get(clientId: string, id: string, campaignType: string) {
    this.store.dispatch(CampaignActions.get({ clientId, id, campaignType }));
    return this.actionSubject$.pipe(
      filter((action) => action.type === CampaignActions.getSuccess.type),
    );
  }

  getList(clientId: string) {
    this.store.dispatch(CampaignActions.getList({ clientId }));
  }

  clearList() {
    this.store.dispatch(CampaignActions.clearList());
  }

  delete(clientId: string, id: string, campaignType: string) {
    this.store.dispatch(
      CampaignActions.deleteCampaign({ clientId, id, campaignType }),
    );
  }

  deleteMultiple(clientId: string, campaigns: SelectedCampaigns) {
    this.store.dispatch(
      CampaignActions.deleteCampaigns({ clientId, campaigns }),
    );
  }

  batchDuplicate(clientId: string, campaigns: SelectedCampaigns) {
    this.store.dispatch(
      CampaignActions.batchDuplicateCampaigns({ clientId, campaigns }),
    );
  }

  populateWithLibrary(clientId: string) {
    this.store.dispatch(CampaignActions.populateWithLibrary({ clientId }));
  }

  resetIndividualCampaignLoadedState() {
    this.store.dispatch(CampaignActions.resetIndividualCampaignLoadedState());
  }
}

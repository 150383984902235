import { PipesModule } from 'ssotool-app/shared/pipes';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { InputModule } from '../input';
import { MultiSelectComponent } from './multi-select.component';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MatSelectModule,
  MatFormFieldModule,
  InputModule,
  FlexLayoutModule,
  MatButtonModule,
  MatIconModule,
  MatTooltipModule,
  PipesModule,
  ScrollingModule,
];

@NgModule({
  declarations: [MultiSelectComponent],
  imports: [...modules],
  exports: [MultiSelectComponent],
})
export class MultiSelectModule {}

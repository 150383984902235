import { first } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { AmbitionFacadeService } from '../store';

@Injectable()
export class AmbitionResolverService implements Resolve<any> {
  constructor(
    private clientFacade: ClientFacadeService,
    private ambitionFacade: AmbitionFacadeService,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    this.clientFacade.activeClientId$.pipe().subscribe((clientId) => {
      if (clientId) {
        this.ambitionFacade.loaded$.pipe(first()).subscribe((isLoaded) => {
          /* istanbul ignore else */
          if (!isLoaded) {
            this.ambitionFacade.getList(clientId);
          }
        });
      }
    });
  }
}

import { EntityCommonTableModule } from 'ssotool-app/+entities/components/entity-common-table';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ExistingConverterListComponent } from './existing-converter-list.component';
import { ExistingConverterDrawerModule } from 'ssotool-app/+entities/components/drawer';

@NgModule({
  declarations: [ExistingConverterListComponent],
  imports: [
    CommonModule,
    EntityCommonTableModule,
    ExistingConverterDrawerModule,
  ],
  exports: [ExistingConverterListComponent],
})
export class ExistingConverterListModule {}

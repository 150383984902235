import { CompareVariationTabDetail } from './compare-variations.model';

export enum CompareVariationMainTabs {
  ANALYSIS_TAB = 'Portfolio Analysis',
  DETAILED_TAB = 'Detailed View',
}

export enum CompareVariationAnalysisKpiTabs {
  FINANCIALS = 'financials',
  SUSTAINABILITY = 'sustainability',
  OPERATIONS = 'operations',
}

export const COMPARE_VARIATION_MAIN_TABS: CompareVariationTabDetail[] = [
  {
    label: CompareVariationMainTabs.ANALYSIS_TAB,
    alignment: 'row',
    mode: 'analysis',
    secondaryTabs: [
      // {
      //   label: CompareVariationAnalysisKpiTabs.FINANCIALS,
      //   alignment: 'row',
      //   mode: CompareVariationAnalysisKpiTabs.FINANCIALS,
      //   svgIcon: 'financials',
      // },
      // {
      //   label: CompareVariationAnalysisKpiTabs.SUSTAINABILITY,
      //   alignment: 'row',
      //   mode: CompareVariationAnalysisKpiTabs.SUSTAINABILITY,
      //   svgIcon: 'sustainability',
      // },
      // {
      //   label: CompareVariationAnalysisKpiTabs.OPERATIONS,
      //   alignment: 'row',
      //   mode: CompareVariationAnalysisKpiTabs.OPERATIONS,
      //   svgIcon: 'operations',
      // },
    ],
  },
  {
    label: CompareVariationMainTabs.DETAILED_TAB,
    alignment: 'row',
    mode: 'detailed',
  },
];

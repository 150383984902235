import { createAction, props } from '@ngrx/store';

const setColorForLabelGrouped = () =>
  createAction(
    'Set Color for Label Grouped',
    props<{ data: { label: string; hexCode: string }[] }>(),
  );

const setColorForLabel = () =>
  createAction('Set Color for Label', props<{ label: string; hexValue }>());

const resetColorMap = () => createAction('Reset Color for Label');

export const ChartActions = () => ({
  setColorForLabelGrouped: setColorForLabelGrouped(),
  setColorForLabel: setColorForLabel(),
  resetColorMap: resetColorMap(),
});

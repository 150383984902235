import { Coerce, hasMultipleValues } from 'ssotool-shared/helpers';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

interface YearlyValues {
  [year: string]: string;
}

interface YearlyValuesSummarized {
  [year: string]: {
    end: string;
    value: string;
  };
}

interface YearlyMap {
  startYear: string;
  endYear: string;
  value: string;
}

@Component({
  selector: 'sso-yearly-view',
  templateUrl: './yearly-view.component.html',
  styleUrls: ['./yearly-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearlyViewComponent {
  tableData: YearlyMap[];
  displayedColumns: string[] = ['startYear', 'endYear', 'value'];
  /**
   * The dictionary of number values where the keys represent unique years
   */
  @Input() set yearlyValuesMap(value: YearlyValues) {
    this.tableData = this.mapToTableData(value);
  }

  mapToTableData(yearlyValues: YearlyValues): YearlyMap[] {
    const values = [];
    if (!hasMultipleValues(yearlyValues)) {
      return [
        {
          startYear: Coerce.getObjKeys(yearlyValues)[0],
          endYear: Coerce.getObjKeys(yearlyValues).pop(),
          value: yearlyValues[Coerce.getObjKeys(yearlyValues)[0]],
        },
      ];
    }

    Object.entries(this.summarizeYearlyRange(yearlyValues)).forEach(
      ([key, v]) => {
        values.push({
          startYear: key,
          endYear: v.end,
          value: v.value,
        });
      },
    );
    return values;
  }

  /**
   * This function recreates a model for the yearly range table
   * @param yearlyValues - year: value dictionary
   * the sample return value:
   *  (e.g. summary: 2010: {end: 2014, value: "3"} )
   */
  summarizeYearlyRange(yearlyValues: YearlyValues): YearlyValuesSummarized {
    const original = {};
    if (yearlyValues) {
      let previous: string;
      let prevYear: string;
      Coerce.getObjKeys(yearlyValues)
        .sort()
        .map((year) => {
          if (yearlyValues[year] === previous) {
            // it means it is in the same range so update end year
            original[prevYear] = { ...original[prevYear], end: year };
          } else {
            original[year] = {
              end: year,
              value: yearlyValues[year],
            };
            prevYear = year;
          }
          previous = yearlyValues[year];
        });
    }
    return original;
  }
}

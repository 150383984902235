import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'sso-navigatable-client',
  templateUrl: './navigatable-client.component.html',
  styleUrls: ['./navigatable-client.component.scss'],
})
export class NavigatableClientComponent {
  @Input() clientName: string;

  constructor() {}
}

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ReleaseNotesResolver } from './services/release-notes.resolver';
import { ReleaseNotesService } from './services/release-notes.service';
import { ReleaseNotesEffects } from './store/release-notes.effect';
import { ReleaseNotesFacadeService } from './store/release-notes.facade.service';
import {
  releaseNoteFeatureKey,
  ReleaseNotesReducer,
} from './store/release-notes.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([ReleaseNotesEffects]),
    StoreModule.forFeature(releaseNoteFeatureKey, ReleaseNotesReducer),
  ],
  providers: [
    ReleaseNotesService,
    ReleaseNotesFacadeService,
    ReleaseNotesResolver,
  ],
})
export class ReleaseNotesRootModule {}

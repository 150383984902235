import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, mergeMap } from 'rxjs/operators';
import { Client } from 'ssotool-app/+client/store';
import { ClientFacadeService } from 'ssotool-app/+client/store/client.facade.service';
import { SSOToolRoutePathService } from 'ssotool-app/shared';

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HotToastRef } from '@ngneat/hot-toast';

import { ClientToastMessageModel } from './client-toast-message.model';

@Component({
  selector: 'sso-client-toast-message',
  templateUrl: './client-toast-message.component.html',
  styleUrls: ['./client-toast-message.component.scss'],
})
export class ClientToastMessageComponent implements OnInit {
  data$ = new BehaviorSubject<ClientToastMessageModel>(null);
  isSuccess$ = new BehaviorSubject<boolean>(false);
  private dupClient$: Observable<Client> = this.data$.pipe(
    map((data) => data?.clientData || {}),
    mergeMap((clientData) =>
      this.clientFacade
        .selectClient$(clientData?.dupClientId)
        .pipe(filter((client) => !!client)),
    ),
  );

  clientName$ = this.dupClient$.pipe(map((client) => client?.name));

  get data() {
    return this.data$.value;
  }
  get clientData() {
    return this.data$.value.clientData;
  }

  constructor(
    @Optional()
    @Inject(HotToastRef)
    public toastRef: HotToastRef<ClientToastMessageModel>,
    private clientFacade: ClientFacadeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private pathService: SSOToolRoutePathService,
  ) {}

  ngOnInit(): void {
    this.data$.next(this.toastRef.data);
    this.isSuccess$.next(this.toastRef.getToast().type === 'success');
  }

  getRedirectFn(
    hasSuccessfulImport: boolean = false,
  ): (clientId: string) => string[] {
    if (!!hasSuccessfulImport) {
      return this.pathService.roadmap;
    }
    return this.pathService.clientImport;
  }

  onAction() {
    switch (this.data.mode) {
      case 'create':
        this.clientFacade.create(
          this.clientData.name,
          this.clientData.description,
        );
        break;
      case 'update':
        this.clientFacade.update(
          this.clientData.clientId,
          this.clientData.name,
          this.clientData.description,
        );
        break;
      case 'getClientDataInfo':
        this.clientFacade.getClientDataInfo(this.clientData.clientId);
        break;
      case 'share':
        this.clientFacade.share(
          this.clientData.clientId,
          this.clientData.shareInfo,
        );
        break;
      case 'delete':
        this.clientFacade.delete(this.clientData.clientId);
        break;
      case 'archive':
        this.clientFacade.setClientStatus(
          this.clientData.clientId,
          this.clientData.mode,
        );
        break;
      case 'duplicate':
        this.clientFacade.duplicateClient(this.clientData.dupClientId);
        break;
      case 'redirect':
        this.dupClient$.pipe(first()).subscribe((client) => {
          const copyName = `Copy of ${client.name}`;
          const currentUrl = this.router.url;
          if (currentUrl === '/clients') {
            this.clientFacade.quickSwitch(
              this.clientData.clientId,
              client.hasSuccessfulImport,
              copyName,
            );
          } else {
            this.router.navigate(this.pathService.clients(), {
              relativeTo: this.activatedRoute,
              queryParams: {
                quickSwitchId: this.clientData.clientId,
                quickSwitchImport: client.hasSuccessfulImport,
                quickSwitchName: copyName,
              },
            });
          }
        });
        break;
    }
    this.toastRef.close({ dismissedByAction: true });
  }
}

<div class="sso-page">
  <div fxLayout="column" fxLayoutGap="2%">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <h2>{{ 'DataManagement.labels.dataManagement' | translate }}</h2>
        <span class="page-description sso-text-gray">{{
          'DataManagement.labels.description' | translate
        }}</span>
      </div>
      <div>
        <button
          data-testid="tools-button"
          type="button"
          color="primary"
          mat-button
          class="tools-button"
          (click)="openToolsDrawer()"
        >
          <mat-icon matPrefix>settings</mat-icon>
          <b>{{ 'DataManagement.labels.tools' | translate }}</b>
        </button>
      </div>
    </div>
    <div
      *ngIf="isReadOnly$ | async"
      fxLayoutAlign="start center"
      fxLayoutGap="1%"
    >
      <mat-icon class="warning">warning_amber</mat-icon>
      <span>
        {{ 'DataManagement.messages.warnings.readonlyAccess' | translate }}
      </span>
    </div>
    <div fxLayout="row" fxLayoutAlign="center  center" fxLayoutGap="2%">
      <div fxFlex="50%" *ngIf="isAdmin$ | async">
        <div
          *ngIf="uploading$ | async; else uploaded"
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
        >
          <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
          <span>
            {{ 'DataManagement.messages.uploadingData' | translate }}
          </span>
        </div>
      </div>
      <ng-template #uploaded>
        <sso-import-form
          [showSelectTimelineType]="(hasSuccessfulImport$ | async) === false"
        ></sso-import-form>
      </ng-template>

      <sso-import-list data-testid="import-list"></sso-import-list>
    </div>
  </div>
</div>

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { CampaignImportActions } from './campaign-import.action';
import { CampaignImportModel } from './campaign-import.model';

export const campaignImportFeatureKey = 'CampaignImport';

// TODO: Update model
export interface CampaignImportState
  extends EntityState<Partial<CampaignImportModel>> {
  loading: boolean;
  loaded: boolean;
  error: string;
  message: string;
  downloading?: boolean;
  downloadingTemplate?: boolean;
  importing?: boolean;
}

export const selectCampaignImportId = (campaignImport: CampaignImportModel) =>
  campaignImport.importId;

export const sortDate = (
  importData1: CampaignImportModel,
  importData2: CampaignImportModel,
) => {
  const date1 = new Date(importData1.createdAt);
  const date2 = new Date(importData2.createdAt);
  return date1.getTime() - date2.getTime();
};

export const campaignImportAdapter: EntityAdapter<
  Partial<CampaignImportModel>
> = createEntityAdapter<CampaignImportModel>({
  selectId: selectCampaignImportId,
  sortComparer: sortDate,
});

export const initialCampaignImportState: CampaignImportState =
  campaignImportAdapter.getInitialState({
    loading: false,
    loaded: false,
    downloadingTemplate: false,
    error: undefined,
    message: undefined,
    importing: undefined,
  });

const campaignImportReducer = createReducer(
  initialCampaignImportState,

  // Get Import
  on(CampaignImportActions.get, (state) => {
    return { ...state, loading: true };
  }),
  on(CampaignImportActions.getSuccess, (state, { data, message }) => {
    return campaignImportAdapter.upsertOne(
      { ...data, loaded: true, loading: false },
      { ...state, message, loading: false },
    );
  }),
  on(CampaignImportActions.getFailure, (state, { error, message }) => {
    return { ...state, error, loading: false, message };
  }),

  // Get List of imports
  on(CampaignImportActions.getList, (state) => {
    return { ...state, loading: true };
  }),
  on(CampaignImportActions.getListSuccess, (state, { data, message }) => {
    const updateData = data.data.map(
      (d) => ({ ...d, loaded: true } as CampaignImportModel),
    );
    return campaignImportAdapter.upsertMany(updateData, {
      ...state,
      message,
      loading: false,
      loaded: true,
    });
  }),
  on(CampaignImportActions.getListFailure, (state, { error, message }) => {
    return { ...state, loading: false, loaded: false, error, message };
  }),

  // Update import status
  on(
    CampaignImportActions.updateImportStatus,
    (state, { clientId, importId, status }) => {
      return campaignImportAdapter.updateOne(
        { id: importId, changes: { status } },
        { ...state, importing: false },
      );
    },
  ),
  on(
    CampaignImportActions.updateImportLogDetails,
    (state, { importId, logDetails }) => {
      return campaignImportAdapter.upsertOne(
        { importId, logDetails },
        { ...state },
      );
    },
  ),

  // Import campaign
  on(CampaignImportActions.importCampaign, (state) => ({
    ...state,
    loading: true,
    importing: true,
  })),
  on(CampaignImportActions.importCampaignSuccess, (state, { data, message }) =>
    campaignImportAdapter.upsertOne(
      { ...data, loaded: true },
      { ...state, loading: false, message, importing: false },
    ),
  ),
  on(
    CampaignImportActions.importCampaignFailure,
    (state, { error, message }) => ({
      ...state,
      loading: false,
      error,
      message,
      importing: false,
    }),
  ),

  // Download imported excel
  on(
    CampaignImportActions.downloadCampaignImportData,
    (state, { importId }) => {
      return campaignImportAdapter.upsertOne(
        { importId, loading: true },
        state,
      );
    },
  ),
  on(
    CampaignImportActions.downloadCampaignImportDataSuccess,
    (state, { message, data }) => {
      return campaignImportAdapter.upsertOne(
        { importId: data.importId, loading: false },
        { ...state, message },
      );
    },
  ),
  on(
    CampaignImportActions.downloadCampaignImportDataFailure,
    (state, { error, message, data }) => {
      return campaignImportAdapter.upsertOne(
        { importId: data.importId, loading: false },
        { ...state, message, error },
      );
    },
  ),

  // Download template
  on(CampaignImportActions.downloadTemplate, (state) => ({
    ...state,
    downloadingTemplate: true,
  })),
  on(CampaignImportActions.downloadTemplateSuccess, (state, { message }) => ({
    ...state,
    downloadingTemplate: false,
    message,
  })),
  on(
    CampaignImportActions.downloadTemplateFailure,
    (state, { error, message }) => ({
      ...state,
      error,
      message,
      downloadingTemplate: false,
    }),
  ),

  // Download Error Logs
  on(CampaignImportActions.downloadErrorLogs, (state, { importId }) => {
    return campaignImportAdapter.upsertOne(
      { importId, logsLoading: true },
      state,
    );
  }),
  on(
    CampaignImportActions.downloadErrorLogsSuccess,
    (state, { importId, data, message }) => {
      return campaignImportAdapter.upsertOne(
        { importId, messages: data, logsLoading: false },
        { ...state, message },
      );
    },
  ),
  on(
    CampaignImportActions.downloadErrorLogsFailure,
    (state, { error, message, data }) => {
      return campaignImportAdapter.upsertOne(
        { importId: data.importId, logsLoading: false },
        { ...state, error, message },
      );
    },
  ),

  // Delete import
  on(CampaignImportActions.deleteImport, (state, { importId }) => {
    return campaignImportAdapter.upsertOne({ importId, loading: true }, state);
  }),
  on(
    CampaignImportActions.deleteImportSuccess,
    (state, { importId, message }) => {
      return campaignImportAdapter.removeOne(importId, {
        ...state,
        loading: false,
        message: message,
      });
    },
  ),
  on(
    CampaignImportActions.deleteImportFailure,
    (state, { message, error, data }) => {
      return campaignImportAdapter.upsertOne(
        { importId: data.importId, loading: false },
        { ...state, message, error },
      );
    },
  ),
);

export function CampaignImportReducer(
  state: CampaignImportState | undefined,
  action: Action,
) {
  return campaignImportReducer(state, action);
}

import { BaseComponent } from 'ssotool-shared/component/base';

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Coerce, convertToYearlyValues } from '../../helpers';
import { YearlyInputDialogModel } from './yearly-input-dialog.model';

@Component({
  selector: 'sso-yearly-input-dialog',
  templateUrl: './yearly-input-dialog.component.html',
  styleUrls: ['./yearly-input-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearlyInputDialogComponent
  extends BaseComponent
  implements OnInit
{
  startYear: number;
  endYear: number;
  yearlyChartDialogForm = this.formBuilder.group({
    rangeValue: [0, Validators.required],
  });

  get rangeValueForm() {
    return this.yearlyChartDialogForm.controls.rangeValue;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: YearlyInputDialogModel,
    public dialogRef: MatDialogRef<YearlyInputDialogModel>,
    public formBuilder: FormBuilder,
  ) {
    super();
  }

  ngOnInit() {
    /* istanbul ignore else */
    if (this.data) {
      this.rangeValueForm.patchValue(
        this.data.yearlyValues
          ? this.data.yearlyValues
          : convertToYearlyValues(null, this.data.startYear, this.data.endYear),
      );
      this.startYear = this.data.startYear;
      this.endYear = this.data.endYear;
    }
  }

  onConfirm() {
    /* istanbul ignore else */
    if (this.yearlyChartDialogForm.valid) {
      this.dialogRef.close(this.rangeValueForm.value);
    }
  }

  isDisabled(): boolean {
    const arr = Coerce.getObjValues(this.rangeValueForm.value);
    return arr.includes(null) || this.yearlyChartDialogForm.pristine;
  }

  onClose = (): void => {
    this.dialogRef.close();
  };
}

export const loaderSizes = {
  x2Small: {
    diameter: 25,
    strokeWidth: 4,
    fontSize: 'small',
    insideText: false,
  },
  xSmall: {
    diameter: 50,
    strokeWidth: 8,
    fontSize: 'small',
    insideText: false,
  },
  small: {
    diameter: 150,
    strokeWidth: 15,
    fontSize: 'small',
    insideText: true,
  },
  medium: {
    diameter: 200,
    strokeWidth: 25,
    fontSize: 'large',
    insideText: true,
  },
  large: {
    diameter: 300,
    strokeWidth: 35,
    fontSize: 'x-large',
    insideText: true,
  },
};

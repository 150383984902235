<div fxLayout="row" fxlayoutGap="30px" class="hov">
  <mat-icon
    fxFlex="15"
    matBadgeColor="accent"
    fontSet="material-icons-outlined"
    class="gray-icon"
    >folder_shared</mat-icon
  >
  <div fxFlex="65">
    <p>{{ clientName }}</p>
  </div>
  <div fxFlex class="change-icon" fxLayout="row" fxLayoutAlign="center center">
    <mat-icon
      fxFlex="20"
      matBadgeColor="accent"
      fontSet="material-icons-outlined"
      class="gray-icon"
      >compare_arrows</mat-icon
    >
  </div>
</div>

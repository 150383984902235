import { combineLatest, iif, of } from 'rxjs';
import { filter, first, map, mergeMap, startWith, tap } from 'rxjs/operators';
import { AmbitionFacadeService } from 'ssotool-app/+ambition/store';
import { ClientFacadeService } from 'ssotool-app/+client';
import { EXECUTION_STATUS } from 'ssotool-app/app.references';
import { Coerce } from 'ssotool-shared/helpers';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { ResultFacadeService } from '../stores/result/result-facade.service';
import { RoadmapVariationFacadeService } from '../stores/roadmap-variation/roadmap-variation.facade.service';
import { RoadmapFacadeService } from '../stores/roadmap/roadmap-facade.service';
import { PortfolioRoadmap } from '../stores/roadmap/roadmap.model';

@Injectable()
export class RoadmapResultResolverService implements Resolve<any> {
  constructor(
    private clientFacade: ClientFacadeService,
    private resultFacade: ResultFacadeService,
    private roadmapFacade: RoadmapFacadeService,
    private ambitionFacade: AmbitionFacadeService,
    private variationFacade: RoadmapVariationFacadeService,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const roadmapId = route.paramMap.get('roadmapId');

    this.clientFacade.selectActiveClientData$
      .pipe(
        filter((client) => !!client?.clientId),
        first(),
        map((client) => client.clientId),
        mergeMap((clientId) =>
          this.roadmapFacade.roadmap$(roadmapId).pipe(
            filter((roadmap) => !!roadmap),
            first(),
            mergeMap((roadmap) =>
              combineLatest([
                this.loadResults(clientId, roadmap),
                this.loadAmbitions(clientId),
              ]),
            ),
          ),
        ),
      )
      .subscribe();
  }

  private loadResults(clientId: string, roadmap: PortfolioRoadmap) {
    const roadmapId = roadmap.roadmapId;

    return this.variationFacade
      .variation$(roadmapId, Coerce.toObject(roadmap.runSettings).variationId)
      .pipe(
        filter((variation) => !!Coerce.toObject(variation).run),
        first(),
        mergeMap((variation) =>
          iif(
            () =>
              Coerce.toObject(variation.run.outputFile).name &&
              variation.beStatus === EXECUTION_STATUS.SUCCESS,
            combineLatest([
              this.resultFacade
                .getBaselineFetchedStream(roadmapId)
                .pipe(startWith(true), first()),
              this.resultFacade.getResultLoadedStream(roadmapId).pipe(first()),
            ]).pipe(
              tap(([baselineLoaded, resultLoaded]) => {
                const variationId = variation.variationId;

                // if (!baselineLoaded) {
                //   this.resultFacade.getBaselineIndicators(
                //     clientId,
                //     roadmapId,
                //   );
                // }

                if (!resultLoaded) {
                  this.resultFacade.get({
                    clientId,
                    roadmapId,
                    variationId,
                  });
                }
              }),
            ),
            of(true),
          ),
        ),
      );
  }

  private loadAmbitions(clientId: string) {
    return this.ambitionFacade.loaded$.pipe(
      first(),
      tap((loaded) => {
        if (!loaded) {
          this.ambitionFacade.getList(clientId);
        }
      }),
    );
  }
}

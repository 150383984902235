import { ChartData, ChartOptions, ChartType } from 'chart.js';
import Chart from 'chart.js/auto';
import { cloneDeep } from 'lodash';
import * as randomColor from 'randomcolor';
import { BehaviorSubject, Observable } from 'rxjs';
import { INDICATOR_LINE_COLOR } from 'ssotool-app/app.references';
import {
  clearLegend,
  generateHTMLLegendPluginStackedBar,
  generateId,
  LegendData,
} from 'ssotool-app/shared/charts.plugins';
import { Coerce } from 'ssotool-app/shared/helpers';
import { ColorSchemeService } from 'ssotool-shared/services';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

import {
  Bar,
  BarBlock,
  BarStack,
  StackedBarMetadata,
} from './stacked-bar.model';
import { ChartFacadeService } from 'ssotool-app/+roadmap/stores/charts/charts.facade.service';

@Component({
  selector: 'sso-stacked-bar',
  templateUrl: './stacked-bar.component.html',
  styleUrls: ['./stacked-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ColorSchemeService],
})
export class StackedBarComponent implements OnDestroy {
  @Input() set metadata(meta: StackedBarMetadata) {
    this._meta = cloneDeep(meta);
    this.stackedBarData = Chart.getChart('stackedBar');
    if (meta?.bars?.length) {
      this.initializeMetadata();
    }
  }
  @Input() diffByOpacity: boolean = true;
  @Input() hideToggle: boolean = false;
  @Input() set hideZeroValues(value: boolean) {
    this.toggleHideZeroValues(!value);
  }

  @Output() legendClick = new EventEmitter();
  @Output() chartJSDataChanges$ = new EventEmitter();

  stackedBarData: Chart;
  private _meta;
  private colorsFromBase: string[];
  private blockColorMap: { [blockName: string]: string } = {};
  @Input() isMultipleStacked: boolean = false;
  private legendId = 'stackedbar-legend';
  private hasData = new BehaviorSubject<boolean>(false);
  private stackNames: string[] = [];
  private opacityProgression: number = 0.4;
  hasData$ = this.hasData.asObservable();

  private _hideZeroValues = new BehaviorSubject<boolean>(false);
  hideZeroValues$: Observable<boolean> = this._hideZeroValues.asObservable();

  private _indicatorLineColor = new BehaviorSubject<string>(
    INDICATOR_LINE_COLOR,
  );

  constructor(
    private schemer: ColorSchemeService,
    private chartFacadeService: ChartFacadeService,
  ) {}

  ngOnDestroy(): void {
    this.cleanup();
  }

  private initializeIndicatorLine() {
    const label = 'Total CO2 Indicator';
    this.chartFacadeService.getColorByLabel(label).subscribe((color) => {
      this._indicatorLineColor.next(color);
    });
  }

  private initializeMetadata() {
    const legendIdLength = 10;
    this.legendId = generateId(legendIdLength);
    const legends = document.getElementById('stackedbar-legend');

    if (legends) {
      legends.id = this.legendId;
    }

    this.initializeIndicatorLine();

    this.cleanup();
    const adjustedMeta = this._meta;
    this.createCanvas();
    this.toChartJS(adjustedMeta);
  }

  private toChartJS(metadata: StackedBarMetadata) {
    const context = (document.getElementById('stackedBar') as any)?.getContext(
      '2d',
    );

    if (!context) {
      return;
    }

    if (metadata.baseColor) {
      this.colorsFromBase = this.schemer.getBaseColors();
    }

    // const legendStackGroups =
    //   metadata.bars.length === 1
    //     ? null
    //     : metadata.bars.map((stack) => {
    //         return {
    //           name: stack.name,
    //           baseColor: stack.baseColor,
    //         };
    //       });

    const chartData = this.toChartJSData(metadata);

    this.chartJSDataChanges$.emit({ ...chartData, ...{ name: 'stacked-bar' } });

    this.stackedBarData = new Chart(context, {
      type: 'bar' as ChartType,
      data: chartData as ChartData,
      options: this.toChartJSOptions(metadata) as ChartOptions,
      plugins: [
        generateHTMLLegendPluginStackedBar(
          null,
          this.legendId,
          this.openDialog.bind(this),
        ),
      ],
    });
  }

  openDialog(selected: LegendData) {
    this.legendClick.emit(selected);
  }

  private toChartJSData(metaData: StackedBarMetadata) {
    let datasets = [];
    let labels: Array<string> = metaData.bars[0].stacks?.map(
      (stack) => stack.name,
    );
    this.hasData.next(false);

    metaData.bars.forEach((bar) => {
      if (bar.baseColor) {
        this.colorsFromBase = this.schemer.getBaseColors();
      }

      let stacks = bar.stacks;

      if (this._hideZeroValues.getValue()) {
        stacks = this.removeZeroValues(bar.stacks);
        labels = stacks.map((stack) => stack.name);
      }

      const data = stacks?.reduce((acc, curr) => {
        curr.blocks.forEach((block) => {
          const chartJsEntry = this.toChartJSDataset(block, bar);
          const idx = acc.findIndex(
            (value) => value.label === chartJsEntry.label,
          );
          if (idx != -1) {
            acc[idx]?.data?.push(
              chartJsEntry.data[chartJsEntry.data.length - 1],
            );
          } else {
            acc.push(chartJsEntry);
          }
        });
        return acc;
      }, []);
      datasets = datasets.concat(data);
    });
    // remove dataset with total 0 value
    datasets = datasets.filter(
      (dataset) =>
        dataset?.data.reduce((totalValue, val) => (totalValue += val), 0) !== 0,
    );

    this.colorsFromBase = this.schemer.getBaseColors();
    datasets = datasets.map((data) => {
      const newColor = this.appendOpacityToColor(
        data.stack,
        this.generateOrGetBlockColor(data.label, data.meta.color),
      );
      return {
        ...data,
        backgroundColor: newColor,
        meta: { ...data.meta, color: newColor },
      };
    });

    if (metaData.line) {
      const flatValues = {};
      metaData.line.data.forEach(
        (point) => (flatValues[point.name] = point.value),
      );

      const lineData = labels.map((label) => {
        if (Coerce.getObjKeys(flatValues).indexOf(label) >= 0) {
          return flatValues[label];
        }
        return null;
      });

      datasets = datasets.concat({
        type: 'line',
        label: metaData.line.label,
        data: lineData,
        order: 1,
        backgroundColor: this._indicatorLineColor.value,
        borderColor: this._indicatorLineColor.value,
        pointBorderColor: this._indicatorLineColor.value,
        pointBackgroundColor: this._indicatorLineColor.value,
      });
    }

    if (datasets.length || labels.length) {
      this.hasData.next(true);
    }

    return {
      labels,
      datasets,
    };
  }

  private appendOpacityToColor(stackName: string, color: string) {
    return this.diffByOpacity ? color + this.getOpacity(stackName) : color;
  }

  private getOpacity(stackName: string): string {
    const FULL_OPACITY = 0xff;
    if (!!!this.stackNames.find((name) => name === stackName)) {
      this.stackNames.push(stackName);
    }
    const opacityLevel = this.stackNames.indexOf(stackName);
    return Math.floor(
      FULL_OPACITY * (1 - opacityLevel * this.opacityProgression),
    ).toString(16);
  }

  private removeZeroValues(stacks: BarStack[]): BarStack[] {
    return stacks.filter(
      (stack) =>
        stack.blocks.reduce(
          (totalBlockValue, block) => (totalBlockValue += block.value),
          0,
        ) !== 0,
    );
  }

  private toChartJSDataset(block: BarBlock, bar: Bar) {
    const parentName =
      typeof bar.parentName == 'undefined' ? '' : bar.parentName;
    const stack = parentName.length ? bar.parentName + bar.name : bar.name;

    return {
      label: block.name,
      data: [block.value],
      meta: {
        ...block,
        name: bar.name,
        isMultipleStacked: this.isMultipleStacked,
      },
      maxBarThickness: 25,
      stack: stack,
      parentName: parentName,
      order: 2,
    };
  }

  private generateOrGetBlockColor(blockName: string, color: string): string {
    const defaultColor = this.blockColorMap[blockName] || this.resolveColor();
    this.blockColorMap[blockName] = defaultColor;

    const existingColor = color || this.blockColorMap[blockName];
    return existingColor;
  }

  private resolveColor(): string {
    return this.colorsFromBase?.shift() || randomColor();
  }

  private toChartJSOptions(data: StackedBarMetadata) {
    const resolvedUnit = data?.unit || '';
    return {
      responsive: !!data.responsive,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
          title: {
            display: true,
            text: data.yAxisName,
          },
          max: Coerce.toObject(data).yAxisMaxScale,
        },
      },
      plugins: {
        htmlLegend: {
          containerID: 'stackedbar-legend',
        },
        legend: {
          display: false,
          position: 'bottom',
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: function (context) {
              return `${context.dataset.label}: ${context.formattedValue} ${resolvedUnit}`;
            },
            title: function (context) {
              if (
                context[0].dataset?.meta?.isMultipleStacked &&
                !context[0].dataset.parentName.length
              ) {
                return `${context[0].label}: ${context[0].dataset.meta.name}`;
              }

              if (
                context[0].dataset?.meta?.isMultipleStacked &&
                context[0].dataset.parentName.length
              ) {
                return `${context[0].dataset.parentName}: ${context[0].dataset.meta.name}\n ${context[0].label}`;
              }

              if (
                !context[0].dataset?.meta?.isMultipleStacked &&
                context[0].dataset.parentName.length
              ) {
                return ` ${context[0].dataset.parentName}\n ${context[0].label}`;
              }

              return `${context[0].label}`;
            },
          },
        },
      },
    };
  }

  cleanup(): void {
    /* istanbul ignore else */
    if (this.stackedBarData) {
      this.stackedBarData.destroy();

      const canvasContainer = document.getElementById('canvas-container');
      if (canvasContainer?.firstChild) {
        canvasContainer.removeChild(canvasContainer.firstChild);
      }
    }
    clearLegend(this.legendId);
    this.stackNames = [];
  }

  private createCanvas(): void {
    if (!!!document.getElementById('stackedBar')) {
      const legends = document.getElementById('canvas-container');
      const canvas = document.createElement('canvas');
      canvas.id = 'stackedBar';
      canvas.style.width = '900px';
      canvas.style.height = '400px';

      legends?.appendChild(canvas);
    }
  }

  toggleHideZeroValues(hideValue?: boolean): void {
    let value = !this._hideZeroValues.getValue();
    if (hideValue !== undefined) {
      value = hideValue;
    }
    this._hideZeroValues.next(value);
    this.initializeMetadata();
  }
}

import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { CampaignFacadeService } from 'ssotool-app/+campaign/store/campaign.facade.service';
import { doNothing } from 'ssotool-core/utils';
import {
  createErrorObject,
  ExecStatusChecker,
  SSOToolRoutePathService,
  UserStateManagerService,
} from 'ssotool-shared';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { CampaignImportAPIService } from '../../services';
import { CampaignImportActions } from './campaign-import.action';
import { ToastService } from 'ssotool-app/shared/services/toast/toast.service';

@Injectable()
export class CampaignImportEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignImportActions.get),
      mergeMap((action) =>
        this.campaignImportService.get(action.clientId, action.importId).pipe(
          map((response) =>
            CampaignImportActions.getSuccess({
              data: response,
              message: 'Get campaign import info success!',
            }),
          ),
          catchError((error) =>
            of(
              CampaignImportActions.getFailure({
                ...createErrorObject(error, 'Get campaign import info error'),
                data: action,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignImportActions.getSuccess),
        tap((action) =>
          this.userManagerService.get(action.data.owner)
            ? doNothing()
            : this.userManagerService.getUserById(action.data.owner),
        ),
      ),
    { dispatch: false },
  );

  getFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignImportActions.getFailure),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  getList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignImportActions.getList),
      mergeMap((action) =>
        this.campaignImportService.getList(action.clientId).pipe(
          map((response) =>
            CampaignImportActions.getListSuccess({
              data: {
                data: response,
                clientId: action.clientId,
              },
              message: 'Get campaign import list success!',
            }),
          ),
          catchError((error) =>
            of(
              CampaignImportActions.getListFailure({
                ...createErrorObject(error, 'Get campaign import list error'),
                data: action,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getListSuccess$ = createEffect(
    () => this.actions$.pipe(ofType(CampaignImportActions.getListSuccess)),
    { dispatch: false },
  );

  getListFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignImportActions.getListFailure),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  downloadCampaignImportData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignImportActions.downloadCampaignImportData),
      mergeMap((action) =>
        this.campaignImportService
          .downloadCampaignImportData(action.clientId, action.importId)
          .pipe(
            map((response) =>
              CampaignImportActions.downloadCampaignImportDataSuccess({
                data: { ...response, ...action },
                message: 'Download Campaign Import Success',
              }),
            ),
            catchError((error) =>
              of(
                CampaignImportActions.downloadCampaignImportDataFailure({
                  ...createErrorObject(error, 'Download campaign import error'),
                  data: action,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  downloadCampaignImportDataSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignImportActions.downloadCampaignImportDataSuccess),
      ),
    { dispatch: false },
  );

  downloadCampaignImportDataFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignImportActions.downloadCampaignImportDataFailure),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  updateImportStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignImportActions.updateImportStatus),
        filter(
          (action) =>
            this.importStatusChecker.isSuccessful(action.status) ||
            this.importStatusChecker.isError(action.status),
        ),
        tap((action) => {
          if (this.importStatusChecker.isSuccessful(action.status)) {
            this.campaignFacade.clearList();
            this.campaignFacade.resetIndividualCampaignLoadedState();
            this.campaignFacade.getList(action.clientId);
            setTimeout(
              () =>
                this.router.navigate(this.routePath.campaign(action.clientId)),
              3000,
            );
          } else {
            this.snackbar.showToast(
              this.translateService.instant('Campaign.messages.import.error'),
              'error',
            );
          }
        }),
      ),
    { dispatch: false },
  );

  importCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignImportActions.importCampaign),
      mergeMap((action) =>
        this.campaignImportService
          .importCampaign(action.clientId, action.data)
          .pipe(
            map((response) =>
              CampaignImportActions.importCampaignSuccess({
                data: response,
                message: this.translateService.instant(
                  'Campaign.messages.import.processing',
                ),
              }),
            ),
            catchError((error) =>
              of(
                CampaignImportActions.importCampaignFailure({
                  ...createErrorObject(error, 'Import campaign error'),
                  data: action.data,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  importCampaignSuccess$ = createEffect(
    () =>
      this.actions$.pipe(ofType(CampaignImportActions.importCampaignSuccess)),
    { dispatch: false },
  );

  importCampaignFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignImportActions.importCampaignFailure),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  downloadTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignImportActions.downloadTemplate),
      mergeMap((action) =>
        this.campaignImportService.downloadTemplate(action.clientId).pipe(
          map(() =>
            CampaignImportActions.downloadTemplateSuccess({
              data: action,
              message: 'Download template success',
            }),
          ),
          catchError((error) =>
            of(
              CampaignImportActions.downloadTemplateFailure({
                ...createErrorObject(error, 'Download template error'),
                data: action,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  downloadTemplateSuccess$ = createEffect(
    () =>
      this.actions$.pipe(ofType(CampaignImportActions.downloadTemplateSuccess)),
    { dispatch: false },
  );

  downloadTemplateFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignImportActions.downloadTemplateFailure),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  downloadErrorLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignImportActions.downloadErrorLogs),
      mergeMap((action) =>
        this.campaignImportService
          .downloadErrorLogs(action.clientId, action.importId)
          .pipe(
            map((response) =>
              CampaignImportActions.downloadErrorLogsSuccess({
                importId: action.importId,
                data: response,
                message: 'Download logs success',
              }),
            ),
            catchError((error) =>
              of(
                CampaignImportActions.downloadErrorLogsFailure({
                  ...createErrorObject(error, 'Download logs error'),
                  data: action,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  downloadErrorLogsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignImportActions.downloadErrorLogsSuccess),
      ),
    { dispatch: false },
  );

  downloadErrorLogsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignImportActions.downloadErrorLogsFailure),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  deleteImport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignImportActions.deleteImport),
      mergeMap((action) =>
        this.campaignImportService
          .deleteImport(action.clientId, action.importId)
          .pipe(
            map(() =>
              CampaignImportActions.deleteImportSuccess({
                data: action,
                importId: action.importId,
                message: 'Campaign import deleted successfully',
              }),
            ),
            catchError((error) =>
              of(
                CampaignImportActions.deleteImportFailure({
                  ...createErrorObject(error, 'Delete import error'),
                  data: action,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  deleteImportSuccessful$ = createEffect(
    () => this.actions$.pipe(ofType(CampaignImportActions.deleteImportSuccess)),
    { dispatch: false },
  );

  deleteImportFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CampaignImportActions.deleteImportFailure),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private campaignImportService: CampaignImportAPIService,
    private snackbar: ToastService,
    private userManagerService: UserStateManagerService,
    private importStatusChecker: ExecStatusChecker,
    private translateService: TranslateService,
    private campaignFacade: CampaignFacadeService,
    private router: Router,
    private routePath: SSOToolRoutePathService,
  ) {}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ShareClientTableComponent } from './share-client-table.component';
import { SearchInputModule } from 'ssotool-app/shared/modules/search-input';
import { SelectModule } from 'ssotool-app/shared/modules/select';

@NgModule({
  declarations: [ShareClientTableComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    SearchInputModule,
    ReactiveFormsModule,
    SelectModule,
    FormsModule,
    MatTooltipModule,
    TranslateModule,
  ],
  exports: [ShareClientTableComponent],
})
export class ShareClientTableModule {}

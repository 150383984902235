import { BehaviorSubject } from 'rxjs';

import { Component, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { FilterForm } from '../filters';
import { ChipData } from './chip-list.model';

@Component({
  selector: 'sso-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ChipListComponent,
      multi: true,
    },
  ],
})
export class ChipListComponent implements ControlValueAccessor, OnInit {
  @Input() colorReference: string[] = [];
  @Input() stripLabel: string = '';
  form = new FormControl({});
  chips = new BehaviorSubject<ChipData[]>([]);
  private _onTouch = () => {};
  private _onChange = (value: { [key: string]: FilterForm }) => {};

  chips$ = this.chips.asObservable();

  constructor() {}

  ngOnInit(): void {
    this.initializeForm();
  }

  private initializeForm() {
    this.form.valueChanges.subscribe(
      (formValue: { [key: string]: FilterForm }) => {
        this.initializeChips(formValue);
        this._onChange(formValue);
      },
    );
  }

  private initializeChips(formValue: { [key: string]: FilterForm }) {
    this.chips.next(
      Object.entries(formValue).reduce((acc, [key, value]) => {
        const form = value as FilterForm;
        if (form?.values?.length) {
          const flatData = form.values?.map((filter, idx) => {
            return {
              idx,
              key,
              value: filter,
              condition: form.condition,
            };
          });
          acc.push(...flatData);
        }

        return acc;
      }, []),
    );
  }

  removeChip(chip: ChipData) {
    const data = this.form.value[chip?.key];
    const values = [...data.values];
    values.splice(chip?.idx, 1);
    const condition = values.length ? data.condition : '';
    this.form.patchValue({
      ...this.form.value,
      [chip?.key]: { values, condition },
    });

    // Check if 'condition' and 'values' are empty, null, or undefined
    if (condition === '' || values.length === 0) {
      type FormValueType = {
        [key: string]: FilterForm;
      };

      const { [chip?.key]: removedKey, ...updatedFormValue } = this.form
        .value as FormValueType;
      this.form.patchValue(updatedFormValue);
    }
  }

  writeValue(obj: any): void {
    this.initializeChips(obj);

    this.form.patchValue(obj, { emitEvent: false });
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    }
  }
}

import { CampaignFacadeService } from 'ssotool-app/+campaign/store/campaign.facade.service';
import { ClientFacadeService } from 'ssotool-app/+client/store/client.facade.service';
import {
  CAMPAIGN_REFERENCES_KEY,
  REFERENCES,
} from 'ssotool-app/app.references';

import {
  ChangeDetectionStrategy,
  Component,
  Optional,
  Self,
} from '@angular/core';
import { FormBuilder, NgControl, Validators } from '@angular/forms';

import { CampaignBaseFormComponent } from '../base/campaign-base-form.component';
import { convertToYearlyValues } from 'ssotool-app/shared/helpers/time-dependent';
import { FormService } from 'ssotool-app/shared/services/form/form.service';
import { Coerce } from 'ssotool-app/shared/helpers/coerce.utils';
import { isFeatureEnabled } from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';

@Component({
  selector: 'sso-drawer-converter',
  templateUrl: './converter.component.html',
  styleUrls: ['./converter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConverterComponent extends CampaignBaseFormComponent {
  campaignType = REFERENCES.converter;
  defaultValues = {
    minCapacity: convertToYearlyValues('0.0', this.startYear, this.endYear),
    maxCapacity: convertToYearlyValues('0.0', this.startYear, this.endYear),
    minimumOperatingHours: convertToYearlyValues(
      '0.0',
      this.startYear,
      this.endYear,
    ),
    technicalLife: convertToYearlyValues('10', this.startYear, this.endYear),
    depreciationTime: convertToYearlyValues('10', this.startYear, this.endYear),
    implementationTime: convertToYearlyValues(
      '12',
      this.startYear,
      this.endYear,
    ),
    buildCo2Scope3Emissions: convertToYearlyValues(
      '0.0',
      this.startYear,
      this.endYear,
    ),
    indivisibleCo2Scope3Emissions: convertToYearlyValues(
      '0.0',
      this.startYear,
      this.endYear,
    ),
    buildCost: convertToYearlyValues('0.0', this.startYear, this.endYear),
    indivisibleCost: convertToYearlyValues('0.0', this.startYear, this.endYear),
    foM: convertToYearlyValues('0.0', this.startYear, this.endYear),
    foMPerInstall: convertToYearlyValues('0.0', this.startYear, this.endYear),
  };
  baseForm = this._formBuilder.group({
    ...this.commonControls,
    campaignType: CAMPAIGN_REFERENCES_KEY.CONVERTER,
    scale: '',
    processId: ['', Validators.required],
    productionFluids: [null, Validators.required],
    consumptionFluids: [null, Validators.required],
    mainConsumptionFluidId: ['', Validators.required],
    mainProductionFluidId: ['', Validators.required],
    minCapacity: [this.defaultValues.minCapacity, Validators.required],
    maxCapacity: [this.defaultValues.maxCapacity, Validators.required],
    minimumOperatingHours: [
      this.defaultValues.minimumOperatingHours,
      Validators.required,
    ],
    technicalLife: [this.defaultValues.technicalLife, Validators.required],
    depreciationTime: [
      this.defaultValues.depreciationTime,
      Validators.required,
    ],
    implementationTime: [
      this.defaultValues.implementationTime,
      Validators.required,
    ],
    buildCo2Scope3Emissions: [
      this.defaultValues.buildCo2Scope3Emissions,
      Validators.required,
    ],
    indivisibleCo2Scope3Emissions: [
      this.defaultValues.indivisibleCo2Scope3Emissions,
      Validators.required,
    ],
    buildCost: [this.defaultValues.buildCost, Validators.required],
    indivisibleCost: [this.defaultValues.indivisibleCost, Validators.required],
    foM: [this.defaultValues.foM, Validators.required],
    foMPerInstall: [this.defaultValues.foMPerInstall, Validators.required],
  });

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private _formBuilder: FormBuilder,
    formService: FormService,
    clientFacade: ClientFacadeService,
    campaignFacade: CampaignFacadeService,
  ) {
    super(ngControl, clientFacade, campaignFacade, formService);
  }

  get isInputSimplified(): boolean {
    return isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE);
  }

  setMainConsumption(id: string) {
    this.baseForm.controls.mainConsumptionFluidId.patchValue(id);
  }

  setMainProduction(id: string) {
    this.baseForm.controls.mainProductionFluidId.patchValue(id);
  }

  getErrors(errorObj: any) {
    return Coerce.getObjKeys(errorObj);
  }
}

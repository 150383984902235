<div
  id="no-client-component"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxFill
>
  <h2>{{ 'Client.labels.noClient' | translate }}</h2>
  <p>
    {{
      ((canCreateClient$ | ngrxPush)
        ? 'Client.messages.noClient'
        : 'Client.messages.cantCreateClient'
      ) | translate
    }}
  </p>
  <button
    mat-flat-button
    *ngIf="canCreateClient$ | ngrxPush"
    data-testid="create"
    color="primary"
    (click)="onCreateClient()"
  >
    {{ 'Client.labels.createClient' | translate | titlecase }}
  </button>
  <i class="sso-icon-blue nj-icon-finger-touch"></i>
</div>

import { PortfolioRoadmap, RoadmapsRun } from 'ssotool-app/+roadmap';

import { createAction, props } from '@ngrx/store';

import { UpdateRunSettingsParams } from './roadmap-parameters.model';
import { RoadmapCampaign, RoadmapParameters } from './roadmap.model';

function createActionTypeSentence(
  verb: string,
  status: 'OK' | 'NOK' | '' = '',
): string {
  return `[Roadmap API] ${verb} ${status}`;
}

const accessRoadmapAction = createAction(
  createActionTypeSentence('Access Roadmap'),
  props<{ roadmapId: string }>(),
);

// GET
const getRoadmapAction = createAction(
  createActionTypeSentence('Get Roadmap'),
  props<{
    clientId: string;
    roadmapId: string;
    variationId?: string;
    isSuccessful?: boolean;
  }>(),
);

const getRoadmapOKAction = createAction(
  createActionTypeSentence('Get Roadmap', 'OK'),
  props<{
    data: PortfolioRoadmap;
    clientId: string;
    roadmapId: string;
    isSuccessful?: boolean;
  }>(),
);

const getRoadmapNOKAction = createAction(
  createActionTypeSentence('Get Roadmap', 'NOK'),
  props<{ clientId: string; roadmapId: string }>(),
);

// GET ALL
const getAllRoadmapAction = createAction(
  createActionTypeSentence('Get All Roadmaps'),
  props<{ clientId: string }>(),
);

const getAllRoadmapOKAction = createAction(
  createActionTypeSentence('Get All Roadmaps', 'OK'),
  props<{ data: PortfolioRoadmap[]; clientId?: string; message: string }>(),
);

const getAllRoadmapNOKAction = createAction(
  createActionTypeSentence('Get All Roadmaps', 'NOK'),
  props<{ message: string; clientId: string }>(),
);

// CREATE
const createRoadmapAction = createAction(
  createActionTypeSentence('Create'),
  props<RoadmapParameters>(),
);

const createRoadmapOKAction = createAction(
  createActionTypeSentence('Create', 'OK'),
  props<{
    data: PortfolioRoadmap;
    params: RoadmapParameters;
    message: string;
  }>(),
);

const createRoadmapNOKAction = createAction(
  createActionTypeSentence('Create', 'NOK'),
  props<{ params: RoadmapParameters; message: string }>(),
);

// UPDATE
const updateRoadmapAction = createAction(
  createActionTypeSentence('Update'),
  props<RoadmapParameters>(),
);

const updateRoadmapOKAction = createAction(
  createActionTypeSentence('Update', 'OK'),
  props<{
    data: PortfolioRoadmap;
    params: RoadmapParameters;
    message: string;
  }>(),
);

const updateRoadmapNOKAction = createAction(
  createActionTypeSentence('Update', 'NOK'),
  props<{ params: RoadmapParameters; message: string }>(),
);

// DELETE
const deleteRoadmapAction = createAction(
  createActionTypeSentence('Delete'),
  props<RoadmapParameters>(),
);

const deleteRoadmapOKAction = createAction(
  createActionTypeSentence('Delete', 'OK'),
  props<{ params: RoadmapParameters; message: string }>(),
);

const deleteRoadmapNOKAction = createAction(
  createActionTypeSentence('Delete', 'NOK'),
  props<{ params: RoadmapParameters; message: string }>(),
);

// SET TO BASELINE
const setToBaselineAction = createAction(
  createActionTypeSentence('Set Roadmap As Baseline'),
  props<RoadmapParameters>(),
);

const setToBaselineOKAction = createAction(
  createActionTypeSentence('Set Roadmap As Baseline', 'OK'),
  props<{
    data: PortfolioRoadmap;
    params: RoadmapParameters;
    message: string;
  }>(),
);

const setToBaselineNOKAction = createAction(
  createActionTypeSentence('Set Roadmap As Baseline', 'NOK'),
  props<{ params: RoadmapParameters; message: any }>(),
);

// SINGLE COMPUTE
const computeRoadmapAction = createAction(
  createActionTypeSentence('Compute'),
  props<RoadmapParameters>(),
);

const computeRoadmapOKAction = createAction(
  createActionTypeSentence('Compute', 'OK'),
  props<{ data: PortfolioRoadmap; message: string }>(),
);

const computeRoadmapNOKAction = createAction(
  createActionTypeSentence('Compute', 'NOK'),
  props<{ params: RoadmapParameters; message: string; status: number }>(),
);

// STOP COMPUTE
const stopComputeRoadmapAction = createAction(
  createActionTypeSentence('Stop Compute'),
  props<{ clientId: string; roadmapId: string }>(),
);

const stopComputeRoadmapOKAction = createAction(
  createActionTypeSentence('Stop Compute', 'OK'),
  props<{ params: { clientId: string; roadmapId: string }; message: string }>(),
);

const stopComputeRoadmapPartialAction = createAction(
  createActionTypeSentence('Stop Compute Partial', 'OK'),
  props<{ roadmapId: string; message: string }>(),
);

const stopComputeRoadmapNOKAction = createAction(
  createActionTypeSentence('Stop Compute', 'NOK'),
  props<{
    params: { clientId: string; roadmapId: string };
    message: string;
  }>(),
);

// MULTIPLE COMPUTE
const computeMultipleRoadmapAction = createAction(
  createActionTypeSentence('Compute Multiple'),
  props<{ clientId: string; roadmapIds: string[]; proceed?: boolean }>(),
);

const computeMultipleRoadmapOKAction = createAction(
  createActionTypeSentence('Compute Multiple', 'OK'),
  props<{ data: RoadmapsRun; message: string }>(),
);

const computeMultipleRoadmapNOKAction = createAction(
  createActionTypeSentence('Compute Multiple', 'NOK'),
  props<{
    params: { clientId: string; roadmapIds: string[] };
    message: string;
    status: number;
  }>(),
);

// SINGLE RELOAD
const reloadSingleRoadmapAction = createAction(
  createActionTypeSentence('Reload'),
  props<{ clientId: string; roadmapId: string; status: string }>(),
);

const reloadSingleRoadmapOKAction = createAction(
  createActionTypeSentence('Reload', 'OK'),
  props<{ data: any; isSuccessful: boolean }>(),
);

const reloadSingleRoadmapNOKAction = createAction(
  createActionTypeSentence('Reload', 'NOK'),
  props<{ message: any }>(),
);

// CAN LAUNCH ROADMAP
const computeCanLaunchRoadmapAction = createAction(
  createActionTypeSentence('Reload Can Launch'),
  props<{
    clientId: string;
    roadmapId: string;
    status: string;
  }>(),
);

const computeCanLaunchRoadmapOKAction = createAction(
  createActionTypeSentence('Reload Can Launch', 'OK'),
  props<{ data: any; isSuccessful: boolean }>(),
);

const computeCanLaunchRoadmapNOKAction = createAction(
  createActionTypeSentence('Reload Can Launch', 'NOK'),
);

// MULTIPLE RELOAD
const reloadMultipleRoadmapAction = createAction(
  createActionTypeSentence('Reload Multiple Roadmap'),
  props<{ clientId: string; roadmapIds: string[]; status: string }>(),
);

const reloadMultipleRoadmapOKAction = createAction(
  createActionTypeSentence('Reload Multiple Roadmap', 'OK'),
  props<{
    clientId: string;
    roadmapIds: string[];
    message: string;
    isSuccessful: boolean;
  }>(),
);

const reloadMultipleRoadmapNOKAction = createAction(
  createActionTypeSentence('Reload Multiple Roadmap', 'NOK'),
  props<{ message: any }>(),
);

const reloadMultipleRoadmapSilentlyAction = createAction(
  createActionTypeSentence('Reload Multiple Roadmap Silently'),
  props<{ roadmapIds: string[]; status: string; feStatus: string }>(),
);

// PROPAGATION RELOAD
const reloadAffectedRoadmapAction = createAction(
  createActionTypeSentence('Reload Roadmap'),
  props<{ clientId: string; roadmapIds: string[] }>(),
);

const reloadAffectedRoadmapOKAction = createAction(
  createActionTypeSentence('Reload Roadmap', 'OK'),
  props<{ data: any; message: string }>(),
);

const reloadAffectedRoadmapNOKAction = createAction(
  createActionTypeSentence('Reload Roadmap', 'NOK'),
  props<{ message: any }>(),
);

const reloadRoadmapSilentlyAction = createAction(
  createActionTypeSentence('Reload Roadmap Silently'),
  props<{ roadmapId: string; status: string; feStatus: string }>(),
);

// DUPLICATE
const duplicateRoadmapsAction = createAction(
  createActionTypeSentence('Duplicate Roadmaps'),
  props<{ clientId: string; roadmapIds: string[] }>(),
);

const duplicateRoadmapsOKAction = createAction(
  createActionTypeSentence('Duplicate Roadmaps', 'OK'),
  props<{ data: PortfolioRoadmap[]; roadmapIds: string[] }>(),
);

const duplicateRoadmapsNOKAction = createAction(
  createActionTypeSentence('Duplicate Roadmaps', 'NOK'),
  props<{ clientId: string; roadmapIds: string[]; message: string }>(),
);

// UPDATE RUN SETTINGS
const updateRunSettingsAction = createAction(
  createActionTypeSentence('Update Roadmap Run Settings'),
  props<{ params: UpdateRunSettingsParams; reloadAfter: boolean }>(),
);

const updateRunSettingsOKAction = createAction(
  createActionTypeSentence('Update Roadmap Run Settings', 'OK'),
  props<{ response: PortfolioRoadmap; reloadAfter: boolean }>(),
);

const updateRunSettingsNOKAction = createAction(
  createActionTypeSentence('Update Roadmap Run Settings', 'NOK'),
  props<UpdateRunSettingsParams>(),
);

// QUEUE UPDATE
const queueUpdateRoadmapAction = createAction(
  createActionTypeSentence('Queue Update Roadmap'),
  props<RoadmapParameters>(),
);

const updateQueueDetailsAction = createAction(
  createActionTypeSentence('Update Queue Count'),
  props<{ id: string; queueCount: number; queueFailed: boolean }>(),
);

// FETCH ROADMAP CAMPAIGNS
const fetchRoadmapCampaigns = createAction(
  createActionTypeSentence('Fetch Roadmap Campaigns'),
  props<{ clientId: string; roadmapId: string }>(),
);

const fetchRoadmapCampaignsOK = createAction(
  createActionTypeSentence('Fetch Roadmap Campaigns', 'OK'),
  props<{ roadmapId: string; data: RoadmapCampaign[] }>(),
);

const fetchRoadmapCampaignsNOK = createAction(
  createActionTypeSentence('Fetch Roadmap Campaigns', 'NOK'),
  props<{ clientId: string; roadmapId: string }>(),
);

const updateRoadmapCampaigns = createAction(
  createActionTypeSentence('Update Roadmap Campaigns'),
  props<{
    clientId: string;
    roadmapId: string;
    campaigns: Array<RoadmapCampaign>;
  }>(),
);

const updateRoadmapCampaignsOK = createAction(
  createActionTypeSentence('Update Roadmap Campaigns', 'OK'),
  props<{ roadmap: PortfolioRoadmap; campaigns: Array<RoadmapCampaign> }>(),
);

const updateRoadmapCampaignsNOK = createAction(
  createActionTypeSentence('Update Roadmap Campaigns', 'NOK'),
  props<{
    clientId: string;
    roadmapId: string;
    campaigns: Array<RoadmapCampaign>;
  }>(),
);

const deleteRoadmapCampaigns = createAction(
  createActionTypeSentence('Delete Roadmap Campaigns'),
  props<{
    clientId: string;
    roadmapId: string;
    campaigns: Array<RoadmapCampaign>;
  }>(),
);

const deleteRoadmapCampaignsOK = createAction(
  createActionTypeSentence('Delete Roadmap Campaigns', 'OK'),
  props<{ roadmap: PortfolioRoadmap; campaigns: Array<RoadmapCampaign> }>(),
);

const deleteRoadmapCampaignsNOK = createAction(
  createActionTypeSentence('Delete Roadmap Campaigns', 'NOK'),
  props<{
    clientId: string;
    roadmapId: string;
    campaigns: Array<RoadmapCampaign>;
  }>(),
);

// OTHERS
const updateResultsXlsRegenerationAction = createAction(
  createActionTypeSentence('Update Results Regeneration'),
  props<{ roadmapId: string; status: string }>(),
);

const doNothingAction = createAction('[Roadmap Page] Do nothing');

const cascadeUpdate = createAction(
  '[Roadmap] Cascade Update',
  props<{
    clientId: string;
    roadmapId: string;
    updatedAt: string;
    updatedBy: string;
  }>(),
);

export const RoadmapEntityActions = {
  accessRoadmapAction,
  getRoadmapAction,
  getRoadmapOKAction,
  getRoadmapNOKAction,
  getAllRoadmapAction,
  getAllRoadmapOKAction,
  getAllRoadmapNOKAction,
  createRoadmapAction,
  createRoadmapOKAction,
  createRoadmapNOKAction,
  updateRoadmapAction,
  updateRoadmapOKAction,
  updateRoadmapNOKAction,
  setToBaselineAction,
  setToBaselineOKAction,
  setToBaselineNOKAction,
  computeRoadmapAction,
  computeRoadmapOKAction,
  computeRoadmapNOKAction,
  stopComputeRoadmapAction,
  stopComputeRoadmapOKAction,
  stopComputeRoadmapPartialAction,
  stopComputeRoadmapNOKAction,
  reloadSingleRoadmapAction,
  reloadSingleRoadmapOKAction,
  reloadRoadmapSilentlyAction,
  reloadSingleRoadmapNOKAction,
  computeCanLaunchRoadmapAction,
  computeCanLaunchRoadmapOKAction,
  computeCanLaunchRoadmapNOKAction,
  deleteRoadmapAction,
  deleteRoadmapOKAction,
  deleteRoadmapNOKAction,
  reloadAffectedRoadmapAction,
  reloadAffectedRoadmapOKAction,
  reloadAffectedRoadmapNOKAction,
  computeMultipleRoadmapAction,
  computeMultipleRoadmapOKAction,
  computeMultipleRoadmapNOKAction,
  reloadMultipleRoadmapAction,
  reloadMultipleRoadmapOKAction,
  reloadMultipleRoadmapNOKAction,
  reloadMultipleRoadmapSilentlyAction,
  duplicateRoadmapsAction,
  duplicateRoadmapsOKAction,
  duplicateRoadmapsNOKAction,
  updateRunSettingsAction,
  updateRunSettingsOKAction,
  updateRunSettingsNOKAction,
  updateResultsXlsRegenerationAction,
  queueUpdateRoadmapAction,
  updateQueueDetailsAction,
  doNothingAction,
  fetchRoadmapCampaigns,
  fetchRoadmapCampaignsOK,
  fetchRoadmapCampaignsNOK,
  updateRoadmapCampaigns,
  updateRoadmapCampaignsOK,
  updateRoadmapCampaignsNOK,
  deleteRoadmapCampaigns,
  deleteRoadmapCampaignsOK,
  deleteRoadmapCampaignsNOK,
  cascadeUpdate,
};

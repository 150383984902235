import { createAction, props } from '@ngrx/store';
import { ClientEntity } from './entities.model';

function createActionTypeSentence(apiName: string, verb: string, status: 'OK' | 'NOK' | '' = ''): string {
  return `[${apiName} API] ${verb} ${status}`;
}
const getAction = (apiName: string) =>
  createAction(createActionTypeSentence(apiName, 'Get'), props<{ id: string; clientId: string }>());
const getOKAction = (apiName: string) =>
  createAction(createActionTypeSentence(apiName, 'Get', 'OK'), props<{ data: ClientEntity }>());
const getNOKAction = (apiName: string) => createAction(createActionTypeSentence(apiName, 'Get', 'NOK'));
const getAllAction = (apiName: string) =>
  createAction(createActionTypeSentence(apiName, 'Get All'), props<{ clientId: string }>());
const getAllOKAction = (apiName: string) =>
  createAction(createActionTypeSentence(apiName, 'Get All', 'OK'), props<{ data: string[]; clientId?: string }>());
const getAllNOKAction = (apiName: string) => createAction(createActionTypeSentence(apiName, 'Get All', 'NOK'));

export const ClientEntityActions = (apiName: string) => ({
  getAction: getAction(apiName),
  getOKAction: getOKAction(apiName),
  getNOKAction: getNOKAction(apiName),
  getAllAction: getAllAction(apiName),
  getAllOKAction: getAllOKAction(apiName),
  getAllNOKAction: getAllNOKAction(apiName),
});

<form
  fxLayout="column"
  fxLayout="column"
  fxLayoutGap="10px"
  [formGroup]="baseControl"
>
  <button mat-button style="height: 0; position: absolute">
    <!-- 'absorbs' the auto-focus behavior -->
  </button>
  <div fxLayout="column" fxLayoutGap="10px">
    <div
      class="contents-loader"
      *ngIf="campaignLoading$ | async; else contents"
    >
      <ngx-skeleton-loader
        [count]="1"
        animation="progress"
        [theme]="{ height: '60px', width: '100%' }"
      ></ngx-skeleton-loader>
      <mat-divider></mat-divider>
      <ngx-skeleton-loader
        [count]="4"
        animation="progress"
        [theme]="{ height: '40px', width: '40%', 'margin-right': '16px' }"
      ></ngx-skeleton-loader>
    </div>
  </div>
  <ng-template #contents>
    <ng-container [ngSwitch]="displayMode$ | ngrxPush">
      <ng-container *ngSwitchCase="'profiles'">
        <sso-breadcrumb
          [levels]="breadcrumbLevels$ | ngrxPush"
          (crumbClick)="handleCrumbNavigation($event)"
        ></sso-breadcrumb>
        <sso-profiles-drawer
          formControlName="profilesFormControl"
          [data]="data"
        ></sso-profiles-drawer>
      </ng-container>
      <sso-campaign-drawer
        *ngSwitchCase="'campaign'"
        formControlName="campaignFormControl"
        [data]="data"
      ></sso-campaign-drawer>
      <div *ngSwitchDefaul>
        {{ 'Campaign.messages.drawer.noImplementation' | translate }}
      </div>
    </ng-container>
  </ng-template>
</form>

import { Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ClientFacadeService, TrajectoryVariation } from 'ssotool-app/+client';
import { AnalysisMapper } from 'ssotool-app/+roadmap/services';
import { FE_EXECUTION_STATUS } from 'ssotool-app/app.references';
import { ExecStatusChecker } from 'ssotool-app/shared';
import { generateEndpoint } from 'ssotool-core/utils';
import { ConfigService } from 'ssotool-shared/services/config';

import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import {
  BackendRoadmapVariation,
  RoadmapVariation,
  RoadmapVariationGetListParams,
  RoadmapVariationGetParams,
  RoadmapVariationRunMap,
} from './roadmap-variation.model';

@Injectable()
export class RoadmapVariationService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private execStatus: ExecStatusChecker,
    private clientFacade: ClientFacadeService,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  get({
    clientId,
    roadmapId,
    variationId,
  }: RoadmapVariationGetParams): Observable<RoadmapVariation> {
    return this.http
      .get(this.makeGetEndpoint(clientId, roadmapId, variationId))
      .pipe(
        mergeMap((response: BackendRoadmapVariation) =>
          this.clientFacade
            .getTrajectoryVariations(clientId)
            .pipe(
              map((variations) => this.mapToFrontend(response, variations)),
            ),
        ),
        catchError((error) => throwError(error)),
      );
  }

  getList({
    clientId,
    roadmapId,
  }: RoadmapVariationGetListParams): Observable<RoadmapVariation[]> {
    return this.http.get(this.makeGetListEndpoint(clientId, roadmapId)).pipe(
      mergeMap((response: BackendRoadmapVariation[]) =>
        this.clientFacade
          .getTrajectoryVariations(clientId)
          .pipe(
            map((variations) =>
              response.map((data) => this.mapToFrontend(data, variations)),
            ),
          ),
      ),
      catchError((error) => throwError(error)),
    );
  }

  private mapToFrontend(
    data: BackendRoadmapVariation,
    variations: TrajectoryVariation,
  ): RoadmapVariation {
    const feStatus = this.mapStatusToFrontend(data.run, data.updatedAt);

    return {
      ...data,
      name: variations[data.variationId].name,
      feStatus,
      beStatus: data.run?.status,
      updatedAt: this.formatDate(data.updatedAt),
      snapshot: AnalysisMapper.mapToFrontend(data.run?.snapshot),
      canLaunchRoadmap: this.execStatus.canRoadmapBeComputed(feStatus),
      canDuplicateRoadmap: this.execStatus.canRoadmapBeDuplicated(feStatus),
    };
  }

  // UTILS
  private isComputationOutdated(launchDate, roadmapUpdatedDate): boolean {
    return (
      !!launchDate &&
      !!roadmapUpdatedDate &&
      new Date(roadmapUpdatedDate) > new Date(launchDate)
    );
  }

  private mapStatusToFrontend(
    run: RoadmapVariationRunMap,
    updatedAt: string,
  ): string {
    return run?.status
      ? this.execStatus.mapToFrontend(
          run.status,
          this.isComputationOutdated(run.launchDate, updatedAt),
        )
      : FE_EXECUTION_STATUS.DRAFT;
  }

  private formatDate(dateString: string) {
    return formatDate(dateString, 'dd MMM YYYY HH:mm', this.locale);
  }

  private makeGetEndpoint(
    clientId: string,
    roadmapId: string,
    variationId: string,
  ): string {
    const { baseUrl, endpoints } = this.config.api;
    return generateEndpoint(
      baseUrl,
      endpoints.roadmapVariation.get,
      clientId,
      roadmapId,
      variationId,
    );
  }

  private makeGetListEndpoint(clientId: string, roadmapId: string): string {
    const { baseUrl, endpoints } = this.config.api;
    return generateEndpoint(
      baseUrl,
      endpoints.roadmapVariation.list,
      clientId,
      roadmapId,
    );
  }
}

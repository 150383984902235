<div
  fxLayout="column"
  fxLayoutAlign="start start"
  fxLayoutGap="12px"
  *ngrxLet="data$; let d"
  class="toast-container"
>
  <div data-testid="message-container" class="toast-message">
    {{ d.message | translate: { name: (clientName$ | async) || '' } }}
  </div>
  <button
    *ngIf="d?.actionLabel as label"
    data-testid="action-button"
    mat-button
    color="primary"
    (click)="onAction()"
  >
    {{ label | translate }}
  </button>
</div>

import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { LibraryImportActions } from './library-import.actions';
import { LibraryImportModel } from './library-import.model';

export const libraryImportFeatureKey = 'libraryImport';

export interface LibraryImportState extends EntityState<LibraryImportModel> {
  loading: boolean;
  error: string;
  message: string;
}

export const selectImportId = (libraryImport: LibraryImportModel) => libraryImport.importId;

export const sortDate = (importLibrary1: LibraryImportModel, importLibrary2: LibraryImportModel) => {
  const date1 = new Date(importLibrary1.date);
  const date2 = new Date(importLibrary2.date);
  return date1.getTime() - date2.getTime();
};

export const libraryImportAdapter: EntityAdapter<LibraryImportModel> = createEntityAdapter<LibraryImportModel>({
  selectId: selectImportId,
  sortComparer: sortDate,
});

export const initialLibraryImportState: LibraryImportState = libraryImportAdapter.getInitialState({
  loading: false,
  error: undefined,
  message: undefined,
});

const libraryImportReducer = createReducer(
  initialLibraryImportState,
  on(LibraryImportActions.get, (state) => {
    return { ...state, loading: true };
  }),
  on(LibraryImportActions.getSuccess, (state, { data, message }) => {
    return libraryImportAdapter.upsertOne({ ...data, loaded: true }, { ...state, message, loading: false });
  }),
  on(LibraryImportActions.getFailure, (state, { error, message }) => {
    return { ...state, error, loading: false, message };
  }),
  on(LibraryImportActions.getList, (state) => {
    return { ...state, loading: true };
  }),
  on(LibraryImportActions.getListSuccess, (state, { message }) => {
    return { ...state, loading: false, message };
  }),
  on(LibraryImportActions.getListFailure, (state, { error, message }) => {
    return { ...state, loading: false, error, message };
  }),
  on(LibraryImportActions.importLibrary, (state) => ({ ...state, loading: true })),
  on(LibraryImportActions.importLibrarySuccess, (state, { data, message }) =>
    libraryImportAdapter.upsertOne({ ...data, loaded: true }, { ...state, loading: false, message }),
  ),
  on(LibraryImportActions.importLibraryFailure, (state, { error, message }) => ({
    ...state,
    loading: false,
    error,
    message,
  })),
  on(LibraryImportActions.downloadLibrary, (state) => ({ ...state, loading: true })),
  on(LibraryImportActions.downloadLibrarySuccess, (state, { message }) => ({ ...state, loading: false, message })),
  on(LibraryImportActions.downloadLibraryFailure, (state, { error, message }) => ({
    ...state,
    loading: false,
    error,
    message,
  })),
  on(LibraryImportActions.downloadLibraryLogs, (state) => ({ ...state, loading: true })),
  on(LibraryImportActions.downloadLibraryLogsSuccess, (state, { message }) => ({ ...state, loading: false, message })),
  on(LibraryImportActions.downloadLibraryLogsFailure, (state, { error, message }) => ({
    ...state,
    loading: false,
    error,
    message,
  })),
);

export function LibraryImportReducer(state: LibraryImportState | undefined, action: Action) {
  return libraryImportReducer(state, action);
}

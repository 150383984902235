import { first } from 'rxjs/operators';
import { Coerce } from 'ssotool-shared/helpers';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { BinDataFacadeService } from '../stores/bindata/bindata-facade.service';

@Injectable({ providedIn: 'root' })
export class BindataSubpageResolverService implements Resolve<any> {
  constructor(private bindataFacade: BinDataFacadeService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const clientId = Coerce.toObject(route.params).clientId;

    if (clientId) {
      this.bindataFacade.loaded$.pipe(first()).subscribe((loaded) => {
        if (!loaded) {
          this.bindataFacade.clearLocalStorage();
          this.bindataFacade.downloadAllBinData(clientId);
        }
      });
    }
  }
}

import { iif, of } from 'rxjs';
import { catchError, first, map, mergeMap, tap } from 'rxjs/operators';
import { ComputeToastComponent } from 'ssotool-app/+roadmap/modules/toasts';
import { NotificationType } from 'ssotool-app/app.references';
import { Coerce, ExecStatusChecker } from 'ssotool-app/shared';

import { Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { RoadmapFacadeService } from '../roadmap/roadmap-facade.service';
import { RoadmapVariationService } from './roadmap-variation-api.service';
import { RoadmapVariationActions } from './roadmap-variation.actions';
import { RoadmapVariationFacadeService } from './roadmap-variation.facade.service';
import { ResultFacadeService } from '../result/result-facade.service';
import { ToastService } from 'ssotool-app/shared/services/toast/toast.service';

@Injectable()
export class RoadmapVariationEffects {
  constructor(
    private action$: Actions,
    private service: RoadmapVariationService,
    private facade: RoadmapVariationFacadeService,
    private toast: ToastService,
    private statusChecker: ExecStatusChecker,
    private toastService: HotToastService,
    private roadmapFacade: RoadmapFacadeService,
    private resultFacade: ResultFacadeService,
  ) {}

  get() {
    return this.action$.pipe(
      ofType(RoadmapVariationActions.get),
      mergeMap((params) =>
        this.service.get(params).pipe(
          map((data) => RoadmapVariationActions.getSuccess({ data })),
          catchError(() => of(RoadmapVariationActions.getFailed(params))),
        ),
      ),
    );
  }

  getFailed() {
    return this.action$.pipe(
      ofType(RoadmapVariationActions.getFailed),
      tap((params) =>
        this.toast.showToast(
          'Roadmap Variation Get Failed',
          'action',
          undefined,
          of({}).pipe(tap(() => this.facade.get(params))),
        ),
      ),
    );
  }

  getList() {
    return this.action$.pipe(
      ofType(RoadmapVariationActions.getList),
      mergeMap((params) =>
        this.service.getList(params).pipe(
          map((data) => RoadmapVariationActions.getListSuccess({ data })),
          catchError(() => of(RoadmapVariationActions.getListFailed(params))),
        ),
      ),
    );
  }

  getListFailed() {
    return this.action$.pipe(
      ofType(RoadmapVariationActions.getListFailed),
      tap((params) =>
        this.toast.showToast(
          'Roadmap Variation Get List Failed',
          'action',
          undefined,
          of({}).pipe(tap(() => this.facade.getList(params))),
        ),
      ),
    );
  }

  reloadSingleRoadmapVariationSideEffect() {
    return this.action$.pipe(
      ofType(RoadmapVariationActions.reloadSingleRoadmapVariationAction),
      mergeMap((action) =>
        iif(
          () =>
            this.statusChecker.isSuccessful(action.status) ||
            this.statusChecker.isError(action.status),
          of(action).pipe(
            map((action) =>
              RoadmapVariationActions.reloadSingleRoadmapVariationOKAction({
                data: action,
                isSuccessful: this.statusChecker.isSuccessful(action.status),
              }),
            ),
          ),
          of(action).pipe(
            map((action) => {
              return RoadmapVariationActions.reloadRoadmapVariationSilentlyAction(
                {
                  roadmapId: action.roadmapId,
                  variationId: action.variationId,
                  status: action.status,
                  feStatus: this.statusChecker.mapToFrontend(action.status),
                },
              );
            }),
          ),
        ),
      ),
      catchError((err) =>
        of(
          RoadmapVariationActions.reloadSingleRoadmapVariationNOKAction({
            message:
              (err.error || {}).error || 'Reloading roadmap results error!',
          }),
        ),
      ),
    );
  }

  reloadSingleRoadmapVariationOKSideEffect() {
    return this.action$.pipe(
      ofType(RoadmapVariationActions.reloadSingleRoadmapVariationOKAction),
      tap((action) => {
        const { clientId, roadmapId, variationId, notifType } = action.data;
        this.facade.get({
          clientId,
          roadmapId,
          variationId,
        });
        if (notifType !== NotificationType.SILENT) {
          // TODO: if computation done:
          // change roadmap store's roadmap.canLaunchRoadmap to true;
          // can also be put in app.component to prevent dependency of 2 stores
          // or somewhere else
          if (action.isSuccessful) {
            this.toastService.success(ComputeToastComponent, {
              data: action.data,
            });
            this.resultFacade.get({
              clientId,
              roadmapId,
              variationId,
            });
          } else {
            this.toastService.error(ComputeToastComponent, {
              data: action.data,
            });
          }
        }
      }),
    );
  }

  reloadSingleRoadmapVariationNOKSideEffect() {
    return this.action$.pipe(
      ofType(RoadmapVariationActions.reloadSingleRoadmapVariationNOKAction),
      tap((action) => {
        this.toastService.error(action.message);
      }),
    );
  }

  reloadAffectedRoadmapVariationSideEffect() {
    return this.action$.pipe(
      ofType(RoadmapVariationActions.reloadAffectedRoadmapVariationAction),
      tap((action) => {
        this.roadmapFacade
          .getAffectedRoadmaps(action.roadmapIds)
          .pipe(first())
          .subscribe((roadmaps) => {
            roadmaps.forEach((roadmap) => {
              this.facade.get({
                clientId: action.clientId,
                roadmapId: roadmap.roadmapId,
                variationId: Coerce.toEmptyObject(roadmap.runSettings)
                  .variationId,
              });
            });
          });
      }),
      map((action) =>
        RoadmapVariationActions.reloadAffectedRoadmapVariationOKAction({
          data: action,
          message: 'Reloading roadmapvariation results success!',
        }),
      ),
      catchError((err) =>
        of(
          RoadmapVariationActions.reloadAffectedRoadmapVariationNOKAction({
            message:
              (err.error || {}).error ||
              'Reloading roadmapvariation results error!',
          }),
        ),
      ),
    );
  }

  reloadAffectedRoadmapVariationOKSideEffect() {
    return this.action$.pipe(
      ofType(RoadmapVariationActions.reloadAffectedRoadmapVariationOKAction),
    );
  }

  reloadAffectedRoadmapVariationNOKSideEffect() {
    return this.action$.pipe(
      ofType(RoadmapVariationActions.reloadAffectedRoadmapVariationNOKAction),
      tap((action) => this.toast.showToast(action.message, 'error')),
    );
  }

  get$ = createEffect(this.get.bind(this));
  getFailed$ = createEffect(this.getFailed.bind(this), { dispatch: false });
  getList$ = createEffect(this.getList.bind(this));
  getListFailed$ = createEffect(this.getListFailed.bind(this), {
    dispatch: false,
  });

  reloadSingleRoadmapVariation$ = createEffect(
    this.reloadSingleRoadmapVariationSideEffect.bind(this),
  );
  reloadSingleRoadmapVariationOK$ = createEffect(
    this.reloadSingleRoadmapVariationOKSideEffect.bind(this),
    {
      dispatch: false,
    },
  );
  reloadSingleRoadmapVariationNOK$ = createEffect(
    this.reloadSingleRoadmapVariationNOKSideEffect.bind(this),
    {
      dispatch: false,
    },
  );

  reloadAffectedRoadmapVariation$ = createEffect(
    this.reloadAffectedRoadmapVariationSideEffect.bind(this),
  );
  reloadAffectedRoadmapVariationOK$ = createEffect(
    this.reloadAffectedRoadmapVariationOKSideEffect.bind(this),
    {
      dispatch: false,
    },
  );
  reloadAffectedRoadmapVariationNOK$ = createEffect(
    this.reloadAffectedRoadmapVariationNOKSideEffect.bind(this),
    {
      dispatch: false,
    },
  );
}

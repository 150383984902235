import * as _moment from 'moment';
import { Options } from '@angular-slider/ngx-slider';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { ENGIE_DEFAULT_SCHEME } from 'ssotool-app/app.references';
import { GENERATE_COLOR_SCHEME } from 'ssotool-shared/charts.token';
import { DateFilterService } from 'ssotool-shared/services';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { YearlyAreaChartData } from './tabbed-area-chart.model';

const moment = _moment;

type ChartScheme = Readonly<{
  name: string;
  selectable: boolean;
  group: string;
  domain: Array<string>;
}>;

@Component({
  selector: 'sso-tabbed-area-chart',
  templateUrl: './tabbed-area-chart.component.html',
  styleUrls: ['./tabbed-area-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabbedAreaChartComponent {
  /* Data */
  @Input() loading: boolean;
  _seriesDataList$ = new BehaviorSubject<any[]>([]);
  seriesDataList$: Observable<any[]> = of([]);
  _sliderOptions: Options = {
    step: 1,
    draggableRange: true,
    showTicks: true,
    floor: 1,
    ceil: 365,
    minRange: 0,
    maxRange: 365,
  };
  dataset;
  @Input() set seriesDataList(seriesDataList: YearlyAreaChartData) {
    /* istanbul ignore else */
    if (seriesDataList) {
      this._seriesDataList$.next(seriesDataList.series);
      this.dataset = seriesDataList.series.map((s, index) =>
        this.dateFilter.mapDataToSeries(s, index, this.dateFilter.year),
      );
      // The seriesDataList$ to be used in displaying the chart based on the array of strings from binstore and the date filter applied
      this.seriesDataList$ = combineLatest([
        this._seriesDataList$,
        this.dateFilter.dateChange$,
      ]).pipe(this.dateFilter.filter$(seriesDataList.name));
    }
  }

  // seriesDataList$ =  this._seriesDataList$;
  legend = false;
  showLabels = true;
  animations = true;
  @Input() width = 720;
  @Input() height = 350;
  @Input() xAxis = true;
  @Input() yAxis = true;
  @Input() showYAxisLabel = true;
  @Input() showXAxisLabel = true;
  @Input() xAxisLabel = '';
  @Input() yAxisLabel = '';
  timeline = true;
  scheme: ChartScheme = ENGIE_DEFAULT_SCHEME;
  view: Array<any> = [this.width, this.height];

  /* Range Slider */
  @Input() hasRangeSlider = true;
  @Input() from: any = 0 as any;
  @Input() to: any = 7 as any;

  /* tabs */
  @Input() yearlyTabs = [];

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onTabChange = new EventEmitter<any>();
  selected = new FormControl(0); // The control representing the selected or active tab
  constructor(
    @Inject(GENERATE_COLOR_SCHEME)
    @Optional()
    private generateColorScheme: (
      numberOfElements: number,
      scheme?: any,
    ) => any,
    public dateFilter: DateFilterService,
  ) {
    this.initSlider();
  }

  initSlider() {
    const { defaultSliderOptions } = this.dateFilter;
    const newDefaultSliderOptions = {
      ...defaultSliderOptions,
      ...this._sliderOptions,
    };
    const nonLeapYear = 2019;
    this.dateFilter.year = nonLeapYear;
    this.dateFilter.defaultSliderOptions = newDefaultSliderOptions;
    this.dateFilter.defaultSliderOptions$.next(newDefaultSliderOptions);
    this.dateFilter.maxDate$.next(this._sliderOptions.maxRange);
  }

  // Format of the chart's x-axis
  xAxisFormat = (value: any) => moment(value).format('MMM D hA');

  onSelect(event) {}

  onFromChange(value: any) {
    this.dateFilter.minDate$.next(value);
  }

  onToChange(value: any) {
    this.dateFilter.maxDate$.next(value);
  }

  onChangeSelectedTab(index: number) {
    this.selected.patchValue(index);
    this.onTabChange.emit(index);
  }
}

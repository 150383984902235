import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseComponent } from 'ssotool-shared/component/base';
import { convertToYearlyValues, YearlyValues } from 'ssotool-shared/helpers';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';

import { Fluid } from '../fluids-toggle-group';

@Component({
  selector: 'sso-fluid-array',
  templateUrl: './fluids-array.component.html',
  styleUrls: ['./fluids-array.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FluidsArrayComponent,
      multi: true,
    },
    { provide: NG_VALIDATORS, useExisting: FluidsArrayComponent, multi: true },
  ],
})
export class FluidsArrayComponent
  extends BaseComponent
  implements ControlValueAccessor
{
  form: FormGroup;
  fluidsForm: FormArray;
  _onChange;
  _onTouched;
  @Input() startYear: number;
  @Input() endYear: number;
  @Input() label: string;
  @Input() unit: string;

  _fluidOptions: Array<Fluid>;
  @Input() set fluidOptions(fluidOptions: Array<Fluid>) {
    /* istanbul ignore else */
    if (!!fluidOptions) {
      this._fluidOptions = fluidOptions;
      this.fluidOptions$.next(fluidOptions);
      this.initializeFluidForm(this._value);
    }
  }

  get fluidOptions() {
    return this._fluidOptions;
  }

  _mode;
  @Input() set viewMode(mode: string) {
    this._mode = mode === 'view';
  }
  get viewMode() {
    return this._mode;
  }

  _mainInput;
  @Input() set mainInput(mainInput: string) {
    this._mainInput = mainInput;
    this.form.controls.fluidToggle.patchValue(mainInput);
  }

  get mainInput(): string {
    return this._mainInput;
  }

  @Output() consumption = new EventEmitter<string>();

  fluids$ = new BehaviorSubject<Array<Fluid>>([]);
  fluidOptions$ = new BehaviorSubject<Array<Fluid>>([]);
  fluidReferences$ = this.fluids$.asObservable().pipe(
    map((fluids) =>
      fluids.reduce(
        (acc, fluid) =>
          fluid?.value && fluid?.name
            ? {
                ...acc,
                [fluid.value]: fluid.name,
              }
            : acc,
        {},
      ),
    ),
  );

  get fluids(): FormArray {
    return this.fluidsForm as FormArray;
  }

  constructor(public formBuilder: FormBuilder) {
    super();
    this.form = this.formBuilder.group({
      fluidToggle: '',
    });
  }

  private _value: any[];
  writeValue(value: any[]) {
    this._value = value;
    this.initializeFluidForm(value);
  }

  initializeFluidForm(value: any[]) {
    if (value) {
      this.initFluidOptionsSelected(value);
      this.updateFluidsForm(value);
    } else {
      this.fluidsForm = new FormArray([]);
    }
    this.fluidsForm.valueChanges.subscribe((res) => {
      this._onChange(res);
    });
  }

  updateFluidsForm(value: any[]) {
    this.fluidsForm = new FormArray(
      value?.map((_v) => {
        return new FormGroup({
          fluidId: new FormControl(_v.fluidId, Validators.required),
          efficiencies: new FormControl(_v.efficiencies, Validators.required),
        });
      }),
    );
  }

  initFluidOptionsSelected(fluids: any[]) {
    const _fluids = [];
    fluids.forEach((_f) => {
      _fluids.push(
        this._fluidOptions?.filter((_o) => _o.value === _f.fluidId)[0],
      );
      this.updateFluidOptions(_f);
    });
    this.fluids$.next(_fluids);
  }

  newEfficiency(id?: string, value?: YearlyValues) {
    const _v = value
      ? value
      : convertToYearlyValues('0.0', this.startYear, this.endYear);
    return this.formBuilder.group({
      fluidId: id || '',
      efficiencies: [_v, Validators.required],
    });
  }

  addEfficiency(id?: string, value?: YearlyValues) {
    this.fluids.push(this.newEfficiency(id, value));
  }

  removeFluid(fluid: string) {
    const fluids = this.fluids$.value.filter((_f) => _f.value !== fluid);
    this.fluids$.next(fluids);
    this.removeEfficiency(fluid);
    this.updateMainFluid(fluid);
    this.addFluidOption(fluid);
  }

  removeEfficiency(fluidId: string) {
    const _f = this.fluidsForm.value.filter((_o) => _o.fluidId === fluidId);
    const i = this.fluidsForm.value.indexOf(_f[0]);
    this.fluids.removeAt(i);
  }

  addFluidOption(fluid: string) {
    const _fluid = this.fluidOptions.filter((_f) => _f.value === fluid);
    this.fluidOptions$.value.push(_fluid[0]);
  }

  updateMainFluid(fluid: string) {
    /* istanbul ignore else */
    if (fluid === this.form.controls.fluidToggle.value) {
      this.fluids$.value.length > 0
        ? this.selectMainFluid(this.fluids$.value[0].value)
        : this.selectMainFluid('');
    }
  }

  selectMainFluid(id: string) {
    this.consumption.emit(id);
    this.form.controls.fluidToggle.patchValue(id);
  }

  selectFluidOption(selectedFluid: Fluid) {
    this.fluids$.next([...this.fluids$.value, selectedFluid]);
    this.autoSelectMainFluid(selectedFluid.value);
    this.updateFluidOptions(selectedFluid);
    this.addEfficiency(selectedFluid.value);
  }

  updateFluidOptions(fluid: Fluid | any) {
    const newFluidOptions = [...this.fluidOptions$.value].filter(
      (_f) => _f.value !== (fluid?.fluidId || fluid.value),
    );
    this.fluidOptions$.next(newFluidOptions);
  }

  autoSelectMainFluid(id: string) {
    /* istanbul ignore else */
    if (!this.mainInput) {
      this.selectMainFluid(id);
    }
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: (value: any) => void) {
    this._onTouched = fn;
  }

  validate({ value }: FormControl) {
    return !this.fluidsForm || this.fluidsForm.valid
      ? null
      : { error: 'Some fields are not fullfilled' };
  }
}

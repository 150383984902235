import { BehaviorSubject } from 'rxjs';
import { ClickMode } from 'ssotool-app/shared/directives/selection-emitter/selection-emitter.model';

import { Injectable } from '@angular/core';

import {
  ClickSelectHandlerType,
  SelectionEventData,
} from './multiselection.model';

@Injectable()
export class MultiselectionService {
  private selected = new BehaviorSubject<string[]>([]);
  selected$ = this.selected.asObservable();
  private allItems = new Array<string>();
  private lastSelected: string;

  select(selectionData: SelectionEventData): void {
    this.selected.next(this.handleSelectionChange(selectionData));
  }

  unselectAll() {
    this.selected.next([]);
  }

  private handleSelectionChange(selectionData: SelectionEventData): string[] {
    return this.SELECTION_CLICK_HANDLERS[selectionData.mode](selectionData.id);
  }

  private SELECTION_CLICK_HANDLERS: Record<ClickMode, ClickSelectHandlerType> =
    {
      [ClickMode.CTRL]: this.ctrlClick.bind(this)(),
      [ClickMode.SHIFT]: this.shiftClick.bind(this)(),
      [ClickMode.DEFAULT]: this.oneClick.bind(this)(),
    };

  loadAllItems(items: string[]): void {
    this.allItems = items;
  }

  private ctrlClick(): ClickSelectHandlerType {
    return (id: string) => {
      const currentSelectedItemsID = this.selected.value;
      this.lastSelected = id;
      return currentSelectedItemsID.includes(id)
        ? currentSelectedItemsID.filter((item) => item !== id)
        : currentSelectedItemsID.concat(id);
    };
  }

  private shiftClick(): ClickSelectHandlerType {
    return (id: string) => {
      this.lastSelected = this.lastSelected
        ? this.lastSelected
        : this.allItems[0];
      let indexLast = this.allItems.indexOf(this.lastSelected);
      let indexCurrent = this.allItems.indexOf(id);
      if (indexLast > indexCurrent) {
        return this.allItems.slice(indexCurrent, indexLast + 1);
      } else {
        return this.allItems.slice(indexLast, indexCurrent + 1);
      }
    };
  }

  private oneClick(): ClickSelectHandlerType {
    return (id: string) => {
      this.lastSelected = id;
      return [id];
    };
  }
}

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { generateEndpoint } from 'ssotool-app/core';
import { ConfigService } from 'ssotool-shared/services/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  RoadmapVisualization,
  RoadmapVisualizationSaveSuccess,
  VisualizationMap,
} from './visualization.model';

@Injectable()
export class RoadmapVisualizationAPIService {
  constructor(private http: HttpClient, private config: ConfigService) {}

  delete(clientId: string, roadmapId: string, visualizationId: string) {
    return this.http.delete(
      this.makeDeleteEndpoint(clientId, roadmapId, visualizationId),
    );
  }

  get(
    clientId: string,
    roadmapId: string,
    visualizationId: string,
  ): Observable<RoadmapVisualization> {
    return this.http.get<RoadmapVisualization>(
      this.makeGetEndpoint(clientId, roadmapId, visualizationId),
    );
  }

  save(
    name: string,
    clientId: string,
    roadmapId: string,
    details: VisualizationMap,
  ): Observable<RoadmapVisualizationSaveSuccess> {
    return this.http.post<RoadmapVisualizationSaveSuccess>(
      this.makeSaveEndpoint(clientId, roadmapId),
      {
        name,
        clientId,
        roadmapId,
        details,
      },
    );
  }

  getList(clientId: string, roadmapId: string) {
    return this.http
      .get<RoadmapVisualization[]>(this.makeListEndpoint(clientId, roadmapId))
      .pipe(catchError((error) => throwError(error)));
  }

  private makeGetEndpoint(
    clientId: string,
    roadmapId: string,
    visualizationId: string,
  ): string {
    const { baseUrl, endpoints } = this.config.api;

    return generateEndpoint(
      baseUrl,
      endpoints.roadmapVisualization.get,
      clientId,
      roadmapId,
      visualizationId,
    );
  }

  private makeSaveEndpoint(clientId: string, roadmapId: string): string {
    const { baseUrl, endpoints } = this.config.api;

    return generateEndpoint(
      baseUrl,
      endpoints.roadmapVisualization.save,
      clientId,
      roadmapId,
    );
  }

  private makeListEndpoint(clientId: string, roadmapId: string): string {
    const { baseUrl, endpoints } = this.config.api;

    return generateEndpoint(
      baseUrl,
      endpoints.roadmapVisualization.list,
      clientId,
      roadmapId,
    );
  }

  private makeDeleteEndpoint(
    clientId: string,
    roadmapId: string,
    visualizationId: string,
  ): string {
    const { baseUrl, endpoints } = this.config.api;

    return generateEndpoint(
      baseUrl,
      endpoints.roadmapVisualization.delete,
      clientId,
      roadmapId,
      visualizationId,
    );
  }
}

export function mapToRoadmapVisualization(data: any[]): RoadmapVisualization[] {
  return data.map((item) => {
    const details: VisualizationMap = item.details
      ? JSON.parse(item.details)
      : {};

    // Validate key spelling
    const validKeys = [
      'filters',
      'kpiType',
      'kpi',
      'subKpi',
      'curveType',
      'xAxis',
      'splitBy',
      'indicatorField',
      'geography',
      'year',
      'showZeroValue',
      'colorMap',
    ];

    const validatedDetails: VisualizationMap = Object.keys(details).reduce(
      (validated, key) => {
        const validatedKey = validKeys.includes(key) ? key : null;
        return validatedKey
          ? { ...validated, [validatedKey]: details[key] }
          : validated;
      },
      {} as VisualizationMap,
    );

    return {
      clientId: item.clientId,
      roadmapId: item.roadmapId,
      visualizationId: item.visualizationId,
      name: item.name,
      createdAt: item.createdAt,
      details: validatedDetails,
    };
  });
}

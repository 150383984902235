import { curveStep } from 'd3-shape';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from 'ssotool-app/shared/component/base';
import { Coerce } from 'ssotool-app/shared/helpers';

import { coerceNumberProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

import { YearlyLineChartData } from './line-chart.model';

interface YearlyValues {
  [year: string]: string;
}

const DEFAULT_CHART_WIDTH = 800;
const DEFAULT_CHART_HEIGHT = 400;

@Component({
  selector: 'sso-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartComponent extends BaseComponent implements OnInit {
  lineCurve = curveStep; // The line chart curve
  showLegend = false;

  /**
   * The dictionary of number values where the keys represent unique years
   */
  @Input() set yearlyValuesMap(value: YearlyValues) {
    this.chartData = this.mapToChartData(value);
  }

  private chartWidth = new BehaviorSubject(0);
  private chartHeight = new BehaviorSubject(0);

  @Input() yAxisLabel: string;
  @Input() xAxisLabel: string;

  // The chart-compatible data used for drawing the chart
  chartData: Array<YearlyLineChartData>;

  chartWidth$ = this.chartWidth.asObservable();
  chartHeight$ = this.chartHeight.asObservable();

  @Input() chartXAxisFormat = (value: any) => value;
  @Input() chartTooltipTitleFormat = (value: any) => value;

  @HostListener('window:resize') onResize() {
    this.setChartWidth(this.calculateWidth());
    this.setChartHeight(this.calculateHeight());
  }

  setChartWidth(value: number) {
    this.chartWidth.next(value);
  }

  setChartHeight(value: number) {
    this.chartHeight.next(value);
  }

  ngOnInit() {
    this.onResize();
  }

  /**
   * Maps the yearly values dictionary to a line-chart compatible data
   * @param value - the yearly values
   */
  mapToChartData(value: YearlyValues): Array<YearlyLineChartData> {
    return [
      {
        name: this.yAxisLabel,
        series: Coerce.getObjKeys(value).map((year) => ({
          name: year,
          value: coerceNumberProperty(value[year]),
        })),
      },
    ];
  }

  calculateWidth() {
    return DEFAULT_CHART_WIDTH;
  }

  calculateHeight() {
    return DEFAULT_CHART_HEIGHT;
  }
}

<div
  class="pie-chart-page"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="20px"
>
  <div
    id="left tooltip-parent graphs"
    [fxLayout]="metadataCount <= 2 ? 'row wrap' : 'column'"
    fxLayoutAlign="center center"
  >
    <div id="main" fxLayoutAlign="center center">
      <canvas
        id="pie"
        data-testid="pie"
        width="1000"
        height="700"
        #pieChartCanvas
      ></canvas>
    </div>
    <div
      class="children-container"
      id="children"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
      fxFlex
    ></div>
  </div>
  <div class="graph-controls">
    <div id="pie-legend" class="chart-legend" fxFlex></div>
  </div>
</div>

import { Observable, Subject } from 'rxjs';
import { filter, map, skipWhile, tap } from 'rxjs/operators';

import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormGroup,
  NgControl,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  distinctObject,
  FormFieldOption,
  getDefaultRootValue,
  mapEntitiesToSelectOptions,
} from '../../helpers';

@UntilDestroy()
@Directive()
export class BaseFormComponent
  implements ControlValueAccessor, OnInit, Validator
{
  @ViewChild('submit', { read: ElementRef }) submitButton: ElementRef;

  baseForm: FormGroup;

  @Input() submit$: Subject<unknown>;

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  onChange = (value: any) => {};
  onTouch = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(controls): void {}

  setDisabledState(isDisabled: boolean): void {}

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.baseForm.valid ? null : { invalid: control.value };
  }

  ngOnInit() {
    this.baseForm.valueChanges
      .pipe(
        untilDestroyed(this),
        filter((value) => !!value),
        distinctObject,
      )
      .subscribe((value) => this.onChange(value));

    this.submit$
      ?.pipe(untilDestroyed(this))
      .subscribe(() => this.submitButton?.nativeElement?.click());

    this.initializeFormControl();
  }

  initializeFormControl() {
    /* istanbul ignore else */
    if (this.ngControl) {
      this.ngControl.control.setValidators([this.validate.bind(this)]);
      this.ngControl.control.updateValueAndValidity({ emitEvent: true });
      this.ngControl.control.markAsPristine();
    }
  }

  /**
   * Populate the options of a select fields from a selector function.
   * @param selectorFn selector function where the values of the options are saved.
   * @param selectorKey selector key (usually a client Id)
   * @param rootKey a key if the list of options has a root value
   * @returns an observable to use to populate a select field.
   */
  createFormOptions(
    selectorFn,
    selectorKey = '',
    rootKey = '',
    addOtherDetails = false,
  ): Observable<FormFieldOption<string>[]> {
    return selectorFn(selectorKey).pipe(
      skipWhile((data) => !data),
      tap((data) => {
        /* istanbul ignore else */
        if (rootKey && !this.baseForm.controls[rootKey].value) {
          this.baseForm.controls[rootKey].patchValue(getDefaultRootValue(data));
          this.initializeFormControl();
        }
      }),
      map((data) => mapEntitiesToSelectOptions(data, true, addOtherDetails)),
    );
  }
}

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AlphaRequestResolver, AlphaRequestService } from './services';
import {
  AlphaRequestEffects,
  AlphaRequestFacadeService,
  alphaRequestFeatureKey,
  AlphaRequestReducer,
} from './store';
import { ToastModule } from 'ssotool-app/shared/services/toast/toast.module';

@NgModule({
  declarations: [],
  imports: [
    EffectsModule.forFeature([AlphaRequestEffects]),
    StoreModule.forFeature(alphaRequestFeatureKey, AlphaRequestReducer),
    ToastModule,
  ],
  providers: [
    AlphaRequestService,
    AlphaRequestFacadeService,
    AlphaRequestResolver,
  ],
})
export class AlphaRequestRootModule {}

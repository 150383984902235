import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarRef,
  SimpleSnackBar,
} from '@angular/material/snack-bar';

@Injectable()
export class NotificationsService {
  DEFAULT_DURATION = 2500;
  ACTION_DURATION = 4000;

  constructor(private _snackBar: MatSnackBar) {}

  show(
    message: string,
    action?: string,
    panelClass?: string | string[],
    duration?: number,
  ): MatSnackBarRef<SimpleSnackBar> {
    if (message.length > 35) {
      this.DEFAULT_DURATION = 7000;
    } else {
      this.DEFAULT_DURATION = 2500;
    }
    return this._snackBar.open(message, action, {
      duration:
        duration || (action ? this.ACTION_DURATION : this.DEFAULT_DURATION),
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass,
    });
  }

  showSuccess(
    message: string,
    action?: string,
    duration?: number,
  ): MatSnackBarRef<SimpleSnackBar> {
    const successPanelClasses = ['sso-snackbar-success'];
    return this.show(message, action, successPanelClasses, duration);
  }

  showError(
    message: string,
    action?: string,
    duration?: number,
  ): MatSnackBarRef<SimpleSnackBar> {
    const errorPanelClasses = ['sso-snackbar-error'];
    return this.show(message, action, errorPanelClasses, duration);
  }

  showInfo(
    message: string,
    action?: string,
    duration?: number,
  ): MatSnackBarRef<SimpleSnackBar> {
    const infoPanelClasses = ['sso-snackbar-info'];
    return this.show(message, action, infoPanelClasses, duration);
  }
}

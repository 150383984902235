import { Injectable } from '@angular/core';
import { ActionsSubject, Store, select } from '@ngrx/store';

import { filter } from 'rxjs/operators';

import { LibraryImportActions } from './library-import.actions';
import { LibraryImportFormModel } from './library-import.model';
import { LibraryImportState } from './library-import.reducer';
import { libraryImportEntities, libraryImportList, loading } from './library-import.selector';

@Injectable()
export class LibraryImportFacadeService {
  constructor(private store: Store<LibraryImportState>, private actionSubject$: ActionsSubject) {}

  loading$ = this.store.pipe(select(loading));
  getLibraryImportEntities$ = this.store.pipe(select(libraryImportEntities));
  getLibraryImportList$ = this.store.pipe(select(libraryImportList));

  get(importId: string) {
    this.store.dispatch(LibraryImportActions.get({ importId }));
    return this.actionSubject$.pipe(filter((action) => action.type === LibraryImportActions.getSuccess.type));
  }

  getList() {
    this.store.dispatch(LibraryImportActions.getList());
  }

  downloadLibrary(importId: string) {
    this.store.dispatch(LibraryImportActions.downloadLibrary({ importId }));
  }

  importLibrary(inputFileLibrary: LibraryImportFormModel) {
    this.store.dispatch(LibraryImportActions.importLibrary({ inputFileLibrary }));
  }

  downloadLibraryLogs(importId: string) {
    this.store.dispatch(LibraryImportActions.downloadLibraryLogs({ importId }));
  }
}

import { PipesModule } from 'ssotool-shared/pipes';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { PushPipe } from '@ngrx/component';

import { ChipListComponent } from './chip-list.component';

@NgModule({
  declarations: [ChipListComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PushPipe,
    MatChipsModule,
    MatIconModule,
    PipesModule,
  ],
  exports: [ChipListComponent],
})
export class ChipListModule {}

import { AbstractControl, ValidationErrors } from '@angular/forms';

export function mustBeWithinRange(data) {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const from = new Date(data.validStartLimit).getTime();
      const to = new Date(data.validEndLimit).getTime();
      const current = new Date(control.value).getTime();
      return from <= current && to >= current
        ? null
        : { mustBeWithinRange: control.value };
    }

    return null;
  };
}

export function endDateMustBeGreaterThanStartDate(start: string, end: string) {
  return (control: AbstractControl) => {
    if (control.get(start)?.disabled || control.get(end)?.disabled) {
      return null;
    }
    const from = new Date(control.get(start)?.value).getTime();
    const to = new Date(control.get(end)?.value).getTime();

    return from <= to || control.pristine ? null : { mustBeEarlier: true };
  };
}

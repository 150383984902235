import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { AmbitionActions } from './ambition.action';

export const ambitionFeatureKey = 'Ambition';

// TODO: Update model
export interface AmbitionState extends EntityState<any> {
  targets: string[];
  loading: boolean;
  loaded: boolean;
  error: string;
  message: string;
}

export const selectConstraintId = (constraint: any) => constraint.constraintId;
export const ambitionAdapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: selectConstraintId,
});
export const initialAmbitionState: AmbitionState =
  ambitionAdapter.getInitialState({
    targets: [],
    loading: false,
    loaded: false,
    error: undefined,
    message: undefined,
  });

const ambitionReducer = createReducer(
  initialAmbitionState,
  on(AmbitionActions.getList, (state) => {
    return { ...state, loading: true, error: undefined };
  }),
  on(AmbitionActions.getListSuccess, (state, { data, message }) => {
    return ambitionAdapter.upsertMany(data, {
      ...state,
      loading: false,
      message,
      loaded: true,
      targets: data.filter((d) => d.isTarget).map((d) => d.constraintId),
    });
  }),
  on(AmbitionActions.getListFailure, (state, { error, message }) => {
    return { ...state, loading: false, error, message };
  }),
  on(AmbitionActions.updateTargets, (state) => {
    return { ...state, loading: true };
  }),
  on(
    AmbitionActions.updateTargetsSuccess,
    (state, { constraints, message }) => {
      const updatedState = ambitionAdapter.upsertMany(constraints, {
        ...state,
        loading: false,
        message,
      });
      const mappedNew = constraints.reduce(
        (acc, { constraintId, isTarget }) => {
          acc[constraintId] = isTarget;
          return acc;
        },
        {},
      );
      const mappedCurrent = state.targets.reduce((acc, curr) => {
        acc[curr] = true;
        return acc;
      }, {});
      const joined = { ...mappedCurrent, ...mappedNew };

      return {
        ...updatedState,
        targets: Object.entries(joined)
          .filter(([_, isTarget]) => isTarget)
          .map(([constraintId, _]) => constraintId),
      };
    },
  ),
  on(AmbitionActions.updateTargetsFailure, (state, { error, message }) => {
    return { ...state, error, message, loading: false };
  }),
  // create custom targets
  on(AmbitionActions.createCustomTarget, (state) => {
    return { ...state, loading: true, error: undefined };
  }),
  on(AmbitionActions.createCustomTargetSuccess, (state, { data, message }) => {
    const newState = ambitionAdapter.upsertOne(data, {
      ...state,
      loading: false,
      message,
    });

    const mappedCurrent = newState.targets.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});
    mappedCurrent[data?.constraintId] = true;

    return {
      ...newState,
      loading: false,
      loaded: true,
      message,
      targets: Object.entries(mappedCurrent)
        .filter(([_, isTarget]) => isTarget)
        .map(([constraintId, _]) => constraintId),
    };
  }),
  on(AmbitionActions.createCustomTargetFailure, (state, { message, error }) => {
    return { ...state, error, message, loading: false };
  }),
  // edit custom targets
  on(AmbitionActions.editCustomTarget, (state) => {
    return { ...state, loading: true, error: undefined };
  }),
  on(AmbitionActions.editCustomTargetSuccess, (state, { data, message }) => {
    return ambitionAdapter.upsertOne(data, {
      ...state,
      loading: false,
      loaded: true,
      message,
    });
  }),
  on(AmbitionActions.editCustomTargetFailure, (state, { message, error }) => {
    return { ...state, error, message, loading: false };
  }),
  // delete custom targets
  on(AmbitionActions.deleteCustomTarget, (state) => {
    return { ...state, loading: true, error: undefined };
  }),
  on(
    AmbitionActions.deleteCustomTargetSuccess,
    (state, { constraintId, message }) => {
      let targets = [...state.targets];
      const index = targets.indexOf(constraintId);
      if (index > -1) {
        targets?.splice(targets.indexOf(constraintId), 1);
      }

      return ambitionAdapter.removeOne(constraintId, {
        ...state,
        loading: false,
        loaded: true,
        message,
        targets,
      });
    },
  ),
  on(AmbitionActions.deleteCustomTargetFailure, (state, { message, error }) => {
    return { ...state, error, message, loading: false };
  }),
  on(AmbitionActions.clearList, () => {
    return initialAmbitionState;
  }),
);

export function AmbitionReducer(
  state: AmbitionState | undefined,
  action: Action,
) {
  return ambitionReducer(state, action);
}

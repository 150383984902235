import { first } from 'rxjs/operators';
import { Coerce } from 'ssotool-shared/helpers';

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import { DataImportFacadeService } from '../stores';

export const dataManagementResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
) => {
  const dataImportFacade = inject(DataImportFacadeService);
  const clientId = Coerce.toObject(route.params).clientId;
  if (clientId) {
    dataImportFacade.loaded$.pipe(first()).subscribe((loaded) => {
      if (!loaded) {
        dataImportFacade.getList(clientId);
      }
    });
  }
};

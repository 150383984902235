import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, first, map } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client/store/client.facade.service';
import { Coerce } from 'ssotool-app/shared/helpers';

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

import { ProfileDetails } from './profile-input.model';

@UntilDestroy()
@Component({
  selector: 'sso-profile-input',
  templateUrl: './profile-input.component.html',
  styleUrls: ['./profile-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ProfileInputComponent,
      multi: true,
    },
  ],
})
export class ProfileInputComponent implements ControlValueAccessor, OnInit {
  private _details = new BehaviorSubject<ProfileDetails>(undefined);

  @Input() label: string;
  @Input() set details(details: ProfileDetails) {
    this._details.next(details);
  }
  get details(): ProfileDetails {
    return this._details.value;
  }

  private _sites = new BehaviorSubject<Record<string, string>>(null);
  profileForm = new FormControl();
  siteSelectControl = new FormControl();
  siteNameMapping = {};
  private _onChange = (value: any) => {};
  private _onTouched = () => {};

  get sites() {
    return this._sites.value;
  }

  siteOptions = this._sites.pipe(map((sites) => Coerce.getObjKeys(sites)));

  constructor(private clientFacade: ClientFacadeService) {}

  ngOnInit(): void {
    this.clientFacade
      .getSites(
        this.details?.clientId,
        this.details?.isGeoGroup,
        this.details?.geoId,
      )
      .pipe(
        filter((geos) => !!geos),
        first(),
      )
      .subscribe((sites) => {
        this._sites.next(
          sites.reduce((acc, site) => {
            acc[site.name] = site.geoId;
            return acc;
          }, {}),
        );
      });
    this.siteSelectControl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((site) => this._onChange(site));
  }

  writeValue(value: any): void {
    if (value) {
      this.siteSelectControl.patchValue(value, { emitEvent: false });
    }
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
}

import { Observable, of } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client';
import { Coerce } from 'ssotool-app/shared/helpers';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { TimeSeriesBarChartDialogComponent } from './timeseries-bar-chart-dialog';
import { TimeSeriesHourlyDialogComponent } from './timeseries-hourly-dialog';

@Component({
  selector: 'sso-timeseries-list',
  templateUrl: './timeseries-list.component.html',
  styleUrls: ['./timeseries-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSeriesListComponent implements OnInit {
  entityType = this.route.snapshot.data['type'];
  referenceKey = this.route.snapshot.data['referenceKey'];
  ID = this.route.snapshot.params[this.referenceKey];

  entities$: Observable<any[]> = of([]);
  loading$: Observable<boolean> = of(false);
  clientId = '';
  columnDefs = [
    { name: 'name', type: '' },
    { name: 'geography', type: 'geographies' },
    { name: 'company', type: 'companies' },
    { name: 'sector', type: 'sectors' },
    { name: 'quantity', type: 'quantity' },
    { name: 'granularity', type: 'granularity' },
    { name: 'values', type: '', mode: 'tabArea' },
  ].filter((col) => col.type !== this.entityType);
  excludedColumnFilter = ['values'];

  constructor(
    private route: ActivatedRoute,
    private clientFacade: ClientFacadeService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.clientFacade.selectActiveClientData$.pipe().subscribe((client) => {
      /* istanbul ignore else */
      if (client && client.clientId) {
        this.clientId = client.clientId;
        this.entities$ = this.clientFacade
          .selectTimeSeries$(client.clientId)
          .pipe(
            skipWhile((data) => !data),
            map((data) =>
              Coerce.getObjValues(data)
                .filter(
                  (timeseries) => timeseries[this.referenceKey] === this.ID,
                )
                .map((timeseries) => ({
                  ...timeseries,
                  geography: timeseries.geoName,
                  company: timeseries.companyName,
                  sector: timeseries.sectorName,
                  quantity: timeseries.quantityName,
                  granularity:
                    timeseries.granularity.charAt(0).toUpperCase() +
                    timeseries.granularity.slice(1),
                })),
            ),
          );
        this.loading$ = this.clientFacade.dataLoading$(client.clientId);
      }
    });
  }

  onClick(entity: any) {
    if (entity.granularity === 'Hourly') {
      this.dialog
        .open(TimeSeriesHourlyDialogComponent, {
          width: '80%',
          data: {
            location: entity.location,
            entityId: entity.timeseriesId,
            quantityId: entity.quantityId,
            clientId: this.clientId,
            values: entity.values,
            name: entity.name,
          },
        })
        .afterClosed()
        .subscribe();
    } else {
      this.dialog
        .open(TimeSeriesBarChartDialogComponent, {
          width: '80%',
          data: {
            location: entity.location,
            entityId: entity.timeseriesId,
            clientId: this.clientId,
            values: entity.values,
            name: entity.name,
            granularity: entity.granularity,
            quantityId: entity.quantityId,
            chartProps: this.generateChartProperties(entity.granularity),
          },
        })
        .afterClosed()
        .subscribe();
    }
  }

  generateChartProperties(granularity: any) {
    let chartProps = {
      yearlyTabs: [],
      floor: 1,
      ceil: 365,
      minRange: 0,
      maxRange: 365,
      showRange: true,
      draggableRange: true,
    };

    /* istanbul ignore else */
    if (granularity === 'Monthly') {
      chartProps = {
        ...chartProps,
        ceil: 12,
        minRange: 0,
        maxRange: 12,
        showRange: false,
      };
    } else if (granularity === 'Yearly') {
      chartProps = {
        ...chartProps,
        minRange: 0,
        showRange: false,
      };
    }

    return chartProps;
  }
}

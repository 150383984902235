import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

import { MenuButtonOption } from './fluids-menu-button.model';

@Component({
  selector: 'sso-fluids-menu-button',
  templateUrl: './fluids-menu-button.component.html',
  styleUrls: ['./fluids-menu-button.component.scss'],
})
export class FluidsMenuButtonComponent<T> {
  @Input() disabled: boolean;
  @Input() label: string;
  @Input() iconColor: ThemePalette;
  @Input() options: Array<MenuButtonOption<T>>;

  @Output() selectOption = new EventEmitter<T>();

  onClick(id: T) {
    this.selectOption.emit(id);
  }
}

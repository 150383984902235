<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
  <h2>{{ toastRef.data.title }}</h2>
  <span> {{ toastRef.data.message }} </span>
  <div
    class="button-container"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="10px"
  >
    <a
      class="sso-clickable sso-text-blue"
      data-testid="action-click"
      (click)="handleActionClick()"
    >
      {{ toastRef.data.labelAction }}
    </a>
    <a
      class="sso-clickable sso-text-blue"
      data-testid="cancel-click"
      (click)="handleCancelClick()"
    >
      Cancel
    </a>
  </div>
</div>

/**
 * @param baseUrl - the domain url
 * @param endpoint - the structure of the path after the domain url
 * @param parameters - the ids for the path
 * For better understanding, refer to the spec file for this util.
 */
export const generateEndpoint = (
  baseUrl: string,
  endpoint: string,
  ...parameters: Array<any>
): string => {
  let index = 0;
  const generated = endpoint.replace(/:[A-Za-z0-9]+/g, (match: string) => {
    const newValue =
      typeof parameters[index] !== undefined ? parameters[index] : match;
    index++;
    return newValue;
  });
  return `${baseUrl}api/${generated}`;
};

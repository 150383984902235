import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { groupBy } from 'lodash';
import { BehaviorSubject, map, mergeMap } from 'rxjs';
import {
  ClientDataEntity,
  ClientFacadeService,
  ScalingFactor,
} from 'ssotool-app/+client';
import { ColumnDefModel } from 'ssotool-app/+entities/components/entity-common-table';

type ScalingFactorTableData = Array<
  Record<
    keyof ScalingFactor | ScalingFactor['archetypeName'],
    string | ScalingFactor['value']
  >
>;

@UntilDestroy()
@Component({
  selector: 'sso-scaling-factor-list',
  templateUrl: './scaling-factor-list.component.html',
  styleUrls: ['./scaling-factor-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScalingFactorListComponent {
  private _columnDefinitions = new BehaviorSubject<ColumnDefModel[]>([]);
  private readonly FIXED_COLUMNS: ColumnDefModel[] = [
    {
      name: 'siteName',
      label: 'Site Name',
      mode: 'event',
    },
    {
      name: 'archetypeName',
      label: 'Archetype',
      mode: null,
    },
  ];

  columnDefinitions$ = this._columnDefinitions.asObservable();

  scalingFactors$ = this.clientFacadeService.selectActiveClientData$.pipe(
    untilDestroyed(this),
    mergeMap((activeClientData) => {
      return this.clientFacadeService.selectScalingFactors$(
        activeClientData.clientId,
      );
    }),
    map((scalingFactors) => {
      return this.mapToTableData(scalingFactors);
    }),
  );

  constructor(private clientFacadeService: ClientFacadeService) {}

  private mapToTableData(
    scalingFactors: ClientDataEntity<ScalingFactor>,
  ): ScalingFactorTableData {
    const columns: Record<
      ScalingFactor['name'],
      ScalingFactor['scalingFactorId']
    > = {};
    const groupedBySite = groupBy(scalingFactors, 'siteId');
    const mappedData = Object.entries(groupedBySite).map(
      ([siteId, details]) => {
        return {
          id: siteId,
          siteName: details[0].siteName,
          archetypeName: details[0].archetypeName,
          archetypeId: details[0].archetypeId,
          ...details.reduce((factorList, detail) => {
            if (!columns[detail.name]) {
              columns[detail.name] = detail.scalingFactorId;
            }
            return {
              ...factorList,
              [detail.name]: detail.value,
            };
          }, {}),
        };
      },
    );
    this.updateColumns(columns);
    return mappedData;
  }

  private updateColumns(columns: Record<string, string>) {
    this._columnDefinitions.next(
      this.FIXED_COLUMNS.concat(
        Object.keys(columns).map((column) => ({
          name: column,
          label: column,
          mode: null,
        })),
      ),
    );
  }
}

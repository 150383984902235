import { CampaignStorage } from 'ssotool-app/+campaign/store/campaign.model';
import { YearlyValuesMapper } from 'ssotool-shared';

import { CampaignBaseMapper } from './campaign-base.mapper';

export class StorageMapper {
  static mapToFrontend = (data: any): CampaignStorage =>
    !!data
      ? ({
          ...CampaignBaseMapper.mapToFrontend(data),
          scale: data.scale,
          fluidId: data.fluidId,
          processId: data.processId,
          minCapacity: YearlyValuesMapper.mapToFrontend(data.minCapacity),
          maxCapacity: YearlyValuesMapper.mapToFrontend(data.maxCapacity),
          epRatio: YearlyValuesMapper.mapToFrontend(data.epRatio),
          maxDod: YearlyValuesMapper.mapToFrontend(data.maxDod),
          roundtripEfficiency: YearlyValuesMapper.mapToFrontend(
            data.roundtripEfficiency,
          ),
          technicalLife: YearlyValuesMapper.mapToFrontend(data.technicalLife),
          depreciationTime: YearlyValuesMapper.mapToFrontend(
            data.depreciationTime,
          ),
          implementationTime: YearlyValuesMapper.mapToFrontend(
            data.implementationTime,
          ),
          buildCo2Scope3Emissions1: YearlyValuesMapper.mapToFrontend(
            data.buildCo2Scope3Emissions,
          ),
          buildCo2Scope3Emissions2: YearlyValuesMapper.mapToFrontend(
            data.buildCo2Scope3Emissions2,
          ),
          indivisibleCo2Scope3Emissions: YearlyValuesMapper.mapToFrontend(
            data.indivisibleCo2Scope3Emissions,
          ),
          buildCost1: YearlyValuesMapper.mapToFrontend(data.buildCost),
          buildCost2: YearlyValuesMapper.mapToFrontend(data.buildCost2),
          indivisibleCost: YearlyValuesMapper.mapToFrontend(
            data.indivisibleCost,
          ),
          foM1: YearlyValuesMapper.mapToFrontend(data.foM),
          foM2: YearlyValuesMapper.mapToFrontend(data.foM2),
          foMPerInstall: YearlyValuesMapper.mapToFrontend(data.foMPerInstall),
        } as CampaignStorage)
      : ({} as CampaignStorage);

  static mapToBackend = (data: CampaignStorage) =>
    !!data
      ? {
          ...CampaignBaseMapper.mapToBackend(data),
          scale: data.scale,
          fluidId: data.fluidId,
          processId: data.processId,
          minCapacity: YearlyValuesMapper.mapToBackend(data.minCapacity),
          maxCapacity: YearlyValuesMapper.mapToBackend(data.maxCapacity),
          epRatio: YearlyValuesMapper.mapToBackend(data.epRatio),
          maxDod: YearlyValuesMapper.mapToBackend(data.maxDod),
          roundtripEfficiency: YearlyValuesMapper.mapToBackend(
            data.roundtripEfficiency,
          ),
          technicalLife: YearlyValuesMapper.mapToBackend(data.technicalLife),
          depreciationTime: YearlyValuesMapper.mapToBackend(
            data.depreciationTime,
          ),
          implementationTime: YearlyValuesMapper.mapToBackend(
            data.implementationTime,
          ),
          buildCo2Scope3Emissions: YearlyValuesMapper.mapToBackend(
            data.buildCo2Scope3Emissions1,
          ),
          buildCo2Scope3Emissions2: YearlyValuesMapper.mapToBackend(
            data.buildCo2Scope3Emissions2,
          ),
          indivisibleCo2Scope3Emissions: YearlyValuesMapper.mapToBackend(
            data.indivisibleCo2Scope3Emissions,
          ),
          buildCost: YearlyValuesMapper.mapToBackend(data.buildCost1),
          buildCost2: YearlyValuesMapper.mapToBackend(data.buildCost2),
          indivisibleCost: YearlyValuesMapper.mapToBackend(
            data.indivisibleCost,
          ),
          foM: YearlyValuesMapper.mapToBackend(data.foM1),
          foM2: YearlyValuesMapper.mapToBackend(data.foM2),
          foMPerInstall: YearlyValuesMapper.mapToBackend(data.foMPerInstall),
        }
      : {};
}

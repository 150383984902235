import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { createErrorObject } from 'ssotool-shared';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { RoadmapVisualizationAPIService } from './visualization-api.service';
import { RoadmapVisualizationActions } from './visualization.actions';
import { ToastService } from 'ssotool-app/shared/services/toast/toast.service';

@Injectable()
export class RoadmapVisualizationEffects {
  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoadmapVisualizationActions.deleteAction),
      mergeMap((action) =>
        this.roadmapVisualizationService
          .delete(action.clientId, action.roadmapId, action.visualizationId)
          .pipe(
            map(() => {
              return RoadmapVisualizationActions.deleteSuccess({
                data: {
                  clientId: action.clientId,
                  roadmapId: action.roadmapId,
                  visualizationId: action.visualizationId,
                  visualizationName: action.name,
                },
                message: 'Delete success',
              });
            }),
            catchError((error) =>
              of(
                RoadmapVisualizationActions.deleteFailed({
                  data: action,
                  message: 'Delete Failed',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  deleteSuccess$ = createEffect(
    () => this.actions$.pipe(ofType(RoadmapVisualizationActions.deleteSuccess)),
    { dispatch: false },
  );

  deleteFailed$ = createEffect(
    () => this.actions$.pipe(ofType(RoadmapVisualizationActions.deleteFailed)),
    { dispatch: false },
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoadmapVisualizationActions.get),
      mergeMap((action) =>
        this.roadmapVisualizationService
          .get(action.clientId, action.roadmapId, action.visualizationId)
          .pipe(
            map((response) =>
              RoadmapVisualizationActions.getSuccess({
                data: response,
              }),
            ),
            catchError((_) =>
              of(
                RoadmapVisualizationActions.getFailed({
                  clientId: action.clientId,
                  roadmapId: action.roadmapId,
                  visualizationId: action.visualizationId,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getSuccess$ = createEffect(
    () => this.actions$.pipe(ofType(RoadmapVisualizationActions.getSuccess)),
    { dispatch: false },
  );

  getFailed$ = createEffect(
    () => this.actions$.pipe(ofType(RoadmapVisualizationActions.getFailed)),
    { dispatch: false },
  );

  getList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoadmapVisualizationActions.getList),
      mergeMap((action) =>
        this.roadmapVisualizationService
          .getList(action.clientId, action.roadmapId)
          .pipe(
            map((response) =>
              RoadmapVisualizationActions.getListSuccess({
                data: {
                  data: response,
                  clientId: action.clientId,
                  roadmapId: action.roadmapId,
                },
                message: 'Get roadmap visualization list success!',
              }),
            ),
            catchError((error) =>
              of(
                RoadmapVisualizationActions.getListFailure({
                  ...createErrorObject(
                    error,
                    'Get roadmap visualization list error',
                  ),
                  data: action,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getListSuccess$ = createEffect(
    () =>
      this.actions$.pipe(ofType(RoadmapVisualizationActions.getListSuccess)),
    { dispatch: false },
  );

  getListFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoadmapVisualizationActions.getListFailure),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoadmapVisualizationActions.save),
      mergeMap((action) =>
        this.roadmapVisualizationService
          .save(action.name, action.clientId, action.roadmapId, action.details)
          .pipe(
            map((response) =>
              RoadmapVisualizationActions.saveSuccess({
                data: response,
                message: 'Successfully saved visualization',
              }),
            ),
            catchError((err) =>
              of(
                RoadmapVisualizationActions.saveFailed({
                  name: action.name,
                  message:
                    (err.error || {}).error || 'Failed saving visualization',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  saveSuccess$ = createEffect(
    () => this.actions$.pipe(ofType(RoadmapVisualizationActions.saveSuccess)),
    {
      dispatch: false,
    },
  );

  saveFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoadmapVisualizationActions.saveFailed),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    {
      dispatch: false,
    },
  );

  constructor(
    private actions$: Actions,
    private roadmapVisualizationService: RoadmapVisualizationAPIService,
    private snackbar: ToastService,
  ) {}
}

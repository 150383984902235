import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { ResultExportEntityActions } from './result-export.actions';
import {
  PartialResultExportModel,
  ResultExportStatus,
} from './result-export.model';

export const RESULT_EXPORT_FEATURE_NAME = 'ResultExport';

export type ResultExportEntityModel = PartialResultExportModel &
  Partial<
    Readonly<{
      loaded: boolean;
      loading: boolean;
    }>
  >;

export interface ResultExportState
  extends EntityState<ResultExportEntityModel> {
  loading: boolean;
  loaded: boolean;
}

export const resultExportAdapter: EntityAdapter<ResultExportEntityModel> =
  createEntityAdapter<ResultExportEntityModel>({
    selectId: (exportModel) => exportModel.exportId,
    sortComparer: (
      importData1: ResultExportEntityModel,
      importData2: ResultExportEntityModel,
    ) => {
      const date1 = new Date(importData1?.createdAt);
      const date2 = new Date(importData2?.createdAt);
      return date2.getTime() - date1.getTime();
    },
  });

export const initialResultExportState: ResultExportState =
  resultExportAdapter.getInitialState({
    ids: [],
    entities: {},
    loading: false,
    loaded: false,
  });

export const ResultExportReducer = createReducer(
  initialResultExportState,
  on(ResultExportEntityActions.getList, (state) => ({
    ...state,
    loading: true,
  })),
  on(ResultExportEntityActions.getListSuccess, (state, { data }) =>
    resultExportAdapter.upsertMany(
      data.map<ResultExportEntityModel>((exportModel) => ({
        ...exportModel,
        loaded: true,
        loading: false,
      })),
      { ...state, loaded: true, loading: false },
    ),
  ),
  on(ResultExportEntityActions.getListFailure, (state) => ({
    ...state,
    loading: false,
    loaded: false,
  })),
  on(ResultExportEntityActions.createExportDetailedResults, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    ResultExportEntityActions.createExportDetailedResultsSuccess,
    (state, { data }) =>
      resultExportAdapter.addOne(
        { ...data, loaded: true, loading: false },
        { ...state, loading: false },
      ),
  ),
  on(ResultExportEntityActions.createExportDetailedResultsFailure, (state) => ({
    ...state,
    loading: false,
  })),

  on(ResultExportEntityActions.createExportSummaryResults, (state) => ({
    ...state,
    loading: true,
  })),

  on(
    ResultExportEntityActions.createExportSummaryResultsSuccess,
    (state, { data }) =>
      resultExportAdapter.addOne(
        { ...data, loaded: true, loading: false },
        { ...state, loading: false },
      ),
  ),
  on(ResultExportEntityActions.createExportSummaryResultsFailure, (state) => ({
    ...state,
    loading: false,
  })),

  on(ResultExportEntityActions.updateExportDetails, (state, { data }) => {
    const finalData =
       [ResultExportStatus.CANCELLED, ResultExportStatus.SUCCESS].includes(state.entities[data.exportId].status)
        ? { ...state.entities[data.exportId], loading: false, exporting: false }
        : data;
    return resultExportAdapter.upsertOne(finalData, state);
  }),
  on(ResultExportEntityActions.cancelExport, (state) => ({
    ...state,
    loading: true,
  })),
  on(ResultExportEntityActions.cancelExportSuccess, (state, {}) => ({
    ...state,
    loading: false,
  })),
  on(ResultExportEntityActions.cancelExportFailure, (state) => ({
    ...state,
    loading: false,
  })),
);

export const ResultExportEntityStore = {
  featureName: RESULT_EXPORT_FEATURE_NAME,
  reducer: ResultExportReducer,
};

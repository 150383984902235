import { INDICATOR_OPTIONS } from 'ssotool-app/app.references';
import { Coerce } from 'ssotool-app/shared/helpers';
import { isFeatureEnabled } from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';
import { iterAssets } from 'ssotool-core/utils/object-util';
import { FilterOptions } from 'ssotool-shared/modules/filters';

import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  Client,
  ClientDataEntity,
  ClientDataInfo,
  ClientFilter,
  ClientFilterOptionsQuery,
  ClientStatus,
  ClientTreeNode,
  ClientTreeRecord,
  GroupType,
} from './client.model';
import { clientAdapter, clientFeatureKey, ClientState } from './client.reducer';

export const { selectIds, selectEntities, selectAll, selectTotal } =
  clientAdapter.getSelectors();

export const clientFeatureState =
  createFeatureSelector<ClientState>(clientFeatureKey);

export const clientListEntities = createSelector(
  clientFeatureState,
  selectEntities,
);

export const clientList = createSelector(clientFeatureState, selectAll);

export const loading = createSelector(
  clientFeatureState,
  (state) => state.loading,
);

export const loaded = createSelector(
  clientFeatureState,
  (state) => state.loaded,
);

export const sharing = createSelector(
  clientFeatureState,
  (state) => state.sharing,
);

export const activeClientId = createSelector(
  clientFeatureState,
  (state) => state.selectedClientId,
);

export const errorInSandbox = createSelector(
  clientFeatureState,
  (state) => state.errorInSandbox,
);

export const archivedClients = createSelector(clientListEntities, (entities) =>
  Coerce.getObjValues(entities).filter((entity) => entity.isArchive),
);

// custom selectors
export const selectDataIneligibleForComputation = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities) => entities[clientId]?.ineligibleForComputation,
  );

export const selectIsIneligibleForComputation = (
  clientId: string | number,
  includeCampaigns: boolean,
) =>
  createSelector(
    selectDataIneligibleForComputation(clientId),
    (entity: any) => {
      return (
        !!entity &&
        ((includeCampaigns ? !!entity.campaigns : false) ||
          !!entity.flowPrices ||
          !!entity.flowVolumes)
      );
    },
  );

export const selectIneligibleCampaignsForComputation = (clientId: string) =>
  createSelector(
    selectDataIneligibleForComputation(clientId),
    (entity: any) => entity?.campaigns || [],
  );

export const selectClientPermission = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities) => entities[clientId].permissions,
  );

export const selectClient = (clientId: string | number) =>
  createSelector(clientListEntities, (entities) => {
    return entities[clientId];
  });

export const selectClientLoaded = (clientId: string | number) =>
  createSelector(clientListEntities, (entities) => {
    return Coerce.toObject(entities[clientId]).loaded;
  });

export const selectRunSettings = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entity) => entity?.[clientId]?.runSettings,
  );

export const hasClients = createSelector(
  clientFeatureState,
  (state) => !!Coerce.getObjKeys(state.entities).length,
);

export const selectClientList = (status: ClientStatus) =>
  createSelector(clientFeatureState, (state) => {
    return filterClientsByStatus(Coerce.getObjValues(state.entities), status);
  });

const filterClientsByStatus = (
  clients: Client[],
  status: ClientStatus,
): Client[] =>
  clients.filter((client) =>
    status === 'archive' ? !!client?.isArchive : !!!client?.isArchive,
  );

/**
 * Filter clients by property
 * Props:
 *  property - the property of a client to be used as a key
 *  values - the values needed to be present in the client in order to be filtered
 */
export const selectFilteredClients = (props: ClientFilter) =>
  createSelector(clientList, (entities: Client[]): Client[] => {
    if (!props || !!!Object.entries(props).length) {
      return entities;
    }

    return entities.filter((entity: Client) =>
      Object.entries(props).reduce(
        (test: Boolean, [property, filterValues]: [string, string[]]) =>
          test &&
          (!!!filterValues?.length ||
            filterValues.includes(entity?.[property])),
        true,
      ),
    );
  });

/**
 * Get all the filter options for clients by properties
 * Props:
 *  keys - the property of Client where the options will be sourced
 */
export const selectClientFilterOptions = (props: ClientFilterOptionsQuery) =>
  createSelector(clientList, (clients: Client[]): FilterOptions => {
    const filterOptions = props.keys.reduce(
      (acc: FilterOptions, curr: string) => {
        const values = clients.reduce(
          (clientValue: string[], client: Client) => {
            if (client.hasOwnProperty(curr)) {
              clientValue.push(client[curr]);
            }
            return clientValue;
          },
          [],
        );

        acc[curr] = values;
        return acc;
      },
      {},
    );
    return removeDuplicateOptions(filterOptions);
  });

/**
 * Removes duplicate options for each category
 * @param options
 * @returns new FilterOptions without duplicate options
 */
const removeDuplicateOptions = (options: FilterOptions): FilterOptions => {
  return Object.entries(options).reduce(
    (
      nonDuplicateOptions: FilterOptions,
      [property, values]: [string, string[]],
    ) => {
      nonDuplicateOptions[property] = Array.from(new Set(values));
      return nonDuplicateOptions;
    },
    {},
  );
};

export const uniqueOwnerIds = createSelector(clientList, (clients) => {
  const ownerIds = [];
  clients.forEach((client) => ownerIds.push(client.owner));
  return [...new Set(ownerIds)];
});

export const selectCompanies = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) =>
      entities[clientId]?.clientData?.companies || {},
  );

export const selectGeos = (clientId: string | number) =>
  createSelector(clientListEntities, (entities: { [key: string]: Client }) => {
    const geos = entities[clientId]?.clientData?.geos || {};
    return isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE)
      ? removeSites(geos)
      : geos;
  });

export const selectArchetypes = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) =>
      entities[clientId]?.clientData?.archetypes || {},
  );

export const selectSites = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) =>
      entities[clientId]?.clientData?.sites || {},
  );

export const selectGeosAndSites = (clientId: string | number) =>
  createSelector(clientListEntities, (entities: { [key: string]: Client }) => {
    let geos = entities[clientId]?.clientData?.geos || {};
    return isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE)
      ? {
          ...removeSites(geos),
          ...(entities[clientId]?.clientData?.sites || {}),
        }
      : geos;
  });

const removeSites = (geos: ClientDataInfo['geos']): ClientDataInfo['geos'] => {
  return Object.entries(geos).reduce((acc, [key, value]) => {
    if (!value.location) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export const selectSectors = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) =>
      entities[clientId]?.clientData?.sectors || {},
  );

export const selectQuantities = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) =>
      entities[clientId]?.clientData?.quantities || {},
  );

export const selectFluids = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) =>
      entities[clientId]?.clientData?.fluids || {},
  );

export const selectFluidIds = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) =>
      entities[clientId]?.clientData?.fluidIds || {},
  );

export const selectProcesses = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) =>
      entities[clientId]?.clientData?.processes || {},
  );

export const selectConverters = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) =>
      entities[clientId]?.clientData?.converters || {},
  );

export const selectMetrics = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) =>
      entities[clientId]?.clientData?.metrics || {},
  );

export const selectScalingFactors = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) =>
      entities[clientId]?.clientData?.scalingFactors || {},
  );

export const selectTimeSeries = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) =>
      entities[clientId]?.clientData?.timeseries || {},
  );

export const selectTrajectories = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) =>
      entities[clientId]?.clientData?.trajectories || {},
  );

export const selectTrajectoryVariations = (clientId: string | number) =>
  createSelector(clientListEntities, (entities: Record<string, Client>) => {
    return entities[clientId]?.clientData?.trajectoryVariations;
  });
export const selectConstraints = (clientId: string | number) =>
  createSelector(clientListEntities, (entities: { [key: string]: Client }) => {
    const constraints = entities[clientId]?.clientData?.constraints || {};
    const processConstraints = {};
    Object.entries(constraints).forEach(([key, value]) => {
      const data = Coerce.getObjKeys(value).length
        ? {
            ...value,
            compositeKey:
              `${value.name}-${value.scenarioName}-${value.indicator}-${value.geoId}-` +
              `${value.companyEntityId}-${value.sectorId}` +
              `-${value.processId ? value.processId : ''}-${
                value.fluidId ? value.fluidId : ''
              }-${value.quantityId}`,
            indicator: INDICATOR_OPTIONS[value.indicator],
          }
        : {};
      processConstraints[key] = data;
    });
    return processConstraints;
  });

export const selectFlowPrices = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) =>
      entities[clientId]?.clientData?.flowPrices || {},
  );

export const selectFlowVolumes = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) =>
      entities[clientId]?.clientData?.flowVolumes || {},
  );

export const selectRenewables = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) =>
      entities[clientId]?.clientData?.renewables || {},
  );

// existingStorages
export const selectStorages = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) =>
      entities[clientId]?.clientData?.storages || {},
  );

// existingContracts
export const selectExistingContracts = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) =>
      entities[clientId]?.clientData?.contracts || {},
  );

export const selectCompanyHierarchy = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) =>
      entities[clientId]?.clientData?.companyHierarchy || {},
  );

export const selectGeoHierarchy = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) =>
      entities[clientId]?.clientData?.geoHierarchy || {},
  );

export const selectGeoHierarchyWOSites = (clientId: string | number) =>
  createSelector(clientListEntities, (entities: { [key: string]: Client }) => {
    const geoHierarchy = entities[clientId]?.clientData?.geoHierarchy || {};
    const { Site, ...geoHierarchyWOSites } = geoHierarchy;
    return geoHierarchyWOSites;
  });

export const selectGeoHierarchySitesOnly = (clientId: string | number) =>
  createSelector(clientListEntities, (entities: { [key: string]: Client }) => ({
    Site: entities[clientId]?.clientData?.geoHierarchy.Site || {},
  }));

export const selectClientLoading = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) => entities[clientId]?.loading,
  );

export const selectShareDataLoading = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) =>
      entities[clientId]?.shareDataLoading,
  );

export const selectDataLoading = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) => entities[clientId]?.dataLoading,
  );

export const selectDataLoaded = (clientId: string | number) =>
  createSelector(
    clientListEntities,
    (entities: { [key: string]: Client }) => entities[clientId]?.dataLoaded,
  );

export const getActiveClientData = createSelector(
  clientFeatureState,
  clientListEntities,
  (state, entities) =>
    state.selectedClientId ? entities[state.selectedClientId] : null,
);

export const selectExistingAssets = (clientId: string | number) =>
  createSelector(
    selectConverters(clientId),
    selectRenewables(clientId),
    selectStorages(clientId),
    selectExistingContracts(clientId),
    (converters, renewables, storages, contracts) => {
      const result = {};
      iterAssets<ClientDataEntity>(result, renewables);
      iterAssets<ClientDataEntity>(result, storages);
      iterAssets<ClientDataEntity>(result, converters);
      iterAssets<ClientDataEntity>(result, contracts);
      return result || {};
    },
  );

/**
 * Selector for groups (i.e. company, geography).
 */
export const selectGroups = (props: {
  clientId: string | number;
  groupKey: GroupType;
}) =>
  createSelector(clientListEntities, (entities: { [key: string]: Client }) => {
    const { clientId, groupKey } = props || {};
    if (
      !clientId ||
      !groupKey ||
      !entities[clientId] ||
      !entities[clientId].clientData
    ) {
      return undefined;
    }
    return entities[clientId].clientData[groupKey];
  });

export const selectGeographyGroups = (clientId: string | number) =>
  createSelector(clientListEntities, (entities: Record<string, Client>) => {
    const clientData = entities[clientId]?.clientData;
    const geographies = clientData?.geos;
    const geographyGroups = Object.entries(
      clientData?.geographyGroups || {},
    ).reduce((acc, [key, group]) => {
      const hIds = group?.geoIds.map((geoId) => geographies[geoId]?.hId);
      const names = group?.geoIds.map((geoId) => geographies[geoId]?.name);
      acc[key] = {
        ...clientData?.geographyGroups[key],
        hIds,
        names,
      };
      return acc;
    }, {});
    return geographyGroups;
  });

const getChildren = (data: ClientTreeRecord): ClientTreeNode[] => {
  return Coerce.getObjValues(data).map((a) => ({
    name: a.name,
    id: a.geoId,
    children: getChildren(a?.children),
  }));
};

export const selectRootGeography = (clientId: string | number) =>
  createSelector(clientListEntities, (entities) => {
    return entities[clientId]?.clientData?.geoTree?.tree['000'];
  });

export const selectGeographyTree = (
  props: Record<'clientId' | 'geoId', string | number>,
) =>
  createSelector(clientListEntities, (entities: Record<string, Client>) => {
    const treeReference = entities[props.clientId]?.clientData?.geoTree;
    const groups = entities[props.clientId]?.clientData?.geographyGroups;
    const groupData = groups?.[props.geoId];

    if (groupData) {
      return [
        {
          name: groupData.name,
          id: groupData.groupId,
          children: groupData.geoIds.map((id) => {
            const hIds = treeReference.hids[id];
            const selected = hIds.reduce<ClientTreeRecord>(
              (acc, curr, index) =>
                index + 1 === hIds.length ? acc[curr] : acc[curr]?.children,
              treeReference.tree,
            );

            return {
              name: selected.name,
              id: selected.geoId,
              children: getChildren(selected.children),
            };
          }),
        },
      ];
    } else {
      const hIds = treeReference.hids[props.geoId];
      const selected = hIds.reduce<ClientTreeRecord>(
        (acc, curr, index) =>
          index + 1 === hIds.length ? acc[curr] : acc[curr]?.children,
        treeReference.tree,
      );

      return [
        {
          name: selected.name,
          id: selected.geoId,
          children: getChildren(selected.children),
        },
      ];
    }
  });

export const selectGeoFlatMemberNames = (clientId: string | number) =>
  createSelector(clientListEntities, (entities: Record<string, Client>) => {
    return entities[clientId]?.clientData?.geoFlatMemberNames;
  });

export const selectCompanyFlatMemberNames = (clientId: string | number) =>
  createSelector(clientListEntities, (entities: Record<string, Client>) => {
    return entities[clientId]?.clientData?.companyFlatMemberNames;
  });

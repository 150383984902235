import { createFeatureSelector, createSelector } from '@ngrx/store';

import { LibraryImportState, libraryImportAdapter, libraryImportFeatureKey } from './library-import.reducer';

const { selectEntities, selectAll } = libraryImportAdapter.getSelectors();

export const libraryImportFeatureState = createFeatureSelector<LibraryImportState>(libraryImportFeatureKey);

export const libraryImportEntities = createSelector(libraryImportFeatureState, selectEntities);

export const libraryImportList = createSelector(libraryImportFeatureState, selectAll);

export const loading = createSelector(libraryImportFeatureState, (state) => state.loading);

// custom selectors

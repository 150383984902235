import { Observable, of } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { ClientFacadeService, ExistingRenewable } from 'ssotool-app/+client';
import { Coerce } from 'ssotool-shared';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DetailsDrawerService } from '@oculus/components/details-drawer';
import {
  ExistingRenewableDrawerComponent,
  ExistingRenewableDrawerData,
  ExistingRenewableDrawerMode,
} from 'ssotool-app/+entities/components/drawer';
import { isFeatureEnabled } from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';

const COLUMNS = [
  { name: 'name', mode: 'event' },
  { name: 'geography' },
  { name: 'entity' },
  { name: 'sector' },
  { name: 'process' },
  { name: 'fluid' },
];
const COLUMNS_V7 = [
  { name: 'name', mode: 'event' },
  {
    name: 'geography',
    label: 'Site/Geography',
  },
  { name: 'scalingFactorName', label: 'Scaling Factor' },
  { name: 'process' },
  { name: 'fluid' },
];
@Component({
  selector: 'sso-existing-renewable-list',
  templateUrl: './existing-renewable-list.component.html',
  styleUrls: ['./existing-renewable-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExistingRenewableListComponent implements OnInit {
  /** Template variables */
  entities$: Observable<any[]> = of([]);
  loading$: Observable<boolean> = of(false);
  clientId = '';
  columnDefs = isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE)
    ? COLUMNS_V7
    : COLUMNS;

  constructor(
    private clientFacade: ClientFacadeService,
    private drawerService: DetailsDrawerService,
  ) {}

  ngOnInit(): void {
    this.clientFacade.selectActiveClientData$.pipe().subscribe((client) => {
      /* istanbul ignore else */
      if (client && client.clientId) {
        this.clientId = client.clientId;
        this.entities$ = this.clientFacade
          .selectRenewables$(client.clientId)
          .pipe(
            skipWhile((data) => !data),
            map((data) =>
              Coerce.getObjValues(data).map((renewable) => ({
                ...renewable,
                name: renewable.name,
                geography: this.getGeoName(renewable),
                scalingFactorName: renewable.scalingFactorName,
                entity: renewable.companyName,
                sector: renewable.sectorName,
                process: renewable.processName,
                fluid: renewable.fluidName,
              })),
            ),
          );
        this.loading$ = this.clientFacade.dataLoading$(client.clientId);
      }
    });
  }

  private getGeoName(renewable: ExistingRenewable): string {
    return renewable.siteName || renewable.geoName;
  }

  openDrawer(existingRenewable: ExistingRenewable) {
    this.drawerService.open<
      ExistingRenewableDrawerComponent,
      ExistingRenewableDrawerData,
      unknown
    >(ExistingRenewableDrawerComponent, {
      data: {
        mode: ExistingRenewableDrawerMode.VIEW,
        clientId: this.clientId,
        existingRenewable,
        readonly: true,
      },
      width: '50vw',
      closeIcon: false,
      overrideClose: true,
    });
  }
}

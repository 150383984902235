import { Injectable } from '@angular/core';
import {
  ELLIPSE_CURVE_COLORS,
  ENGIE_CURVE_COLORS,
  OTHER_COLORS,
} from 'ssotool-app/app.references';

const ColorScheme = require('color-scheme');

@Injectable()
export class ColorSchemeService {
  constructor() {}

  generateAdjacentScheme(baseInHex: string, length?: number): string[] {
    if (!baseInHex) {
      return [];
    }

    let generatedColors = [];

    generatedColors = this.generateScheme(baseInHex);

    // add handling for big data
    if (length) {
      let _length = length;
      generatedColors = [];
      while (_length > 0) {
        generatedColors.push(...this.generateScheme(baseInHex));
        _length -= 12;
      }
    }

    if (generatedColors.length > length) {
      generatedColors.slice(0, length - 1);
    }

    return generatedColors.map((color) => `#${color}`);
  }

  getBaseColors(): string[] {
    return [...ELLIPSE_CURVE_COLORS, ...ENGIE_CURVE_COLORS, ...OTHER_COLORS];
  }

  private generateScheme(baseInHex: string) {
    const scheme = new ColorScheme();
    return scheme
      .from_hex(this.sanitizeHexCode(baseInHex))
      .scheme('analogic')
      .distance(0.25)
      .colors();
  }

  private sanitizeHexCode(hexCode: string): string {
    return hexCode.replace('#', '');
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'sso-sticky-horizontal-scroll',
  templateUrl: './sticky-horizontal-scroll.component.html',
  styleUrls: ['./sticky-horizontal-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StickyHorizontalScrollComponent {
  constructor() {}

  @Input() contentScrollId = 'contentScrollId';
  @Input() leftContentSpace = 0;
  @Input() rightContentSpace = 0;
  @Input() contentWidth = 0;
  @Input() contentScrollLeft = 0;
  @Input() contentColumnWidth = 0;
  @Output() scrollHorizontal = new EventEmitter();

  onScroll(event) {
    this.scrollHorizontal.emit(event);
  }
}

<div class="sso-page">
  <sso-spinner
    *ngIf="loading$ | async; else clientList"
    data-testid="client-list-spinner"
    caption="{{ 'Client.messages.loadingClientCount' | translate }}"
  ></sso-spinner>

  <ng-template #clientList>
    <sso-progress-indicator
      data-testid="sandbox-indicator"
      *ngIf="hasSandboxError$ | async"
      [message]="'Client.messages.sandboxCreationFailureBanner' | translate"
      icon="info"
      [hasProgressBar]="false"
    ></sso-progress-indicator>
    <sso-no-client *ngIf="noClients$ | async; else clientTable"></sso-no-client>

    <ng-template #clientTable>
      <div
        class="table-container"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxFill
      >
        <form class="client-form">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxFlex fxLayoutAlign="end center">
              <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
                <button
                  data-testid="create-client-button"
                  *ngIf="canCreateClient$ | ngrxPush"
                  mat-button
                  color="primary"
                  [disabled]="loading$ | async"
                  (click)="onAdd()"
                  fxLayoutAlign="center center"
                >
                  <mat-icon matPrefixa> add </mat-icon>
                  <b>{{ 'Client.labels.create' | translate | uppercase }}</b>
                </button>
              </div>

              <sso-search-input
                class="searchInput"
                [placeholder]="'Search Client'"
                [label]="'Search Client'"
                [formControl]="form.controls.searchInput"
              ></sso-search-input>
            </div>
          </div>
        </form>

        <sso-tab-group
          class="full-width"
          [selections]="tabOptions"
          color="primary"
          [formControl]="form.controls.selectedTab"
        >
        </sso-tab-group>

        <mat-table
          class="full-width sso-stripe-table no-row-divider"
          [dataSource]="dataSource$ | async"
        >
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>{{
              'Client.labels.name' | translate
            }}</mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="15px"
                *ngIf="!row.loading; else nameLoader"
              >
                <div
                  *ngIf="row?.isSandbox; else normalClientIcon"
                  class="client-icon client-icon-initial-size"
                  fxLayout="column"
                  fxLayoutAlign="center center"
                  [matTooltip]="
                    'Client.messages.sandboxClientTooltip' | translate
                  "
                >
                  <mat-icon class="sso-text-gray sso-color-java">
                    credit_card
                  </mat-icon>
                </div>
                <ng-template #normalClientIcon>
                  <div
                    class="client-icon client-icon-initial-size"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                  >
                    <mat-icon class="sso-text-gray">credit_card</mat-icon>
                  </div>
                </ng-template>
                <div
                  fxLayout="column"
                  fxLayoutAlign="start start"
                  fxLayoutGap="2px"
                >
                  <a
                    data-testid="select-client-button"
                    class="client-name sso-primary medium-font"
                    [ngClass]="{
                      'sso-clickable': !isClientArchived(row),
                      archived: isClientArchived(row)
                    }"
                    (click)="!isClientArchived(row) && onSelectClient(row)"
                    >{{ row?.name || '-' }}</a
                  >
                  <span class="client-description">
                    updated at {{ row?.updatedAt || '-' }}
                  </span>
                  <span class="client-description">
                    {{ row?.description || '-' }}
                  </span>
                </div>
              </div>
              <ng-template #nameLoader>
                <div
                  fxLayout="column"
                  fxLayoutAlign="start start"
                  fxLayoutGap="3px"
                >
                  <ngx-skeleton-loader
                    count="1"
                    animation="progress"
                    [theme]="loaderConfig.common"
                  ></ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    count="1"
                    animation="progress"
                    [theme]="loaderConfig.author"
                  ></ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    count="1"
                    animation="progress"
                    [theme]="loaderConfig.description"
                  ></ngx-skeleton-loader>
                </div>
              </ng-template>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="creator">
            <mat-header-cell *matHeaderCellDef>{{
              'Client.labels.creator' | translate
            }}</mat-header-cell>
            <mat-cell class="client-owner" *matCellDef="let row">
              <div *ngIf="!row?.loading; else commonLoader">
                {{ (references$ | async)['names'][row.owner] }}
              </div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>{{
              'Client.labels.actions' | translate
            }}</mat-header-cell>
            <mat-cell *matCellDef="let client">
              <div *ngIf="client?.isSandbox; else actions"></div>
              <ng-template #actions>
                <div
                  fxLayout="row"
                  fxLayoutAlign="start start"
                  fxLayoutGap="10px"
                  *ngIf="!client?.loading; else commonLoader"
                >
                  <div
                    class="client-icon-initial-size client-actions"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                    *ngIf="!isClientArchived(client)"
                  >
                    <button
                      data-testid="share-client-button"
                      mat-icon-button
                      (click)="onShare(client)"
                      type="button"
                      color="primary"
                    >
                      <mat-icon
                        [matBadge]="getBadge(client.clientId)"
                        matBadgeColor="accent"
                        >share</mat-icon
                      >
                    </button>
                    <span class="small-text client-hover">{{
                      'Generic.labels.share' | translate | uppercase
                    }}</span>
                  </div>

                  <div
                    class="client-icon-initial-size client-actions"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                    *ngIf="!isClientArchived(client)"
                  >
                    <button
                      data-testid="edit-client-button"
                      mat-icon-button
                      (click)="onEdit(client)"
                      type="button"
                      color="primary"
                      [disabled]="!isAdmin(client)"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                    <span
                      class="small-text client-hover"
                      [ngClass]="{
                        'mat-button-disabled': !isAdmin(client)
                      }"
                      >{{ 'Generic.labels.edit' | translate | uppercase }}</span
                    >
                  </div>

                  <div
                    class="client-icon-initial-size client-actions"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                    data-testid="duplicate-client"
                    *ngIf="!isClientArchived(client)"
                  >
                    <button
                      mat-icon-button
                      data-testid="duplicate-client-button"
                      (click)="onDuplicate(client)"
                      type="button"
                      color="primary"
                      [disabled]="(canDuplicate$(client) | async) === false"
                    >
                      <mat-icon>control_point_duplicate</mat-icon>
                    </button>
                    <span
                      class="small-text client-hover"
                      [ngClass]="{
                        'mat-button-disabled':
                          (canDuplicate$(client) | async) === false
                      }"
                      >{{
                        'Generic.labels.duplicate' | translate | uppercase
                      }}</span
                    >
                  </div>

                  <div
                    class="client-icon-initial-size client-actions"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                    *ngIf="!isClientArchived(client)"
                  >
                    <button
                      data-testid="archive-client-button"
                      mat-icon-button
                      (click)="onArchive(client)"
                      type="button"
                      color="primary"
                      [disabled]="!isAdmin(client)"
                    >
                      <mat-icon>archive</mat-icon>
                    </button>
                    <span
                      class="small-text client-hover"
                      [ngClass]="{
                        'mat-button-disabled': !isAdmin(client)
                      }"
                      >{{
                        'Generic.labels.archive' | translate | uppercase
                      }}</span
                    >
                  </div>

                  <div
                    class="client-icon-initial-size client-actions"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                    *ngIf="isClientArchived(client)"
                  >
                    <button
                      data-testid="unarchive-client-button"
                      mat-icon-button
                      (click)="onUnarchive(client)"
                      type="button"
                      color="primary"
                      [disabled]="!isAdmin(client)"
                    >
                      <mat-icon>unarchive</mat-icon>
                    </button>
                    <span
                      class="small-text client-hover"
                      [ngClass]="{
                        'mat-button-disabled': !isAdmin(client)
                      }"
                      >{{
                        'Generic.labels.unarchive' | translate | uppercase
                      }}</span
                    >
                  </div>
                  <div
                    class="client-icon-initial-size client-actions"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                    *ngIf="isClientArchived(client)"
                  >
                    <button
                      data-testid="delete-client-button"
                      mat-icon-button
                      (click)="onDelete(client)"
                      type="button"
                      class="client-delete"
                      [disabled]="!isAdmin(client)"
                    >
                      <mat-icon>delete_forever</mat-icon>
                    </button>
                    <span
                      class="small-text client-hover client-delete"
                      [ngClass]="{
                        'mat-button-disabled': !isAdmin(client)
                      }"
                      >{{
                        'Generic.labels.delete' | translate | uppercase
                      }}</span
                    >
                  </div>
                </div>
              </ng-template>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row
            class="sso-clickable"
            *matRowDef="let row; columns: displayedColumns"
            data-testid="client-row"
            [@rowAnimation]
          ></mat-row>
        </mat-table>
        <div
          class="spinner-container full-width"
          *ngIf="loading$ | async"
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
        >
          <mat-spinner strokeWidth="3" [diameter]="30"></mat-spinner>
          <span class="spinner-caption">{{
            'Generic.messages.loadingData' | translate
          }}</span>
        </div>
        <div
          *ngIf="isEmpty$ | async"
          class="full-width no-clients"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <span>{{
            'Client.messages.noSpecificClient'
              | translate : { status: currentClientStatus }
              | titlecase
          }}</span>
        </div>

        <button style="visibility: hidden" (click)="testSentry()">
          Test Sentry
        </button>
      </div>
    </ng-template>
  </ng-template>
  <mat-paginator
    [hidden]="(loading$ | async) || (noClients$ | async)"
    pageSize="10"
    [pageSizeOptions]="[10, 50, 100]"
  >
  </mat-paginator>
</div>

<ng-template #commonLoader>
  <ngx-skeleton-loader
    count="1"
    animation="progress"
    [theme]="loaderConfig.common"
  ></ngx-skeleton-loader>
</ng-template>

<div fxLayout="row" fxLayoutGap="10px">
  <span
    *ngFor="let level of levels; let idx = index"
    fxLayout="row"
    fxLayoutGap="10px"
    fxLayoutAlign="start center"
    class="breadcrumb"
  >
    <mat-icon *ngIf="idx !== 0">chevron_right</mat-icon>
    <span
      [class]="idx === levels.length - 1 ? 'current' : 'clickable'"
      [attr.data-testid]="getCrumbTestID(level, idx)"
      (click)="onBreadcrumbClick(level)"
      >{{ level.name | translate }}</span
    >
  </span>
</div>

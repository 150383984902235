import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  FLUID_DEMAND_FEATURE_NAME,
  FluidDemand,
  PartialFluidDemand,
} from './fluid-demand.model';
import { FluidDemandActions } from './fluid-demand.actions';

export interface FluidDemandState extends EntityState<PartialFluidDemand> {
  loading: boolean;
  loaded: boolean;
  error: string;
  message: string;
  clientId: string;
  data: FluidDemand;
}

export const fluidDemandAdapter: EntityAdapter<PartialFluidDemand> =
  createEntityAdapter<PartialFluidDemand>({});

export const initialFluidDemandState: FluidDemandState =
  fluidDemandAdapter.getInitialState({
    entities: {},
    loading: false,
    loaded: false,
    error: null,
    message: null,
    clientId: null,
    data: {
      fluid_demands: {},
      existing_contracts: {},
      existing_renewables: {},
    },
  });

const fluidDemandReducer = createReducer(
  initialFluidDemandState,
  //GET
  on(FluidDemandActions.get, (state) => ({
    ...state,
    loading: true,
  })),
  on(FluidDemandActions.getSuccess, (state, { data }) => ({
    ...state,
    data,
    loading: false,
    loaded: true,
  })),
  on(FluidDemandActions.getFailed, (state, { error, message }) => ({
    ...state,
    loading: false,
    loaded: true,
    error,
    message,
  })),
);

export const FluidDemandStore = {
  featureName: FLUID_DEMAND_FEATURE_NAME,
  reducer: fluidDemandReducer,
};

export function FluidDemandReducer(
  state: FluidDemandState | undefined,
  action: Action,
) {
  return fluidDemandReducer(state, action);
}

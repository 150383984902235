import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ClientFacadeService, Hierarchy } from 'ssotool-app/+client';
import { CompareVariationsService } from 'ssotool-app/+roadmap/services';
import {
  CurveEntity,
  HierarchicalFilter,
  HierarchichalCurveData,
  KPIType,
  NewKPIType,
  ResultFilterOptions,
  SummedHierarchicalCurveData,
} from 'ssotool-app/+roadmap/stores';
import { ChartFacadeService } from 'ssotool-app/+roadmap/stores/charts/charts.facade.service';
import {
  CompareVariationsFacadeService,
  CurveEntityVariation,
} from 'ssotool-app/+roadmap/stores/compare-variations/compare-variations-facade.service';
import {
  OTHER_LABEL,
  PIE_THRESHOLD_DISPLAY,
  STACKED_BAR_THRESHOLD_DISPLAY,
  WATERFALL_THRESHOLD_DISPLAY,
} from 'ssotool-app/app.references';
import {
  BarBlock,
  BarStack,
  Coerce,
  FilterConditionValue,
  PieMetadata,
  PieSlice,
  StackedBarMetadata,
  WaterfallBlock,
  WaterfallMetadata,
  WaterfallStack,
} from 'ssotool-shared';

import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
  CompareRoadmapWithVariationType,
  CompareVariationQueryParamMap,
} from '../compare-variations.model';
import {
  ChartInterpretation,
  ChartType,
  CurveFormModel,
} from './curves-compare-variations.models';
import {
  INCLUDE_SECONDARY_GROUPING,
  STACKED_BAR,
  YEARS,
} from './curves-compare-variations.references';

type GroupedSums = { [group: string]: { [year: string]: number } };
type GroupedWaterfallBlocksData = {
  [stack: string]: { [block: string]: number };
};
interface IndexedStacks {
  parentName?: string;
  name: string;
  stacks: BarStack[];
}

@Injectable()
export class CompareCurveInterpreter {
  waterfallBlockGrouping: { [kpi: string]: string } = {};
  private _blockKey = '';
  private _roadmapVarOrder: CompareRoadmapWithVariationType;
  private _isSingleRoadmap = new BehaviorSubject<boolean>(false);

  constructor(
    private compareFacade: CompareVariationsFacadeService,
    private compareService: CompareVariationsService,
    private chartFacadeService: ChartFacadeService,
    private clientFacadeService: ClientFacadeService,
    private route: ActivatedRoute,
  ) {
    this.initializeRoadmapVariationOrder();
  }

  // Used by pie chart
  private initializeRoadmapVariationOrder() {
    this.route.queryParamMap
      .pipe(
        map((paramMap: CompareVariationQueryParamMap) =>
          this.compareService.parseQueryParam(paramMap),
        ),
        // Filter added for queryParamMap jitters sending null on subsequent visits
        filter((compareData) => compareData !== null),
      )
      .subscribe((compareData) => {
        this._isSingleRoadmap.next(compareData.length === 1);
        this._roadmapVarOrder =
          this.compareService.transformToRoadmapWithVariation(compareData);
      });
  }

  private transformDataToCurveMetadata = {
    [ChartType.STACKED_BAR]: this.mapToStackedBar.bind(this),
    [ChartType.WATERFALL]: this.mapToWaterfall.bind(this),
    [ChartType.PIE]: this.mapToPie.bind(this),
  };

  getCurveStream(
    { kpi, subKpi, curveType, splitBy, enumerateBy, filters }: CurveFormModel,
    kpiType: string,
    roadmapWithVariations: CompareRoadmapWithVariationType,
  ): Observable<ChartInterpretation> {
    let kpiKey = kpi;
    /* istanbul ignore else */
    if (INCLUDE_SECONDARY_GROUPING.includes(kpi)) {
      kpiKey = `${kpi}_${curveType}`;
    }

    if (curveType !== STACKED_BAR.value) {
      enumerateBy = null;
    }

    this._blockKey = this.waterfallBlockGrouping[kpi];

    const filterClone = JSON.parse(JSON.stringify(filters)) || {};

    if (this._blockKey && subKpi) {
      filterClone[this._blockKey] = {
        condition: FilterConditionValue.IS,
        values: subKpi,
      };
    }

    return combineLatest([
      this.compareFacade.selectCurves(
        roadmapWithVariations,
        kpiType,
        kpiKey,
        splitBy,
        enumerateBy,
        filterClone,
      ),
      this.chartFacadeService.getLabelColorData(),
    ]).pipe(
      map(([data, chartColorMap]) => {
        return this.transformDataToCurveMetadata[curveType]?.(
          kpiType,
          data,
          chartColorMap,
          splitBy,
        );
      }),
    );
  }

  getFilterOptions(
    activeFilters: string[],
    referenceIds: CompareRoadmapWithVariationType,
  ): Observable<ResultFilterOptions> {
    return this.compareFacade.selectFilters(referenceIds, activeFilters);
  }

  private mapToWaterfall(
    kpiType: string,
    curveEntities: CurveEntityVariation[],
    chartColorMap: Record<string, string>,
    splitBy: HierarchicalFilter,
  ): WaterfallMetadata[] {
    return curveEntities.map((curveEntity) => {
      const groupedData = this.groupWaterfallBlocks(curveEntity);
      const totalStack = this.createWaterfallStack(
        'Grand Total',
        Coerce.getObjValues(groupedData),
        chartColorMap,
      );
      const nextStacks = Object.entries(groupedData).map(([name, data]) =>
        this.createWaterfallStack(name, [data], chartColorMap),
      );

      const processedStacks = this.getStacksToDisplayWithThreshold(
        [totalStack].concat(nextStacks),
        chartColorMap,
      );

      return {
        ...this.createWaterfallMetadata(kpiType, processedStacks),
        unit: curveEntity.unit,
        yAxisName: curveEntity.unit,
        responsive: true,
        name: curveEntity.variationName,
        parentName: curveEntity.roadmapName,
      };
    });
  }

  private groupWaterfallBlocks(
    entity: CurveEntityVariation,
  ): GroupedWaterfallBlocksData {
    return Object.entries(entity.data).reduce((acc, [name, curveEntity]) => {
      acc[name] = curveEntity.reduce((stackAcc, curr) => {
        const key = curr?.[this._blockKey] || name;
        const total = this.computeSum(
          Coerce.getObjValues(curr.values).map((value) => Number(value)),
        );
        if (stackAcc[key]) {
          stackAcc[key] += total;
        } else {
          stackAcc[key] = total;
        }

        return stackAcc;
      }, {});

      return acc;
    }, {} as GroupedWaterfallBlocksData);
  }

  private computeSum(data: number[]) {
    return Number(
      Number(
        data.reduce(
          (acc, curr) => (Number.isFinite(Number(curr)) ? acc + curr : acc),
          0,
        ),
      ).toFixed(2),
    );
  }

  private createWaterfallStack(
    stackName: string,
    blockData: { [blockName: string]: number }[],
    chartColorMap: Record<string, string>,
  ): WaterfallStack {
    const blocks = this.createWaterfallBlocks(stackName, blockData);
    return new WaterfallStack(
      blocks,
      'dec',
      stackName,
      chartColorMap?.[stackName],
    );
  }

  private createWaterfallBlocks(
    stackName: string,
    blockData: { [blockName: string]: number }[],
  ): WaterfallBlock[] {
    const setData = blockData.reduce((acc, curr) => {
      Object.entries(curr).forEach(([key, value]) => {
        const keyToUse = this._blockKey ? key : stackName;
        if (acc[keyToUse]) {
          acc[keyToUse] += value;
        } else {
          acc[keyToUse] = value;
        }
      });
      return acc;
    }, {});
    return Object.entries(setData).map(([blockName, total]) =>
      this.createWaterfallBlock(blockName, total),
    );
  }

  private createWaterfallBlock(name, value): WaterfallBlock {
    return { name, value };
  }

  private getStacksToDisplayWithThreshold(
    stacks: WaterfallStack[],
    chartColorMap: Record<string, string>,
  ) {
    // +1 for the grand total
    const threshold = WATERFALL_THRESHOLD_DISPLAY + 1;
    const middleStacks = stacks
      .slice(1)
      .sort((a, b) => b.blocksTotalValue - a.blocksTotalValue)
      .slice(0, threshold - 1);

    const initialStacks = [stacks[0]].concat(middleStacks);
    const otherStacks =
      stacks.length > threshold
        ? this.extractOthersStack(initialStacks, chartColorMap)
        : [];

    // remove duplicate
    return initialStacks.concat(otherStacks);
  }

  private extractOthersStack(
    stacks: WaterfallStack[],
    chartColorMap: Record<string, string>,
  ) {
    const othersBlocksObj = stacks.reduce((acc, { blocks }) => {
      blocks.forEach(({ name, value }) => {
        const _v = Number(value);
        if (this._blockKey) {
          if (acc[name]) {
            const newValue = acc[name] - _v;
            acc[name] = newValue < 0 ? 0 : newValue;
          } else {
            acc[name] = _v;
          }
        } else {
          if (acc[OTHER_LABEL]) {
            const newValue = acc[OTHER_LABEL] - _v;
            acc[OTHER_LABEL] = newValue < 0 ? 0 : newValue;
          } else {
            acc[OTHER_LABEL] = _v;
          }
        }
      });

      return acc;
    }, {});

    return new WaterfallStack(
      Object.entries<number>(othersBlocksObj).map<WaterfallBlock>(
        ([name, value]) => ({ name, value }),
      ),
      'dec',
      OTHER_LABEL,
      chartColorMap?.[OTHER_LABEL],
    );
  }

  private mapToStackedBar(
    kpiType: string,
    curveEntities: CurveEntityVariation[],
    chartColorMap: Record<string, string>,
    splitBy: HierarchicalFilter,
  ): StackedBarMetadata {
    let mappedData: SummedHierarchicalCurveData = {};
    let stacks: BarStack[] = [];

    const indexedStacks: IndexedStacks[] = curveEntities.map((curveEntity) => {
      const roadmapName = curveEntity.roadmapName || '';
      mappedData = this.mapGroupedSums(
        (curveEntity as CurveEntity<HierarchichalCurveData>).data,
        this.isYearlySplit(splitBy.group),
      );
      stacks = this.mapStacks(mappedData, chartColorMap);
      return {
        name: curveEntity.variationName,
        parentName: roadmapName,
        stacks,
      };
    });
    return {
      ...this.createStackBarMetadata(kpiType, indexedStacks),
      responsive: true,
      yAxisName: curveEntities[0]?.unit,
      unit: curveEntities[0]?.unit,
    };
  }

  private createWaterfallMetadata(
    kpiType: string,
    stacks: WaterfallStack[],
  ): WaterfallMetadata {
    return { stacks, baseColor: this.getBaseColorInHex(kpiType), yAxisMin: 0 };
  }

  getLevels() {
    return this.clientFacadeService.selectActiveClientData$.pipe(
      filter((client) => !!client),
      mergeMap((client) =>
        combineLatest([
          this.clientFacadeService
            .selectGeoHierarchy$(client.clientId)
            .pipe(this.convertHierarchyToOptions('geography')),
          this.clientFacadeService
            .selectCompanyHierarchy$(client.clientId)
            .pipe(this.convertHierarchyToOptions('entity')),
        ]).pipe(map(([geo, company]) => ({ ...geo, ...company }))),
      ),
    );
  }

  private convertHierarchyToOptions(key: string) {
    return map((hierarchy: Hierarchy) => ({
      [key]: Coerce.getObjKeys(hierarchy).map((level) => ({
        name: level,
        value: level,
      })),
    }));
  }

  /**
   * Arrange the blocks in descending order and limit the display according to threshold set.
   * @param blocks blocks to sort and process.
   * @param chartColorMap colorMap
   * @return arranged blocks.
   */
  private getBarBlocksWithThreshold(
    blocks: BarBlock[],
    chartColorMap: Record<string, string>,
  ) {
    const sortedBlocks = blocks?.sort((a, b) => b.value - a.value);
    let withinThreshold = sortedBlocks?.slice(0, STACKED_BAR_THRESHOLD_DISPLAY);
    let others = sortedBlocks?.slice(STACKED_BAR_THRESHOLD_DISPLAY);
    let otherSumValue = 0;
    let otherBlock = undefined;
    if (others && others.length > 0) {
      others = others.map((block) => {
        otherSumValue += block.value;
        return { ...block, value: 0 };
      });
      otherBlock = {
        name: OTHER_LABEL,
        color: chartColorMap?.[OTHER_LABEL],
        value: otherSumValue,
      } as BarBlock;
      withinThreshold = withinThreshold
        .concat(others)
        .sort((a, b) => (a.name > b.name ? 1 : -1));
      withinThreshold.push(otherBlock);
    }
    return withinThreshold;
  }

  private isYearlySplit(level: string) {
    return level === YEARS.value;
  }

  private mapGroupedSums(
    data: HierarchichalCurveData,
    yearly: boolean,
  ): SummedHierarchicalCurveData {
    const leanGroupedSums = Object.entries(data).reduce(
      (summedGroup, [group, components]) => {
        summedGroup[group] = Object.entries(components).reduce(
          (summedComponents, [index, campaigns]) => {
            summedComponents[index] = (
              campaigns?.length ? campaigns : []
            ).reduce(
              (sum, campaign) =>
                (sum += yearly
                  ? Number(campaign.values[index]) || 0
                  : this.sumObjectvalues(campaign.values)),
              0,
            );
            return summedComponents;
          },
          {},
        );
        return summedGroup;
      },
      {},
    );

    return this.padGroupedSums(leanGroupedSums);
  }

  private mapStacks(
    data: SummedHierarchicalCurveData,
    chartColorMap: Record<string, string>,
  ): BarStack[] {
    return Object.entries(data).map(([name, blocks]) => {
      return {
        name,
        blocks: this.createBlocks(blocks, chartColorMap),
      };
    });
  }

  private createBlocks(
    sums: Record<string, number>,
    chartColorMap: Record<string, string>,
  ): BarBlock[] {
    let blocks: BarBlock[] = Object.entries(sums).map(([name, value]) => ({
      name,
      value,
      color: chartColorMap?.[name],
    }));
    blocks = this.getBarBlocksWithThreshold(blocks, chartColorMap);
    return blocks;
  }

  private sumObjectvalues = (obj: Record<string, any>) => {
    return (
      Coerce.getObjValues(obj).reduce(
        (sum, value) => sum + (Number(value) || 0),
        0,
      ) || 0
    );
  };

  private padGroupedSums = (groupedSums: GroupedSums): GroupedSums => {
    const keys = this.getAllSumKeys(groupedSums);
    return Object.entries(groupedSums).reduce(
      (paddedGroupedSum, [group, sums]) => {
        paddedGroupedSum[group] = keys.reduce((newSums, key) => {
          newSums[key] = key in sums ? sums[key] : 0;
          return newSums;
        }, {});
        return paddedGroupedSum;
      },
      {},
    );
  };

  private getAllSumKeys = (groupedSums: GroupedSums): string[] => {
    const keys = Object.entries(groupedSums).reduce(
      (allKeys, [group, sums]) => {
        Object.entries(sums).forEach(([key, sum]) => {
          allKeys.push(key);
        });
        return allKeys;
      },
      [],
    );

    return [...new Set(keys)];
  };

  private createStackBarMetadata(
    kpiType: string,
    indexedStacks: IndexedStacks[],
  ): StackedBarMetadata {
    const bars = indexedStacks.map((stack) => ({
      parentName: stack.parentName,
      name: stack.name,
      stacks: stack.stacks,
    }));

    return {
      orientation: 'vertical',
      bars,
      baseColor: this.getBaseColorInHex(kpiType),
    };
  }

  private getBaseColorInHex(kpiType: string): string {
    return this.baseColors[kpiType];
  }

  private baseColors: Record<NewKPIType | KPIType, string> = {
    [NewKPIType.SUSTAINABILITY]: '#0b74c0',
    [KPIType.ENVIRONMENTAL]: '#0b74c0',
    [NewKPIType.FINANCIALS]: '#faca08',
    [KPIType.ECONOMICS]: '#faca08',
    [NewKPIType.OPERATIONS]: '#69af23',
  };

  mapToPie(
    kpiType: string,
    curveEntities: CurveEntityVariation[],
    chartColorMap: Record<string, string>,
    splitBy: HierarchicalFilter,
  ): PieMetadata[] {
    return this._roadmapVarOrder.map((roadmapVar) => {
      const curve = curveEntities.filter(
        (curve) =>
          curve.variationId === roadmapVar[2] &&
          curve.roadmapId === roadmapVar[0],
      )[0];

      const KPI_SLICES = Coerce.getObjKeys(curve?.data);
      let slices = KPI_SLICES.map((slice) =>
        this.createPieSliceDatum(slice, curve?.data, chartColorMap?.[slice]),
      );

      // process slices here; sort and display items first
      // according to the threshold
      let processedSlices = this.getSlicesWithThreshold(slices, chartColorMap);
      const total = this.computeTotal(processedSlices);
      processedSlices = this.setPercentageAttr(total, processedSlices);

      return {
        slices: processedSlices,
        name: this.getPieName(curve),
        unit: curve?.unit,
        baseColor: this.getBaseColorInHex(kpiType),
        total,
      } as PieMetadata;
    });
  }

  private createPieSliceDatum(
    slice: string,
    valueChunk: unknown,
    color: string,
  ): PieSlice {
    const perGroupData: [] = valueChunk[slice];
    let applicableValues: number[] = [];
    perGroupData.forEach((data) => {
      const values = Coerce.getObjValues(data['values']).map((value) =>
        Number(value),
      );
      applicableValues = applicableValues.concat(values);
    });
    const total = this.computeSum(applicableValues);
    return { name: slice, color, value: total.toFixed(2) } as PieSlice;
  }

  /**
   * Arrange the slices in descending order and limit the display according to threshold set.
   * @param slices slices to sort and process.
   * @param chartColorMap colorMap
   * @return sliced slices.
   */
  getSlicesWithThreshold(
    slices: PieSlice[],
    chartColorMap: Record<string, string>,
  ) {
    const sortedSlices = slices.sort(
      (a, b) => parseFloat(b.value) - parseFloat(a.value),
    );
    slices = sortedSlices
      .slice(0, PIE_THRESHOLD_DISPLAY)
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    let otherSlicesValue = 0;
    let otherSlices = sortedSlices.slice(PIE_THRESHOLD_DISPLAY);
    otherSlices.forEach((pieSlice) => {
      otherSlicesValue += parseFloat(pieSlice.value);
    });

    if (otherSlices.length > 0) {
      slices.push({
        name: OTHER_LABEL,
        value: otherSlicesValue.toFixed(2),
        color: chartColorMap?.[OTHER_LABEL],
      } as PieSlice);
    }
    return slices;
  }

  computeTotal(slice: PieSlice[]) {
    return Number(slice.reduce((acc, it) => acc + parseFloat(it.value), 0));
  }

  setPercentageAttr(totalValue, slices: PieSlice[]) {
    return slices.map((slice) => ({
      ...slice,
      extra: {
        percentage: this.calcPercentage(totalValue, parseFloat(slice.value)),
      },
    }));
  }

  calcPercentage(totalValue: number, itemValue: number) {
    return ((itemValue / totalValue) * 100).toFixed(2);
  }

  private getPieName(curve: CurveEntityVariation): string {
    return this._isSingleRoadmap.getValue()
      ? curve?.variationName
      : `${curve?.roadmapName}: ${curve?.variationName}`;
  }
}

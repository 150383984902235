import { query, style, transition, trigger, useAnimation } from '@angular/animations';
import { zoomInDown } from 'ng-animate';

export const detailRouteAnimation = trigger('detailRouteAnimation', [
  transition('* => *', [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          left: 0,
          opacity: 0
        })
      ],
      { optional: true }
    ),
    query(
      ':enter',
      [
        style({
          position: 'absolute',
          left: 24,
          top: 104
        }),
        useAnimation(zoomInDown)
      ],
      { optional: true }
    )
  ])
]);

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  FilterGroup,
  FilterGroups,
  SelectedFilters,
} from './combination-filter.model';

@Component({
  selector: 'sso-combination-filter',
  templateUrl: './combination-filter.component.html',
  styleUrls: ['./combination-filter.component.scss'],
})
export class CombinationFilterComponent {
  private _filters: FilterGroups = [];
  @Input() set options(filters: FilterGroups) {
    this._filters = filters;
    this.optionGroups$.next(filters);
    this._selectedFilters.next({});
  }
  get options(): FilterGroups {
    return this._filters;
  }

  @Output() selectedFiltersChange = new EventEmitter<SelectedFilters>();

  private _selectedFilters = new BehaviorSubject<SelectedFilters>({});

  optionGroups$ = new BehaviorSubject<FilterGroups>([]);

  onSelectionChange(selectedItems: any, group: FilterGroup): void {
    this._selectedFilters.next({
      ...this._selectedFilters.value,
      [group.id]: selectedItems.map((item) => item.id),
    });
    this.selectedFiltersChange.emit(this._selectedFilters.value);
  }

  constructor() {}
}

import { MarkdownModule } from 'ngx-markdown';
import { generateShadedScheme } from 'ssotool-app/app.references';
import { environment } from 'ssotool-env';
import { GENERATE_COLOR_SCHEME } from 'ssotool-shared/charts.token';
import { ErrorService } from 'ssotool-shared/services/error/error.service';
import { PageModule } from 'ssotool-shared/stores/page';
import { RouterModule } from 'ssotool-shared/stores/router';

import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ServiceWorkerModule,
  SwRegistrationOptions,
} from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthModule, AuthService } from '@oculus/auth/amplify';
import { CloudWatchLoggerService } from '@oculus/utils';

import { AppRoutingModule, SUPPORT } from '../app-routing.module';
import { CampaignPageGuard } from './guards';
import { ErrorInterceptor, RefreshInterceptor } from './interceptors';
import { MaterialModule } from './material.module';
import {
  API_CONFIG,
  AUTH_CONFIG,
  ENV_CONFIG,
  HOME_URL_REDIRECT_PROVIDER,
  HTTP_CONFIG,
  LOGIN_URL_REDIRECT_PROVIDER,
  SHARED_API_TOKEN,
  SHARED_SUPPORT_TOKEN,
  SHARED_VERSION_TOKEN,
  SUPPORT_CONFIG,
  WEBSOCKET_CONFIG,
} from './tokens';
import { HttpLoaderFactory, redirectHome, redirectLogin } from './utils';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    StoreModule.forRoot([]),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    MarkdownModule.forRoot(),
    RouterModule.forRoot(),
    PageModule.forRoot(),
    HttpClientModule,
    environment.production ? [] : StoreDevtoolsModule.instrument(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      useDefaultLang: true,
    }),
    MaterialModule,
    AuthModule.forRoot(environment.cognito.Auth),
  ],
  declarations: [],
  providers: [
    // support module
    { provide: SHARED_API_TOKEN, useValue: environment.api },
    { provide: SHARED_VERSION_TOKEN, useValue: environment.version },
    { provide: SHARED_SUPPORT_TOKEN, useValue: SUPPORT },

    { provide: AUTH_CONFIG, useValue: environment.cognito.Auth },
    { provide: ENV_CONFIG, useValue: environment },
    { provide: HTTP_CONFIG, useValue: environment.http },
    { provide: SUPPORT_CONFIG, useValue: SUPPORT },
    { provide: API_CONFIG, useValue: environment.api },
    { provide: WEBSOCKET_CONFIG, useValue: environment.websocket },
    /* Redirection */
    { provide: HOME_URL_REDIRECT_PROVIDER, useValue: redirectHome },
    { provide: LOGIN_URL_REDIRECT_PROVIDER, useValue: redirectLogin },
    /* Color Scheme */
    { provide: GENERATE_COLOR_SCHEME, useValue: generateShadedScheme },
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({ enabled: environment.production }),
    },
    {
      provide: ErrorHandler,
      useClass: environment.production ? ErrorService : ErrorHandler,
    },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: 'loggerService', useClass: CloudWatchLoggerService },
    CampaignPageGuard,
    AuthService,
  ],
})
export class CoreModule {
  // constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
  //   if (parentModule) {
  //     throw new Error('CoreModule is already loaded. Import it in the AppModule only');
  //   }
  // }
}

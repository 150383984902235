import { Coerce } from 'ssotool-app/shared';

import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  RESULT_EXPORT_FEATURE_NAME,
  resultExportAdapter,
  ResultExportEntityModel,
  ResultExportState,
} from './result-export.reducer';

const { selectEntities, selectAll } = resultExportAdapter.getSelectors();

const exportFeatureState = createFeatureSelector<ResultExportState>(
  RESULT_EXPORT_FEATURE_NAME,
);

const selectResultExportEntities = createSelector(
  exportFeatureState,
  selectEntities,
);

const selectResultExportList = createSelector(exportFeatureState, selectAll);

export const selectLoading = createSelector(
  exportFeatureState,
  (state) => state.loading,
);

export const selectLoaded = createSelector(
  exportFeatureState,
  (state) => state.loaded,
);

export const selectExport = (props: { id: string }) =>
  createSelector(
    selectResultExportEntities,
    (entities: Dictionary<ResultExportEntityModel>) =>
      entities[Coerce.toObject(props).id],
  );

export const selectActiveExport = createSelector(
  selectResultExportList,
  (exports) => Coerce.toArray(exports)[0],
);

import { Coerce } from 'ssotool-app/shared/helpers';

import { createReducer, on } from '@ngrx/store';

import {
  INITIAL_PORTFOLIO_ENTITY_STATE,
  PortfolioEntityState,
} from '../portfolio.reducer';
import { ResultGetParameters } from './result-parameters.model';
import { ResultEntityActions } from './result.actions';
import {
  BackendBaselineIndicators,
  BaselineIndicators,
  BaselineIndicatorTrigger,
} from './result.model';

export const RESULTS_FEATURE_NAME = 'Result';

function reduceGetAction(
  previous: PortfolioEntityState,
  { roadmapId, variationId }: ResultGetParameters,
): PortfolioEntityState {
  const currentMap = { ...previous.dataMap };
  currentMap[roadmapId] = {
    ...currentMap[roadmapId],
    [variationId]: {
      ...currentMap[roadmapId]?.[variationId],
      loading: true,
      loaded: false,
    },
    loaded: false,
    loading: true,
  };
  return { ...previous, dataMap: currentMap, loading: true };
}

function reduceGetOKAction(
  previous: PortfolioEntityState,
  { data, params },
): PortfolioEntityState {
  const currentMap = { ...previous.dataMap };
  const portfolioModel = data;
  currentMap[portfolioModel.id] = {
    ...currentMap[portfolioModel.id],
    ...portfolioModel,
    [params.variationId]: {
      ...currentMap[params.roadmapId]?.[params.variationId],
      loading: false,
      loaded: true,
    },
    loaded: true,
    loading: false,
    progress: currentMap[portfolioModel.id]?.progress,
  };
  return { ...previous, dataMap: currentMap, loading: false, loaded: true };
}

function reduceGetNOKAction(
  previous: PortfolioEntityState,
  { roadmapId, variationId }: ResultGetParameters,
): PortfolioEntityState {
  const currentMap = { ...previous.dataMap };
  currentMap[roadmapId] = {
    ...currentMap[roadmapId],
    [variationId]: {
      ...currentMap[roadmapId]?.[variationId],
      loading: false,
      loaded: false,
    },
    loaded: false,
    loading: false,
  };
  return { ...previous, dataMap: currentMap, loading: false };
}

function reduceUpdateLoadedVariationProgressAction(
  previous: PortfolioEntityState,
  { roadmapId, variationId, progress },
): PortfolioEntityState {
  const currentMap = { ...previous.dataMap };
  currentMap[roadmapId] = {
    ...currentMap[roadmapId],
    [variationId]: {
      ...currentMap[roadmapId]?.[variationId],
      progress,
    },
  };
  return { ...previous, dataMap: currentMap };
}

function reduceDownloadComputeLogsAction(
  previous: PortfolioEntityState,
  _: {},
): PortfolioEntityState {
  return { ...previous, loading: false };
}

function reduceDownloadComputeLogsOKAction(
  previous: PortfolioEntityState,
  _: {},
): PortfolioEntityState {
  return { ...previous, loading: false };
}

function reduceDownloadComputeLogsNOKAction(
  previous: PortfolioEntityState,
  _: {},
): PortfolioEntityState {
  return { ...previous, loading: false };
}

function reduceDownloadComputeInputsAction(
  previous: PortfolioEntityState,
  _: {},
): PortfolioEntityState {
  return { ...previous, loading: false };
}

function reduceDownloadComputeInputsOKAction(
  previous: PortfolioEntityState,
  _: {},
): PortfolioEntityState {
  return { ...previous, loading: false };
}

function reduceDownloadComputeInputsNOKAction(
  previous: PortfolioEntityState,
  _: {},
): PortfolioEntityState {
  return { ...previous, loading: false };
}

function reduceDownloadComputeResultAction(
  previous: PortfolioEntityState,
  _: {},
): PortfolioEntityState {
  return { ...previous, loading: false };
}

function reduceDownloadComputeResultOKAction(
  previous: PortfolioEntityState,
  _: {},
): PortfolioEntityState {
  return { ...previous, loading: false };
}

function reduceDownloadComputeResultNOKAction(
  previous: PortfolioEntityState,
  _: {},
): PortfolioEntityState {
  return { ...previous, loading: false };
}

function reduceDownloadResultExcelAction(
  previous: PortfolioEntityState,
  _: {},
): PortfolioEntityState {
  return { ...previous, loading: false };
}

function reduceDownloadResultExcelOKAction(
  previous: PortfolioEntityState,
  _: {},
): PortfolioEntityState {
  return { ...previous, loading: false };
}

function reduceDownloadResultExcelNOKAction(
  previous: PortfolioEntityState,
  _: {},
): PortfolioEntityState {
  return { ...previous, loading: false };
}

function reduceUpdateLoadedProgressAction(
  previous: PortfolioEntityState,
  { id, progress },
): PortfolioEntityState {
  const currentMap = { ...previous.dataMap };
  currentMap[id] = { ...currentMap[id], progress };
  return { ...previous, dataMap: currentMap };
}

function reduceGetBaselineIndicatorsAction(
  previous: PortfolioEntityState,
  { roadmapId }: { roadmapId: string },
): PortfolioEntityState {
  const currentMap = { ...previous.dataMap };
  currentMap[roadmapId] = {
    ...currentMap[roadmapId],
    baselineLoading: false,
    baselineFetched: true,
  };
  return { ...previous, dataMap: currentMap };
}

function reduceGetBaselineIndicatorsOKAction(
  previous: PortfolioEntityState,
  { data }: { data: BaselineIndicatorTrigger },
): PortfolioEntityState {
  const currentMap = { ...previous.dataMap };
  currentMap[data.roadmapId] = {
    ...currentMap[data.roadmapId],
    baselineLoading: data.isComputing,
    baselineFetched: !data.isComputing,
  };
  return { ...previous, dataMap: currentMap, loading: data.isComputing };
}

function reduceGetBaselineIndicatorsNOKAction(
  previous: PortfolioEntityState,
  { roadmapId }: { roadmapId: string },
): PortfolioEntityState {
  const currentMap = { ...previous.dataMap };
  currentMap[roadmapId] = {
    ...currentMap[roadmapId],
    baselineLoading: false,
    baselineFetched: true,
  };
  return { ...previous, dataMap: currentMap, loading: false };
}

function reduceGetMultipleBaselineIndicatorsOKAction(
  previous: PortfolioEntityState,
  { roadmapIds }: { roadmapIds: string[] },
): PortfolioEntityState {
  const currentMap = { ...previous.dataMap };
  Coerce.getObjKeys(currentMap).forEach((id) => {
    currentMap[id] = {
      ...currentMap[id],
      baselineIndicators: {
        ...currentMap[id]?.baselineIndicators,
        analysis: null,
        worldMap: [],
      },
    };
  });
  roadmapIds.forEach((id) => {
    currentMap[id] = {
      ...currentMap[id],
      baselineLoading: true,
      baselineFetched: false,
    };
  });
  return { ...previous, dataMap: currentMap, loading: false };
}

function reduceUpdateBaselineIndicatorsOKAction(
  previous: PortfolioEntityState,
  { data }: { data: BaselineIndicators },
): PortfolioEntityState {
  const currentMap = { ...previous.dataMap };
  currentMap[data.id] = {
    ...currentMap[data.id],
    baselineIndicators: data,
    baselineLoading: false,
    baselineFetched: true,
  };
  return { ...previous, dataMap: currentMap, loading: false };
}

function reduceUpdateBaselineIndicatorsNOKAction(
  previous: PortfolioEntityState,
  { data }: { data: BackendBaselineIndicators },
): PortfolioEntityState {
  const currentMap = { ...previous.dataMap };
  currentMap[data.roadmapId] = {
    ...currentMap[data.roadmapId],
    baselineIndicators: { ...data, analysis: null, worldMap: [] },
    baselineLoading: false,
    baselineFetched: true,
  };
  return { ...previous, dataMap: currentMap, loading: false };
}

const resultAPIActions = ResultEntityActions(RESULTS_FEATURE_NAME);
const resultReducer = createReducer(
  INITIAL_PORTFOLIO_ENTITY_STATE,
  on(resultAPIActions.getAction, reduceGetAction),
  on(resultAPIActions.getOKAction, reduceGetOKAction),
  on(resultAPIActions.getNOKAction, reduceGetNOKAction),
  on(
    resultAPIActions.downloadComputationLogsAction,
    reduceDownloadComputeLogsAction,
  ),
  on(
    resultAPIActions.downloadComputationLogsOKAction,
    reduceDownloadComputeLogsOKAction,
  ),
  on(
    resultAPIActions.downloadComputationLogsNOKAction,
    reduceDownloadComputeLogsNOKAction,
  ),
  on(
    resultAPIActions.downloadComputationInputsAction,
    reduceDownloadComputeInputsAction,
  ),
  on(
    resultAPIActions.downloadComputationInputsOKAction,
    reduceDownloadComputeInputsOKAction,
  ),
  on(
    resultAPIActions.downloadComputationInputsNOKAction,
    reduceDownloadComputeInputsNOKAction,
  ),
  on(
    resultAPIActions.downloadComputationResultAction,
    reduceDownloadComputeResultAction,
  ),
  on(
    resultAPIActions.downloadComputationResultOKAction,
    reduceDownloadComputeResultOKAction,
  ),
  on(
    resultAPIActions.downloadComputationResultNOKAction,
    reduceDownloadComputeResultNOKAction,
  ),
  on(
    resultAPIActions.downloadResultExcelAction,
    reduceDownloadResultExcelAction,
  ),
  on(
    resultAPIActions.downloadResultExcelOKAction,
    reduceDownloadResultExcelOKAction,
  ),
  on(
    resultAPIActions.downloadResultExcelNOKAction,
    reduceDownloadResultExcelNOKAction,
  ),
  on(
    resultAPIActions.updateLoadedProgressAction,
    reduceUpdateLoadedProgressAction,
  ),
  on(
    resultAPIActions.updateLoadedVariationProgressAction,
    reduceUpdateLoadedVariationProgressAction,
  ),
  on(
    resultAPIActions.getBaselineIndicatorsAction,
    reduceGetBaselineIndicatorsAction,
  ),
  on(
    resultAPIActions.getBaselineIndicatorsOKAction,
    reduceGetBaselineIndicatorsOKAction,
  ),
  on(
    resultAPIActions.getBaselineIndicatorsNOKAction,
    reduceGetBaselineIndicatorsNOKAction,
  ),
  on(
    resultAPIActions.getMultipleBaselineIndicatorsOKAction,
    reduceGetMultipleBaselineIndicatorsOKAction,
  ),
  on(
    resultAPIActions.updateBaselineIndicatorsOKAction,
    reduceUpdateBaselineIndicatorsOKAction,
  ),
  on(
    resultAPIActions.updateBaselineIndicatorsNOKAction,
    reduceUpdateBaselineIndicatorsNOKAction,
  ),
);

export const ResultEntityStore = {
  featureName: RESULTS_FEATURE_NAME,
  reducer: resultReducer,
};

export const ResultEntityReducerFns = {
  reduceDownloadComputeLogsAction,
  reduceDownloadComputeLogsOKAction,
  reduceDownloadComputeLogsNOKAction,
  reduceDownloadComputeInputsAction,
  reduceDownloadComputeInputsOKAction,
  reduceDownloadComputeInputsNOKAction,
  reduceDownloadComputeResultAction,
  reduceDownloadComputeResultOKAction,
  reduceDownloadComputeResultNOKAction,
  reduceDownloadResultExcelAction,
  reduceDownloadResultExcelOKAction,
  reduceDownloadResultExcelNOKAction,
  reduceUpdateLoadedProgressAction,
  reduceGetBaselineIndicatorsAction,
  reduceGetBaselineIndicatorsOKAction,
  reduceGetBaselineIndicatorsNOKAction,
  reduceGetMultipleBaselineIndicatorsOKAction,
  reduceUpdateBaselineIndicatorsOKAction,
  reduceUpdateBaselineIndicatorsNOKAction,
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LetModule, PushPipe } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';

import { ComputeToastComponent } from './compute-toast.component';

@NgModule({
  declarations: [ComputeToastComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    LetModule,
    PushPipe,
  ],
  exports: [ComputeToastComponent],
})
export class ComputeToastModule {}

export const GEO_ENTITY_TYPE = 'geoType';
export const COMPANY_ENTITY_TYPE = 'companyType';

export const HID_KEY = 'hId';
export const NAME_KEY = 'name';
export const GROUP_KEY = 'group';
export const GEO_GROUPS_KEY = 'geoGroups';
export const COMPANY_GROUPS_KEY = 'companyGroups';

export const ENTITY_KEY = 'entity';
export const COMPANY_KEY = 'company';
export const GEOGRAPHY_KEY = 'geography';

export const HID_KEYS = {
  geography: 'geoHid',
  entity: 'companyHid',
};

export enum ClientTabs {
  ACTIVE = 'active',
  ARCHIVE = 'archive',
}

export interface WaterfallBlock {
  readonly name: string;
  readonly value: number;
  readonly values?: any;
}

export type WaterfallStackChange = 'inc' | 'dec' | 'still';

export class WaterfallStack {
  private calculatedFloor: number;
  private change: WaterfallStackChange;

  readonly blocks: WaterfallBlock[];
  readonly name: string;
  readonly color: string;
  readonly parentName: string;
  constructor(
    blocks: WaterfallBlock[],
    change: WaterfallStackChange,
    name?: string,
    color?: string,
    parentName?: string,
  ) {
    this.blocks = blocks;
    this.change = change;
    this.name = name;

    this.color = color;
    this.calculatedFloor = 0;
  }

  get blocksTotalValue(): number {
    return this.blocks.reduce((acc, curr) => acc + curr.value, 0);
  }

  calculateAndSaveFloor(previous?: WaterfallStack): number {
    const calculateFns = {
      inc: this.calculateFloorForInc.bind(this),
      dec: this.calculateFloorForDec.bind(this),
      still: this.calculateFloorForStill.bind(this),
    };
    const calculated = previous ? calculateFns[this.change](previous) : 0;
    this.calculatedFloor = calculated;
    return this.calculatedFloor;
  }

  private calculateFloorForInc(previous: WaterfallStack): number {
    return this.calculateFloorWithChange(previous);
  }

  private calculateFloorForDec(previous: WaterfallStack): number {
    return this.calculateFloorWithChange(previous) - this.blocksTotalValue;
  }

  private calculateFloorForStill(): number {
    return 0;
  }

  private calculateFloorWithChange(previous: WaterfallStack): number {
    const isPreviousDecreasing = previous.change === 'dec';
    const correctAdditive = isPreviousDecreasing
      ? 0
      : previous.blocksTotalValue;
    return previous.calculatedFloor + correctAdditive;
  }
}

export interface WaterfallMetadata {
  readonly stacks: WaterfallStack[];
  readonly name?: string;
  readonly xAxisName?: string;
  readonly yAxisName?: string;
  readonly unit?: string;
  readonly baseColor?: string;
  readonly yAxisMin?: number;
  readonly responsive?: boolean;
  readonly parentName?: string;
}

import { Observable, of } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client';
import { Coerce, SSOToolRoutePathService } from 'ssotool-shared';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  isFeatureDisabled,
  isFeatureEnabled,
} from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';

@Component({
  selector: 'sso-geography-list',
  templateUrl: './geography-list.component.html',
  styleUrls: ['./geography-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeographyListComponent implements OnInit {
  entities$: Observable<any[]> = of([]);
  loading$: Observable<boolean> = of(false);
  clientId = '';

  columnDefs = [
    { name: 'name', mode: 'event' },
    { name: 'parent' },
    ...(isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE)
      ? []
      : [{ name: 'type' }, { name: 'groups', mode: 'array' }]),
  ];
  constructor(
    private clientFacade: ClientFacadeService,
    private router: Router,
    private routePath: SSOToolRoutePathService,
  ) {}

  ngOnInit(): void {
    this.clientFacade.selectActiveClientData$.pipe().subscribe((client) => {
      /* istanbul ignore else */
      if (client && client.clientId) {
        this.clientId = client.clientId;
        this.entities$ = this.clientFacade.selectGeos$(client.clientId).pipe(
          skipWhile((data) => !data),
          map((data) =>
            Coerce.getObjValues(data).map(
              ({ geoId, name, parentName, geoType, group }) => ({
                id: geoId,
                name,
                type: geoType,
                parent: parentName,
                groups: group?.split(';')?.map((g) => ({ name: g })) || [],
              }),
            ),
          ),
        );
        this.loading$ = this.clientFacade.dataLoading$(client.clientId);
      }
    });
  }

  onClick(event: any) {
    if (isFeatureDisabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE)) {
      this.router.navigate(
        this.routePath.viewGeography(this.clientId, event.id),
      );
    }
  }
}

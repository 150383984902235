import { SelectionGroups } from 'ssotool-app/shared/component/generic-table/generic-table.model';
import { Trajectory, TrajectoryBackend } from '../client.model';

export const TRAJECTORY_GROUP_DEFINITIONS: SelectionGroups = [
  {
    labelKey: 'willOverwrite',
    id: 'willOverwrite',
  },
  {
    labelKey: 'new',
    id: 'new',
  },
];

export type TrajectoryRecord = Record<Trajectory['trajectoryId'], Trajectory>;

export type TrajectoryBackendRecord = Record<
  TrajectoryBackend['trajectoryId'],
  TrajectoryBackend
>;

<div
  fxLayout="column"
  fxLayoutAlign="start start"
  fxLayoutGap="12px"
  *ngrxLet="data$; let d"
>
  <div
    data-testid="message-container"
    class="toast-message"
    *ngrxLet="roadmap$; let r"
  >
    {{
      'Roadmap.messages.compute' + ((isSuccess$ | async) ? 'Success' : 'Failed')
        | translate: { name: r.name }
    }}
  </div>
  <button
    *ngIf="!d?.hideRoadmapNavigation"
    data-testid="navigate-button"
    mat-button
    color="primary"
    (click)="navigateToSpecificRoadmap()"
  >
    {{ 'Roadmap.labels.viewRoadmap' | translate }}
  </button>
</div>

import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { BasePortfolioEntityEffects } from '../portfolio.effects';
import { CompareApiService } from './compare-api.service';
import { CompareFacadeService } from './compare-facade.service';
import { CompareEntityActions } from './compare.actions';
import { CompareEntityStore } from './compare.reducer';
import { ToastService } from 'ssotool-app/shared/services/toast/toast.service';

@Injectable()
export class CompareEffects extends BasePortfolioEntityEffects {
  constructor(
    private act: Actions,
    private api: CompareApiService,
    private fac: CompareFacadeService,
    private toast: ToastService,
  ) {
    super(act, api, fac);
  }

  getAPIName(): string {
    return CompareEntityStore.featureName;
  }

  getComparisonResultSideEffect = () => {
    return this.act.pipe(
      ofType(CompareEntityActions.getComparisonResultAction),
      mergeMap((action) =>
        this.api
          .getComparisonResult(
            action.clientId,
            action.referenceRoadmapId,
            action.roadmapIds,
          )
          .pipe(
            map(() =>
              CompareEntityActions.getComparisonResultOKAction({
                message: 'Comparing roadmaps started.',
              }),
            ),
            catchError((err) =>
              of(
                CompareEntityActions.getComparisonResultNOKAction({
                  params: action,
                  message:
                    (err.error || {}).error || 'Comparing roadmaps error!',
                }),
              ),
            ),
          ),
      ),
    );
  };

  getComparisonResultOKSideEffect = () => {
    return this.act.pipe(
      ofType(CompareEntityActions.getComparisonResultOKAction),
      tap((action) => this.toast.showToast(action.message, 'info')),
    );
  };

  getComparisonResultNokSideEffect = () => {
    return this.act.pipe(
      ofType(CompareEntityActions.getComparisonResultNOKAction),
      tap(({ params, message }) =>
        this.toast.showToast(
          message,
          'action',
          undefined,
          of({}).pipe(
            tap(() =>
              this.fac.getComparisonResult(
                params.clientId,
                params.referenceRoadmapId,
                params.roadmapIds,
              ),
            ),
          ),
        ),
      ),
    );
  };

  downloadComparisonResultSideEffect = () => {
    return this.act.pipe(
      ofType(CompareEntityActions.downloadComparisonResultAction),
      mergeMap((action) =>
        this.api
          .downloadComparisonResult(action.signedUrl, action.filesize)
          .pipe(
            map((data) =>
              CompareEntityActions.downloadComparisonResultOKAction({
                data,
                message: 'Download Comparison Data success!',
              }),
            ),
            catchError((err) =>
              of(
                CompareEntityActions.downloadComparisonResultNOKAction({
                  params: action,
                  message:
                    (err.error || {}).error ||
                    'Download Comparison Data error!',
                }),
              ),
            ),
          ),
      ),
    );
  };

  downloadComparisonResultOKSideEffect = () => {
    return this.act.pipe(
      ofType(CompareEntityActions.downloadComparisonResultOKAction),
      tap((action) => this.toast.showToast(action.message, 'success')),
    );
  };

  downloadComparisonResultNokSideEffect = () => {
    return this.act.pipe(
      ofType(CompareEntityActions.downloadComparisonResultNOKAction),
      tap(({ params, message }) =>
        this.toast.showToast(
          message,
          'action',
          undefined,
          of({}).pipe(
            tap(() =>
              this.fac.downloadComparisonResult(
                params.signedUrl,
                params.filesize,
              ),
            ),
          ),
        ),
      ),
    );
  };

  get$ = createEffect(this.getSideEffect);
  getOk$ = createEffect(this.getOKSideEffect, { dispatch: false });
  getNok$ = createEffect(this.getNokSideEffect, { dispatch: false });
  getAll$ = createEffect(this.getAllSideEffect);
  getAllOk$ = createEffect(this.getAllOkSideEffect, { dispatch: false });
  getAllNOK$ = createEffect(this.getAllNokSideEffect, { dispatch: false });
  getComparisonResult$ = createEffect(
    this.getComparisonResultSideEffect.bind(this),
  );
  getComparisonResultOk$ = createEffect(
    this.getComparisonResultOKSideEffect.bind(this),
    { dispatch: false },
  );
  getComparisonResultNok$ = createEffect(
    this.getComparisonResultNokSideEffect.bind(this),
    { dispatch: false },
  );
  downloadComparisonResult$ = createEffect(
    this.downloadComparisonResultSideEffect.bind(this),
  );
  downloadComparisonResultOk$ = createEffect(
    this.downloadComparisonResultOKSideEffect.bind(this),
    { dispatch: false },
  );
  downloadComparisonResultNok$ = createEffect(
    this.downloadComparisonResultNokSideEffect.bind(this),
    { dispatch: false },
  );
}

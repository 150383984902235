<div
  class="container"
  fxLayoutGap="10px"
  fxLayout="column"
  fxLayoutAlign="center stretch"
>
  <div fxLayoutGap="10px" fxLayout="row">
    <mat-icon aria-hidden="false">insert_chart_outlined</mat-icon>
    <h2 class="mat-headline-6" mat-dialog-title>
      {{ data?.name | titlecase }} Flow Volumes
    </h2>
  </div>
  <sso-tabbed-area-chart
    [yearlyTabs]="yearlyTabs"
    [seriesDataList]="yearlyChartValues$ | async"
    (onTabChange)="onTabChange($event)"
    [loading]="loading$ | async"
    [yAxisLabel]="quantity$ | async"
  ></sso-tabbed-area-chart>
  <div mat-dialog-actions fxLayout="row">
    <button (click)="onClose()" color="" mat-flat-button type="button">
      Close
    </button>
  </div>
</div>

export interface ColumnDefModel {
  readonly name: string;
  readonly label?: string;
  readonly type?: string;
  readonly mode?: string;
}

export enum SelectedType {
  SELECTED = 'selected',
  NOT_SELECTED = 'not selected',
}

export interface EntityAction {
  shown?: (...args) => boolean;
  actionFn: Function;
  label: string;
  icon: string;
  color: string;
}

import { Coerce } from 'ssotool-app/shared/helpers';

import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { GROUP_INDICATOR } from '../filters/filters.const';
import {
  FilterConditionValue,
  FilterInitialData,
  FiltersDialogData,
} from './filters-dialog.model';
import { FiltersDialogConditionOptions } from './filters-dialog.references';

@Component({
  selector: 'sso-filters-dialog',
  templateUrl: './filters-dialog.component.html',
  styleUrls: ['./filters-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersDialogComponent implements OnInit {
  filterForm = this.fb.group({
    filters: this.fb.array([]),
  });
  flatInitialFilter: FilterInitialData[] = this.data.initialValue;

  filterOptions: Record<string, string[]> = this.data.filterOptions;
  flatFilterOptions: string[] = Coerce.getObjKeys(this.filterOptions) || [];

  get filters(): FormArray {
    return this.filterForm.controls['filters'] as FormArray;
  }
  stripLabel = GROUP_INDICATOR;
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  scrollViewport: CdkVirtualScrollViewport;

  constructor(
    public dialogRef: MatDialogRef<FiltersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FiltersDialogData,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.initializeFilters();
  }

  addFilter(formGroup: AbstractControl = this.createFilter()): void {
    this.filters.push(formGroup);
  }

  deleteFilter(filterIndex: number): void {
    this.filters.removeAt(filterIndex);
  }

  private initializeFilters(): void {
    this.flatInitialFilter.forEach((fieldSet: FilterInitialData) => {
      if (
        fieldSet?.filterName &&
        fieldSet?.filterCondition &&
        fieldSet?.filterValue?.length
      ) {
        const { filterName, filterCondition, filterValue } = fieldSet;
        const formGroup = this.createFilter(
          filterName,
          filterCondition,
          filterValue,
        );

        this.addFilter(formGroup);
      }
    });

    if (!this.filters?.controls.length) {
      this.addFilter();
    }
  }

  private createFilter(
    name: string = '',
    condition: FilterConditionValue = FilterConditionValue.IS,
    filterValue: string[] = [],
  ) {
    return this.fb.group({
      filterName: [name, Validators.required],
      filterCondition: [condition, Validators.required],
      filterValue: [
        filterValue,
        [Validators.required, Validators.minLength(1)],
      ],
    });
  }

  isAddButtonDisabled(): boolean {
    return this.filters?.controls.length === this.flatFilterOptions.length;
  }

  onSubmit(): void {
    if (this.filterForm.valid) {
      this.dialogRef.close(this.filters.value || []);
    }
  }

  setDisabledOption(valueOption: string, valueIndex: number): boolean {
    const chosenFilters = [];
    this.filters?.controls.forEach((fieldSet) => {
      chosenFilters.push(fieldSet.get('filterName').value);
    });

    if (chosenFilters.length) {
      return (
        chosenFilters.find((item) => item === valueOption) &&
        this.filters?.at(valueIndex).get('filterName').value !== valueOption
      );
    }

    return false;
  }

  getFilterConditionOptions(filterName: string) {
    return (
      FiltersDialogConditionOptions[
        this.data.dialogConfig?.conditionMode?.[filterName]
      ] || FiltersDialogConditionOptions.direct
    );
  }
}

import { createAction, props } from '@ngrx/store';

import { SelectedCampaigns } from '../campaign.model';
import {
  CAMPAIGN_EXPORT_STATUS,
  CampaignExportModel,
  CampaignStageMap,
} from './campaign-export.model';

const exportSelectedCampaigns = createAction(
  '[Campaign List Page], Export campaigns',
  props<{ clientId: string; campaigns: SelectedCampaigns }>(),
);

const exportSelectedCampaignsSuccess = createAction(
  '[Campaign export API] Export campaigns success',
  props<{ clientId: string; data: { exportId: string; clientId: string } }>(),
);

const exportSelectedCampaignsFailure = createAction(
  '[Campaign export API] Export failure',
  props<{
    data: { clientId: string; campaigns: SelectedCampaigns };
    error: string;
    message: string;
  }>(),
);

const cancelExportCampaigns = createAction(
  '[Campaign List Page], Cancel export',
  props<{ clientId: string; exportId: string }>(),
);

const cancelExportCampaignsOK = createAction(
  '[Campaign List Page], Cancel export OK',
  props<{ clientId: string; exportId: string; message: string }>(),
);

const cancelExportCampaignsPartial = createAction(
  '[Campaign List Page], Cancel export partial OK',
  props<{ clientId: string; exportId: string; message: string }>(),
);

const cancelExportCampaignsNOK = createAction(
  '[Campaign List Page], Cancel export NOK',
  props<{
    clientId: string;
    exportId: string;
    message: string;
    error: string;
  }>(),
);

const getListCampaignsExport = createAction(
  '[Campaign List Page], Get list campaigns export',
  props<{ clientId: string }>(),
);

const getListCampaignsExportSuccess = createAction(
  '[Campaign get list exports API] Get list campaigns export success',
  props<{ clientId: string; data: CampaignExportModel[]; message: string }>(),
);

const getListCampaignsExportFailure = createAction(
  '[Campaign get list exports API] Get list campaigns export failure',
  props<{ data: {}; error: {}; message: string }>(),
);

const updateExportStatus = createAction(
  '[Campaign Export API] Update Export Status',
  props<{
    clientId: string;
    exportId: string;
    status: CAMPAIGN_EXPORT_STATUS;
    stages: CampaignStageMap[];
  }>(),
);

const updateExportStatusSuccess = createAction(
  '[Campaign Export API] Update Export Status Success',
  props<{
    clientId: string;
    exportId: string;
    status: CAMPAIGN_EXPORT_STATUS;
    stages: CampaignStageMap[];
  }>(),
);

const updateExportStatusFailure = createAction(
  '[Campaign Export API] Update Export Status Failure',
  props<{
    clientId: string;
    exportId: string;
    status: CAMPAIGN_EXPORT_STATUS;
    stages: CampaignStageMap[];
    message: string;
  }>(),
);

const downloadExportExcel = createAction(
  '[Campaign Export API] Download Excel',
  props<{
    clientId: string;
    exportId: string;
  }>(),
);

const downloadExportExcelSuccess = createAction(
  '[Campaign Export API] Download Excel Success',
  props<{
    data: Partial<CampaignExportModel>;
    message: string;
  }>(),
);

const downloadExportExcelFailure = createAction(
  '[Campaign Export API] Download Excel Failure',
  props<{
    data: Partial<CampaignExportModel>;
    error: string;
    message: string;
  }>(),
);

export const CampaignExportActions = {
  exportSelectedCampaigns,
  exportSelectedCampaignsSuccess,
  exportSelectedCampaignsFailure,
  cancelExportCampaigns,
  cancelExportCampaignsPartial,
  cancelExportCampaignsNOK,
  cancelExportCampaignsOK,
  getListCampaignsExport,
  getListCampaignsExportSuccess,
  getListCampaignsExportFailure,
  updateExportStatus,
  updateExportStatusSuccess,
  updateExportStatusFailure,
  downloadExportExcel,
  downloadExportExcelSuccess,
  downloadExportExcelFailure,
};

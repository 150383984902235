import { FileUploadModule, InputModule } from 'ssotool-shared';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';

import { CampaignImportDialogComponent } from './import-dialog.component';

@NgModule({
  declarations: [CampaignImportDialogComponent],
  exports: [CampaignImportDialogComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatFormFieldModule,
    InputModule,
    FileUploadModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  providers: [TranslatePipe],
})
export class CampaignImportDialogModule {}

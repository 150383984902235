import { Coerce } from 'ssotool-app/shared/helpers';

import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'sso-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultiSelectComponent,
      multi: true,
    },
    { provide: NG_VALIDATORS, useExisting: MultiSelectComponent, multi: true },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectComponent
  implements ControlValueAccessor, Validator, OnInit
{
  @ViewChild('multiSelect') selectElement: MatSelect;
  _options: any[];
  filteredOptions: any[];
  grouped = false;

  @Input() label: string;
  @Input() searchLabel: string;
  @Input() appearance = 'outline';
  @Input() enableSearch: boolean = true;
  @Input() multiple: boolean = true;
  @Input() set options(options: any[]) {
    this.grouped = typeof options[0] !== 'string';
    this._options = options;
    this.filteredOptions = options;
  }
  @Input() searchIconPrefix = true;
  @Input() required = false;

  /**
   * The string to strip from the option label.
   */
  @Input() stripLabel: string = '';

  control = new FormControl();
  searchControl = new FormControl();
  scrollTop = 0;

  get selected() {
    return this.multiple ? this.control.value[0] : this.control.value;
  }
  private _onChange = (value) => {};

  ngOnInit(): void {
    this.searchControl.valueChanges.subscribe((value) =>
      this.searchOptions(value),
    );
    this.control.valueChanges.subscribe((val) => this._onChange(val));
  }

  @HostListener('click')
  click() {
    this.selectElement.open();
  }

  writeValue(obj: any): void {
    this.control.patchValue(obj, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  onScroll(e) {
    this.scrollTop = e.target.scrollTop;
  }

  registerOnTouched(fn: any): void {}

  validate(control: AbstractControl): ValidationErrors {
    return !this.control || this.control.valid
      ? null
      : { error: 'Some fields are not fullfilled' };
  }

  searchOptions(value: string) {
    const filter = Coerce.toString(value).toLowerCase();
    if (this.grouped) {
      this.filteredOptions = this._options.reduce<any[]>((acc, curr) => {
        const values = curr.values.filter((option) =>
          Coerce.toString(option).toLowerCase().includes(filter),
        );
        return values.length
          ? acc.concat({
              name: curr.name,
              values,
            })
          : acc;
      }, []);
    } else {
      this.filteredOptions = this._options.filter((option) =>
        Coerce.toString(option).toLowerCase().includes(filter),
      );
    }
  }

  clearSearch() {
    this.searchControl.patchValue('');
  }

  clearFilter() {
    this.clearSearch();
    this.control.patchValue([]);
  }
}

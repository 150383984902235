import { LogDetails } from 'ssotool-app/app.model';

import { createAction, props } from '@ngrx/store';

import {
  DataImportFormModel,
  DataImportResponseModel,
} from './data-import.model';
import { TimelineType } from 'ssotool-app/+roadmap/modules/timeline/roadmap-timeline.model';

const get = createAction(
  '[Data Import Page] Get data import information',
  props<{ clientId: string; importId: string }>(),
);

const getSuccess = createAction(
  '[Client API] Get data import information success',
  props<{ data: any; message: string }>(),
);

const getFailure = createAction(
  '[Client API] Get data import information failure',
  props<{ data: any; error: any; message: string }>(),
);

const getList = createAction(
  '[Data Import Page], Get list of data import',
  props<{ clientId: string }>(),
);

const getListSuccess = createAction(
  '[Client API] Get list of data import success',
  props<{
    data: { data: DataImportResponseModel[]; clientId: string };
    message: string;
  }>(),
);

const getListFailure = createAction(
  '[Client API] Get list of data import failure',
  props<{ data: any; error: any; message: string }>(),
);

const downloadClientData = createAction(
  '[Client Import Data Page], Download Client Data',
  props<{ clientId: string; importId: string }>(),
);

const downloadClientDataSuccess = createAction(
  '[Client Import Data Page], Download Client Data Success',
  props<{ data: any; message: string }>(),
);

const downloadClientDataFailure = createAction(
  '[Client Import Data Page], Download Client Data Failure',
  props<{ data: any; error: any; message: string }>(),
);

const downloadTemplate = createAction(
  '[Client Import Data Page], Download template',
  props<{ clientId: string }>(),
);

const downloadTemplateSuccess = createAction(
  '[Client Import Data Page], Download template success',
  props<{ data: any; message: string }>(),
);

const downloadTemplateFailure = createAction(
  '[Client Import Data Page], Download template failure',
  props<{ data: any; error: any; message: string }>(),
);

const downloadErrorLogs = createAction(
  '[Client Import Data Page], Download error logs',
  props<{ clientId: string; importId: string }>(),
);

const downloadErrorLogsSuccess = createAction(
  '[Client Import Data Page], Download error logs success',
  props<{ importId: string; data: any; message: string }>(),
);

const downloadErrorLogsFailure = createAction(
  '[Client Import Data Page], Download error logs failure',
  props<{ data: any; error: any; message: string }>(),
);

const importData = createAction(
  '[Client Import Data Page], Import client profile',
  props<{
    clientId: string;
    inputFileData: DataImportFormModel;
    clientTimelineType: TimelineType;
  }>(),
);

const importDataSuccess = createAction(
  '[Client Import Data Page], Import client profile success',
  props<{ data: any; message: string }>(),
);

const importDataFailure = createAction(
  '[Client Import Data Page], Import client profile failure',
  props<{ importData: DataImportFormModel; error: any; message: string }>(),
);

const updateImportStatus = createAction(
  '[Client Import Data Page], Import status update',
  props<{
    clientId: string;
    importId: string;
    status: string;
    tdbStatus: string;
  }>(),
);

const updateImportStatusSilently = createAction(
  '[Client Import Data Page], Import status update silently',
  props<{ importId: string; status: string; tdbStatus: string }>(),
);

const deleteImport = createAction(
  '[Client Import Data Page] Delete import',
  props<{ importId: string; clientId: string }>(),
);

const deleteImportOK = createAction(
  '[Client Import Data Page] Delete import successful',
  props<{ data: any; message: string }>(),
);

const deleteImportNOK = createAction(
  '[Client Import Data Page] Delete import failed',
  props<{ data: any; message: string; error: any }>(),
);

const updateImportLogDetails = createAction(
  '[Client Import Data Page], Import log details update',
  props<{ importId: string; logDetails: LogDetails }>(),
);

export const DataImportActions = {
  get,
  getSuccess,
  getFailure,
  getList,
  getListSuccess,
  getListFailure,
  downloadClientData,
  downloadClientDataSuccess,
  downloadClientDataFailure,
  importData,
  importDataSuccess,
  importDataFailure,
  downloadTemplate,
  downloadTemplateSuccess,
  downloadTemplateFailure,
  downloadErrorLogs,
  downloadErrorLogsSuccess,
  downloadErrorLogsFailure,
  updateImportStatus,
  updateImportStatusSilently,
  deleteImport,
  deleteImportOK,
  deleteImportNOK,
  updateImportLogDetails,
};

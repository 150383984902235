import { map } from 'rxjs/operators';
import { generateEndpoint } from 'ssotool-core/utils';
import { ConfigService } from 'ssotool-shared/services/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Announcement, BackendAnnouncement } from '../stores';

@Injectable()
export class AnnouncementAPIService {
  baseUrl = '';
  endpoints;
  constructor(private http: HttpClient, private config: ConfigService) {
    const { baseUrl, endpoints } = this.config.api;
    this.baseUrl = baseUrl;
    this.endpoints = endpoints;
  }

  getList() {
    return this.http
      .get<BackendAnnouncement[]>(this.makeGetListEndpoint())
      .pipe(
        map((response) => response.map((data) => this.mapToFrontend(data))),
      );
  }

  readAnnouncement(id: string) {
    return this.http.post<BackendAnnouncement[]>(
      this.makeReadAnnouncementEndpoint(id),
      null,
    );
  }

  mapToFrontend({
    announcementId,
    ...others
  }: BackendAnnouncement): Announcement {
    return {
      ...others,
      id: announcementId,
    };
  }

  private makeGetListEndpoint() {
    return generateEndpoint(this.baseUrl, this.endpoints.announcements.getList);
  }

  private makeReadAnnouncementEndpoint(id: string) {
    return generateEndpoint(
      this.baseUrl,
      this.endpoints.announcements.read,
      id,
    );
  }
}

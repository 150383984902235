import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Trajectory } from 'ssotool-app/+client';
import { TRAJECTORY_GROUP_DEFINITIONS } from 'ssotool-app/+client/store/datahub/datahub.model';
import { PackagedDialogConfig } from 'ssotool-app/+data-management/data-import.model';
import { Coerce, DialogComponent } from 'ssotool-app/shared';
import {
  TableConfiguration,
  TableItem,
} from 'ssotool-app/shared/component/generic-table/generic-table.model';

import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'sso-additional-import-dialog',
  templateUrl: './additional-import-dialog.component.html',
  styleUrls: ['./additional-import-dialog.component.scss'],
})
export class AdditionalImportDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AdditionalImportDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public items: PackagedDialogConfig['data'],
    private matDialog: MatDialog,
  ) {
    this.configuration$.next(this.createTableConfiguration(items));
    this.listenToBackdropClicks();
  }

  private _selectedIDs = new BehaviorSubject<TableItem<Trajectory>['id'][]>([]);

  configuration$ = new BehaviorSubject<TableConfiguration<Trajectory>>({
    items: [],
    columnDefinitions: {},
    filtersToExclude: [],
  });

  private createTableConfiguration(
    items: PackagedDialogConfig['data'],
  ): TableConfiguration<Trajectory> {
    return {
      items: items.dialogData.trajectories,
      columnDefinitions: {
        selection: {
          id: 'selection',
          name: '',
          type: 'selection',
        },
        fluidName: {
          id: 'fluidName',
          name: 'Fluid Name',
          type: 'text',
          labelKey: 'fluidName',
        },
        geoName: {
          id: 'geoName',
          name: 'Geo Name',
          type: 'text',
          labelKey: 'geoName',
        },
        scenarioName: {
          id: 'scenarioName',
          name: 'Scenario Name',
          type: 'text',
          labelKey: 'scenarioName',
        },
        granularity: {
          id: 'granularity',
          name: 'Granularity',
          type: 'text',
          labelKey: 'granularity',
        },
        values: {
          id: 'values',
          name: 'Values',
          type: 'sparkline',
          labelKey: 'preview',
        },
      },
      filtersToExclude: [
        'clientId',
        'companyEntityId',
        'dataType',
        'fluidId',
        'geoId',
        'isCustom',
        'isTarget',
        'name',
        'owner',
        'processId',
        'quantityId',
        'sectorId',
        'trajectoryId',
        'values',
        'version',
        'companyName',
        'processName',
        'quantityName',
        'sectorName',
        'unit',
        'willOverwrite',
      ],
      selectionGroups: TRAJECTORY_GROUP_DEFINITIONS,
    };
  }

  onSelectionChange(selections: TableItem<Trajectory>['id'][]): void {
    this._selectedIDs.next(selections);
  }

  onSaveSelected(): void {
    this.dialogRef.close(this._selectedIDs.value);
  }

  showSaveButtonLabel$(): Observable<string> {
    return this._selectedIDs.pipe(
      map((ids) => {
        return Coerce.toArray(ids).length
          ? 'Client.labels.saveTrajectories'
          : 'Client.labels.saveNoTrajectories';
      }),
    );
  }

  private listenToBackdropClicks(): void {
    this.dialogRef.backdropClick().subscribe((mouseEvent) => {
      this.matDialog
        .open(DialogComponent, {
          data: {
            title: 'Client.labels.clientImport',
            message: 'Client.messages.skipSaveTrajectories',
            confirm: 'Generic.labels.yes',
            close: 'Client.labels.reviewTrajectories',
            disableClose: false,
            width: '250px',
            client: {},
          },
        })
        .afterClosed()
        .subscribe((confirmed) => {
          if (!!confirmed) {
            this.dialogRef.close([]);
          }
        });
    });
  }
}

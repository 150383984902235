import { CampaignConverter } from 'ssotool-app/+campaign/store';
import { YearlyValuesBackend, YearlyValuesMapper } from 'ssotool-shared';

import { CampaignBaseMapper } from './campaign-base.mapper';

export interface BackendEfficiency {
  inputFluidId: string;
  outputFluidId: string;
  efficiency: YearlyValuesBackend[];
}

export class ConverterMapper {
  static mapToFrontend = (data: any): CampaignConverter =>
    !!data
      ? ({
          ...CampaignBaseMapper.mapToFrontend(data),
          processId: data.processId,
          scale: data.scale,
          productionFluids: data.productionFluids.map((_d) => ({
            fluidId: _d.fluidId,
            efficiencies: YearlyValuesMapper.mapToFrontend(_d.rate),
          })),
          consumptionFluids: data.consumptionFluids.map((_d) => ({
            fluidId: _d.fluidId,
            efficiencies: YearlyValuesMapper.mapToFrontend(_d.rate),
          })),
          mainConsumptionFluidId: data.mainConsumptionFluidId,
          mainProductionFluidId: data.mainProductionFluidId,
          minCapacity: YearlyValuesMapper.mapToFrontend(data.minCapacity),
          maxCapacity: YearlyValuesMapper.mapToFrontend(data.maxCapacity),
          minimumOperatingHours: YearlyValuesMapper.mapToFrontend(
            data.minOperatingHrs,
          ),
          technicalLife: YearlyValuesMapper.mapToFrontend(data.technicalLife),
          depreciationTime: YearlyValuesMapper.mapToFrontend(
            data.depreciationTime,
          ),
          implementationTime: YearlyValuesMapper.mapToFrontend(
            data.implementationTime,
          ),
          buildCo2Scope3Emissions: YearlyValuesMapper.mapToFrontend(
            data.buildCo2Scope3Emissions,
          ),
          indivisibleCo2Scope3Emissions: YearlyValuesMapper.mapToFrontend(
            data.indivisibleCo2Scope3Emissions,
          ),
          buildCost: YearlyValuesMapper.mapToFrontend(data.buildCost),
          indivisibleCost: YearlyValuesMapper.mapToFrontend(
            data.indivisibleCost,
          ),
          foM: YearlyValuesMapper.mapToFrontend(data.foM),
          foMPerInstall: YearlyValuesMapper.mapToFrontend(data.foMPerInstall),
        } as any)
      : {};

  static mapToBackend = (data: CampaignConverter) =>
    !!data
      ? {
          ...CampaignBaseMapper.mapToBackend(data),
          scale: data.scale,
          processId: data.processId,
          consumptionFluids: data.consumptionFluids.map((_d) => ({
            fluidId: _d.fluidId,
            rate: YearlyValuesMapper.mapToBackend(_d.efficiencies),
          })),
          productionFluids: data.productionFluids.map((_d) => ({
            fluidId: _d.fluidId,
            rate: YearlyValuesMapper.mapToBackend(_d.efficiencies),
          })),
          mainConsumptionFluidId: data.mainConsumptionFluidId,
          mainProductionFluidId: data.mainProductionFluidId,
          minCapacity: YearlyValuesMapper.mapToBackend(data.minCapacity),
          maxCapacity: YearlyValuesMapper.mapToBackend(data.maxCapacity),
          minOperatingHrs: YearlyValuesMapper.mapToBackend(
            data.minimumOperatingHours,
          ),
          technicalLife: YearlyValuesMapper.mapToBackend(data.technicalLife),
          depreciationTime: YearlyValuesMapper.mapToBackend(
            data.depreciationTime,
          ),
          implementationTime: YearlyValuesMapper.mapToBackend(
            data.implementationTime,
          ),
          buildCo2Scope3Emissions: YearlyValuesMapper.mapToBackend(
            data.buildCo2Scope3Emissions,
          ),
          indivisibleCo2Scope3Emissions: YearlyValuesMapper.mapToBackend(
            data.indivisibleCo2Scope3Emissions,
          ),
          buildCost: YearlyValuesMapper.mapToBackend(data.buildCost),
          indivisibleCost: YearlyValuesMapper.mapToBackend(
            data.indivisibleCost,
          ),
          foM: YearlyValuesMapper.mapToBackend(data.foM),
          foMPerInstall: YearlyValuesMapper.mapToBackend(data.foMPerInstall),
        }
      : {};
}

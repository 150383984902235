import { BehaviorSubject } from 'rxjs';
import { Coerce } from 'ssotool-app/shared/helpers';

import { Component, Input } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import {
  FilterDialogForm,
  FiltersDialogComponent,
  FiltersDialogConfiguration,
} from '../filters-dialog';
import {
  FilterForm,
  FilterOptions,
  FilterWithCondition,
} from './filters.model';

@Component({
  selector: 'sso-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FiltersComponent, multi: true },
  ],
})
export class FiltersComponent implements ControlValueAccessor {
  fields: string[];
  filterOptions$ = new BehaviorSubject<FilterOptions>({});
  disableFilter = new BehaviorSubject<boolean>(false);
  formDialog: FormControl = this.formBuilder.control({});
  form: FormGroup = this.formBuilder.group({});
  flexValue: number = 19.2;
  disableFilter$ = this.disableFilter.asObservable();

  @Input() mode: 'chips' | 'multiselect' | 'dialog' = 'chips';
  @Input() set options(filterOptions: FilterOptions) {
    this.fields = Coerce.getObjKeys(filterOptions || {});
    this.flexValue = 100 / this.fields.length - 1;
    this.filterOptions$.next(filterOptions || {});
    this.initializeForm();
  }

  /**
   * The string to strip from the option label.
   */
  @Input() stripLabel: string;
  @Input() title: string = '';
  @Input() set disable(disable: boolean) {
    this.disableFilter.next(disable);
  }

  @Input() dialogConfig: FiltersDialogConfiguration;

  get filterOptions() {
    return this.filterOptions$.value;
  }

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog) {}

  writeValue(filters: any): void {
    if (this.mode === 'dialog') {
      this.formDialog.patchValue(filters, { emitEvent: false });
    } else if (
      this.fields.every((field) => Coerce.getObjKeys(filters).includes(field))
    ) {
      this.form.patchValue(filters, { emitEvent: false });
    }
  }

  registerOnTouched(fn: any): void {}

  registerOnChange(fn: any): void {
    if (this.mode === 'dialog') {
      this.formDialog.valueChanges.subscribe((value) => fn(value));
    } else {
      this.form.valueChanges.subscribe((value) => fn(value));
    }
  }

  initializeForm() {
    this.formDialog.patchValue(
      this.fields.reduce((acc, field) => {
        if (field && !this.form.controls[field]) {
          this.form.addControl(field, new FormControl([]));
        }

        acc[field] = {};
        return acc;
      }, {}),
    );
  }

  onFieldChipsChange(field: string, selected: string[]) {
    this.form.controls[field].patchValue(selected);
  }

  onDialogClick() {
    this.dialog
      .open(FiltersDialogComponent, {
        data: {
          title: this.title,
          filterOptions: this.filterOptions,
          dialogConfig: this.dialogConfig,
          initialValue: Object.entries(this.formDialog.value).map(
            ([key, value]) => ({
              filterName: key,
              filterCondition: (value as FilterForm).condition,
              filterValue: (value as FilterForm).values,
            }),
          ),
        },
      })
      .afterClosed()
      .subscribe((data: FilterDialogForm[]) => {
        if (data) {
          this.formDialog.patchValue(
            (data || []).reduce<FilterWithCondition>((acc, item) => {
              acc[item.filterName] = {
                condition: item?.filterCondition,
                values: item?.filterValue,
              };

              return acc;
            }, {}),
          );
        }
      });
  }
}

import { diff, Diff } from 'deep-diff';
import { Coerce } from 'ssotool-app/shared/helpers/coerce.utils';

export const iterAssets = <T = any>(result: {}, assets: T) => {
  Object.entries(assets).forEach(([key, value]) => {
    result[key] = value.name;
  });
};

/**
 * Accumulate the updates on an object.
 * @param sums The sums object to update
 * @param updates The object containing the updates
 * @returns Updated sums object
 */
export const updateSums = (
  sums: Record<string, number>,
  updates: Record<string, number>,
) => {
  return Object.entries(updates).forEach(([key, value]) => {
    if (key in sums) {
      sums[key] += value;
    } else {
      sums[key] = value;
    }
  });
};

const isIncluded = <T>(toInclude: string[], diff: Diff<T, T>): boolean => {
  const intersection =
    toInclude?.filter((value) => diff.path?.includes(value)) || [];

  if (intersection.length) {
    return true;
  }
  return false;
};

export const checkObjectDifferences = <T>(
  source: T,
  target: T,
  toInclude?: string[],
): Diff<T, T>[] => {
  return diff(source, target)?.filter((diff) =>
    isIncluded(toInclude || [], diff),
  );
};

export const retrieveParentHids = (hid: string): string[] =>
  (hid?.split('-') || [])
    .reduce((acc, _, index, arr) => {
      acc.push(
        Array(index)
          .fill(0)
          .map((_, i) => arr[i])
          .join('-'),
      );
      return acc;
    }, [])
    ?.filter((hid) => !!hid);

export const swapObjectKeyAndValue = (data: Record<string, any>) =>
  Object.entries(data || {}).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
  }, {});

export const doNothing = () => {};

/**
 * Returns a list of values based on the key name parameter. Note that the source array is an array of objects.
 * @param objArray - array of ojects
 * @param key - key name to be filtered
 */
export const collectItemsWithKey = (
  objArray: Array<any>,
  key: string = 'key',
) => {
  const arr = [];
  objArray.forEach((obj) => {
    arr.push(obj[key]);
  });
  return arr;
};

/**
 * used to locate a specific item in an array, returns boolean type
 * @param arr - the list
 * @param value - value of the item you want to find from the string
 */
export const contains = (arr: Array<any> | string, value: any): boolean => {
  return arr.indexOf(value) !== -1;
};

/**
 * converts the object to list
 * @param obj - the json object to be converted to list
 */
export const toList = (obj: any): Array<any> => {
  if (obj) {
    return Object.keys(obj).map((key) => obj[key]);
  }
  return [];
};

/**
 * is used to interpret the load profile from backend to ui.
 * the oposite function of stringToArray.
 * @param value - the array of string
 * @param separator - the separator '\n'
 */
export const arrayToString = (
  value: Array<string>,
  separator: string = '\n',
) => {
  if (!!!value) {
    return '';
  }
  return value.join(separator);
};

/**
 * match items of array, if one item from arr1 matches arr2, return true
 * @param arr1 - string array 1
 * @param arr2 - string array 2
 */
export const matchArrays = (
  arr1: Array<string>,
  arr2: Array<string>,
): boolean => {
  let match = false;
  arr1.forEach((item) => {
    if (contains(arr2, item)) {
      match = true;
      return;
    }
  });
  return match;
};

/**
 * Must return true only if all items of each array matches
 * @param arr1 - string array 1
 * @param arr2 - string array 2
 */
export const compareArrays = (
  arr1: Array<string>,
  arr2: Array<string>,
): boolean => {
  let match = false;
  if (arr1 && arr2) {
    if (arr1.length === arr2.length) {
      arr1.forEach((item) => {
        if (contains(arr2, item)) {
          match = true;
        } else {
          match = false;
        }
      });
    }
  }
  return match;
};

export const createRangeArray = (start: number, end: number): Array<string> =>
  Array(end - start + 1)
    .fill(0)
    .map((_, i) => (i + Coerce.toZero(start)).toString());

export const isObjectEmpty = (obj: Object): boolean => {
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return true;
};

/**
 * Must return true only if all items of object is null,
 * will return **false** on empty obj
 * @param obj - Object
 */
export const isObjectValuesAllNull = (obj: Object): boolean => {
  let set = new Set(Object.values(obj));
  const [firstItem] = set;
  return set.size === 1 && firstItem === null;
};

/**
 * Must return true only if all items of object is empty string,
 * will return **false** on empty obj
 * @param obj - Object
 */
export const isObjectValuesAllEmptyString = (obj: Object): boolean => {
  let set = new Set(Object.values(obj));
  const [firstItem] = set;
  return set.size === 1 && firstItem === '';
};

interface TypeWithID {
  readonly id: string;
}

/**
 * Transforms an array to an object with id of items as keys
 * @param arr - Array of any type with id in it
 */
export const convertArrayToIdKeyObj = <T extends TypeWithID>(
  arr: Array<T>,
): Record<string, T> => {
  return arr.reduce((map, item) => {
    map[item.id] = item;
    return map;
  }, {});
};

import { environment } from 'ssotool-env';

// istanbul ignore next
export const redirect = (url: string) => {
  window.location.assign(url);
  return false;
};

// istanbul ignore next
export const redirectLogin = () => {
  const identityProvider = environment.cognito.Auth.userPoolIdentityProvider;
  const domain = environment.cognito.Auth.oauth.domain;
  const clientId = environment.cognito.Auth.userPoolWebClientId;
  const redirectSignIn = environment.cognito.Auth.oauth.redirectSignIn;
  const responseType = environment.cognito.Auth.oauth.responseType;

  let url = `https://${domain}/login?redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${clientId}`;
  // istanbul ignore else
  if (identityProvider) {
    // istanbul ignore next
    url =
      `https://${domain}/oauth2/authorize?redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${clientId}` +
      `&identity_provider=${identityProvider}`;
  }
  return redirect(url);
};

// istanbul ignore next
export const redirectHome = () => redirect(environment.homeUrl);

// istanbul ignore next
export const redirectFAQ = () => redirect(environment.faqUrl);

// istanbul ignore next
export const redirectTraining = () => redirect(environment.trainingUrl);

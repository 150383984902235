import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';

import { combineLatest } from 'rxjs';
import { map, first } from 'rxjs/operators';

import { CompareVariationQueryParam } from 'ssotool-roadmap/modules/compare-variations/compare-variations.model';
import { ResultFacadeService } from 'ssotool-roadmap/stores/result/result-facade.service';
import { ResultGetParameters } from 'ssotool-roadmap/stores/result/result-parameters.model';
import { RoadmapVariationFacadeService } from 'ssotool-roadmap/stores/roadmap-variation/roadmap-variation.facade.service';

@Injectable()
export class RoadmapVariationCompareResolver implements Resolve<any> {
  constructor(
    private readonly resultVariationService: ResultFacadeService,
    private readonly roadmapVariationService: RoadmapVariationFacadeService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const queryParams = route.queryParams as CompareVariationQueryParam;
    const compareData = JSON.parse(queryParams.compareData);

    compareData.forEach((roadmapWithVariations) =>
      roadmapWithVariations.variations.forEach((variationId) => {
        this.fetchVariationResults({
          clientId: queryParams.clientId,
          roadmapId: roadmapWithVariations.roadmapId,
          variationId,
        });
      }),
    );
  }

  private fetchVariationResults(params: ResultGetParameters): void {
    this.roadmapVariationService.get({
      clientId: params.clientId,
      roadmapId: params.roadmapId,
      variationId: params.variationId,
    });
    combineLatest([
      this.resultVariationService
        .getResultVariationLoaded(params.roadmapId, params.variationId)
        .pipe(first()),
      this.resultVariationService
        .getResultVariationLoading(params.roadmapId, params.variationId)
        .pipe(first()),
    ])
      .pipe(
        map(([loaded, loading]) => {
          if (!(loaded || loading)) {
            this.resultVariationService.get(params);
          }
        }),
      )
      .subscribe();
  }
}

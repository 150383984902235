import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { generateEndpoint } from 'ssotool-core/utils';
import { ConfigService } from 'ssotool-shared/services/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CloudWatchLoggerConfig } from '@oculus/utils';

import { LogData, RoadmapLogCredentials } from './credentials.model';

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  constructor(private _http: HttpClient, private _config: ConfigService) {}

  fetchRoadmapLogCredentials(
    clientId: string,
    id: string,
    variationId: string,
  ) {
    return this._http
      .get<RoadmapLogCredentials>(
        generateEndpoint(
          this._config.api.baseUrl,
          this._config.api.endpoints.portfolio.getLogCredentials,
          clientId,
          id,
          variationId,
        ),
      )
      .pipe(
        map((response) => response),
        catchError((error) => throwError(error)),
      );
  }

  fetchLogCredentials({ clientId, logType, body }: LogData) {
    return this._http
      .post<CloudWatchLoggerConfig>(
        generateEndpoint(
          this._config.api.baseUrl,
          this._config.api.endpoints.clients.getLogCredentials,
          clientId,
        ),
        body,
        {
          params: {
            logType,
          },
        },
      )
      .pipe(
        map((response) => response),
        catchError((error) => throwError(error)),
      );
  }
}

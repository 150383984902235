import { Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DatahubAPIFacadeService } from './datahub-api.facade.service';
import { DatahubActions } from './datahub.actions';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class DatahubEffects {
  selectTrajectories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DatahubActions.chooseTrajectoriesToSave),
      mergeMap((actionProps) => {
        return this.datahubApiService
          .chooseTrajectoriesToSave(
            actionProps.clientId,
            actionProps.importId,
            actionProps.selectedIds,
          )
          .pipe(
            map(() => DatahubActions.chooseTrajectoriesToSaveSuccess()),
            catchError((error) => {
              return of(
                DatahubActions.chooseTrajectoriesToSaveFailed({
                  error: { message: error },
                }),
              );
            }),
          );
      }),
    );
  });

  selectTrajectoriesFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DatahubActions.chooseTrajectoriesToSaveFailed),
        tap((actionProps) => {
          return this.toastService.error(actionProps.error.message);
        }),
      );
    },
    { dispatch: false },
  );

  selectTrajectoriesSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DatahubActions.chooseTrajectoriesToSaveSuccess),
        tap(() => {
          this.toastService.success('Trajectories selected successfully');
        }),
      );
    },
    { dispatch: false },
  );

  getTDBCommodities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DatahubActions.getTDBCommodities),
      mergeMap((action) =>
        this.datahubApiService
          .getCommodities(action.clientId, action.importId)
          .pipe(
            map((response) =>
              DatahubActions.getTDBCommoditiesSuccess({
                data: response,
              }),
            ),
            catchError((error) =>
              of(
                DatahubActions.getTDBCommoditiesFailure({
                  data: [],
                  error:
                    (error.error || {}).error ||
                    'Get TDB Commodities data error!',
                  message:
                    (error.error || {}).error ||
                    'Get TDB Commodities data error!',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getTDBCommoditiesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DatahubActions.getTDBCommoditiesFailure),
        tap((action) => {
          this.toastService.error(action.message);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private datahubApiService: DatahubAPIFacadeService,
    private toastService: HotToastService,
  ) {}
}

import { CampaignMarket } from 'ssotool-app/+campaign/store/campaign.model';
import { YearlyValuesMapper } from 'ssotool-shared';

import { CampaignBaseMapper } from './campaign-base.mapper';

export class MarketMapper {
  static mapToFrontend = (data: any): CampaignMarket =>
    !!data
      ? ({
          ...CampaignBaseMapper.mapToFrontend(data),
          processId: data.processId,
          fluidId: data.fluidId,
          minVolume: YearlyValuesMapper.mapToFrontend(data.minVolume),
          maxVolume: YearlyValuesMapper.mapToFrontend(data.maxVolume),
          duration: data.duration,
          co2RateScope1: YearlyValuesMapper.mapToFrontend(data.co2RateScope1),
          co2RateScope2: YearlyValuesMapper.mapToFrontend(data.co2RateScope2),
          co2RateScope3: YearlyValuesMapper.mapToFrontend(data.co2RateScope3),
          fixedPrice: YearlyValuesMapper.mapToFrontend(data.fixedPrice),
          indivisibleCost: YearlyValuesMapper.mapToFrontend(
            data.indivisibleCost,
          ),
          tax: YearlyValuesMapper.mapToFrontend(data.tax),
          pricingMethod: data.pricingMethod,
          sizingMethod: data.sizingMethod,
        } as CampaignMarket)
      : ({} as CampaignMarket);

  static mapToBackend = (data: CampaignMarket) =>
    !!data
      ? {
          ...CampaignBaseMapper.mapToBackend(data),
          processId: data.processId,
          fluidId: data.fluidId,
          minVolume: YearlyValuesMapper.mapToBackend(data.minVolume),
          maxVolume: YearlyValuesMapper.mapToBackend(data.maxVolume),
          duration: data.duration ? Number(data.duration) : 0,
          co2RateScope1: YearlyValuesMapper.mapToBackend(data.co2RateScope1),
          co2RateScope2: YearlyValuesMapper.mapToBackend(data.co2RateScope2),
          co2RateScope3: YearlyValuesMapper.mapToBackend(data.co2RateScope3),
          fixedPrice: YearlyValuesMapper.mapToBackend(data.fixedPrice),
          indivisibleCost: YearlyValuesMapper.mapToBackend(
            data.indivisibleCost,
          ),
          tax: YearlyValuesMapper.mapToBackend(data.tax),
          pricingMethod: data.pricingMethod,
          sizingMethod: data.sizingMethod,
        }
      : {};
}

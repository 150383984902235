import { Coerce } from 'ssotool-app/shared/helpers';

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { RoadmapVariationActions } from './roadmap-variation.actions';
import {
  PartialRoadmapVariation,
  RoadmapVariationRunMap,
} from './roadmap-variation.model';

export const ROADMAP_VARIATION_FEATURE_NAME = 'Roadmap Variation';

export interface RoadmapVariationState
  extends EntityState<PartialRoadmapVariation> {
  loading: boolean;
  loaded: boolean;
}

export const createRoadmapVariationId = ({
  roadmapId,
  variationId,
}: PartialRoadmapVariation) => `${roadmapId}-${variationId}`;

export const roadmapVariationAdapter: EntityAdapter<PartialRoadmapVariation> =
  createEntityAdapter<PartialRoadmapVariation>({
    selectId: (variation) => createRoadmapVariationId(variation),
  });

export const initialRoadmapVariationState: RoadmapVariationState =
  roadmapVariationAdapter.getInitialState({
    ids: [],
    entities: {},
    loading: false,
    loaded: false,
  });

const roadmapVariationReducer = createReducer(
  initialRoadmapVariationState,
  //ACCESS
  on(
    RoadmapVariationActions.accessRoadmapVariationAction,
    (state, { roadmapId, variationId }) =>
      roadmapVariationAdapter.upsertOne(
        { roadmapId, variationId, accessed: true },
        state,
      ),
  ),
  on(RoadmapVariationActions.get, (state, { roadmapId, variationId }) =>
    roadmapVariationAdapter.upsertOne(
      { roadmapId, variationId, loading: true },
      state,
    ),
  ),
  on(RoadmapVariationActions.getSuccess, (state, { data }) =>
    roadmapVariationAdapter.upsertOne(
      { ...data, loading: false, loaded: true },
      state,
    ),
  ),
  on(RoadmapVariationActions.getFailed, (state, { roadmapId, variationId }) =>
    roadmapVariationAdapter.upsertOne(
      { roadmapId, variationId, loading: false, loaded: false },
      state,
    ),
  ),
  on(RoadmapVariationActions.getList, (state) => ({
    ...state,
    loading: true,
  })),
  on(RoadmapVariationActions.getListSuccess, (state, { data }) =>
    roadmapVariationAdapter.upsertMany(
      data.map((variation) => ({ ...variation, loaded: true })),
      { ...state, loaded: true, loading: false },
    ),
  ),
  on(RoadmapVariationActions.getListFailed, (state) => ({
    ...state,
    loading: false,
    loaded: false,
  })),
  on(
    RoadmapVariationActions.reloadRoadmapVariationSilentlyAction,
    (state, { roadmapId, variationId, status, feStatus }) =>
      roadmapVariationAdapter.upsertOne(
        {
          roadmapId,
          variationId,
          beStatus: status,
          feStatus,
          run: {
            ...(state.entities[
              createRoadmapVariationId({ roadmapId, variationId })
            ]?.run
              ? {
                  ...state.entities[
                    createRoadmapVariationId({ roadmapId, variationId })
                  ]?.run,
                  status,
                }
              : ({ status: status } as RoadmapVariationRunMap)),
          },
        },
        state,
      ),
  ),
  on(
    RoadmapVariationActions.reloadMultipleRoadmapSilentlyAction,
    (state, { roadmapIds, beStatus, feStatus }) => {
      const entitiesToUpdate = Coerce.getObjValues(
        state?.entities || {},
      ).filter((entity) =>
        roadmapIds.some((id) => entity?.roadmapId.startsWith(id)),
      );

      return roadmapVariationAdapter.upsertMany(
        entitiesToUpdate.map((entity) => ({ ...entity, beStatus, feStatus })),
        state,
      );
    },
  ),
);

export const RoadmapVariationStore = {
  featureName: ROADMAP_VARIATION_FEATURE_NAME,
  reducer: roadmapVariationReducer,
};

export function RoadmapVariationReducer(
  state: RoadmapVariationState | undefined,
  action: Action,
) {
  return roadmapVariationReducer(state, action);
}

import { HierarchicalFilter } from 'ssotool-app/+roadmap/stores';
import {
  PieMetadata,
  SankeyMetadata,
  StackedBarMetadata,
  WaterfallMetadata,
} from 'ssotool-shared';

export type ChartInterpretation =
  | Array<PieMetadata>
  | StackedBarMetadata
  | Array<WaterfallMetadata>
  | SankeyMetadata;

export enum ChartType {
  PIE = 'pie',
  STACKED_BAR = 'stacked_bar',
  WATERFALL = 'waterfall',
  STACKED_BAR_RELATIVE = 'stacked_bar_relative',
  // CUSTOM_WIDTH_BAR = 'custom_width_bar',
  SANKEY = 'sankey',
}
export interface CurveFormModel {
  readonly kpi: string;
  readonly subKpi?: string[];
  readonly curveType?: string;
  readonly splitBy?: HierarchicalFilter;
  readonly enumerateBy?: HierarchicalFilter;
  readonly filters?: any;
  readonly indicatorField?: string;
  readonly xAxis?: GroupedOption;
  readonly geography?: string;
  readonly year?: string;
  readonly variationId: string;
}

export type YearValueTuple = [string, string];

export interface GroupedOption {
  group: string;
  level: string;
}

export enum FilterConditionValue {
  IS = 'is',
  IS_NOT = 'is not',
  IS_PART_OF = 'is part of',
}

export type FiltersDialogData = Readonly<{
  title: string;
  filterOptions: Record<string, string[]>;
  dialogConfig: FiltersDialogConfiguration;
  initialValue: FilterInitialData[];
}>;

export type FilterDialogForm = Readonly<{
  filterName: string;
  filterCondition: FilterConditionValue;
  filterValue: string[];
}>;

export interface FilterInitialData {
  filterName: string;
  filterCondition: FilterConditionValue | undefined;
  filterValue: string[] | undefined;
}

export enum FiltersDialogConditionMode {
  DIRECT = 'direct',
  WITH_GROUP = 'withGroup',
  GROUP_ONLY = 'groupOnly',
  ALL = 'all',
}
export interface FiltersDialogConfiguration {
  readonly conditionMode?: Record<string, FiltersDialogConditionMode>;
}

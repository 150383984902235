import { BehaviorSubject } from 'rxjs';

export abstract class StateManager<T> {
  private state = new BehaviorSubject<T>(this.initialState());

  state$ = this.state.asObservable();

  abstract initialState(): T;

  setNextState(nextState: T) {
    this.state.next({
      ...this.getCurrentState(),
      ...nextState,
    });
  }

  getCurrentState() {
    return {
      ...this.state.value,
    };
  }
}

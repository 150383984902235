import { tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { ClientFacadeService } from '../store';

@Injectable()
export class ClientResolverService implements Resolve<any> {
  constructor(private clientFacade: ClientFacadeService) {}

  resolve(route: ActivatedRouteSnapshot) {
    this.clientFacade.loaded$
      .pipe(tap((loaded) => !loaded && this.clientFacade.getList()))
      .subscribe();
  }
}

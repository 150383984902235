/* eslint-disable no-console */
import { EnvConfig } from 'ssotool-core/config';
import { ENV_CONFIG } from 'ssotool-core/tokens';

import { Inject, Injectable } from '@angular/core';

enum LogType {
  DEBUG = '#4CC355',
  INFO = '#10ADED',
  ERROR = '#FA113D',
  WARN = '#FB1',
}

@Injectable({ providedIn: 'root' })
export class LoggerService {
  isProduction: boolean;

  constructor(@Inject(ENV_CONFIG) environment: EnvConfig) {
    if (environment) {
      this.isProduction = environment.production;
    }
  }

  debug(message: any, ...params): void {
    if (this.isProduction) {
      return;
    }
    this.log(LogType.DEBUG, message, params);
  }

  error(message: any, ...params): void {
    this.log(LogType.ERROR, message, params);
  }

  info(message: any, ...params): void {
    this.log(LogType.INFO, message, params);
  }

  warn(message: any, ...params): void {
    this.log(LogType.WARN, message, params);
  }

  findKey = (obj: any, value: string): any => {
    return Object.keys(obj).find((key) => obj[key] === value);
  };

  private log(type: LogType, message: any, params): void {
    const key = this.findKey(LogType, type);
    if (params && params.length > 0) {
      console.group(`%c ${key}:`, `color: ${type};`, message);
      (params as Array<any>).forEach((data) => console.log(data));
      console.groupEnd();
    } else {
      console.log(`%c ${key}:`, `color: ${type}; font-weight: bold`, message);
    }
  }
}

import { Coerce } from 'ssotool-app/shared/helpers/coerce.utils';
import {
  FINAL_ENERGY_CONSUMPTION,
  PRIMARY_ENERGY_SUPPLY,
} from '../modules/results/analysis/curves/kpi-curve-v2/kpi-curve.references';
import {
  ResultReferenceCampaign,
  ResultReferenceEntities,
} from '../stores/result';
import { BaseGraphMapper } from './base-graph.mapper';

export class SustainabilityCurvesMapper extends BaseGraphMapper {
  static mapSustainabilityCurves = (
    data,
    entities: ResultReferenceEntities,
    clientData,
    campaignData: ResultReferenceCampaign,
  ) => {
    const unitConsumption = data?.['Consumption']?.[0]?.unit || '';
    const unitProduction = data?.['Production']?.[0]?.unit || '';
    const unitFinalEnergyConsumption =
      data?.[FINAL_ENERGY_CONSUMPTION.name]?.[0]?.unit || '';
    const unitPrimaryEnergySupply =
      data?.[PRIMARY_ENERGY_SUPPLY.name]?.[0]?.unit || '';

    return {
      totalEnergyConsumption: {
        data: SustainabilityCurvesMapper.mapEntitiesNameAndIndex(
          data?.['Consumption'],
          entities,
          'totalEnergyConsumption',
          data.index,
          clientData,
          campaignData,
        ),
        unit: unitConsumption,
      },
      totalEnergyProduction: {
        data: SustainabilityCurvesMapper.mapEntitiesNameAndIndex(
          data?.['Production'],
          entities,
          'totalEnergyProduction',
          data.index,
          clientData,
          campaignData,
        ),
        unit: unitProduction,
      },
      primaryEnergySupply: {
        data: SustainabilityCurvesMapper.mapEntitiesNameAndIndex(
          Coerce.toArray(data[PRIMARY_ENERGY_SUPPLY.name]),
          entities,
          PRIMARY_ENERGY_SUPPLY.value,
          data.index,
          clientData,
          campaignData,
        ),
        unit: unitPrimaryEnergySupply,
      },
      finalEnergyConsumption: {
        data: SustainabilityCurvesMapper.mapEntitiesNameAndIndex(
          Coerce.toArray(data[FINAL_ENERGY_CONSUMPTION.name]),
          entities,
          FINAL_ENERGY_CONSUMPTION.value,
          data.index,
          clientData,
          campaignData,
        ),
        unit: unitFinalEnergyConsumption,
      },
    };
  };

  static mapSustainabilityCurvesv2 = (data) => {
    return {
      totalEnergyConsumption: data?.['Consumption'],
      totalEnergyProduction: data?.['Production'],
    };
  };
}

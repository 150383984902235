import { NgModule, ModuleWithProviders } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { pageFeatureName } from './page.actions';
import { pageReducer } from './page.reducer';
import { PageStore } from './page-store.service';
import { PageEffects } from './page.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(pageFeatureName, pageReducer),
    EffectsModule.forFeature([PageEffects]),
  ]
})
export class PageModule {
  static forRoot(): ModuleWithProviders<PageModule> {
    return {
      ngModule: PageModule,
      providers: [
        PageStore
      ]
    };
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChartState } from './charts.reducer';

export const chartFeatureState = createFeatureSelector<ChartState>('Chart');

export const selectColorByLabel = (label: string) =>
  createSelector(chartFeatureState, (state) => state.colorMap[label]);

export const selectLabelColorDictionary = () =>
  createSelector(chartFeatureState, (state) => state.colorMap);

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Observer,
  catchError,
  map,
  mergeMap,
  skipWhile,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { generateEndpoint } from 'ssotool-app/core/utils/api.util';
import { ConfigService } from 'ssotool-app/shared/services/config';
import {
  ConverterErrorLogs,
  ConverterNotifType,
  ConverterStatus,
  ConverterErrorLogJSON,
  ImportFileResponse,
} from './input-converter.model';
import {
  CONVERTER_BE_SUCCESS_STATUS,
  INPUT_CONVERTER_OUTPUT_FILENAME,
} from 'ssotool-app/app.references';
import { download } from 'ssotool-app/shared/services/download/download.service';

@Injectable({
  providedIn: 'root',
})
export class InputConverterService {
  constructor(private http: HttpClient, private config: ConfigService) {}

  private _status = new BehaviorSubject<ConverterStatus>('ready');
  status$ = this._status as Observable<ConverterStatus>;

  processing$ = this.status$.pipe(map((status) => status === 'processing'));

  done$ = this.status$.pipe(
    map((status) => ['success', 'failed'].includes(status)),
  );

  private _token: string = '';

  private _errorLogs = new BehaviorSubject<ConverterErrorLogs[]>([]);
  errorLogs$ = this._errorLogs as Observable<ConverterErrorLogs[]>;

  importData(clientId: string, file: File) {
    return this.http
      .get(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.inputConverter.getImportSignedURL,
          clientId,
        ),
      )
      .pipe(
        tap(() => {
          this._status.next('processing');
        }),
        mergeMap((importFileResponse: ImportFileResponse) =>
          this.http.put(importFileResponse.signedUrl, file).pipe(
            tap(() => {
              this._token = importFileResponse.token;
            }),
          ),
        ),
        catchError((error) => {
          this._status.next('failed');
          return throwError(() => new Error(error));
        }),
      )
      .subscribe();
  }

  reset(): void {
    this._status.next('ready');
    this._token = '';
  }

  /**
   * Sets corresponding flags after processing, before downloading zip or logs.
   * Downloads zipped output regardless of status according to previous input converter.
   * @param obj container for URLs, token, status
   */
  processOutput({
    outputUrl,
    logUrl,
    token,
    status,
  }: ConverterNotifType): void {
    // check to prevent other sessions from processing this notif
    if (!!this._token && this._token === token) {
      if (status === CONVERTER_BE_SUCCESS_STATUS) {
        this._status.next('success');
      } else {
        this._status.next('failed');
        this.fetchErrorLogs(logUrl);
      }

      if (outputUrl) {
        this.downloadConvertedFile(outputUrl);
      }
    }
  }

  /**
   * Requests and downloads zipped output
   * @param signedUrl
   */
  downloadConvertedFile(signedUrl: string): void {
    this.http
      .get(signedUrl)
      .pipe(
        map((response: any) => {
          const blob = new Blob([response], {
            type: 'applications/octet-stream',
          });
          const resUrl = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.download = INPUT_CONVERTER_OUTPUT_FILENAME;
          anchor.href = resUrl;
          anchor.click();
          return response;
        }),
      )
      .subscribe();
  }

  private fetchErrorLogs(logUrl: string): void {
    this.http
      .get(logUrl, {
        reportProgress: true,
        observe: 'events',
        responseType: 'blob',
      })
      .pipe(
        download(),
        skipWhile((downloadedData) => !downloadedData['content']),
        switchMap(
          (response) =>
            new Observable((observer: Observer<any>) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                observer.next(reader.result);
                observer.complete();
              };
              reader.readAsText(response.content);
            }),
        ),
        map((data: string) =>
          this.mapLogsToFrontEnd(JSON.parse(data) as ConverterErrorLogJSON),
        ),
      )
      .subscribe((logs) => {
        this._errorLogs.next(logs);
      });
  }

  private mapLogsToFrontEnd(
    backendLogs: ConverterErrorLogJSON,
  ): ConverterErrorLogs[] {
    return backendLogs.issues;
  }
}

<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
<!-- 
  Commenting out this first for validation.
  <mat-form-field class="ease-on-interact full-width" [class.full-opacity]="hasValue$ | async">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    placeholder="Press ENTER to add as filter..."
    [formControl]="chipInputCtrl"
    [matAutocomplete]="auto"
    (keydown.enter)="onKeydownEnter()"
  />

  <button matSuffix mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
    <mat-icon
      [matBadge]="activeChipsSize$ | async"
      [matBadgeHidden]="noActiveChips$ | async"
      matBadgeSize="small"
      matBadgeColor="accent"
    >
      filter_list
    </mat-icon>
  </button>
</mat-form-field>

< !-- Auto complete functionality -- >
<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
  <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
    {{ option }}
  </mat-option>
</mat-autocomplete>

< !-- Active Filter Chips List -- >
<mat-menu #menu="matMenu">
  <div class="chips-component-list">
    <sso-chips-panel [chips]="activeChips$ | async" (remove)="onChipRemove($event)"></sso-chips-panel>
  </div>
</mat-menu>
-->

<mat-form-field class="ease-on-interact full-width">
  <mat-label>{{ label }}</mat-label>
  <mat-chip-grid #chipList [attr.aria-label]="label">
    <div class="chips-component-list">
      <mat-chip-row
        *ngFor="let chip of activeChips$ | async"
        class="mat-chip-selected"
        selected
        [removable]="removable"
        (removed)="onChipRemove(chip)"
        [value]="chip"
        color="accent"
      >
        {{ chip }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip-row>
    </div>
    <input
      #chipInput
      #trigger="matAutocompleteTrigger"
      matInput
      placeholder="Press ENTER to add as filter..."
      [formControl]="chipInputCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      (keydown.enter)="onKeydownEnter()"
      (click)="onClick(trigger)"
      [attr.aria-label]="label"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption (optionSelected)="selected($event)">
    <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  RoadmapVisualizationState,
  roadmapVisualizationAdapter,
} from './visualization.reducer';
import {
  PartialRoadmapVisualization,
  ROADMAP_VISUALIZATION_FEATURE_NAME,
} from './visualization.model';

const { selectIds, selectEntities, selectAll, selectTotal } =
  roadmapVisualizationAdapter.getSelectors();

const visualizationFeatureState =
  createFeatureSelector<RoadmapVisualizationState>(
    ROADMAP_VISUALIZATION_FEATURE_NAME,
  );

export const selectActiveVisualization = createSelector(
  visualizationFeatureState,
  (state) => (!!state.active ? state.entities[state.active] : null),
);

export const selectVisualizationEntities = createSelector(
  visualizationFeatureState,
  selectEntities,
);

export const selectVisualizationList = createSelector(
  visualizationFeatureState,
  selectAll,
);

export const selectLoading = createSelector(
  visualizationFeatureState,
  (state) => state.loading,
);

export const selectLoaded = createSelector(
  visualizationFeatureState,
  (state) => state.loaded,
);

export const selectVisualization = (name: string) =>
  createSelector(
    selectVisualizationEntities,
    (entities) => entities[name] as PartialRoadmapVisualization,
  );

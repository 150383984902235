<div class="sso-page" fxLayout="column" fxLayoutGap="30px">
  <sso-progress-indicator
    mode="indeterminate"
    [message]="'Campaign.messages.importingCampaign' | translate"
    icon="info"
    data-testid="import-progress-indicator"
    *ngIf="importing$ | async"
  ></sso-progress-indicator>
  <sso-title-bar
    [titlePage]="'Campaign.labels.importTitle' | translate"
    icon="campaign"
    class="sso-title-bar"
    [breadcrumbs]="titleBreadcrumb$ | async"
  >
    <div titleBarActions>
      <div fxLayoutAlign="flex-end">
        <button
          data-testid="download-template-button"
          *ngIf="isAdmin$ | async"
          mat-button
          type="button"
          color="primary"
          (click)="onDownloadTemplate()"
        >
          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2px">
            <mat-icon
              *ngIf="(downloadingTemplate$ | async) === false; else downloading"
              matPrefix
              >download</mat-icon
            >

            <b
              >{{ 'Campaign.labels.downloadTemplate' | translate }} (v{{
                templateVersion$ | async
              }})</b
            >
          </div>
        </button>
        <mat-divider></mat-divider>
        <button
          data-testid="upload-campaign-button"
          *ngIf="isAdmin$ | async"
          mat-button
          type="button"
          color="primary"
          [disabled]="(loading$ | async) || (hasOngoingImport$ | async)"
          (click)="onImport()"
        >
          <mat-icon matPrefix>attach_file</mat-icon>
          <b>{{ 'Campaign.labels.importUpload' | translate }}</b>
        </button>
      </div>
    </div>
  </sso-title-bar>
  <div class="table-container">
    <mat-progress-bar
      *ngIf="loadingNotImporting$ | async"
      mode="indeterminate"
    ></mat-progress-bar>
    <mat-table
      [dataSource]="datasource$ | async"
      matSort
      class="sso-stripe-table"
    >
      <ng-container *ngFor="let field of COLUMN_DEFS" [matColumnDef]="field">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ field | titlecase }}
        </mat-header-cell>
        <mat-cell *matCellDef="let data; let i = index" [ngSwitch]="field">
          <div *ngIf="!data.loading; else skeletonLoader">
            <div *ngSwitchCase="'name'">
              {{ (ownerReferences$ | async)[data.owner] }}
            </div>

            <div *ngSwitchCase="'status'">
              <div
                [class]="statusMap[data?.status]?.class"
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="5%"
              >
                <mat-icon
                  [attr.aria-label]="statusMap[data?.status]?.label"
                  *ngIf="statusMap[data?.status]?.icon"
                >
                  {{ statusMap[data.status]?.icon }}
                </mat-icon>
                <span style="white-space: nowrap"
                  ><strong>{{ statusMap[data.status]?.label }}</strong></span
                >
              </div>
            </div>

            <div *ngSwitchCase="'actions'">
              <div fxLayout="row" fxLayoutAlign="start center">
                <button
                  data-testid="view-logs-button"
                  mat-icon-button
                  type="button"
                  color="primary"
                  (click)="viewLogs(data)"
                  [disabled]="isLogsDisabled(data)"
                >
                  <mat-icon>notes</mat-icon>
                </button>
                <button
                  data-testid="import-download-button"
                  mat-icon-button
                  color="primary"
                  (click)="onDownload(data)"
                  [disabled]="loading$ | async"
                >
                  <mat-icon>download</mat-icon>
                </button>
                <button
                  data-testid="import-delete-button"
                  mat-icon-button
                  color="warn"
                  (click)="deleteImport(data)"
                  [disabled]="(loading$ | async) || cannotBeDeleted(data)"
                >
                  <mat-icon>delete_forever</mat-icon>
                </button>
              </div>
            </div>

            <div *ngSwitchDefault>
              {{ data[field] || '-' }}
            </div>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="COLUMN_DEFS; sticky: true"
      ></mat-header-row>

      <mat-row *matRowDef="let row; columns: COLUMN_DEFS"></mat-row>
    </mat-table>

    <ng-template #downloading>
      <mat-spinner strokeWidth="3" [diameter]="20"></mat-spinner>
    </ng-template>

    <ng-template #skeletonLoader>
      <ngx-skeleton-loader
        count="1"
        animation="progress"
        [theme]="{ margin: '12px 0', height: '20px', width: '100px' }"
      ></ngx-skeleton-loader>
    </ng-template>
    <div
      *ngIf="noData$ | async"
      class="full-width no-data"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <mat-icon>history</mat-icon>
      <span>{{ 'Campaign.messages.noHistory' | translate }}</span>
    </div>

    <mat-paginator
      pageSize="10"
      [pageSizeOptions]="[10, 20, 50]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>

import { filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';

import { AnnouncementActions } from './announcement.action';
import { AnnouncementState } from './announcement.reducer';
import {
  announcementList,
  latestReleaseId,
  latestReleaseVersion,
  loaded,
  loading,
  showReleaseBanner,
} from './announcement.selector';

@Injectable()
export class AnnouncementFacadeService {
  constructor(
    private store: Store<AnnouncementState>,
    private actionSubject$: ActionsSubject,
  ) {}

  announcements$ = this.store.pipe(select(announcementList));
  loading$ = this.store.pipe(select(loading));
  loaded$ = this.store.pipe(select(loaded));
  showReleaseBanner$ = this.store.pipe(select(showReleaseBanner));
  latestReleaseVersion$ = this.store.pipe(select(latestReleaseVersion));
  latestReleaseId$ = this.store.pipe(select(latestReleaseId));

  getList() {
    this.store.dispatch(AnnouncementActions.getList());
    return this.actionSubject$.pipe(
      filter(
        (action) => action.type === AnnouncementActions.getListSuccess.type,
      ),
    );
  }

  readAnnouncement(id: string) {
    this.store.dispatch(AnnouncementActions.readAnnouncement({ id }));
  }
}

import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  filter,
  first,
  map,
  mergeMap,
  sampleTime,
  skipWhile,
  take,
  tap,
} from 'rxjs/operators';
import {
  CredentialsService,
  LogData,
} from 'ssotool-shared/services/credentials';

import { formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  LOCALE_ID,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  CloudWatchLogEvent,
  CloudWatchLoggerConfig,
  CloudWatchLoggerService,
} from '@oculus/utils';
import { FormControl } from '@angular/forms';
import {
  ERROR_TAB_NAME,
  LOG_REGEX,
  LOG_TAB_DEFINITIONS,
  Module,
} from './log-viewer.references';
import { LogViewerService } from './log-viewer.service';
import { TabDetail, TabSelectionType } from '../tab-group';

@UntilDestroy()
@Component({
  selector: 'sso-log-viewer',
  templateUrl: './log-viewer.component.html',
  styleUrls: ['./log-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogViewerComponent implements OnInit {
  @ViewChild('logContent') private logContainer: ElementRef;

  // logTabDefinitions = ['Real-time Logs', 'Error Messages'];
  logTabDefinitions: TabSelectionType;

  logTabControl = new FormControl(0);

  private logs = new BehaviorSubject<Array<CloudWatchLogEvent>>([]);
  logs$ = this.logs.pipe(
    map(
      (logs) =>
        logs.filter(
          (log) =>
            !log.message.includes('DEBUG') &&
            !this.checkStartLog(log.message) &&
            !this.checkStartLog(log.message, false),
        ), // TEMPORARY HANDLING FOR THE FILTERING
    ),
  );
  noLogs$ = this.logs$.pipe(map((logs) => logs?.length === 0));
  private logsConfig$ = new BehaviorSubject<CloudWatchLoggerConfig>(null);

  timer$ = new BehaviorSubject<number>(30);

  private downloading = new BehaviorSubject<boolean>(false);
  downloading$ = this.downloading.asObservable();

  readonly logRegex = LOG_REGEX;

  loading$: Observable<boolean> = this.data?.loading$ || of(false);

  issues: Observable<Module[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LogData,
    private credentials: CredentialsService,
    @Inject('loggerService')
    private loggerService: CloudWatchLoggerService,
    @Inject(LOCALE_ID) private locale: string,
    private renderer: Renderer2,
    private logViewerService: LogViewerService,
  ) {}

  get requestId() {
    return this.data?.details?.requestId;
  }

  ngOnInit(): void {
    this.initializeTabDef();

    if (this.requestId) {
      this.initializeLogs();
      this.fetchCredentials();
      this.initializeScrollBottom();
      this.initializeTimer();
    }

    this.issues = this.logViewerService.getLogMessages(
      this.loading$,
      this.data?.errorMessages$,
    );
  }

  private initializeTabDef() {
    this.logTabDefinitions = LOG_TAB_DEFINITIONS.map((item) => {
      return this.disableErrorTab(item);
    });
  }

  private initializeLogs() {
    this.logsConfig$
      .pipe(
        filter((logConfig) => !!logConfig),
        mergeMap((logConfig) =>
          this.loggerService.logs$(logConfig, false, 5000),
        ),
        untilDestroyed(this),
      )
      .subscribe(this.processLogs.bind(this));
  }

  private processLogs(logs: Array<CloudWatchLogEvent>) {
    let nextLogs = this.logs.value
      .concat(logs)
      .sort((x, y) => x?.timestamp - y?.timestamp);
    const startIndex = nextLogs.findIndex((log) =>
      this.checkStartLog(log.message),
    );

    if (startIndex >= 0) {
      const endIndex = nextLogs.findIndex((log) =>
        this.checkStartLog(log.message, false),
      );
      nextLogs = nextLogs.slice(
        startIndex,
        endIndex >= 0 ? endIndex + 1 : nextLogs.length,
      );
    }
    this.logs.next(nextLogs);
  }

  private checkStartLog(message: string, isStart: boolean = true) {
    return (
      message.includes(isStart ? 'START' : 'END') &&
      message.includes(this.requestId)
    );
  }

  private fetchCredentials() {
    this.credentials
      .fetchLogCredentials(this.data)
      .pipe(untilDestroyed(this))
      .subscribe(this.setLogsConfig.bind(this));
  }

  private setLogsConfig = (logConfig: CloudWatchLoggerConfig) => {
    if (!this.logsConfig$.value && !!logConfig) {
      this.logsConfig$.next(logConfig);
    }
  };

  private initializeScrollBottom() {
    this.logs$
      .pipe(
        untilDestroyed(this),
        map((logs) => logs),
        skipWhile((logs) => logs.length !== 0),
        take(1),
        tap(() => {
          setTimeout(() => this.scrollToBottom(), 500);
        }),
      )
      .subscribe();
  }

  private scrollToBottom(): void {
    try {
      this.logContainer.nativeElement.scrollTop =
        this.logContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  private initializeTimer() {
    this.timer$.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value > 0) {
        let val = value - 1;
        setTimeout(() => this.timer$.next(val), 500);
      }
    });
  }

  onDownload() {
    this.downloading.next(true);
    this.logs$.pipe(sampleTime(6000), first()).subscribe((logs) => {
      const downloadFormatData = logs.map(
        (d) => `${formatDate(d.timestamp, 'medium', this.locale)} ${d.message}`,
      );
      const blob = new Blob(downloadFormatData, {
        type: 'text/plain',
        endings: 'native',
      });
      const resUrl = window.URL.createObjectURL(blob);
      const anchor = this.renderer.createElement('a');
      this.renderer.setProperty(anchor, 'download', 'log.txt');
      this.renderer.setProperty(anchor, 'href', resUrl);
      anchor.click();
      this.downloading.next(false);
    });
  }

  isViewingRealTimeLogs(): boolean {
    return this.logTabControl.value === 0;
  }

  formatTableName(tableName: string): string {
    return this.logViewerService.formatTableName(tableName);
  }

  private disableErrorTab(tabDetail: TabDetail): TabDetail {
    return {
      ...tabDetail,
      disabled: tabDetail.label === ERROR_TAB_NAME && !!!this.data?.hasError,
    };
  }
}

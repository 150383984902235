import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AmbitionFacadeService } from 'ssotool-app/+ambition/store';
import { ClientFacadeService } from 'ssotool-app/+client';
import { ChartDetails } from 'ssotool-app/+roadmap/services/curve-export/kpi-curve-export.model';
import { KPICurveExporterService } from 'ssotool-app/+roadmap/services/curve-export/kpi-curve-export.service';
import {
  CurveData,
  CurveEntity,
  HierarchicalFilter,
  HierarchichalCurveData,
  ResultFacadeService,
  ResultFilterOptions,
  RoadmapFacadeService,
  SankeyData,
  SummedHierarchicalCurveData,
} from 'ssotool-app/+roadmap/stores';
import { ChartFacadeService } from 'ssotool-app/+roadmap/stores/charts/charts.facade.service';
import {
  ELLIPSE_CURVE_COLORS,
  INDICATOR_OPTIONS,
  MOCK_CAMPAIGN_MAPPER,
  OTHER_COLORS,
  OTHER_LABEL,
  PIE_THRESHOLD_DISPLAY,
  STACKED_BAR_THRESHOLD_DISPLAY,
  WATERFALL_THRESHOLD_DISPLAY,
} from 'ssotool-app/app.references';
import {
  BarBlock,
  BarStack,
  Coerce,
  convertArrayToFormFieldOptions,
  FilterConditionValue,
  FormFieldOption,
  PieMetadata,
  PieSlice,
  SankeyMetadata,
  StackedBarMetadata,
  WaterfallBlock,
  WaterfallMetadata,
  WaterfallStack,
} from 'ssotool-shared';

import { ActivatedRoute } from '@angular/router';

import {
  ChartInterpretation,
  ChartType,
  CurveFormModel,
  GroupedOption,
} from './kpi-curve.models';
import {
  DISPLAY_LEVEL_FIELD,
  INCLUDE_SECONDARY_GROUPING,
  INDICATOR_TO_KPI_MAPPING,
  RELATIVE_VALUE_UNIT,
  STACKED_BARS,
  YEARS,
} from './kpi-curve.references';
import { isFeatureEnabled } from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';

type GroupedSums = { [group: string]: { [year: string]: number } };
type GroupedWaterfallBlocksData = {
  [stack: string]: { [block: string]: number };
};

export abstract class KPICurveInterpreter {
  private resultFacade: ResultFacadeService;
  private clientFacade: ClientFacadeService;
  private ambitionFacade: AmbitionFacadeService;
  private roadmapFacade: RoadmapFacadeService;
  private roadmapId: string;
  private chartFacadeService: ChartFacadeService;
  otherStackBarMetadataDetails = {};
  waterfallBlockGrouping: { [kpi: string]: string } = {};
  private _blockKey = '';

  constructor(
    resultFacade: ResultFacadeService,
    clientFacade: ClientFacadeService,
    ambitionFacade: AmbitionFacadeService,
    roadmapFacade: RoadmapFacadeService,
    chartFacadeService: ChartFacadeService,
    route: ActivatedRoute,
    private exporterService: KPICurveExporterService,
  ) {
    this.resultFacade = resultFacade;
    this.clientFacade = clientFacade;
    this.ambitionFacade = ambitionFacade;
    this.roadmapFacade = roadmapFacade;
    this.chartFacadeService = chartFacadeService;
    this.roadmapId = route.parent.snapshot.params?.roadmapId;
  }

  private transformDataToCurveMetadata = {
    [ChartType.STACKED_BAR]: this.mapToStackedBar.bind(this, false),
    [ChartType.PIE]: this.createPieMetadata.bind(this),
    [ChartType.WATERFALL]: this.mapToWaterfall.bind(this),
    [ChartType.STACKED_BAR_RELATIVE]: this.mapToStackedBar.bind(this, true),
  };

  abstract getBaseColorInHex(): string;
  abstract getCurveToDisplay(
    kpiDetails: CurveFormModel,
  ): Observable<ChartInterpretation>;
  abstract getFilterOptions(
    activeFilters: string[],
    variationId: string,
  ): Observable<ResultFilterOptions>;

  getCurveStream(
    {
      kpi,
      subKpi,
      curveType,
      splitBy,
      enumerateBy,
      filters,
      indicatorField,
      geography,
      year,
      variationId,
    }: CurveFormModel,
    kpiType,
  ): Observable<ChartInterpretation> {
    if (curveType === ChartType.SANKEY) {
      return this.resultFacade
        .selectSankeyData(this.roadmapId, variationId, geography, year)
        .pipe(map((sankeyData) => this.createSankeyMetadata(sankeyData)));
    }

    let kpiKey = kpi;
    /* istanbul ignore else */
    if (INCLUDE_SECONDARY_GROUPING.includes(kpi)) {
      kpiKey = `${kpi}_${curveType}`;
    }

    if (
      !DISPLAY_LEVEL_FIELD.includes(splitBy.group) &&
      splitBy.level !== null
    ) {
      splitBy.level = null;
    }

    if (!STACKED_BARS.includes(curveType as ChartType)) {
      enumerateBy = null;
    }

    this._blockKey = this.waterfallBlockGrouping[kpi];

    let newFilters = Object.entries(filters).reduce((acc, [key, value]) => {
      acc[key === 'site' ? 'geography' : key] = value;
      return acc;
    }, {});

    const dynamicFilters = isFeatureEnabled(
      FeatureFlag.INPUT_SIMPLIFICATION_FEATURE,
    )
      ? newFilters
      : filters;

    const filterClone = JSON.parse(JSON.stringify(dynamicFilters)) || {};

    if (this._blockKey && subKpi) {
      filterClone[this._blockKey] = {
        condition: FilterConditionValue.IS,
        values: subKpi,
      };
    }

    return combineLatest([
      this.resultFacade.selectCurve(
        this.roadmapId,
        variationId,
        kpiType,
        kpiKey,
        splitBy,
        enumerateBy,
        filterClone,
      ),
      this.getDisplayedIndicator(filterClone, indicatorField, kpi),
      this.chartFacadeService.getLabelColorData(),
    ]).pipe(
      map(([data, indicatorData, chartColorMap]) => {
        return this.transformDataToCurveMetadata[curveType](
          data,
          indicatorData,
          chartColorMap,
          splitBy,
        );
      }),
    );
  }

  private getDisplayedIndicator(
    filters,
    indicatorField: string,
    displayedKpi: string,
  ) {
    return combineLatest([
      this.roadmapFacade.getRoadmapTargets(this.roadmapId),
      this.ambitionFacade.getTargetEntities$,
    ]).pipe(
      map(([targets, constraints]) => {
        const targetConstraints = targets
          .map((target) => constraints[target?.id])
          .filter((constraint) => !!constraint);
        const mappedKpi = INDICATOR_TO_KPI_MAPPING[displayedKpi];

        if (!indicatorField || !mappedKpi) {
          return null;
        }

        if (indicatorField?.toLowerCase() === mappedKpi?.toLowerCase()) {
          let filteredConstraints = targetConstraints.filter(
            (constraint) =>
              INDICATOR_OPTIONS?.[constraint?.indicator] === indicatorField,
          );

          if (filters?.entity?.length) {
            filteredConstraints = filteredConstraints.filter(
              (constraint) =>
                filters.entity.indexOf(constraint?.companyName) >= 0,
            );
          }

          if (filters?.geography?.length) {
            filteredConstraints = filteredConstraints.filter(
              (constraint) =>
                filters.geography.indexOf(constraint?.geoName) >= 0,
            );
          }

          return filteredConstraints?.length ? filteredConstraints[0] : null;
        }
      }),
    );
  }

  // STACKED BAR
  private mapToStackedBar(
    isRelative: boolean,
    curveEntity: CurveEntity<HierarchichalCurveData>,
    indicatorData,
    chartColorMap: Record<string, string>,
    splitBy: HierarchicalFilter,
  ): StackedBarMetadata {
    let mappedData: SummedHierarchicalCurveData = {};
    let stacks: BarStack[] = [];

    mappedData = this.mapGroupedSums(
      curveEntity.data,
      this.isYearlySplit(splitBy.group),
    );

    stacks = this.mapStacks(mappedData, chartColorMap, isRelative);

    const unit = isRelative ? RELATIVE_VALUE_UNIT : curveEntity.unit;
    return {
      ...this.createStackBarMetadata(stacks),
      responsive: true,
      yAxisName: unit,
      unit,
      yAxisMaxScale: isRelative ? 100 : null,
      ...this.otherStackBarMetadataDetails,
      line: indicatorData && {
        label: `${INDICATOR_OPTIONS?.[indicatorData.indicator]} Indicator`,
        data: Coerce.getObjKeys(indicatorData.values).map((k) => ({
          name: k,
          value: parseFloat(indicatorData.values[k]),
        })),
      },
    };
  }

  private isYearlySplit(level: string) {
    return level === YEARS.value;
  }

  private mapGroupedSums(
    data: HierarchichalCurveData,
    yearly: boolean,
  ): SummedHierarchicalCurveData {
    const leanGroupedSums = Object.entries(data).reduce(
      (summedGroup, [group, components]) => {
        summedGroup[group] = Object.entries(components).reduce(
          (summedComponents, [index, campaigns]) => {
            summedComponents[index] = (
              campaigns?.length ? campaigns : []
            ).reduce(
              (sum, campaign) =>
                (sum += yearly
                  ? Number(campaign.values[index]) || 0
                  : this.sumObjectvalues(campaign.values)),
              0,
            );
            return summedComponents;
          },
          {},
        );
        return summedGroup;
      },
      {},
    );

    return this.padGroupedSums(leanGroupedSums);
  }

  private mapStacks(
    data: SummedHierarchicalCurveData,
    chartColorMap: Record<string, string>,
    isRelative: boolean = false,
  ): BarStack[] {
    return Object.entries(data).map(([name, blocks]) => {
      return {
        name,
        blocks: this.createBlocks(blocks, chartColorMap, isRelative),
      };
    });
  }

  private createBlocks(
    sums: Record<string, number>,
    chartColorMap: Record<string, string>,
    isRelative: boolean = false,
  ): BarBlock[] {
    const totalBlockValue = Object.values(sums).reduce(
      (total, sum) => total + Coerce.toZero(sum),
      0,
    );
    const getRelativeValue = isRelative && totalBlockValue;

    let blocks: BarBlock[] = Object.entries(sums).map(([name, value]) => ({
      name,
      value: getRelativeValue ? (value * 100) / totalBlockValue : value,
      color: chartColorMap?.[name],
    }));

    blocks = this.getBarBlocksWithThreshold(blocks, chartColorMap);
    return blocks;
  }

  /**
   * Arrange the blocks in descending order and limit the display according to threshold set.
   * @param blocks blocks to sort and process.
   * @param chartColorMap colorMap
   * @return arranged blocks.
   */
  getBarBlocksWithThreshold(
    blocks: BarBlock[],
    chartColorMap: Record<string, string>,
  ) {
    const sortedBlocks = blocks?.sort(
      (a, b) => Math.abs(b.value) - Math.abs(a.value),
    );
    let withinThreshold = sortedBlocks?.slice(0, STACKED_BAR_THRESHOLD_DISPLAY);
    let others = sortedBlocks?.slice(STACKED_BAR_THRESHOLD_DISPLAY);
    let otherSumValue = 0;
    let otherBlock = undefined;
    if (others && others.length > 0) {
      others = others.map((block) => {
        otherSumValue += block.value;
        return { ...block, value: 0 };
      });
      otherBlock = {
        name: OTHER_LABEL,
        color: chartColorMap?.[OTHER_LABEL],
        value: otherSumValue,
      } as BarBlock;
      withinThreshold = withinThreshold
        .concat(others)
        .sort((a, b) => (a.name > b.name ? 1 : -1));
      withinThreshold.push(otherBlock);
    }
    return withinThreshold;
  }

  private sumObjectvalues = (obj: Record<string, any>) => {
    return (
      Coerce.getObjValues(obj).reduce(
        (sum, value) => sum + (Number(value) || 0),
        0,
      ) || 0
    );
  };

  private padGroupedSums = (groupedSums: GroupedSums): GroupedSums => {
    const keys = this.getAllSumKeys(groupedSums);
    return Object.entries(groupedSums).reduce(
      (paddedGroupedSum, [group, sums]) => {
        paddedGroupedSum[group] = keys.reduce((newSums, key) => {
          newSums[key] = key in sums ? sums[key] : 0;
          return newSums;
        }, {});
        return paddedGroupedSum;
      },
      {},
    );
  };

  private getAllSumKeys = (groupedSums: GroupedSums): string[] => {
    const keys = Object.entries(groupedSums).reduce(
      (allKeys, [group, sums]) => {
        Object.entries(sums).forEach(([key, sum]) => {
          allKeys.push(key);
        });
        return allKeys;
      },
      [],
    );

    return [...new Set(keys)];
  };

  private createStackBarMetadata(stacks: BarStack[]): StackedBarMetadata {
    const bars = [{ name: this.roadmapId, stacks }];
    return {
      orientation: 'vertical',
      bars,
      baseColor: this.getBaseColorInHex(),
    };
  }

  // WATERFALL
  private mapToWaterfall(
    curveEntity: CurveEntity<CurveData>,
    indicatorData,
    chartColorMap: Record<string, string>,
    xAxis?: GroupedOption,
  ): WaterfallMetadata[] {
    const groupedData = this.groupWaterfallBlocks(curveEntity.data);
    const totalStack = this.createWaterfallStack(
      'Grand Total',
      Coerce.getObjValues(groupedData),
      chartColorMap,
    );
    const nextStacks = Object.entries(groupedData).map(([name, data]) =>
      this.createWaterfallStack(name, [data], chartColorMap),
    );
    const processedStacks = this.getStacksToDisplayWithThreshold(
      [totalStack].concat(nextStacks),
      chartColorMap,
    );

    return [
      {
        ...this.createWaterfallMetadata(processedStacks),
        unit: curveEntity.unit,
        yAxisName: curveEntity.unit,
        responsive: true,
      },
    ];
  }

  private groupWaterfallBlocks(data: CurveData): GroupedWaterfallBlocksData {
    return Object.entries(data).reduce((acc, [name, curveEntity]) => {
      acc[name] = curveEntity.reduce((stackAcc, curr) => {
        const key = curr?.[this._blockKey] || name;
        const total = this.computeSum(
          Coerce.getObjValues(curr.values).map((value) => Number(value)),
        );
        if (stackAcc[key]) {
          stackAcc[key] += total;
        } else {
          stackAcc[key] = total;
        }

        return stackAcc;
      }, {});

      return acc;
    }, {} as GroupedWaterfallBlocksData);
  }

  private createWaterfallStack(
    stackName: string,
    blockData: { [blockName: string]: number }[],
    chartColorMap: Record<string, string>,
  ): WaterfallStack {
    const blocks = this.createWaterfallBlocks(stackName, blockData);
    return new WaterfallStack(
      blocks,
      'dec',
      stackName,
      chartColorMap?.[stackName],
    );
  }

  private createWaterfallBlocks(
    stackName: string,
    blockData: { [blockName: string]: number }[],
  ): WaterfallBlock[] {
    const setData = blockData.reduce((acc, curr) => {
      Object.entries(curr).forEach(([key, value]) => {
        const keyToUse = this._blockKey ? key : stackName;
        if (acc[keyToUse]) {
          acc[keyToUse] += value;
        } else {
          acc[keyToUse] = value;
        }
      });
      return acc;
    }, {});
    return Object.entries(setData).map(([blockName, total]) =>
      this.createWaterfallBlock(blockName, total),
    );
  }

  private createWaterfallBlock(name, value): WaterfallBlock {
    return { name, value };
  }

  private createWaterfallMetadata(stacks: WaterfallStack[]): WaterfallMetadata {
    return { stacks, baseColor: this.getBaseColorInHex(), yAxisMin: 0 };
  }

  /**
   * Arrange stacks to descending order and limit the display to a threshold
   * @param stacks stacks to transform
   * @param hasBlockGrouping flag to check if blocks have subgroups
   * @returns sliced stacks
   */
  private getStacksToDisplayWithThreshold(
    stacks: WaterfallStack[],
    chartColorMap: Record<string, string>,
  ) {
    // +1 for the grand total
    const threshold = WATERFALL_THRESHOLD_DISPLAY + 1;
    const middleStacks = stacks
      .slice(1)
      .sort((a, b) => b.blocksTotalValue - a.blocksTotalValue)
      .slice(0, threshold - 1);

    const initialStacks = [stacks[0]].concat(middleStacks);
    const otherStacks =
      stacks.length > threshold
        ? this.extractOthersStack(initialStacks, chartColorMap)
        : [];

    // remove duplicate
    return initialStacks.concat(otherStacks);
  }

  /**
   * Extracts values of sliced stacks to create the others stack.
   * First index is always the grand total. diminish the values of the succeeding stacks
   * to the grand total to get the others value.
   * @param stacks sliced stacks
   * @param hasBlockGrouping flag to check if blocks have subgroups
   * @returns others stack
   */
  private extractOthersStack(
    stacks: WaterfallStack[],
    chartColorMap: Record<string, string>,
  ) {
    const othersBlocksObj = stacks.reduce((acc, { blocks }) => {
      blocks.forEach(({ name, value }) => {
        const _v = Number(value);
        if (this._blockKey) {
          if (acc[name]) {
            const newValue = acc[name] - _v;
            acc[name] = newValue < 0 ? 0 : newValue;
          } else {
            acc[name] = _v;
          }
        } else {
          if (acc[OTHER_LABEL]) {
            const newValue = acc[OTHER_LABEL] - _v;
            acc[OTHER_LABEL] = newValue < 0 ? 0 : newValue;
          } else {
            acc[OTHER_LABEL] = _v;
          }
        }
      });

      return acc;
    }, {});

    return new WaterfallStack(
      Object.entries<number>(othersBlocksObj).map<WaterfallBlock>(
        ([name, value]) => ({ name, value }),
      ),
      'dec',
      OTHER_LABEL,
      chartColorMap?.[OTHER_LABEL],
    );
  }

  // PIE;
  createPieMetadata(
    curveEntity: CurveEntity,
    indicatorData,
    chartColorMap: Record<string, string>,
    xAxis?: GroupedOption,
  ): PieMetadata[] {
    const slices = Coerce.getObjKeys(curveEntity?.data)
      .map((slice) =>
        this.createPieSliceDatum(
          slice,
          curveEntity?.data,
          chartColorMap?.[slice],
        ),
      )
      .filter((slice) => !!Coerce.toStandardFloat(slice.value));
    // process slices here; sort and display items first according to the
    // threshold
    let processedSlices = this.getSlicesWithThreshold(slices, chartColorMap);

    const total = this.computeTotal(processedSlices);
    processedSlices = this.setPercentageAttr(total, processedSlices);
    return [
      {
        slices: processedSlices,
        unit: curveEntity?.unit,
        baseColor: this.getBaseColorInHex(),
        total,
      } as PieMetadata,
    ];
  }

  /**
   * Arrange the slices in descending order and limit the display according to threshold set.
   * @param slices slices to sort and process.
   * @param chartColorMap colorMap
   * @return sliced slices.
   */
  getSlicesWithThreshold(
    slices: PieSlice[],
    chartColorMap: Record<string, string>,
  ) {
    const sortedSlices = slices.sort(
      (a, b) => parseFloat(b.value) - parseFloat(a.value),
    );
    slices = sortedSlices
      .slice(0, PIE_THRESHOLD_DISPLAY)
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    let otherSlicesValue = 0;
    let otherSlices = sortedSlices.slice(PIE_THRESHOLD_DISPLAY);
    otherSlices.forEach((pieSlice) => {
      otherSlicesValue += parseFloat(pieSlice.value);
    });

    if (otherSlices.length > 0) {
      slices.push({
        name: OTHER_LABEL,
        value: otherSlicesValue.toFixed(2),
        color: chartColorMap?.[OTHER_LABEL],
      } as PieSlice);
    }
    return slices;
  }

  extractYearsFromPieData(slice: PieSlice): string[] {
    return Coerce.getObjKeys(slice.value);
  }

  calcPercentage(totalValue: number, itemValue: number) {
    return ((itemValue / totalValue) * 100).toFixed(2);
  }

  setPercentageAttr(totalValue, slices: PieSlice[]) {
    return slices.map((slice) => ({
      ...slice,
      extra: {
        percentage: this.calcPercentage(totalValue, parseFloat(slice.value)),
      },
    }));
  }

  private createPieSliceDatum(
    slice: string,
    valueChunk: unknown,
    color: string,
  ): PieSlice {
    const perGroupData: [] = valueChunk[slice];
    let applicableValues: number[] = [];
    perGroupData.forEach((data) => {
      const values = Coerce.getObjValues(data['values']).map((value) =>
        Number(value),
      );
      applicableValues = applicableValues.concat(values);
    });
    const total = this.computeSum(applicableValues);
    return { name: slice, color, value: total.toFixed(2) } as PieSlice;
  }

  computeTotal(slice: PieSlice[]) {
    return Number(slice.reduce((acc, it) => acc + parseFloat(it.value), 0));
  }

  // SANKEY
  createSankeyMetadata(sankeyData: SankeyData[]): SankeyMetadata {
    const colors = ELLIPSE_CURVE_COLORS.concat(OTHER_COLORS);
    const grouped = (sankeyData || []).reduce<Record<string, SankeyData[]>>(
      (acc, curr) => {
        if (acc[curr.fluidId]) {
          acc[curr.fluidId].push(curr);
        } else {
          acc[curr.fluidId] = [curr];
        }
        return acc;
      },
      {},
    );

    const mappedSankey = Object.entries(grouped || {}).reduce(
      (acc, [fluidId, groupedData], index) => {
        const nodeColor = colors[index];
        const flowColor = `${nodeColor}33`;

        acc.flows[fluidId] = groupedData.map((datum) => {
          const { srcId, srcCampaign } = this.getSource(datum);
          const { destId, destCampaign } = this.getTarget(datum);

          if (!acc.nodes[srcId]) {
            acc.nodes[srcId] = {
              id: srcId,
              name: srcCampaign,
              color: nodeColor,
            };
          }

          acc.nodes[destId] = {
            id: destId,
            name: destCampaign,
            color: nodeColor,
          };

          return {
            id: fluidId,
            name: datum.fluid,
            sourceNodeId: srcId,
            targetNodeId: destId,
            color: flowColor,
            value: datum.value,
          };
        });
        return acc;
      },
      { nodes: {}, flows: {} },
    );

    return {
      nodes: Coerce.getObjValues(mappedSankey.nodes),
      flows: [].concat.apply([], Coerce.getObjValues(mappedSankey.flows)),
    };
  }

  private getSource(datum: SankeyData): { srcId: string; srcCampaign: string } {
    return datum.srcCampaignId === Object.keys(MOCK_CAMPAIGN_MAPPER)[0]
      ? {
          srcId: 'supply_' + datum.fluidId,
          srcCampaign: datum.fluid + '_supply',
        }
      : { srcId: datum.srcCampaignId, srcCampaign: datum.srcCampaign };
  }

  private getTarget(datum: SankeyData): {
    destId: string;
    destCampaign: string;
  } {
    return datum.destCampaignId === Object.keys(MOCK_CAMPAIGN_MAPPER)[1]
      ? {
          destId: 'demand_' + datum.fluidId,
          destCampaign: datum.fluid + '_demand',
        }
      : { destId: datum.destCampaignId, destCampaign: datum.destCampaign };
  }

  getSankeyGeographyOptions(variationId: string) {
    return this.resultFacade
      .selectSankeyGeoOptions(this.roadmapId, variationId)
      .pipe(map(convertArrayToFormFieldOptions));
  }

  getSankeyYearOptions(variationId: string) {
    return this.resultFacade
      .selectSankeyYearOptions(this.roadmapId, variationId)
      .pipe(map(convertArrayToFormFieldOptions));
  }

  export(chart: ChartInterpretation, options: ChartDetails) {
    this.exporterService.export(chart, options);
  }

  // SHARED

  computeSum(data: number[]) {
    return Number(
      Number(
        data.reduce(
          (acc, curr) => (Number.isFinite(Number(curr)) ? acc + curr : acc),
          0,
        ),
      ).toFixed(2),
    );
  }

  getLevels(group: string) {
    return this.clientFacade.selectActiveClientData$.pipe(
      mergeMap((client) => {
        /* istanbul ignore else */
        if (client?.clientId) {
          switch (group) {
            case 'geography':
              return this.clientFacade.selectGeoHierarchy$(client.clientId);
            case 'entity':
              return this.clientFacade.selectCompanyHierarchy$(client.clientId);
          }
        }

        return of({});
      }),
    );
  }

  getLevelOptions(
    groups: string[],
  ): Observable<Record<string, FormFieldOption<string>[]>> {
    return combineLatest(groups.map((i) => this.getLevels(i))).pipe(
      map((levels) =>
        groups.reduce((acc, group, index) => {
          acc[group] = Coerce.getObjKeys(levels[index]).map((level) => ({
            name: level,
            value: level,
          }));
          return acc;
        }, {}),
      ),
    );
  }

  getFilters(
    activeFilters: string[],
    variationId: string,
  ): Observable<ResultFilterOptions> {
    return this.resultFacade.selectFilter(
      this.roadmapId,
      variationId,
      activeFilters,
    );
  }
}

import { Component, Inject, Optional } from '@angular/core';
import { HotToastRef } from '@ngneat/hot-toast';
import { Observable } from 'rxjs';

@Component({
  selector: 'sso-meteomatics-toast-component',
  templateUrl: './meteomatics-toast.component.html',
  styleUrls: ['./meteomatics-toast.component.scss'],
})
export class MeteomaticsToastComponent {
  constructor(
    @Optional()
    @Inject(HotToastRef)
    public toastRef: HotToastRef<{
      title: string;
      message: string;
      labelAction: string;
      action: Observable<{}>;
    }>,
  ) {}

  handleActionClick() {
    this.toastRef.data.action.subscribe();
    setTimeout(() => {
      this.toastRef.close();
    }, 100);
  }

  handleCancelClick() {
    this.toastRef.close();
  }
}

import { ColorPickerModule as NgxColorPickerModule } from 'ngx-color-picker';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { ColorPickerComponent } from './color-picker.component';

@NgModule({
  declarations: [ColorPickerComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgxColorPickerModule,
    MatDialogModule,
    MatButtonModule,
    FlexLayoutModule,
  ],
  exports: [ColorPickerComponent],
})
export class ColorPickerModule {}

import { of } from 'rxjs';
import { distinctUntilChanged, map, pluck } from 'rxjs/operators';

import { Entity, EntityState } from './models';
import { StateManager } from './state-manager';

export abstract class EntityStateManager<T extends Entity, U extends EntityState<T>> extends StateManager<U> {
  getEmailLoading$ = this.state$.pipe(pluck('loading'), distinctUntilChanged());
  loaded$ = this.state$.pipe(pluck('loaded'), distinctUntilChanged());
  error$ = this.state$.pipe(pluck('error'), distinctUntilChanged());
  pageSize$ = this.state$.pipe(pluck('pageSize'), distinctUntilChanged());
  pageIndex$ = this.state$.pipe(pluck('pageIndex'), distinctUntilChanged());
  data$ = this.state$.pipe(pluck('data'), distinctUntilChanged());

  dataList$ = this.data$.pipe(map((datum) => Array.from(datum.values())));
  total$ = this.dataList$.pipe(map((itemList) => itemList.length));

  constructor() {
    super();
  }

  load() {
    this.setNextState({
      ...this.getCurrentState(),
      loading: true,
    });
    return of(this.getCurrentState());
  }

  loadSuccess(dataList: T[]) {
    this.setNextState({
      ...this.getCurrentState(),
      loading: false,
      loaded: true,
      data: new Map(dataList.map((datum) => [datum.id, datum])),
    });
    return of(this.getCurrentState());
  }

  loadFailure(error: string) {
    this.setNextState({
      ...this.getCurrentState(),
      loading: false,
      error,
    });
    return of(this.getCurrentState());
  }

  upsert(datum: T) {
    const currentData = new Map(this.getCurrentState().data);
    currentData.set(datum.id, { ...datum });

    this.setNextState({
      ...this.getCurrentState(),
      data: currentData,
    });
  }

  delete(id: string) {
    const currentData = new Map(this.getCurrentState().data);
    currentData.delete(id);

    this.setNextState({
      ...this.getCurrentState(),
      data: currentData,
    });
  }

  get(id: string) {
    const currentData = new Map(this.getCurrentState().data);
    return currentData.get(id);
  }
}

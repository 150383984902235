<div fxLayout="column" class="title-page-container">
  <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
      <div
        class="icon-page"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
      >
        <span fxLayout="column" fxLayoutGap="8px">
          <div fxLayout="row" fxLayoutGap="2px">
            <div
              class="breadcrumb-container"
              *ngFor="
                let crumb of breadcrumbs;
                let index = index;
                let isLast = last
              "
              fxLayout="row"
              fxLayoutGap="6px"
            >
              <a
                [attr.data-testid]="'title-breadcrumb-' + index"
                class="breadcrumb"
                [routerLink]="crumb.route"
                routerLinkActive="sso-quick-link-active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                {{ crumb.label | translate | titlecase }}
              </a>
              <span class="breadcrumb-divider" *ngIf="!isLast">/</span>
            </div>
          </div>
          <span
            *ngIf="titlePage; else titleLabel"
            class="title-page"
            data-testid="title-label"
            >{{ titlePage }}</span
          >
          <ng-template #titleLabel>
            <ng-content select="[titleBarLabel]"></ng-content>
          </ng-template>
        </span>
      </div>
      <span class="subtitle" data-testid="subtitle-label">{{ subtitle }}</span>
    </div>
    <ng-content select="[titleBarActions]" #actions></ng-content>
  </div>
</div>

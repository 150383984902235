import { HierarchicalFilter } from 'ssotool-app/+roadmap/stores';
import {
  FilterWithCondition,
  PieMetadata,
  StackedBarMetadata,
  WaterfallMetadata,
} from 'ssotool-shared/modules';

// add stacked bar and waterfall models to ChartInterpretationType
export type ChartInterpretation =
  | PieMetadata[]
  | StackedBarMetadata
  | WaterfallMetadata[];

export enum ChartType {
  PIE = 'pie',
  STACKED_BAR = 'stacked_bar',
  WATERFALL = 'waterfall',
}

export interface GroupedOption {
  group: string;
  level: string;
}

export interface CurveFormModel {
  readonly kpi: string;
  readonly subKpi?: string[];
  readonly enumerateByXAxis?: HierarchicalFilter;
  readonly enumerateByYAxis?: HierarchicalFilter;
  readonly enumerateBy: HierarchicalFilter;
  readonly yAxis?: GroupedOption;
  readonly xAxis?: GroupedOption;
  readonly curveType: string;
  readonly splitBy?: HierarchicalFilter;
  readonly filters: FilterWithCondition;
}

<form
  [formGroup]="form"
  fxLayout="row wrap"
  fxLayoutAlign="space-between center"
  fxLayoutGap="1%"
  [ngSwitch]="mode"
>
  <ng-container *ngSwitchCase="'multiselect'">
    <sso-multi-select
      *ngFor="let field of fields"
      [fxFlex]="flexValue"
      [label]="'Filter by ' + (field | titlecase)"
      [options]="(filterOptions$ | async)[field]"
      [formControlName]="field"
      [stripLabel]="stripLabel"
      color="primary"
    ></sso-multi-select>
  </ng-container>
  <ng-container *ngSwitchCase="'chips'">
    <sso-simple-chips
      *ngFor="let field of fields"
      [fxFlex]="flexValue"
      [label]="'Filter by ' + (field | titlecase)"
      [options]="(filterOptions$ | async)[field]"
      (chips)="onFieldChipsChange(field, $event)"
    >
    </sso-simple-chips>
  </ng-container>
  <ng-container *ngSwitchCase="'dialog'">
    <button
      data-testid="filter-button"
      mat-icon-button
      [matTooltip]="'Generic.labels.filter' | translate"
      (click)="onDialogClick()"
      [disabled]="disableFilter$ | async"
    >
      <mat-icon>filter_list</mat-icon>
    </button>
  </ng-container>
</form>

import { BehaviorSubject } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { SSOToolRoutePathService } from 'ssotool-shared';

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { HotToastRef } from '@ngneat/hot-toast';

import { RoadmapFacadeService } from '../../../stores/roadmap/roadmap-facade.service';
import { ComputeToastModel } from './compute-toast.model';

@Component({
  selector: 'sso-compute-toast',
  templateUrl: './compute-toast.component.html',
  styleUrls: ['./compute-toast.component.scss'],
})
export class ComputeToastComponent implements OnInit {
  data$ = new BehaviorSubject<ComputeToastModel>(null);
  isSuccess$ = new BehaviorSubject<boolean>(false);
  get data() {
    return this.data$.value;
  }
  roadmap$ = this.data$.pipe(
    filter((d) => !!d),
    mergeMap((data) => this.roadmapFacade.roadmap$(data.roadmapId)),
  );

  constructor(
    @Optional()
    @Inject(HotToastRef)
    public toastRef: HotToastRef<ComputeToastModel>,
    private router: Router,
    private routePath: SSOToolRoutePathService,
    private roadmapFacade: RoadmapFacadeService,
  ) {}

  ngOnInit(): void {
    this.data$.next(this.toastRef.data);
    this.isSuccess$.next(this.toastRef.getToast().type === 'success');
  }

  navigateToSpecificRoadmap() {
    this.router.navigate(
      this.routePath.viewRoadmap(this.data.clientId, this.data.roadmapId),
    );
    this.toastRef.close();
  }
}

import { combineLatest } from 'rxjs';
import { filter, first, map, mergeMap, tap } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { ResultExportFacadeService } from '../stores/result-export/result-export-facade.service';
import { RoadmapFacadeService } from '../stores/roadmap/roadmap-facade.service';
import { FluidDemandFacadeService } from 'ssotool-app/+client/store/fluid-demand/fluid-demand.facade.service';

@Injectable()
export class RoadmapResolverService implements Resolve<any> {
  constructor(
    private clientFacade: ClientFacadeService,
    private roadmapFacade: RoadmapFacadeService,
    private resultExportFacade: ResultExportFacadeService,
    private fluidDemandFacade: FluidDemandFacadeService,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    this.clientFacade.selectActiveClientData$
      .pipe(
        filter((client) => !!client?.clientId),
        first(),
        map((client) => client.clientId),
        mergeMap((id) =>
          combineLatest([
            this.roadmapFacade.loaded$.pipe(first()),
            this.resultExportFacade.loaded$.pipe(first()),
            this.fluidDemandFacade.loaded$.pipe(first()),
          ]).pipe(
            tap(([roadmapLoaded, exportLoading, demandLoaded]) => {
              if (!roadmapLoaded) {
                this.roadmapFacade.getAllRoadmap(id);
              }

              if (!exportLoading) {
                this.resultExportFacade.getList(id);
              }

              if (!!!demandLoaded) {
                this.fluidDemandFacade.getFluidDemand(id);
              }
            }),
          ),
        ),
      )
      .subscribe();
  }
}

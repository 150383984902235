import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ResultApiService } from './result-api.service';
import { ResultFacadeService } from './result-facade.service';
import { ResultEntityActions } from './result.actions';
import { BaselineIndicators } from './result.model';
import { ResultEntityStore } from './result.reducer';
import { ToastService } from 'ssotool-app/shared/services/toast/toast.service';

@Injectable()
export class ResultEffects {
  get$ = createEffect(this.getSideEffect.bind(this));
  getOk$ = createEffect(this.getOKSideEffect.bind(this), { dispatch: false });
  getNok$ = createEffect(this.getNokSideEffect.bind(this), { dispatch: false });
  downloadComputationLogs$ = createEffect(
    this.downloadComputationLogSideEffect.bind(this),
  );
  downloadComputationLogsOK$ = createEffect(
    this.downloadComputationLogOKSideEffect.bind(this),
    { dispatch: false },
  );
  downloadComputationLogsNOK$ = createEffect(
    this.downloadComputationLogNOKSideEffect.bind(this),
    { dispatch: false },
  );
  downloadComputationInputs$ = createEffect(
    this.downloadComputationInputSideEffect.bind(this),
  );
  downloadComputationInputsOK$ = createEffect(
    this.downloadComputationInputOKSideEffect.bind(this),
    {
      dispatch: false,
    },
  );
  downloadComputationInputsNOK$ = createEffect(
    this.downloadComputationInputNOKSideEffect.bind(this),
    {
      dispatch: false,
    },
  );
  downloadComputationResult$ = createEffect(
    this.downloadComputationResultSideEffect.bind(this),
  );
  downloadComputationResultOK$ = createEffect(
    this.downloadComputationResultOKSideEffect.bind(this),
    {
      dispatch: false,
    },
  );
  downloadComputationResultNOK$ = createEffect(
    this.downloadComputationResultNOKSideEffect.bind(this),
    {
      dispatch: false,
    },
  );
  downloadResultExcel$ = createEffect(
    this.downloadResultExcelSideEffect.bind(this),
  );
  downloadResultExcelOK$ = createEffect(
    this.downloadResultExcelOKSideEffect.bind(this),
    {
      dispatch: false,
    },
  );
  downloadResultExcelNOK$ = createEffect(
    this.downloadResultExcelNOKSideEffect.bind(this),
    {
      dispatch: false,
    },
  );
  // Temporary disabling of baseline indicators
  getBaselineIndicators$ = createEffect(
    this.getBaselineIndicatorsSideEffect.bind(this),
    { dispatch: false },
  );
  getBaselineIndicatorsOK$ = createEffect(
    this.getBaselineIndicatorsOKSideEffect.bind(this),
    { dispatch: false },
  );
  getBaselineIndicatorsNOK$ = createEffect(
    this.getBaselineIndicatorsNOKSideEffect.bind(this),
    { dispatch: false },
  );
  getMultipleBaselineIndicators$ = createEffect(
    this.getMultipleBaselineIndicatorsSideEffect.bind(this),
  );
  getMultipleBaselineIndicatorsOK$ = createEffect(
    this.getMultipleBaselineIndicatorsOKSideEffect.bind(this),
    { dispatch: false },
  );
  getMultipleBaselineIndicatorsNOK$ = createEffect(
    this.getMultipleBaselineIndicatorsNOKSideEffect.bind(this),
    { dispatch: false },
  );
  updateBaselineIndicators$ = createEffect(
    this.updateBaselineIndicatorsSideEffect.bind(this),
  );

  constructor(
    private act: Actions,
    private api: ResultApiService,
    private fac: ResultFacadeService,
    private toast: ToastService,
  ) {}

  getAPIName(): string {
    return ResultEntityStore.featureName;
  }

  getSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(
      ofType(actions.getAction),
      mergeMap((params) =>
        this.api.getRequest(params).pipe(
          map((data) => actions.getOKAction({ data, params })),
          catchError(() => of(actions.getNOKAction(params))),
        ),
      ),
    );
  }

  getOKSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(ofType(actions.getOKAction));
  }

  getNokSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(ofType(actions.getNOKAction));
  }

  downloadComputationLogSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(
      ofType(actions.downloadComputationLogsAction),
      mergeMap(({ clientId, roadmapId, variationId }) =>
        this.api.downloadComputationLogs(clientId, roadmapId, variationId).pipe(
          map(() =>
            actions.downloadComputationLogsOKAction({
              message: 'Download Computation logs success!',
            }),
          ),
          catchError((err) =>
            of(
              actions.downloadComputationLogsNOKAction({
                clientId,
                roadmapId,
                variationId,
                message:
                  (err.error || {}).error || 'Download Computation logs error!',
              }),
            ),
          ),
        ),
      ),
    );
  }

  downloadComputationLogOKSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(
      ofType(actions.downloadComputationLogsOKAction),
      tap((action) => this.toast.showToast(action.message, 'success')),
    );
  }

  downloadComputationLogNOKSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(
      ofType(actions.downloadComputationLogsNOKAction),
      tap(({ clientId, roadmapId, variationId, message }) =>
        this.toast.showToast(
          message,
          'action',
          undefined,
          of({}).pipe(
            tap(() =>
              this.fac.downloadComputationLogs(
                clientId,
                roadmapId,
                variationId,
              ),
            ),
          ),
        ),
      ),
    );
  }

  downloadComputationInputSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(
      ofType(actions.downloadComputationInputsAction),
      mergeMap(({ clientId, roadmapId, variationId }) =>
        this.api
          .downloadComputationInputs(clientId, roadmapId, variationId)
          .pipe(
            map(() =>
              actions.downloadComputationInputsOKAction({
                message: 'Download Computation inputs success!',
              }),
            ),
            catchError((err) =>
              of(
                actions.downloadComputationInputsNOKAction({
                  clientId,
                  roadmapId,
                  variationId,
                  message:
                    (err.error || {}).error ||
                    'Download Computation inputs error!',
                }),
              ),
            ),
          ),
      ),
    );
  }

  downloadComputationInputOKSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(
      ofType(actions.downloadComputationInputsOKAction),
      tap((action) => this.toast.showToast(action.message, 'success')),
    );
  }

  downloadComputationInputNOKSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(
      ofType(actions.downloadComputationInputsNOKAction),
      tap(({ clientId, roadmapId, variationId, message }) =>
        this.toast.showToast(
          message,
          'action',
          undefined,
          of({}).pipe(
            tap(() =>
              this.fac.downloadComputationInputs(
                clientId,
                roadmapId,
                variationId,
              ),
            ),
          ),
        ),
      ),
    );
  }

  downloadComputationResultSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(
      ofType(actions.downloadComputationResultAction),
      mergeMap(({ clientId, roadmapId, variationId }) =>
        this.api
          .downloadComputationResult(clientId, roadmapId, variationId)
          .pipe(
            map(() =>
              actions.downloadComputationResultOKAction({
                message: 'Download Computation result success!',
              }),
            ),
            catchError((err) =>
              of(
                actions.downloadComputationResultNOKAction({
                  clientId,
                  roadmapId,
                  variationId,
                  message:
                    (err.error || {}).error ||
                    'Download Computation result error!',
                }),
              ),
            ),
          ),
      ),
    );
  }

  downloadComputationResultOKSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(
      ofType(actions.downloadComputationResultOKAction),
      tap((action) => this.toast.showToast(action.message, 'success')),
    );
  }

  downloadComputationResultNOKSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(
      ofType(actions.downloadComputationResultNOKAction),
      tap(({ clientId, roadmapId, variationId, message }) =>
        this.toast.showToast(
          message,
          'action',
          undefined,
          of({}).pipe(
            tap(() =>
              this.fac.downloadComputationResult(
                clientId,
                roadmapId,
                variationId,
              ),
            ),
          ),
        ),
      ),
    );
  }

  downloadResultExcelSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(
      ofType(actions.downloadResultExcelAction),
      mergeMap(({ clientId, roadmapId, variationId }) =>
        this.api.downloadResultExcel(clientId, roadmapId, variationId).pipe(
          map(() =>
            actions.downloadResultExcelOKAction({
              message: 'Export result to excel success!',
            }),
          ),
          catchError((err) =>
            of(
              actions.downloadResultExcelNOKAction({
                clientId,
                roadmapId,
                variationId,
                message:
                  (err.error || {}).error || 'Export result to excel error!',
              }),
            ),
          ),
        ),
      ),
    );
  }

  downloadResultExcelOKSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(
      ofType(actions.downloadResultExcelOKAction),
      tap((action) => this.toast.showToast(action.message, 'success')),
    );
  }

  downloadResultExcelNOKSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(
      ofType(actions.downloadResultExcelNOKAction),
      tap(({ clientId, roadmapId, variationId, message }) =>
        this.toast.showToast(
          message,
          'action',
          undefined,
          of({}).pipe(
            tap(() =>
              this.fac.downloadResultExcel(clientId, roadmapId, variationId),
            ),
          ),
        ),
      ),
    );
  }

  getBaselineIndicatorsSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(
      ofType(actions.getBaselineIndicatorsAction),
      // mergeMap(({ clientId, roadmapId }) =>
      //   this.api.getBaselineIndicators(clientId, roadmapId).pipe(
      //     map((data) =>
      //       actions.getBaselineIndicatorsOKAction({
      //         message: 'Computation of baseline indicators has been started.',
      //         data,
      //       }),
      //     ),
      //     catchError((err) =>
      //       of(
      //         actions.getBaselineIndicatorsNOKAction({
      //           clientId,
      //           roadmapId,
      //           message:
      //             (err.error || {}).error || 'Get baseline indicators error!',
      //         }),
      //       ),
      //     ),
      //   ),
      // ),
    );
  }

  getBaselineIndicatorsOKSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(
      ofType(actions.getBaselineIndicatorsOKAction),
      tap((action) => this.toast.showToast(action.message, 'info')),
    );
  }

  getBaselineIndicatorsNOKSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(
      ofType(actions.getBaselineIndicatorsNOKAction),
      tap(({ clientId, roadmapId, message }) =>
        this.toast.showToast(
          message,
          'action',
          undefined,
          of({}).pipe(
            tap(() => this.fac.getBaselineIndicators(clientId, roadmapId)),
          ),
        ),
      ),
    );
  }

  getMultipleBaselineIndicatorsSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(
      ofType(actions.getMultipleBaselineIndicatorsAction),
      mergeMap(({ clientId }) =>
        this.api.getMultipleBaselineIndicators(clientId).pipe(
          map((roadmapIds) =>
            actions.getMultipleBaselineIndicatorsOKAction({
              message:
                'Computation of multiple baseline indicators has been started.',
              roadmapIds,
            }),
          ),
          catchError((err) =>
            of(
              actions.getMultipleBaselineIndicatorsNOKAction({
                clientId,
                message:
                  (err.error || {}).error ||
                  'Get multiple baseline indicators error!',
              }),
            ),
          ),
        ),
      ),
    );
  }

  getMultipleBaselineIndicatorsOKSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(
      ofType(actions.getMultipleBaselineIndicatorsOKAction),
      tap((action) => {
        /** istanbul ignore else */
        if (action.roadmapIds?.length) {
          this.toast.showToast(action.message, 'info');
        }
      }),
    );
  }

  getMultipleBaselineIndicatorsNOKSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(
      ofType(actions.getMultipleBaselineIndicatorsNOKAction),
      tap(({ clientId, message }) =>
        this.toast.showToast(
          message,
          'action',
          undefined,
          of({}).pipe(
            tap(() => this.fac.getMultipleBaselineIndicators(clientId)),
          ),
        ),
      ),
    );
  }

  updateBaselineIndicatorsSideEffect() {
    const actions = ResultEntityActions(this.getAPIName());
    return this.act.pipe(
      ofType(actions.updateBaselineIndicatorsAction),
      mergeMap(({ data }) =>
        this.api.updateBaselineIndicatorsOutput(data).pipe(
          map((output: BaselineIndicators) =>
            actions.updateBaselineIndicatorsOKAction({
              message: 'Baseline indicators processing is finished',
              data: output,
            }),
          ),
          catchError((err) =>
            of(
              actions.updateBaselineIndicatorsNOKAction({
                data,
                message:
                  (err.error || {}).error ||
                  'Baseline indicators processing error!',
              }),
            ),
          ),
        ),
      ),
    );
  }
}

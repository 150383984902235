import { throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { SignedUrl } from 'ssotool-app/app.model';
import { ArrayUtil, generateEndpoint } from 'ssotool-core/utils';
import { ConfigService } from 'ssotool-shared/services/config';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CAMPAIGN_EXPORT_GOOD_STATUS } from '../campaign-page.references';
import { SelectedCampaigns } from '../store/campaign.model';
import {
  CAMPAIGN_EXPORT_STATUS,
  CampaignExportModel,
  CampaignStageMap,
} from '../store/export/campaign-export.model';

@Injectable()
export class CampaignExportService {
  constructor(private http: HttpClient, private config: ConfigService) {}

  exportCampaign(clientId: string, campaigns: SelectedCampaigns) {
    return this.http
      .post<SelectedCampaigns>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.campaign.export,
          clientId,
        ),
        campaigns,
      )
      .pipe(
        map((response) => response),
        catchError((error) => throwError(error)),
      );
  }

  cancelExportCampaigns(clientId: string, exportId: string) {
    return this.http
      .get<HttpResponse<void>>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.campaign.cancelExport,
          clientId,
          exportId,
        ),
      )
      .pipe(
        map((response) => response),
        catchError((error) => throwError(error)),
      );
  }

  getListCampaignsExport(clientId: string) {
    return this.http
      .get<CampaignExportModel[]>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.campaign.export,
          clientId,
        ),
      )
      .pipe(
        map((response) => response),
        catchError((error) => throwError(error)),
      );
  }

  downloadExport(clientId: string, exportId: string) {
    return this.http
      .get<SignedUrl>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.campaign.downloadExport,
          clientId,
          exportId,
        ),
      )
      .pipe(
        mergeMap((downloadSignedUrl: SignedUrl) =>
          this.http.get(downloadSignedUrl.signedUrl).pipe(
            map((response: any) => {
              const blob = new Blob([response], {
                type: 'applications/octet-stream',
              });
              const resUrl = window.URL.createObjectURL(blob);
              const anchor = document.createElement('a');
              anchor.download = downloadSignedUrl.filename;
              anchor.href = resUrl;
              anchor.click();
              return response;
            }),
          ),
        ),
        catchError((error) => throwError(error)),
      );
  }

  isExcelGenerateDone(stages: CampaignStageMap[]): boolean {
    // filter [] and not arrays
    if (!!!stages?.length || !!!Array.isArray(stages)) {
      return false;
    }

    let lastStage = stages.slice(-1)[0];

    return (
      lastStage?.name === CAMPAIGN_EXPORT_STATUS.EXCEL_GENERATION &&
      !!lastStage.details?.generate_done
    );
  }

  isExportValid(campaignExport: Partial<CampaignExportModel>) {
    return (
      this.isStagesValid(campaignExport.stages) &&
      CAMPAIGN_EXPORT_GOOD_STATUS.includes(campaignExport.status)
    );
  }

  // put all stages validation here
  isStagesValid(stages: CampaignStageMap[]): boolean {
    if (stages.length === 0) {
      return false;
    }

    let stageNames = stages.map((stage) => stage.name);

    return (
      stages.length <= 4 &&
      stageNames.length === ArrayUtil.filterUnique(stageNames).length
    );
  }
}

export const determineExportingStatus = (
  status: CAMPAIGN_EXPORT_STATUS,
): boolean => {
  return [
    CAMPAIGN_EXPORT_STATUS.ONGOING,
    CAMPAIGN_EXPORT_STATUS.ONGOING_CANCELLING,
    CAMPAIGN_EXPORT_STATUS.FAILED_CANCELLING,
    CAMPAIGN_EXPORT_STATUS.ERROR_CANCELLING,
  ].includes(status);
};

export const checkIfInCancel = (status: CAMPAIGN_EXPORT_STATUS): boolean => {
  return [
    CAMPAIGN_EXPORT_STATUS.CANCELLED,
    CAMPAIGN_EXPORT_STATUS.ONGOING_CANCELLING,
    CAMPAIGN_EXPORT_STATUS.ERROR_CANCELLING,
    CAMPAIGN_EXPORT_STATUS.FAILED_CANCELLING,
  ].includes(status);
};

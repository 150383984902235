import { LogDetails } from 'ssotool-app/app.model';

import { createAction, props } from '@ngrx/store';

import {
  CampaignImportFormModel,
  CampaignImportModel,
} from './campaign-import.model';

const get = createAction(
  '[Campaign Import Page] Get campaign import information',
  props<{ clientId: string; importId: string }>(),
);

const getSuccess = createAction(
  '[Campaign Import API] Get campaign import information success',
  props<{ data: any; message: string }>(),
);

const getFailure = createAction(
  '[Campaign Import API] Get campaign import information failure',
  props<{ data: any; error: any; message: string }>(),
);

const getList = createAction(
  '[Campaign Import Page], Get list of campaign import',
  props<{ clientId: string }>(),
);

const getListSuccess = createAction(
  '[Campaign Import API] Get list of campaign import success',
  props<{
    data: { data: CampaignImportModel[]; clientId: string };
    message: string;
  }>(),
);

const getListFailure = createAction(
  '[Campaign Import API] Get list of campaign import failure',
  props<{ data: any; error: any; message: string }>(),
);

const updateImportStatus = createAction(
  '[Campaign Import List] Update import status',
  props<{ clientId: string; importId: string; status: string }>(),
);

const importCampaign = createAction(
  '[Campaign Import Data Page], Import campaign data',
  props<{ clientId: string; data: CampaignImportFormModel }>(),
);

const importCampaignSuccess = createAction(
  '[Campaign Import API], Import campaign data success',
  props<{ data: any; message: string }>(),
);

const importCampaignFailure = createAction(
  '[Campaign Import API], Import campaign data failure',
  props<{ data: CampaignImportFormModel; error: any; message: string }>(),
);

const downloadCampaignImportData = createAction(
  '[Campaign Import Data Page], Download Campaign Data',
  props<{ clientId: string; importId: string }>(),
);

const downloadCampaignImportDataSuccess = createAction(
  '[Campaign Import API], Download Campaign Import Data Success',
  props<{ data: any; message: string }>(),
);

const downloadCampaignImportDataFailure = createAction(
  '[Campaign Import API], Download Campaign Import Data Failure',
  props<{ data: any; error: any; message: string }>(),
);

const downloadTemplate = createAction(
  '[Campaign List Page], Download template',
  props<{ clientId: string }>(),
);

const downloadTemplateSuccess = createAction(
  '[Campaign export API] Download template success',
  props<{ data: any; message: string }>(),
);

const downloadTemplateFailure = createAction(
  '[Campaign export API] Download template failure',
  props<{ data: any; error: any; message: string }>(),
);

const downloadErrorLogs = createAction(
  '[Campaign export API], Download error logs',
  props<{ clientId: string; importId: string }>(),
);

const downloadErrorLogsSuccess = createAction(
  '[Campaign export API], Download error logs success',
  props<{ importId: string; data: any; message: string }>(),
);

const downloadErrorLogsFailure = createAction(
  '[Campaign export API], Download error logs failure',
  props<{ data: any; error: any; message: string }>(),
);

const deleteImport = createAction(
  '[Campaign export API], Delete Import',
  props<{ clientId: string; importId: string }>(),
);

const deleteImportSuccess = createAction(
  '[Campaign export API], Delete import success',
  props<{ importId: string; data: any; message: string }>(),
);

const deleteImportFailure = createAction(
  '[Campaign export API], Delete import failure',
  props<{ data: any; error: any; message: string }>(),
);

const updateImportLogDetails = createAction(
  '[Campaign Import Data Page], Import log details update',
  props<{ importId: string; logDetails: LogDetails }>(),
);

export const CampaignImportActions = {
  get,
  getSuccess,
  getFailure,
  getList,
  getListSuccess,
  getListFailure,
  updateImportStatus,
  importCampaign,
  importCampaignSuccess,
  importCampaignFailure,
  downloadCampaignImportData,
  downloadCampaignImportDataSuccess,
  downloadCampaignImportDataFailure,
  downloadTemplate,
  downloadTemplateSuccess,
  downloadTemplateFailure,
  downloadErrorLogs,
  downloadErrorLogsSuccess,
  downloadErrorLogsFailure,
  deleteImport,
  deleteImportSuccess,
  deleteImportFailure,
  updateImportLogDetails,
};

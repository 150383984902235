<div *ngrxLet="selectedClient$ as client" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div
      fxLayout="row"
      fxLayoutGap="2rem"
      *ngIf="showClientDetails; else showLogo"
    >
      <div
        class="name-container"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="24px"
      >
        <ngx-skeleton-loader
          *ngIf="!client?.loaded; else showName"
          count="1"
          animation="progress"
          [theme]="{ margin: '12px 0', height: '16px', width: '200px' }"
        ></ngx-skeleton-loader>

        <ng-template #showName>
          <div
            fxLayout="row"
            fxLayoutGap="20px"
            fxLayoutAlign="start center"
            ssoTooltip
            [config]="clientTooltipConfig"
            [content]="clientDetails"
            class="trigger-container"
            data-testid="client-name"
          >
            <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
              <mat-icon fontSet="material-icons-outlined" class="gray-icon">
                folder_shared
              </mat-icon>
              <h3>{{ client?.name }}</h3>
            </span>

            <mat-icon class="gray-icon">arrow_drop_down</mat-icon>
          </div>

          <div
            #clientDetails
            class="client-details"
            fxLayout="column"
            fxLayoutGap="10px"
          >
            <div fxLayout="column">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <h1>{{ client?.name }}</h1>
                <div *ngIf="!client?.isSandbox" class="client-actions">
                  <div class="sso-text-gray">
                    <button
                      data-testid="edit-button-tippy"
                      mat-icon-button
                      *ngIf="isAdmin$ | async"
                      (click)="onEdit()"
                      [matTooltip]="'Generic.labels.edit' | translate"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      data-testid="duplicate-client"
                      *ngIf="isAdmin$ | async"
                      (click)="onDuplicate()"
                      [matTooltip]="'Generic.labels.duplicate' | translate"
                      [disabled]="(canDuplicate$ | async) === false"
                    >
                      <mat-icon>control_point_duplicate</mat-icon>
                    </button>
                    <button
                      data-testid="share-button-tippy"
                      mat-icon-button
                      data-testid="share-button-tippy"
                      (click)="onShare()"
                      [matTooltip]="'Generic.labels.share' | translate"
                    >
                      <mat-icon
                        matBadgeColor="accent"
                        [matBadge]="client?.sharedCount"
                        >groups</mat-icon
                      >
                    </button>
                    <button
                      data-testid="archive-button-tippy"
                      mat-icon-button
                      *ngIf="isAdmin$ | async"
                      (click)="onArchive()"
                      [matTooltip]="'Generic.labels.archive' | translate"
                    >
                      <mat-icon>archive</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="sso-text-gray sso-text-smaller">
                {{
                  'Client.messages.updatedLast'
                    | translate: { date: client?.updatedAt }
                }}
              </div>
            </div>
            <div
              class="client-description"
              *ngIf="client?.description as desc"
              fxLayout="row"
              fxLayoutGap="0.75rem"
            >
              <div fxLayout="column">
                <h3
                  #description
                  class="sso-remove-margin sso-text-gray"
                  [ngClass]="{ 'sso-text-ellipsis': !showMore }"
                >
                  {{ desc }}
                </h3>

                <div fxLayout="row" fxLayoutAlign="end">
                  <a
                    class="sso-text-small"
                    mat-button
                    color="primary"
                    (click)="showMore = !showMore"
                  >
                    {{
                      (showMore
                        ? 'Generic.labels.showLess'
                        : 'Generic.labels.showMore'
                      )
                        | translate
                        | titlecase
                    }}
                  </a>
                </div>
              </div>
            </div>
            <button mat-button color="primary" (click)="navigateToClientList()">
              {{ 'Client.labels.manageClients' | translate | uppercase }}
            </button>
            <div>
              <mat-action-list
                *ngFor="let client of this.recentClients$ | async"
              >
                <button mat-list-item (click)="clientClicked(client)">
                  <sso-navigatable-client
                    class="navigatable-client"
                    [clientName]="client.name"
                  ></sso-navigatable-client>
                </button>
              </mat-action-list>
            </div>
          </div>
        </ng-template>
      </div>
    </div>

    <div>
      <ng-content select="[header-global-actions]"></ng-content>
    </div>
  </div>

  <ng-template #showLogo>
    <sso-logo
      (click)="redirectToClientsPage()"
      expandedLogo="assets/logos/logo-ellipse.svg"
      class="logo-pointer"
    ></sso-logo>
  </ng-template>
</div>

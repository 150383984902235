import { createAction, props } from '@ngrx/store';
import {
  PartialRoadmapVisualization,
  ROADMAP_VISUALIZATION_FEATURE_NAME,
  RoadmapVisualization,
  RoadmapVisualizationSaveSuccess,
} from './visualization.model';

function createActionType(
  verb: string,
  status: 'Success' | 'Failed' | '' = '',
): string {
  return `[${ROADMAP_VISUALIZATION_FEATURE_NAME} API] ${verb} ${status}`;
}

const get = createAction(
  createActionType('Get'),
  props<{ clientId: string; roadmapId: string; visualizationId: string }>(),
);

const getSuccess = createAction(
  createActionType('Get', 'Success'),
  props<{ data: RoadmapVisualization }>(),
);

const getFailed = createAction(
  createActionType('Get', 'Failed'),
  props<{ clientId: string; roadmapId: string; visualizationId: string }>(),
);

const deleteAction = createAction(
  createActionType('Delete'),
  props<RoadmapVisualization>(),
);

const deleteFailed = createAction(
  createActionType('Delete', 'Failed'),
  props<{
    data: RoadmapVisualization;
    message: string;
  }>(),
);

const setActiveVisualization = createAction(
  createActionType('Set Active'),
  props<{ name: string }>(),
);

const resetActiveVisualization = createAction(createActionType('Reset Active'));

const deleteSuccess = createAction(
  createActionType('Delete', 'Success'),
  props<{
    data: {
      clientId: string;
      roadmapId: string;
      visualizationId: string;
      visualizationName: string;
    };
    message: string;
  }>(),
);

const deleteFromNotif = createAction(
  createActionType('Delete From Notif', ''),
  props<{
    clientId: string;
    roadmapId: string;
    visualizationId: string;
    visualizationName: string;
  }>(),
);

const save = createAction(
  createActionType('Save'),
  props<PartialRoadmapVisualization>(),
);

const saveSuccess = createAction(
  createActionType('Save', 'Success'),
  props<{
    data: RoadmapVisualizationSaveSuccess;
    message: string;
  }>(),
);

const saveFailed = createAction(
  createActionType('Save', 'Failed'),
  props<{
    name: string;
    message: string;
  }>(),
);

const getList = createAction(
  createActionType('GetList'),
  props<{ clientId: string; roadmapId: string }>(),
);

const getListSuccess = createAction(
  createActionType('GetList', 'Success'),
  props<{
    data: { data: RoadmapVisualization[]; clientId: string; roadmapId: string };
    message: string;
  }>(),
);

const getListFailure = createAction(
  createActionType('GetList', 'Failed'),
  props<{ data: any; error: any; message: string }>(),
);

export const RoadmapVisualizationActions = {
  get,
  getSuccess,
  getFailed,
  save,
  saveSuccess,
  saveFailed,
  getList,
  getListSuccess,
  getListFailure,
  deleteAction,
  deleteFailed,
  deleteSuccess,
  deleteFromNotif,
  setActiveVisualization,
  resetActiveVisualization,
};

import { CORE_KPI_NAMES } from '../../app.references';
import { WorldMapData } from '../stores/result/result.model';

export class WorldmapKpisMapper {
  static mapToFrontend = (sites: any[]): WorldMapData[] => {
    return sites
      ? sites.map((site) => ({
          ...site,
          position: WorldmapKpisMapper.mapPosition(site.position),
          values: WorldmapKpisMapper.mapValues(site.values),
        }))
      : [];
  };

  private static mapValues = (values: any) =>
    Object.entries<{ value: number; unit: string }>(values).reduce(
      (acc, [key, value]) => {
        const mappedKey = CORE_KPI_NAMES[key];

        /* istanbul ignore else */
        if (mappedKey) {
          acc[mappedKey] = {
            value: value?.value ? WorldmapKpisMapper.getValue(value.value) : 0,
            unit: value.unit,
          };
        }

        return acc;
      },
      {},
    );

  private static mapPosition = (position: any): any => ({
    latitude:
      position[0] || position[0] === 0 ? position[0] : position.latitude,
    longitude:
      position[1] || position[1] === 0 ? position[1] : position.longitude,
  });

  private static getValue = (value) => {
    if (typeof value === 'number') {
      return Number(value.toFixed(2));
    } else if (typeof value === 'object') {
      return [Number(value[0].toFixed(2)), Number(value[1].toFixed(2))];
    } else {
      return Number(Number(value).toFixed(2));
    }
  };
}

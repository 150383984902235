import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { ChartState } from './charts.reducer';
import { ChartActions } from './charts.actions';

import {
  selectLabelColorDictionary,
  selectColorByLabel,
} from './charts.selector';

@Injectable()
export class ChartFacadeService {
  private actions;

  constructor(private store: Store<ChartState>) {
    this.actions = ChartActions();
  }

  setColorByLabelGrouped(data: { label: string; hexValue: string }[]) {
    return this.store.dispatch(
      this.actions.setColorForLabelGrouped({ data: data }),
    );
  }

  setColorByLabel(label: string, hexValue: string) {
    return this.store.dispatch(
      this.actions.setColorForLabel({
        label,
        hexValue,
      }),
    );
  }

  getColorByLabel(label: string) {
    return this.store.select(selectColorByLabel(label));
  }

  getLabelColorData() {
    return this.store.select(selectLabelColorDictionary());
  }

  resetColorMap() {
    return this.store.dispatch(this.actions.resetColorMap());
  }
}

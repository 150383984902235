import { first } from 'rxjs/operators';
import { Coerce } from 'ssotool-shared/helpers';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { CampaignFacadeService } from '../store';

@Injectable({
  providedIn: 'root',
})
export class CampaignSubpageResolverService implements Resolve<any> {
  constructor(private campaignFacade: CampaignFacadeService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const clientId = Coerce.toObject(route.params).clientId;

    if (clientId) {
      this.campaignFacade.fetched$.pipe(first()).subscribe((fetched) => {
        if (!fetched) {
          this.campaignFacade.getList(clientId);
        }
      });
    }
  }
}

import { from } from 'rxjs';
import { ScoopaBase } from 'scoopabase';

import { Injectable } from '@angular/core';

import { Result } from './result.model';
import { LOCAL_DB_NAME, MAIN_COLLECTION_NAME } from './result.reference';

@Injectable()
export class ResultLocalStorageService {
  private scoopaDB = new ScoopaBase(LOCAL_DB_NAME);

  /**
   * Getter method for the collection of the main results.
   */
  get mainCollection() {
    return this.scoopaDB.collection<Result>(MAIN_COLLECTION_NAME);
  }

  createId(roadmapId: string, variationId: string) {
    return `${roadmapId}-${variationId}`;
  }

  /**
   * A function that returns an observable of a specific result entity
   * @param id id of the result: ${roadmapId}-${variationId}
   * @returns single result observable
   */
  getMainResultStream = (id: string, variationId: string) =>
    this.mainCollection.document$(this.createId(id, variationId));

  /**
   * Process the result to be saved in the local storage
   * @param result result entity to be saved in the local storage
   * @returns an observable of the promise
   */
  addMainResult(result: Result) {
    return from(this.mainCollection.add(result, result.id).then());
  }

  /**
   * Clear all of the contents of the local storage
   * Purpose is to remove the persistent of local storage
   */
  clear() {
    this.mainCollection.clearAll();
  }
}

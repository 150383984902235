import { from, of } from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  mergeMap,
  tap,
  toArray,
} from 'rxjs/operators';
import { doNothing } from 'ssotool-core/utils';
import { ExecStatusChecker } from 'ssotool-shared/helpers';
import {
  localStorage,
  SSOToolRoutePathService,
  UserStateManagerService,
  WindowService,
} from 'ssotool-shared/services';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  ClientToastMessageComponent,
  ClientToastMessageModel,
} from '../components/client-toast-message';
import { ClientAPIService } from '../services/client.service';
import { ClientActions } from './client.actions';
import { ClientFacadeService } from './client.facade.service';

export const ACTIVE_CLIENT_ID = 'activeClientId';

@UntilDestroy()
@Injectable()
export class ClientEffects {
  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.create),
      mergeMap((action) =>
        this.clientService.create(action.name, action.description).pipe(
          map((response) =>
            ClientActions.createSuccess({
              data: response,
              message: 'Create client success!',
            }),
          ),
          catchError((error) =>
            of(
              ClientActions.createFailure({
                data: action,
                error: (error.error || {}).error || 'Create client error!',
                message: (error.error || {}).error || 'Create client error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  createSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.createSuccess),
        tap((action) => {
          this.toastService.success(action.message);
        }),
        tap((action) => {
          // localStorage.set(ACTIVE_CLIENT_ID, action.data.clientId);
          // window.location.assign('/clients');
          this.clientFacade.setActiveClientAndReloadPage(action.data.clientId);
        }),
      ),
    { dispatch: false },
  );

  createFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.createFailure),
        tap((action) =>
          this.toastService.error<ClientToastMessageModel>(
            ClientToastMessageComponent,
            {
              data: {
                message: action.message,
                actionLabel: 'Generic.labels.retry',
                clientData: action.data,
                mode: 'create',
              },
            },
          ),
        ),
      ),
    { dispatch: false },
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.update),
      mergeMap((action) =>
        this.clientService
          .update(action.clientId, action.name, action.description)
          .pipe(
            map((response) =>
              ClientActions.updateSuccess({
                data: response,
                message: 'Update client success!',
              }),
            ),
            catchError((error) =>
              of(
                ClientActions.updateFailure({
                  data: action,
                  error: (error.error || {}).error || 'Update client error!',
                  message: (error.error || {}).error || 'Update client error!',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  updateSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.updateSuccess),
        tap((action) => this.toastService.success(action.message)),
      ),
    { dispatch: false },
  );

  updateFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.updateFailure),
        tap((action) =>
          this.toastService.error<ClientToastMessageModel>(
            ClientToastMessageComponent,
            {
              data: {
                message: action.message,
                actionLabel: 'Generic.labels.retry',
                clientData: action.data,
                mode: 'update',
              },
            },
          ),
        ),
      ),
    { dispatch: false },
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.get),
      mergeMap((action) =>
        this.clientService.get(action.clientId).pipe(
          map((response) =>
            ClientActions.getSuccess({
              data: response,
              message: 'Get client success!',
            }),
          ),
          catchError((error) => {
            return of(
              ClientActions.getFailure({
                data: action,
                code: error.status,
                error: (error.error || {}).error || 'Get client error!',
                message: (error.error || {}).error || 'Get client error!',
              }),
            );
          }),
        ),
      ),
    ),
  );

  getSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.getSuccess),
        tap((action) =>
          this.userManagerService.get(action.data.owner)
            ? doNothing()
            : this.userManagerService.getUserById(action.data.owner),
        ),
      ),
    { dispatch: false },
  );

  getFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.getFailure),
        tap((res) => {
          this.router.navigate(this.routeService.error(), {
            queryParams: {
              code: res.code,
            },
          });
        }),
      ),
    { dispatch: false },
  );

  getList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.getList),
      mergeMap((action) =>
        this.clientService.getList().pipe(
          map((response) =>
            ClientActions.getListSuccess({
              data: response,
              message: 'Get clients list success!',
            }),
          ),
          catchError((error) =>
            of(
              ClientActions.getListFailure({
                data: action,
                error: (error.error || {}).error || 'Get clients list error!',
                message: (error.error || {}).error || 'Get clients list error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getListSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.getListSuccess),
      tap((action) => {
        action.data.forEach((client) => {
          this.userManagerService.get(client.owner)
            ? doNothing()
            : this.userManagerService.getUserById(client.owner);
        });
      }),
      map((action) => ClientActions.getArchivedList()),
    ),
  );

  getArchivedList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.getArchivedList),
      mergeMap((action) =>
        this.clientService.getArchivedlist().pipe(
          map((response) =>
            ClientActions.getArchivedListSuccess({
              data: response,
              message: 'Get archived clients list success!',
            }),
          ),
          catchError((error) =>
            of(
              ClientActions.getArchivedListFailure({
                data: action,
                error:
                  (error.error || {}).error ||
                  'Get archived clients list error!',
                message:
                  (error.error || {}).error ||
                  'Get archived clients list error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getArchivedListSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.getArchivedListSuccess),
        tap((action) => {
          action.data.forEach((client) => {
            this.userManagerService.get(client.owner)
              ? doNothing()
              : this.userManagerService.getUserById(client.owner);
          });
        }),
      ),
    { dispatch: false },
  );

  getListFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.getListFailure),
        tap((action) => this.toastService.error(action.message)),
      ),
    { dispatch: false },
  );

  getArchivedListFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.getArchivedListFailure),
        tap((action) => this.toastService.error(action.message)),
      ),
    { dispatch: false },
  );

  getShareInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.getClientShareInfo),
      mergeMap((action) =>
        this.clientService.getClientShareInfo(action.clientId).pipe(
          map((response) =>
            ClientActions.getClientShareInfoSuccess({
              data: response,
              message: 'Get client share info success!',
            }),
          ),
          catchError((error) =>
            of(
              ClientActions.getFailure({
                data: action,
                error:
                  (error.error || {}).error || 'Get client share info error!',
                message:
                  (error.error || {}).error || 'Get client share info error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getShareInfoSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.getClientShareInfoSuccess),
        mergeMap((action) =>
          from(
            (action.data.shareInfo || [])
              .filter((info) =>
                this.userManagerService.get(info.userId) ? doNothing() : info,
              )
              .map((info) => info.userId),
          ).pipe(
            mergeMap((id: string) => {
              this.userManagerService.getUserById(id);
              return this.actions$.pipe(
                filter((act) => act.type === ClientActions.getSuccess.type),
              );
            }),
            toArray(),
          ),
        ),
      ),
    { dispatch: false },
  );

  getClientDataInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.getClientDataInfo),
      mergeMap((action) =>
        this.clientService.getClientDataInfo(action.clientId).pipe(
          map((response) =>
            ClientActions.getClientDataInfoSuccess({
              data: response,
              message: 'Get client data info success!',
            }),
          ),
          catchError((error) =>
            of(
              ClientActions.getClientDataInfoFailure({
                clientId: action.clientId,
                error:
                  (error.error || {}).error || 'Get client data info error!',
                message:
                  (error.error || {}).error || 'Get client data info error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getClientDataInfoSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.getClientDataInfoSuccess),
        // TODO: Fix hot toast or revert back to snackbar, this is unneeded anyway
        // you can delete
        // tap((action) => this.toastService.success(action.message)),
      ),
    { dispatch: false },
  );

  share$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.share),
      mergeMap((action) =>
        this.clientService.share(action.clientId, action.shareInfo).pipe(
          map((response) =>
            ClientActions.shareSuccess({
              data: response,
              message: 'Share client success!',
            }),
          ),
          catchError((error) =>
            of(
              ClientActions.shareFailure({
                data: action,
                error: (error.error || {}).error || 'Share client error!',
                message: (error.error || {}).error || 'Share client error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  shareSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.shareSuccess),
        tap((action) => this.toastService.success(action.message)),
        tap((action) => this.clientFacade.get(action.data.clientId)), // refresh client sharedCount
        // tap(() => this.router.navigate(['clients'])),
      ),
    { dispatch: false },
  );

  shareFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.shareFailure),
        tap((action) =>
          this.toastService.error<ClientToastMessageModel>(
            ClientToastMessageComponent,
            {
              data: {
                message: action.message,
                actionLabel: 'Generic.labels.retry',
                clientData: action.data,
                mode: 'share',
              },
            },
          ),
        ),
      ),
    { dispatch: false },
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.deleteClient),
      mergeMap((action) =>
        this.clientService.delete(action.clientId).pipe(
          map((response) =>
            ClientActions.deleteClientSuccess({
              data: response,
              message: 'Delete client success!',
            }),
          ),
          catchError((error) =>
            of(
              ClientActions.getFailure({
                data: action,
                error: (error.error || {}).error || 'Delete client error!',
                message: (error.error || {}).error || 'Delete client error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  deleteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.deleteClientSuccess),
      map((action) => {
        const deletedClientId = action.data?.clientId;
        let selectedClientId;
        this.clientFacade.activeClientId$
          .pipe(first())
          .subscribe((clientId) => {
            selectedClientId = clientId;
          });
        if (deletedClientId === selectedClientId) {
          this.toastService.info(
            'The active client has been deleted. Please select a client.',
          );
          this.clientFacade.removeActiveClient(deletedClientId);
          return ClientActions.removeActiveClient({
            clientId: selectedClientId,
          });
        } else {
          return ClientActions.doNothingAction();
        }
      }),
    ),
  );

  deleteFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.deleteClientFailure),
        tap((action) =>
          this.toastService.error<ClientToastMessageModel>(
            ClientToastMessageComponent,
            {
              data: {
                message: action.message,
                actionLabel: 'Generic.labels.retry',
                clientData: action.data,
                mode: 'delete',
              },
            },
          ),
        ),
      ),
    { dispatch: false },
  );

  selectActiveClient$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.selectActiveClient),
        tap((action) => localStorage.set(ACTIVE_CLIENT_ID, action.clientId)),
      ),
    { dispatch: false },
  );

  removeActiveClient$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.removeActiveClient),
        tap(() => localStorage.remove(ACTIVE_CLIENT_ID)),
      ),
    { dispatch: false },
  );

  archiveClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.archiveClient),
      mergeMap((action) =>
        this.clientService.archive(action.clientId, 'archive').pipe(
          map((response) =>
            ClientActions.archiveClientSuccess({
              data: action.clientId,
              message: 'Archive client success!',
            }),
          ),
          catchError((error) =>
            of(
              ClientActions.archiveClientFailure({
                data: action,
                error: (error.error || {}).error || 'Archive client error!',
                message: (error.error || {}).error || 'Archive client error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  archiveClientSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.archiveClientSuccess),
      map((action: any) => {
        const archiveClientId = action.data;
        let selectedClientId;
        this.clientFacade.activeClientId$
          .pipe(first())
          .subscribe((clientId) => {
            selectedClientId = clientId;
          });
        this.clientFacade.get(archiveClientId);
        if (archiveClientId === selectedClientId) {
          this.toastService.info(
            'The active client has been archived. Please select a client.',
          );
          this.clientFacade.removeActiveClient(archiveClientId);
          return ClientActions.removeActiveClient({
            clientId: selectedClientId,
          });
        } else {
          return ClientActions.doNothingAction();
        }
      }),
    ),
  );

  archiveClientFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.archiveClientFailure),
        tap((action) =>
          this.toastService.error<ClientToastMessageModel>(
            ClientToastMessageComponent,
            {
              data: {
                message: action.message,
                actionLabel: 'Generic.labels.retry',
                clientData: action.data,
                mode: 'archive',
              },
            },
          ),
        ),
      ),
    { dispatch: false },
  );

  unarchiveClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.unarchiveClient),
      mergeMap((action) =>
        this.clientService.archive(action.clientId, 'active').pipe(
          map((response) =>
            ClientActions.unarchiveClientSuccess({
              data: action.clientId,
              message: 'Unarchiving client success!',
            }),
          ),
          catchError((error) =>
            of(
              ClientActions.unarchiveClientFailure({
                data: action,
                error: (error.error || {}).error || 'Unarchiving client error!',
                message:
                  (error.error || {}).error || 'Unarchiving client error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  unarchiveClientSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.unarchiveClientSuccess),
      map((action) => ClientActions.get({ clientId: action.data })),
    ),
  );

  unarchiveClientFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.unarchiveClientFailure),
        tap((action) =>
          this.toastService.error<ClientToastMessageModel>(
            ClientToastMessageComponent,
            {
              data: {
                message: action.message,
                actionLabel: 'Generic.labels.retry',
                clientData: action.data,
                mode: 'archive',
              },
            },
          ),
        ),
      ),
    { dispatch: false },
  );

  duplicateClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.duplicateClient),
      mergeMap((action) =>
        this.clientService.duplicateClient(action.clientId).pipe(
          map(() =>
            ClientActions.duplicateClientSuccess({
              data: {
                clientId: action.clientId,
              },
              message: 'Client.messages.duplicateOngoing',
            }),
          ),
          tap(() => this.userManagerService.reloadCurrentUserPref()),
          catchError((error) =>
            of(
              ClientActions.duplicateClientFailure({
                data: action,
                error: (error.error || {}).error || 'Client duplicate error!',
                message: (error.error || {}).error || 'Client duplicate error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  duplicateClientSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.duplicateClientSuccess),
        tap((action) => {
          this.toastService.info<ClientToastMessageModel>(
            ClientToastMessageComponent,
            {
              data: {
                message: action.message,
                clientData: {
                  dupClientId: action.data.clientId,
                },
              },
            },
          );
        }),
      ),
    { dispatch: false },
  );

  duplicateClientFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.duplicateClientFailure),
        tap((action) => {
          this.toastService.error<ClientToastMessageModel>(
            ClientToastMessageComponent,
            {
              data: {
                message: action.message,
                actionLabel: 'Generic.labels.retry',
                clientData: {
                  dupClientId: action.data.clientId,
                },
                mode: 'duplicate',
              },
            },
          );
        }),
      ),
    { dispatch: false },
  );

  updateDuplicateStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.updateDuplicateStatus),
        tap((action) => {
          if (this.duplicateStatusChecker.isSuccessful(action.status)) {
            this.clientFacade.get(action.clientId);

            const message = action.isSandbox
              ? 'Client.messages.sandboxCreationSuccess'
              : 'Client.messages.duplicateSuccess';
            this.toastService.success<ClientToastMessageModel>(
              ClientToastMessageComponent,
              {
                data: {
                  message,
                  actionLabel: 'Client.labels.switch',
                  clientData: action,
                  mode: 'redirect',
                },
              },
            );
          } else {
            const message = action.isSandbox
              ? 'Client.messages.sandboxCreationFailure'
              : 'Client.messages.duplicateFailed';

            this.toastService.error<ClientToastMessageModel>(
              ClientToastMessageComponent,
              {
                data: {
                  message,
                  actionLabel: 'Generic.labels.retry',
                  clientData: action,
                  mode: 'duplicate',
                },
              },
            );
          }
          this.userManagerService.reloadCurrentUserPref();
        }),
      ),
    { dispatch: false },
  );

  quickSwitch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientActions.quickSwitch),
        tap((action) => {
          this.clientFacade.selectActiveClient(action.clientId);

          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
              redirect: action?.hasSuccessfulImport
                ? this.pathService.roadmap(action.clientId)
                : this.pathService.clientImport(action.clientId),
            },
          });
          this.windowService.reloadWindow();
          this.toastService.success(
            `You have switched to ${action.clientName} client!`,
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private windowService: WindowService,
    private activatedRoute: ActivatedRoute,
    private clientService: ClientAPIService,
    private pathService: SSOToolRoutePathService,
    private clientFacade: ClientFacadeService,
    private userManagerService: UserStateManagerService,
    private duplicateStatusChecker: ExecStatusChecker,
    private toastService: HotToastService,
    private dialog: MatDialog,
    private routeService: SSOToolRoutePathService,
  ) {}
}

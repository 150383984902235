import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { DataImportActions } from './data-import.actions';
import { DataImportModel } from './data-import.model';

export const dataImportFeatureKey = 'dataImport';

export interface DataImportState extends EntityState<Partial<DataImportModel>> {
  loading: boolean;
  error: string;
  message: string;
  downloading?: boolean;
  uploading?: boolean;
  loaded?: boolean;
}

export const selectImportId = (dataImport: DataImportModel) =>
  dataImport.importId;

export const sortDate = (
  importData1: DataImportModel,
  importData2: DataImportModel,
) => {
  const date1 = new Date(importData1.createdAt);
  const date2 = new Date(importData2.createdAt);
  return date2.getTime() - date1.getTime();
};

export const dataImportAdapter: EntityAdapter<Partial<DataImportModel>> =
  createEntityAdapter<DataImportModel>({
    selectId: selectImportId,
    sortComparer: sortDate,
  });

export const initialDataImportState: DataImportState =
  dataImportAdapter.getInitialState({
    loading: false,
    error: undefined,
    message: undefined,
    loaded: false,
    uploading: false,
  });

const dataImportReducer = createReducer(
  initialDataImportState,
  on(DataImportActions.get, (state) => {
    return { ...state, loading: true };
  }),
  on(DataImportActions.getSuccess, (state, { data, message }) => {
    return dataImportAdapter.upsertOne(
      { ...data, loaded: true, logsLoaded: false },
      { ...state, message, loading: false },
    );
  }),
  on(DataImportActions.getFailure, (state, { error, message }) => {
    return { ...state, error, loading: false, message };
  }),
  on(DataImportActions.getList, (state) => {
    return { ...state, loading: true, loaded: false };
  }),
  on(DataImportActions.getListSuccess, (state, { data, message }) => {
    const updateData = data.data.map(
      (d) => ({ ...d, loaded: true, logsLoaded: false } as DataImportModel),
    );
    return dataImportAdapter.upsertMany(updateData, {
      ...state,
      message,
      loading: false,
      loaded: true,
    });
  }),
  on(DataImportActions.getListFailure, (state, { error, message }) => {
    return { ...state, loading: false, error, message, loaded: false };
  }),
  on(DataImportActions.importData, (state) => ({ ...state, uploading: true })),
  on(DataImportActions.importDataSuccess, (state, { data, message }) =>
    dataImportAdapter.upsertOne(
      { ...data, loaded: true },
      { ...state, message, uploading: false },
    ),
  ),
  on(DataImportActions.importDataFailure, (state, { error, message }) => ({
    ...state,
    loading: false,
    uploading: false,
    error,
    message,
  })),
  on(DataImportActions.downloadClientData, (state) => ({
    ...state,
    loading: true,
  })),
  on(DataImportActions.downloadClientDataSuccess, (state, { message }) => ({
    ...state,
    loading: false,
    message,
  })),
  on(
    DataImportActions.downloadClientDataFailure,
    (state, { error, message }) => ({
      ...state,
      loading: false,
      error,
      message,
    }),
  ),
  on(DataImportActions.downloadTemplate, (state) => ({
    ...state,
    loading: true,
  })),
  on(DataImportActions.downloadTemplateSuccess, (state, { message }) => ({
    ...state,
    loading: false,
    message,
  })),
  on(
    DataImportActions.downloadTemplateFailure,
    (state, { error, message }) => ({
      ...state,
      loading: false,
      error,
      message,
    }),
  ),
  on(DataImportActions.downloadErrorLogs, (state) => ({
    ...state,
    downloading: true,
  })),
  on(
    DataImportActions.downloadErrorLogsSuccess,
    (state, { importId, data, message }) => {
      return dataImportAdapter.upsertOne(
        { importId, messages: data, logsLoaded: true },
        { ...state, downloading: false, message },
      );
    },
  ),
  on(
    DataImportActions.downloadErrorLogsFailure,
    (state, { error, message }) => ({
      ...state,
      downloading: false,
      error,
      message,
    }),
  ),
  on(
    DataImportActions.updateImportStatusSilently,
    (state, { importId, status, tdbStatus }) => {
      return dataImportAdapter.upsertOne(
        { importId, status: status, tdbStatus: tdbStatus },
        { ...state },
      );
    },
  ),
  on(DataImportActions.deleteImport, (state, { clientId, importId }) => {
    return { ...state, loading: true };
  }),
  on(DataImportActions.deleteImportOK, (state, { data, message }) => {
    return dataImportAdapter.removeOne(data.importId, {
      ...state,
      message,
      loading: false,
    });
  }),
  on(DataImportActions.deleteImportNOK, (state, { data, message, error }) => {
    return { ...state, message, error, loading: false };
  }),
  on(
    DataImportActions.updateImportLogDetails,
    (state, { importId, logDetails }) => {
      return dataImportAdapter.upsertOne(
        { importId, logDetails },
        { ...state },
      );
    },
  ),
);

export const DataImportStore = {
  featureName: dataImportFeatureKey,
  reducer: dataImportReducer,
};

export function DataImportReducer(
  state: DataImportState | undefined,
  action: Action,
) {
  return dataImportReducer(state, action);
}

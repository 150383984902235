import { SsoCommifyModule } from 'ssotool-app/shared/directives/commify';
import { FormService } from 'ssotool-app/shared/services';
import { YearlyViewModule } from 'ssotool-shared/component';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

import { SelectModule } from '../select/select.module';
import { RangeInputComponent } from './range-input.component';

const modules = [
  CommonModule,
  TranslateModule,
  FormsModule,
  ReactiveFormsModule,
  YearlyViewModule,
  SsoCommifyModule,
  SelectModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatIconModule,
];

@NgModule({
  declarations: [RangeInputComponent],
  imports: [...modules],
  exports: [RangeInputComponent],
  providers: [FormService],
})
export class RangeInputModule {}

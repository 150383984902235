import * as moment from 'moment';

import { GanttLockStatus, GanttViewMode } from './gantt.models';

export const GANTT_ITEM_HEIGHT = 48;
export const viewColumnWidths: Record<GanttViewMode, number> = {
  year: 70,
  month: 70,
  day: 1,
};

export function changeDate(
  origDate,
  change,
  mode: GanttViewMode = 'year',
  dateFormat = 'YYYY',
) {
  return getDate(origDate).subtract(change, mode).format(dateFormat);
}

export function getDateDiff(
  firstDate,
  secondDate,
  mode: GanttViewMode = 'year',
) {
  return getDate(firstDate).diff(getDate(secondDate), mode);
}

export function getDate(dateString, dateFormat = 'YYYY') {
  return moment(dateString, dateFormat);
}

// Min: 100, Max: 215
export function computeGanttWidth(dateLength, columnWidth: number): number {
  if (columnWidth < 50) {
    return dateLength * 50 + 50;
  } else if (columnWidth >= 50 && columnWidth <= 96) {
    return dateLength * columnWidth + 80;
  } else if (columnWidth > 96 && columnWidth <= 165) {
    return dateLength * columnWidth + 110;
  } else {
    return dateLength * 165 + 50;
  }
}

export function checkLock(lockLength: number, referenceLength: number) {
  if (referenceLength > 0 && lockLength === referenceLength) {
    return GanttLockStatus.LOCK;
  } else if (lockLength > 0 && lockLength < referenceLength) {
    return GanttLockStatus.PARTIAL;
  } else {
    return GanttLockStatus.OPEN_LOCK;
  }
}

import { filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';

import { DataImportActions } from './data-import.actions';
import { DataImportFormModel } from './data-import.model';
import { DataImportState } from './data-import.reducer';
import {
  dataImportEntities,
  downloading,
  getDataImportList,
  getLogs,
  latestImportNeedingTrajectories,
  latestSuccessfulImport,
  loaded,
  loading,
  logsLoaded,
  uploading,
} from './data-import.selector';

@Injectable()
export class DataImportFacadeService {
  constructor(
    private store: Store<DataImportState>,
    private actionSubject$: ActionsSubject,
  ) {}

  loading$ = this.store.pipe(select(loading));
  loaded$ = this.store.pipe(select(loaded));
  uploading$ = this.store.pipe(select(uploading));
  getDataImportEntities$ = this.store.pipe(select(dataImportEntities));
  getImportDataList$ = (clientId: string) =>
    this.store.pipe(select(getDataImportList(clientId)));
  downloading$ = this.store.pipe(select(downloading));
  getLatestSuccessfulImport$ = this.store.pipe(select(latestSuccessfulImport));
  getImportNeedingTrajectories$ = this.store.pipe(
    select(latestImportNeedingTrajectories),
  );

  getLogs(importId: string) {
    return this.store.pipe(select(getLogs(importId)));
  }

  logsLoaded$(importId: string) {
    return this.store.pipe(select(logsLoaded(importId)));
  }

  get(clientId: string, importId: string) {
    this.store.dispatch(DataImportActions.get({ clientId, importId }));
    return this.actionSubject$.pipe(
      filter((action) => action.type === DataImportActions.getSuccess.type),
    );
  }

  getList(clientId: string) {
    this.store.dispatch(DataImportActions.getList({ clientId }));
  }

  downloadClientData(clientId: string, importId: string) {
    this.store.dispatch(
      DataImportActions.downloadClientData({ clientId, importId }),
    );
  }

  downloadTemplate(clientId: string) {
    this.store.dispatch(DataImportActions.downloadTemplate({ clientId }));
  }

  // unused
  downloadErrorLogs(clientId: string, importId: string) {
    return this.store.dispatch(
      DataImportActions.downloadErrorLogs({ clientId, importId }),
    );
  }

  importData(clientId: string, importData: DataImportFormModel) {
    this.store.dispatch(
      DataImportActions.importData({
        clientId,
        inputFileData: importData,
        clientTimelineType: importData.clientTimelineType,
      }),
    );
  }

  updateImportStatus(
    clientId: string,
    importId: string,
    status: string,
    tdbStatus: string = '',
  ) {
    this.store.dispatch(
      DataImportActions.updateImportStatus({
        clientId,
        importId,
        status,
        tdbStatus,
      }),
    );
  }

  updateImportStatusSilently(
    importId: string,
    status: string,
    tdbStatus: string,
  ) {
    this.store.dispatch(
      DataImportActions.updateImportStatusSilently({
        importId,
        status,
        tdbStatus,
      }),
    );
  }

  updateImportLogDetails(
    importId: string,
    requestId: string,
    streamName: string,
  ) {
    this.store.dispatch(
      DataImportActions.updateImportLogDetails({
        importId,
        logDetails: { requestId, streamName },
      }),
    );
  }

  deleteImport(clientId: string, importId: string) {
    this.store.dispatch(DataImportActions.deleteImport({ importId, clientId }));
  }
}

import { FORM_OPTION_DIVIDER_VALUE } from 'ssotool-app/app.references';

import { FormGroup } from '@angular/forms';

import { Coerce } from './coerce.utils';

export type Position = 'left' | 'center' | 'right';

// trims the form fields for unwanted spaces
export const cleanForm = (formGroup: FormGroup) => {
  Coerce.getObjKeys(formGroup.controls).forEach((key) => {
    // istanbul ignore else
    if (!formGroup.get(key)) {
      formGroup.get(key).setValue(formGroup.get(key).value.trim());
    }
  });
};

export type FormFieldOption<T> = Readonly<{
  name: string;
  value: T;
  otherDetails?: Record<string, any>;
  tooltip?: string;
  tooltipPosition?: Position;
  icon?: string;
}>;

export type FormFieldErrorMessage = Record<string, string>;

export type FormFieldErrorMessageMap = Readonly<{
  [module: string]: FormFieldErrorMessage;
}>;

export type ErrorDataMap = Readonly<{
  [errorKey: string]: Record<string, Record<string, string>>;
}>;

export const generateErrorTranslationKey = (key: string, module: string) =>
  `${key}.${module}.`;

export const getDefaultRootValue = (options) =>
  Coerce.getObjKeys(options).find((key) => !options[key].parentId);

type EntityOptions = Readonly<{
  name: string;
  value: string;
  otherDetails?: any;
}>;

export const mapEntitiesToSelectOptions = (
  entity = {},
  sorted: boolean = true,
  addOtherDetails = false,
): EntityOptions[] => {
  const formOptions = Object.entries<any>(entity)
    .map(([value, details]) => ({
      name: details?.name,
      value,
      ...(!!addOtherDetails && { otherDetails: details }),
    }))
    .filter((val) => !!val.name);

  return sorted ? sortAlphabetical(formOptions) : formOptions;
};

export const sortAlphabetical = (data) => {
  return data.sort((a, b) => {
    /* istanbul ignore else */
    if (a.name && b.name) {
      return a.name.localeCompare(b.name);
    }
    return 0;
  });
};

export const getItemsWithKey = (objArray: any[], key = 'key'): any[] => {
  return objArray.reduce((acc, obj) => acc.concat(obj[key]), []);
};

export const hasMultipleValues = <T>(value: Record<string, T>) => {
  if (!!!value) {
    return false;
  }
  const valueSet = new Set(Coerce.getObjValues(value));
  return valueSet.size > 1;
};

export const getStartYear = (value: Record<string, string>): string => {
  return Coerce.getObjKeys(value || {}).sort()?.[0];
};

export const getEndYear = (value: Record<string, string>): string => {
  const values = Coerce.getObjKeys(value || {});
  return values.sort()?.[values.length - 1];
};

export const filterObjectByKeys = (
  obj: { [key: string]: any },
  includedKeys: string[],
): { [key: string]: any } => {
  return includedKeys.reduce((acc, key) => {
    const value = obj[key];

    /* istanbul ignore else */
    if (value) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export const convertArrayToFormFieldOptions = (
  array: string[],
): FormFieldOption<string>[] =>
  array.map((item) => ({ name: item, value: item }));

export const convertObjectToFormFieldOptions = (
  object: Record<string, string>,
): FormFieldOption<string>[] =>
  Object.entries(object || {}).map(([value, name]) => ({ name, value }));

export const generateKeyNameReference = (data) => {
  return Object.entries<any>(data || {}).reduce((acc, [key, value]) => {
    acc[key] = value?.name;
    return acc;
  }, {});
};

export const mapEnumToOptions = (
  enumeration: Record<string, string>,
): FormFieldOption<string>[] =>
  Object.entries(enumeration).map(([value, name]: [string, string]) => ({
    name,
    value,
  }));

export const getEnumKeyByValue = (
  enumeration: Record<string, string | number>,
  value: string | number,
): string =>
  Coerce.getObjKeys(enumeration).find(
    (enum_key) => enumeration[enum_key] == value,
  );

export const createDividerFormOption = (name: string) => ({
  name,
  value: FORM_OPTION_DIVIDER_VALUE,
});

export const getLeavesFromHids = (
  data: Record<string, Record<string, any>>,
  hids: string[],
) => {
  return Coerce.getObjValues(data).filter(
    (geo) => geo?.location && hids.some((hid) => geo?.hId?.startsWith(hid)),
  );
};

export const NEGATIVE_SIGN = 45;
export const DECIMAL_POINT = 46;
export const EXPONENTIAL_CONSTANT = 69;
export const EXPONENTIAL_CONSTANT_UPPERCASE = 101;
export const ALLOWED_CHARACTERS = [
  NEGATIVE_SIGN,
  DECIMAL_POINT,
  EXPONENTIAL_CONSTANT,
  EXPONENTIAL_CONSTANT_UPPERCASE,
]; // -, ., e, E
/**
 * This helps validating the key inputs for number fields
 * @param event - a keyboard event
 * @param allowedCharacters - list of charactes allowed the developer can define
 */
export const isKeyPressedNumber = (
  event: KeyboardEvent,
  allowedCharacters = ALLOWED_CHARACTERS,
): boolean => {
  // tslint:disable-next-line: deprecation
  const charCode = event.which ? event.which : event.keyCode;
  if (allowedCharacters.indexOf(charCode) !== -1) {
    return true;
  }
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
};

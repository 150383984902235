<section class="dataset">
  <ul>
    <li class="data-line" *ngFor="let data of dataset; let i = index">
      <span class="data-index"> {{ data.moment | date : dateFormat }} </span>
      <span class="timestamp">
        <span>{{ data.moment | date : 'h:mm' }}</span>
        <span>{{ data.moment | date : 'a' }}</span>
      </span>
      <span class="data-value">{{ data.value }}</span>
    </li>
  </ul>
</section>

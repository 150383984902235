import {
  animate,
  AnimationTriggerMetadata,
  keyframes,
  query,
  sequence,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const SlideInOutAnimation = [
  trigger('slideInOut', [
    transition(':enter', [
      style({ 'margin-top': '-60px' }),
      animate('300ms', style({ 'margin-top': '0px' })),
    ]),
    transition(':leave', [animate('300ms', style({ 'margin-top': '-60px' }))]),
  ]),
];

export const routerAnimation: AnimationTriggerMetadata = trigger(
  'routerAnimation',
  [
    transition('* => *', [
      query(':enter', style({ opacity: 0 }), { optional: true }),
      query(':enter', animate('0.35s ease-in', style({ opacity: 1 })), {
        optional: true,
      }),
    ]),
  ],
);

export const fadeInOut: AnimationTriggerMetadata = trigger('fadeInOut', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('150ms ease-in', style({ opacity: 1 })),
  ]),
  transition(':leave', [animate('150ms ease-out', style({ opacity: 0 }))]),
]);

export const slideAnimation = trigger('slideAnimation', [
  transition('void => *', [
    style({
      height: '*',
      opacity: '0',
      transform: 'translateX(-550px)',
      'box-shadow': 'none',
    }),
    sequence([
      animate(
        '0.35s ease',
        style({
          height: '*',
          opacity: 0.2,
          transform: 'translateX(0)',
          'box-shadow': 'none',
        }),
      ),
      animate(
        '0.35s ease',
        style({ height: '*', opacity: 1, transform: 'translateX(0)' }),
      ),
    ]),
  ]),
]);

// export const rowAnimation = trigger('rowAnimation', [
//   transition('* => *', [
//     query(':enter', style({ height: '*', opacity: '0', transform: 'translateX(-550px)', 'box-shadow': 'none' }), { optional: true }),
//     query(':enter', sequence([
//       animate('0.35s ease', style({ height: '*', opacity: 0.2, transform: 'translateX(0)', 'box-shadow': 'none' })),
//       animate('0.35s ease', style({ height: '*', opacity: 1, transform: 'translateX(0)' }))
//     ]), { optional: true })
//     // query(':leave', style({ height: '*', opacity: '1', transform: 'translateX(0)' }), { optional: true }),
//     // query(':leave', sequence([
//     //   animate('0.35s ease', style({ height: '0', opacity: 0.2, transform: 'translateX(-550px)' })),
//     //   animate('0.35s ease', style({ height: '0', opacity: 0, transform: 'translateX(-550px)', 'box-shadow': 'none'  }))
//     // ]), { optional: true })
//   ])
// ]);

export const rowAnimation = trigger('rowAnimation', [
  transition('void => *', [
    style({
      height: '*',
      opacity: '0',
      transform: 'translateX(-550px)',
      'box-shadow': 'none',
    }),
    sequence([
      animate(
        '.35s ease',
        style({
          height: '*',
          opacity: '.2',
          transform: 'translateX(0)',
          'box-shadow': 'none',
        }),
      ),
      animate(
        '.35s ease',
        style({ height: '*', opacity: 1, transform: 'translateX(0)' }),
      ),
    ]),
  ]),
]);

export const listAnimation = trigger('listAnimation', [
  transition('* => *', [
    query(':enter', style({ opacity: 0 }), { optional: true }),
    query(
      ':enter',
      stagger('300ms', [
        animate(
          '1s ease-in',
          keyframes([
            style({ opacity: 0, transform: 'translateY(-75%)' }),
            style({ opacity: 0.5, transform: 'translateY(35px)' }),
            style({ opacity: 1, transform: 'translateY(0)' }),
          ]),
        ),
      ]),
      { optional: true },
    ),
  ]),
]);

import {
  ClientReferenceData,
  ResultReferenceEntities,
  ResultReferenceCampaign,
} from '../stores/result';
import { BaseGraphMapper } from './base-graph.mapper';

export class EnvironmentalCurvesMapper extends BaseGraphMapper {
  protected static BASE_COLOR = '#69af23';

  static mapEnvironmentalCurves = (
    data,
    entities: ResultReferenceEntities,
    clientData: ClientReferenceData,
    campaignData: ResultReferenceCampaign,
  ) => {
    const unit = data?.['CO2 scope 1']?.[0]?.unit || '';
    const scope1 = EnvironmentalCurvesMapper.mapEntitiesNameAndIndex(
      data?.['CO2 scope 1'],
      entities,
      'totalCo2Emissions',
      data.index,
      clientData,
      campaignData,
      'scope1',
    );
    const scope2 = EnvironmentalCurvesMapper.mapEntitiesNameAndIndex(
      data?.['CO2 scope 2'],
      entities,
      'totalCo2Emissions',
      data.index,
      clientData,
      campaignData,
      'scope2',
    );
    const scope3 = EnvironmentalCurvesMapper.mapEntitiesNameAndIndex(
      data?.['CO2 scope 3'],
      entities,
      'totalCo2Emissions',
      data.index,
      clientData,
      campaignData,
      'scope3',
    );
    const totalCo2Emissions = scope1.concat(scope2).concat(scope3);

    return {
      totalCo2Emissions: { data: totalCo2Emissions, unit },
      scope1: { data: scope1, unit },
      scope2: { data: scope2, unit },
      scope3: { data: scope3, unit },
    };
  };

  static mapEnvironmentalCurvesv2 = (data) => {
    const totalCo2Emissions = data.scope1
      .concat(data.scope2)
      .concat(data.scope3);

    return {
      ...data,
      totalCo2Emissions: totalCo2Emissions,
    };
  };
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PushPipe } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';

import { StackedBarComponent } from './stacked-bar.component';

@NgModule({
  declarations: [StackedBarComponent],
  imports: [
    PushPipe,
    CommonModule,
    MatCardModule,
    TranslateModule,
    FlexLayoutModule,
    MatSlideToggleModule,
  ],
  exports: [StackedBarComponent],
})
export class StackedBarModule {}

import {
  CAMPAIGN_ENTITY,
  Flag,
  GEOGRAPHY_ENTITY,
} from 'ssotool-app/app.references';
import { Coerce } from 'ssotool-app/shared/helpers';

import { DetailsColumnDefinition } from './detailed-compare.model';

export enum CompareKpis {
  CAPEX = 'capex',
  OPEX = 'opex',
  CO2_EMISSIONS = 'totalCo2Emissions',
  ENERGY_CONSUMPTION = 'totalEnergyConsumption',
  ENERGY_PRODUCTION = 'totalEnergyProduction',
}
export const REQUIRED_KPI_VALUES = Coerce.getObjValues(CompareKpis);

export const COLUMN_CAMPAIGN_TYPE: DetailsColumnDefinition = {
  name: 'type',
  mode: 'campaignType',
};

export const COLUMN_CAMPAIGN_NAME: DetailsColumnDefinition = {
  name: 'name',
  header: 'Campaign Name',
};

export const COLUMN_KPI_LABEL: DetailsColumnDefinition = {
  name: 'kpiLabel',
  mode: 'values',
};

export const COLUMN_GEOGRAPHY: DetailsColumnDefinition = {
  name: 'name',
  header: 'Geography',
};

export const COLUMN_GEO_PARENT_NAME: DetailsColumnDefinition = {
  name: 'geoParent',
  mode: 'geoParent',
  header: 'Parent',
};

export const COLUMN_GEOGRAPHY_ATTRIBUTE: DetailsColumnDefinition = {
  name: 'geography',
  header: 'Geography',
};

export const COLUMN_ASSET_SIZE: DetailsColumnDefinition = {
  name: 'assetSize',
  header: 'Asset Size',
};

export const COLUMN_ASSET_INSTALLATION_YEAR: DetailsColumnDefinition = {
  name: 'assetInstallationYear',
  header: 'Asset Installation Year',
};

export const COLUMN_ASSET_EXPIRATION_YR: DetailsColumnDefinition = {
  name: 'assetExpirationYr',
  header: 'Asset Expiration Year',
};

export const COLUMN_DEFINITIONS = {
  [`${CAMPAIGN_ENTITY}1`]: [
    COLUMN_CAMPAIGN_TYPE,
    COLUMN_CAMPAIGN_NAME,
    COLUMN_KPI_LABEL,
  ],
  [`${CAMPAIGN_ENTITY}2`]: [COLUMN_GEOGRAPHY_ATTRIBUTE, COLUMN_KPI_LABEL],
  [`${CAMPAIGN_ENTITY}3`]: [
    COLUMN_ASSET_INSTALLATION_YEAR,
    COLUMN_ASSET_EXPIRATION_YR,
    COLUMN_KPI_LABEL,
  ],
  [`${GEOGRAPHY_ENTITY}1`]: [
    COLUMN_GEOGRAPHY,
    COLUMN_GEO_PARENT_NAME,
    COLUMN_KPI_LABEL,
  ],
  [`${GEOGRAPHY_ENTITY}2`]: [
    COLUMN_CAMPAIGN_TYPE,
    COLUMN_CAMPAIGN_NAME,
    COLUMN_KPI_LABEL,
  ],
  [`${GEOGRAPHY_ENTITY}3`]: [
    COLUMN_ASSET_INSTALLATION_YEAR,
    COLUMN_ASSET_EXPIRATION_YR,
    COLUMN_KPI_LABEL,
  ],
};

export const REQUIRED_ENTITY_FIELDS_MAPPER = {
  [`${CAMPAIGN_ENTITY}1`]: REQUIRED_KPI_VALUES,
  [`${CAMPAIGN_ENTITY}2`]: REQUIRED_KPI_VALUES,
  [`${CAMPAIGN_ENTITY}3`]: [COLUMN_ASSET_SIZE.name, CompareKpis.CAPEX],
  [`${GEOGRAPHY_ENTITY}1`]: REQUIRED_KPI_VALUES,
  [`${GEOGRAPHY_ENTITY}2`]: REQUIRED_KPI_VALUES,
  [`${GEOGRAPHY_ENTITY}3`]: [COLUMN_ASSET_SIZE.name, CompareKpis.CAPEX],
};

export const KPI_LABEL_MAPPER: Record<CompareKpis, string> = {
  [CompareKpis.CAPEX]: 'CAPEX',
  [CompareKpis.OPEX]: 'OPEX',
  [CompareKpis.CO2_EMISSIONS]: 'CO2 Emissions',
  [CompareKpis.ENERGY_CONSUMPTION]: 'Energy Inflow',
  [CompareKpis.ENERGY_PRODUCTION]: 'Energy Outflow',
  [COLUMN_ASSET_SIZE.name]: COLUMN_ASSET_SIZE.header,
};

export const VIEW_LEVEL_ATTRIBUTE = {
  [`${CAMPAIGN_ENTITY}1`]: '',
  [`${CAMPAIGN_ENTITY}2`]: 'name',
  [`${CAMPAIGN_ENTITY}3`]: 'geography',
  [`${GEOGRAPHY_ENTITY}1`]: '',
  [`${GEOGRAPHY_ENTITY}2`]: 'name',
  [`${GEOGRAPHY_ENTITY}3`]: 'name',
};

export const BREADCRUMB_INITIAL_DISPLAY_MAP = {
  [CAMPAIGN_ENTITY]: 'Campaigns',
  [GEOGRAPHY_ENTITY]: 'Geographies',
};

export const KPI_LABEL_FLAG: Record<CompareKpis, Flag> = {
  [CompareKpis.CAPEX]: Flag.NEGATIVE,
  [CompareKpis.OPEX]: Flag.NEGATIVE,
  [CompareKpis.CO2_EMISSIONS]: Flag.NEGATIVE,
  [CompareKpis.ENERGY_CONSUMPTION]: Flag.NEGATIVE,
  [CompareKpis.ENERGY_PRODUCTION]: Flag.POSITIVE,
  [COLUMN_ASSET_SIZE.name]: Flag.NEGATIVE,
};

export const GEOGRAPHY = { name: 'Geography', value: 'geography' };
export const YEARS = { name: 'Years', value: 'years' };
export const ENTITY = { name: 'Company', value: 'entity' };
export const CAMPAIGN = { name: 'Campaign', value: 'campaign' };
const LEVER = { name: 'Lever', value: 'lever' };
const PROCESS = { name: 'Process', value: 'process' };
const FLUID = { name: 'Fluid', value: 'fluid' };

export const COMMON_FILTER = [
  YEARS.value,
  LEVER.value,
  ENTITY.value,
  PROCESS.value,
  GEOGRAPHY.value,
  CAMPAIGN.value,
  FLUID.value,
];

export const SORTABLE_COLUMNS = [
  COLUMN_CAMPAIGN_NAME.name,
  COLUMN_GEOGRAPHY_ATTRIBUTE.name,
  COLUMN_ASSET_INSTALLATION_YEAR.name,
  COLUMN_ASSET_EXPIRATION_YR.name,
  COLUMN_ASSET_SIZE.name,
  COLUMN_GEOGRAPHY.name,
  COLUMN_GEO_PARENT_NAME.name,
];

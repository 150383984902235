import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SSOToolRoutePathService } from 'ssotool-shared';

@Component({
  selector: 'sso-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginPageComponent {
  constructor(
    private router: Router,
    private routePath: SSOToolRoutePathService,
  ) {}

  onLogin() {
    this.router.navigate(this.routePath.cognito());
  }
}

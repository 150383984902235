import { APPLICATION_STAGES } from 'ssotool-app/app.references';
import { FeatureFlagMap, FeatureFlagsState } from './feature-flagger.model';
import { FeatureFlag } from './feature-flags.config';
import { environment } from 'ssotool-env';

const STAGE = environment.name as APPLICATION_STAGES;

const dev: FeatureFlagsState = {
  [FeatureFlag.INPUT_SIMPLIFICATION_FEATURE]: true,
};

const test: FeatureFlagsState = {
  [FeatureFlag.INPUT_SIMPLIFICATION_FEATURE]: false,
};

const qa: FeatureFlagsState = {
  [FeatureFlag.INPUT_SIMPLIFICATION_FEATURE]: false,
};

const staging: FeatureFlagsState = {
  [FeatureFlag.INPUT_SIMPLIFICATION_FEATURE]: false,
};

const prod: FeatureFlagsState = {
  [FeatureFlag.INPUT_SIMPLIFICATION_FEATURE]: false,
};

const algo: FeatureFlagsState = {
  [FeatureFlag.INPUT_SIMPLIFICATION_FEATURE]: true,
};

const flaggerMapper: FeatureFlagMap = {
  [APPLICATION_STAGES.DEV]: dev,
  [APPLICATION_STAGES.TEST]: test,
  [APPLICATION_STAGES.QA]: qa,
  [APPLICATION_STAGES.STAGING]: staging,
  [APPLICATION_STAGES.PRODUCTION]: prod,
  [APPLICATION_STAGES.ALGO]: algo,
};

const getFeature = (feature: FeatureFlag): boolean => {
  const flags = flaggerMapper[STAGE];
  if (!flags) {
    throw new Error(`Stage ${STAGE} not supported`);
  }
  return flags[feature];
};

const isFeatureEnabled = (feature: FeatureFlag): boolean => getFeature(feature);

const isFeatureDisabled = (feature: FeatureFlag): boolean =>
  !getFeature(feature);

export { getFeature, isFeatureEnabled, isFeatureDisabled };

import { Injectable } from '@angular/core';
import { ToolRoute } from './tools-router.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToolsRouterService {
  private _routes: ToolRoute = null;
  set routes(routes: ToolRoute) {
    this._routes = routes;
    this.activateRoute(routes.id);
  }
  get routes(): ToolRoute {
    return this._routes;
  }

  activeRoute = new BehaviorSubject<ToolRoute['id']>(null);
  breadcrumbs = new BehaviorSubject<ToolRoute[]>([]);

  activateRoute(id: ToolRoute['id']): void {
    this.activeRoute.next(id);
    this.breadcrumbs.next(this.getPath(this.routes, id));
  }

  private getPath(
    currentRoute: ToolRoute,
    id: ToolRoute['id'],
    path: ToolRoute[] = [],
  ): ToolRoute[] {
    if (currentRoute.id === id) {
      return [...path, currentRoute];
    }

    if (!!currentRoute.children.length) {
      for (const route of currentRoute.children) {
        if (route.id === id) {
          return [...path, currentRoute, route];
        }
        return this.getPath(route, id, [...path, currentRoute]);
      }
    } else {
      throw new Error('Route not found');
    }
  }
}

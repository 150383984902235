import { from, of } from 'rxjs';
import { catchError, first, map, mapTo, mergeMap, tap } from 'rxjs/operators';
import { DatahubFacadeService } from 'ssotool-app/+client/store/datahub/datahub.facade.service';
import { HAS_COMMODITY_PRICES } from 'ssotool-app/app.references';
import { ToastService } from 'ssotool-app/shared/services/toast/toast.service';
import { doNothing } from 'ssotool-core/utils';
import { ExecStatusChecker, UserStateManagerService } from 'ssotool-shared';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { DataImportAPIService } from '../services/data-import/data-import.service';
import { DataImportFacadeService } from './data-import-facade.service';
import { DataImportActions } from './data-import.actions';

@Injectable()
export class DataImportEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.get),
      mergeMap((action) =>
        this.dataImportService.get(action.clientId, action.importId).pipe(
          map((response) =>
            DataImportActions.getSuccess({
              data: response,
              message: 'Get client data import info success!',
            }),
          ),
          catchError((error) =>
            of(
              DataImportActions.getFailure({
                data: action,
                error:
                  (error.error || {}).error || 'Get data import info error!',
                message:
                  (error.error || {}).error || 'Get data import info error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataImportActions.getSuccess),
        tap((action) =>
          this.userManagerService.get(action.data.owner)
            ? doNothing()
            : this.userManagerService.getUserById(action.data.owner),
        ),
      ),
    { dispatch: false },
  );

  getFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataImportActions.getFailure),
        tap((action) => this.toast.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  getList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.getList),
      mergeMap((action) =>
        this.dataImportService.getList(action.clientId).pipe(
          map((response) => {
            return DataImportActions.getListSuccess({
              data: {
                data: response,
                clientId: action.clientId,
              },
              message: 'Get data import list success!',
            });
          }),
          catchError((error) =>
            of(
              DataImportActions.getListFailure({
                data: action,
                error:
                  (error.error || {}).error || 'Get data import list error!',
                message:
                  (error.error || {}).error || 'Get data import list error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getListSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataImportActions.getListSuccess),
        mergeMap((action) =>
          this.dataImportFacade.getDataImportEntities$.pipe(
            mergeMap((dataList) =>
              from(
                (action?.data?.data || [])
                  .filter((data) =>
                    dataList?.[data?.importId]?.loaded ? doNothing() : data,
                  )
                  .map((data) => data),
              ),
            ),
            mapTo(action),
          ),
        ),
      ),
    { dispatch: false },
  );

  getListFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataImportActions.getListFailure),
        tap((action) => this.toast.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  downloadClientData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.downloadClientData),
      mergeMap((action) =>
        this.dataImportService
          .downloadClientData(action.clientId, action.importId)
          .pipe(
            map((response) =>
              DataImportActions.downloadClientDataSuccess({
                data: response,
                message: 'Download Client Data Success',
              }),
            ),
            catchError((error) =>
              of(
                DataImportActions.getFailure({
                  data: action,
                  error:
                    (error.error || {}).error || 'Download client data error!',
                  message:
                    (error.error || {}).error || 'Download client data error!',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  downloadClientDataSuccess$ = createEffect(
    () =>
      this.actions$.pipe(ofType(DataImportActions.downloadClientDataSuccess)),
    { dispatch: false },
  );

  downloadClientDataFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataImportActions.downloadClientDataFailure),
        tap((action) => this.toast.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  downloadTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.downloadTemplate),
      mergeMap((action) =>
        this.dataImportService.downloadTemplate(action.clientId).pipe(
          map((response) =>
            DataImportActions.downloadTemplateSuccess({
              data: response,
              message: 'Download template success',
            }),
          ),
          catchError((error) =>
            of(
              DataImportActions.getFailure({
                data: action,
                error: (error.error || {}).error || 'Download template error!',
                message:
                  (error.error || {}).error || 'Download template error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  downloadTemplateSuccess$ = createEffect(
    () => this.actions$.pipe(ofType(DataImportActions.downloadTemplateSuccess)),
    { dispatch: false },
  );

  downloadTemplateFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataImportActions.downloadTemplateFailure),
        tap((action) => this.toast.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  // unused
  downloadErrorLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.downloadErrorLogs),
      mergeMap((action) =>
        this.dataImportService
          .downloadErrorLogs(action.clientId, action.importId)
          .pipe(
            map((response) =>
              DataImportActions.downloadErrorLogsSuccess({
                importId: action.importId,
                data: response,
                message: 'Download logs success',
              }),
            ),
            catchError((error) =>
              of(
                DataImportActions.downloadErrorLogsFailure({
                  data: action,
                  error: (error.error || {}).error || 'Download logs error!',
                  message: (error.error || {}).error || 'Download logs error!',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  // unused
  downloadErrorLogsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataImportActions.downloadErrorLogsSuccess),
        tap((action) => this.toast.showToast(action.message, 'success')),
      ),
    { dispatch: false },
  );

  // unused
  downloadErrorLogsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataImportActions.downloadErrorLogsFailure),
        tap((action) => this.toast.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  importData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.importData),
      mergeMap((action) =>
        this.dataImportService
          .importData(action.clientId, action.inputFileData)
          .pipe(
            map((response) =>
              DataImportActions.importDataSuccess({
                data: response,
                message: 'Import started.',
              }),
            ),
            catchError((error) =>
              of(
                DataImportActions.importDataFailure({
                  importData: action.inputFileData,
                  error:
                    (error.error || {}).error || 'Import client data error!',
                  message:
                    (error.error || {}).error || 'Import client data error!',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  updateImportStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataImportActions.updateImportStatus),
        tap((action) => {
          // If import is still ongoing
          if (this.importStatusChecker.checkImport(action.status)) {
            // Silently update the status
            this.dataImportFacade.updateImportStatusSilently(
              action.importId,
              action.status,
              action.tdbStatus,
            );

            if (action.tdbStatus === HAS_COMMODITY_PRICES) {
              this.datahubFacade.getTDBCommodities(
                action.clientId,
                action.importId,
              );
            }
          } else {
            // Reload the data
            if (this.importStatusChecker.isSuccessful(action.status)) {
              window.location.assign(`/clients/${action.clientId}/entities`);
            } else {
              this.toast.showToast('Import Failed!', 'error');
            }
            this.dataImportFacade.get(action.clientId, action.importId);
          }
        }),
      ),
    { dispatch: false },
  );

  importDataSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataImportActions.importDataSuccess),
        tap((action) => this.toast.showToast(action.message, 'success')),
      ),
    { dispatch: false },
  );

  importDataFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataImportActions.importDataFailure),
        tap((action) => this.toast.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  deleteImport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.deleteImport),
      mergeMap((action) =>
        this.dataImportService
          .deleteImport(action.clientId, action.importId)
          .pipe(
            map((response) =>
              DataImportActions.deleteImportOK({
                data: action,
                message: 'Successfully deleted import',
              }),
            ),
            catchError((error) =>
              of(
                DataImportActions.deleteImportNOK({
                  data: action,
                  error: (error.error || {}).error || 'Delete import error',
                  message: (error.error || {}).error || 'Delete import error',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  deleteImportOK$ = createEffect(
    () => this.actions$.pipe(ofType(DataImportActions.deleteImportOK)),
    { dispatch: false },
  );

  deleteImportNOK$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataImportActions.deleteImportNOK),
        tap((action) => {
          this.toast.showToast(
            action.message,
            'action',
            undefined,
            of({}).pipe(
              first(),
              tap((dialogData) => {
                this.dataImportFacade.deleteImport(
                  action.data.clientId,
                  action.data.importId,
                );
              }),
            ),
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private dataImportService: DataImportAPIService,
    private toast: ToastService,
    private userManagerService: UserStateManagerService,
    private dataImportFacade: DataImportFacadeService,
    private importStatusChecker: ExecStatusChecker,
    private datahubFacade: DatahubFacadeService,
  ) {}
}

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClickMode } from 'ssotool-app/shared/directives/selection-emitter';
import {
  MULTISELECTION_CLASS,
  MultiselectionService,
  SelectionEventData,
} from 'ssotool-app/shared/services/multiselection';
import { DialogComponent } from 'ssotool-shared/component/dialog';

import { SelectionModel } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
// Components
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { GanttDialogComponent } from './gantt-dialog/gantt-dialog.component';
import {
  GanttConfig,
  GanttItem,
  GanttItemAction,
  GanttMultiSelectionChanges,
  GanttNameClickEvent,
  GanttViewMode,
  PreSelectedType,
} from './gantt.models';
import {
  computeGanttWidth,
  GANTT_ITEM_HEIGHT,
  getDate,
  getDateDiff,
  viewColumnWidths,
} from './gantt.references';
import { Coerce } from 'ssotool-app/shared/helpers';
import { HorizonValues } from 'ssotool-app/+roadmap/modules/timeline/roadmap-timeline.model';

const initialConfig: GanttConfig = {
  taskName: 'Task',
  startDate: '2010',
  endDate: '2050',
  viewMode: 'year',
  dateFormat: 'YYYY', // moment format
  emptyChartMessage: 'No items.',
  limitColor: '#e0e0e0',
};

@UntilDestroy()
@Component({
  selector: 'sso-gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: GanttComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: GanttComponent, multi: true },
    MultiselectionService,
  ],
})
export class GanttComponent
  implements ControlValueAccessor, OnDestroy, OnInit, AfterViewInit
{
  private BATCH_LOAD_SIZE: number = 50;
  preSelectedIds$ = new BehaviorSubject<string[]>([]);

  form = this.formBuilder.group({
    items: [],
  });

  get formItems() {
    return this.form.controls.items as FormControl;
  }

  chartItems$ = new BehaviorSubject<GanttItem[]>([]);
  chartShownItems$ = new BehaviorSubject<GanttItem[]>([]);
  selection: SelectionModel<string>;

  private _config = new BehaviorSubject<GanttConfig>(initialConfig);

  @Input() set config(config: GanttConfig) {
    this.initializeConfig(config);
  }

  get config() {
    return this._config.value;
  }

  config$ = this._config.asObservable();

  startDate$ = this.config$.pipe(map((config) => config.startDate));

  // @Input() config: GanttConfig;
  @Input() isDisabled = false;
  @Input() isLockMeatBallDisabled = false;
  @Input() readonly = false;
  @Input() dialogInputLabel = 'Date';
  @Input() hideAction = false;
  @Input() disableOutputMovement = true;
  @Input() showResultsOnlyItems = true;
  @Input() ganttItemActions: GanttItemAction[] = [];
  @Input() disabledActionsByTypeMap: Record<string, string[]> = {};
  private _isSelectionView = new BehaviorSubject<boolean>(false);
  @Input() set isSelectionView(isSelectionView: boolean) {
    this._isSelectionView.next(isSelectionView);
    if (isSelectionView) {
      this.initializeSelectionView();
    }
    this.initializeObservableItems();
  }

  @Output() lockClick = new EventEmitter<GanttItem>();
  @Output() openLockClick = new EventEmitter<GanttItem>();
  @Output() preSelectedChange = new EventEmitter<PreSelectedType>();
  @Output() horizonChanged = new EventEmitter<HorizonValues>();
  @Output() barChanged = new EventEmitter<GanttItem>();

  get isSelectionView() {
    return this._isSelectionView.value;
  }

  infoIconTooltipConfig = {
    followCursor: false,
    placement: 'bottom-end',
    animation: 'shift-away',
    theme: 'dark-blue',
    arrow: false,
    delay: [0, 0],
  };

  ganttHeaderWidth = 0;
  _dates = new BehaviorSubject<string[]>([]);

  private set dates(dates: string[]) {
    this._dates.next(dates);
  }

  get dates() {
    return this._dates.value;
  }
  dates$ = this._dates.asObservable();

  subDates: string[] = [];
  incompatibleDateRange = false;
  columnWidth = 0;
  subColumnWidth = 0;
  headerHeight = 30;
  scrollTop = 0;
  scrollLeft = 0;

  hasLimits = false;
  ganttHeightLimit = 0;
  ganttWidthLimit = 0;
  ganttLeftLimit = 0;
  itemHeight = GANTT_ITEM_HEIGHT;

  _onChange;

  // Scroll navigation properties
  xStart = 0;
  xMax = 0;
  yMax = 0;
  yStart = 0;

  @Output() nameClick = new EventEmitter<GanttNameClickEvent>();
  @Output() selectionChanges = new EventEmitter<string[]>();

  @ViewChild('yearColumns')
  yearColumns: ElementRef;

  @ViewChildren('scrollViewport')
  scrollViewport: QueryList<CdkVirtualScrollViewport>;

  get enableActionColumn() {
    return !this.isDisabled && !this.hideAction;
  }

  get enableActionColumnForAll() {
    return !this.readonly && !this.isDisabled && !this.hideAction;
  }

  private multiSelectChanges = new BehaviorSubject<GanttMultiSelectionChanges>({
    ids: [],
    limitLeftChange: 0,
    limitWidthChange: 0,
  });
  multiSelectChanges$ = this.multiSelectChanges.asObservable();

  observableSubscriptions: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private multiselectionService: MultiselectionService,
    private elementReference: ElementRef,
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((form) => {
      this._onChange(form.items);
      this.initializeObservableItems();
    });
    this.initializePreselectedListener();

    this.incompatibleDateRange = this.chartItems$.value
      .filter((item) => !!item.isShown)
      .some((item) => {
        if (!item.startLimit || !item.endLimit) {
          return false;
        }
        return (
          getDateDiff(item.startLimit, this.config.startDate) < 0 ||
          getDateDiff(item.endLimit, this.config.endDate) > 0
        );
      });
  }

  ngOnDestroy(): void {
    document
      .querySelectorAll('mat-drawer-content')[1]
      ?.classList?.remove('viewport-hidden');
  }

  ngAfterViewInit(): void {
    this.computeGanttWidth();
  }

  isSelectedItem(id: string): Observable<boolean> {
    return this.preSelectedIds$.pipe(
      map((selectedItems) => selectedItems.includes(id)),
    );
  }

  writeValue(chartItems: any): void {
    const updatedItems = this.initializeChartItemsDetails(chartItems);
    this.chartItems$.next(updatedItems);
    this.initializeChartItems();
    this.updateMultiselectionAllItems();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  validate({ value }: FormControl) {
    return !this.form || this.form.valid
      ? null
      : { error: 'Some fields are not fullfilled' };
  }

  disableEditActions(item: GanttItem) {
    return (
      this.readonly ||
      this.isSelectionView ||
      !this.isResultsOnlyItemEditable(item)
    );
  }

  disableActionColumn(item: GanttItem) {
    return this.disableEditActions(item) || this.isLockMeatBallDisabled;
  }

  initializeChartItemsDetails(chartItems: GanttItem[]) {
    return chartItems?.length
      ? chartItems
          .map((item) => ({
            ...item,
            color: item.color || '#8c8c8c',
            isDraggable: this.isDraggable(
              item.startLimit,
              item.endLimit,
              this.getStartYearHorizonDate(),
              this.getEndYearHorizonDate(),
            ),
          }))
          .sort((a, b) => a?.name?.localeCompare(b?.name))
      : [];
  }

  initializeChartItems() {
    this.initializeForm();
    this.initializeViewMode();
    this.initializeObservableItems();
    // Remove offset for now
    // this.initializeGanttViewLeftOffset();
    this.initializeLimits();
  }

  initializeConfig(config: GanttConfig) {
    this._config.next({
      ...initialConfig,
      ...config,
    });
    this.columnWidth = viewColumnWidths[this.config.viewMode];
    this.initializeViewMode();
    this.writeValue(this.formItems.value);
    this.computeGanttWidth();
  }

  initializeForm() {
    this.formItems.patchValue(this.chartItems$.value, { emitEvent: false });
  }

  private computeGanttWidth(): void {
    const dateLength = getDateDiff(
      this.config.endDate,
      this.config.startDate,
      this.config.viewMode,
    );
    this.ganttHeaderWidth = computeGanttWidth(dateLength, this.columnWidth);
    let elementWidth = Coerce.toZero(
      Coerce.toEmptyObject(
        Coerce.toEmptyObject(this.yearColumns).nativeElement as HTMLDivElement,
      ).offsetWidth,
    );
    if (!!elementWidth && this.ganttHeaderWidth < elementWidth) {
      this.ganttHeaderWidth = elementWidth;
      this.recalculateColumnWidth(elementWidth, dateLength);
    }
  }

  private recalculateColumnWidth(
    elementWidth: number,
    dateLength: number,
  ): void {
    let fitHeaderWidth = elementWidth / (dateLength + 1);
    if (fitHeaderWidth > this.columnWidth) {
      this.columnWidth = fitHeaderWidth;
    }
  }

  initializeViewMode() {
    switch (this.config.viewMode) {
      case 'year':
        this.dates = this.getDates('year', 'YYYY');
        break;
      case 'month':
        // not yet properly implemented.
        this.subColumnWidth = this.columnWidth * 12;
        this.subDates = this.getDates('year', 'YYYY');
        this.dates = this.getDates('month', 'MMMM');
        break;
      default:
        break;
    }
  }

  @HostListener('document:click', ['$event'])
  onWindowClick(event) {
    if (
      !this.isGanttClicked(event?.target) &&
      !this.isMultiselectionActionsClicked(event?.path)
    ) {
      this.multiselectionService.unselectAll();
    }
  }

  isResultsOnlyItem(item: GanttItem): boolean {
    return item.isResultsOnly && this.showResultsOnlyItems;
  }

  isResultsOnlyItemEditable(item: GanttItem): boolean {
    return this.isResultsOnlyItem(item) ? item.isShown : true;
  }

  private isGanttClicked(target: EventTarget) {
    return this.elementReference.nativeElement.contains(target);
  }

  private isMultiselectionActionsClicked(paths: Element[]) {
    return paths?.some((path) =>
      path?.classList?.value?.includes(MULTISELECTION_CLASS),
    );
  }

  getDates(type: GanttViewMode, format: string) {
    const dateStart = getDate(this.config.startDate);
    const dateEnd = getDate(this.config.endDate);
    const interim = dateStart.clone();
    const timeValues = [];

    while (
      dateEnd > interim ||
      interim.format(format) === dateEnd.format(format)
    ) {
      timeValues.push(interim.format(format));
      interim.add(1, type);
    }

    return timeValues;
  }

  initializeObservableItems() {
    const filteredItems = this.chartItems$.value.filter(
      (item) =>
        (item.isFiltered && (this.isSelectionView || item.isShown)) ||
        (item.isFiltered && this.isResultsOnlyItem(item)),
    );
    this.chartShownItems$.next(filteredItems);
  }

  private initializePreselectedListener(): void {
    this.preSelectedIds$
      .pipe(
        untilDestroyed(this),
        map((ids) => this.preSelectedChange.emit(ids)),
      )
      .subscribe();
    this.multiselectionService.selected$
      .pipe(untilDestroyed(this))
      .subscribe((selected) => this.preSelectedIds$.next(selected));
  }

  private initializeSelectionView() {
    if (this.observableSubscriptions) {
      this.observableSubscriptions.unsubscribe();
    }

    this.selection = new SelectionModel<string>(
      true,
      this.chartItems$.value
        .filter((item) => item.isShown)
        .map((item) => item.id),
    );
    this.observableSubscriptions = this.selection.changed.subscribe((changes) =>
      this.selectionChanges.emit(changes.source.selected.map((item) => item)),
    );
  }

  initializeGanttViewLeftOffset() {
    const shownItems = [...this.chartShownItems$.value];
    /* istanbul ignore else */
    if (shownItems.length) {
      const minItemDate =
        shownItems.length === 1
          ? shownItems[0].startLimit
          : shownItems.sort((a, b) =>
              getDateDiff(a.startLimit, b.startLimit),
            )[0].startLimit;

      this.scrollLeft =
        (getDateDiff(minItemDate, this.config.startDate) * this.columnWidth ||
          0) - this.columnWidth;
    }
  }

  initializeLimits() {
    const { startLimit, endLimit, viewMode, startDate } = this.config;

    this.hasLimits = !!startLimit && !!endLimit;
    this.ganttHeightLimit =
      this.itemHeight * this.chartShownItems$.value.length;
    this.ganttWidthLimit =
      getDateDiff(endLimit, startLimit, viewMode) * this.columnWidth +
      this.columnWidth;
    this.ganttLeftLimit =
      getDateDiff(startLimit, startDate, viewMode) * this.columnWidth;
  }

  updateChartItemDate(event: GanttItem) {
    const { updateVariable, updateFn } = this.config;
    const selectedIDs = this.preSelectedIds$.getValue();
    if (!!selectedIDs?.length && this.ganttItemEventHasChanges(event)) {
      this.multiSelectChanges.next({
        ids: selectedIDs.filter((id) => id != event.id),
        limitLeftChange: event.leftChange,
        limitWidthChange: event.widthChange,
      });
    }
    if (event.endLimit > this.config.validEndLimit) {
      event.endLimit = this.config.validEndLimit;
    }

    if (event.startLimit < this.config.validStartLimit) {
      event.startLimit = this.config.validStartLimit;
    }

    const itemChanged = this.updateNewChartItems(event.id, {
      startDate: event.startDate,
      endDate: event.endDate,
      startLimit: event.startLimit,
      endLimit: event.endLimit,
      isDraggable: this.isDraggable(
        event.startLimit,
        event.endLimit,
        this.getStartYearHorizonDate(),
        this.getEndYearHorizonDate(),
      ),
      ...(!!updateVariable && {
        [updateVariable]: updateFn?.(
          event[updateVariable],
          event.startLimit,
          event.endLimit,
        ),
      }),
    });

    this.barChanged.emit(itemChanged);

    this.updateHorizon(event);
  }

  isDraggable(
    startLimit: string,
    endLimit: string,
    startYearHorizon: string,
    endYearHorizon: string,
  ) {
    const startLimitDate = getDate(startLimit);
    const endLimitDate = getDate(endLimit);
    const startYearHorizonDate = getDate(startYearHorizon);
    const endYearHorizonDate = getDate(endYearHorizon);
    return (
      startLimitDate > startYearHorizonDate || endLimitDate < endYearHorizonDate
    );
  }

  private getStartYearHorizonDate(): string {
    return this.dates[0];
  }

  private getEndYearHorizonDate(): string {
    return this.dates[this.dates.length - 1];
  }

  private updateHorizon(event: GanttItem) {
    if (event.leftChange !== 0 || event.widthChange !== 0) {
      const validEndLimit = parseInt(this.config.validEndLimit) + 1;
      if (
        event.startLimit < this.config.startDate ||
        (event.endLimit > this.config.endDate &&
          parseInt(event.endLimit) < validEndLimit)
      ) {
        this._config.next({
          ...this.config,
          startDate: this.updateStartDate(
            this.config.startDate,
            event.leftChange,
          ),
          endDate: event.endLimit,
        });

        this.initializeViewMode();
        this.computeGanttWidth();
      }

      const newLeftHorizon =
        Coerce.toZero(this.config.startDate) - Coerce.toZero(event.leftChange);
      const newRightHorizon =
        Coerce.toZero(this.config.endDate) - Coerce.toZero(event.widthChange);
      const currentStartDate = Coerce.toZero(this.config.startDate);
      const currentEndDate = Coerce.toZero(this.config.endDate);
      this.horizonChanged.emit({
        left: Math.min(currentStartDate, newLeftHorizon + 1),
        right: Math.max(currentEndDate, newRightHorizon - 1),
      });
    }
  }

  private updateStartDate(current: string, leftChange: number): string {
    return (Coerce.toZero(current) - leftChange).toString();
  }

  private ganttItemEventHasChanges(event: GanttItem) {
    return !!event?.leftChange || !!event?.widthChange;
  }

  onScroll(e) {
    if (e.target.id === 'ganttChartScroll') {
      this.scrollLeft = e.target.scrollLeft;
    }
  }

  onNameClick(selection: SelectionEventData, item: GanttItem) {
    this.multiselectionService.select(selection);

    this.nameClick.emit({
      item,
      isDefault: selection.mode === ClickMode.DEFAULT,
    });
  }

  onLockClick(item: GanttItem) {
    this.lockClick.emit(item);
  }

  onOpenLockClick(item: GanttItem) {
    this.openLockClick.emit(item);
  }

  onUpdateLimits(event: GanttItem) {
    this.dialog
      .open(GanttDialogComponent, {
        width: '30%',
        data: {
          inputStartLimit: this.config.startLimit || this.config.startDate,
          inputEndLimit: this.config.endLimit || this.config.endDate,
          name: event.name,
          label: this.dialogInputLabel,
          defaultStart: this.config.defaultStartLimit,
          defaultEnd: this.config.defaultEndLimit,
          currentStart: event.startLimit,
          currentEnd: event.endLimit,
          validStartLimit: this.config.validStartLimit,
          validEndLimit: this.config.validEndLimit,
        },
      })
      .afterClosed()
      .subscribe((data) => {
        const { updateVariable, updateFn } = this.config;
        const earliestDate = data?.earliestDate;
        const latestDate = data?.latestDate;

        if (earliestDate && latestDate) {
          this.config.startLimit =
            this.getFullYear(this.config.startLimit) <
            this.getFullYear(earliestDate)
              ? this.config.startLimit
              : this.getYear(earliestDate);
          this.config.endLimit =
            this.getFullYear(this.config.endLimit) <
            this.getFullYear(latestDate)
              ? this.getYear(latestDate)
              : this.config.endLimit;
          const startLimit = this.getYear(earliestDate);
          const endLimit = this.getYear(latestDate);
          const itemChanged = this.updateNewChartItems(
            event.id,
            {
              startLimit: startLimit,
              endLimit: endLimit,
              isDraggable: this.isDraggable(
                startLimit,
                endLimit,
                this.getStartYearHorizonDate(),
                this.getEndYearHorizonDate(),
              ),
              ...(!!updateVariable && {
                [updateVariable]: updateFn?.(
                  event[updateVariable],
                  earliestDate,
                  latestDate,
                ),
              }),
            },
            true,
          );
          this.barChanged.emit(itemChanged);
          this.initializeChartItems();
        }
      });
  }

  deleteItem(item: GanttItem) {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: `Delete ${this.config.taskName} ${item.name}`,
          message: 'Are you sure?',
          confirm: 'Generic.labels.delete',
          close: 'Generic.labels.cancel',
          disableClose: false,
          width: '250px',
        },
      })
      .afterClosed()
      .subscribe((resp) => {
        /* istanbul ignore else */
        if (resp) {
          const deletedItem = this.updateNewChartItems(
            item.id,
            {
              isShown: false,
              isToBeDeleted: true,
            },
            true,
          );
          this.barChanged.emit(deletedItem);
        }
      });
  }

  private updateMultiselectionAllItems() {
    let chartItemsIDs = this.chartItems$.value
      .filter((item) => item.isShown || this.isResultsOnlyItem(item))
      .map((item) => item.id);
    this.multiselectionService.loadAllItems(chartItemsIDs);
  }

  /**
   * Initialize the values to navigate the scroll in the gantt chart.
   * @param event mouse event to based the initial values.
   */
  onMouseDown(event) {
    /* istanbul ignore else */
    if (event.target.classList.contains('mat-list-item-content')) {
      this.yStart = event.clientY + this.scrollTop;
      this.xStart = event.clientX + this.scrollLeft;
      this.yMax =
        event.target.scrollHeight * this.chartShownItems$.value.length -
        this.scrollTop;
      this.xMax = event.target.scrollWidth - this.scrollLeft;
    }
  }

  /**
   * Limit the value based on the min and max given
   * @param value value to limit
   * @param min minimum limit
   * @param max maximum limit
   * @returns limited value
   */
  limitValue(value, min = 0, max = 0) {
    return Math.min(Math.max(value, min), max);
  }

  private updateNewChartItems(
    id: string,
    updates: Partial<GanttItem>,
    updateList = false,
  ) {
    let itemChanged: GanttItem;
    const newItems = this.formItems.value.map((item: GanttItem) => {
      if (item.id === id) {
        itemChanged = {
          ...item,
          ...updates,
        };
        return itemChanged;
      }
      return item;
    });

    if (updateList) {
      this.chartItems$.next(newItems);
    }
    this.formItems.patchValue(newItems, { emitEvent: false });

    this.updateGanttLimits(updates);

    return itemChanged;
  }

  private updateGanttLimits(updates) {
    const { viewMode, startDate } = this.config;
    const { startLimit, endLimit } = updates;

    if (this.ganttWidthLimit && this.ganttLeftLimit) {
      let updatedGanttWidthLimit =
        getDateDiff(endLimit, startLimit, viewMode) * this.columnWidth +
        this.columnWidth;
      let updatedGanttLeftLimit =
        getDateDiff(startLimit, startDate, viewMode) * this.columnWidth + 4;

      if (
        updatedGanttWidthLimit + updatedGanttLeftLimit >=
        this.ganttWidthLimit + this.ganttLeftLimit
      ) {
        this.config.endLimit = endLimit;
      }

      if (this.ganttLeftLimit >= updatedGanttLeftLimit) {
        this.config.startLimit = startLimit;
      }

      this.initializeLimits();
    }
  }

  private getYear(_date: string) {
    return _date && new Date(_date).getFullYear().toString();
  }

  private getFullYear(_date: string): Number {
    return _date && new Date(_date).getFullYear();
  }

  isAllSelected() {
    const numSelected = this.chartItems$.value.filter(
      (item) => item.isFiltered && this.selection.selected.includes(item.id),
    ).length;
    const numRows = this.chartShownItems$.value?.length;
    return numSelected == numRows;
  }

  masterToggle() {
    const ids = this.chartShownItems$.value.map((item) => item.id);
    this.isAllSelected()
      ? this.selection.deselect(...ids)
      : this.selection.select(...ids);
  }

  itemById(index, item: GanttItem) {
    return item?.id;
  }

  isActionDisabled(ganttItem: GanttItem, action: GanttItemAction): boolean {
    return !!action.disabled || this.isDisabledByType(ganttItem, action);
  }

  private isDisabledByType(
    ganttItem: GanttItem,
    action: GanttItemAction,
  ): boolean {
    let disabledLabels = Coerce.toArray(
      this.disabledActionsByTypeMap[ganttItem.type],
    );
    return disabledLabels.some((label) => label === action.label);
  }

  showOutOfRange(): boolean {
    return this.incompatibleDateRange && !!!this._isSelectionView.value;
  }
}

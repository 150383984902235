import { toTitleCase } from 'ssotool-core/utils/string.util';

import { Color, ScaleType } from '@swimlane/ngx-charts';

import { StatusMap } from './app.model';

export const PROJECT_NAME = 'RoadToNetZero';
export const ENGIE_PRIMARY_COLOR = '#009DE9';
export const REDIRECT_URL_KEY = 'RedirectUrl';
export const COMPUTATION_HOOK = 'computation_hook';
export const CAMPAIGN_IMPORT = 'campaign_import';
export const CAMPAIGN_IMPORT_LOG_DETAILS = 'campaign_import.log_details';
export const CAMPAIGN_EXPORT = 'campaign_export';
export const CLIENT_IMPORT = 'client_import';
export const CLIENT_IMPORT_LOG_DETAILS = 'client_import.log_details';
export const UPDATE_AFFECTED_ROADMAPS = 'campaign.update_affected_roadmaps';
export const MULTIPLE_COMPUTATION_HOOK = 'multiple_computation_hook';
export const COMPARE_HOOK = 'roadmap_compare';
export const BASELINE_INDICATOR_COMPUTATION_HOOK = 'baseline_indicator_hook';
export const RELEASE_NOTES_SUBJECT = 'release_notes';
export const RESULT_EXPORT_TRIGGER = 'roadmap_result_export';
export const CLIENT_CASCADE_UPDATE = 'client_cascade_update';
export const CLIENT_DUPLICATE_UPDATE = 'client_duplicate';
export const SANDBOX_CREATED_SUBJECT = 'sandbox_created';
export const REGENERATE_XLS = 'regenerate_xls';
export const PROFILE_MODIFY = 'profile_modify';
export const ROADMAP_IMPORT = 'roadmap_import';
export const ROADMAP_IMPORT_LOG_DETAILS = 'roadmap_import.log_details';
export const ROADMAP_CASCADE_UPDATE = 'roadmap_cascade_update';
export const ROADMAP_VISUALIZATION_NOTIF = 'roadmapvisualization';
export const INPUT_CONVERTER = 'client.input_converter';
export const TDB_INVOKED = 'tdb_invoked';
export const TDB_INVOKING = 'tdb_invoking';
export const HAS_COMMODITY_PRICES = 'has_commodity_prices';
export const WATERFALL_THRESHOLD_DISPLAY = 10;
export const PIE_THRESHOLD_DISPLAY = 10;
export const STACKED_BAR_THRESHOLD_DISPLAY = 10;
export const OTHER_LABEL = 'Other';
export const SYSTEM_USER_IDS = ['00000000-0000-0000-0000-000000000000'];
export const INDICATOR_LINE_COLOR = 'rgba(169, 169, 169, 0.5)';
export const SYSTEM_USER_DETAILS = {
  firstName: 'SYSTEM',
  lastName: '',
  name: 'SYSTEM',
};
export const DEFAULT_START_YEAR = 2010;
export const DEFAULT_END_YEAR = 2050;
export const INITIAL_DATE = '01/01/2021 12:00:00 AM';
export const FORM_OPTION_DIVIDER_VALUE = 'divider';
export const NAN_VALUE = 'NaN';
export const ELECTRICITY_FLUID = 'Electricity';

export enum CAMPAIGN_REFERENCES_KEY {
  CONVERTER = 'converter',
  ENERGY_EFFICIENCY = 'energyEfficiency',
  FUEL_SUBSTITUTION = 'fuelSubstitution',
  LOCALIZATION = 'localization',
  MARKET = 'market',
  RENEWABLE_PRODUCTION = 'renewableProduction',
  STORAGE = 'storage',
}

export const REFERENCES: Record<CAMPAIGN_REFERENCES_KEY, string> = {
  [CAMPAIGN_REFERENCES_KEY.CONVERTER]: 'Converter',
  [CAMPAIGN_REFERENCES_KEY.ENERGY_EFFICIENCY]: 'Energy Efficiency',
  [CAMPAIGN_REFERENCES_KEY.FUEL_SUBSTITUTION]: 'Fuel Substitution',
  [CAMPAIGN_REFERENCES_KEY.LOCALIZATION]: 'Localization',
  [CAMPAIGN_REFERENCES_KEY.MARKET]: 'Market',
  [CAMPAIGN_REFERENCES_KEY.RENEWABLE_PRODUCTION]: 'Renewable Production',
  [CAMPAIGN_REFERENCES_KEY.STORAGE]: 'Storage',
};

export const LIMIT_PARAMETER: Record<CAMPAIGN_REFERENCES_KEY, string> = {
  [CAMPAIGN_REFERENCES_KEY.ENERGY_EFFICIENCY]: 'savings',
  [CAMPAIGN_REFERENCES_KEY.CONVERTER]: 'maxCapacity',
  [CAMPAIGN_REFERENCES_KEY.RENEWABLE_PRODUCTION]: 'maxCapacity',
  [CAMPAIGN_REFERENCES_KEY.STORAGE]: 'maxCapacity',
  [CAMPAIGN_REFERENCES_KEY.MARKET]: 'maxVolume',
  [CAMPAIGN_REFERENCES_KEY.LOCALIZATION]: '',
  [CAMPAIGN_REFERENCES_KEY.FUEL_SUBSTITUTION]: '',
};

export const ENTITY_TYPE_IDS = {
  geography: 'geoId',
  sector: 'sectorId',
  company: 'companyEntityId',
  process: 'processId',
  fluid: 'fluidId',
  quantity: 'quantityId',
  converterId: 'converterId',
};

export const ENGIE_DEFAULT_SCHEME: Color = {
  name: 'engieColors',
  selectable: true,
  group: ScaleType.Ordinal,
  domain: [
    '#002F4D',
    '#005288',
    '#0195F5',
    '#784300',
    '#D57700',
    '#FF8E00',
    '#004846',
    '#007873',
    '#2C9995',
    '#52330A',
    '#785222',
    '#8F6C3E',
    '#092335',
    '#2B485C',
    '#637D8F',
    '#356A00',
    '#6AAF23',
    '#ADEF73',
    '#670024',
    '#AA2252',
    '#DF7097',
    '#9A7B00',
    '#FACA08',
    '#FFE168',
    '#1A0A6A',
    '#331AAD',
    '#6F5EC3',
    '#7F0020',
    '#CC0033',
    '#EB4F76',
  ],
};

export function generateShadedScheme(
  numberOfElements: number,
  scheme: {
    name: string;
    selectable: boolean;
    group: string;
    domain: string[];
  } = ENGIE_DEFAULT_SCHEME,
  random: boolean = true,
) {
  const SHADE_STEP = '303030';
  // Lambda function to simply add 2 hex color codes
  const addTwoColors = (first, second) => {
    let hexStr = (parseInt(first, 16) + parseInt(second, 16)).toString(16);
    hexStr = '#' + (hexStr.length === 6 ? hexStr : 'F' + hexStr.substr(2));
    return hexStr.toUpperCase();
  };
  // Shuffle color pallette
  const shuffleArray = (array: string[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Actual logic for generating shades of the RESULTS_DEFAULT_SCHEME
  const shadeIterations = Math.ceil(
    numberOfElements / Object.keys(scheme.domain || {}).length,
  );
  // Generate an x number of array of different shades of the MainColor
  let currentArr = Object.values(scheme.domain);
  let shadedSchemeDomain = [...currentArr];
  for (let ctr = 1; ctr < shadeIterations; ctr++) {
    // Combine all arrays
    currentArr = currentArr.map((color: string) =>
      addTwoColors(color.substr(1), SHADE_STEP),
    );
    shadedSchemeDomain = shadedSchemeDomain.concat(currentArr);
  }

  // Shuffle color so will have different scheme.
  /* istanbul ignore else */
  if (random) {
    shadedSchemeDomain = shuffleArray(shadedSchemeDomain);
  }

  // Slice the overflown elements and return
  return {
    ...scheme,
    domain:
      shadeIterations === 1
        ? shadedSchemeDomain
        : shadedSchemeDomain.slice(0, numberOfElements),
  };
}

export const IMPORT_STATUS = {
  PROCESSING: 'processing',
  NEW: 'new',
  SUCCESS: 'success',
  ERROR: 'error',
  REQUESTED: 'requested',
  READY: 'ready',
  TDB_INVOKING: 'tdb_invoking',
};

export const IMPORT_STATUS_ICON_MAP: StatusMap = {
  new: {
    class: 'sso-status-pending',
    icon: 'description',
    label: toTitleCase(IMPORT_STATUS.NEW),
  },
  ready: {
    class: 'sso-status-pending',
    icon: 'schedule',
    label: toTitleCase(IMPORT_STATUS.READY),
  },
  processing: {
    class: 'sso-status-pending',
    icon: 'hourglass_bottom',
    label: toTitleCase(IMPORT_STATUS.PROCESSING),
  },
  error: {
    class: 'sso-status-failed',
    icon: 'rule',
    label: toTitleCase(IMPORT_STATUS.ERROR),
  },
  success: {
    class: 'sso-status-success',
    icon: 'done_all',
    label: toTitleCase(IMPORT_STATUS.SUCCESS),
  },
  requested: {
    class: 'sso-status-pending',
    icon: 'hourglass_empty',
    label: toTitleCase(IMPORT_STATUS.REQUESTED),
  },
  tdb_invoking: {
    class: 'sso-status-pending',
    icon: 'hourglass_bottom',
    label: toTitleCase(IMPORT_STATUS.PROCESSING),
  },
};

export const ALPHA_REQUEST = {
  CREATED: 'created',
  READY: 'ready',
  RECEIVED: 'received',
  PROCESSING: 'processing',
  FINISHED: 'finished',
  ERROR: 'error',
  COMPLETING: 'completing',
  COMPLETION_ERROR: 'completionError',
};

export const ALPHA_REQUEST_ICON_MAP: StatusMap = {
  [ALPHA_REQUEST.CREATED]: {
    class: 'exec-draft',
    icon: 'description',
    label: 'CREATED',
  },
  [ALPHA_REQUEST.READY]: {
    class: 'exec-processing',
    icon: 'schedule',
    label: 'READY',
  },
  [ALPHA_REQUEST.RECEIVED]: {
    class: 'exec-processing',
    icon: 'file_download',
    label: 'RECEIVED',
  },
  [ALPHA_REQUEST.PROCESSING]: {
    class: 'exec-processing',
    icon: 'hourglass_bottom',
    label: 'PROCESSING',
  },
  [ALPHA_REQUEST.ERROR]: {
    class: 'exec-failed',
    icon: 'close',
    label: 'ERROR',
  },
  [ALPHA_REQUEST.FINISHED]: {
    class: 'exec-success',
    icon: 'check',
    label: 'COMPLETED',
  },
  [ALPHA_REQUEST.COMPLETING]: {
    class: 'exec-processing',
    icon: 'loop',
    label: 'COMPLETING',
  },
  [ALPHA_REQUEST.COMPLETION_ERROR]: {
    class: 'exec-failed',
    icon: 'sync_problem',
    label: 'COMPLETION ERROR',
  },
};

export const PERCENT_PIN_MARKER = {
  Negative: {
    94.435: 'marker_18',
    88.88: 'marker_17',
    83.325: 'marker_16',
    77.77: 'marker_15',
    72.215: 'marker_14',
    66.66: 'marker_13',
    61.105: 'marker_12',
    55.55: 'marker_11',
    50: 'marker_10',
    44.44: 'marker_9',
    38.885: 'marker_8',
    33.33: 'marker_7',
    27.775: 'marker_6',
    22.22: 'marker_5',
    16.665: 'marker_4',
    11.11: 'marker_3',
    5.555: 'marker_2',
    0: 'marker_1',
  },
  Positive: {
    94.435: 'marker_1',
    88.88: 'marker_2',
    83.325: 'marker_3',
    77.77: 'marker_4',
    72.215: 'marker_5',
    66.66: 'marker_6',
    61.105: 'marker_7',
    55.55: 'marker_8',
    50: 'marker_9',
    44.44: 'marker_10',
    38.885: 'marker_11',
    33.33: 'marker_12',
    27.775: 'marker_13',
    22.22: 'marker_14',
    16.665: 'marker_15',
    11.11: 'marker_16',
    5.555: 'marker_17',
    0: 'marker_18',
  },
};

export enum Flag {
  POSITIVE = 'Positive',
  NEGATIVE = 'Negative',
}

export enum NotificationType {
  SILENT = 'silent',
  SYSTEM = 'system',
}

export const KPI_FLAG = {
  // economics
  totalInvestmentCost: 'Negative',
  overallIrr: 'Negative',
  totalOperationalCost: 'Negative',
  npv: 'Negative',
  overallPaybackTime: 'Negative',
  mac: 'Negative',
  // environmental
  totalCo2SavingsEmission2: 'Positive',
  totalCo2SavingsEmission: 'Positive',
  targetEmissionsReached: 'Negative',
  totalCo2Emissions: 'Negative',
  totalNOxEmissions: 'Negative',
  totalCH4Emissions: 'Negative',
  co2Intensity: 'Negative', // TODO: Verify with SSO-2861
  // sustainability
  totalEnergyConsumption: 'Negative',
  totalEnergyProduction: 'Positive',
  renewableEnergyRate: 'Positive',
  totalWasteGeneration: 'Positive',
  totalWaterConsumption: 'Positive',
  rawMaterialsConsumption: 'Positive',
  energyIntensity: 'Negative',
};

export const CORE_KPI_NAMES = {
  // economics
  total_investment_costs: 'totalInvestmentCost',
  total_operational_costs: 'totalOperationalCost',
  npv: 'npv',
  mac: 'mac',
  overall_irr: 'overallIrr',
  overall_payback_time: 'overallPaybackTime',
  // environmental
  total_co2_emission_savings: 'totalCo2SavingsEmission',
  total_co2_emissions: 'totalCo2Emissions',
  target_reached_in: '',
  co2_intensity: 'co2Intensity', // TODO: Verify after SSO-2861
  energy_intensity: 'energyIntensity',
  // sustainability
  total_energy_consumption: 'totalEnergyConsumption',
  total_energy_production: 'totalEnergyProduction',
  renewable_energy_rate: 'renewableEnergyRate',
};

const green = { class: 'percentage-green', icon: 'north' };
const gray = { class: 'percentage-gray', icon: '' };
const red = { class: 'percentage-red', icon: 'south' };

export const KPI_PERCENTAGE_DEFINITIONS = {
  Negative: {
    '-1': { ...red, icon: 'north' },
    0: gray,
    1: { ...green, icon: 'south' },
  },
  Positive: {
    '-1': green,
    0: gray,
    1: red,
  },
};

export const WIDGET_REFERENCES = {
  economics: {
    container: 'sso-widget-container-yellow',
    icon: {
      totalInvestmentCost: 'sso-icon-yellow nj-icon-document-money',
      overallIrr: 'sso-icon-yellow nj-icon-piggybank',
      mac: 'sso-icon-yellow nj-icon-gauge',
      macPortfolio: 'sso-icon-yellow nj-icon-gauge',
      totalOperationalCost: 'sso-icon-yellow nj-icon-hand-tool',
      npv: 'sso-icon-yellow nj-icon-card-money',
      overallPaybackTime: 'sso-icon-yellow nj-icon-document-money',
    },
    label: {
      totalInvestmentCost: 'Total investment costs',
      overallIrr: 'Overall IRR',
      mac: 'MAC',
      totalOperationalCost: 'Total operational costs',
      npv: 'Total discounted cost',
      overallPaybackTime: 'Overall Payback time',
      macPortfolio: 'MAC at portfolio level',
    },
  },
  environmental: {
    container: 'sso-widget-container-green',
    icon: {
      totalCo2SavingsEmission: 'sso-icon-green nj-icon-document-search',
      totalCo2SavingsEmission2: 'sso-icon-green nj-icon-document-search',
      targetEmissionsReached: 'sso-icon-green nj-icon-diagram',
      totalCo2Emissions: 'sso-icon-green nj-icon-gauge',
      totalNOxEmissions: 'sso-icon-green nj-icon-house-energy',
      totalCH4Emissions: 'sso-icon-green nj-icon-smoke',
    },
    label: {
      totalCo2SavingsEmission: 'Total CO2 savings emission',
      totalCo2SavingsEmission2: 'Total CO2 savings',
      targetEmissionsReached: 'Target emissions reached in [year]',
      totalCo2Emissions: 'Total CO2 emissions',
      totalNOxEmissions: 'Total NOx emissions',
      totalCH4Emissions: 'Total CH4 emissions',
    },
  },
  sustainability: {
    container: 'sso-widget-container-blue',
    icon: {
      totalEnergyConsumption: 'sso-icon-blue nj-icon-twenty-four',
      totalWasteGeneration: 'sso-icon-blue nj-icon-recycling',
      totalWaterConsumption: 'sso-icon-blue nj-icon-water',
      rawMaterialsConsumption: 'sso-icon-blue nj-icon-recycling',
    },
    label: {
      totalEnergyConsumption:
        'Total energy consumption + Renewable energy rate',
      totalWasteGeneration:
        'Total Waste generation + recycled & recovered rate',
      totalWaterConsumption:
        'Total water consumption + Water recycled and re-used rate',
      rawMaterialsConsumption:
        '(Raw) Materials consumption + Reusable / Recycled material rate',
      renewableEnergyRate: 'Renewable energy rate',
    },
  },
};

export const WORLDMAP_SCALE_STEP_COUNT = 10;
// To generate you can use this: https://colordesigner.io/gradient-generator
// or this: https://codesandbox.io/s/javascript-color-gradient-forked-85k0z
export const WORLDMAP_PIN_COLOR = [
  '#fae600',
  '#f5cc00',
  '#f0b300',
  '#eb9900',
  '#e68000',
  '#e06600',
  '#db4d00',
  '#d63300',
  '#d11a00',
  '#cc0000',
];

export const CAMPAIGN_COLORS = {
  'Energy Efficiency': '#009934',
  'Fuel Substitution': '#cc0033',
  Converter: '#0D74C0',
  'Renewable Production': '#D37B2D',
  Storage: '#852379',
  Market: '#005288',
  Localization: '#1f106d',
};

export const CAMPAIGN_ASSET_COLORS = {
  ...CAMPAIGN_COLORS,
};

export const EXECUTION_STATUS = {
  NOT_READY: 'not_ready',
  PREPARING: 'preparing',
  READY: 'ready',
  RUNNING: 'running',
  RETRYING: 'retrying',
  QUEUED: 'queued',
  POST_PROCESSING: 'post_processing',
  SUCCESS: 'succeeded',
  FAILED: 'failed',
  CANCELLED: 'cancelled',
};

export const FE_EXECUTION_STATUS = {
  DRAFT: 'draft',
  NEW: 'new',
  PREPARING: 'preparing',
  PROCESSING: 'processing',
  RETRYING: 'retrying',
  POST_PROCESSING: 'post_processing',
  SUCCESS: 'success',
  FAILED: 'failed',
  CANCELLED: 'cancelled',
  OUTDATED: 'outdated',
};

export const EXECUTION_STATUS_ICON_MAP: StatusMap = {
  [FE_EXECUTION_STATUS.DRAFT]: {
    class: 'exec-draft',
    icon: 'description',
    label: 'DRAFT',
    message: 'Draft',
  },
  [FE_EXECUTION_STATUS.NEW]: {
    class: 'exec-processing',
    icon: 'schedule',
    label: 'NEW',
    message: 'Initializing Data',
  },
  [FE_EXECUTION_STATUS.PREPARING]: {
    class: 'exec-processing',
    icon: 'hourglass_empty',
    label: 'PREPARING',
    message: 'Preparing Profiles',
  },
  [FE_EXECUTION_STATUS.PROCESSING]: {
    class: 'exec-processing',
    icon: 'hourglass_top',
    label: 'PROCESSING',
    message: 'Processing Computations',
  },
  [FE_EXECUTION_STATUS.RETRYING]: {
    class: 'exec-retrying',
    icon: 'cycle',
    label: 'RETRYING',
    message: 'Failed Processing. Retrying...',
  },
  [FE_EXECUTION_STATUS.POST_PROCESSING]: {
    class: 'exec-post-processing',
    icon: 'equalizer',
    label: 'POST PROCESSING',
    message: 'Generating Results',
  },
  [FE_EXECUTION_STATUS.SUCCESS]: {
    class: 'exec-success',
    icon: 'check_circle',
    label: 'SUCCESS',
    message: 'Success',
  },
  [FE_EXECUTION_STATUS.FAILED]: {
    class: 'exec-failed',
    icon: 'cancel',
    label: 'FAILED',
    message: 'Failed',
  },
  [FE_EXECUTION_STATUS.CANCELLED]: {
    class: 'exec-cancelled',
    icon: 'remove',
    label: 'CANCELLED',
    message: 'Cancelled',
  },
  [FE_EXECUTION_STATUS.OUTDATED]: {
    class: 'exec-outdated',
    icon: 'restore',
    label: 'OUTDATED',
    message: 'Outdated Roadmap. Please recompute.',
  },
};

export const ELLIPSE_CURVE_COLORS = [
  '#009DE9',
  '#1F106D',
  '#6ED2B1',
  '#910F7D',
  '#FBDB3C',
  '#007873',
  '#E74A50',
  '#E62B87',
  '#69AF23',
  '#F78A31',
];

export const ENGIE_CURVE_COLORS = [
  '#1ED284',
  '#001FA0',
  '#832765',
  '#FD5064',
  '#FFA101',
];

export const OTHER_COLORS = [
  '#00ffff',
  '#f0ffff',
  '#f5f5dc',
  '#000000',
  '#0000ff',
  '#a52a2a',
  '#00ffff',
  '#00008b',
  '#008b8b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#9932cc',
  '#8b0000',
  '#e9967a',
  '#9400d3',
  '#ff00ff',
  '#ffd700',
  '#008000',
  '#4b0082',
  '#f0e68c',
  '#add8e6',
  '#e0ffff',
  '#90ee90',
  '#d3d3d3',
  '#ffb6c1',
  '#ffffe0',
  '#00ff00',
  '#ff00ff',
  '#800000',
  '#000080',
  '#808000',
  '#ffa500',
  '#ffc0cb',
  '#800080',
  '#800080',
  '#ff0000',
  '#c0c0c0',
  '#ffffff',
  '#ffff00',
];

export const ROADMAP_CUSTOM_ICON_PATH = '../assets/roadmap';

export const ROADMAP_CUSTOM_ICONS_FN = {
  campaigns_icon: 'campaigns_icon.svg',
  detailedview_icon: 'detailedview_icon.svg',
  targets_icon: 'targets_icon.svg',
  timeline_icon: 'timeline_icon.svg',
  worldmap_icon: 'worldmap_icon.svg',
  portfolio_icon: 'portfolio_icon.svg',
  operations: 'operations.svg',
  financials: 'financials.svg',
  sustainability: 'sustainability.svg',
};

export const GANTT_ICON_PATH = '../assets/images/gantt';
export const TABLE_VIEW_ICON_PATH = '../assets/images/table_view';

export const GANTT_CUSTOM_ICONS_FN = {
  partial_lock_icon: 'partial_lock.svg',
};

export const TABLE_VIEW_CUSTOM_ICONS_FN = {
  ink_eraser_icon: 'ink_eraser.svg',
};

export const MOCK_CAMPAIGN_MAPPER = {
  '0000-0000-0001': 'Market',
  '0000-0000-0002': 'Final Demand',
};

export const CO2_EMISSIONS_STR = 'Total CO2';
export const CO2_SCOPE1 = 'CO2 Scope 1';
export const CO2_SCOPE2 = 'CO2 Scope 2';
export const INDICATOR_OPTIONS = {
  // capex: 'CAPEX',
  co2_emissions: CO2_EMISSIONS_STR,
  co2_scope1: CO2_SCOPE1,
  co2_scope2: CO2_SCOPE2,
};

export const GRANULARITY_TICKFORMAT = {
  hourly: '%b %d %H:00',
  daily: '%b %d',
  monthly: '%b',
  yearly: '%Y',
};

export enum Granularity {
  HOURLY = 'hourly',
  DAILY = 'daily',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export const GRANULARITY_COUNT = {
  [Granularity.HOURLY]: 8760,
  [Granularity.DAILY]: 365,
  [Granularity.MONTHLY]: 12,
  [Granularity.YEARLY]: 1,
};

function generateGranularityDateArray(
  granularity: string,
  initialDate = INITIAL_DATE,
): string[] {
  const startDate = new Date(initialDate);
  const res = [];

  for (let i = 0; i < GRANULARITY_COUNT[granularity]; i++) {
    res.push(startDate.toUTCString());
    switch (granularity) {
      case Granularity.HOURLY:
        startDate.setTime(startDate.getTime() + 1 * 60 * 60 * 1000);
        break;
      case Granularity.DAILY:
        startDate.setDate(startDate.getDate() + 1);
        break;
      case Granularity.MONTHLY:
        startDate.setMonth(startDate.getMonth() + 1);
        break;
      default:
        break;
    }
  }

  return res;
}

export function generateYearlyArray(
  values: string[],
  startYear: number,
  endYear: number,
) {
  let yearDuration = startYear === endYear ? 1 : endYear - startYear + 1;
  return Array(yearDuration)
    .fill(0)
    .reduce((acc, _, index) => {
      const startDate = new Date(INITIAL_DATE);
      startDate.setFullYear(startYear + index);
      acc[startDate.toUTCString()] = values[0];
      return acc;
    }, {});
}

const HOURLY_DATE_ARRAY = generateGranularityDateArray(Granularity.HOURLY);
const DAILY_DATE_ARRAY = generateGranularityDateArray(Granularity.DAILY);
const MONTHLY_DATE_ARRAY = generateGranularityDateArray(Granularity.MONTHLY);

export const GRANULARITY_DATE_ARRAY_MAP = {
  [Granularity.HOURLY]: HOURLY_DATE_ARRAY,
  [Granularity.DAILY]: DAILY_DATE_ARRAY,
  [Granularity.MONTHLY]: MONTHLY_DATE_ARRAY,
  [Granularity.YEARLY]: [],
};

export const CAMPAIGN_ENTITY = 'campaign';
export const GEOGRAPHY_ENTITY = 'geography';

export const CAMPAIGNS_WITH_CAMPAIGN_LEVEL_CONSTRAINTS = [
  CAMPAIGN_REFERENCES_KEY.MARKET,
];

export const INPUT_CONVERTER_OUTPUT_FILENAME = 'sso_input.zip';
export const CONVERTER_BE_SUCCESS_STATUS = 'Input conversion succeeded: True';

export const enum APPLICATION_STAGES {
  DEV = 'dev',
  TEST = 'test',
  QA = 'qa',
  STAGING = 'staging',
  PRODUCTION = 'production',
  ALGO = 'algo',
}

<div>
  <!-- refactor on Angular material v15, dynamic directive for mat-stretch-tabs is already available -->
  <mat-tab-group
    *ngIf="stretchTabs; else compressedTabs"
    #tabGroup
    animationDuration="0ms"
    [color]="color"
    dynamicHeight
    mat-stretch-tabs
    [ngClass]="{
      'object-tabs': isAlignmentColumn$ | async,
      'disabled-tab': disable,
      'no-active': noActive
    }"
    [selectedIndex]="selected.value"
    (selectedTabChange)="onChangeSelectedTab($event)"
  >
    <mat-tab *ngIf="disable || noActive"></mat-tab>
    <mat-tab *ngFor="let option of selections" [disabled]="!!option?.disabled">
      <ng-template matTabLabel *ngIf="isObjectTabs$ | async; else stringLabel">
        <div
          class="custom-label"
          [fxLayout]="option.alignment"
          fxLayoutAlign="center center"
        >
          <div
            [fxLayout]="option.alignment"
            fxLayoutAlign="center center"
            fxLayoutGap="5px"
          >
            <mat-icon
              *ngIf="option.icon; else svgIcon"
              [fontSet]="option.fontSet"
              >{{ option.icon }}</mat-icon
            >
            <ng-template #svgIcon>
              <mat-icon [svgIcon]="option.svgIcon"></mat-icon>
            </ng-template>
            <span fxFlex>{{ option.label | titlecase }}</span>
          </div>
        </div>
      </ng-template>
      <ng-template matTabLabel #stringLabel>
        <div class="custom-label" fxLayout="row" fxLayoutAlign="center center">
          <span>{{ option | titlecase }}</span>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <ng-template #compressedTabs>
    <mat-tab-group
      #tabGroup
      animationDuration="0ms"
      [color]="color"
      [selectedIndex]="selected.value"
      dynamicHeight
      [ngClass]="{
        'object-tabs': isAlignmentColumn$ | async,
        'disabled-tab': disable,
        'no-active': noActive
      }"
      (selectedTabChange)="onChangeSelectedTab($event)"
    >
      <mat-tab *ngIf="disable || noActive"></mat-tab>
      <mat-tab *ngFor="let option of selections" [disabled]="disable">
        <ng-template
          matTabLabel
          *ngIf="isObjectTabs$ | async; else stringLabel"
        >
          <div
            class="custom-label"
            (click)="onChangeSelectedTab(option)"
            [fxLayout]="option.alignment"
            fxLayoutAlign="center center"
          >
            <div
              [fxLayout]="option.alignment"
              fxLayoutAlign="center center"
              fxLayoutGap="5px"
            >
              <mat-icon
                *ngIf="option.icon; else svgIcon"
                [fontSet]="option.fontSet"
                >{{ option.icon }}</mat-icon
              >
              <ng-template #svgIcon>
                <mat-icon
                  *ngIf="option.svgIcon"
                  [svgIcon]="option.svgIcon"
                ></mat-icon>
              </ng-template>
              <span fxFlex>{{ option.label | titlecase }}</span>
            </div>
          </div>
        </ng-template>
        <ng-template matTabLabel #stringLabel>
          <div
            class="custom-label"
            fxLayout="row"
            fxLayoutAlign="center center"
            (click)="onChangeSelectedTab(option)"
          >
            <span>{{ option | titlecase }}</span>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</div>

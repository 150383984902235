import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { ReleaseNotesBannerComponent } from './release-notes-banner.component';

@NgModule({
  declarations: [ReleaseNotesBannerComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    TranslateModule,
    RouterModule,
    BrowserAnimationsModule,
  ],
  exports: [ReleaseNotesBannerComponent],
})
export class ReleaseNotesBannerModule {}

import { StatusMap } from 'ssotool-app/app.model';
import { arrayToString } from 'ssotool-core/utils';
import { rowAnimation } from 'ssotool-shared/helpers/animations';
import { SSOToolRoutePathService } from 'ssotool-shared/services/route-path.service';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { PermissionChecker } from '../../helpers';
import { ManagedDataService } from './managed-data.service';
import { ManagedTableComponent } from './managed-table.component';
import { PermissionTableOptions } from './permission-table.options';

@Component({
  selector: 'sso-permission-table',
  templateUrl: './permission-table.component.html',
  styleUrls: ['./permission-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [rowAnimation],
  providers: [ManagedDataService],
})
export class PermissionTableComponent<T> extends ManagedTableComponent<T> {
  @Input() addButton: any;
  @Input() addButtonDisabled = false;
  @Input() options: PermissionTableOptions;
  @Input() statusMap: StatusMap;
  @Input() loading: boolean;
  @Input() message: string;
  @Input() customSort: (data: any, sort: MatSort) => any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  private defaultSort = this.dataSource.sortData;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    @Self() public managedData: ManagedDataService<T>,
    private permissionChecker: PermissionChecker,
    private routPathService: SSOToolRoutePathService,
  ) {
    super(ngControl, changeDetector, managedData);
    this.dataSource.paginator = this.paginator;
  }

  /**
   * Override
   */
  defineColumns() {
    return this.columnsDef || {};
  }

  /**
   * Override
   */
  onAdd() {
    this.add.emit();
  }

  /**
   * Override
   */
  onEdit(data: T) {
    this.edit.emit(data);
  }

  /**
   * Override
   */
  onDelete(data: T) {
    this.delete.emit(data);
  }

  /**
   * Check for permission
   * @param data - Row data
   * @param action - string name for the action. reffer from PermissionChecker variables (e.g., READ, WRITE, etc..)
   */
  isPermitted(data: T, action: string): boolean {
    if (data && data['permissions'] && action && action !== 'SHARE') {
      return this.permissionChecker.check(
        data['permissions'],
        this.permissionChecker[action],
      );
    }
    return true;
  }

  /**
   * Get value of the keys from references.
   * @param keys is a list of string. e.g ['abc','def'].
   * @param referenceKey a key from references.
   */
  getValueFromKeyList(keys: Array<string>, referenceKey?: string): string {
    const value = [];
    if (!!keys && keys instanceof Array) {
      if (!!referenceKey) {
        keys.forEach((key) =>
          value.push(this.references[referenceKey][key] || key),
        );
      } else {
        keys.forEach((key) => value.push(this.references[key] || key));
      }
    }
    return arrayToString(value.sort(), ',');
  }

  getRoutePath(path: string, clientId?: string, id?: string) {
    // TODO: update if needed to add more params
    if (clientId && id) {
      return this.routPathService[path](clientId, id);
    }
    if (clientId && !id) {
      return this.routPathService[path](clientId);
    }
    return this.routPathService[path];
  }

  setCustomSort() {
    if (this.customSort) {
      this.dataSource.sortData = this.customSort.bind(this);
    }
  }

  /**
   * Check if matBadge should be shown or hidden
   * @param data - Row data
   * @param action - string name for the action. reffer from PermissionChecker variables (e.g., READ, WRITE, etc..)
   */
  isBadgeHidden = (data: T, action: string): boolean => {
    if (data && action && action === 'SHARE') {
      return false;
    }
    return true;
  };

  /**
   * Get the value to be used for the matBadge.
   * Since this is a shared table, we avoided calling data.sharedCount directly
   * in case other objects would not have this property
   * @param key - id or unique identifier of the row
   */
  getBadgeValue = (key: string) => {
    if (this.references && this.references.badgeValues && key) {
      const value = this.references['badgeValues'][key];
      return value === 0 ? null : value;
    }
    return null;
  };

  checkType(type, row) {
    return typeof type === 'function' ? type(row) : type;
  }
}

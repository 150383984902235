import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataImportFacadeService } from 'ssotool-app/+data-management/stores';
import { TimelineType } from 'ssotool-app/+roadmap/modules/timeline/roadmap-timeline.model';
import { Coerce, ExecStatusChecker } from 'ssotool-app/shared';
import { ClientFacadeService } from 'ssotool-client';
import { CustomValidators } from 'ssotool-core/utils';

import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

const DEFAULT_FILE_EXTS = ['xls', 'xlsx'];
@Component({
  selector: 'sso-import-form',
  templateUrl: './import-form.component.html',
  styleUrls: ['./import-form.component.scss'],
})
export class ImportFormComponent {
  @Input() showSelectTimelineType = true;
  timelineTypes: TimelineType[] = ['gantt', 'table'];
  allowedFileExtns = DEFAULT_FILE_EXTS;
  activeClient$ = this.clientFacade.selectActiveClientData$;
  clientId: string;
  templateVersion$: Observable<string> = this.activeClient$.pipe(
    map((client) => {
      this.clientId = client.clientId;
      return client.templateVersion;
    }),
  );
  uploading$ = this.importFacade.uploading$;
  importForm = this.formBuilder.group({
    file: ['', [Validators.required, CustomValidators.file(['xls', 'xlsx'])]],
    clientTimelineType: ['gantt' as TimelineType, Validators.required],
    description: '',
  });

  hasOngoingImport$: Observable<boolean> =
    this.importFacade.getDataImportEntities$.pipe(
      map((entities) => {
        return Coerce.getObjValues(entities).some((el) =>
          this.execStatus.checkImport(el.status),
        );
      }),
    );

  constructor(
    private formBuilder: FormBuilder,
    private clientFacade: ClientFacadeService,
    private importFacade: DataImportFacadeService,
    private execStatus: ExecStatusChecker,
  ) {}

  downloadTemplate() {
    if (this.clientId) {
      this.importFacade.downloadTemplate(this.clientId);
    }
  }

  onUpload() {
    if (this.importForm.valid) {
      this.importFacade.importData(this.clientId, {
        description: this.importForm.controls.description.value,
        file: this.importForm.controls.file.value?.['files']?.[0],
        clientTimelineType: this.importForm.controls.clientTimelineType
          .value as TimelineType,
      });
    }
    this.importForm.reset();
  }
}

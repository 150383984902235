import { Coerce } from './coerce.utils';

export type ErrorMessage = Readonly<{
  error: string;
  message: string;
}>;

export const createErrorObject = (
  error: Record<string, string>,
  defaultMsg: string,
): ErrorMessage => {
  const errMsg = Coerce.toString(
    Coerce.toEmptyObject<Record<string, string>>(error).error,
    defaultMsg,
  );
  return {
    error: errMsg,
    message: errMsg,
  };
};

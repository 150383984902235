import { on } from '@ngrx/store';

import {
  INITIAL_PORTFOLIO_ENTITY_STATE,
  makePortfolioEntityStateReducer,
  PortfolioEntityState,
} from '../portfolio.reducer';
import { CompareEntityActions } from './compare.actions';
import { CompareData } from './compare.model';

export const COMPARE_FEATURE_NAME = 'Compare';
export const TEMPORARY_ID = '1';

function reduceGetComparisonResultAction(
  previous: PortfolioEntityState,
  _: any,
): PortfolioEntityState {
  return { ...previous, loading: true };
}

function reduceGetComparisonResultNOKAction(
  previous: PortfolioEntityState,
  _: {},
): PortfolioEntityState {
  return { ...previous, loaded: false, loading: false, error: true };
}

function reduceDownloadComparisonResultAction(
  previous: PortfolioEntityState,
  _: any,
): PortfolioEntityState {
  return { ...previous, loading: true };
}

function reduceDownloadComparisonResultOKAction(
  previous: PortfolioEntityState,
  payload: { data: CompareData },
): PortfolioEntityState {
  const currentMap = { ...previous.dataMap };
  const portfolioModel = payload.data;
  currentMap[portfolioModel.id] = {
    ...portfolioModel,
    loaded: true,
    loading: false,
  };
  return { ...previous, dataMap: currentMap, loaded: true, loading: false };
}

function reduceDownloadComparisonResultNOKAction(
  previous: PortfolioEntityState,
  _: {},
): PortfolioEntityState {
  return { ...previous, loaded: false, loading: false, error: true };
}

function reduceUpdateLoadedProgressAction(
  previous: PortfolioEntityState,
  { id, progress },
): PortfolioEntityState {
  const currentMap = { ...previous.dataMap };
  currentMap[id] = { ...currentMap[id], progress };
  return { ...previous, dataMap: currentMap };
}

function reduceResetStoreAction(previous: any, _: any): PortfolioEntityState {
  return INITIAL_PORTFOLIO_ENTITY_STATE;
}

export function compareStateReducer(state, action) {
  return makePortfolioEntityStateReducer(
    COMPARE_FEATURE_NAME,
    on(
      CompareEntityActions.getComparisonResultAction,
      reduceGetComparisonResultAction,
    ) as ReturnType<typeof on>,
    on(
      CompareEntityActions.getComparisonResultNOKAction,
      reduceGetComparisonResultNOKAction,
    ) as ReturnType<typeof on>,
    on(
      CompareEntityActions.downloadComparisonResultAction,
      reduceDownloadComparisonResultAction,
    ) as ReturnType<typeof on>,
    on(
      CompareEntityActions.downloadComparisonResultOKAction,
      reduceDownloadComparisonResultOKAction,
    ) as ReturnType<typeof on>,
    on(
      CompareEntityActions.downloadComparisonResultNOKAction,
      reduceDownloadComparisonResultNOKAction,
    ) as ReturnType<typeof on>,
    on(
      CompareEntityActions.updateLoadedProgressAction,
      reduceUpdateLoadedProgressAction,
    ) as ReturnType<typeof on>,
    on(CompareEntityActions.resetStoreAction, reduceResetStoreAction),
  )(state, action);
}

export const CompareEntityStore = {
  featureName: COMPARE_FEATURE_NAME,
  reducer: compareStateReducer,
};

export const CompareEntityReducerFns = {
  reduceUpdateLoadedProgressAction,
  reduceGetComparisonResultAction,
  reduceGetComparisonResultNOKAction,
  reduceDownloadComparisonResultAction,
  reduceDownloadComparisonResultOKAction,
  reduceDownloadComparisonResultNOKAction,
  reduceResetStoreAction,
};

export type FlowsData = Array<Array<string>>;
export type Flows = Readonly<{
  entityId: string;
  data: FlowsData;
}>;

export enum FlowsType {
  TIMESERIES = 'timeseries',
  FLOWVOLUME = 'flowvolume',
  FLOWPRICE = 'flowprice',
}

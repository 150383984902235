import { Observable, Observer, throwError, timer, EMPTY } from 'rxjs';
import {
  catchError,
  finalize,
  mergeMap,
  retryWhen,
  shareReplay,
  takeUntil,
  timeout,
} from 'rxjs/operators';
import { LoggerService } from 'ssotool-shared/services';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { EnvConfig } from '../config';
import { ENV_CONFIG } from '../tokens';
import { exponentialBackoff } from '../utils';
import { authUtil } from '@oculus/auth/amplify';
import { MultiTranslateHttpLoader } from '../utils/multi-translate-http-loader.util';

const DEFAULT_TIMEOUT_MS = 1000000;
const DEFAULT_RETRY_TIMES = 3;
const DEFAULT_RETRY_SCALING_DURATION_MS = 1000;
const DEFAULT_RETRY_INCLUDED_STATUS_CODES = [];
const STOP_AND_END_STATUS_CODES = [403];

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    @Inject(ENV_CONFIG) environment: EnvConfig,
    private _logger: LoggerService,
    private router: Router,
  ) {
    this._timeoutMs = environment.http.timeoutMs
      ? environment.http.timeoutMs
      : DEFAULT_TIMEOUT_MS;
    this._retryScalingDuration = environment.http.retryScalingDuration
      ? environment.http.retryScalingDuration
      : DEFAULT_RETRY_SCALING_DURATION_MS;
    this._maxRetryAttempts = environment.http.maxRetryAttempts
      ? environment.http.maxRetryAttempts
      : DEFAULT_RETRY_TIMES;
    this._retryIncludedStatusCodes = environment.http
      .refreshTokenIncludedStatusCodes
      ? environment.http.refreshTokenIncludedStatusCodes
      : DEFAULT_RETRY_INCLUDED_STATUS_CODES;
  }

  private _timeoutMs = DEFAULT_TIMEOUT_MS;
  private _retryScalingDuration = DEFAULT_RETRY_SCALING_DURATION_MS;
  private _maxRetryAttempts = DEFAULT_RETRY_TIMES;
  private _retryIncludedStatusCodes = DEFAULT_RETRY_INCLUDED_STATUS_CODES;
  private _interrupt$: Observable<any> = new Observable(
    (observer: Observer<any>) => observer.complete(),
  );

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // See note on multi-translate-http-loader.util.ts
    if (request.headers.has(MultiTranslateHttpLoader.InterceptorSkipHeader)) {
      const headers = request.headers.delete(
        MultiTranslateHttpLoader.InterceptorSkipHeader,
      );
      return next.handle(request.clone({ headers }));
    }

    return next.handle(request).pipe(
      timeout(this._timeoutMs),
      catchError((error: HttpErrorResponse) => {
        if (error.status && STOP_AND_END_STATUS_CODES.includes(error.status)) {
          this.router.navigate(['/error'], {
            queryParams: { code: error.status },
          });
          return EMPTY;
        }

        if (this.isForRefresh(error)) {
          this.logError(error, request);
          return next.handle(request);
        }

        return throwError(error);
      }),
      retryWhen(this.retryStrategy()),
      takeUntil(this._interrupt$),
      shareReplay(1),
    );
  }

  private isForRefresh(error: HttpErrorResponse): boolean {
    return this.isTimeout(error) || this.isTokenExpired(error);
  }

  private isTimeout(error: HttpErrorResponse): boolean {
    return (
      error.status &&
      error.status === 504 &&
      error.error.message === 'Endpoint request timed out'
    );
  }

  private isTokenExpired(error: HttpErrorResponse): boolean {
    return (
      authUtil.isHttpErrorResponse(error) &&
      authUtil.isErrorTokenExpired(error) &&
      authUtil.isStatusCodeForRefreshToken(error.status)
    );
  }

  private logError(error: HttpErrorResponse, request: HttpRequest<any>): void {
    if (this.isTimeout(error)) {
      this._logger.info(`[504] Retrying... ${request?.body}`);
    }
  }

  retryStrategy = () => (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        // If maximum number of retries have been met or response' status code is not included we will not retry but throw error
        if (
          retryAttempt > this._maxRetryAttempts ||
          !this.isStatusCodeForRetry(error.status)
        ) {
          return throwError(error);
        }

        const retryTimeout = exponentialBackoff(
          retryAttempt - 1,
          this._retryScalingDuration,
          1,
          1000,
        );
        this._logger.debug(
          `Attempt ${retryAttempt}: retrying in ${retryTimeout / 1000}s`,
        );
        return timer(retryTimeout);
      }),
      finalize(() => this._logger.debug('Done...')),
    );
  };

  isStatusCodeForRetry(statusCode: number) {
    return this._retryIncludedStatusCodes.find(
      (statusCodeItem) => statusCodeItem === statusCode,
    );
  }
}

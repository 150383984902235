<form data-testid="form-field">
  <mat-form-field
    appearance="fill"
    floatLabel="always"
    [hideRequiredMarker]="'true'"
    fxFill
  >
    <mat-label
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="2px"
      data-testid="label"
    >
      <span>{{ label }}</span>
    </mat-label>
    <input matInput type="text" readonly />
    <mat-icon
      class="sso-clickable"
      (click)="siteSelect.click()"
      data-testid="profile-icon"
      color="primary"
      >assessment</mat-icon
    >
  </mat-form-field>
</form>
<sso-multi-select
  class="menu-mode"
  data-testid="site-selection"
  #siteSelect
  [multiple]="false"
  [options]="siteOptions | ngrxPush"
  [formControl]="siteSelectControl"
  color="primary"
  [searchLabel]="'Profiles.labels.searchSiteLabel' | translate"
  [searchIconPrefix]="false"
></sso-multi-select>

import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScoopaBase } from 'scoopabase';

import { Injectable } from '@angular/core';

import { Profile, Profiles } from './profile.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileLocalStorageService {
  private scoopaDB = new ScoopaBase('profiles');

  /**
   * Getter method for the collection of the main profile.
   */
  get mainCollection() {
    return this.scoopaDB.collection<Profiles>('main');
  }

  /**
   * Process the profile to be saved in the local storage
   * @param profile profile entity to be saved in the local storage
   */
  addProfile(profile: Profiles) {
    from(this.mainCollection.add(profile, profile.profileId).then());
  }

  addProfiles(profiles: Profiles[]) {
    profiles?.forEach((profile) =>
      this.mainCollection.add(profile, profile.profileId),
    );
  }

  addSiteProfile(siteProfileId: string, profile: Profiles) {
    from(this.mainCollection.add(profile, siteProfileId).then());
  }

  upsertProfile(siteProfileId: string, profile: Profiles) {
    from(this.mainCollection.update(profile, siteProfileId).then());
  }

  deleteProfile(siteProfileId: string) {
    from(this.mainCollection.delete(siteProfileId).then());
  }

  /**
   * Returns list of profiles for the given id.
   * @param profileId either campaign id or existing asset id.
   */
  getProfilesByIdStream = (profileId: string): Observable<Profile[]> =>
    this.mainCollection
      .document$(profileId)
      .pipe(map((profiles) => profiles?.profiles || []));

  /**
   * Clear all of the contents of the local storage
   * Purpose is to remove the persistent of local storage
   */
  clear() {
    this.mainCollection.clearAll();
  }
}

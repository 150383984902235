import { cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { ColorSchemeService } from 'ssotool-shared';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sso-macc-curve-component',
  templateUrl: './macc-curve.component.html',
  styleUrls: ['./macc-curve.component.scss'],
  providers: [ColorSchemeService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaccCurveComponent {
  _data;
  // TODO: Add model
  @Input() set data(data: any) {
    if (data) {
      const _deepData = cloneDeep(data);
      this.parseData(_deepData);
      this.generateColorScheme(_deepData);
    }
  }
  get data(): any {
    return this._data;
  }

  colorScheme$ = new BehaviorSubject<string[]>([]);

  constructor(private colorSchemeService: ColorSchemeService) {}

  parseData(data: any) {
    this._data = data.slices || [];
  }

  generateColorScheme(data) {
    const colors = this.colorSchemeService.generateAdjacentScheme(data.baseColor, data.slices.length);
    this.colorScheme$.next(colors);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { Coerce } from '../helpers';

/**
 * Pipe to get first value of an object.
 */
@Pipe({
  name: 'firstValue',
})
export class FirstValuePipe implements PipeTransform {
  /**
   * Get the first value of an object.
   * @param obj - object that has values
   * @returns value of the first entry in an object.
   */
  transform(obj: object): any {
    return obj && Coerce.getObjValues(obj)[0];
  }
}

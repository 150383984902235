import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Trajectory, TrajectoryBackend } from 'ssotool-app/+client/store';
import { SignedUrl } from 'ssotool-app/app.model';
import { generateEndpoint } from 'ssotool-app/core';
import {
  YearlyValuesMapper,
  download,
  processDownloadedData,
} from 'ssotool-app/shared';
import { ConfigService } from 'ssotool-app/shared/services/config';
import { TrajectoryRecord } from './datahub.model';

@Injectable({
  providedIn: 'root',
})
export class DatahubAPIFacadeService {
  chooseTrajectoriesToSave(
    clientId: string,
    importId: string,
    selectedIds: Trajectory['trajectoryId'][],
  ): Observable<any> {
    const { baseUrl, endpoints } = this.configService.api;
    return this.http.post(
      generateEndpoint(
        baseUrl,
        endpoints.dataImport.selectTrajectories,
        clientId,
        importId,
      ),
      {
        trajectories: selectedIds,
      },
    );
  }

  /**
   * Retrieves commodities for a specific client and import.
   * @param {string} clientId - The ID of the client
   * @param {string} importId - The ID of the import
   * @return {Observable<any>} An observable of the commodities data
   */
  getCommodities(
    clientId: string,
    importId: string,
  ): Observable<TrajectoryRecord> {
    const { baseUrl, endpoints } = this.configService.api;
    return this.http
      .get<SignedUrl>(
        generateEndpoint(
          baseUrl,
          endpoints.dataImport.getCommodities,
          clientId,
          importId,
        ),
      )
      .pipe(
        mergeMap((TDBSignedUrl: SignedUrl) => {
          return this.http
            .get(TDBSignedUrl.signedUrl, {
              reportProgress: true,
              observe: 'events',
              responseType: 'blob',
            })
            .pipe(
              download(),
              processDownloadedData<string>(),
              map((data: string) => this.mapToTDBTrajectoriesFrontend(data)),
            );
        }),
      );
  }

  private mapToTDBTrajectoriesFrontend(data: string): TrajectoryRecord {
    const trajectories = JSON.parse(data);
    return Object.entries(trajectories).reduce(
      (
        injectedTrajectories,
        [trajectoryId, trajectory]: [
          TrajectoryBackend['trajectoryId'],
          TrajectoryBackend,
        ],
      ) => {
        injectedTrajectories[trajectoryId] = {
          ...trajectory,
          granularity: 'yearly',
          values: YearlyValuesMapper.mapToFrontend(trajectory.values),
        };
        return injectedTrajectories;
      },
      {} as TrajectoryRecord,
    );
  }

  constructor(private http: HttpClient, private configService: ConfigService) {}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AreaDiagramModule } from '@oculus/components/area-diagram';

import { AreaChartComponent } from './area-chart.component';

@NgModule({
  declarations: [AreaChartComponent],
  imports: [CommonModule, AreaDiagramModule],
  exports: [AreaChartComponent],
})
export class AreaChartModule {}

import { of, pipe } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { AmbitionService } from '../services/ambition.service';
import { AmbitionActions } from './ambition.action';
import { AmbitionFacadeService } from './ambition.facade.service';
import { ToastService } from 'ssotool-app/shared/services/toast/toast.service';

@Injectable()
export class AmbitionEffects {
  getList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AmbitionActions.getList),
      mergeMap((action) =>
        this.ambitionService.getList(action.clientId).pipe(
          map((data) => {
            return AmbitionActions.getListSuccess({
              clientId: action.clientId,
              data,
              message: 'Get constraints list success!',
            });
          }),
          catchError((error) =>
            of(
              AmbitionActions.getListFailure({
                clientId: action.clientId,
                error:
                  (error.error || {}).error || 'Get constraints list error!',
                message:
                  (error.error || {}).error || 'Get constraints list error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getListSuccess$ = createEffect(
    () => this.actions$.pipe(ofType(AmbitionActions.getListSuccess)),
    { dispatch: false },
  );

  getListFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AmbitionActions.getListFailure),
        tap((action) =>
          this.snackbar.showToast(
            action.message,
            'action',
            undefined,
            of({}).pipe(
              tap(() => this.ambitionFacade.getList(action.clientId)),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );
  updateTargets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AmbitionActions.updateTargets),
      mergeMap(({ clientId, constraints }) =>
        this.ambitionService.updateTargets(clientId, constraints).pipe(
          map(() =>
            AmbitionActions.updateTargetsSuccess({
              constraints,
              message: 'Update targets success!',
            }),
          ),
          catchError((err) =>
            of(
              AmbitionActions.updateTargetsFailure({
                clientId,
                constraints,
                error: (err.error || {}).error || 'Update targets error!',
                message: (err.error || {}).error || 'Update targets error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  updateTargetsSuccess$ = createEffect(
    () => this.actions$.pipe(ofType(AmbitionActions.updateTargetsSuccess)),
    { dispatch: false },
  );

  updateTargetsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AmbitionActions.updateTargetsFailure),
        tap((action) =>
          this.snackbar.showToast(
            action.message,
            'action',
            undefined,
            of({}).pipe(
              tap(() =>
                this.ambitionFacade.updateTargets(
                  action.clientId,
                  action.constraints,
                ),
              ),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  createCustomTarget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AmbitionActions.createCustomTarget),
      mergeMap((action) =>
        this.ambitionService
          .createCustomTarget(action.clientId, action.data)
          .pipe(
            map((response) =>
              AmbitionActions.createCustomTargetSuccess({
                data: response,
                message: 'Create Target success!',
              }),
            ),
            catchError((error) =>
              of(
                AmbitionActions.createCustomTargetFailure({
                  clientId: action.clientId,
                  data: action.data,
                  error: (error.error || {}).error || 'Create Target error!',
                  message: (error.error || {}).error || 'Create Target error!',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  createCustomTargetSuccess$ = createEffect(
    () => this.actions$.pipe(ofType(AmbitionActions.createCustomTargetSuccess)),
    { dispatch: false },
  );

  createCustomTargetFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AmbitionActions.createCustomTargetFailure),
        tap((action) =>
          this.snackbar.showToast(
            action.message,
            'action',
            undefined,
            of({}).pipe(
              tap(() =>
                this.ambitionFacade.createCustomTarget(
                  action.clientId,
                  action.data,
                ),
              ),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  editCustomTarget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AmbitionActions.editCustomTarget),
      mergeMap((action) =>
        this.ambitionService
          .editCustomTarget(action.clientId, action.data)
          .pipe(
            map((response) =>
              AmbitionActions.editCustomTargetSuccess({
                data: response,
                message: 'Edit Target success!',
              }),
            ),
            catchError((error) =>
              of(
                AmbitionActions.editCustomTargetFailure({
                  clientId: action.clientId,
                  data: action.data,
                  error: (error.error || {}).error || 'Edit Target error!',
                  message: (error.error || {}).error || 'Edit Target error!',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  editCustomTargetSuccess$ = createEffect(
    () => this.actions$.pipe(ofType(AmbitionActions.editCustomTargetSuccess)),
    { dispatch: false },
  );

  editCustomTargetFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AmbitionActions.editCustomTargetFailure),
        tap((action) =>
          this.snackbar.showToast(
            action.message,
            'action',
            undefined,
            of({}).pipe(
              tap(() =>
                this.ambitionFacade.editCustomTarget(
                  action.clientId,
                  action.data,
                ),
              ),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  deleteCustomTarget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AmbitionActions.deleteCustomTarget),
      mergeMap((action) =>
        this.ambitionService
          .deleteCustomTarget(action.clientId, action.constraintId)
          .pipe(
            map(() =>
              AmbitionActions.deleteCustomTargetSuccess({
                constraintId: action.constraintId,
                message: 'Delete Target success!',
              }),
            ),
            catchError((error) =>
              of(
                AmbitionActions.deleteCustomTargetFailure({
                  clientId: action.clientId,
                  constraintId: action.constraintId,
                  error: (error.error || {}).error || 'Delete Target error!',
                  message: (error.error || {}).error || 'Delete Target error!',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  deleteCustomTargetSuccess$ = createEffect(
    () => this.actions$.pipe(ofType(AmbitionActions.deleteCustomTargetSuccess)),
    { dispatch: false },
  );

  deleteCustomTargetFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AmbitionActions.deleteCustomTargetFailure),
        tap((action) =>
          this.snackbar.showToast(
            action.message,
            'action',
            undefined,
            of({}).pipe(
              tap(() =>
                this.ambitionFacade.deleteCustomTarget(
                  action.clientId,
                  action.constraintId,
                ),
              ),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private ambitionService: AmbitionService,
    private snackbar: ToastService,
    private ambitionFacade: AmbitionFacadeService,
  ) {}
}

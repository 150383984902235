import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client';
import {
  BindataChartValues,
  BindataHourlyModel,
} from 'ssotool-app/+entities/containers/bindata/bindata-chart.model';
import { BinDataFacadeService } from 'ssotool-app/+entities/stores/bindata/bindata-facade.service';
import { BinData } from 'ssotool-app/+entities/stores/bindata/bindata.model';
import { BaseComponent } from 'ssotool-shared/component/base';

import { Directive } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

type YearlyChartModel = Readonly<{
  readonly name?: string;
  readonly granularity?: string;
  readonly series?: string[];
  readonly startYear?: number;
}>;

@UntilDestroy()
@Directive()
export abstract class BindataBaseComponent extends BaseComponent {
  yearlyTabs = [];
  yearlyChartValues$ = new BehaviorSubject<YearlyChartModel>(undefined);
  activeBin: BindataChartValues;
  quantityId$ = new BehaviorSubject<string>(undefined);
  quantity$ = new BehaviorSubject<string>(undefined);
  loading$ = this.binDataFacade.getLoadingStream();
  startYear: number;

  protected constructor(
    public dialogRef: MatDialogRef<BindataHourlyModel>,
    public binDataFacade: BinDataFacadeService,
    public clientFacade: ClientFacadeService,
  ) {
    super();
  }

  setQuantityByFluid(clientId: string, fluidId: string) {
    this.clientFacade
      .selectFluids$(clientId)
      .pipe(
        first(),
        map((fluids) => fluids[fluidId]),
      )
      .subscribe((fluid) => {
        this.setQuantity(clientId, fluid.quantityId);
      });
  }

  setQuantity(clientId: string, quantityId: string) {
    this.clientFacade
      .selectQuantities$(clientId)
      .pipe(first())
      .pipe(map((quantities) => quantities[quantityId]))
      .subscribe((quantity) =>
        this.quantity$.next(`${quantity.name} - (${quantity.unit})`),
      );
  }

  getIndexOfBinaryId(
    location: string,
    entityId: string,
    values: BindataChartValues[],
  ): BinData {
    const index = values.findIndex(
      (value) => value.binaryId === this.activeBin.binaryId,
    );
    const chartValue = values[index];

    return {
      id: chartValue.binaryId,
      localId: chartValue.binaryId,
      entityId,
      index,
      data: [],
      location,
    };
  }

  loadBinaryData(data: BindataHourlyModel) {
    const currentBinData = this.getIndexOfBinaryId(
      data.location,
      data.entityId,
      data.values,
    );

    this.binDataFacade
      .getBinData(data.location, data.entityId, currentBinData.index)
      .pipe(first())
      .subscribe((values) =>
        this.setYearlyChartValue({
          ...currentBinData,
          data: values,
        }),
      );
  }

  onClose() {
    this.dialogRef.close();
  }

  abstract setYearlyChartValue(bdata: BinData);

  abstract onTabChange(event);
}

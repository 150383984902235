import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client';
import {
  DEFAULT_END_YEAR,
  DEFAULT_START_YEAR,
} from 'ssotool-app/app.references';
import { BaseFormComponent } from 'ssotool-app/shared/component/base-form/base-form.component';
import {
  convertToYearlyValues,
  FormFieldErrorMessageMap,
  FormFieldOption,
  FormService,
} from 'ssotool-shared';

import { Component, Inject, OnInit, Optional, Self } from '@angular/core';
import { FormBuilder, FormGroup, NgControl, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  DetailsDrawerService,
  OCULUS_DETAILS_DRAWER_DATA,
} from '@oculus/components/details-drawer';

import {
  ExistingStorageDrawerData,
  ExistingStorageDrawerMode,
} from './existing-storage-drawer.model';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'sso-existing-storage-drawer',
  templateUrl: './existing-storage-drawer.component.html',
  styleUrls: ['./existing-storage-drawer.component.scss'],
})
export class ExistingStorageDrawerComponent
  extends BaseFormComponent
  implements OnInit
{
  startYear = DEFAULT_START_YEAR;
  endYear = DEFAULT_END_YEAR;
  baseForm: FormGroup = this.formBuilder.group({
    storageId: '',
    clientId: '',
    owner: '',
    name: ['', Validators.required],
    geoId: ['', Validators.required],
    companyEntityId: ['', Validators.required],
    sectorId: '',
    fluidId: '',
    processId: ['', Validators.required],
    startYear: DEFAULT_START_YEAR,
    endYear: DEFAULT_END_YEAR,
    capacity: [
      convertToYearlyValues('0.0', this.startYear, this.endYear),
      Validators.required,
    ],
    ePRatio: [
      convertToYearlyValues('0.0', this.startYear, this.endYear),
      Validators.required,
    ],
    maxDod: [
      convertToYearlyValues('0.0', this.startYear, this.endYear),
      Validators.required,
    ],
    roundtripEfficiency: [
      convertToYearlyValues('0.0', this.startYear, this.endYear),
      Validators.required,
    ],
    technicalLife: [
      convertToYearlyValues('10', this.startYear, this.endYear),
      Validators.required,
    ],
    depreciationTime: [
      convertToYearlyValues('10', this.startYear, this.endYear),
      Validators.required,
    ],
    foM1: [
      convertToYearlyValues('0.0', this.startYear, this.endYear),
      Validators.required,
    ],
    foM2: [
      convertToYearlyValues('0.0', this.startYear, this.endYear),
      Validators.required,
    ],
    fomPerInstall: [
      convertToYearlyValues('0.0', this.startYear, this.endYear),
      Validators.required,
    ],
  });
  errorMessages: FormFieldErrorMessageMap =
    this._formService.getErrorMessageMap('Entities.messages.errors');
  fluidOptions$ = new Observable<FormFieldOption<string>[]>();
  geoOptions$ = new Observable<FormFieldOption<string>[]>();
  companyOptions$ = new Observable<FormFieldOption<string>[]>();
  sectorOptions$ = new Observable<FormFieldOption<string>[]>();
  processOptions$ = new Observable<FormFieldOption<string>[]>();

  private _mode = new BehaviorSubject<ExistingStorageDrawerMode>(
    ExistingStorageDrawerMode.VIEW,
  );
  mode$ = this._mode.asObservable();

  readonly$ = this.mode$.pipe(
    startWith(ExistingStorageDrawerMode.VIEW),
    map(
      (mode) => this.data.readonly || mode === ExistingStorageDrawerMode.VIEW,
    ),
  );
  loading$ = this.clientFacade.dataLoading$(this.data.clientId);

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    @Inject(OCULUS_DETAILS_DRAWER_DATA)
    public data: ExistingStorageDrawerData,
    public formBuilder: FormBuilder,
    private _formService: FormService,
    private clientFacade: ClientFacadeService,
    private drawerService: DetailsDrawerService,
  ) {
    super(ngControl);
  }

  get isPristine() {
    return this.baseForm.pristine;
  }

  ngOnInit() {
    super.ngOnInit();
    this._mode.next(this.data.mode);

    this.initForm();
    this.initFieldOptions();
  }

  initForm() {
    let initializedForm = {
      clientId: this.data.clientId,
    };
    if (
      this.data?.existingStorage &&
      this.data.mode !== ExistingStorageDrawerMode.CREATE
    ) {
      initializedForm = {
        ...initializedForm,
        ...this.data.existingStorage,
      };
    }
    this.baseForm.patchValue(initializedForm, { emitEvent: false });
  }

  /**
   * Initialized fields that has reference to the client entities.
   */
  private initFieldOptions() {
    const selectorKey = this.data.clientId;
    this.geoOptions$ = this.createFormOptions(
      this.clientFacade.selectGeos$,
      selectorKey,
      'geoId',
    );
    this.companyOptions$ = this.createFormOptions(
      this.clientFacade.selectCompanies$,
      selectorKey,
      'companyEntityId',
    );
    this.sectorOptions$ = this.createFormOptions(
      this.clientFacade.selectSectors$,
      selectorKey,
    );
    this.processOptions$ = this.createFormOptions(
      this.clientFacade.selectProcesses$,
      selectorKey,
    );
    this.fluidOptions$ = this.createFormOptions(
      this.clientFacade.selectFluids$,
      selectorKey,
    );
  }

  onClose(): void {
    this.drawerService.close();
  }
}

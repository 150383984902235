<div class="dialog-container">
  <form
    class="form"
    [formGroup]="importForm"
    fxLayout="column"
    fxLayoutGap="10px"
    (ngSubmit)="onSubmit()"
  >
    <h1>{{ 'Campaign.labels.importDialogTitle' | translate }}</h1>

    <mat-form-field class="sso-file-upload-form-field" appearance="outline">
      <mat-label>{{ 'Campaign.labels.inputFile' | translate }}</mat-label>
      <sso-file-upload
        required
        [placeholder]="'Select file for import'"
        [formControl]="importForm?.controls?.file"
        [acceptedExtensions]="allowedFileExtns"
      >
      </sso-file-upload>
      <mat-icon matSuffix>attach_file</mat-icon>
      <mat-error *ngIf="importForm?.controls?.file?.errors?.required">
      </mat-error>
    </mat-form-field>
    <div fxLayout="row" fxLayoutGap="10px">
      <sso-input
        fxFlex="100%"
        [placeholder]="'Campaign.labels.importDialogDescription' | translate"
        [label]="'Campaign.labels.importDialogDescription' | translate"
        [formControl]="importForm?.controls?.description"
        [isTextArea]="true"
        [tooltip]="
          'campaigns_import.campaigns_import_create_description' | translate
        "
      ></sso-input>
    </div>
    <div
      mat-dialog-actions
      fxLayout="row"
      class="dialogActions"
      fxLayoutGap="10px"
    >
      <button mat-flat-button type="button" [color]="" (click)="onCancel()">
        {{ 'Generic.labels.cancel' | translate }}
      </button>
      <button
        data-testid="submit-button"
        mat-flat-button
        type="submit"
        color="primary"
        [disabled]="isPristine"
      >
        {{ 'Generic.labels.submit' | translate }}
      </button>
    </div>
  </form>
</div>

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { profileFeatureKey, ProfileState } from './profile.reducer';

export const profileFeatureState =
  createFeatureSelector<ProfileState>(profileFeatureKey);

export const loading = createSelector(
  profileFeatureState,
  (state) => state.loading,
);

export const loaded = createSelector(
  profileFeatureState,
  (state) => state.loaded,
);

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { NavigatableClientComponent } from './navigatable-client.component';

@NgModule({
  declarations: [NavigatableClientComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [NavigatableClientComponent],
})
export class NavigatableClientModule {}

import { filter, first, map, mergeMap, tap } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client';
import { Coerce } from 'ssotool-shared/helpers';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { PortfolioRoadmap } from '../stores';
import { RoadmapFacadeService } from '../stores/roadmap/roadmap-facade.service';

@Injectable()
export class RoadmapTimelineResolverService implements Resolve<any> {
  constructor(
    private clientFacade: ClientFacadeService,
    private roadmapFacade: RoadmapFacadeService,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const roadmapId = Coerce.toObject(route.parent.params).roadmapId;

    this.clientFacade.selectActiveClientData$
      .pipe(
        filter((client) => !!client?.clientId),
        first(),
        map((client) => client.clientId),
        mergeMap((id) =>
          this.roadmapFacade.roadmap$(roadmapId).pipe(
            filter((roadmap) => !!roadmap),
            first(),
            tap((roadmap: PortfolioRoadmap) => {
              if (!roadmap.campaignsLoaded && !roadmap.campaignsLoading) {
                this.roadmapFacade.fetchRoadmapCampaigns(id, roadmapId);
              }
            }),
          ),
        ),
      )
      .subscribe();
  }
}

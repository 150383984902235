import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { RoadmapImportActions } from './roadmap-import.action';
import { RoadmapImportModel } from './roadmap-import.model';

export const roadmapImportFeatureKey = 'RoadmapImport';

export interface RoadmapImportState
  extends EntityState<Partial<RoadmapImportModel>> {
  loading: boolean;
  loaded: boolean;
  error: string;
  message: string;
  downloading?: boolean;
  downloadingTemplate?: boolean;
  importing?: boolean;
}

export const selectRoadmapImportId = (roadmapImport: RoadmapImportModel) =>
  roadmapImport.importId;

export const sortDate = (
  importData1: RoadmapImportModel,
  importData2: RoadmapImportModel,
) => {
  const date1 = new Date(importData1.createdAt);
  const date2 = new Date(importData2.createdAt);
  return date1.getTime() - date2.getTime();
};

export const roadmapImportAdapter: EntityAdapter<Partial<RoadmapImportModel>> =
  createEntityAdapter<RoadmapImportModel>({
    selectId: selectRoadmapImportId,
    sortComparer: sortDate,
  });

export const initialRoadmapImportState: RoadmapImportState =
  roadmapImportAdapter.getInitialState({
    loading: false,
    loaded: false,
    downloadingTemplate: false,
    error: undefined,
    message: undefined,
    importing: undefined,
  });

const roadmapImportReducer = createReducer(
  initialRoadmapImportState,

  // Get Import
  on(RoadmapImportActions.get, (state) => {
    return { ...state, loading: true };
  }),
  on(RoadmapImportActions.getSuccess, (state, { data, message }) => {
    return roadmapImportAdapter.upsertOne(
      { ...data, loaded: true, loading: false },
      { ...state, message, loading: false },
    );
  }),
  on(RoadmapImportActions.getFailure, (state, { error, message }) => {
    return { ...state, error, loading: false, message };
  }),

  // Get List of imports
  on(RoadmapImportActions.getList, (state) => {
    return { ...state, loading: true };
  }),
  on(RoadmapImportActions.getListSuccess, (state, { data, message }) => {
    const updateData = data.data.map(
      (d) => ({ ...d, loaded: true } as RoadmapImportModel),
    );
    return roadmapImportAdapter.upsertMany(updateData, {
      ...state,
      message,
      loading: false,
      loaded: true,
    });
  }),
  on(RoadmapImportActions.getListFailure, (state, { error, message }) => {
    return { ...state, loading: false, loaded: false, error, message };
  }),

  // Update import status
  on(
    RoadmapImportActions.updateImportStatus,
    (state, { clientId, importId, status }) => {
      return roadmapImportAdapter.updateOne(
        { id: importId, changes: { status } },
        { ...state, importing: false },
      );
    },
  ),
  on(
    RoadmapImportActions.updateImportLogDetails,
    (state, { importId, logDetails }) => {
      return roadmapImportAdapter.upsertOne(
        { importId, logDetails },
        { ...state },
      );
    },
  ),

  // Import roadmap
  on(RoadmapImportActions.importRoadmap, (state) => ({
    ...state,
    loading: true,
    importing: true,
  })),
  on(RoadmapImportActions.importRoadmapSuccess, (state, { data, message }) =>
    roadmapImportAdapter.upsertOne(
      { ...data, loaded: true },
      { ...state, loading: false, message, importing: false },
    ),
  ),
  on(
    RoadmapImportActions.importRoadmapFailure,
    (state, { error, message }) => ({
      ...state,
      loading: false,
      error,
      message,
      importing: false,
    }),
  ),

  // Download imported excel
  on(RoadmapImportActions.downloadRoadmapImportData, (state, { importId }) => {
    return roadmapImportAdapter.upsertOne({ importId, loading: true }, state);
  }),
  on(
    RoadmapImportActions.downloadRoadmapImportDataSuccess,
    (state, { message, data }) => {
      return roadmapImportAdapter.upsertOne(
        { importId: data.importId, loading: false },
        { ...state, message },
      );
    },
  ),
  on(
    RoadmapImportActions.downloadRoadmapImportDataFailure,
    (state, { error, message, data }) => {
      return roadmapImportAdapter.upsertOne(
        { importId: data.importId, loading: false },
        { ...state, message, error },
      );
    },
  ),

  // Download Error Logs
  on(RoadmapImportActions.downloadErrorLogs, (state, { importId }) => {
    return roadmapImportAdapter.upsertOne(
      { importId, logsLoading: true },
      state,
    );
  }),
  on(
    RoadmapImportActions.downloadErrorLogsSuccess,
    (state, { importId, data, message }) => {
      return roadmapImportAdapter.upsertOne(
        { importId, messages: data, logsLoading: false },
        { ...state, message },
      );
    },
  ),
  on(
    RoadmapImportActions.downloadErrorLogsFailure,
    (state, { error, message, data }) => {
      return roadmapImportAdapter.upsertOne(
        { importId: data.importId, logsLoading: false },
        { ...state, error, message },
      );
    },
  ),

  // Delete import
  on(RoadmapImportActions.deleteImport, (state, { importId }) => {
    return roadmapImportAdapter.upsertOne({ importId, loading: true }, state);
  }),
  on(
    RoadmapImportActions.deleteImportSuccess,
    (state, { importId, message }) => {
      return roadmapImportAdapter.removeOne(importId, {
        ...state,
        loading: false,
        message: message,
      });
    },
  ),
  on(
    RoadmapImportActions.deleteImportFailure,
    (state, { message, error, data }) => {
      return roadmapImportAdapter.upsertOne(
        { importId: data.importId, loading: false },
        { ...state, message, error },
      );
    },
  ),

  // Download template
  on(RoadmapImportActions.downloadTemplate, (state) => ({
    ...state,
    downloadingTemplate: true,
  })),
  on(RoadmapImportActions.downloadTemplateSuccess, (state, { message }) => ({
    ...state,
    downloadingTemplate: false,
    message,
  })),
  on(
    RoadmapImportActions.downloadTemplateFailure,
    (state, { error, message }) => ({
      ...state,
      error,
      message,
      downloadingTemplate: false,
    }),
  ),
);

export const RoadmapImportStore = {
  featureName: roadmapImportFeatureKey,
  reducer: roadmapImportReducer,
};

export function RoadmapImportReducer(
  state: RoadmapImportState | undefined,
  action: Action,
) {
  return roadmapImportReducer(state, action);
}

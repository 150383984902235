<div class="dialog-container">
  <mat-progress-bar
    *ngIf="loading$ | async"
    mode="indeterminate"
    color="accent"
  ></mat-progress-bar>
  <form
    fxLayout="column"
    fxLayoutGap="1em"
    [formGroup]="addUserForm"
    (ngSubmit)="onSave(formDirective)"
    #formDirective="ngForm"
  >
    <div>
      <sso-share-client-table
        [activeClient]="activeClient$ | async"
        [sharedUsers]="sharedUserList$ | async"
        [permissionOptions]="permissionOptions"
        [readonly]="isReadOnly$ | async"
        (pristine)="onValueChange($event)"
      ></sso-share-client-table>
      <div
        *ngIf="isAdmin$ | async"
        fxLayout="row"
        [ngStyle]="{ padding: '1.34375em 0' }"
      >
        <div fxFlex="40%">
          <mat-form-field
            data-testid="share-input-email-testid"
            [ngStyle]="{ 'margin-left': '1em' }"
            class="sso-maximize-width"
            appearance="outline"
            fxFlex="90"
          >
            <mat-label>{{ 'Client.labels.userEmail' | translate }}</mat-label>
            <input
              matInput
              formControlName="email"
              data-testid="e2e-share-input-email-testid"
              [matAutocomplete]="auto"
              [placeholder]="'Client.labels.userEmail' | translate"
              required
              (blur)="onBlur()"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="selectOptionAutoComplete($event)"
            >
              <mat-option *ngFor="let name of names$ | async" [value]="name">
                {{ name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error
              *ngFor="
                let error of getErrors(addUserForm?.controls?.email?.errors)
              "
              [innerHtml]="errorMessageMap['email'][error]"
            >
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="40%">
          <sso-select
            data-testid="permission-select-box"
            fxFlex="90"
            [control]="addUserForm?.controls?.role"
            [options]="permissionOptions"
            [placeholder]="'Client.labels.permission' | translate"
            [label]="'Client.labels.permission' | translate"
            [required]="true"
          ></sso-select>
        </div>
        <div style="position: relative">
          <button
            mat-icon-button
            type="button"
            tooltipPosition="right"
            color="primary"
            #add
            (click)="onAddUser(formDirective)"
            [disabled]="addUserForm.pristine || addUserForm.invalid"
          >
            <mat-icon matTooltip="Add more user">add</mat-icon>
          </button>
          <mat-spinner
            *ngIf="isRetrievingEmail$ | async"
            diameter="40"
            color="accent"
          >
          </mat-spinner>
        </div>
      </div>
      <div class="button-holder" fxLayout="row" fxLayoutGap="1em">
        <button
          mat-flat-button
          data-testid="cancel-button"
          color=""
          (click)="onCancel()"
          type="button"
        >
          {{ 'Generic.labels.' + (cancelButtonLabel$ | async) | translate }}
        </button>
        <button
          data-testid="confirm-button"
          *ngIf="isAdmin$ | async"
          mat-flat-button
          color="primary"
          [disabled]="isSubmitDisabled$ | async"
        >
          {{ 'Generic.labels.save' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Coerce } from 'ssotool-app/shared/helpers';

import { Store } from '@ngrx/store';

import { PortfolioEntityActions } from './portfolio.actions';
import { PortfolioEntityState } from './portfolio.reducer';

export abstract class BasePortfolioFacadeService {
  private actions;
  private rxStore: Store<PortfolioEntityState>;

  abstract getAPIName(): string;

  constructor(store: Store<PortfolioEntityState>) {
    this.rxStore = store;
    this.actions = PortfolioEntityActions(this.getAPIName());
  }

  get(clientId: string, id: string) {
    this.rxStore.dispatch(this.actions.getAction({ clientId, id }));
    return this.actions.getOKAction.type;
  }

  getAll(clientId: string) {
    this.rxStore.dispatch(this.actions.getAllAction({ clientId }));
  }

  getLoadingStream(): Observable<boolean> {
    return this.getStateStream().pipe(map((state) => state.loading));
  }

  getDataListStream(): Observable<any[]> {
    return this.getStateStream().pipe(
      map((state) => Coerce.getObjValues(state.dataMap)),
    );
  }

  getDataMapStream(): Observable<any> {
    return this.getStateStream().pipe(map((state) => state.dataMap));
  }

  getDataStream(id: string): Observable<any> {
    return this.getStateStream().pipe(map((state) => state.dataMap[id]));
  }

  getLoadedStream(): Observable<boolean> {
    return this.getStateStream().pipe(map((state) => state.loaded));
  }

  getErrorStream(): Observable<boolean> {
    return this.getStateStream().pipe(map((state) => state.error));
  }

  private getStateStream(): Observable<PortfolioEntityState> {
    return this.rxStore.select((state) => state[this.getAPIName()]);
  }
}

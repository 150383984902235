import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { TooltipModule } from '@swimlane/ngx-charts';

import { FluidsMenuButtonModule } from '../fluids-menu-button';
import { FluidsToggleGroupModule } from '../fluids-toggle-group';
import { YearlyInputModule } from '../yearly-input';
import { FluidsArrayComponent } from './fluids-array.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    FluidsToggleGroupModule,
    FluidsMenuButtonModule,
    YearlyInputModule,
    TooltipModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  declarations: [FluidsArrayComponent],
  exports: [FluidsArrayComponent],
  providers: [],
})
export class FluidsArrayModule {}

import { GanttNameComponent } from './gantt-name.component';
import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { CommonModule } from '@angular/common';
import { SelectionEmitterDirectiveModule } from 'ssotool-app/shared/directives/selection-emitter';

@NgModule({
  declarations: [GanttNameComponent],
  imports: [MatListModule, CommonModule, SelectionEmitterDirectiveModule],
  exports: [GanttNameComponent],
})
export class GanttNameModule {}

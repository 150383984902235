import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { ClientActions } from './client.actions';
import { Client } from './client.model';
import { ExecStatusChecker } from 'ssotool-app/shared/helpers/exec-status.checker';

export const clientFeatureKey = 'client';

export interface ClientState extends EntityState<Client> {
  loading: boolean;
  loaded: boolean;
  error: string;
  errorInSandbox: boolean;
  message: string;
  selectedClientId: string;
  archive: Record<string, Client>;
  archiveLoading: boolean;
  sharing: boolean;
}

export const selectClientId = (client: Client) => client.clientId;
export const sortName = (client1: Client, client2: Client) =>
  client1.name.localeCompare(client2.name);

export const clientAdapter: EntityAdapter<Partial<Client>> =
  createEntityAdapter<Client>({
    selectId: selectClientId,
    sortComparer: sortName,
  });

export const initialClientState: ClientState = clientAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: undefined,
  errorInSandbox: false,
  selectedClientId: undefined,
  message: undefined,
  archive: {},
  archiveLoading: false,
  sharing: false,
});

function getClientTransientAttributes(data: Client) {
  const permissions = data?.permissions;
  const templateVersion = data?.templateVersion;
  const campaignTemplateVersion = data?.campaignTemplateVersion;
  return { permissions, templateVersion, campaignTemplateVersion };
}

const statusChecker = new ExecStatusChecker();

const clientReducer = createReducer(
  initialClientState,
  on(ClientActions.selectActiveClient, (state, { clientId }) => {
    return { ...state, selectedClientId: clientId };
  }),
  on(ClientActions.removeActiveClient, (state) => {
    return { ...state, selectedClientId: null };
  }),
  on(ClientActions.create, (state) => {
    return { ...state, loading: true, error: undefined };
  }),
  on(ClientActions.createSuccess, (state, { data, message }) => {
    return clientAdapter.upsertOne(
      { ...data, loaded: true },
      { ...state, loading: false, message, selectedClientId: data.clientId },
    );
  }),
  on(ClientActions.createFailure, (state, { message, error }) => {
    return { ...state, error, message, loading: false };
  }),
  on(ClientActions.update, (state, { clientId }) => {
    return clientAdapter.upsertOne(
      { clientId, loading: true },
      { ...state, loading: false, error: undefined },
    );
  }),
  on(ClientActions.updateSuccess, (state, { data, message }) => {
    const clientToUpdate = state.entities[data.clientId];
    const transientAttribs = getClientTransientAttributes(clientToUpdate);
    return clientAdapter.upsertOne(
      { ...data, ...transientAttribs, loaded: true, loading: false },
      { ...state, loading: false, message },
    );
  }),
  on(ClientActions.updateFailure, (state, { data, message, error }) => {
    return clientAdapter.upsertOne(
      { clientId: data.clientId, loaded: true, loading: false },
      { ...state, error, message },
    );
  }),
  on(ClientActions.get, (state, { clientId }) => {
    const clientToGet = state.entities[clientId];
    if (!!clientToGet) {
      return clientAdapter.upsertOne(
        { ...clientToGet, loading: true, loaded: false },
        { ...state, error: undefined },
      );
    }
    return { ...state, loading: true, error: undefined };
  }),
  on(ClientActions.getSuccess, (state, { data, message }) => {
    return clientAdapter.upsertOne(
      { ...data, loaded: true, loading: false },
      { ...state, error: undefined, message, loading: false },
    );
  }),
  on(ClientActions.getFailure, (state, { data, error, message }) => {
    const clientToGetFail = state.entities[data?.clientId];
    if (!!clientToGetFail) {
      return clientAdapter.upsertOne(
        { ...clientToGetFail, loading: false, loaded: true },
        { ...state, error, message },
      );
    }
    return { ...state, error, loading: false, message };
  }),
  on(ClientActions.getList, (state) => {
    return { ...state, loading: true, error: undefined };
  }),
  on(ClientActions.getListSuccess, (state, { data, message }) => {
    return clientAdapter.upsertMany(
      data.map((datum) => ({ ...datum, loaded: true })),
      {
        ...state,
        loading: false,
        loaded: true,
        message,
      },
    );
  }),
  on(ClientActions.getListFailure, (state, { error, message }) => {
    return { ...state, loading: false, loaded: false, error, message };
  }),
  on(ClientActions.getArchivedList, (state) => {
    return { ...state, archiveLoading: true, error: undefined };
  }),
  on(ClientActions.getArchivedListSuccess, (state, { data, message }) => {
    return clientAdapter.upsertMany(
      data.map((datum) => ({ ...datum, loaded: true })),
      {
        ...state,
        archiveLoading: false,
        message,
      },
    );
  }),
  on(ClientActions.getArchivedListFailure, (state, { error, message }) => {
    return { ...state, archiveLoading: false, error, message };
  }),
  on(ClientActions.getClientShareInfo, (state, { clientId }) => {
    return clientAdapter.upsertOne(
      { clientId, shareDataLoading: true },
      { ...state },
    );
  }),
  on(ClientActions.getClientShareInfoSuccess, (state, { data, message }) => {
    return clientAdapter.upsertOne(
      { ...data, shareDataLoading: false },
      { ...state, message },
    );
  }),
  on(
    ClientActions.getClientShareInfoFailure,
    (state, { data, error, message }) => {
      return clientAdapter.upsertOne(
        { ...data, shareDataLoading: false },
        { ...state, error, message },
      );
    },
  ),
  on(ClientActions.share, (state) => {
    return { ...state, sharing: true };
  }),
  on(ClientActions.shareSuccess, (state, { data, message }) => {
    return clientAdapter.upsertOne(
      { ...data },
      { ...state, sharing: false, message },
    );
  }),
  on(ClientActions.shareFailure, (state, { message, error }) => {
    return { ...state, sharing: false, message, error };
  }),
  on(ClientActions.deleteClient, (state, { clientId }) => {
    return clientAdapter.upsertOne({ clientId, loading: true }, state);
  }),
  on(ClientActions.deleteClientSuccess, (state, { data, message }) => {
    return clientAdapter.removeOne(data.clientId, {
      ...state,
      loading: false,
      message,
    });
  }),
  on(ClientActions.deleteClientFailure, (state, { message, error }) => {
    return { ...state, loading: false, message, error };
  }),
  on(ClientActions.getClientDataInfo, (state, { clientId }) => {
    return clientAdapter.upsertOne(
      { clientId, dataLoading: true, dataLoaded: false },
      { ...state },
    );
  }),
  on(
    ClientActions.getClientDataInfoSuccess,
    (state, { data: { clientId, clientData }, message }) => {
      return clientAdapter.upsertOne(
        { clientId, clientData, dataLoading: false, dataLoaded: true },
        { ...state, message },
      );
    },
  ),
  on(
    ClientActions.getClientDataInfoFailure,
    (state, { clientId, error, message }) => {
      return clientAdapter.upsertOne(
        { clientId, dataLoading: false, dataLoaded: false },
        { ...state, error, message },
      );
    },
  ),
  on(ClientActions.updateOngoingExport, (state, { clientId, flag }) => {
    return clientAdapter.upsertOne(
      {
        clientId,
        // exportOngoing: flag
      },
      { ...state },
    );
  }),

  on(ClientActions.archiveClient, (state, { clientId }) => {
    return clientAdapter.upsertOne({ clientId, loading: true }, state);
  }),
  on(ClientActions.archiveClientSuccess, (state, { data, message }) => {
    return clientAdapter.upsertOne(
      {
        clientId: data,
        loaded: true,
        loading: false,
      },
      {
        ...state,
        message,
      },
    );
  }),
  on(
    ClientActions.archiveClientFailure,
    (state, { data: { clientId }, error, message }) => {
      return clientAdapter.upsertOne(
        { clientId, loading: false },
        { ...state, message, error },
      );
    },
  ),

  on(ClientActions.unarchiveClient, (state, { clientId }) => {
    return clientAdapter.upsertOne({ clientId, loading: true }, state);
  }),
  on(ClientActions.unarchiveClientSuccess, (state, { data, message }) => {
    return clientAdapter.upsertOne(
      {
        clientId: data,
        loaded: true,
        loading: false,
      },
      {
        ...state,
        message,
      },
    );
  }),
  on(
    ClientActions.unarchiveClientFailure,
    (state, { data: { clientId }, error, message }) => {
      return clientAdapter.upsertOne(
        { clientId, loading: false },
        { ...state, error, message },
      );
    },
  ),
  on(
    ClientActions.cascadeUpdate,
    (state, { clientId, updatedAt, updatedBy }) => {
      return clientAdapter.upsertOne(
        { clientId, updatedAt, updatedBy },
        { ...state, loading: false },
      );
    },
  ),
  on(ClientActions.duplicateClient, (state, { clientId }) => {
    return clientAdapter.upsertOne({ clientId, loading: true }, state);
  }),
  on(ClientActions.duplicateClientSuccess, (state, { data, message }) => {
    return clientAdapter.upsertOne(
      { ...data, loading: false },
      { ...state, message },
    );
  }),
  on(
    ClientActions.duplicateClientFailure,
    (state, { data, error, message }) => {
      return clientAdapter.upsertOne(
        { clientId: data.clientId, loading: false },
        { ...state, error, message },
      );
    },
  ),
  on(ClientActions.updateDuplicateStatus, (state, { status, isSandbox }) => {
    if (isSandbox) {
      return {
        ...state,
        errorInSandbox: !statusChecker.isSuccessful(status),
      };
    }

    return state;
  }),
  on(ClientActions.quickSwitch, (state) => ({ ...state, loading: true })),
);

export function ClientReducer(state: ClientState | undefined, action: Action) {
  return clientReducer(state, action);
}

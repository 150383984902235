import { concatMap, filter, map, mergeMap, take } from 'rxjs/operators';
import { DatahubFacadeService } from 'ssotool-app/+client/store/datahub/datahub.facade.service';
import { TRAJECTORY_GROUP_DEFINITIONS } from 'ssotool-app/+client/store/datahub/datahub.model';
import { ToolsDrawerComponent } from 'ssotool-app/+data-management/components/tools-drawer/tools-drawer.component';
import {
  DataImportFacadeService,
  DataImportModel,
} from 'ssotool-app/+data-management/stores';
import { TableItems } from 'ssotool-app/shared/component/generic-table/generic-table.model';
import { ClientFacadeService, Trajectory } from 'ssotool-client';
import { Coerce, PermissionChecker } from 'ssotool-shared/helpers';

import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DetailsDrawerService } from '@oculus/components/details-drawer';

import { AdditionalImportDialogComponent } from './components/additional-import-dialog/additional-import-dialog.component';
import { AdditionalImportDialogResult } from './components/additional-import-dialog/additional-import-dialog.model';
import { PackagedDialogConfig } from './data-import.model';
import { DATA_IMPORT_DIALOG_CONFIG } from './data-import.references';

@UntilDestroy()
@Component({
  selector: 'sso-data-import',
  templateUrl: './data-import.component.html',
  styleUrls: ['./data-import.component.scss'],
})
export class DataImportComponent {
  isAdmin$ = this.clientFacade.selectActiveClientData$.pipe(
    map((client) => this.permissionChecker.isAdmin(client?.permissions)),
  );
  hasSuccessfulImport$ = this.clientFacade.selectActiveClientData$.pipe(
    map((client) => !!client?.hasSuccessfulImport),
  );
  isReadOnly$ = this.isAdmin$.pipe(map((isAdmin) => !isAdmin));
  uploading$ = this.importFacade.uploading$;
  constructor(
    private clientFacade: ClientFacadeService,
    private importFacade: DataImportFacadeService,
    private permissionChecker: PermissionChecker,
    private datahubService: DatahubFacadeService,
    private matDialog: MatDialog,
    private drawerService: DetailsDrawerService,
  ) {
    this.subscribeDataImportNeededingTrajectories();
    this.subscribeAdditionalImportDialogOpening();
  }

  private subscribeAdditionalImportDialogOpening(): void {
    this.datahubService.getTrajectories$
      .pipe(
        untilDestroyed(this),
        filter((trajectories) => !!trajectories.length),
        take(1),
        mergeMap((trajectories: Trajectory[]) => {
          return this.importFacade.getImportNeedingTrajectories$.pipe(
            filter(Boolean),
            concatMap((dataImport: DataImportModel) => {
              return this.clientFacade.selectActiveClientData$.pipe(
                take(1),
                map((client) =>
                  this.packageDataImportDetails(
                    client.clientId,
                    dataImport.importId,
                    trajectories,
                  ),
                ),
              );
            }),
          );
        }),
      )
      .subscribe((dialogConfig) => {
        const { clientId, importId } = dialogConfig.data;
        this.matDialog
          .open(AdditionalImportDialogComponent, dialogConfig)
          .afterClosed()
          .subscribe((selectedIds: AdditionalImportDialogResult) => {
            this.datahubService.chooseTrajectoriesToSave(
              clientId,
              importId,
              selectedIds ? Coerce.toArray(selectedIds) : [],
            );
          });
      });
  }

  private subscribeDataImportNeededingTrajectories(): void {
    this.datahubService.pristine$
      .pipe(
        filter(Boolean),
        mergeMap(() => {
          return this.importFacade.getImportNeedingTrajectories$.pipe(
            untilDestroyed(this),
            filter(Boolean),
            mergeMap((dataImport: DataImportModel) => {
              return this.clientFacade.selectActiveClientData$.pipe(
                take(1),
                map((client) => {
                  return {
                    clientId: client.clientId,
                    importId: dataImport.importId,
                  };
                }),
              );
            }),
          );
        }),
      )
      .subscribe(({ clientId, importId }) => {
        this.datahubService.getTDBCommodities(clientId, importId);
      });
  }

  private packageDataImportDetails(
    clientId: string,
    importId: string,
    trajectories: Trajectory[],
  ): PackagedDialogConfig {
    return {
      ...DATA_IMPORT_DIALOG_CONFIG,
      data: {
        dialogData: {
          trajectories: this.transformTrajectoriesToTableItems(trajectories),
        },
        clientId,
        importId,
      },
    };
  }

  private transformTrajectoriesToTableItems(
    trajectories: Trajectory[],
  ): TableItems<Trajectory> {
    return trajectories.map((trajectory) => {
      return {
        id: trajectory.trajectoryId,
        isShown: true,
        isSelected: false,
        value: trajectory,
        groupId: !!trajectory.willOverwrite
          ? TRAJECTORY_GROUP_DEFINITIONS[0].id
          : TRAJECTORY_GROUP_DEFINITIONS[1].id,
      };
    });
  }

  openToolsDrawer() {
    this.drawerService.open(ToolsDrawerComponent, {
      data: {},
      width: '30vw',
      closeIcon: false,
      overrideClose: true,
    });
  }
}

import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, mergeMap, tap } from 'rxjs/operators';
import {
  DataImportFacadeService,
  DataImportModel,
} from 'ssotool-app/+data-management/stores';
import { StatusMap } from 'ssotool-app/app.model';
import {
  IMPORT_STATUS,
  IMPORT_STATUS_ICON_MAP,
} from 'ssotool-app/app.references';
import { LogViewerComponent } from 'ssotool-app/shared/modules/log-viewer/log-viewer.component';
import { ClientFacadeService } from 'ssotool-client';
import {
  DialogComponent,
  ExecStatusChecker,
  LogData,
  LogType,
  UserStateManagerService,
} from 'ssotool-shared';

import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';

import { DataImportTableModel } from './import-list.model';

@Component({
  selector: 'sso-import-list',
  templateUrl: './import-list.component.html',
  styleUrls: ['./import-list.component.scss'],
})
export class ImportListComponent {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  COLUMN_DEFS = ['user', 'status', 'actions'];

  statusMap: StatusMap = IMPORT_STATUS_ICON_MAP;

  activeClient$ = this.clientFacade.selectActiveClientData$;
  clientId: string;
  data$: Observable<DataImportTableModel[]> = this.activeClient$.pipe(
    filter((client) => !!client),
    tap((client) => {
      this.clientId = client?.clientId;
    }),
    mergeMap((client) =>
      this.importFacade.getImportDataList$(client?.clientId),
    ),
    map((importList) =>
      importList.map((data) => ({ ...data, date: new Date(data.createdAt) })),
    ),
  );

  ownerReferences$ = combineLatest([
    this.data$,
    this.userService.dataList$,
  ]).pipe(map(([data]) => this.nameMapper(data)));

  datasource$ = this.data$.pipe(
    map((importList) => {
      const matDatasource = new MatTableDataSource(importList);
      matDatasource.sort = this.sort;
      matDatasource.paginator = this.paginator;
      matDatasource.sortData;
      return matDatasource;
    }),
  );
  loading$ = this.importFacade.loading$;
  latestSuccessfulImport$ = this.importFacade.getLatestSuccessfulImport$;

  constructor(
    private clientFacade: ClientFacadeService,
    private importFacade: DataImportFacadeService,
    private userService: UserStateManagerService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private statusChecker: ExecStatusChecker,
  ) {}

  isButtonDisabled(status: string): boolean {
    const ongoingUploadStatuses = [
      'new',
      'ready',
      'processing',
      'requested',
      'tdb_invoking',
    ];
    return ongoingUploadStatuses.includes(status);
  }

  viewLogs(data: DataImportTableModel) {
    this.importFacade
      .logsLoaded$(data.importId)
      .pipe(first())
      .subscribe((isLogsLoaded) => {
        if (!isLogsLoaded) {
          this.importFacade.downloadErrorLogs(data.clientId, data.importId);
        }
      });
    this.dialog.open<LogViewerComponent, LogData, any>(LogViewerComponent, {
      width: '60%',
      data: {
        clientId: this.clientId,
        logType: LogType.CLIENT_IMPORT,
        body: { importId: data.importId },
        details: data.logDetails,
        loading$: this.importFacade.downloading$,
        errorMessages$: this.importFacade.getLogs(data.importId),
        hasError: !!!this.isErrorTableDisabled(data),
      },
    });
  }

  onDownload(data: DataImportTableModel) {
    this.importFacade.downloadClientData(data.clientId, data.importId);
  }

  onDelete(data: DataImportModel): void {
    this.dialog
      .open(DialogComponent, {
        data: {
          message: this.translateService.instant(
            'Entities.labels.delete.import.message',
          ),
          title: this.translateService.instant(
            'Entities.labels.delete.import.title',
          ),
          data,
        },
      })
      .afterClosed()
      .subscribe((dialogData) => {
        if (dialogData?.data) {
          this.importFacade.deleteImport(data.clientId, data.importId);
        }
      });
  }

  isLogsButtonDisabled(data: DataImportTableModel): boolean {
    return this.isLogsDisabled(data) && this.isErrorTableDisabled(data);
  }

  isLogsDisabled(data: DataImportTableModel): boolean {
    return this.statusChecker.isStartingState(data.status);
  }

  isErrorTableDisabled(data: DataImportTableModel): boolean {
    return data.status !== IMPORT_STATUS.ERROR;
  }

  private fetchUser(ownerId: string) {
    this.userService.getUserById(ownerId);
    return this.userService.getUserIdLoading$;
  }

  private nameMapper(data: DataImportTableModel[]) {
    return data.reduce((acc, { owner }) => {
      const user = this.userService.get(owner);

      if (!!!user) {
        this.fetchUser(owner);
      }
      const fullName = `${user?.firstName} ${user?.lastName}`;
      acc[owner] = user && user.loaded ? fullName : 'Loading name...';

      return acc;
    }, {});
  }
}

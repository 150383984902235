import { PermissionChecker, UserStateManagerService } from 'ssotool-shared';
import { NotificationsModule } from 'ssotool-shared/services/notifications/notifications.module';

import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ClientResolverService } from './services';
import { ClientFiltersService } from './services/client-filter.service';
import { ClientAPIService } from './services/client.service';
import { ClientFacadeService, ProfileFacadeService } from './store';
import { ClientEffects } from './store/client.effects';
import { clientFeatureKey, ClientReducer } from './store/client.reducer';
import { ProfileEffects } from './store/profile-local-storage/profile.effects';
import {
  profileFeatureKey,
  ProfileReducer,
} from './store/profile-local-storage/profile.reducer';
import { ProfileService } from './store/profile-local-storage/profile.service';
import { FluidDemandStore } from './store/fluid-demand/fluid-demand.reducer';
import { DatahubStore } from './store/datahub/datahub.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([ClientEffects, ProfileEffects]),
    StoreModule.forFeature(clientFeatureKey, ClientReducer),
    StoreModule.forFeature(profileFeatureKey, ProfileReducer),
    StoreModule.forFeature(
      FluidDemandStore.featureName,
      FluidDemandStore.reducer,
    ),
    StoreModule.forFeature(DatahubStore.featureName, DatahubStore.reducer),
    MatDialogModule,
    NotificationsModule,
  ],
  providers: [
    ClientAPIService,
    ClientFacadeService,
    ClientResolverService,
    UserStateManagerService,
    PermissionChecker,
    ProfileService,
    ProfileFacadeService,
    ClientFiltersService,
  ],
})
export class ClientRootModule {}

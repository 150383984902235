import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { RoadmapVisualizationState } from './visualization.reducer';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { diff } from 'deep-diff';
import { RoadmapVisualizationActions } from './visualization.actions';
import {
  selectActiveVisualization,
  selectLoaded,
  selectLoading,
  selectVisualization,
  selectVisualizationList,
} from './visualization.selector';
import { RoadmapVisualization, VisualizationMap } from './visualization.model';
import { FLUID_FLOWS } from 'ssotool-app/+roadmap/modules/results/analysis/curves/kpi-curve-v2/kpi-curve.references';

@Injectable()
export class RoadmapVisualizationFacadeService {
  constructor(private store: Store<RoadmapVisualizationState>) {}

  loading$ = this.store.pipe(select(selectLoading));
  loaded$ = this.store.pipe(select(selectLoaded));
  visualization$ = (visualizationName: string) =>
    this.store.select(selectVisualization(visualizationName));
  visualizations$ = this.store.pipe(select(selectVisualizationList));
  activeVisualization$ = this.store.pipe(select(selectActiveVisualization));

  save(
    clientId: string,
    roadmapId: string,
    details: VisualizationMap,
    name?: string,
  ) {
    this.visualizations$.pipe(take(1)).subscribe((visualizations) => {
      let saveName = this.generateSaveName(
        visualizations,
        name || this.generateVisualizationName(details),
      );
      this.store.dispatch(
        RoadmapVisualizationActions.save({
          clientId,
          roadmapId,
          details,
          name: saveName,
        }),
      );
    });
  }

  delete(roadmapVisualization: RoadmapVisualization) {
    this.store.dispatch(
      RoadmapVisualizationActions.deleteAction(roadmapVisualization),
    );
  }

  setActiveVisualization(name: string) {
    this.store.dispatch(
      RoadmapVisualizationActions.setActiveVisualization({ name }),
    );
  }

  resetActiveVisualization() {
    this.store.dispatch(RoadmapVisualizationActions.resetActiveVisualization());
  }

  getExistingFromDetails(
    details: VisualizationMap,
  ): Observable<RoadmapVisualization> {
    return this.visualizations$.pipe(
      map(
        (visuals) =>
          visuals.find(
            (visual) => !diff(details, visual.details),
          ) as RoadmapVisualization,
      ),
    );
  }

  /**
   * Returns corrected visualization name given a list of existing visualizations.
   * @param visualizations - existing visualizations
   * @param name - supposed name
   * @returns corrected name
   */
  generateSaveName(
    visualizations: Partial<RoadmapVisualization>[],
    name: string,
  ): string {
    let sameNames = visualizations
      .map((viz) => viz.name)
      .filter((viz) => viz.includes(name))
      .sort();

    if (sameNames.length === 0) {
      return name;
    }

    let lastIteration = sameNames[sameNames.length - 1];
    let lastNumberSuffix = this.getNumberSuffix(lastIteration);
    return name + '_' + (lastNumberSuffix + 1);
  }

  private getNumberSuffix(name: string): number {
    return Number(name.slice(name.lastIndexOf('_') + 1)) || 0;
  }

  private generateVisualizationName(details: VisualizationMap): string {
    const { curveType, kpi, xAxis, splitBy, geography, year } = details;

    return kpi === FLUID_FLOWS.value
      ? [kpi, geography, year].join('-')
      : [
          curveType,
          kpi,
          xAxis.level || xAxis.group,
          splitBy.level || splitBy.group,
        ].join('-');
  }

  getList(clientId: string, roadmapId: string) {
    this.store.dispatch(
      RoadmapVisualizationActions.getList({ clientId, roadmapId }),
    );
  }

  checkIfExisting(details: VisualizationMap): Observable<boolean> {
    return this.visualizations$.pipe(
      map((visualizations) => {
        return visualizations.some((visualization) => {
          return this.compareVisualization(details, visualization.details);
        });
      }),
    );
  }

  private compareVisualization(
    details: VisualizationMap,
    existingVisualization: VisualizationMap,
  ): boolean {
    const compareResult = diff(details, existingVisualization);
    return !!!compareResult;
  }

  checkIfNameExists(name: string): Observable<boolean> {
    return this.visualizations$.pipe(
      map((visualizations) =>
        visualizations.map((viz) => viz.name).includes(name),
      ),
    );
  }

  processNotification(
    clientId: string,
    roadmapId: string,
    visualizationId: string,
    name: string,
    operationType: string,
  ) {
    if (operationType === 'roadmapvisualization.delete') {
      this.store.dispatch(
        RoadmapVisualizationActions.deleteFromNotif({
          visualizationName: name,
          clientId,
          roadmapId,
          visualizationId,
        }),
      );
    } else if (operationType === 'roadmapvisualization.add') {
      this.store.dispatch(
        RoadmapVisualizationActions.get({
          clientId,
          roadmapId,
          visualizationId,
        }),
      );
    }
  }
}

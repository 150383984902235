import { formatNumber } from '@angular/common';
import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  Renderer2,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';

@Directive({
  selector: 'input[ssoCommify]',
  providers: [
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: CommifyDirective },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CommifyDirective),
      multi: true,
    },
  ],
})
export class CommifyDirective implements ControlValueAccessor {
  private _value: string | null;

  get value(): string | null {
    return this._value;
  }

  @Input() set value(newValue: string | null) {
    this._value = newValue;
    this.displayFormattedValue(this.cleanString(this._value || ''));
  }

  constructor(
    private element: ElementRef<HTMLInputElement>,
    @Inject(LOCALE_ID) private locale: string,
    private renderer: Renderer2,
  ) {}

  @HostListener('focus')
  onFocus() {
    this.displayUnformattedValue(this._value || '');
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    this._value = this.cleanString(value);
    this.updateFormValue(parseFloat(this._value).toString());
  }

  @HostListener('blur')
  onBlur() {
    this.displayFormattedValue(this._value || '');
  }

  private cleanString(numberString: string | null): string | null {
    return numberString ? numberString?.replace(/[^\d.-]/g, '') : numberString;
  }

  private displayUnformattedValue(numberString: string): void {
    this.render(this.cleanString(numberString));
  }

  private displayFormattedValue(numberString: string): void {
    this.render(this.commify(numberString));
  }

  private render(numberString: string): void {
    this.renderer.setProperty(
      this.element.nativeElement,
      'value',
      numberString,
    );
  }

  private updateFormValue(numberString: string): void {
    this.onChange(numberString || '');
  }

  private commify(numberString: string): string {
    if (numberString === '') {
      return '';
    }

    // Consider JavaScripts's max limits on the numbers
    const formattedValue = formatNumber(
      parseFloat(numberString),
      this.locale,
      '1.0-5',
    );

    // handles "∞" return
    return !!parseFloat(formattedValue) ? formattedValue : '0';
  }

  // CVA methods
  onChange(value: any): void {}
  writeValue(newValue: string): void {
    this._value = this.cleanString(newValue);
    this.displayFormattedValue(this._value || '');
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean) {}
}

import { Injectable } from '@angular/core';
import { FluidDemandState } from './fluid-demand.reducer';
import { Store, select } from '@ngrx/store';
import {
  selectLoading,
  selectLoaded,
  selectFluidDemand,
  selectStoreError,
  selectStoreMessage,
  selectExistingContracts,
  selectExistingRenewables,
} from './fluid-demand.selector';
import { FluidDemandActions } from './fluid-demand.actions';
import { ClientFacadeService } from '../client.facade.service';
import { map, mergeMap } from 'rxjs/operators';
import {
  DemandsExistingContracts,
  DemandsExistingRenewables,
  ExistingAsset,
} from './fluid-demand.model';
import {
  ClientDataEntity,
  ExistingContract,
  ExistingRenewable,
} from '../client.model';
import { Observable } from 'rxjs';
import { YearlyValues } from 'ssotool-app/shared/helpers/time-dependent';

@Injectable()
export class FluidDemandFacadeService {
  constructor(
    private store: Store<FluidDemandState>,
    private clientFacadeService: ClientFacadeService,
  ) {}

  loading$ = this.store.pipe(select(selectLoading));
  loaded$ = this.store.pipe(select(selectLoaded));
  error$ = this.store.pipe(select(selectStoreError));
  message$ = this.store.pipe(select(selectStoreMessage));
  fluidDemand$ = (siteId: string, variationId: string, fluidId: string) =>
    this.store.select(
      selectFluidDemand({
        variationId,
        siteId,
        fluidId,
      }),
    );

  getExistingContracts$ = (
    siteIds: string[],
    clientId: string,
  ): Observable<ExistingAsset[]> =>
    this.store
      .select(
        selectExistingContracts({
          siteIds,
        }),
      )
      .pipe(
        mergeMap((contractsFromDemand: DemandsExistingContracts) => {
          return this.clientFacadeService
            .selectExistingContracts$(clientId)
            .pipe(
              map((existingContracts: ClientDataEntity<ExistingContract>) => {
                return this.indexBy(
                  Object.values(existingContracts),
                  'contractId',
                  ['name', 'fluidId', 'contractId', 'geoId'],
                );
              }),
              map(
                (
                  existingContracts: Record<
                    ExistingContract['contractId'],
                    Partial<ExistingContract>
                  >,
                ) => {
                  return this.buildExistingContracts(
                    contractsFromDemand,
                    existingContracts,
                  );
                },
              ),
            );
        }),
      );

  private indexBy(
    items: Record<string, any>,
    key: string,
    properties?: string[],
  ): Record<string, any> {
    return Object.values(items).reduce((indexed, item) => {
      let indexedItem = {};
      if (properties) {
        properties.forEach((property) => {
          indexedItem[property] = item[property];
        });
      } else {
        indexedItem = { ...item };
      }
      indexed[item[key]] = indexedItem;
      return indexed;
    }, {});
  }

  private buildExistingContracts(
    contractsFromDemand: DemandsExistingContracts,
    existingContracts: Record<
      ExistingContract['geoId'],
      Partial<ExistingContract>
    >,
  ): ExistingAsset[] {
    return Object.entries(contractsFromDemand).reduce(
      (
        assets: ExistingAsset[],
        [_, contracts]: [string, DemandsExistingContracts['siteId']],
      ) => {
        return assets.concat(
          Object.entries(contracts).reduce(
            (
              assets: ExistingAsset[],
              [contractId, fluids]: [string, Record<string, YearlyValues>],
            ) => {
              const existingContract = existingContracts[contractId];
              const values = fluids[existingContract.fluidId];
              assets.push({
                ...existingContract,
                assetId: existingContract.contractId,
                values,
              });
              return assets;
            },
            [],
          ),
        );
      },
      [],
    );
  }

  private buildExistingRenewables(
    renewablesFromDemand: DemandsExistingRenewables,
    existingRenewables: Record<string, Partial<ExistingRenewable>>,
  ): ExistingAsset[] {
    return Object.entries(renewablesFromDemand).reduce(
      (
        assets: ExistingAsset[],
        [_, contracts]: [string, DemandsExistingContracts['siteId']],
      ) => {
        return assets.concat(
          Object.entries(contracts).reduce(
            (
              assets: ExistingAsset[],
              [contractId, fluids]: [string, Record<string, YearlyValues>],
            ) => {
              const existingContract = existingRenewables[contractId];
              const values = fluids[existingContract.fluidId];
              assets.push({
                ...existingContract,
                assetId: existingContract.renewableId,
                values,
              });
              return assets;
            },
            [],
          ),
        );
      },
      [],
    );
  }

  getExistingRenewables$ = (siteIds: string[], clientId: string) =>
    this.store
      .select(
        selectExistingRenewables({
          siteIds,
        }),
      )
      .pipe(
        mergeMap((renewablesFromDemand) => {
          return this.clientFacadeService.selectRenewables$(clientId).pipe(
            map((existingRenewables: ClientDataEntity<ExistingRenewable>) => {
              return this.indexBy(existingRenewables, 'renewableId', [
                'name',
                'fluidId',
                'renewableId',
                'geoId',
              ]);
            }),
            map(
              (
                existingRenewables: Record<string, Partial<ExistingContract>>,
              ) => {
                return this.buildExistingRenewables(
                  renewablesFromDemand,
                  existingRenewables,
                );
              },
            ),
          );
        }),
      );

  getFluidDemand(clientId: string): void {
    this.store.dispatch(FluidDemandActions.get({ clientId }));
  }
}

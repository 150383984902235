import { CampaignRenewableProduction } from 'ssotool-app/+campaign/store/campaign.model';
import { YearlyValuesMapper } from 'ssotool-shared';

import { CampaignBaseMapper } from './campaign-base.mapper';

export class RenewableProductionMapper {
  static mapToFrontend = (data: any): CampaignRenewableProduction =>
    !!data
      ? ({
          ...CampaignBaseMapper.mapToFrontend(data),
          scale: data.scale,
          fluidId: data.fluidId,
          processId: data.processId,
          minCapacity: YearlyValuesMapper.mapToFrontend(data.minCapacity),
          maxCapacity: YearlyValuesMapper.mapToFrontend(data.maxCapacity),
          technicalLife: YearlyValuesMapper.mapToFrontend(data.technicalLife),
          depreciationTime: YearlyValuesMapper.mapToFrontend(
            data.depreciationTime,
          ),
          implementationTime: YearlyValuesMapper.mapToFrontend(
            data.implementationTime,
          ),
          buildCo2Scope3Emissions: YearlyValuesMapper.mapToFrontend(
            data.buildCo2Scope3Emissions,
          ),
          indivisibleCo2Scope3Emissions: YearlyValuesMapper.mapToFrontend(
            data.indivisibleCo2Scope3Emissions,
          ),
          buildCost: YearlyValuesMapper.mapToFrontend(data.buildCost),
          indivisibleCost: YearlyValuesMapper.mapToFrontend(
            data.indivisibleCost,
          ),
          foM: YearlyValuesMapper.mapToFrontend(data.foM),
          foMPerInstall: YearlyValuesMapper.mapToFrontend(data.foMPerInstall),
        } as any)
      : {};

  static mapToBackend = (data: CampaignRenewableProduction) =>
    !!data
      ? {
          ...CampaignBaseMapper.mapToBackend(data),
          scale: data.scale,
          fluidId: data.fluidId,
          processId: data.processId,
          minCapacity: YearlyValuesMapper.mapToBackend(data.minCapacity),
          maxCapacity: YearlyValuesMapper.mapToBackend(data.maxCapacity),
          technicalLife: YearlyValuesMapper.mapToBackend(data.technicalLife),
          depreciationTime: YearlyValuesMapper.mapToBackend(
            data.depreciationTime,
          ),
          implementationTime: YearlyValuesMapper.mapToBackend(
            data.implementationTime,
          ),
          buildCo2Scope3Emissions: YearlyValuesMapper.mapToBackend(
            data.buildCo2Scope3Emissions,
          ),
          indivisibleCo2Scope3Emissions: YearlyValuesMapper.mapToBackend(
            data.indivisibleCo2Scope3Emissions,
          ),
          buildCost: YearlyValuesMapper.mapToBackend(data.buildCost),
          indivisibleCost: YearlyValuesMapper.mapToBackend(
            data.indivisibleCost,
          ),
          foM: YearlyValuesMapper.mapToBackend(data.foM),
          foMPerInstall: YearlyValuesMapper.mapToBackend(data.foMPerInstall),
        }
      : {};
}

<div class="x-scroll-bar" fxLayout="row" fxLayoutAlign="end none">
  <div [fxFlex]="leftContentSpace"></div>
  <div [fxFlex]="rightContentSpace">
    <div
      class="content-scroll"
      [id]="contentScrollId"
      [style.max-width.px]="contentWidth"
      [scrollLeft]="contentScrollLeft"
      (scroll)="onScroll($event)"
    >
      <div fxLayout="column" [style.width.px]="contentWidth">
        <div [style.width.px]="contentColumnWidth"></div>
      </div>
    </div>
  </div>
</div>

import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { NAN_VALUE } from 'ssotool-app/app.references';
import { CustomValidators } from 'ssotool-core/utils';

import { coerceNumberProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators,
} from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataType } from '@oculus/components/yearly-forecast';

import {
  Coerce,
  convertToYearlyValues,
  GranularYearlyValueData,
  validateGranularYearlyValues,
  YearlyValues,
} from '../../helpers';

@UntilDestroy()
@Component({
  selector: 'sso-yearly-input',
  templateUrl: './yearly-input.component.html',
  styleUrls: ['./yearly-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: YearlyInputComponent,
      multi: true,
    },
    { provide: NG_VALIDATORS, useExisting: YearlyInputComponent, multi: true },
  ],
})
export class YearlyInputComponent
  implements ControlValueAccessor, Validator, OnInit
{
  @Input() contextHelpMsg: string;
  @Input() errorMessage: string;
  @Input() startYear: string | number;
  @Input() endYear: string | number;
  @Input() isNonNegativeInteger = false;
  @Input() warning = false;
  @Input() warningMessage: string;
  // TODO: update on Fluid's component release
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() mainInput = false;
  @Input() hideRequiredMarker = false;
  @Input() placeholder = '';
  @Input() hint = '';
  @Input() isCustomAction: boolean;
  @Input() granularValueValidatorData: GranularYearlyValueData;
  @Input() noBorderBottom = true;
  @Input() isSingleValueClickable = true;
  @Input() yearlyValueDict = null;
  private noControl = new BehaviorSubject<boolean>(false);
  @Input() set noControlValue(value: YearlyValues) {
    this.noControl.next(!!value);
    this.initializeMultiInputValue(value);
  }

  @Input() set disabled(disabled: boolean) {
    if (!this.inputValueControl) {
      return;
    }

    this.isDisabled = disabled;
    disabled
      ? this.inputValueControl.disable()
      : this.inputValueControl.enable();
  }

  _r: boolean;
  @Input() set readonly(readonly: boolean) {
    this._r = readonly;
  }
  get readonly(): boolean {
    return this._r;
  }

  _l: string;
  @Input() set label(label: string) {
    this._l = label;
  }
  get label(): string {
    return this._l;
  }

  _lng: boolean;
  @Input() set loading(loading: boolean) {
    this._lng = loading;
  }
  get loading(): boolean {
    return this._lng;
  }

  @Input() unit;
  @Output() customClick = new EventEmitter();

  isDisabled = false;
  iconSuffix = 'assessment';
  forecastType = DataType.DICTIONARY;

  multiValueControl = new FormControl({});
  inputValueControl: FormControl = new FormControl('', Validators.required);
  private hasMultipleValues = new BehaviorSubject<boolean>(false);
  hasMultipleValues$ = this.hasMultipleValues.asObservable();
  notMultipleValues$ = this.hasMultipleValues$.pipe(map((multi) => !multi));
  private minYearValue = new BehaviorSubject<string>('');
  minYearValue$ = this.minYearValue.asObservable();
  private maxYearValue = new BehaviorSubject<string>('');
  private readonly opened$ = new Subject<boolean>();
  maxYearValue$ = this.maxYearValue.asObservable();
  private menuOpened = new BehaviorSubject<boolean>(false);
  menuOpened$ = this.menuOpened.asObservable();
  private years = new BehaviorSubject<Array<number>>([]);
  years$ = this.years.asObservable();

  constructor() {}

  _onChange: Function;

  ngOnInit(): void {
    this.initializeYears();
    this.initializeAdditionalValidators();
    this.initializeInputValueChanges();
    this.initializeMultiValueChanges();
  }

  registerOnChange(fn: any): void {
    this._onChange = this.noControl.value ? () => {} : fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(value: YearlyValues): void {
    if (!this.noControl.value) {
      this.initializeMultiInputValue(value);
    }
  }

  validate(_) {
    const isValid = this.hasMultipleValues.value
      ? !this.multiValueControl || this.multiValueControl.valid
      : !this.inputValueControl || this.inputValueControl.valid;
    return isValid ? null : { error: 'Some fields are not fullfilled' };
  }
  openMenu() {
    this.opened$.next(true);
  }

  /**
   * Clears all the value for each year.
   */
  clearValues() {
    /* istanbul ignore else */
    if (this.hasMultipleValues) {
      this.hasMultipleValues.next(false);
      this.inputValueControl.enable({ onlySelf: true, emitEvent: true });
      this.inputValueControl.patchValue('');
      this.inputValueControl.markAsTouched();
      this.inputValueControl.markAsDirty();
    }
  }

  toggleMenu(toggle: boolean = true) {
    this.menuOpened.next(toggle);
  }

  private initializeYears(
    startYear: string | number = this.startYear,
    endYear: string | number = this.endYear,
  ) {
    const start = parseInt(startYear?.toString());
    const end = parseInt(endYear?.toString());
    const years = Array.from({ length: end - start + 1 }, (_, i) => start + i);

    this.years.next(years);
  }

  private initializeAdditionalValidators() {
    const additionalValidators = [];

    if (this.isNonNegativeInteger) {
      additionalValidators.push(CustomValidators.mustBeNonNegativeInteger);
    }

    if (this.granularValueValidatorData) {
      const validator = validateGranularYearlyValues(
        this.granularValueValidatorData,
      );
      additionalValidators.push(validator);
      this.multiValueControl.setValidators(validator);
      this.multiValueControl.updateValueAndValidity({ emitEvent: false });
    }

    this.inputValueControl.setValidators(
      [Validators.required].concat(additionalValidators),
    );

    this.inputValueControl.updateValueAndValidity();
  }

  private initializeInputValueChanges() {
    this.inputValueControl.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe((value) => {
        let updatedValue = value;
        if (!value || value === NAN_VALUE) {
          updatedValue = '';
          this.inputValueControl.patchValue(updatedValue, { emitEvent: false });
        }

        const multiValue = this.setConstantYearlyValues(updatedValue);
        this.multiValueControl.patchValue(multiValue, { emitEvent: false });
        this._onChange(multiValue);
      });
  }

  private initializeMultiValueChanges() {
    this.multiValueControl.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe((value) => {
        this.initializeMultiInputValue(value);
        this._onChange(value);
      });
  }

  /**
   * Sets the value of the inputValueControl and set the hasMultipleValues if needed
   * @param value the yearly values from the control.
   */
  private initializeMultiInputValue(value: YearlyValues) {
    if (!!value) {
      this.multiValueControl.patchValue(value, { emitEvent: false });

      const valueSet = new Set(Coerce.getObjValues(value));
      const valueArray = Array.from(valueSet.values());

      // Remove this for now - [SSO] fix multiple duplicated years when object is partial
      // const yearArray = Array.from(Coerce.getObjKeys(value));
      // const yearNumberArray = yearArray.map((year) => Number(year));
      // this.initializeYears(
      //   Math.min(...yearNumberArray).toString(),
      //   Math.max(...yearNumberArray).toString(),
      // );

      this.extractMinMaxValues(valueArray);

      if (valueSet.size > 1) {
        this.updateMultiInputState();
      } else {
        this.updateSingleInputState(valueArray);
      }
    }
  }

  private extractMinMaxValues(valueArray: string[]) {
    this.minYearValue.next(valueArray[0]);
    this.maxYearValue.next(valueArray[valueArray.length - 1]);
  }

  private updateMultiInputState() {
    this.hasMultipleValues.next(true);
    this.inputValueControl.disable({ onlySelf: true, emitEvent: false });
    this.inputValueControl.patchValue(undefined, { emitEvent: false });
  }

  private updateSingleInputState(valueArray: string[]) {
    this.hasMultipleValues.next(false);
    this.inputValueControl.patchValue(
      valueArray?.length === 1 ? valueArray[0]?.toString() : '',
      { emitEvent: false },
    );
    this.inputValueControl.enable({ onlySelf: true, emitEvent: false });
    this.inputValueControl.markAsTouched();
    this.inputValueControl.markAsDirty();
  }

  /**
   * This method sets the yearly values with the given new value.
   * Replaces the old value with the new value as long as the value param is valid.
   * Otherwise, returns null.
   * @param value new yearly value from the input text field.
   * @param yearlyValues old yearly-value map.
   * @return YearlValues with new value, null if the value is invalid.
   */
  private setConstantYearlyValues(value: string): YearlyValues {
    return convertToYearlyValues(
      value || '',
      coerceNumberProperty(this.startYear),
      coerceNumberProperty(this.endYear),
    );
  }
}


import { skipWhile, take } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-app/+client';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { RoadmapCompareQueryParams } from '../stores';
import { CompareFacadeService } from '../stores/compare/compare-facade.service';

@Injectable()
export class RoadmapCompareResolverService implements Resolve<any> {
  constructor(private clientFacade: ClientFacadeService, private compareFacade: CompareFacadeService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const data = route.queryParams as RoadmapCompareQueryParams;
    this.compareFacade.resetStore();
    this.clientFacade.selectActiveClientData$
      .pipe(
        skipWhile((client) => !client),
        take(1)
      )
      .subscribe((client) => {
        /* istanbul ignore else */
        if (client && client.clientId) {
          // return this.compareFacade.downloadComparisonResult('', 0); // MOCK
          this.compareFacade.getComparisonResult(
            client.clientId,
            data.referenceRoadmapId,
            [].concat(data.selectedRoadmaps)
          );
        }
      });
  }
}

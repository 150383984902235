import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LogoModule } from 'ssotool-app/shared/component';
import { SsoTooltipModule } from 'ssotool-shared/directives/tooltip';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PushPipe, LetModule } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';

import { NavigatableClientModule } from '../navigatable-client';
import { ClientHeaderComponent } from './client-header.component';

@NgModule({
  declarations: [ClientHeaderComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    FlexLayoutModule,
    TranslateModule,
    MatTooltipModule,
    LetModule,
    PushPipe,
    NgxSkeletonLoaderModule,
    LogoModule,
    SsoTooltipModule,
    BrowserAnimationsModule,
    MatBadgeModule,
    NavigatableClientModule,
  ],
  exports: [ClientHeaderComponent],
})
export class ClientHeaderModule {}

import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-client';
import { PermissionChecker, SSOToolRoutePathService } from 'ssotool-shared';

import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  ActivatedRoute,
  ActivationStart,
  NavigationEnd,
  Router,
  UrlSegment,
} from '@angular/router';

// import { DATA_IMPORT_ROUTE } from './entities-routing.module';
import {
  MAIN_ENTITIES_TABS,
  SECONDARY_ENTITIES_TABS,
} from './entities.references';

@Component({
  selector: 'sso-entities-page',
  templateUrl: './entities-page.component.html',
  styleUrls: ['./entities-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntitiesPageComponent implements OnInit, OnDestroy {
  private clientId = '';

  mainEntitiesTabs = MAIN_ENTITIES_TABS.map((_e) => _e.label);
  secondaryEntitiesTabs = SECONDARY_ENTITIES_TABS.map((_e) => _e.label);
  selectedMainTabControl = new FormControl(0);
  selectedSecondaryTabControl = new FormControl(0);

  secondaryTabUrl = new BehaviorSubject<string>('');
  isSecondaryTabsDisplayed = new BehaviorSubject<boolean>(false);

  isIneligibleForComputation$: Observable<boolean> = of(false);

  subscriptions = new Subscription();
  loading$: Observable<boolean> = of(true);

  private isDataImportRoute = new BehaviorSubject<boolean>(false);
  isDataImportRoute$ = this.isDataImportRoute.asObservable();

  showTabs$ = this.isDataImportRoute$.pipe(map((res) => !!!res));

  isAdmin$ = this.clientService.selectActiveClientData$.pipe(
    map((client) => this.permissionChecker.isAdmin(client?.permissions)),
  );

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePath: SSOToolRoutePathService,
    private clientService: ClientFacadeService,
    private permissionChecker: PermissionChecker,
  ) {}

  ngOnInit(): void {
    this.initializeClient();
    this.initializeControlValues();
    this.initializeControlSubscriptions();
    this.initializeRouteSubscription();
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  navigateToImport() {
    this.router.navigate(this.routePath.dataImport(this.clientId));
  }

  private initializeClient() {
    this.clientService.activeClientId$
      .pipe(filter((clientId) => !!clientId))
      .subscribe((clientId) => {
        this.clientId = clientId;
      });
    this.isIneligibleForComputation$ =
      this.clientService.selectIsIneligibleForComputation$(this.clientId);

    this.loading$ = this.clientService.dataLoading$(this.clientId);
  }

  private initializeControlValues() {
    const activeRouteSnapshot = this.activeRoute.snapshot;
    let firstChild = activeRouteSnapshot.firstChild;
    let urlArray = this.parseUrlSegment(activeRouteSnapshot.url).concat(
      this.parseUrlSegment(firstChild.url),
    );

    while (firstChild?.firstChild) {
      firstChild = firstChild.firstChild;
      urlArray = urlArray.concat(this.parseUrlSegment(firstChild.url));
    }

    // this.isDataImportRoute.next(
    //   firstChild.data?.type === DATA_IMPORT_ROUTE.data.type,
    // );

    this.selectedMainTabControl.patchValue(
      MAIN_ENTITIES_TABS.findIndex((_e) => _e.value === firstChild.data?.type),
    );

    if (firstChild.data?.secondary) {
      this.isSecondaryTabsDisplayed.next(true);
      this.getSecondaryTabUrl(urlArray.join('/'));
      this.selectedSecondaryTabControl.patchValue(
        SECONDARY_ENTITIES_TABS.findIndex(
          (_e) => _e.value === firstChild.routeConfig?.path,
        ),
      );
    }
  }

  private initializeControlSubscriptions() {
    this.subscriptions.add(
      this.selectedMainTabControl.valueChanges.subscribe((index) => {
        const routeValue = MAIN_ENTITIES_TABS[index].value;
        this.router.navigate(this.routePath[routeValue](this.clientId));
      }),
    );
    this.subscriptions.add(
      this.selectedSecondaryTabControl.valueChanges.subscribe((index) => {
        const routeValue = SECONDARY_ENTITIES_TABS[index].value;
        this.router.navigateByUrl(
          `${this.secondaryTabUrl.value}/${routeValue}`,
        );
      }),
    );
  }

  private initializeRouteSubscription() {
    this.subscriptions.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          this.isDataImportRoute.next(
            event.url === this.routePath.dataImport(this.clientId).join('/'),
          );
          this.getSecondaryTabUrl(event.urlAfterRedirects);
          if (event.url === this.routePath.entities(this.clientId).join('/')) {
            this.selectedMainTabControl.setValue(0, { emitEvent: false });
          }
        }),
    );
    this.subscriptions.add(
      this.router.events
        .pipe(filter((event) => event instanceof ActivationStart))
        .subscribe((event: ActivationStart) => {
          const isSecondaryTab = event.snapshot.data?.secondary;
          if (!this.isSecondaryTabsDisplayed.value && isSecondaryTab) {
            this.selectedSecondaryTabControl.setValue(0, { emitEvent: false });
          }
          this.isSecondaryTabsDisplayed.next(isSecondaryTab);
        }),
    );
  }

  private getSecondaryTabUrl(url: string) {
    const currentUrl = (url || '').split('/');
    currentUrl.pop();
    this.secondaryTabUrl.next(currentUrl.join('/'));
  }

  private parseUrlSegment(urlSegments: UrlSegment[]) {
    return urlSegments.map((segment) => segment.path);
  }
}

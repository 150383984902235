import {
  BubbleDataPoint,
  Chart,
  ChartMeta,
  ChartTypeRegistry,
  Element,
  Plugin,
  ScatterDataPoint,
} from 'chart.js';
import { merge } from 'chart.js/helpers';

import classes from './classes';
import defaults from './defaults';
import { AnyObject } from 'chart.js/types/basic';

const pieLabelPlugin: Plugin<'pie', AnyObject> = {
  id: defaults.LABEL_KEY,

  afterDatasetUpdate: (
    chart: Chart<
      keyof ChartTypeRegistry,
      (number | ScatterDataPoint | BubbleDataPoint)[],
      unknown
    >,
    args,
    options,
  ) => {
    const datasetIndex = args.index;
    const labels = chart.config.data.labels;
    const visibility = chart.isDatasetVisible(datasetIndex);
    const dataset = chart.data.datasets[datasetIndex];
    const overrideOptions = chart.config.options.plugins[defaults.LABEL_KEY];
    const config = merge({}, [options, defaults, overrideOptions]);
    const meta: ChartMeta<any, any, 'pie'> = args.meta;
    const elements = meta.data || [];
    const ctx = chart.ctx;
    const display = config?.['display'];

    ctx.save();

    elements.forEach((element, dataIndex) => {
      const prevLabel = element[defaults.LABEL_KEY];
      const percent = (dataset.data[dataIndex] as number) / meta.total;
      let label = null;

      if (
        display &&
        visibility &&
        element &&
        chart.getDataVisibility(dataIndex) &&
        !element?.skip
      ) {
        try {
          const context = {
            chart,
            dataIndex,
            dataset,
            labels,
            datasetIndex,
            percent,
          };

          label = new classes.Label(element, dataIndex, ctx, config, context);

          label.$groups = {
            _set: datasetIndex,
            _key: defaults.DEFAULT_KEY,
          };
        } catch (e) {
          label = null;
        }
      }

      if (
        prevLabel &&
        label &&
        label.label === prevLabel.label &&
        label.encodedText === prevLabel.encodedText
      ) {
        label.offset = prevLabel.offset;
      }

      element[defaults.LABEL_KEY] = label;
    });

    ctx.restore();
  },

  afterDatasetDraw: (
    chart: Chart<
      keyof ChartTypeRegistry,
      (number | ScatterDataPoint | BubbleDataPoint)[],
      unknown
    >,
    args,
    options,
  ) => {
    const elements = args.meta.data || [];
    const ctx = chart.ctx;
    var el: Element, label, index;

    for (let i = 0; i < 2 * elements.length; ++i) {
      index = i < elements.length ? i : i - elements.length;

      el = elements[index];
      label = el[defaults.LABEL_KEY];

      if (!label) {
        continue;
      }

      if (i < elements.length) {
        label.update(el, elements, i);
        label.drawLine(ctx);
      } else {
        label.draw(ctx);
      }
    }
  },
};

export { pieLabelPlugin };

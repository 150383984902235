import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ColorPickerDialogData } from './color-picker.model';

@Component({
  selector: 'sso-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit {
  color = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ColorPickerDialogData,
    private dialogRef: MatDialogRef<ColorPickerComponent>,
  ) {}

  ngOnInit(): void {
    this.color = this.data.color;
  }

  onConfirm() {
    this.dialogRef.close({ name: this.data.name, color: this.color });
  }
}

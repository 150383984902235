import { ClientFacadeService } from 'ssotool-app/+client';
import { BindataBaseComponent } from 'ssotool-app/+entities/containers/bindata/bindata-base.component';
import {
  BindataBarChartModel,
  BindataChartValues,
} from 'ssotool-app/+entities/containers/bindata/bindata-chart.model';
import { BinDataFacadeService } from 'ssotool-app/+entities/stores/bindata/bindata-facade.service';
import { BinData } from 'ssotool-app/+entities/stores/bindata/bindata.model';
import { Granularity } from 'ssotool-app/app.references';

import { Directive, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Directive()
export class BindataBarChartBaseComponent
  extends BindataBaseComponent
  implements OnInit
{
  constructor(
    public data: BindataBarChartModel,
    public dialogRef: MatDialogRef<BindataBarChartModel>,
    public binDataFacade: BinDataFacadeService,
    public clientFacade: ClientFacadeService,
  ) {
    super(dialogRef, binDataFacade, clientFacade);
  }

  ngOnInit(): void {
    if (this.data) {
      this.yearlyTabs = this.data.values.map((v) => ({
        startYear: v.startYear,
        endYear: v.endYear,
      }));
      const nonLeapYear = 2019;
      this.startYear =
        this.data.granularity.toLocaleLowerCase() === Granularity.YEARLY
          ? this.data.values[0].startYear
          : nonLeapYear;
      this.activeBin = this.data.values[0] as BindataChartValues;
      this.loadBinaryData(this.data);
      if (this.data.fluidId) {
        this.setQuantityByFluid(this.data.clientId, this.data.fluidId);
      } else if (this.data.quantityId) {
        this.setQuantity(this.data.clientId, this.data.quantityId);
      }
    }
  }

  setYearlyChartValue(bdata: BinData) {
    this.yearlyChartValues$.next({
      name: this.data.name,
      series: bdata.data,
      granularity: this.data.granularity,
      startYear: this.startYear,
    });
  }

  onTabChange(event) {
    this.activeBin = this.data.values[event] as BindataChartValues;
    this.startYear = this.yearlyTabs[event].startYear;
    this.loadBinaryData(this.data);
  }
}

import { throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { SignedUrl } from 'ssotool-app/app.model';
import { download } from 'ssotool-app/shared/services';
import { generateEndpoint } from 'ssotool-core/utils';
import { processDownloadedData } from 'ssotool-shared/services';
import { ConfigService } from 'ssotool-shared/services/config';

import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { ReleaseNotesFacadeService } from '../store/release-notes.facade.service';
import {
  BackendReleaseModel,
  ReleaseModel,
  ReleaseNote,
} from '../store/release-notes.model';
import { TEMPORARY_ID } from '../store/release-notes.reducer';

@Injectable()
export class ReleaseNotesService {
  baseUrl = '';
  endpoints;
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    @Inject(LOCALE_ID) private locale: string,
    private releaseNotesFacade: ReleaseNotesFacadeService,
  ) {
    const { baseUrl, endpoints } = this.config.api;
    this.baseUrl = baseUrl;
    this.endpoints = endpoints;
  }

  get() {
    return this.http.get(this.makeGetEndpoint()).pipe(
      mergeMap((releaseSignedUrl: SignedUrl) =>
        this.http
          .get(releaseSignedUrl.signedUrl, {
            reportProgress: true,
            observe: 'events',
            responseType: 'blob',
          })
          .pipe(
            download(releaseSignedUrl.filesize),
            tap((data) =>
              this.releaseNotesFacade.updateLoadedProgress(data.progress),
            ),
            processDownloadedData<string>(),
            map((rawReleaseNotes: string) => {
              try {
                return this.mapToFrontend(
                  JSON.parse(rawReleaseNotes) as BackendReleaseModel,
                );
              } catch (e) {
                throw e;
              }
            }),
            catchError((error) => throwError(error)),
          ),
      ),
    );
  }

  private mapToFrontend(data: BackendReleaseModel): ReleaseModel {
    return {
      ...data,
      id: TEMPORARY_ID,
      notes: data.notes
        .sort(
          (a, b) => new Date(b?.date).valueOf() - new Date(a?.date).valueOf(),
        )
        .map((note) => ({
          ...note,
          date: this.formatDate(note.date),
        })),
    };
  }

  private makeGetEndpoint() {
    return generateEndpoint(this.baseUrl, this.endpoints.releaseNotes.get);
  }

  private formatDate(dateString: string) {
    return formatDate(dateString, 'longDate', this.locale);
  }

  update(data: ReleaseNote) {
    return this.http
      .put(
        generateEndpoint(this.baseUrl, this.endpoints.releaseNotes.put),
        data,
      )
      .pipe(catchError((error) => throwError(error)));
  }
}

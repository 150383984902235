<div #ganttBar class="bar-container" [style.height.px]="limitHeight">
  <ng-container *ngFor="let currWidth of currWidths; index as i">
    <div
      class="bar"
      *ngIf="hasResult"
      id="currentBar"
      data-testid="currentBar"
      [attr.data-index]="i"
      [class.readonly]="readonly"
      [class.disabled]="disabled"
      [style.height.px]="height"
      [style.width.px]="currWidth"
      [style.left.px]="currLefts[i]"
      [style.background]="data?.color"
      (mousedown)="!disableOutputMovement && onMouseDown($event)"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      ssoTooltip
      [content]="resultTooltip"
      [disabled]="isMoving"
    >
      <div
        id="leftHandler"
        data-testid="leftHandler"
        class="handler"
        (mousedown)="onMouseDown($event)"
        *ngIf="!disableOutputMovement"
      ></div>
      <div
        id="rightHandler"
        data-testid="rightHandler"
        class="handler"
        (mousedown)="onMouseDown($event)"
        *ngIf="!disableOutputMovement"
      ></div>
    </div>
    <div #resultTooltip class="tooltip-card" [ngClass]="{ hide: !hasResult }">
      <ng-container *ngrxLet="tooltipData$ as toolTipInfo">
        <div class="description">
          <div
            style="font-size: medium"
            *ngIf="toolTipInfo.startDates && toolTipInfo.endDates"
          >
            Result: {{ toolTipInfo.startDates[i] }} to
            {{ toolTipInfo.endDates[i] }}
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
<div
  *ngIf="hasLimits"
  class="bar-limits"
  id="limitBar"
  data-testid="limitBar"
  [style.height.px]="limitHeight"
  [style.width.px]="limitWidth"
  [style.left.px]="limitLeft"
  [style.background]="data?.color"
  [style.cursor]="data?.isDraggable ? 'pointer': 'default'"
  ssoTooltip
  [content]="limitTooltip"
  [disabled]="isMoving"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  (mousedown)="onMouseDown($event)"
  [ngClass]="{ 'cursor-default': readonly }"
>
  <div
    id="leftLimitHandler"
    data-testid="leftLimitHandler"
    class="limit-handler left-handler"
    [ngClass]="{ 'cursor-default': readonly }"
    (mousedown)="onMouseDown($event)"
  ></div>
  <div
    id="rightLimitHandler"
    data-testid="rightLimitHandler"
    class="limit-handler right-handler"
    [ngClass]="{ 'cursor-default': readonly }"
    (mousedown)="onMouseDown($event)"
  ></div>
</div>

<div #limitTooltip class="tooltip-card" [ngClass]="{ hide: !hasLimits }">
  <ng-container *ngIf="tooltipData$ | async as toolTipInfo">
    <div
      *ngIf="toolTipHeader$ | async as toolTipHeader"
      [ngClass]="{
        header: true,
        warn: !(toolTipHeader === toolTipInfo.name)
      }"
    >
      {{ toolTipHeader }}
    </div>
    <div class="description">
      <div
        style="font-size: medium"
        *ngIf="toolTipInfo.startLimit && toolTipInfo.endLimit"
      >
        Limits: {{ toolTipInfo.startLimit }} to {{ toolTipInfo.endLimit }}
      </div>
    </div>
  </ng-container>
</div>

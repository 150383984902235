import { YearlyValues } from 'ssotool-app/shared';
import { ExistingContract, ExistingRenewable } from '../client.model';

export const FLUID_DEMAND_FEATURE_NAME = 'Fluid Demand';

export type FluidDemands = {
  [siteId: string]: {
    [variationId: string]: {
      [fluidId: string]: YearlyValues;
    };
  };
};

export type DemandsExistingContracts = {
  [siteId: string]: {
    [contractId: string]: {
      [fluidId: string]: YearlyValues;
    };
  };
};

export type DemandsExistingRenewables = {
  [siteId: string]: {
    [renewableId: string]: {
      [fluidId: string]: YearlyValues;
    };
  };
};

export type ExistingAsset = Partial<ExistingContract | ExistingRenewable> & {
  assetId: string;
  values: YearlyValues;
};

export type FluidDemand = {
  fluid_demands: FluidDemands;
  existing_contracts: DemandsExistingContracts;
  existing_renewables: DemandsExistingRenewables;
};

// export type FluidDemand = Record<string, FluidDemandSiteScope>

// type FluidDemandSiteScope = Record<string, FluidDemandFluidScope>

// type FluidDemandFluidScope = Record<string, YearlyValues>;

export type PartialFluidDemand = Partial<FluidDemand>;

export type BackendFluidDemand = {
  fluid_demands: FluidDemands;
  existing_contracts: DemandsExistingContracts;
  existing_renewables: DemandsExistingRenewables;
};

export type FluidDemandActionParams = {
  clientId?: string;
  variationId?: string;
  siteId?: string;
  siteIds?: string[];
  fluidId?: string;
  contractId?: string;
  renewableId?: string;
};

export type FluidDemandActionParamsWithData = FluidDemandActionParams & {
  data?: FluidDemand;
  message?: string;
  error?: string;
};

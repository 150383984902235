import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BasePortfolioFacadeService } from '../portfolio-facade.service';
import { PortfolioEntityState } from '../portfolio.reducer';
import { CompareEntityActions } from './compare.actions';
import { CompareEntityStore, TEMPORARY_ID } from './compare.reducer';
import {
  selectBaselineId,
  selectCompareCurves,
  selectCompareKpis,
  selectHierarchy,
  selectLevel,
  selectNameMapping,
  selectOptions,
  selectProgress,
  selectReferenceRoadmapId,
} from './compare.selector';

@Injectable()
export class CompareFacadeService extends BasePortfolioFacadeService {
  baselineId = () => this.store.select(selectBaselineId());
  progress = () => this.store.select(selectProgress());
  nameMapper = () => this.store.select(selectNameMapping());
  kpis = () => this.store.select(selectCompareKpis());
  referenceRoadmapId = () => this.store.select(selectReferenceRoadmapId());
  selectHierarchy = () => this.store.select(selectHierarchy());
  selectLevelOptions = (groups: string[]) =>
    this.store.select(selectLevel(groups));
  selectFilterOptions = () => this.store.select(selectOptions());

  constructor(private store: Store<PortfolioEntityState>) {
    super(store);
  }

  /**
   * get api name.
   * @returns name of the compare feature.
   */
  getAPIName(): string {
    return CompareEntityStore.featureName;
  }

  /**
   * Dispatch the action to fetch the comparison results basedon the passed parameters.
   * @param clientId id of client
   * @param referenceRoadmapId id of the roadmap to be referenced.
   * @param roadmapIds other roadmap ids to be compared.
   */
  getComparisonResult(
    clientId: string,
    referenceRoadmapId: string,
    roadmapIds: string[],
  ) {
    return this.store.dispatch(
      CompareEntityActions.getComparisonResultAction({
        clientId,
        referenceRoadmapId,
        roadmapIds,
      }),
    );
  }

  /**
   * Filter and group the curves according to parameters
   * @returns filtered and grouped curves
   */
  selectCurves(kpiType, kpi, curveType, group, level, filters) {
    if (kpi === 'tdc' && curveType === 'stacked_bar') {
      kpi = 'tdc_sk';
    }
    return this.store
      .select(selectCompareCurves(kpiType, kpi, group, level, filters))
      .pipe(map((value) => value));
  }

  /**
   * Dispatches an action to update the current process progress.
   * @param data updated data of the progress
   */
  updateLoadedProgress(data) {
    this.store.dispatch(
      CompareEntityActions.updateLoadedProgressAction({
        id: TEMPORARY_ID,
        progress: data,
      }),
    );
  }

  /**
   * Dispatch an action to download the compare data using the generated signed url.
   * @param signedUrl signed download url of the compare data
   * @param filesize total size of the file
   */
  downloadComparisonResult(signedUrl: string, filesize: number) {
    this.store.dispatch(
      CompareEntityActions.downloadComparisonResultAction({
        signedUrl,
        filesize,
      }),
    );
  }

  /**
   * Reset the state of the store.
   */
  resetStore() {
    this.store.dispatch(CompareEntityActions.resetStoreAction());
  }
}

import { animate, style, transition, trigger, sequence } from '@angular/animations';

export const slideDownAnimation = trigger('slideDown', [
  transition(':enter', [
    style({ transform: 'translateY(-100%)', zIndex: -1 }),
    animate('200ms ease-in', style({ transform: 'translateY(0%)' })),
  ]),
  transition(':leave', [
    style({ transform: 'translateY(0%)', zIndex: -1 }),
    animate('200ms ease-in', style({ transform: 'translateY(-100%)' })),
  ]),
]);

export const bulletAnimation = trigger('bullet', [
  transition(':enter', [
    style({
      height: '*',
      opacity: '0',
      transform: 'translateX(-550px)',
      'box-shadow': 'none',
    }),
    sequence([
      animate(
        '.35s ease',
        style({
          height: '*',
          opacity: '.2',
          transform: 'translateX(0)',
          'box-shadow': 'none',
        }),
      ),
      animate(
        '.35s ease',
        style({ height: '*', opacity: 1, transform: 'translateX(0)' }),
      ),
    ]),
  ]),
  transition(':leave', [
    style({
      height: '*',
      opacity: 1,
      transform: 'translateX(0)',
    }),
    sequence([
      animate(
        '0.1s ease',
        style({
          height: '*',
          opacity: '.2',
          transform: 'translateX(0)',
          'box-shadow': 'none',
        }),
      ),
      animate(
        '.5s ease',
        style({
          height: '*',
          opacity: '0',
          // transform: 'translateX(+550px)',
          'box-shadow': 'none',
        }),
      ),
    ]),
  ]),
]);

import { Coerce } from 'ssotool-app/shared';

export enum ResultExportStage {
  INITIALIZE = 'initialize',
  RETRIEVING = 'retrieving_results',
  PROCESSING = 'process_results',
  EXCEL_GENERATION = 'excel_generate',
  DOWNLOADING = 'excel_download',
}

export namespace ResultExportStage {
  export function canDownload(stage: ResultExportStageMap): boolean {
    return (
      stage.name === ResultExportStage.DOWNLOADING &&
      !!Coerce.toObject(stage.details).signedUrl
    );
  }
}

export enum ResultExportStatus {
  SUCCESS = 'succeeded',
  FAILED = 'failed',
  ONGOING = 'ongoing',
  CANCELLED = 'cancelled',
}

export namespace ResultExportStatus {
  export function canDownload(status: string): boolean {
    return status === ResultExportStatus.ONGOING;
  }

  export function isOngoing(status: string): boolean {
    return status === ResultExportStatus.ONGOING;
  }
}

export type ResultExportProcessingStageDetails = Partial<
  Readonly<{
    name: string;
    done: number;
    total: number;
  }>
>;

export type ResultExportDownloadingStageDetails = Partial<
  Readonly<{
    signedUrl: string;
    filesize: number;
    filename: string;
  }>
>;

export type ResultExportStageMapDetails = ResultExportProcessingStageDetails &
  ResultExportDownloadingStageDetails;

export type ResultExportStageMap = Readonly<{
  name: ResultExportStage;
  details?: ResultExportStageMapDetails;
}>;

export namespace ResultExportStageMap {
  const STAGE_MESSAGE_MAP: Record<ResultExportStage, string> = {
    [ResultExportStage.INITIALIZE]:
      'Roadmap.messages.exportingResults.initialize',
    [ResultExportStage.RETRIEVING]:
      'Roadmap.messages.exportingResults.retrieval',
    [ResultExportStage.PROCESSING]:
      'Roadmap.messages.exportingResults.processing',
    [ResultExportStage.EXCEL_GENERATION]:
      'Roadmap.messages.exportingResults.generation',
    [ResultExportStage.DOWNLOADING]:
      'Roadmap.messages.exportingResults.download',
  };

  export function getMessage(name: ResultExportStage): string {
    return STAGE_MESSAGE_MAP[name];
  }

  export function getDetails(
    stage: ResultExportStageMap,
  ): Record<string, string | number> {
    if (Coerce.toObject(stage).name === ResultExportStage.PROCESSING) {
      const details = Coerce.toObject<ResultExportProcessingStageDetails>(
        stage.details,
      );
      return {
        name: Coerce.toString(details.name),
        done: Coerce.toZero(details.done),
        total: Coerce.toZero(details.total),
      };
    }
  }
}

export type ResultExportModel = Readonly<{
  clientId: string;
  exportId: string;
  roadmapIds: Array<string>;
  status: ResultExportStatus;
  stage?: ResultExportStageMap;
  stages: Array<ResultExportStageMap>;
  filename: string;
  createdAt: string;
  updatedAt: string;
  exporting?: boolean;
  stageMessage?: string;
}>;

export type PartialResultExportModel = Partial<ResultExportModel>;

export type ResultExportNotification = Readonly<{
  code?: number;
  type?: string;
  generated_by?: string;
  clientId: string;
  exportId: string;
  stage: ResultExportStageMap;
  status: ResultExportStatus;
}>;

export type ResultExportNotificationUpdateDetails = Pick<
  ResultExportModel,
  'exportId' | 'stage' | 'status' | 'exporting' | 'stageMessage'
>;

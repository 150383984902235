import { UntilDestroy } from '@ngneat/until-destroy';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { TableCellActionMode } from './table-cell.model';
import { BehaviorSubject } from 'rxjs';
import {
  EDIT_MODE_CLASS,
  KEYS_TO_TRACK,
  READONLY_CLASS,
  READONLY_VALID_CLASS,
  READONLY_INVALID_CLASS,
  DISABLED_CELL,
  SELECT_MODE_CLASS,
  TAB,
  SHIFT_TAB,
  BACKSPACE,
  READONLY_SELECTED_CLASS,
} from 'ssotool-shared/modules/table-cell/table-cell.const';
import { isBlank } from 'ssotool-core/utils/string.util';
import {
  Range,
  Coordinate,
} from 'ssotool-shared/modules/table-input/table-input.model';
import { isBetweenOrEqual } from 'ssotool-core/utils/math.util';

@UntilDestroy()
@Component({
  selector: 'sso-table-cell',
  templateUrl: './table-cell.component.html',
  styleUrls: ['./table-cell.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TableCellComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCellComponent
  implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit
{
  @ViewChild('tableCellElement') cellElement!: ElementRef<HTMLInputElement>;
  private originalValue: string = '';
  private _onChange = (value) => {};

  private _mode = new BehaviorSubject<TableCellActionMode>('readonly');

  private _fillStyleClass: string = READONLY_CLASS;
  private _borderStyleClass: string = '';
  private _selectedRegion: Range;

  currentStyleClass = new BehaviorSubject<string>(READONLY_CLASS);

  @Output() keyDown = new EventEmitter<string>();

  @Input() currentPosition: Coordinate;
  @Input() set selectedRegion(value: Range) {
    this._selectedRegion = value;
    this._isSelected = this.isCoordinateInRange(value, this.currentPosition);
    if (!this._isSelected) {
      this._isEditing = false;
    }
    this.recomputeStyle();
  }
  @Input() isValidValue: boolean;
  @Input() set mode(value: TableCellActionMode) {
    this._mode.next(value);
  }
  @Input() isSelectingCampaigns: boolean = false;

  private _isSelected = false;
  private _isEditing = false;

  get mode() {
    return this._mode.value;
  }

  get selectedRegion() {
    return this._selectedRegion;
  }

  cellInput = new FormControl({
    value: '',
    disabled: this.mode === DISABLED_CELL,
  });

  private isCoordinateWithinSelectedRegion(coordinate: Coordinate): boolean {
    if (!this.selectedRegion) {
      return false;
    }

    return this.isCoordinateInRange(this.selectedRegion, coordinate);
  }

  private isCoordinateInRange(plane: Range, coordinate: Coordinate): boolean {
    if (!plane) {
      return false;
    }

    return (
      isBetweenOrEqual(plane.topLeft.x, plane.bottomRight.x, coordinate.x) &&
      isBetweenOrEqual(plane.topLeft.y, plane.bottomRight.y, coordinate.y)
    );
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(tableCellValue: any): void {
    this.originalValue = tableCellValue;
    this.cellInput.patchValue(
      this.coerceToNumber(
        tableCellValue,
        Math.round(+tableCellValue),
      ).toString(),
      {
        emitEvent: false,
      },
    );
    this.recomputeStyle();
  }

  ngOnInit() {
    this.cellInput.valueChanges.subscribe((value) => {
      const newValue = !Number.isNaN(parseFloat(value)) ? value.toString() : '';
      this.originalValue = newValue;
      this._onChange(this.originalValue);
      this.recomputeStyle();
    });
  }

  ngAfterViewInit() {
    this.observeReadonlyMode();
  }

  ngOnChanges(changes: SimpleChanges) {
    const selectedRegionChanges = changes.selectedRegion;
    const isValidValueChanges = changes.isValidValue;
    const isSelectingCampaignsChanges = changes.isSelectingCampaigns;

    if (selectedRegionChanges && !selectedRegionChanges.firstChange) {
      this.recomputeStyle();
    }

    if (isValidValueChanges) {
      this.recomputeStyle();
    }

    // TO BE REVIEW - Can be convert in to observable and not put in ngOnChanges?
    if (isSelectingCampaignsChanges) {
      this.setDisabledState(isSelectingCampaignsChanges.currentValue);
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.cellInput.disable({ emitEvent: false });
    } else {
      this.cellInput.enable({ emitEvent: false });
    }
  }

  focusCell(highlightCell) {
    this.cellElement.nativeElement.focus();

    if (highlightCell) {
      this.cellElement.nativeElement.select();
    }
  }

  unFocusCell() {
    this.cellElement.nativeElement.blur();
  }

  selectCell() {
    if (this.mode === DISABLED_CELL) {
      this.recomputeStyle();
      return;
    }

    if (this._isSelected) {
      this.mode = 'edit' as TableCellActionMode;
      this._isEditing = true;
    } else {
      this._isEditing = false;
    }
    this.recomputeStyle();
  }

  editCell() {
    if (this.mode === DISABLED_CELL) {
      return;
    }

    this._isEditing = true;
    this.mode = 'edit' as TableCellActionMode;
    this.recomputeStyle();
  }

  unselectCell() {
    if (this.mode === DISABLED_CELL) {
      return;
    }

    this._isSelected = false;
    this._isEditing = false;
    this.mode = 'readonly' as TableCellActionMode;
    this.recomputeStyle();
  }

  onKeyDown(event: KeyboardEvent) {
    if (KEYS_TO_TRACK.includes(event.key)) {
      this.unselectCell();
      let keyPressed = event.key;

      if (TAB === event.key && event.shiftKey) {
        keyPressed = SHIFT_TAB;
      }

      this.keyDown.emit(keyPressed);
      event.preventDefault();
    } else if (
      event.key === BACKSPACE &&
      this._isSelected &&
      !this._isEditing &&
      this.mode !== DISABLED_CELL
    ) {
      this.cellInput.patchValue('');
      event.preventDefault();
    }
  }

  private observeReadonlyMode() {
    const observer = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'readonly') {
          if ((mutation.target as HTMLInputElement).readOnly) {
            // for display purposes, round the value
            this.cellInput.patchValue(
              this.coerceToNumber(
                this.cellInput.value,
                Math.round(+this.cellInput.value),
              ).toString(),
              {
                emitEvent: false,
              },
            );
          } else {
            this.cellInput.patchValue(this.originalValue, { emitEvent: false });
          }
          this.recomputeStyle();
        }
      });
    });

    observer.observe(this.cellElement.nativeElement, {
      attributes: true,
    });
  }

  private coerceToNumber(value: string, fallback: number): '' | number {
    if (value === '' || value === undefined || value === null) {
      return '';
    }
    return fallback;
  }

  // reevaluates the style of the cell
  private recomputeStyle(): void {
    this._borderStyleClass =
      this._isSelected && this.mode !== DISABLED_CELL ? SELECT_MODE_CLASS : '';

    this._borderStyleClass =
      this._isSelected && this.mode === DISABLED_CELL
        ? READONLY_SELECTED_CLASS
        : this._borderStyleClass;

    this._fillStyleClass = this.getFillStyleClass();
    this.currentStyleClass.next(
      `${this._borderStyleClass} ${this._fillStyleClass}`,
    );
  }

  // determines the fill style of the cell
  private getFillStyleClass(): string {
    if (this.cellInput.status === 'DISABLED' || this.mode === DISABLED_CELL) {
      return READONLY_CLASS;
    }

    if (this._isEditing && !this.isValidValue) {
      return READONLY_INVALID_CLASS;
    }

    if (this._isEditing) {
      return EDIT_MODE_CLASS;
    }

    if (this.isValidValue && !isBlank(this.cellInput.value.toString())) {
      return READONLY_VALID_CLASS;
    }

    if (!this.isValidValue) {
      return READONLY_INVALID_CLASS;
    }

    if (
      this.mode === 'readonly' &&
      this.isCoordinateWithinSelectedRegion(this.currentPosition)
    ) {
      return READONLY_SELECTED_CLASS;
    }

    return READONLY_CLASS;
  }
}

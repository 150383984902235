<form>
  <ng-container>
    <sso-profiles-form
      [details]="details"
      [formControl]="profilesForm"
      [mode]="mode$ | ngrxPush"
      data-testid="profile-form"
    ></sso-profiles-form>
  </ng-container>
  <div
    class="drawer-actions"
    data-testid="profiles-drawer-actions"
    fxLayout="row"
    fxLayoutGap="5px"
    fxLayoutAlign="end center"
  >
    <button
      (click)="onClose()"
      data-testid="close-button"
      mat-stroked-button
      *ngIf="data?.readonly; else drawerActions"
      type="button"
    >
      {{ 'Generic.labels.close' | translate | titlecase }}
    </button>
    <ng-template #drawerActions>
      <div [ngSwitch]="mode$ | ngrxPush" class="full-width">
        <ng-container *ngSwitchCase="'view'">
          <div
            class="full-width"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <button
                (click)="onEdit()"
                data-testid="edit-button"
                mat-stroked-button
                [disabled]="loading$ | ngrxPush"
                color="primary"
                type="button"
              >
                {{ 'Generic.labels.edit' | translate | titlecase }}
              </button>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
              <button
                (click)="onDelete()"
                data-testid="delete-button"
                [disabled]="loading$ | ngrxPush"
                mat-stroked-button
                color="warn"
                type="button"
              >
                {{ 'Generic.labels.delete' | translate | titlecase }}
              </button>
              <button
                (click)="onClose()"
                data-testid="close-button"
                mat-stroked-button
                color=""
                type="button"
              >
                {{ 'Generic.labels.close' | translate | titlecase }}
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'edit'">
          <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
            <button
              data-testid="edit-button"
              (click)="onBack()"
              mat-stroked-button
              color=""
              type="button"
            >
              {{ 'Generic.labels.back' | translate | titlecase }}
            </button>
            <button
              data-testid="update-button"
              mat-stroked-button
              color="primary"
              [disabled]="loading$ | ngrxPush"
              (click)="onConfirm()"
              type="button"
            >
              {{ 'Generic.labels.update' | translate | titlecase }}
            </button>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'create'">
          <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
            <button
              data-testid="create-button"
              mat-stroked-button
              color="primary"
              [disabled]="loading$ | ngrxPush"
              (click)="onConfirm()"
              type="button"
            >
              {{ 'Generic.labels.create' | translate | titlecase }}
            </button>
            <button
              (click)="onClose()"
              data-testid="close-button"
              mat-stroked-button
              color=""
              type="button"
            >
              {{ 'Generic.labels.close' | translate | titlecase }}
            </button>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </div>
</form>

<div fxLayout="column" fxLayoutAlign="start center">
  <mat-progress-spinner
    [class]="sizeClass"
    [color]="color"
    [mode]="mode"
    [value]="value$ | async"
    [diameter]="loaderSize?.diameter"
    [strokeWidth]="loaderSize?.strokeWidth"
  ></mat-progress-spinner>
  <div
    *ngIf="loaderSize?.insideText; else dropText"
    class="content"
    [style.max-width.px]="contentSize"
    [style.max-height.px]="contentSize"
    [style.height.px]="contentSize"
    [style.top.px]="contentTop"
    [style.font-size]="loaderSize?.fontSize"
  >
    {{ text }}
  </div>
  <ng-template #dropText>
    <div class="drop-content">
      {{ text }}
    </div>
  </ng-template>
</div>

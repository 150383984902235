import { TabbedAreaChartModule } from 'ssotool-app/shared/component/tabbed-area-chart';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { FlowPricessHourlyDialogComponent } from './flow-prices-hourly-dialog.component';

@NgModule({
  declarations: [FlowPricessHourlyDialogComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    TabbedAreaChartModule,
  ],
  exports: [FlowPricessHourlyDialogComponent],
})
export class FlowPricesHourlyDialogModule {}

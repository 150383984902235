<div
  class="container"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="16px"
>
  <ng-select
    *ngFor="let group of optionGroups$ | ngrxPush"
    [items]="group['options']"
    bindLabel="name"
    bindValue="id"
    [searchable]="true"
    [placeholder]="
      'Entities.columns.' + group['placeholder'] | translate | titlecase
    "
    [multiple]="group['multiple']"
    [closeOnSelect]="!group['multiple']"
    (change)="onSelectionChange($event, group)"
  ></ng-select>
</div>

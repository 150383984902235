export const EDIT_MODE_CLASS = 'edit-mode';
export const EDIT_MODE_INVALID_CLASS = 'edit-mode-invalid';
export const SELECT_MODE_CLASS = 'select-mode';
export const READONLY_VALID_CLASS = 'readonly-valid';
export const READONLY_SELECTED_CLASS = 'readonly-selected';
export const READONLY_INVALID_CLASS = 'readonly-invalid';
export const READONLY_CLASS = 'readonly';
export const DISABLED_CELL = 'disabled-mode';

export const TAB = 'Tab';
export const ARROW_DOWN = 'ArrowDown';
export const ARROW_UP = 'ArrowUp';
export const ARROW_LEFT = 'ArrowLeft';
export const ARROW_RIGHT = 'ArrowRight';
export const ENTER = 'Enter';
export const SHIFT_TAB = 'Shift+Tab';
export const BACKSPACE = 'Backspace';

export const KEYS_TO_TRACK = [
  TAB,
  ARROW_DOWN,
  ARROW_LEFT,
  ARROW_UP,
  ARROW_RIGHT,
  ENTER,
];

import { CanDeactivateGuard } from 'ssotool-app/core';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DataImportAPIService } from './services/data-import/data-import.service';
import {
  DataImportEffects,
  DataImportFacadeService,
  DataImportStore,
} from './stores';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([DataImportEffects]),
    StoreModule.forFeature(
      DataImportStore.featureName,
      DataImportStore.reducer,
    ),
  ],
  providers: [
    CanDeactivateGuard,
    DataImportFacadeService,
    DataImportAPIService,
  ],
})
export class DataManagementRootModule {}

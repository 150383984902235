import pptxgen from 'pptxgenjs';
import {
  ChartInterpretation,
  ChartType,
} from 'ssotool-app/+roadmap/modules/results/analysis/curves/kpi-curve-v2';
import {
  BarBlock,
  Coerce,
  ColorSchemeService,
  PieMetadata,
  StackedBarMetadata,
  WaterfallMetadata,
} from 'ssotool-shared';

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ChartData, ChartDetails } from './kpi-curve-export.model';
import { BASE_FILENAME, ICHART_OPTIONS } from './kpi-curve-export.references';

@Injectable()
export class KPICurveExporterService {
  private exporter: pptxgen = new pptxgen();

  private mapToChartExporter: Record<
    ChartType,
    (chart: ChartInterpretation) => ChartData
  > = {
    [ChartType.STACKED_BAR]: this.exportStackedBar.bind(this),
    [ChartType.STACKED_BAR_RELATIVE]: this.exportStackedBar.bind(this),
    [ChartType.PIE]: this.exportPie.bind(this),
    [ChartType.WATERFALL]: this.exportWaterfall.bind(this),
    [ChartType.SANKEY]: (chart) => null,
  };

  private mapToChartOptions: Record<
    ChartType,
    (chart: ChartInterpretation, kpi: string) => pptxgen.IChartOpts
  > = {
    [ChartType.STACKED_BAR]: this.createStackedBarOptions.bind(this),
    [ChartType.STACKED_BAR_RELATIVE]: this.createStackedBarOptions.bind(this),
    [ChartType.PIE]: this.createPieOptions.bind(this),
    [ChartType.WATERFALL]: this.createWaterfallOptions.bind(this),
    [ChartType.SANKEY]: (chart, kpi) => null,
  };

  private mapChartType: Record<ChartType, pptxgen.ChartType> = {
    [ChartType.STACKED_BAR]: this.exporter.ChartType.bar,
    [ChartType.STACKED_BAR_RELATIVE]: this.exporter.ChartType.bar,
    [ChartType.PIE]: this.exporter.ChartType.pie,
    [ChartType.WATERFALL]: this.exporter.ChartType.bar,
    [ChartType.SANKEY]: null,
  };

  constructor(
    private colorSchemer: ColorSchemeService,
    private translateService: TranslateService,
  ) {}

  export(chart: ChartInterpretation, options: ChartDetails) {
    this.exporter = new pptxgen();
    const { title, kpi, curveType } = options;

    const data = this.mapToChartExporter[curveType](chart);
    const opts = this.mapToChartOptions[curveType](chart, kpi);

    const slide = this.exporter.addSlide();

    this.attachSlideTitle(slide, title);
    slide.addChart(
      this.mapChartType[curveType] as
        | pptxgen.CHART_NAME
        | pptxgen.IChartMulti[],
      this.fixData(data),
      opts,
    );

    this.exporter.writeFile({
      fileName: BASE_FILENAME + curveType,
    });
  }

  // tempfix for bug SSO-8961
  private fixData(data: ChartData): ChartData {
    return data.length == 1
      ? [
          { ...data[0], _dataIndex: 0 },
          { name: '', labels: [], values: [], _dataIndex: 1 },
        ]
      : data;
  }

  // type isn't resolving until typescript update
  private attachSlideTitle(slide: pptxgen.Slide, leftTitle: string) {
    slide.addTable([
      [
        {
          text: leftTitle,
          options: {
            color: '9F9F9F',
            margin: 3,
            border: [
              null,
              null,
              {
                pt: 1,
                color: 'CFCFCF',
              },
              null,
            ],
          },
        },
        {
          text: 'SSo Tool',
          options: {
            color: '9F9F9F',
            margin: 3,
            border: [
              null,
              null,
              {
                pt: 1,
                color: 'CFCFCF',
              },
              null,
            ],
            align: 'right',
          },
        },
      ],
    ]);
  }

  // STACKED BAR
  exportStackedBar(chart: StackedBarMetadata): ChartData {
    const { bars } = chart;

    return bars
      .reduce((acc, bar) => {
        bar.stacks.forEach((barStack) => {
          barStack.blocks.forEach((barBlock) => {
            this.insertBarBlock(acc, barStack.name, barBlock);
          });
        });
        return acc;
      }, [])
      .filter((bar) =>
        Coerce.toArray(Coerce.toEmptyObject(bar).values).some(
          (value) => value !== 0,
        ),
      );
  }

  private insertBarBlock(
    bars: ChartData,
    stackName: string,
    barBlock: BarBlock,
  ) {
    if (!!!this.isInBars(bars, barBlock)) {
      bars.push({
        name: barBlock.name,
        labels: [],
        values: [],
      });
    }

    this.insertToBars(bars, stackName, barBlock);
  }

  private isInBars(bars: ChartData, barBlock: BarBlock): boolean {
    return !!bars.find((bar) => bar.name === barBlock.name);
  }

  private insertToBars(bars: ChartData, stackName: string, barBlock: BarBlock) {
    let bar = bars.find((bar) => bar.name === barBlock.name);
    bar.labels.push(stackName);
    bar.values.push(barBlock.value);
  }

  private createStackedBarOptions(
    chart: StackedBarMetadata,
    kpi: string,
  ): Record<string, any> {
    return {
      ...ICHART_OPTIONS,
      barDir: this.convertBarDirection(chart.orientation),
      barGrouping: 'stacked',
      chartColors: this.colorSchemer.getBaseColors(),
      // CHART TITLE
      showTitle: true,
      title: this.translateService.instant(`Portfolio.titles.${kpi}`),
      // titleFontFace: '',
      // titleFontSize: 0,
      // titleColor: '',
      // titlePos is bugged until pptxgenjs@3.11
      // titlePos: { x: 1.5, y: 0 },
      // VERTICAL AXIS
      showValAxisTitle: true,
      // valAxisLabelColor: '',
      // valAxisTitleColor: '',
      valAxisTitle: chart.unit,
      // valAxisTitleFontSize: 0,
    };
  }

  private convertBarDirection(
    orientation: StackedBarMetadata['orientation'],
  ): string {
    return orientation === 'vertical' ? 'col' : 'bar';
  }

  // WATERFALL
  /**
   * The first barDatum in the data set will be used as the 'floaters'.
   * @param chart
   * @returns
   */
  exportWaterfall(chart: Array<WaterfallMetadata>): ChartData {
    const metadata = chart[0];

    const labels = metadata.stacks
      .filter((stack, index) => index === 0 || !!stack.blocksTotalValue)
      .map((stack) => stack.name);

    return metadata.stacks.slice(0, labels.length).reduce<ChartData>(
      (barData, stack, index) => {
        if (index === 0) {
          barData.push({
            name: null,
            labels,
            values: [stack.blocksTotalValue, 0, 0, 0],
          });
        } else {
          barData[0].values.push(
            (index === 1
              ? barData[1].values[0]
              : barData[0].values[index - 1]) - stack.blocksTotalValue,
          );
          barData.push({
            name: null,
            labels,
            values: labels.map((label) =>
              label === stack.name ? stack.blocksTotalValue : 0,
            ),
          });
        }

        return barData;
      },
      [
        {
          name: null,
          labels,
          values: [0],
        },
      ] as ChartData,
    );
  }

  /**
   * The 'floaters' color is the transparent one.
   * @param chart
   * @param kpi
   * @returns
   */
  private createWaterfallOptions(
    chart: Array<WaterfallMetadata>,
    kpi: string,
  ): pptxgen.IChartOpts {
    return {
      ...ICHART_OPTIONS,
      barDir: 'col',
      barGrouping: 'stacked',
      chartColors: ['transparent'].concat(this.colorSchemer.getBaseColors()),
      showTitle: true,
      title: this.translateService.instant(`Portfolio.titles.${kpi}`),
      showValAxisTitle: true,
      valAxisTitle: chart[0].unit,
    };
  }

  // PIE
  exportPie(chart: Array<PieMetadata>): ChartData {
    const metadata = chart[0];
    return [
      metadata.slices.reduce(
        (pieData, slice) => {
          const value = Coerce.toStandardFloat(slice.value);
          if (value) {
            pieData.labels.push(slice.name);
            pieData.values.push(value);
          }

          return pieData;
        },
        {
          name: null,
          labels: [],
          values: [],
        },
      ),
    ];
  }

  private createPieOptions(chart: Array<PieMetadata>, kpi: string) {
    const metadata = chart[0];
    return {
      ...ICHART_OPTIONS,
      chartColors: this.colorSchemer.getBaseColors(),
      showTitle: true,
      title:
        this.translateService.instant(`Portfolio.titles.${kpi}`) +
        ` (${metadata.unit})`,
      showLeaderLines: true,
      showPercent: true,
      showValue: false,
      legendPos: 'l',
      legendFontFace: 'Courier New',
      showLegend: true,
    };
  }
}

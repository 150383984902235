import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { ProfileActions } from './profile.actions';

export const profileFeatureKey = 'Profiles';
export interface ProfileState extends EntityState<any> {
  loading: boolean;
  loaded: boolean;
  error: string;
  message: string;
}

export const selectProfileId = (profile: any) => profile?.id;
export const sortName = (profile1: any, profile2: any) =>
  profile1?.name?.localeCompare(profile2?.name);

export const profileAdapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: selectProfileId,
  sortComparer: sortName,
});

export const initialProfileState: ProfileState = profileAdapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: undefined,
    message: undefined,
  },
);

const profileReducer = createReducer(
  initialProfileState,

  on(ProfileActions.getProfiles, (state) => {
    return { ...state, loading: true, error: undefined };
  }),
  on(ProfileActions.getProfilesSuccess, (state, { message }) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: undefined,
      message,
    };
  }),
  on(ProfileActions.getProfilesFailure, (state, { error, message }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
      message,
    };
  }),

  on(ProfileActions.getProfile, (state) => {
    return { ...state, loading: true, error: undefined };
  }),

  on(ProfileActions.deleteProfile, (state) => {
    return { ...state, loading: true };
  }),
  on(ProfileActions.deleteProfileSuccess, (state) => {
    return { ...state, loading: false };
  }),
  on(ProfileActions.deleteProfileFailure, (state) => {
    return { ...state, loading: false };
  }),

  on(ProfileActions.upsertProfile, (state) => {
    return { ...state, loading: true };
  }),
  on(ProfileActions.upsertProfileSuccess, (state) => {
    return { ...state, loading: false };
  }),
  on(ProfileActions.upsertProfileFailure, (state) => {
    return { ...state, loading: false };
  }),
);

export function ProfileReducer(
  state: ProfileState | undefined,
  action: Action,
) {
  return profileReducer(state, action);
}

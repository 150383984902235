import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  releaseNoteFeatureKey,
  releaseNotesAdapter,
  ReleaseNotesState,
  TEMPORARY_ID,
} from './release-notes.reducer';

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = releaseNotesAdapter.getSelectors();

export const releaseNotesFeatureState = createFeatureSelector<
  ReleaseNotesState
>(releaseNoteFeatureKey);

export const releaseNotesEntities = createSelector(
  releaseNotesFeatureState,
  selectEntities,
);

const mainEntity = createSelector(
  releaseNotesEntities,
  (state) => state[TEMPORARY_ID],
);

export const loading = createSelector(
  releaseNotesFeatureState,
  (state) => state.loading,
);

export const loaded = createSelector(
  releaseNotesFeatureState,
  (state) => state.loaded,
);

export const progress = createSelector(
  releaseNotesFeatureState,
  (state) => state.progress,
);

export const latestVersion = createSelector(
  mainEntity,
  (release) => release?.version || '',
);

export const notes = createSelector(
  mainEntity,
  (release) => release?.notes || [],
);

<div class="sso-page" fxLayout="column">
  <!-- Refactor to put the actions or make the table the main page -->
  <sso-progress-indicator
    mode="indeterminate"
    [displayCancelButton]="displayCancelExport$ | async"
    [cancelButtonMessage]="'Campaign.labels.export.cancelExport' | translate"
    (cancelClick)="onCancelExport()"
    [message]="
      (exportStatus$ | async) ||
      ('Campaign.messages.exportingCampaigns.indicator' | translate)
    "
    icon="info"
    data-testid="progress-indicator"
    *ngIf="exporting$ | async"
  ></sso-progress-indicator>
  <div class="table-container">
    <sso-campaign-table
      data-testid="campaign-table"
      [clientId]="clientId"
      [data]="data$ | async"
      [loading]="loading$ | async"
      [isPermitted]="isAdmin$ | async"
      [hasOngoingExport]="hasOngoingExport$ | async"
      [hasOngoingImport]="hasOngoingImport$ | async"
      [ineligibleCampaigns]="isIneligibleForComputation$ | async"
      (delete)="onDelete($event)"
      (deleteMulti)="deleteMultiple($event)"
      (add)="onAdd()"
      (populate)="onPopulateWithLibrary()"
      (duplicate)="onDuplicate($event)"
      (batchDuplicate)="onBatchDuplicate($event)"
      (export)="onExport($event)"
      (openImport)="onOpenImport()"
      (rowClick)="openDrawer($event)"
      [clearSelection]="clearSelection$ | async"
    >
    </sso-campaign-table>
  </div>
</div>

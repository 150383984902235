import { combineLatest, iif, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ClientFacadeService, Hierarchy } from 'ssotool-app/+client';
import {
  DetailDataParameters,
  ResultFiltersService,
} from 'ssotool-app/+roadmap/utilities';
import { ResultParserService } from 'ssotool-app/+roadmap/utilities/result/result-parser.service';
import { CAMPAIGN_ENTITY } from 'ssotool-app/app.references';
import { swapObjectKeyAndValue } from 'ssotool-core/utils';
import { Coerce, FilterWithCondition } from 'ssotool-shared';

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { KPIType, PortfolioEntity } from '../portfolio.model';
import { PortfolioEntityState } from '../portfolio.reducer';
import { ResultLocalStorageService } from './result-local-storage.service';
import { ResultGetParameters } from './result-parameters.model';
import { ResultEntityActions } from './result.actions';
import {
  BackendBaselineIndicators,
  BaselineIndicators,
  CurveEntity,
  HierarchicalFilter,
  HierarchicalFilters,
  PortfolioAnalysis,
  PortfolioCurveEntity,
  ProjectDetails,
  Result,
  ResultDetails,
  ResultEntity,
  ResultFilterOptions,
  SankeyData,
  WorldMapData,
} from './result.model';
import { ResultEntityStore } from './result.reducer';
import {
  selectGrouped,
  selectResultLoaded,
  selectResultLoading,
  selectResultVariationLoaded,
  selectResultVariationLoading,
  selectResultVariationProgress,
  selectSankeyData,
  selectSankeyGeographies,
  selectSankeyYears,
  selectUnits,
} from './result.selector';
import { isFeatureEnabled } from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';

export type CurveDataParameters = Readonly<{
  kpi: string;
  kpiType: KPIType;
  filters: FilterWithCondition;
}>;

@Injectable()
export class ResultFacadeService {
  private resultActions;

  constructor(
    private store: Store<PortfolioEntityState>,
    private clientFacade: ClientFacadeService,
    private localStorage: ResultLocalStorageService,
    private filtersService: ResultFiltersService,
    private parserService: ResultParserService,
  ) {
    this.resultActions = ResultEntityActions(this.getAPIName());
  }

  private getStateStream(): Observable<PortfolioEntityState> {
    return this.store.select((state) => state[this.getAPIName()]);
  }

  getDataStream(id: string): Observable<any> {
    return this.getStateStream().pipe(map((state) => state.dataMap[id]));
  }

  get(params: ResultGetParameters) {
    this.store.dispatch(this.resultActions.getAction(params));
  }

  getResultVariationLoading(roadmapId: string, variationId: string) {
    return this.store.select(
      selectResultVariationLoading({ roadmapId, variationId }),
    );
  }

  getResultVariationLoaded(roadmapId: string, variationId: string) {
    return this.store.select(
      selectResultVariationLoaded({ roadmapId, variationId }),
    );
  }

  getResultVariationProgress(roadmapId: string, variationId: string) {
    return this.store.select(
      selectResultVariationProgress({ roadmapId, variationId }),
    );
  }

  getResultLoading(id: string) {
    return this.store.select(selectResultLoading({ id }));
  }

  getResultLoaded(id: string) {
    return this.store.select(selectResultLoaded({ id }));
  }

  getMainResult(id: string, variationId: string) {
    return this.getResultVariationLoaded(id, variationId).pipe(
      mergeMap(() => this.localStorage.getMainResultStream(id, variationId)),
    );
  }

  /**
   * Dispatch an action to fetch baseline indicators.
   * @param clientId id of the client.
   * @param roadmapId id of the selected roadmap.
   */
  getBaselineIndicators(clientId: string, roadmapId: string) {
    this.store.dispatch(
      this.resultActions.getBaselineIndicatorsAction({ clientId, roadmapId }),
    );
  }

  /**
   * Dispatch an action to fetch multiple baseline indicators.
   * @param clientId id of the client.
   */
  getMultipleBaselineIndicators(clientId: string) {
    this.store.dispatch(
      this.resultActions.getMultipleBaselineIndicatorsAction({ clientId }),
    );
  }

  /**
   * Dispatch and action to update/appends the baseline indicators.
   * @param data baseline indicators data to be appended in the result.
   */
  updateBaselineIndicators(data: BackendBaselineIndicators) {
    this.store.dispatch(
      this.resultActions.updateBaselineIndicatorsAction({
        data,
      }),
    );
  }

  /**
   * get api name.
   * @returns name of the result feature.
   */
  getAPIName(): string {
    return ResultEntityStore.featureName;
  }

  // CURVES
  /**
   * Select a specific curve based on the data being passed.
   * @param roadmapId - id of a roadmap.
   * @param kpiType - type of the kpi.
   * @param kpi - specific kpi selected.
   * @param group - selected grouping for display.
   * @param level - selected level for display.
   * @param filters - list of selected filters.
   * @returns curve entity.
   */
  selectCurve(
    roadmapId: string,
    variationId: string,
    kpiType,
    kpi,
    splitBy: HierarchicalFilter,
    enumerateBy: HierarchicalFilter,
    filters,
  ): Observable<CurveEntity> {
    return this.clientFacade.activeClientId$.pipe(
      mergeMap((clientId) =>
        combineLatest([
          this.clientFacade.selectGeoHierarchy$(clientId),
          this.clientFacade.selectSites$(clientId),
          this.clientFacade.selectCompanyHierarchy$(clientId),
          this.getCurveData(clientId, roadmapId, variationId, {
            kpi,
            kpiType,
            filters,
          }),
        ]).pipe(
          mergeMap(([geography, sites, entity, filteredData]) => {
            const transformedSites = { Site: {} };
            Object.values(sites).forEach((site) => {
              if (!transformedSites.Site[site.name]) {
                transformedSites.Site[site.name] = '';
              }
            });

            if (isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE)) {
              geography = { ...geography, ...transformedSites };
            } else {
              geography = { ...geography };
            }

            return this.store.pipe(
              select(
                selectGrouped(
                  filteredData,
                  {
                    splitBy,
                    enumerateBy,
                  } as HierarchicalFilters,
                  {
                    geography,
                    entity,
                  },
                ),
              ),
            );
          }),
        ),
      ),
    );
  }

  private getCurveData(
    clientId: string,
    roadmapId: string,
    variationId: string,
    { kpi, kpiType, filters }: CurveDataParameters,
  ) {
    return this.getCurveKpiSection(roadmapId, variationId, kpiType, kpi).pipe(
      mergeMap((data) =>
        this.filtersService.filterPortfolioCurve(clientId, data, filters),
      ),
    );
  }

  private getCurveKpiSection(
    roadmapId: string,
    variationId: string,
    kpiType: KPIType,
    kpi: string,
  ): Observable<PortfolioCurveEntity> {
    const mainResult = this.getMainResult(roadmapId, variationId).pipe(
      map((data) => data?.curves?.[kpiType]?.[kpi] || []),
    );
    // c.log('mainResult:', mainResult);
    return mainResult;
  }

  /**
   * Select the filters to be displayed and parts of the options.
   * @param roadmapId - id of the roadmap
   * @param kpiType - type of kpi.
   * @param kpi - specific kpi selected.
   * @param activeFilters - type of filters to be displayed.
   * @returns filter options.
   */
  selectFilter(
    roadmapId: string,
    variationId: string,
    activeFilters: string[],
  ): Observable<ResultFilterOptions> {
    return combineLatest([
      this.clientFacade.activeClientId$,
      this.getMainResult(roadmapId, variationId),
    ]).pipe(
      mergeMap(([id, data]) =>
        this.filtersService.getResultFilterOptions(
          id,
          data?.filterOptions,
          activeFilters,
        ),
      ),
    );
  }

  // SANKEY
  selectSankeyGeoOptions(
    roadmapId: string,
    variationId: string,
  ): Observable<string[]> {
    return this.localStorage
      .getMainResultStream(roadmapId, variationId)
      .pipe(
        mergeMap((data) =>
          this.store.pipe(select(selectSankeyGeographies(data))),
        ),
      );
  }

  selectSankeyYearOptions(
    roadmapId: string,
    variationId: string,
  ): Observable<string[]> {
    return this.localStorage
      .getMainResultStream(roadmapId, variationId)
      .pipe(
        mergeMap((data) => this.store.pipe(select(selectSankeyYears(data)))),
      );
  }

  selectSankeyData(
    roadmapId: string,
    variationId: string,
    geography: string,
    year: string,
  ): Observable<SankeyData[]> {
    return this.localStorage
      .getMainResultStream(roadmapId, variationId)
      .pipe(
        mergeMap((data) =>
          this.store.pipe(select(selectSankeyData(data, geography, year))),
        ),
      );
  }

  // DETAILS
  /**
   * Retrieves the detailed data of the selected entity
   * @param roadmapId id of the roadmap
   * @param entity type of the detailed view to be shown
   * @param filters selected filter values
   * @param requiredFields fields to be added as part of the table
   * @returns data of the details of the selected entity
   */
  selectDetails(
    roadmapId: string,
    variationId: string,
    entity: string,
    filters: FilterWithCondition,
    level: string,
    viewLevels: string[],
    requiredFields: string[],
  ): Observable<ResultDetails[]> {
    return this.clientFacade.activeClientId$.pipe(
      mergeMap((clientId) =>
        combineLatest([
          this.clientFacade.selectGeos$(clientId),
          this.clientFacade
            .selectGeoHierarchy$(clientId)
            .pipe(
              map((hierarchy) => this.createHierarchyMap(hierarchy, level)),
            ),
        ]).pipe(
          mergeMap(([geos, hierarchyData]) => {
            return this.getDetailData(clientId, roadmapId, variationId, {
              group: entity,
              level,
              isCampaign: entity === CAMPAIGN_ENTITY,
              viewLevels,
              requiredFields,
              filters,
              ...hierarchyData,
              geos,
            }).pipe(map((data) => Coerce.getObjValues(data)));
          }),
        ),
      ),
    );
  }

  private createHierarchyMap(
    hierarchy: Hierarchy,
    level: string,
  ): {
    geographyHierarchyMap: Record<string, string>;
    geographyNameLevelMap: Record<string, string>;
    geographyLevelNameMap: Record<string, string>;
  } {
    const levelHierarchyData = hierarchy?.[level];
    return {
      geographyHierarchyMap: Object.values(hierarchy).reduce((hMap, geos) => {
        Object.entries(geos || {}).forEach(([name, hid]) => (hMap[hid] = name));
        return hMap;
      }, {}),
      geographyNameLevelMap: levelHierarchyData,
      geographyLevelNameMap: swapObjectKeyAndValue(levelHierarchyData),
    };
  }

  private getDetailData(
    clientId: string,
    roadmapId: string,
    variationId: string,
    params: DetailDataParameters,
  ) {
    const isProject = params.viewLevels?.length === 3;
    return this.getMainResult(roadmapId, variationId).pipe(
      mergeMap((data) =>
        iif(
          () => isProject,
          this.filtersService
            .filterDetailedProjects(clientId, data?.projects, params)
            .pipe(
              map((data) =>
                this.parserService.groupDetailedProjects(
                  data,
                  params.requiredFields,
                ),
              ),
            ),
          this.filtersService
            .filterDetailedCurves(clientId, data?.curves, params)
            .pipe(
              map((data) =>
                this.parserService.groupDetailedCurves(data, params),
              ),
            ),
        ),
      ),
    );
  }

  selectProjectDetails(
    roadmapId: string,
    variationId: string,
  ): Observable<ProjectDetails[]> {
    return this.getMainResult(roadmapId, variationId).pipe(
      map((data) => data?.projects),
    );
  }
  /**
   * Retrieves the corresponding units for each required fields on the table
   * @param roadmapId id of the selected roadmap
   * @param requiredFields fields that has units
   * @returns reference units
   */
  selectUnitReference(
    roadmapId: string,
    variationId: string,
    requiredFields: string[],
  ) {
    return this.localStorage
      .getMainResultStream(roadmapId, variationId)
      .pipe(
        mergeMap((data) =>
          this.store.pipe(select(selectUnits(data, requiredFields))),
        ),
      );
  }

  /**
   * Get the stream of the current process (download) progress.
   * @param roadmapId id of the roadmap.
   * @returns observable of the current progress.
   */
  progress(roadmapId: string) {
    return this.getDataStream(roadmapId).pipe(
      map((data: Result) => data?.progress),
    );
  }

  /**
   * Dispatches an action to download the computation logs.
   * @param clientId id of the client.
   * @param roadmapId id of the roadmap.
   */
  downloadComputationLogs(
    clientId: string,
    roadmapId: string,
    variationId: string,
  ) {
    this.store.dispatch(
      this.resultActions.downloadComputationLogsAction({
        clientId,
        roadmapId,
        variationId,
      }),
    );
  }

  /**
   * Dispatches an action to download the computation inputs.
   * @param clientId id of the client.
   * @param roadmapId id of the roadmap.
   */
  downloadComputationInputs(
    clientId: string,
    roadmapId: string,
    variationId: string,
  ) {
    this.store.dispatch(
      this.resultActions.downloadComputationInputsAction({
        clientId,
        roadmapId,
        variationId,
      }),
    );
  }

  /**
   * Dispatches an action to download the computation result.
   * @param clientId id of the client.
   * @param roadmapId id of the roadmap.
   */
  downloadComputationResult(
    clientId: string,
    roadmapId: string,
    variationId: string,
  ) {
    this.store.dispatch(
      this.resultActions.downloadComputationResultAction({
        clientId,
        roadmapId,
        variationId,
      }),
    );
  }

  /**
   * Dispatches an action to download the computation result in excel.
   * @param clientId id of the client.
   * @param roadmapId id of the roadmap.
   */
  downloadResultExcel(
    clientId: string,
    roadmapId: string,
    variationId: string,
  ) {
    this.store.dispatch(
      this.resultActions.downloadResultExcelAction({
        clientId,
        roadmapId,
        variationId,
      }),
    );
  }

  /**
   * Get the stream of the roadmap analysis kpis.
   * @param roadmapId id of the roadmap.
   * @returns Observable of the roadmap analysis kpis.
   */
  getPortfolioAnalysis(roadmapId: string, variationId: string) {
    return this.localStorage
      .getMainResultStream(roadmapId, variationId)
      .pipe(map((data: Result) => data?.analysis as PortfolioAnalysis));
  }

  /**
   * Get the stream of the roadmap worldmap kpis.
   * @param roadmapId id of the roadmap.
   * @returns Observable of the roadmap worldmap kpis.
   */
  getWorldMap(roadmapId: string, variationId: string) {
    return this.localStorage
      .getMainResultStream(roadmapId, variationId)
      .pipe(map((data: Result) => data?.worldMap as WorldMapData[]));
  }

  /**
   * TODO: Transfer this to local storage also
   * Get the stream of the baseline indicators.
   * @param roadmapId id of the roadmap.
   * @returns Observable of the baseline indicators.
   */
  private selectBaselineIndicator(roadmapId: string) {
    return this.getDataStream(roadmapId).pipe(
      map(
        (data: PortfolioEntity<BaselineIndicators>) =>
          data?.baselineIndicators || {},
      ),
    );
  }

  /**
   * Get the stream of the baseline id.
   * @param roadmapId id of the roadmap.
   * @returns Observable of the baseline id.
   */
  selectBaselineIndicatorId(roadmapId: string) {
    return this.selectBaselineIndicator(roadmapId).pipe(
      map((data: BaselineIndicators) => data?.baselineId),
    );
  }

  /**
   * Get the stream of the baseline analysis kpis.
   * @param roadmapId id of the roadmap.
   * @returns Observable of the baseline analysis kpis.
   */
  selectBaselineIndicatorAnalysis(roadmapId: string) {
    return this.selectBaselineIndicator(roadmapId).pipe(
      map((data: BaselineIndicators) => data?.analysis),
    );
  }

  /**
   * Get the stream of the baseline worldmap kpis.
   * @param roadmapId id of the roadmap.
   * @returns Observable of the baseline worldmap kpis.
   */
  selectBaselineIndicatorWorldMap(roadmapId: string) {
    return this.selectBaselineIndicator(roadmapId).pipe(
      map((data: BaselineIndicators) => data?.worldMap || []),
    );
  }

  /**
   * Get the stream of the combined analysis kpis.
   * @param roadmapId id of the roadmap.
   * @returns Observable of the combined analysis kpis.
   */
  selectCombinedAnalysis(roadmapId: string, variationId: string) {
    return combineLatest([
      this.getPortfolioAnalysis(roadmapId, variationId),
      this.selectBaselineIndicatorAnalysis(roadmapId),
    ]).pipe(
      map(([roadmap, baseline]) => this.combineAnalysis(roadmap, baseline)),
    );
  }

  /**
   * Get the stream of the combined worldmap kpis.
   * @param roadmapId id of the roadmap.
   * @returns Observable of the combined worldmap kpis.
   */
  selectCombinedWorldMap(roadmapId: string, variationId: string) {
    return combineLatest([
      this.getWorldMap(roadmapId, variationId),
      this.selectBaselineIndicatorWorldMap(roadmapId),
    ]).pipe(
      map(([roadmap, baseline]) => this.combineWorldMap(roadmap, baseline)),
    );
  }

  /**
   * Get the stream of the value of loading field.
   * @param roadmapId id of the roadmap.
   * @returns Observable of the value of loading field.
   */
  getResultLoadingStream(roadmapId: string): Observable<boolean> {
    return this.getDataStream(roadmapId).pipe(map((state) => state?.loading));
  }

  /**
   * Get the stream of the value of loaded field.
   * @param roadmapId id of the roadmap.
   * @returns Observable of the value of loaded field.
   */
  getResultLoadedStream(roadmapId: string): Observable<boolean> {
    return this.getDataStream(roadmapId).pipe(map((state) => state?.loaded));
  }

  /**
   * Get the stream of the value of baseline loading field.
   * @param roadmapId id of the roadmap.
   * @returns Observable of the value of baseline loading field.
   */
  getBaselineLoadingStream(roadmapId: string): Observable<boolean> {
    return this.getDataStream(roadmapId).pipe(
      map((state) => state?.baselineLoading),
    );
  }

  /**
   * Get the stream of the value of baseline fetched field.
   * @param roadmapId id of the roadmap.
   * @returns Observable of the value of baseline fetched field.
   */
  getBaselineFetchedStream(roadmapId: string): Observable<boolean> {
    return this.getDataStream(roadmapId).pipe(
      map((state) => state?.baselineFetched),
    );
  }

  /**
   * Dispatches an action to update the current process progress.
   * @param id id of the roadmap
   * @param data updated data of the progress
   */
  updateLoadedProgress(id, data) {
    this.store.dispatch(
      this.resultActions.updateLoadedProgressAction({ id, progress: data }),
    );
  }

  updateLoadedVariationProgress(
    roadmapId: string,
    variationId: string,
    progress,
  ) {
    this.store.dispatch(
      this.resultActions.updateLoadedVariationProgressAction({
        roadmapId,
        variationId,
        progress,
      }),
    );
  }

  clearLocalStorage() {
    this.localStorage.clear();
  }

  /**
   * Combine roadmap and baseline kpi analysis.
   * @param roadmap selected roadmap.
   * @param baseline current active baseline roadmap.
   * @returns combined kpi analysis.
   */
  private combineAnalysis(
    roadmap: PortfolioAnalysis,
    baseline: PortfolioAnalysis,
  ): PortfolioAnalysis {
    return !!baseline && !!roadmap
      ? {
          economics: this.removeDuplicatedKpis(
            roadmap.economics,
            baseline.economics,
          ),
          environmental: this.removeDuplicatedKpis(
            roadmap.environmental,
            baseline.environmental,
          ),
          sustainability: this.removeDuplicatedKpis(
            roadmap.sustainability,
            baseline.sustainability,
          ),
        }
      : roadmap;
  }

  /**
   * Check if baseline kpis include a duplicated kpis in the roadmap results
   * @param roadmap roadmap kpis.
   * @param baseline baseline kpis.
   * @returns Removed list of kpis that was duplicated.
   */
  private removeDuplicatedKpis(
    roadmap: ResultEntity[],
    baseline: ResultEntity[],
  ) {
    return roadmap
      .filter((kpi) => baseline.findIndex((_kpi) => kpi.name === _kpi.name) < 0)
      .concat(baseline);
  }

  /**
   * Combine roadmap and baseline world map kpis.
   * @param roadmap selected roadmap.
   * @param baseline current active baseline roadmap.
   * @returns combined worldmap values.
   */
  private combineWorldMap(
    roadmap: WorldMapData[],
    baseline: WorldMapData[] = [],
  ): WorldMapData[] {
    const combinedData = roadmap?.concat(baseline);
    const combinedValues = combinedData.reduce<{ [key: string]: WorldMapData }>(
      (acc, curr) => {
        const existingData = acc[curr.id];
        if (existingData) {
          acc[curr.id] = {
            ...existingData,
            values: { ...existingData.values, ...curr.values },
          };
        } else {
          acc[curr.id] = curr;
        }

        return acc;
      },
      {},
    );

    return Coerce.getObjValues(combinedValues);
  }
}

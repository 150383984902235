import { Subject } from 'rxjs';
import { BaseComponent } from 'ssotool-shared/component/base';

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { FormBuilder, NgControl } from '@angular/forms';

import { Fluid } from './fluid-toggle.model';

@Component({
  selector: 'sso-fluids-toggle-group',
  templateUrl: './fluids-toggle-group.component.html',
  styleUrls: ['./fluids-toggle-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FluidsToggleGroupComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  static nextUniqueId = 0;

  // tslint:disable-next-line:variable-name
  private _value: any;
  get value(): any {
    return this._value;
  }
  @Input() set value(newValue: any) {
    if (newValue !== this._value) {
      this._value = newValue;
      this.stateChanges.next();
    }
  }

  // tslint:disable-next-line:variable-name
  private _id: string;
  get id(): string {
    return this._id;
  }
  @Input() set id(value: string) {
    this._id = value || this.uid;
    this.stateChanges.next();
  }

  // tslint:disable-next-line:variable-name
  private _required = false;
  get required(): boolean {
    return this._required;
  }
  @Input() set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  // tslint:disable-next-line:variable-name
  private _focused = false;
  get focused(): boolean {
    return this._focused;
  }
  set focused(value: boolean) {
    this._focused = value;
  }

  // tslint:disable-next-line:variable-name
  private _disabled = false;
  get disabled(): boolean {
    return this._disabled;
  }
  @Input() set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  // tslint:disable-next-line:variable-name
  private _readonly = false;
  get readonly(): boolean {
    return this._readonly;
  }
  @Input() set readonly(value: boolean) {
    this._readonly = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  uid = `sso-base-control-${FluidsToggleGroupComponent.nextUniqueId++}`;
  stateChanges = new Subject<void>();

  onChange: (value: any) => void = () => {};
  onTouched = () => {};

  ngOnInit() {
    this.setDisabledState(this.disabled);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.stateChanges.complete();
  }

  writeValue(value: any): void {
    this.value = value;
    this.markForCheck();
    this.stateChanges.next();
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.markForCheck();
    this.stateChanges.next();
  }

  markForCheck() {
    if (this.changeDetector) {
      this.changeDetector.markForCheck();
    }
  }
  @Input() width = 170;
  @Input() height = 49.31;
  @Input() viewMode;
  _fluids: Array<Fluid> = [];
  @Input() set fluids(fluidArray: Array<Fluid>) {
    if (!!fluidArray) {
      this._fluids = fluidArray;
    }
  }
  get fluids() {
    return this._fluids;
  }
  @Input() fluidOptions: Array<any> = [];
  @Input() layout = 'column';
  @Output() remove: EventEmitter<string> = new EventEmitter<string>(null);
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() select: EventEmitter<string> = new EventEmitter<string>(null);
  deleteVisibility = {};
  mainSelected = '';

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: FormBuilder,
  ) {
    super();

    if (ngControl != null) {
      ngControl.valueAccessor = this;
    }
  }

  defineForm() {
    return {
      mainFluid: this.formBuilder.control(null),
    };
  }

  onValChange(value: any) {
    this.select.emit(value);
  }

  onDelete(fluid: Fluid) {
    this.remove.emit(fluid.value);
  }

  onHideDelete(fluid: Fluid) {
    this.deleteVisibility[fluid.name + fluid.value] = false;
  }

  onShowDelete(fluid: Fluid) {
    this.deleteVisibility[fluid.name + fluid.value] = true;
  }
}

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterWithCondition } from 'ssotool-app/shared';

import { Injectable } from '@angular/core';

import {
  CurveEntity,
  HierarchicalFilter,
  ResultFacadeService,
  ResultFilterOptions,
} from '../result';
import { RoadmapVariationFacadeService } from '../roadmap-variation/roadmap-variation.facade.service';
import { CompareRoadmapWithVariationType } from 'ssotool-app/+roadmap/modules/compare-variations/compare-variations.model';

export type CurveEntityVariation = CurveEntity & {
  roadmapId?: string;
  roadmapName?: string;
  variationId: string;
  variationName: string;
};

@Injectable()
export class CompareVariationsFacadeService {
  constructor(
    private resultFacade: ResultFacadeService,
    private roadmapVariationFacade: RoadmapVariationFacadeService,
  ) {}

  selectCurves(
    roadmapWithVariations: CompareRoadmapWithVariationType,
    kpiType: string,
    kpi: string,
    splitBy: HierarchicalFilter,
    enumerateBy: HierarchicalFilter,
    filters: FilterWithCondition,
  ): Observable<CurveEntityVariation[]> {
    const curveObservables = roadmapWithVariations.map((roadmapVar) => {
      const [roadmapId, roadmapName, variationId] = roadmapVar;

      return combineLatest([
        this.roadmapVariationFacade.variation$(roadmapId, variationId),
        this.resultFacade.selectCurve(
          roadmapId,
          variationId,
          kpiType,
          kpi,
          splitBy,
          enumerateBy,
          filters,
        ),
      ]).pipe(
        map(([roadmapVariation, res]) => ({
          ...res,
          roadmapId,
          roadmapName,
          variationId,
          variationName: roadmapVariation.name,
        })),
      );
    });

    return combineLatest([...curveObservables]);
  }

  selectLoading(roadmapId: string, variationIds: string[]) {
    return combineLatest(
      variationIds.map((variationId) =>
        this.resultFacade.getResultVariationLoading(roadmapId, variationId),
      ),
    ).pipe(map((loading) => loading.includes(true)));
  }

  selectProgress(roadmapId: string, variationIds: string[]) {
    return combineLatest(
      variationIds.map((variationId) =>
        this.resultFacade.getResultVariationProgress(roadmapId, variationId),
      ),
    ).pipe(
      map((progresses) =>
        progresses.reduce(
          (acc, progress) => acc + progress / variationIds.length,
          1,
        ),
      ),
    );
  }

  selectDetails(
    referenceIds: CompareRoadmapWithVariationType,
    entity: string,
    level: string,
    filters: FilterWithCondition,
    viewLevels: string[],
    requiredFields: string[],
  ) {
    return combineLatest(
      referenceIds.map(([roadmapId, , variationId]) =>
        this.resultFacade.selectDetails(
          roadmapId,
          variationId,
          entity,
          filters,
          level,
          viewLevels,
          requiredFields,
        ),
      ),
    );
  }

  selectUnits(
    referenceIds: CompareRoadmapWithVariationType,
    requiredFields: string[],
  ) {
    return combineLatest(
      referenceIds.map(([roadmapId, , variationId]) =>
        this.resultFacade.selectUnitReference(
          roadmapId,
          variationId,
          requiredFields,
        ),
      ),
    );
  }

  selectFilters(
    referenceIds: CompareRoadmapWithVariationType,
    activeFilters: string[],
  ): Observable<ResultFilterOptions> {
    return combineLatest(
      referenceIds.map(([roadmapId, , variationId]) =>
        this.resultFacade.selectFilter(roadmapId, variationId, activeFilters),
      ),
    ).pipe(map((filterOptions) => this.mergedFilters(filterOptions)));
  }

  private mergedFilters(
    filterOptions: ResultFilterOptions[],
  ): ResultFilterOptions {
    return filterOptions.reduce((acc, filters) => {
      Object.entries(filters).forEach(([key, options]) => {
        {
          acc[key] = Array.from(
            new Set([].concat(acc?.[key] || []).concat(options || [])),
          );
        }
      });
      return acc;
    }, {});
  }
}

import { ChipListModule } from 'ssotool-app/shared/modules/chip-list/chip-list.module';
import { SSOTitleCasePipe } from 'ssotool-app/shared/pipes/titlecase.pipe';
import {
  FiltersModule,
  SearchInputModule,
  SparklineModule,
  YearlyInputModule,
} from 'ssotool-shared';
import { SimpleChipsModule } from 'ssotool-shared/component/simple-chips';
import { SSOSpinnerModule } from 'ssotool-shared/component/spinner';
import { PipesModule } from 'ssotool-shared/pipes';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';

import { EntityCommonTableComponent } from './entity-common-table.component';

@NgModule({
  declarations: [EntityCommonTableComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatTableModule,
    SimpleChipsModule,
    MatSortModule,
    MatPaginatorModule,
    SSOSpinnerModule,
    MatIconModule,
    SparklineModule,
    MatCheckboxModule,
    PipesModule,
    FiltersModule,
    SearchInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    TranslateModule,
    ChipListModule,
    YearlyInputModule,
    SSOTitleCasePipe,
  ],
  exports: [EntityCommonTableComponent],
})
export class EntityCommonTableModule {}

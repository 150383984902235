import { WIDGET_REFERENCES } from 'ssotool-app/app.references';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { KPIWidgetModel } from './kpi-widget.model';

@Component({
  selector: 'sso-kpi-widget-component',
  templateUrl: './kpi-widget.component.html',
  styleUrls: ['./kpi-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KPIWidgetComponent {
  WIDGET_REF = WIDGET_REFERENCES;

  _widgetConfig;
  @Input() set widgetConfig(kpi: KPIWidgetModel) {
    this._widgetConfig = kpi;
  }

  get widgetConfig() {
    return this._widgetConfig;
  }

  get hasObjectValue() {
    return typeof this._widgetConfig.value === 'object';
  }
}

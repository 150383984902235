import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { RoadmapVariationActions } from './roadmap-variation.actions';
import {
  RoadmapVariationGetListParams,
  RoadmapVariationGetParams,
} from './roadmap-variation.model';
import { RoadmapVariationState } from './roadmap-variation.reducer';
import {
  selectLoaded,
  selectLoading,
  selectVariation,
  selectVariationAccessed,
  selectVariationLoaded,
  selectVariationLoading,
  selectVariations,
} from './roadmap-variation.selector';

@Injectable()
export class RoadmapVariationFacadeService {
  constructor(private store: Store<RoadmapVariationState>) {}

  loading$ = this.store.pipe(select(selectLoading));
  loaded$ = this.store.pipe(select(selectLoaded));
  variations$ = (roadmapId: string) =>
    this.store.select(selectVariations({ roadmapId }));
  variation$ = (roadmapId: string, variationId: string) =>
    this.store.select(selectVariation({ roadmapId, variationId }));
  variationLoading$ = (roadmapId: string, variationId: string) =>
    this.store.select(selectVariationLoading({ roadmapId, variationId }));
  variationLoaded$ = (roadmapId: string, variationId: string) =>
    this.store.select(selectVariationLoaded({ roadmapId, variationId }));

  getVariationAccessed(roadmapId: string, variationId: string) {
    return this.store.select(
      selectVariationAccessed({ roadmapId, variationId }),
    );
  }

  get(params: RoadmapVariationGetParams) {
    this.store.dispatch(RoadmapVariationActions.get(params));
  }

  getList(params: RoadmapVariationGetListParams) {
    this.store.dispatch(RoadmapVariationActions.getList(params));
  }

  accessVariation(roadmapId: string, variationId: string) {
    this.store.dispatch(
      RoadmapVariationActions.accessRoadmapVariationAction({
        roadmapId,
        variationId,
      }),
    );
  }

  reloadSingleRoadmapVariation(
    clientId: string,
    roadmapId: string,
    variationId: string,
    status: string,
    notifType: string,
  ) {
    this.store.dispatch(
      RoadmapVariationActions.reloadSingleRoadmapVariationAction({
        clientId,
        roadmapId,
        variationId,
        status,
        notifType,
      }),
    );
  }

  reloadAffectedRoadmapVariation(clientId: string, roadmapIds: string[]) {
    this.store.dispatch(
      RoadmapVariationActions.reloadAffectedRoadmapVariationAction({
        clientId,
        roadmapIds,
      }),
    );
  }
}

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientFacadeService } from 'ssotool-app/+client';
import { BinDataFacadeService } from 'ssotool-app/+entities/stores/bindata/bindata-facade.service';
import { BindataHourlyBaseComponent } from '../../bindata';
import { FlowPricesHourlyDialogModel } from './flow-prices-hourly-dialog.model';

@Component({
  selector: 'sso-flow-prices-hourly-dialog',
  templateUrl: './flow-prices-hourly-dialog.component.html',
  styleUrls: ['./flow-prices-hourly-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowPricessHourlyDialogComponent extends BindataHourlyBaseComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FlowPricesHourlyDialogModel,
    public dialogRef: MatDialogRef<FlowPricesHourlyDialogModel>,
    public binDataFacade: BinDataFacadeService,
    public clientFacade: ClientFacadeService,
  ) {
    super(data, dialogRef, binDataFacade, clientFacade);
  }
}

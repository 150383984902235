import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError, first, map, mergeMap, skipWhile } from 'rxjs/operators';
import { ClientFacadeService } from 'ssotool-client/store/client.facade.service';
import { generateEndpoint } from 'ssotool-core/utils';
import {
  Coerce,
  generateKeyNameReference,
  YearlyValuesMapper,
} from 'ssotool-shared';
import { ConfigService } from 'ssotool-shared/services/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  BackendCampaignEntity,
  CampaignEntity,
  CampaignEntityReferences,
  CampaignTypes,
  SelectedCampaigns,
} from '../store/campaign.model';
import { ConverterMapper } from './mappers/converter.mapper';
import { EnergyEfficiencyMapper } from './mappers/energy-efficiency.mapper';
import { MarketMapper } from './mappers/market.mapper';
import { RenewableProductionMapper } from './mappers/renewable-production.mapper';
import { StorageMapper } from './mappers/storage.mapper';

@Injectable()
export class CampaignAPIService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private clientFacade: ClientFacadeService,
  ) {}

  private invokeEndpointAndMapEntities(
    clientId: string,
    httpCall: Observable<any>,
  ): Observable<CampaignTypes> {
    return this.clientFacade.dataLoaded$(clientId).pipe(
      skipWhile((loaded) => !loaded),
      first(),
      mergeMap(() =>
        this.getEntityReferences(clientId).pipe(
          first(),
          mergeMap((entities) =>
            httpCall.pipe(
              map((response) => {
                return this.mapCampaignToFrontend(response, entities);
              }),
              catchError((error) => throwError(error)),
            ),
          ),
        ),
      ),
    );
  }

  // TODO: Update expected observable as we create models for each campaign type
  create(clientId: string, data: any): Observable<CampaignTypes> {
    return this.invokeEndpointAndMapEntities(
      clientId,
      this.http.post(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.campaign.create,
          clientId,
          data.campaignType,
        ),
        this.mapCampaignToBackend(data),
      ),
    );
  }

  get(
    clientId: string,
    id: string,
    campaignType: string,
  ): Observable<CampaignTypes> {
    return this.invokeEndpointAndMapEntities(
      clientId,
      this.http.get<any>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.campaign.getCampaign,
          clientId,
          campaignType,
          id,
        ),
      ),
    );
  }

  // TODO: Update expected observable as we create models for each campaign type
  update(clientId: string, id: string, data: any): Observable<CampaignTypes> {
    return this.invokeEndpointAndMapEntities(
      clientId,
      this.http.post(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.campaign.update,
          clientId,
          data.campaignType,
          id,
        ),
        this.mapCampaignToBackend(data),
      ),
    );
  }

  getList(clientId: string) {
    return this.clientFacade.dataLoaded$(clientId).pipe(
      skipWhile((loaded) => !loaded),
      first(),
      mergeMap(() =>
        this.getEntityReferences(clientId).pipe(
          first(),
          mergeMap((entities) =>
            this.http
              .get<BackendCampaignEntity[]>(
                generateEndpoint(
                  this.config.api.baseUrl,
                  this.config.api.endpoints.campaign.getCampaigns,
                  clientId,
                ),
              )
              .pipe(
                map((response) =>
                  response.map((data) =>
                    this.mapCampaignEntities(data, entities),
                  ),
                ),
                catchError((error) => throwError(error)),
              ),
          ),
        ),
      ),
    );
  }

  delete(clientId: string, id: string, campaignType: string) {
    return this.http
      .delete<any>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.campaign.delete,
          clientId,
          campaignType,
          id,
        ),
      )
      .pipe(
        map((response) => ({ id, clientId })),
        catchError((error) => throwError(error)),
      );
  }

  deleteMultiple(clientId: string, campaigns: SelectedCampaigns) {
    return this.http
      .post<any>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.campaign.batchDelete,
          clientId,
        ),
        campaigns,
      )
      .pipe(
        map((response) => ({ ids: Coerce.getObjKeys(campaigns) })),
        catchError((error) => throwError(error)),
      );
  }

  batchDuplicate(clientId: string, campaigns: SelectedCampaigns) {
    return this.http
      .post<any>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.campaign.batchDuplicate,
          clientId,
        ),
        campaigns,
      )
      .pipe(
        map((response) => response),
        catchError((error) => throwError(error)),
      );
  }

  exportCampaign(clientId: string, campaigns: SelectedCampaigns) {
    return this.http
      .post<any>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.campaign.export,
          clientId,
        ),
        campaigns,
      )
      .pipe(
        map((response) => response),
        catchError((error) => throwError(error)),
      );
  }

  populateWithLibrary(clientId: string) {
    return this.http
      .post(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.campaign.populateWithLibrary,
          clientId,
        ),
        {},
      )
      .pipe(
        map((response) => response),
        catchError((error) => throwError(error)),
      );
  }

  // TODO: update model
  mapCampaignToFrontend(
    data: CampaignTypes,
    {
      geos,
      companies,
      sectors,
      processes,
      fluids,
      metrics,
      geoGroups,
      sites,
    }: CampaignEntityReferences,
  ): CampaignTypes {
    let _c = data;
    switch (data.campaignType) {
      case 'energyEfficiency':
        _c = EnergyEfficiencyMapper.mapToFrontend(data);
        break;
      case 'converter':
        _c = ConverterMapper.mapToFrontend(data);
        break;
      case 'renewableProduction':
        _c = RenewableProductionMapper.mapToFrontend(data);
        break;
      case 'storage':
        _c = StorageMapper.mapToFrontend(data);
        break;
      case 'market':
        _c = MarketMapper.mapToFrontend(data);
    }
    _c = {
      ..._c,
      geography:
        (!_c?.isGeoGroup ? geos?.[_c?.geoId] : geoGroups?.[_c?.geoId]) ?? null,
      site: sites?.[_c?.siteId] || null,
      company: companies?.[_c?.companyId] || null, // Null for now due to other levers using this
      sector: sectors?.[_c?.sectorId] || null, // Null for now due to other levers using this
      process: processes?.[_c?.processId],
      fluid: fluids?.[_c?.fluidId],
      fluids: this.mapFluids(fluids, _c),
      metric: metrics?.[_c?.metricId],
    };
    return _c;
  }

  private mapFluids(
    fluids: Record<string, any>,
    data: BackendCampaignEntity | CampaignTypes,
  ): string[] {
    let mappedfluids = [];
    if (data?.associatedFluidIds) {
      data?.associatedFluidIds.forEach((fluidId) =>
        mappedfluids.push(fluids?.[fluidId]),
      );
    }
    return mappedfluids;
  }

  mapCampaignEntities(
    data: BackendCampaignEntity,
    {
      geos,
      companies,
      sectors,
      geoGroups,
      fluids,
      processes,
      sites,
    }: CampaignEntityReferences,
  ): CampaignEntity {
    const mappedFluids = this.mapFluids(fluids, data);
    return {
      clientId: data.clientId,
      id: data.campaignId,
      campaignType: data.campaignType,
      subtype: data.subtype,
      category: data.campaignCategory,
      name: data.name,
      pathway: data.roadmap,
      isGeoGroup: !!data?.isGeoGroup,
      geoId: data.geoId || null,
      siteId: data.siteId || null,
      companyId: data.companyEntityId || null, // Null for now due to other levers using this
      sectorId: data.sectorId || null, // Null for now due to other levers using this
      processId: data.processId,
      savings: data.savings && YearlyValuesMapper.mapToFrontend(data.savings),
      maxCapacity:
        data.maxCapacity && YearlyValuesMapper.mapToFrontend(data.maxCapacity),
      maxVolume:
        data.maxVolume && YearlyValuesMapper.mapToFrontend(data.maxVolume),
      geography:
        (!data?.isGeoGroup ? geos?.[data?.geoId] : geoGroups?.[data?.geoId]) ??
        null,
      site: sites?.[data?.siteId] || null,
      company: companies?.[data?.companyEntityId] || null,
      sector: sectors?.[data?.sectorId] || null,
      process: processes?.[data?.processId],
      fluids: mappedFluids,
      fluidIds: Object.entries(fluids)
        .filter(([, name]) => mappedFluids.includes(name))
        .map(([fluidId]) => fluidId),
      profileSites: data.profileSites,
    };
  }

  mapCampaignToBackend(data: any): any {
    switch (data.campaignType) {
      case 'energyEfficiency':
        return { ...EnergyEfficiencyMapper.mapToBackend(data) };
      case 'converter':
        return { ...ConverterMapper.mapToBackend(data) };
      case 'renewableProduction':
        return { ...RenewableProductionMapper.mapToBackend(data) };
      case 'storage':
        return { ...StorageMapper.mapToBackend(data) };
      case 'market':
        return { ...MarketMapper.mapToBackend(data) };
      default:
        return data;
    }
  }

  getEntityReferences(clientId: string): Observable<CampaignEntityReferences> {
    return combineLatest([
      this.clientFacade
        .selectCompanies$(clientId)
        .pipe(map(generateKeyNameReference)),
      this.clientFacade
        .selectGeos$(clientId)
        .pipe(map(generateKeyNameReference)),
      this.clientFacade
        .selectSectors$(clientId)
        .pipe(map(generateKeyNameReference)),
      this.clientFacade
        .selectProcesses$(clientId)
        .pipe(map(generateKeyNameReference)),
      this.clientFacade
        .selectFluids$(clientId)
        .pipe(map(generateKeyNameReference)),
      this.clientFacade
        .selectMetrics$(clientId)
        .pipe(map(generateKeyNameReference)),
      this.clientFacade
        .selectGeographyGroups$(clientId)
        .pipe(map(generateKeyNameReference)),
      this.clientFacade
        .selectSites$(clientId)
        .pipe(map(generateKeyNameReference)),
    ]).pipe(
      map(
        ([
          companies,
          geos,
          sectors,
          processes,
          fluids,
          metrics,
          geoGroups,
          sites,
        ]) => ({
          companies,
          geos,
          sectors,
          processes,
          fluids,
          metrics,
          geoGroups,
          sites,
        }),
      ),
    );
  }
}

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileInput } from 'ssotool-core/models/file.model';
import { BaseComponent } from 'ssotool-shared/component/base';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroupDirective, NgControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';

@Component({
  selector: 'sso-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: MatFormFieldControl, useExisting: FileUploadComponent },
  ],
})
export class FileUploadComponent
  extends BaseComponent
  implements MatFormFieldControl<FileInput>, OnInit, OnDestroy
{
  static nextId = 0;
  @ViewChild('input') input: ElementRef;

  stateChanges = new Subject<void>();
  focused = false;
  controlType = 'sso-file-upload';
  acceptedExt: string;

  private _placeholder: string;
  private _required = false;

  @HostBinding() id = `sso-file-upload-${FileUploadComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';
  @HostBinding('class.sso-file-upload-disabled') get isDisabled() {
    return this.disabled;
  }
  @HostBinding('class.sso-floating') get shouldLabelFloat() {
    return this.focused || !this.empty || this.placeholder !== undefined;
  }

  @Input() multiple: boolean;
  @Input() set acceptedExtensions(acceptedFiles: Array<string>) {
    this.acceptedExt =
      '.' +
      acceptedFiles.reduce((prev, curr) => {
        return `${prev},.${curr}`;
      });
  }

  @Input() get value(): FileInput | null {
    return this.empty
      ? null
      : new FileInput(this._elementRef.nativeElement.value || []);
  }
  set value(fileInput: FileInput | null) {
    this.writeValue(fileInput.files);
    this.stateChanges.next();
  }

  @Input() get placeholder() {
    return this._placeholder;
  }
  set placeholder(placeholder) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  @Input() get required() {
    return this._required;
  }
  set required(req: boolean) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  get empty() {
    return (
      !this._elementRef.nativeElement.value ||
      this._elementRef.nativeElement.value.length === 0
    );
  }

  @Input() get disabled() {
    return this._elementRef.nativeElement.disabled;
  }
  set disabled(disabled: boolean) {
    this.setDisabledState(coerceBooleanProperty(disabled));
    this.stateChanges.next();
  }

  @Input() get errorState() {
    return this.defaultErrorStateMatcher.isErrorState(
      this.ngControl.control as FormControl,
      this.parentFormGroup,
    );
  }

  private _onChange = (_: any) => {};
  private _onTouched = () => {};

  // istanbul ignore next
  @HostListener('change', ['$event']) change(event) {
    const fileList = event.target.files;

    // istanbul ignore else
    if (this.value && (!fileList || fileList.length === 0)) {
      return;
    }

    const fileArray = [];
    // istanbul ignore else
    if (fileList) {
      for (const file of fileList) {
        fileArray.push(file);
      }
    }
    this.value = new FileInput(fileArray);
    this._onChange(this.value);
    this.input.nativeElement.value = '';
  }

  @HostListener('focusout') blur() {
    this.focused = false;
    this._onTouched();
  }

  constructor(
    public ngControl: NgControl,
    public defaultErrorStateMatcher: ErrorStateMatcher,
    public parentFormGroup: FormGroupDirective,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
  ) {
    super();
    // istanbul ignore else
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    // istanbul ignore next
    _focusMonitor
      .monitor(_elementRef.nativeElement, true)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((origin) => {
        this.focused = !!origin;
        this.stateChanges.next();
      });
  }

  ngOnInit() {
    this.multiple = coerceBooleanProperty(this.multiple);
    // istanbul ignore next
    this.parentFormGroup.ngSubmit
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => this._onChange(this.value));
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef.nativeElement);
  }

  writeValue(obj: any): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'value', obj);
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._renderer.setProperty(
      this._elementRef.nativeElement,
      'disabled',
      isDisabled,
    );
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  // istanbul ignore next
  onContainerClick(event: MouseEvent) {
    // istanbul ignore else
    if (
      (event.target as Element).tagName.toLowerCase() !== 'input' &&
      !this.disabled
    ) {
      this._elementRef.nativeElement.querySelector('input').focus();
      this.focused = true;
      this.open();
    }
  }

  open(): void {
    if (!this.disabled) {
      this._elementRef.nativeElement.querySelector('input').click();
    }
  }

  get fileNames() {
    return this.value ? this.value.fileNames : undefined;
  }
}

import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import {
  Client,
  ClientFacadeService,
  ClientShareInfo,
} from 'ssotool-app/+client/store';
import { doNothing } from 'ssotool-core/utils';
import {
  Coerce,
  FormFieldErrorMessageMap,
  FormFieldOption,
  PermissionChecker,
  User,
  UserStateManagerService,
} from 'ssotool-shared';

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UserDetails } from './share-client.model';

@UntilDestroy()
@Component({
  selector: 'sso-share-client',
  templateUrl: './share-client.component.html',
  styleUrls: ['./share-client.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareClientComponent implements OnInit {
  @ViewChild('add', { read: ElementRef }) onAddButton: ElementRef;
  formDirective: FormGroupDirective;
  addUserForm: FormGroup;
  clientData: Client;
  sharedUserList$ = new BehaviorSubject<ClientShareInfo[]>([]);
  permissionOptions: Array<FormFieldOption<any>> = [
    {
      name: 'Admin',
      value: 'admin',
    },
    {
      name: 'Read only',
      value: 'readonly',
    },
  ];
  loading$: Observable<boolean>;
  isRetrievingEmail$ = this.userManagerService.getEmailLoading$;
  isAdmin$ = new BehaviorSubject<boolean>(false);
  isReadOnly$ = this.isAdmin$.pipe(map((isAdmin) => !isAdmin));
  loaderSize = 1;
  errorMessageMap: FormFieldErrorMessageMap = {
    email: {
      required: 'User email is required.',
      email: 'User email is not valid.',
      duplicate: 'There is an existing shared user with this email.',
    },
  };
  isPristine$ = new BehaviorSubject<boolean>(true);
  activeClient$ = new BehaviorSubject<any>('');
  clientId: string;
  searchedUsers$ = new BehaviorSubject<UserDetails[]>([
    {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
    },
  ]);
  names$ = new BehaviorSubject<string[]>([]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Client,
    public dialogRef: MatDialogRef<ShareClientComponent>,
    private clientFacade: ClientFacadeService,
    private formBuilder: FormBuilder,
    private userManagerService: UserStateManagerService,
    private permissionChecker: PermissionChecker,
  ) {}

  get isSubmitDisabled$() {
    return combineLatest([
      this.isPristine$,
      this.loading$,
      this.isRetrievingEmail$,
    ]).pipe(map((loading) => loading.includes(true)));
  }

  get cancelButtonLabel$() {
    return combineLatest([this.isReadOnly$, this.isPristine$]).pipe(
      map((c) => (c.includes(true) ? 'close' : 'cancel')),
    );
  }

  ngOnInit(): void {
    this.clientId = this.data?.clientId;
    this.loading$ = combineLatest([
      this.clientFacade.shareDataLoading$(this.clientId),
      this.clientFacade.sharing$,
    ]).pipe(map((loading) => loading.includes(true)));
    this.clientFacade.getClientShareInfo(this.clientId);
    // define form
    this.addUserForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      role: ['', Validators.required],
    });
    // detect changes in roles
    this.addUserForm.valueChanges.subscribe(() =>
      this.addUserForm.valid ? this.onValueChange(false) : doNothing(),
    );
    // subscribe for changes on client and users
    combineLatest([
      this.clientFacade.selectClient$(this.clientId),
      this.userManagerService.data$,
    ])
      .pipe()
      .subscribe(([client, users]) => {
        // setup client share component
        if (this.isPristine$.value) {
          this.loadClient(client);
          this.getActiveClient(client);
          this.getShareInfo(client, users);
          this.isAdmin$.next(
            this.permissionChecker.isAdmin(client?.permissions),
          );
        }
      });

    this.emailInputValueChanges();
  }

  /**
   * Subcribe to any input in email
   */
  emailInputValueChanges(): void {
    this.addUserForm.controls.email.valueChanges
      .pipe(untilDestroyed(this), debounceTime(300))
      .subscribe((input: string) => {
        const userInput = !!input ? input.trim() : '';
        if (Coerce.toString(userInput).length > 2) {
          this.userManagerService
            .searchUsersByName(userInput)
            .subscribe((users) => {
              const names = users.map((user) => this.concatenateFullName(user));
              this.names$.next(names);
              this.searchedUsers$.next(users);
            });
        } else {
          this.names$.next([]);
          this.searchedUsers$.next([]);
        }
      });
  }

  /**
   * Concatenenate first and last name
   */
  concatenateFullName({ firstName, lastName }): string {
    return `${firstName} ${lastName}`;
  }

  selectOptionAutoComplete(event: MatAutocompleteSelectedEvent): void {
    const selectedName = event.option.value;
    const selectedPerson = this.searchedUsers$.value.find(
      (person) => `${person.firstName} ${person.lastName}` === selectedName,
    );

    this.addUserForm.controls.email.setValue(selectedPerson.email);
  }

  /**
   * When no client loaded, get from backend,
   * update share info
   * @param client - active client
   */
  loadClient(client) {
    if (!client) {
      this.clientFacade.get(this.clientId);
      this.clientFacade.getClientShareInfo(this.clientId);
    }
  }

  /**
   * Get current client
   * @param client - active client
   */
  getActiveClient(client) {
    if (client) {
      this.activeClient$.next({
        ...client,
        ownerName: `${this.userManagerService.get(client.owner)?.firstName} ${
          this.userManagerService.get(client.owner)?.lastName
        }`,
      });
    }
  }

  /**
   * Retrieve the user info based on client's share info
   * @param client - active ciient
   * @param users - current user pool
   */
  getShareInfo(client: Client, users: Map<string, User>) {
    if (client) {
      const shareInformation = (client.shareInfo || []).map((info) => {
        const user = users.get(info.userId);
        return {
          firstName: user?.firstName,
          lastName: user?.lastName,
          userId: info.userId,
          name:
            user && user.loaded
              ? `${user.firstName} ${user.lastName}`
              : 'Loading name...',
          role: this.permissionChecker.getRole(info.permissions),
        };
      });
      this.sharedUserList$.next(shareInformation);
    }
  }

  /**
   * Triggers when an admin adds a user to the shared client info
   * @param formDirective - form group directive (used to reset form and validators)
   */
  onAddUser(formDirective: FormGroupDirective) {
    if (this.addUserForm.valid) {
      const { email, role } = this.addUserForm.value;
      const user = this.userManagerService.selectEmail(email);
      if (user) {
        if (user.email === this.addUserForm.controls.email.value) {
          const newUser: ClientShareInfo = {
            userId: user.id,
            name: `${user.firstName} ${user.lastName}`,
            email,
            role,
          };
          const update = [...this.sharedUserList$.value, newUser];
          this.sharedUserList$.next([...new Set(update)]);
        }
      }
      formDirective.resetForm();
      this.addUserForm.reset();
      this.onValueChange(false);
    }
  }

  /**
   * Triggers client facade to save changes made by admin
   */
  onSave(formDirective: FormGroupDirective) {
    // when there's value on addform fields and user preferred to save without clicking + button to add user to table
    if (this.emailControl.value && this.addUserForm.valid) {
      this.onAddButton.nativeElement.click();
    }
    // clean addUserForm
    this.addUserForm.reset();
    formDirective.resetForm();
    // map to backend
    const { value } = this.sharedUserList$;
    const shareInfo: ClientShareInfo[] = value.map((user: ClientShareInfo) => {
      return {
        userId: user.userId,
        permissions: this.permissionChecker.getPermissions(user.role),
      };
    });
    const clientId = this.clientId;

    if (clientId && shareInfo) {
      this.clientFacade
        .share(clientId, shareInfo)
        .subscribe(() => this.dialogRef.close(this.data));
    }
  }

  /**
   * Will update enable and disable of save button.
   * @param value status
   */
  onValueChange(value: boolean) {
    this.isPristine$.next(value);
  }
  /**
   * Redirect to Clients Page
   */
  onCancel(): void {
    this.dialogRef.close(this.data);
  }

  /**
   * When admin removes focus on email control
   * @param event - keyboard/mouse event
   */
  onBlur() {
    this.validateInputEmail();
  }

  /**
   * Custom email validator - checks if the user is an owner/duplicate,
   * checks if email is a valid user
   */
  validateInputEmail() {
    if (this.emailControl.valid) {
      this.userManagerService
        .getUserByEmail(this.emailControl?.value?.trim())
        .subscribe(
          (user) => {
            // checks for duplicate
            if (
              this.sharedUserList$.value.filter(
                (users) => users.userId === user.id,
              ).length
            ) {
              this.emailControl.setErrors({
                duplicate: { value: this.emailControl?.value },
              });
            }
            if (this.activeClient$.value.owner === user.id) {
              this.emailControl.setErrors({
                duplicate: { value: this.emailControl?.value },
              });
            }
          },
          (error) => {
            // checks if valid user via api call
            this.emailControl.setErrors({
              email: { value: this.emailControl?.value },
            });
          },
        );
    }
  }

  get emailControl() {
    return this.addUserForm.get('email') as FormControl;
  }

  getErrors(errorObj: any) {
    return errorObj ? Coerce.getObjKeys(errorObj) : [];
  }
}

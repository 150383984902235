import { from, of } from 'rxjs';
import {
  catchError,
  first,
  map,
  mapTo,
  mergeMap,
  tap,
  toArray,
} from 'rxjs/operators';
import { doNothing } from 'ssotool-core/utils';
import { UserStateManagerService } from 'ssotool-shared';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { LibraryImportAPIService } from '../services';
import { LibraryImportActions } from './library-import.actions';
import { LibraryImportFacadeService } from './library-import.facade.service';
import { ToastService } from 'ssotool-app/shared/services/toast/toast.service';

@Injectable()
export class LibraryImportEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryImportActions.get),
      mergeMap((action) =>
        this.libraryImportService.get(action.importId).pipe(
          map((response) =>
            LibraryImportActions.getSuccess({
              data: response,
              message: 'Get global library import info success!',
            }),
          ),
          catchError((error) =>
            of(
              LibraryImportActions.getFailure({
                data: action,
                error:
                  (error.error || {}).error || 'Get library import info error!',
                message:
                  (error.error || {}).error || 'Get library import info error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LibraryImportActions.getSuccess),
        tap((action) =>
          this.userManagerService.get(action.data.owner)
            ? doNothing()
            : this.userManagerService.getUserById(action.data.owner),
        ),
      ),
    { dispatch: false },
  );

  getFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LibraryImportActions.getFailure),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  getList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryImportActions.getList),
      mergeMap((action) =>
        this.libraryImportService.getList().pipe(
          map((response) =>
            LibraryImportActions.getListSuccess({
              data: {
                libraryImportListIds: response,
              },
              message: 'Get library import list success!',
            }),
          ),
          catchError((error) =>
            of(
              LibraryImportActions.getListFailure({
                data: action,
                error:
                  (error.error || {}).error || 'Get library import list error!',
                message:
                  (error.error || {}).error || 'Get library import list error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getListSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LibraryImportActions.getListSuccess),
        mergeMap((action) =>
          this.libraryImportFacade.getLibraryImportEntities$.pipe(
            first(),
            mergeMap((libraryList) =>
              from(
                (action.data.libraryImportListIds || [])
                  .filter((library) =>
                    libraryList &&
                    libraryList[library] &&
                    libraryList[library].loaded
                      ? doNothing()
                      : library,
                  )
                  .map((library) => library),
              ).pipe(
                mergeMap((importId) => this.libraryImportFacade.get(importId)),
                toArray(),
              ),
            ),
            mapTo(action),
          ),
        ),
      ),
    { dispatch: false },
  );

  getListFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LibraryImportActions.getListFailure),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  downloadLibrary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryImportActions.downloadLibrary),
      mergeMap((action) =>
        this.libraryImportService.downloadLibrary(action.importId).pipe(
          map((response) =>
            LibraryImportActions.downloadLibrarySuccess({
              data: response,
              message: 'Download Global Library Success',
            }),
          ),
          catchError((error) =>
            of(
              LibraryImportActions.getFailure({
                data: action,
                error:
                  (error.error || {}).error || 'Download global library error!',
                message:
                  (error.error || {}).error || 'Download global library error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  downloadLibrarySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LibraryImportActions.downloadLibrarySuccess),
        tap((action) => this.snackbar.showToast(action.message, 'success')),
      ),
    { dispatch: false },
  );

  downloadLibraryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LibraryImportActions.downloadLibraryFailure),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  importLibrary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryImportActions.importLibrary),
      mergeMap((action) =>
        this.libraryImportService.importLibrary(action.inputFileLibrary).pipe(
          map((response) =>
            LibraryImportActions.importLibrarySuccess({
              data: response,
              message: 'Processing Import, please refresh to get status.',
            }),
          ),
          catchError((error) =>
            of(
              LibraryImportActions.getFailure({
                data: action,
                error:
                  (error.error || {}).error || 'Import global library error!',
                message:
                  (error.error || {}).error || 'Import global library error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  importLibrarySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LibraryImportActions.importLibrarySuccess),
        tap((action) => this.snackbar.showToast(action.message, 'info')),
      ),
    { dispatch: false },
  );

  importLibraryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LibraryImportActions.importLibraryFailure),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  downloadLibraryLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibraryImportActions.downloadLibraryLogs),
      mergeMap((action) =>
        this.libraryImportService.downloadLibraryLogs(action.importId).pipe(
          map((response) =>
            LibraryImportActions.downloadLibraryLogsSuccess({
              data: response,
              message: 'Download Global Library Success',
            }),
          ),
          catchError((error) =>
            of(
              LibraryImportActions.getFailure({
                data: action,
                error:
                  (error.error || {}).error || 'Download global library error!',
                message:
                  (error.error || {}).error || 'Download global library error!',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  downloadLibraryLogsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LibraryImportActions.downloadLibraryLogsSuccess),
        tap((action) => this.snackbar.showToast(action.message, 'success')),
      ),
    { dispatch: false },
  );

  downloadLibraryLogsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LibraryImportActions.downloadLibraryLogsFailure),
        tap((action) => this.snackbar.showToast(action.message, 'error')),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private libraryImportService: LibraryImportAPIService,
    private snackbar: ToastService,
    private userManagerService: UserStateManagerService,
    private libraryImportFacade: LibraryImportFacadeService,
  ) {}
}

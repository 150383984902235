import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { AnnouncementAPIService } from '../services/announcement.service';
import { AnnouncementActions } from './announcement.action';

@Injectable()
export class AnnouncementEffects {
  getList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnnouncementActions.getList),
      mergeMap(() =>
        this.announcementService.getList().pipe(
          map((items) =>
            AnnouncementActions.getListSuccess({
              items,
            }),
          ),
          catchError(() => of(AnnouncementActions.getListFailure())),
        ),
      ),
    ),
  );

  readAnnouncement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnnouncementActions.readAnnouncement),
      mergeMap((action) =>
        this.announcementService.readAnnouncement(action.id).pipe(
          map(() => AnnouncementActions.readAnnouncementSuccess()),
          catchError(() => of(AnnouncementActions.readAnnouncementFailure())),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private announcementService: AnnouncementAPIService,
  ) {}
}

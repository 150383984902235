import { createAction, props } from '@ngrx/store';
import { Trajectory } from '../client.model';

const chooseTrajectoriesToSave = createAction(
  '[Datahub] Select trajectories',
  props<{
    clientId: string;
    importId: string;
    selectedIds: Trajectory['trajectoryId'][];
  }>(),
);
const chooseTrajectoriesToSaveSuccess = createAction(
  '[Datahub] Select trajectories success',
);
const chooseTrajectoriesToSaveFailed = createAction(
  '[Datahub] Select trajectories failed',
  props<{ error: { message: string } }>(),
);

const getTDBCommodities = createAction(
  '[Datahub], Get trajectories of client',
  props<{
    clientId: string;
    importId: string;
  }>(),
);

const getTDBCommoditiesSuccess = createAction(
  '[Datahub] Get trajectories of client success',
  props<{
    data: Record<Trajectory['trajectoryId'], Trajectory>;
  }>(),
);

const getTDBCommoditiesFailure = createAction(
  '[Datahub] Get trajectories of client failure',
  props<{ data: any; error: any; message: string }>(),
);

export const DatahubActions = {
  chooseTrajectoriesToSave,
  chooseTrajectoriesToSaveSuccess,
  chooseTrajectoriesToSaveFailed,
  getTDBCommodities,
  getTDBCommoditiesSuccess,
  getTDBCommoditiesFailure,
};

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserStateManagerService } from 'ssotool-app/shared';
import { ClientFormComponent } from '../client-form/client-form.component';

import { map } from 'rxjs/operators';

@Component({
  selector: 'sso-no-client',
  templateUrl: './no-client.component.html',
  styleUrls: ['./no-client.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoClientComponent {
  constructor(
    private dialog: MatDialog,
    private userManagerService: UserStateManagerService,
  ) {}

  canCreateClient$ = this.userManagerService
    .isExternalUser()
    .pipe(map((status) => !status));

  onCreateClient() {
    this.dialog
      .open(ClientFormComponent, {
        data: {
          mode: 'create',
          title: 'Create New Client',
        },
      })
      .afterClosed();
  }
}

function toEmptyObject<T>(from: T): T {
  return from || ({} as T);
}

function toZero(from: unknown): number {
  return Number(from) || 0;
}

function toString(from: string, to: string = ''): string {
  return from ? from.toString() : to;
}

function toArray<T>(from: T[], to: T[] = []): T[] {
  return from || to;
}

function toObject<T>(from: T, to: T = {} as T): T {
  return from || to;
}

function isBlank(str: string) {
  return !str || str === '';
}

function toMissingMessage(from: string, to: string): string {
  return isBlank(from) ? to : from;
}

function booleanToString(from: boolean, truthy: string, falsy: string): string {
  return from ? truthy : falsy;
}

function toStandardFloat(from: string): number {
  return toZero(from.replace(',', '.'));
}

function getObjValues<T = any, V = Record<string, T>>(obj: V): Array<T> {
  return toArray<T>(Object.values(toObject<V>(obj)));
}

function getObjKeys<T>(obj: T): string[] {
  return toArray(Object.keys(toObject<T>(obj)));
}

function getObjEntries<T = any, V = Record<string, T>>(
  obj: V,
): Array<[string, T]> {
  return toArray(Object.entries(toObject<V>(obj)));
}

export const Coerce = {
  toEmptyObject,
  toZero,
  toString,
  toArray,
  toObject,
  toMissingMessage,
  toStandardFloat,
  booleanToString,
  getObjValues,
  getObjKeys,
  getObjEntries,
};

/**
 * Filter-outs null or undefined items from the list
 * @param items - the item list to filter
 * @returns list of defined items
 */
const filterNonEmpty = <T>(items: T[]): T[] => items?.filter((item) => !!item);

/**
 * Filter-outs duplicate items from the list
 * @param items - the item list to filter
 * @returns list of unique items
 */
const filterUnique = <T>(items: T[]): T[] => [...new Set(items)];

/**
 * Utility for list or array
 */
export const ArrayUtil = {
  filterNonEmpty,
  filterUnique
};

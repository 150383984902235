import { animate, style, transition, trigger, useAnimation } from '@angular/animations';
import { fadeOut } from 'ng-animate';

export const hintAnimation = trigger('hint', [
  transition(':enter', [
    style({ transform: 'translateY(-100%)', zIndex: -1 }),
    animate('200ms ease-in', style({ transform: 'translateY(0%)' })),
  ]),
  transition(':leave', [
    style({
      position: 'fixed',
    }),
    useAnimation(fadeOut, { params: { timing: 1, a: 0 } }),
  ]),
]);

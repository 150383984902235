import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, mergeMap, startWith, take } from 'rxjs/operators';
import {
  Coerce,
  DialogComponent,
  localStorage,
  PermissionChecker,
  SSOToolRoutePathService,
  UserStateManagerService,
} from 'ssotool-shared';
import { BaseComponent } from 'ssotool-shared/component/base';
import { rowAnimation } from 'ssotool-shared/helpers/animations';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

import { ClientTabs } from './client.const';
import { ClientFormComponent, ShareClientComponent } from './components';
import {
  ACTIVE_CLIENT_ID,
  Client,
  ClientFacadeService,
  ClientStatus,
} from './store';

@UntilDestroy()
@Component({
  selector: 'sso-client-page',
  templateUrl: './client-list-page.component.html',
  styleUrls: ['./client-list-page.component.scss'],
  animations: [rowAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientListPageComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatPaginator) paginator: MatPaginator;

  tabOptions = Coerce.getObjValues(ClientTabs);
  expanded = true;

  form: FormGroup = this.formBuilder.group({
    searchInput: '',
    selectedTab: 0,
  });

  noClients$ = this.clientFacade.hasClients$.pipe(
    map((hasClients) => !hasClients),
  );
  loading$ = this.clientFacade.loading$;
  activeClients$ = this.clientFacade.selectClientList$('active');
  archiveClients$ = this.clientFacade.selectClientList$('archive');
  hasArchiveClients$ = this.clientFacade.selectArchiveClients$.pipe(
    map((clients) => clients?.length > 0),
  );
  isDeletePerformed = new BehaviorSubject<boolean>(false);
  displayedColumns = ['name', 'creator', 'actions'];
  references$ = new BehaviorSubject<any>({});
  dataSource$: Observable<MatTableDataSource<Client>>;
  isEmpty$: Observable<boolean> = of(false);
  loaderConfig = {
    common: { margin: '1px', height: '20px', width: '100px' },
    author: { margin: '1px', height: '15px', width: '200px' },
    description: { margin: '1px', height: '20px', width: '300px' },
  };

  _ongoingDuplicateList = new BehaviorSubject<string[]>([]);

  ongoingDuplicateList$ = this._ongoingDuplicateList
    .asObservable()
    .pipe(untilDestroyed(this));

  get currentClientStatus(): ClientStatus {
    return this.tabOptions[this.form.controls.selectedTab.value];
  }

  get localStorageClientId(): string {
    return localStorage.get(ACTIVE_CLIENT_ID);
  }

  isCreatingSandbox$ = this.activeClients$.pipe(
    map((clients) => {
      if (clients.length === 0) {
        return false; // No active clients, emit false
      }

      return !clients.some((client) => client.isSandbox); // Emit false if any client is a sandbox
    }),
  );

  hasSandboxError$ = this.clientFacade.errorInSandbox$;

  constructor(
    private clientFacade: ClientFacadeService,
    private userManagerService: UserStateManagerService,
    private dialog: MatDialog,
    private permissionChecker: PermissionChecker,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private routeService: SSOToolRoutePathService,
    private translateService: TranslateService,
  ) {
    super();
  }

  canCreateClient$ = this.userManagerService
    .isExternalUser()
    .pipe(map((status) => !status));

  ngOnInit() {
    this.initializeRouteSubscription();
    this.initializeReferences();
    this.initializeDuplicatePref();
  }

  ngAfterViewInit() {
    this.initializeDataSource();

    combineLatest([this.isDeletePerformed, this.hasArchiveClients$])
      .pipe(
        untilDestroyed(this),
        filter(([isClick, hasArchive]) => isClick && !hasArchive),
      )
      .subscribe((_) => {
        this.form.controls.selectedTab.patchValue(0);
        this.isDeletePerformed.next(false);
      });
  }

  private initializeRouteSubscription() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      if (params.redirect) {
        this.router.navigate(params.redirect);
      } else if (
        params.quickSwitchId &&
        params.quickSwitchName &&
        params.quickSwitchImport
      ) {
        this.clientFacade.quickSwitch(
          params.quickSwitchId,
          params.quickSwitchImport,
          params.quickSwitchName,
        );
      }
    });
  }

  private initializeReferences() {
    combineLatest([
      this.activeClients$,
      this.archiveClients$,
      this.userManagerService.dataList$,
    ])
      .pipe(this.takeUntil())
      .subscribe(([activeClients, archiveClients, _]) => {
        this.generateReferences(activeClients.concat(archiveClients));
      });
  }

  private initializeDuplicatePref() {
    this.userManagerService
      .getCurrentUserDuplicateClientsPref()
      .pipe(untilDestroyed(this))
      .subscribe((list) => {
        this._ongoingDuplicateList.next(list);
      });
  }

  private initializeDataSource() {
    this.dataSource$ = combineLatest([
      this.form.controls.selectedTab.valueChanges.pipe(
        map((tabIndex) => this.tabOptions[tabIndex]),
        startWith('active'),
      ),
      this.form.controls.searchInput.valueChanges.pipe(
        map((filterValue) => filterValue.toLocaleLowerCase()),
        startWith(''),
      ),
    ]).pipe(
      mergeMap(([status, filterValue]: [ClientStatus, string]) =>
        this.clientFacade.selectClientList$(status).pipe(
          map((clients) =>
            clients.sort(
              (a, b) =>
                new Date(b.updatedAt).valueOf() -
                new Date(a.updatedAt).valueOf(),
            ),
          ),
          map((clients) => {
            const activeClientIndex = clients.findIndex(
              (client) => client.clientId === this.localStorageClientId,
            );
            const copy = [].concat(clients);

            return activeClientIndex >= 0
              ? copy.splice(activeClientIndex, 1).concat(copy)
              : clients;
          }),
          map((clients) => {
            const sandboxClientIndex = clients.findIndex(
              (client) => !!client?.isSandbox,
            );
            const copy = [].concat(clients);

            return sandboxClientIndex >= 0
              ? copy.splice(sandboxClientIndex, 1).concat(copy)
              : clients;
          }),
          map((clients) => this.mapClientsToDatasource(clients, filterValue)),
        ),
      ),
    );

    this.isEmpty$ = combineLatest([this.dataSource$, this.loading$]).pipe(
      map(([datasource, loading]) => !loading && datasource?.data.length < 1),
    );
  }

  private mapClientsToDatasource = (
    clients: Client[],
    filterValue = '',
  ): MatTableDataSource<Client> => {
    const data = new MatTableDataSource(clients);

    data.paginator = this.paginator;
    data.filterPredicate = (data, filter: string) => {
      const updatedBy = this.references$.getValue()?.names?.[data?.owner] || '';
      return (
        data?.name.toLocaleLowerCase().includes(filter) ||
        updatedBy?.toLocaleLowerCase().includes(filter)
      );
    };
    data.filter = filterValue;
    return data;
  };

  generateReferences(clients: Client[]) {
    const names: Record<string, string> = {};
    const badgeValues: Record<string, string> = {};
    clients.forEach((client) => {
      const owner = this.userManagerService.get(client?.owner);
      names[client?.owner] = owner?.loaded
        ? `${owner?.firstName} ${owner?.lastName}`
        : 'Loading...';
      badgeValues[client.clientId] = client.sharedCount?.toString();
    });
    this.references$.next({
      names,
      badgeValues,
    });
  }

  onAdd(): void {
    this.dialog
      .open(ClientFormComponent, {
        data: {
          mode: 'create',
          title: 'Create New Client',
        },
      })
      .afterClosed();
  }

  onShare(data: Client): void {
    this.dialog
      .open(ShareClientComponent, {
        data,
      })
      .afterClosed();
  }

  onEdit(client: Client) {
    this.dialog
      .open(ClientFormComponent, {
        data: {
          client: client,
          mode: 'update',
          title: `Edit ${client.name}`,
        },
      })
      .afterClosed();
  }

  onDuplicate(client: Client) {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: 'Client.labels.duplicateClient',
          message: this.translateService.instant(
            'Client.messages.duplicateConfirmation',
            { name: client.name },
          ),
          confirm: 'Generic.labels.duplicate',
          close: 'Generic.labels.cancel',
          disableClose: false,
          width: '250px',
          client,
        },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data?.client) {
          this.clientFacade.duplicateClient(data.client.clientId);
        }
      });
  }

  onDelete(client: Client) {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: `Delete ${client.name}`,
          message: `Are you sure to delete ${client.name}?`,
          confirm: 'Delete',
          close: 'Cancel',
          disableClose: false,
          width: '250px',
          client,
        },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data && data.client) {
          this.clientFacade.delete(data.client.clientId);
          // this is to trigger whatever needs to perform after deletion.
          this.isDeletePerformed.next(true);
        }
      });
  }

  onArchive(client: Client) {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: 'Archive Client',
          message: `Are you sure you want to archive the client ${client.name}?`,
          confirm: 'Archive',
          close: 'Cancel',
          disableClose: false,
          width: '250px',
          client,
        },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data?.client) {
          this.clientFacade.setClientStatus(data.client.clientId, 'archive');
        }
      });
  }

  onUnarchive(client: Client) {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: 'Unarchive Client',
          message: `Are you sure you want to unarchive the client ${client.name}?`,
          confirm: 'Unarchive',
          close: 'Cancel',
          disableClose: false,
          width: '250px',
          client,
        },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data && data.client) {
          this.clientFacade.setClientStatus(data.client.clientId, 'active');
        }
      });
  }

  getRedirectFn(
    hasSuccessfulImport: boolean = false,
  ): (clientId: string) => string[] {
    if (!!hasSuccessfulImport) {
      return this.routeService.roadmap;
    }
    return this.routeService.clientImport;
  }

  onSelectClient(client: Client) {
    if (client.clientId === this.localStorageClientId) {
      this.router.navigate(
        this.getRedirectFn(client.hasSuccessfulImport)(client.clientId),
      );
    } else {
      this.clientFacade.quickSwitch(
        client.clientId,
        client.hasSuccessfulImport,
        client.name,
      );
    }
  }

  isAdmin(client: Client) {
    return this.permissionChecker.isAdmin(client.permissions);
  }

  canDuplicate$(client: Client): Observable<boolean> {
    return this.isDuplicating$(client).pipe(
      map((isDuplicating) => !!!isDuplicating && this.isAdmin(client)),
    );
  }

  isDuplicating$(client: Client): Observable<boolean> {
    return this.ongoingDuplicateList$.pipe(
      map((list) => list.includes(client?.clientId)),
    );
  }

  getBadge(id: string) {
    return this.references$?.getValue()?.badgeValues?.[id];
  }

  isClientArchived(client: Client): boolean {
    return client.isArchive;
  }

  /* istanbul ignore next */
  testSentry() {
    this.clientFacade.get(null);
    const a = null;
    return a['test_sentry'];
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { PipesModule } from '../../pipes/pipes.module';
import { SparklineComponent } from './sparkline.component';

@NgModule({
  declarations: [SparklineComponent],
  imports: [CommonModule, FlexLayoutModule, PipesModule],
  exports: [SparklineComponent],
})
export class SparklineModule {}

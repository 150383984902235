import { first } from 'rxjs/operators';
import { Coerce } from 'ssotool-shared/helpers';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { ClientFacadeService } from '../store';

@Injectable({
  providedIn: 'root',
})
export class ClientDataSubpageResolverService implements Resolve<any> {
  constructor(private clientFacade: ClientFacadeService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const clientId = Coerce.toObject(route.params).clientId;

    if (clientId) {
      this.clientFacade
        .dataLoaded$(clientId)
        .pipe(first())
        .subscribe((loaded) => {
          if (!loaded) {
            this.clientFacade.getClientDataInfo(clientId);
          }
        });
    }
  }
}

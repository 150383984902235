import { BehaviorSubject, Observable } from 'rxjs';
import { PortfolioRoadmap } from 'ssotool-app/+roadmap/stores';

import { Injectable } from '@angular/core';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Coerce } from 'ssotool-app/shared';

@Injectable({
  providedIn: 'root',
})
export class RoadmapIoService {
  private _clientId = new BehaviorSubject<string>('');
  private _selectedVariationId = new BehaviorSubject<string>('');
  private _owner = new BehaviorSubject<string>('');
  private _roadmap = new BehaviorSubject<PortfolioRoadmap>(null);
  private _readonly = new BehaviorSubject<boolean>(false);
  private _selectedSecondaryTab = new BehaviorSubject<string>('');
  private _viewMode = new BehaviorSubject<string>('');
  private _roadmapVisualizationNav = new BehaviorSubject<string>('');
  private _isRunAsPercent = new BehaviorSubject<boolean>(false);

  clientId$ = this._clientId as Observable<string>;
  variationId$ = this._selectedVariationId as Observable<string>;
  owner$ = this._owner as Observable<string>;
  roadmap$ = this._roadmap as Observable<PortfolioRoadmap>;
  roadmapId$ = this.roadmap$.pipe(
    map((roadmap) => Coerce.toEmptyObject(roadmap).id),
    distinctUntilChanged(),
  );
  readonly$ = this._readonly as Observable<boolean>;
  secondaryTab$ = this._selectedSecondaryTab as Observable<string>;
  viewMode$ = this._viewMode as Observable<string>;
  roadmapVisualizationNav$ = this
    ._roadmapVisualizationNav as Observable<string>;
  isRunAsPercent$ = this._isRunAsPercent as Observable<boolean>;

  setRunAsPercent(isPercent: boolean) {
    this._isRunAsPercent.next(isPercent);
  }

  setClientId(id: string) {
    this._clientId.next(id);
  }

  setVariationId(id: string) {
    this._selectedVariationId.next(id);
  }

  setOwner(owner: string) {
    this._owner.next(owner);
  }

  setRoadmap(roadmap: PortfolioRoadmap) {
    this._roadmap.next(roadmap);
  }

  setReadonly(readonly: boolean) {
    this._readonly.next(readonly);
  }

  setSecondaryTab(tab: string) {
    this._selectedSecondaryTab.next(tab);
  }

  setViewMode(viewMode: string) {
    this._viewMode.next(viewMode);
  }

  setRoadmapVisualizationNav(roadmapVisualizationName: string) {
    this._roadmapVisualizationNav.next(roadmapVisualizationName);
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FluidDemandState } from './fluid-demand.reducer';
import {
  DemandsExistingContracts,
  DemandsExistingRenewables,
  FLUID_DEMAND_FEATURE_NAME,
  FluidDemandActionParams,
} from './fluid-demand.model';
import { Coerce } from 'ssotool-app/shared';

const fluidDemandFeatureState = createFeatureSelector<FluidDemandState>(
  FLUID_DEMAND_FEATURE_NAME,
);

export const selectLoading = createSelector(
  fluidDemandFeatureState,
  (state) => state.loading,
);

export const selectLoaded = createSelector(
  fluidDemandFeatureState,
  (state) => state.loaded,
);

export const selectStoreMessage = createSelector(
  fluidDemandFeatureState,
  (state) => state.message,
);

export const selectStoreError = createSelector(
  fluidDemandFeatureState,
  (state) => state.error,
);

export const selectFluidDemand = ({
  variationId,
  siteId,
  fluidId,
}: FluidDemandActionParams) =>
  createSelector(fluidDemandFeatureState, (state) =>
    Coerce.toEmptyObject(
      Coerce.toEmptyObject(
        Coerce.toEmptyObject(state.data.fluid_demands[siteId])[variationId],
      )[fluidId],
    ),
  );

export const selectExistingContracts = ({ siteIds }: FluidDemandActionParams) =>
  createSelector(fluidDemandFeatureState, (state) => {
    const existingContracts = Coerce.toEmptyObject(
      state.data.existing_contracts,
    );
    return Object.entries(existingContracts).reduce(
      (
        filteredContracts,
        [siteId, contract]: [string, DemandsExistingContracts['siteId']],
      ) => {
        if (siteIds.includes(siteId)) {
          filteredContracts[siteId] = contract;
        }
        return filteredContracts;
      },
      {} as DemandsExistingContracts,
    );
  });

export const selectExistingRenewables = ({
  siteIds,
}: FluidDemandActionParams) =>
  createSelector(fluidDemandFeatureState, (state) => {
    const existingRenewables = Coerce.toEmptyObject(
      state.data.existing_renewables,
    );
    return Object.entries(existingRenewables).reduce(
      (
        filteredRenewables,
        [siteId, renewable]: [string, DemandsExistingRenewables['siteId']],
      ) => {
        if (siteIds.includes(siteId)) {
          filteredRenewables[siteId] = renewable;
        }
        return filteredRenewables;
      },
      {} as DemandsExistingRenewables,
    );
  });

export const selectExistingContract = ({
  contractId,
  siteId,
  fluidId,
}: FluidDemandActionParams) =>
  createSelector(fluidDemandFeatureState, (state) =>
    Coerce.toEmptyObject(
      Coerce.toEmptyObject(
        Coerce.toEmptyObject(state.data.existing_contracts[siteId])[contractId],
      )[fluidId],
    ),
  );

export const selectExistingRenewable = ({
  renewableId,
  siteId,
  fluidId,
}: FluidDemandActionParams) =>
  createSelector(fluidDemandFeatureState, (state) =>
    Coerce.toEmptyObject(
      Coerce.toEmptyObject(
        Coerce.toEmptyObject(state.data.existing_renewables[siteId])[
          renewableId
        ],
      )[fluidId],
    ),
  );

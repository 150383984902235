<div
  fxLayout="column"
  fxLayoutAlign="start start"
  fxLayoutGap="50px"
  class="oculus-drawer-content login-container"
>
  <div fxLayout="row" fxLayoutAlign="start center" class="login-header">
    <img
      data-test="ellipseLogo"
      src="assets/logos/logo-ellipse.svg"
      [style.display]="'block'"
    />
  </div>

  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    class="login-content"
    fxLayoutGap="20px"
  >
    <div
      class="gradient"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="30px"
    >
      <h1 data-test="welcomeTo">
        {{ 'Dashboard.messages.welcomeToHeader' | translate | titlecase }}
      </h1>
      <h1 data-test="projectName">
        {{ 'Dashboard.messages.ssoToolHeader' | translate }}
      </h1>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
      <button
        data-test="login"
        mat-raised-button
        color="primary"
        (click)="onLogin()"
      >
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="25px">
          <mat-icon data-test="loginLogo">login</mat-icon>
          <span>Login</span>
        </div>
      </button>
      <span class="request">
        Click <a class="sso-clickable link-primary">here</a> to request
        access</span
      >
    </div>
  </div>
</div>
